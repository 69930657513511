import React from "react";
import { Box, Typography, Button, Chip, Divider } from "@mui/material";

const SectionTitle = ({
  title,
  type,
  code,
  SubTotal,
  Total,
  hasItems = false,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          maxWidth: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" color="primary">
            {title}
          </Typography>
          <Button variant="outlined" onClick={() => {}}>
            Editar
          </Button>
        </Box>
        {hasItems && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <Chip
              variant="outlined"
              label={type || "-"}
              sx={{ fontSize: "13px" }}
            />
            <Chip
              variant="outlined"
              label={`Code: ${
                typeof code === "string" ? code : code.Value || "-"
              }`}
              sx={{ fontSize: "13px" }}
            />
            <Chip
              variant="outlined"
              label={`Subtotal: $${SubTotal || "-"}`}
              sx={{ fontSize: "13px" }}
            />
            <Chip
              variant="outlined"
              label={`Total: $${Total || "-"}`}
              sx={{ fontSize: "13px" }}
            />
          </Box>
        )}
      </Box>
      <Divider sx={{ bgcolor: "#01579b", height: 2, mb: 4, mt: 1 }} />
    </>
  );
};

export default SectionTitle;
