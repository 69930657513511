import React, {useEffect, useRef, useState} from "react";
//*MUI Componentes
import {
  Container,
  Box,
  Button,
  CssBaseline,
  ThemeProvider,
  Typography,
  Stepper,
  Paper,
  Step,
  StepLabel,
} from "@mui/material";
import {AlertDialog} from "../../../components/userFeedback/AlertDialog";
//*Network, api and routing
import CustomerFiscalForm from "../../../components/Forms/Customers/CustomerFiscalForm";
import CustomerAddressForm from "../../../components/Forms/Customers/CustomerAddressForm";
import {saveCustomer} from "../../../services/modules/organization/customers";
import {useNavigate} from "react-router";
import useAuth from "../../../hooks/useAuth";
import ferbyTheme from "../../../theme/ferbyTheme";
import {getRegimes} from "../../../services/modules/invoices/catalogs";
import AddressDto from "../../../utils/dto/address.dto";
import {ReceiverDto} from "../../../utils/dto/receiver.dto";

const steps = ["Información Fiscal", "Agregar Dirección"];

const theme = ferbyTheme;

export const CreateCustomer = () => {
  const {userid} = useAuth();

  const addressRef = useRef(null);
  const customerRef = useRef(null);

  const [customer, setCustomer] = useState({
    ...JSON.parse(JSON.stringify(ReceiverDto)),
    Address: JSON.parse(JSON.stringify(AddressDto)),
    subEmpresas: [],
  });
  const [regimenOptions, setRegimenOptions] = useState([]);

  const [activeStep, setActiveStep] = React.useState(0);

  const navigate = useNavigate();

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
    keep: false,
  });

  const handleNext = () => {
    if (activeStep === 0) {
      if (customerRef.current && !customerRef.current.validateAndShow()) {
        return;
      }
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSetCustomer = (e, v, r) => {
    const id = e.target.id.split("-")[0];
    if (r === "selectOption" || r === "input") {
      setCustomer({
        ...customer,
        [id]: v,
      });
    }
    if (r === "clear") {
      setCustomer({
        ...customer,
        [id]: "",
      });
    }
  };

  const setCustomerAddress = (e, v, r) => {
    const id = e.target.id.split("-")[0];
    const propertiesOfAddress = [
      "ZipCode",
      "ExteriorNumber",
      "InteriorNumber",
      "Neighborhood",
      "Street",
    ];

    if (propertiesOfAddress.includes(id)) {
      setCustomer({
        ...customer,
        Address: {
          ...customer.Address,
          [id]: v,
        },
      });

      return;
    }
    if (r === "selectOption" || r === "input") {
      setCustomer({
        ...customer,
        [id]: v,
      });
    }
    if (r === "clear") {
      setCustomer({
        ...customer,
        [id]: JSON.parse(JSON.stringify(AddressDto)),
      });
    }
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <CustomerFiscalForm
            setter={handleSetCustomer}
            typeHook={customer}
            regimenOptions={regimenOptions}
            ref={customerRef}
          />
        );
      case 1:
        return (
          <CustomerAddressForm
            type={"Cliente"}
            setter={setCustomerAddress}
            typeHook={customer}
            ref={addressRef}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const handle400Error = (data) => {
    let message = "";
    try {
      if (
        data.data.error &&
        data.data.error.Descriptions &&
        data.data.error.Descriptions.length > 0
      ) {
        data.data.error.Descriptions.forEach((description, index) => {
          message +=
            description.Reason +
            `${index === data.data.error.Descriptions.length - 1 ? "" : ", "}`;
        });
      }

      if (
        data.data.error &&
        (!data.data.error.Descriptions ||
          data.data.error.Descriptions.length === 0)
      ) {
        message += data.data.message;
      }
    } catch (err) {
      console.log(err);
      message = "Error desconocido, status 400";
    }

    return message;
  };

  const handleRequestErrorUi = (err) => {
    console.log(err);
    if (err.response) {
      switch (err.response.status) {
        case 400:
          setDialog({
            ...dialog,
            open: true,
            title: "Verifica los datos",
            content: handle400Error(err.response),
            actions: [
              {
                label: "Reintentar",
                execute: () => setDialog({...dialog, open: false}),
              },
            ],
          });
          break;
        case 401:
          setDialog({
            ...dialog,
            open: true,
            title: "Error de autenticación",
            content: "No tienes permiso para realizar esta acción",
            actions: [
              {
                label: "Ok",
                execute: () => navigate("/login"),
              },
            ],
          });
          break;
        case 403:
          setDialog({
            ...dialog,
            open: true,
            title: "Error de autenticación",
            content: "No tienes permiso para realizar esta acción",
            actions: [
              {
                label: "Ok",
                execute: () => navigate("/login"),
              },
            ],
          });
          break;
        case 404:
          setDialog({
            ...dialog,
            open: true,
            title: "Recursos no encontrados",
            content: "No se encontraron los recursos solicitados",
            actions: [
              {
                label: "Ok",
                execute: () => navigate("/login"),
              },
            ],
          });
          break;
        case 500:
          setDialog({
            ...dialog,
            open: true,
            title: "Error de servidor",
            content: "Error interno del servidor",
            actions: [
              {
                label: "Reintentar",
                execute: () => setDialog({...dialog, open: false}),
              },
            ],
          });
          break;
        default:
          setDialog({
            ...dialog,
            open: true,
            title: "Error desconocido",
            content: "Error desconocido",
            actions: [
              {
                label: "Reintentar",
                execute: () => setDialog({...dialog, open: false}),
              },
            ],
          });
          break;
      }
      return;
    }

    setDialog({
      ...dialog,
      open: true,
      title: "Error guardando cliente",
      content: "Detalle de error: " + err,
      actions: [
        {
          label: "Reintentar",
          execute: () => setDialog({...dialog, open: false}),
        },
      ],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (addressRef.current && !addressRef.current.validateAndShow()) {
      return;
    }

    saveCustomer(customer, userid)
      .then((response) => {
        if (response.status === 201) {
          setDialog({
            ...dialog,
            open: true,
            title: "Guardado exitosamente",
            content: "",
            actions: [
              {
                label: "Ok",
                execute: () => navigate(`/${userid.claims.rol}/customers`),
              },
            ],
          });
        }
      })
      .catch((err) => {
        console.log(err);
        handleRequestErrorUi(err);
      });
  };

  const handleUpdateOpen = () => setDialog(!dialog.open);

  useEffect(() => {
    getRegimes(userid, userid)
      .then((data) => {
        if (data.status === 200) {
          setRegimenOptions(data.data);
          return;
        }
        setDialog({
          ...dialog,
          open: true,
          title: "Error obteniendo catálogo de regímenes fiscales",
          content: "La respuesta del servidor no es válida",
          actions: [
            {
              label: "Reintentar",
              execute: () => window.location.reload(),
            },
          ],
          keep: true,
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          setDialog({
            ...dialog,
            open: true,
            title: "Error obteniendo catálogo de regímenes fiscales",
            content: err.response.data.message,
            actions: [
              {
                label: "Reintentar",
                execute: () => window.location.reload(),
              },
            ],
            keep: true,
          });
        }
      });
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AlertDialog
          updateOpenState={handleUpdateOpen}
          open={dialog.open}
          title={dialog.title}
          content={dialog.content}
          actions={dialog.actions}
          keep={dialog.keep}
        />
        <Container component="main" maxWidth="sm" sx={{mb: 4}}>
          <Paper
            variant="outlined"
            sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}
          >
            <Typography component="h1" variant="h4" align="center">
              Agregar cliente
            </Typography>
            <Stepper activeStep={activeStep} sx={{pt: 3, pb: 5}}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Has completado el registro.
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {React.cloneElement(getStepContent(activeStep), {
                    dialog: dialog,
                    setDialog: setDialog,
                  })}
                  <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} sx={{mt: 3, ml: 1}}>
                        Anterior
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      onClick={
                        activeStep === steps.length - 1
                          ? handleSubmit
                          : handleNext
                      }
                      sx={{mt: 3, ml: 1}}
                    >
                      {activeStep === steps.length - 1
                        ? "Guardar"
                        : "Siguiente"}
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </Container>
      </ThemeProvider>
    </>
  );
};
