import React from "react";
import { Grid, Typography, TextField, Autocomplete } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toAntiguedadFormat } from "../../../modules/invoices/utils/toAntiguedadFormat";
import { toIsoHelper } from "../Nomina/NominaDateTypeComponent";

const WorkDataForm = ({
  employeeData,
  onWorkDataChange,
  onAutoCompleteChange,
  onDateChange,
  options
}) => {
  const handleInputChange = (e) => {
    onWorkDataChange(e);
  };

  const handleAutoCompleteChange = (e, v, r) => {
    onAutoCompleteChange(e, v, r);
  };

  const handleDateChange = (newValue) => {
    let antig = toAntiguedadFormat(newValue, true);
    onDateChange(toIsoHelper(new Date(newValue)), antig);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography color="primary">Datos Laborales</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          id="Id"
          name="Id"
          label="Identificador del empleado"
          fullWidth
          value={employeeData.Id}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            id="FechaInicio"
            name="FechaInicio"
            label="Fecha de inicio de relación laboral"
            required
            disableFuture
            slotProps={{ textField: { variant: "standard" } }}
            sx={{ width: "100%" }}
            value={new Date(employeeData.FechaInicio)}
            onChange={handleDateChange}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          id="Antiguedad"
          name="Antiguedad"
          label="Antigüedad"
          fullWidth
          value={employeeData.Antiguedad}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          autoComplete
          id="TipoRegimen"
          sx={{ width: "100%" }}
          options={options.regimeType}
          value={employeeData.TipoRegimen || ""}
          getOptionLabel={(option) => {
            return `${option.Name}`;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                multiline
                id="RegimeType"
                sx={{ width: "100%" }}
                label="Régimen"
                required
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }}
          onChange={onAutoCompleteChange}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Autocomplete
          autoComplete
          sx={{ width: "100%" }}
          id="Sindicato"
          options={options.sindicato}
          value={employeeData.Sindicato || ""}
          getOptionLabel={(option) => {
            return `${option.Name}`;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                id="Unionized"
                sx={{ width: "100%" }}
                label="Sindicalizado?"
                required
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }}
          onChange={onAutoCompleteChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          id="Riesgo"
          autoComplete
          sx={{ width: "100%" }}
          options={options.risk}
          value={employeeData.Riesgo || ""}
          getOptionLabel={(option) => {
            return `${option.Name}`;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                sx={{ width: "100%" }}
                variant="standard"
                label="Riesgo"
                required
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }}
          onChange={onAutoCompleteChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          id="TipoContrato"
          autoComplete
          sx={{ width: "100%" }}
          options={options.contractType}
          value={employeeData.TipoContrato || ""}
          getOptionLabel={(option) => {
            return `${option.Name}`;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                multiline
                sx={{ width: "100%" }}
                variant="standard"
                label="Tipo de Contrato"
                required
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }}
          onChange={onAutoCompleteChange}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Autocomplete
          id="TipoJornada"
          autoComplete
          sx={{ width: "100%" }}
          options={options.journey}
          value={employeeData.TipoJornada || ""}
          getOptionLabel={(option) => {
            return `${option.Name}`;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                multiline
                sx={{ width: "100%" }}
                variant="standard"
                label="Tipo de Jornada"
                required
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }}
          onChange={onAutoCompleteChange}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Autocomplete
          autoComplete
          id="Dpto"
          sx={{ width: "100%" }}
          options={options.departments}
          value={employeeData.Dpto || ""}
          getOptionLabel={(option) => {
            return `${option.label}`;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                multiline
                id="Dpto"
                name="Dpto"
                label="Departamento"
                fullWidth
                sx={{ width: "100%" }}
                required
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }}
          onChange={onAutoCompleteChange}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Autocomplete
          autoComplete
          id="Puesto"
          sx={{ width: "100%" }}
          options={options.positions}
          value={employeeData.Puesto || ""}
          getOptionLabel={(option) => {
            return `${option.label}`;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                multiline
                id="Puesto"
                name="Puesto"
                label="Puesto"
                sx={{ width: "100%" }}
                required
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }}
          onChange={onAutoCompleteChange}
        />
      </Grid>

      {employeeData.Puesto === "OPERADOR" && (
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            id="Licencia"
            name="Licencia"
            label="Licencia"
            fullWidth
            value={employeeData.Licencia}
            onChange={handleInputChange}
          />
        </Grid>
      )}
    </>
  );
};

export default WorkDataForm;