import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

//*todo: This component is bad named. It should be called DataGridActionsComponent
const EditComponent = ({
  editionRoute,
  objName,
  onDelete,
  rowParams,
  loading,
  useParams,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleEdit = () => {
    // Construct the edition route with URL variables based on row params
    if(useParams){
      const augmentedEditionRoute = `${editionRoute}/?Id=${rowParams._id}`;
      navigate(augmentedEditionRoute);
      return;
    }
    
    const augmentedEditionRoute = `${editionRoute}/${rowParams._id}`;
    navigate(augmentedEditionRoute);
  };

  const handleDelete = () => {
    // Call the delete function specified in props
    onDelete();
  };

  const handleClose = (e, r) => {
    if (r === "backdropClick") {
      return;
    }
    setModalOpen(false);
  };

  return (
    <div>
      <IconButton onClick={() => setModalOpen(true)} disabled={loading}>
        <EditIcon />
      </IconButton>
      <Dialog open={isModalOpen} onClose={handleClose}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              "&:hover": {
                backgroundColor: "revert-layer",
                transition: "background-color 0.3s ease",
              },
            }}
          >
            <Close />
          </IconButton>
        </Grid>
        <Grid
          container
          spacing={2}
          display={"flex"}
          textAlign={"center"}
          justifyContent={"center"}
          sx={{
            padding: "5%",
            "& .MuiButtonBase-root": { minWidth: "36%" },
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h4" color="primary.dark">
              Acciones de {objName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleEdit} disabled={loading}>
              Editar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="error"
              onClick={handleDelete}
              disabled={loading}
            >
              Eliminar
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

export default EditComponent;
