import apiObj from "../../axios";
const { api, version } = apiObj;

const EMAIL_API_CONTEXT = (controller, endpoint) => {
  const emails = {
    invoices: {
      sendInvoiceById: (userid, credential, id, payload) =>
        api.post(
          `/${version}/invoices/send/${id}`,
          { ...payload },
          {
            headers: {
              authorization: credential.token,
              user: userid,
            },
          }
        ),
    },
  };

  return emails[controller][endpoint];
};

export default EMAIL_API_CONTEXT;
