import React, { useState, useEffect } from "react";
import GoogleMapsWrapper from "../maps/GoogleMapsWrapper";
import { Divider, Grid, Typography } from "@mui/material";
import CardContainer from "../Cards/CardContainer";
import CardTitle from "../Cards/CardTitle";

const DestinationCompanyCard = ({ company, times }) => {
  const buildAddress = (address) => {
    if (address) {
      if (address?.FullAddress && address?.description) {
        return `${address.description}`;
      }

      if (address?.FullAddress) {
        return `${address.FullAddress}`;
      }

      if (address?.description) {
        return `${address.description}`;
      }

      if (
        address?.Street &&
        address?.State &&
        address?.ZipCode &&
        state?.Country
      ) {
        return `${address.Street}, ${address.State}, ${address.ZipCode}, ${state.Country}`;
      }

      if (address?.ZipCode) {
        return `Código postal: ${address.ZipCode}`;
      }
    }
    return "-";
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Typography variant="body2" align="center">
          <b>Nombre: </b>
          {company?.TaxName || "-"}
        </Typography>
        <Typography variant="body2" align="center">
          <b>Rfc: </b>
          {company?.Rfc || "-"}
        </Typography>
        <Typography variant="body2" align="center">
          <b>Tipo de lugar: </b>
          {company?.Type || "-"}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" align="center">
          <b>Dirección:</b>
        </Typography>
        <Typography variant="body2" align="center">
          {buildAddress(company?.Address)}
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Typography variant="body2" align="center">
          Cita: {times?.ScheduleTime || "-"}
        </Typography>
      </Grid>
      <Grid item xs={6} md={12}>
        <Typography variant="body2" align="center">
          Real: {times?.RealTime || "-"}
        </Typography>
      </Grid>
    </Grid>
  );
};

const DesintationCompaniesCard = ({ loadCompany, unloadCompany, times }) => {
  const [route, setRoute] = useState(null);

  useEffect(() => {
    if (
      loadCompany?.Address?.lattitude &&
      loadCompany?.Address?.longitude &&
      unloadCompany?.Address?.lattitude &&
      unloadCompany?.Address?.longitude
    ) {
      const origin = {
        lat: loadCompany.Address.lattitude,
        lng: loadCompany.Address.longitude,
      };
      const destinations = [
        {
          lat: unloadCompany.Address.lattitude,
          lng: unloadCompany.Address.longitude,
        },
      ];
      const params = {
        travelMode: "DRIVING",
        unitSystem: "metric",
        avoidHighways: false,
        avoidTolls: false,
      };
      setRoute({ origin, destinations, params });
      //*DISABLED DUE TO COMPATIBILITY WITH GOOGLE MAPS API
      // getRoute(userid, userid, origin, destinations[0], params)
      //   .then((response) => {
      //     // setRoute(response.data);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     handleErrorUi(err);
      //   });
    }
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        {/* Load Company on the left */}
        <Grid item xs={12} md={6}>
          <CardContainer>
            <CardTitle title="Empresa de carga" />
            <DestinationCompanyCard company={loadCompany} times={times.load} />
          </CardContainer>
        </Grid>

        {/* Unload Company on the right */}
        <Grid item xs={12} md={6}>
          <CardContainer>
            <CardTitle title="Empresa de descarga" />
            <DestinationCompanyCard
              company={unloadCompany}
              times={times.unload}
            />
          </CardContainer>
        </Grid>

        {/* Google Maps Wrapper */}
        <Grid
          item
          xs={12}
          sx={{ width: "100%", height: "40vh", marginY: "2vh" }}
        >
          <GoogleMapsWrapper directions={route} />
        </Grid>
      </Grid>
    </>
  );
};

export default DesintationCompaniesCard;
