import React, {useEffect, useState} from "react";
import {TransportItem} from "../../../../modules/services/utils/ServiceItemsENUM";
import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import {LocalizationProvider, MobileDateTimePicker} from "@mui/x-date-pickers";
import {toIsoHelper} from "../../Nomina/NominaDateTypeComponent";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
//icons
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import {isEmpty} from "../../../../services/helpers/objects/isEmpty";
import DestinationCompanyPaper from "./DestinationCompanyPaper";
import GoogleMapsWrapper from "../../../maps/GoogleMapsWrapper";
import useAuth from "../../../../hooks/useAuth";

const DestinationCompanies = ({
  CompanyLoad,
  CompanyUnload,
  dateTimeLoad,
  dateTimeUnload,
  options,
  handleChangeSelection,
  setLoadingCompanyInput,
  setUnloadingCompanyInput,
  handleShowForm = undefined,
  snack = undefined,
  setSnack = undefined,
}) => {
  const {userid} = useAuth();
  const [route, setRoute] = useState(null);

  const handleChangeAutoComplete = (e, v, r) => {
    handleChangeSelection(e, v, r);
  };

  const handleChangeAutocompleteTextFieldLoad = (e, newValue) => {
    if (newValue.length > 3) {
      setLoadingCompanyInput(newValue);
    }
  };

  const handleChangeAutocompleteTextFieldUnload = (e, newValue) => {
    if (newValue.length > 3) {
      setUnloadingCompanyInput(newValue);
    }
  };

  const handleShowNewLoadingCompanyForm = () => {
    handleShowForm("CompanyLoad");
  };

  const handleShowNewUnloadingCompanyForm = () => {
    handleShowForm("CompanyUnload");
  };

  const handleDeleteLoadDestinationCompany = () => {
    handleChangeSelection(
      {
        target: {
          id: "CompanyLoad",
          value: TransportItem.CompanyLoad,
        },
      },
      TransportItem.CompanyLoad,
      "clear"
    );
  };

  const handleDeleteUnloadDestinationCompany = () => {
    handleChangeSelection(
      {
        target: {
          id: "CompanyUnload",
          value: TransportItem.CompanyUnload,
        },
      },
      TransportItem.CompanyUnload,
      "clear"
    );
  };

  const handleSetDateTimeLoad = (newValue) => {
    handleChangeSelection(
      {
        target: {
          id: "DateTimeLoad",
          value: toIsoHelper(newValue),
        },
      },
      toIsoHelper(newValue),
      "selectOption"
    );
  };

  const handleSetDateTimeUnload = (newValue) => {
    handleChangeSelection(
      {
        target: {
          id: "DateTimeUnload",
          value: toIsoHelper(newValue),
        },
      },
      toIsoHelper(newValue),
      "selectOption"
    );
  };

  const bestCaseOptionLocation = (option) => {
    if (option.Rfc && option.Address.ZipCode && option.Address.description) {
      return `${option.Address.description} - ${option.Address.ZipCode}`;
    }

    return null;
  };

  const handleErrorUi = (err) => {
    if (snack && setSnack) {
      if (err.response && err.response.status === 404) {
        setSnack({
          open: true,
          message: "No se encontró ruta",
          severity: "warning",
        });
        return;
      }

      if (err.response && err.response.status === 500) {
        setSnack({
          open: true,
          message: "Ocurrió un error al obtener la ruta",
          severity: "error",
        });
        return;
      }

      if (err.response && err.response.status === 400) {
        setSnack({
          open: true,
          message: "Error en los parámetros de la ruta",
          severity: "error",
        });
        return;
      }

      if (err.response && err.response.status === 401) {
        setSnack({
          open: true,
          message: "No autorizado",
          severity: "error",
        });
        return;
      }

      setSnack({
        open: true,
        message: "Ocurrió un error desconocido al obtener la ruta",
        severity: "error",
      });
    }
  };

  useEffect(() => {
    if (
      CompanyLoad?.Address?.lattitude &&
      CompanyLoad?.Address?.longitude &&
      CompanyUnload?.Address?.lattitude &&
      CompanyUnload?.Address?.longitude
    ) {
      const origin = {
        lat: CompanyLoad.Address.lattitude,
        lng: CompanyLoad.Address.longitude,
      };
      const destinations = [
        {
          lat: CompanyUnload.Address.lattitude,
          lng: CompanyUnload.Address.longitude,
        },
      ];
      const params = {
        travelMode: "DRIVING",
        unitSystem: "metric",
        avoidHighways: false,
        avoidTolls: false,
      };
      setRoute({origin, destinations, params});
      //*DISABLED DUE TO COMPATIBILITY WITH GOOGLE MAPS API
      // getRoute(userid, userid, origin, destinations[0], params)
      //   .then((response) => {
      //     // setRoute(response.data);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     handleErrorUi(err);
      //   });
    }
  }, [CompanyLoad, CompanyUnload]);

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={1} sx={{marginTop: "2vh"}}>
          <Grid item xs={12} sm={6}>
            {isEmpty(CompanyLoad) && (
              <Grid container spacing={2}>
                <Grid item xs={10} md={10}>
                  <Autocomplete
                    id="CompanyLoad"
                    sx={{minWidth: "100%"}}
                    options={options[0]}
                    autoComplete
                    clearText="Limpiar"
                    onChange={handleChangeAutoComplete}
                    onInputChange={handleChangeAutocompleteTextFieldLoad}
                    filterOptions={(x) => x}
                    includeInputInList
                    filterSelectedOptions
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Lugar de carga"
                          name="Load"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      );
                    }}
                    getOptionLabel={(option) => {
                      if (!option) {
                        return "";
                      }

                      return (
                        option?.Address?.description ||
                        option?.Address?.FullAddress
                      );
                    }}
                    renderOption={(props, option, state) => {
                      return (
                        <h4 {...props}>{`${
                          bestCaseOptionLocation(option) ||
                          option?.Address?.description ||
                          option?.Address?.FullAddress ||
                          option?.TaxName ||
                          option?.Address?.ZipCode ||
                          option?.Rfc ||
                          "Opción sin detalle"
                        }`}</h4>
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      if (value === "") {
                        return true;
                      }

                      return option.TaxName === value.TaxName;
                    }}
                    value={!isEmpty(CompanyLoad) ? CompanyLoad : null}
                  />
                </Grid>

                {handleShowForm && (
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    <Tooltip title="Añadir nuevo destino">
                      <IconButton
                        variant="contained"
                        onClick={handleShowNewLoadingCompanyForm}
                        color="primary"
                      >
                        <AddLocationAltIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            )}

            {!isEmpty(CompanyLoad) && CompanyLoad.Rfc && (
              <Grid container spacing={1} columns={12}>
                <Grid item xs={12}>
                  <DestinationCompanyPaper
                    company={CompanyLoad}
                    type={"load"}
                    handleClose={handleDeleteLoadDestinationCompany}
                    handleEdit={handleShowNewLoadingCompanyForm}
                  />
                </Grid>

                <Grid item xs={12} sx={{marginTop: "2vh"}}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDateTimePicker
                      id="l_date"
                      label="Fecha y hora de carga"
                      value={new Date(dateTimeLoad || "")}
                      onChange={handleSetDateTimeLoad}
                      sx={{width: "100%"}}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {isEmpty(CompanyUnload) && (
              <Grid container spacing={2}>
                <Grid item xs={10} md={10}>
                  <Autocomplete
                    id="CompanyUnload"
                    sx={{minWidth: "100%"}}
                    options={options[1]}
                    autoComplete
                    clearText="Limpiar"
                    onChange={handleChangeSelection}
                    onInputChange={handleChangeAutocompleteTextFieldUnload}
                    filterOptions={(x) => x}
                    includeInputInList
                    filterSelectedOptions
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Lugar de descarga"
                          name="Load"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      );
                    }}
                    getOptionLabel={(option) => {
                      if (!option) {
                        return "";
                      }

                      return (
                        option?.Address?.description ||
                        option?.Address?.FullAddress
                      );
                    }}
                    renderOption={(props, option, state) => {
                      return (
                        <h4 {...props}>{`${
                          bestCaseOptionLocation(option) ||
                          option?.Address?.description ||
                          option?.Address?.FullAddress ||
                          option?.TaxName ||
                          option?.Address?.ZipCode ||
                          option?.Rfc ||
                          "Opción sin detalle"
                        }`}</h4>
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      if (value === "") {
                        return true;
                      }

                      return option.TaxName === value.TaxName;
                    }}
                    value={!isEmpty(CompanyUnload) ? CompanyUnload : null}
                  />
                </Grid>

                {handleShowForm && (
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    <Tooltip title="Añadir nuevo destino">
                      <IconButton
                        variant="contained"
                        onClick={handleShowNewUnloadingCompanyForm}
                        color="primary"
                      >
                        <AddLocationAltIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            )}

            {!isEmpty(CompanyUnload) && CompanyUnload.Rfc && (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <DestinationCompanyPaper
                    company={CompanyUnload}
                    type={"unload"}
                    handleClose={handleDeleteUnloadDestinationCompany}
                    handleEdit={handleShowNewUnloadingCompanyForm}
                  />
                </Grid>
                <Grid item xs={12} sx={{marginTop: "2vh"}}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDateTimePicker
                      id="u_date"
                      label="Fecha y hora de descarga"
                      value={new Date(dateTimeUnload || "")}
                      onChange={handleSetDateTimeUnload}
                      sx={{width: "100%"}}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {CompanyLoad.Address.lattitude &&
          CompanyLoad.Address.longitude &&
          CompanyUnload.Address.lattitude &&
          CompanyUnload.Address.longitude &&
          route && (
            <Grid
              item
              xs={12}
              sx={{width: "100%", height: "40vh", marginY: "2vh"}}
            >
              <GoogleMapsWrapper directions={route} />
            </Grid>
          )}
      </Grid>
    </>
  );
};

export default DestinationCompanies;
