import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import {styled} from "@mui/material/styles";
import {TextField, Typography, Grid, Button} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {LocalizationProvider, DatePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const PolicyComponent = forwardRef(({transport, handleChange}, ref) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("Póliza no seleccionada");

  //IF HAVE PREVIUS POLICY, WE MUST SHOW THE NAME
  useEffect(() => {
    // Check if transport.Policy.Document exists before accessing its properties
    if (transport.Policy && transport.Policy.Document) {
      setFileName(transport.Policy.Document.Name);
    }
  }, [transport.Policy]);

  const handleDateChangeExpiryDate = (stringDate) => {
    transport.Policy.ExpiryDate = new Date(stringDate).toISOString();
  };
  const handleDateChangePrimaCost = (stringDate) => {
    transport.Policy.PrimaCost = new Date(stringDate).toISOString();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setFileName(file.name);
    console.log(file);
  };

  useImperativeHandle(ref, () => ({
    getFile: () => selectedFile,
  }));

  return (
    <>
      <Typography variant="h6" align="center" color="primary.dark">
        Información de la poliza
      </Typography>

      <Grid container spacing={1} sx={{mt: "1vh"}}>
        <Grid item xs={12} sx={{marginTop: "4vh"}}>
          <TextField
            onChange={handleChange}
            id="Policy.InsuranceCompany"
            label="Empresa Aseguradora"
            value={transport.Policy.InsuranceCompany}
            InputLabelProps={{
              shrink: Boolean(transport.Policy.InsuranceCompany),
            }}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={handleChange}
            id="Policy.NoPoliza"
            label="Número de Poliza"
            value={transport.Policy.NoPoliza}
            InputLabelProps={{
              shrink: Boolean(transport.Policy.NoPoliza),
            }}
            variant={"standard"}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="Policy.ExpiryDate"
              name="Policy.ExpiryDate"
              label="Fecha Expiración"
              required
              slotProps={{textField: {variant: "standard"}}}
              sx={{width: "100%"}}
              value={
                transport.Policy.ExpiryDate
                  ? new Date(transport.Policy.ExpiryDate)
                  : ""
              }
              onChange={handleDateChangeExpiryDate}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="Policy.ExpiryDate"
              name="Policy.ExpiryDate"
              label="Fecha Ultima Renovación"
              slotProps={{textField: {variant: "standard"}}}
              sx={{width: "100%"}}
              //value={new Date(transport.Policy.ExpiryDate)}
              onChange={handleDateChangePrimaCost}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={6}>
          <TextField
            onChange={handleChange}
            id="Policy.PrimaCost"
            name="Policy.PrimaCost"
            label="Costo Prima"
            type="text"
            value={transport.Policy.PrimaCost}
            InputLabelProps={{
              shrink: Boolean(transport.Policy.PrimaCost),
            }}
            variant={"standard"}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={handleChange}
            id="Policy.TotalCost"
            name="Policy.TotalCost"
            label="Costo Total"
            type="text"
            value={transport.Policy.TotalCost}
            InputLabelProps={{
              shrink: Boolean(transport.Policy.TotalCost),
            }}
            variant={"standard"}
            fullWidth
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{display: "flex", justifyContent: "center", marginTop: "15px"}}
        >
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Seleccionar Póliza
            <VisuallyHiddenInput
              type="file"
              onChange={handleFileChange}
              accept=".pdf"
            />
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{display: "flex", justifyContent: "center", marginTop: "5px"}}
        >
          <Typography>{fileName}</Typography>
        </Grid>
      </Grid>
    </>
  );
});

export default PolicyComponent;
