import { API_CONTEXT } from "../invoices/invoices"

export const saveCustomer = (body, user, credential) => {
    const saveCustomerPromise = API_CONTEXT("organization","saveCustomer");
    return saveCustomerPromise(body, user)
}

export const editCustomer = (customerId, body, user, credential) => {
    const editCustomerPromise = API_CONTEXT("organization","editCustomer");
    return editCustomerPromise(customerId, body, user)
}

export const findCustomer = (user, customerId, credential, nameString, searchField) => {
    const getCustomersPromise = API_CONTEXT("organization", "findCustomer")
    return getCustomersPromise(user, customerId, credential, nameString, searchField)
}
export const searchCustomerByName = (user, credential, nameString, searchField) => {
    const getCustomersPromise = API_CONTEXT("customers", "searchName")
    return getCustomersPromise(user, credential, nameString, searchField)
}

export const deleteCustomer = (user, credential,id,body) => {
    const saveCustomerPromise = API_CONTEXT("organization","deleteCustomer");
    return saveCustomerPromise(user, credential, id, body)
}