import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { MarkAsPaidComponent } from "../../modules/invoices/postIssue/utils/PostIssueActionsComponents";
import { formatDateDDMMYYYY } from "../../utils/dateManipulators";

const InvoiceDetailsCard = ({ invoice, showActions }) => {
  const modalStyles = {
    container: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: 400,
      width: "90vw",
      backgroundColor: "white",
      border: "5px solid #000",
      boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.4)",
      padding: 16,
      display: "flex",
      flexDirection: "column",
    },
    section: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid #000",
      paddingBottom: 8,
      marginBottom: 8,
    },
  };
  const getTypeDescription = (type) => {
    switch (type) {
      case "N":
        return "Nómina";
      case "I":
        return "Ingreso";
      case "P":
        return "Pago";
      default:
        return "General";
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        {/* Folio */}
        <Grid
          item
          xs={12}
          style={{ justifyContent: "center", ...modalStyles.section }}
        >
          <Typography variant="h5">
            <b>Folio: {invoice.GeneralModel.Folio}</b>
          </Typography>
        </Grid>

        {/* Tipo */}
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <b>Tipo:</b>
          </Typography>
          <Typography variant="body1">
            {getTypeDescription(invoice.GeneralModel.CfdiType.Type)}
          </Typography>
        </Grid>

        {/* Fecha */}
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <b>Fecha:</b>
          </Typography>
          <Typography variant="body1">
            {invoice.GeneralModel.Date
              ? formatDateDDMMYYYY(invoice.GeneralModel.Date)
              : "Sin fecha"}
          </Typography>
        </Grid>

        {/* UUID In case of the invoice is "issued" */}
        {invoice.status === "issued" && invoice.uuid !== null ? (
          <Grid
            item
            xs={12}
            style={{flexDirection: "column" }}
          >
            <Typography variant="body1">
              <b>Folio fiscal:</b>
            </Typography>
            <Typography variant="body1">{invoice.uuid}</Typography>
          </Grid>
        ) : null}

        {/* Emisor */}
        <Grid
          item
          xs={12}
          sm={6}
          style={{flexDirection: "column" }}
        >
          <Typography variant="body1">
            <b>Emisor:</b>
          </Typography>
          <Typography variant="body1">
            {invoice.GeneralModel.Issuer.Name}
          </Typography>
          <Typography variant="body1">
            {invoice.GeneralModel.Issuer.Rfc}
          </Typography>
        </Grid>

        {/* Receptor */}
        <Grid
          item
          xs={12}
          sm={6}
          style={{ flexDirection: "column" }}
        >
          <Typography variant="body1">
            <b>Receptor:</b>
          </Typography>
          <Typography variant="body1">
            {invoice.GeneralModel.Receiver.Name}
          </Typography>
          <Typography variant="body1">
            {invoice.GeneralModel.Receiver.Rfc}
          </Typography>
        </Grid>

        {/* Descripción de Items */}
        {invoice.Models?.I?.Items && (
          <Grid item xs={12} style={{ marginBottom: 16 }}>
            {invoice.Models.I.Items.map((item, index) => (
              <React.Fragment key={index}>
                <Typography variant="body1">
                  {item.description} <br />
                  <b>Subtotal: </b> ${item.subtotal}
                </Typography>
                {item.taxes.map((tax, taxIndex) => (
                  <div key={taxIndex}>
                    <Typography variant="body1">
                      <b>
                        {tax?.Name || "IMPUESTO SIN NOMBRE"}: {tax.Rate}
                      </b>
                    </Typography>
                    <Typography variant="body1">
                      <b>
                        Total {tax.Name}: ${tax.Total}
                      </b>
                    </Typography>
                  </div>
                ))}
              </React.Fragment>
            ))}
          </Grid>
        )}

        {/* Total */}
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "auto",
          }}
        >
          <Typography variant="body1">
            <b>Total: ${invoice?.Total}</b>
          </Typography>
        </Grid>

        {/* Botón */}
        {invoice.Models?.I?.Items &&
          showActions &&
          invoice.Status?.paymentStatus === "unpaid" && (
            <Grid item xs={12} style={{ marginTop: 16, textAlign: "center" }}>
              <MarkAsPaidComponent invoiceId={invoice._id} />
            </Grid>
          )}
      </Grid>
    </>
  );
};

export { InvoiceDetailsCard };
