import { useEffect, useMemo, useRef, useState } from "react";
import { TimerCC } from "./utils/components/TimerComponent";
import { Box, Grid } from "@mui/material";
import {
  getServiceById,
  updateService,
  updateTransportService,
} from "../../services/modules/services/servicesGeneral";
import useAuth from "../../hooks/useAuth";
import { toIsoDate } from "../../utils/dateManipulators";
import { DisplayDragAndDrop } from "../../components/DragAndDrop/DragAndDrop";
import ProfilePic from "../admin/dashboard/LogoComponent";
import { ImageUpload } from "./utils/components/EvidenceUpload";
import { isEmptyObject } from "./utils/ServiceValidationFunctions";

export const ActiveServiceManeuverView = ({
  activeServiceId,
  setActiveService,
  type,
}) => {
  const { userid } = useAuth();

  const [activeServiceObj, setActiveServiceObj] = useState({});

  const [waitTimerProps, setWaitTimerProps] = useState({
    label: "Espera Ingreso",
    onStart: () => onWaitStart(),
    onStop: () => onWaitStop(),
    canStart: false,
  });

  const [maneuverTimerProps, setManeuverTimerProps] = useState({
    label: type === "Unload" ? "Espera Descarga" : "Espera Carga",
    onStart: () => onLoadingStart(),
    onStop: () => onLoadingStop(),
    canStart: false,
  });

  const [evidenceTimerProps, setEvidenceTimerProps] = useState({
    label: "Espera de Evidencias",
    onStart: () => onEvidenceStart(),
    onStop: () => onEvidenceStop(),
    canStart: false,
  });

  const [filledTimes, setFilledTimes] = useState({
    accessStart: false,
    accessEnd: false,
    loadStart: false,
    loadEnd: false,
    evidenceStart: false,
    evidenceEnd: false,
  });

  const createPayload = (obj, param, value) => {
    return {
      [type]: {
        ...obj?.TransportService[type],
        [param]: value,
      },
    };
  };

  const onLoadingStart = async () => {
    try {
      // Fetch the data before executing the logic
      let obj = await getServiceById(userid, userid, activeServiceId);
      obj = obj.data; // Access the data property of the response
      let filledObj = calculateTimeConditionals(obj);

      if (filledObj.accessStart && filledObj.accessEnd) {
        if (!filledObj.loadStart) {
          let newDate = new Date();
          let isoDate = toIsoDate(newDate);

          updateTransportService(userid, userid, activeServiceId, {
            [type]: {
              ...obj?.TransportService[type],
              StartedWaiting: isoDate,
            },
          })
            .then((res) => {
              setActiveServiceObj({
                ...activeServiceObj,
                TransportService: res.data,
              });
              setActiveService({
                ...activeServiceObj,
                TransportService: res.data,
              });
            })
            .catch((err) => {
              console.log("error al actualizar");
            });
        } else {
          if (filledObj.loadEnd) {
            onEvidenceStart();
          } else {
            console.log(
              "Escaping loading start because load has already started."
            );
          }
        }
      } else {
        console.log(
          "Escaping loading start because access has not both started and ended."
        );
        return;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onEvidenceStart = async () => {
    let newDate = new Date();
    let isoDate = toIsoDate(newDate);

    try {
      // Fetch the data before executing the logic
      let obj = await getServiceById(userid, userid, activeServiceId);
      obj = obj.data; // Access the data property of the response
      let filledObj = calculateTimeConditionals(obj);

      if (
        filledObj.accessStart &&
        filledObj.accessEnd &&
        filledObj.loadStart &&
        filledObj.loadEnd
      ) {
        if (!filledObj.evidenceStart) {
          updateTransportService(
            userid,
            userid,
            activeServiceId,
            createPayload(obj, "StartedWaitingDocs", isoDate)
          )
            .then((res) => {
              setActiveServiceObj({
                ...activeServiceObj,
                TransportService: res.data,
              });
              setActiveService({
                ...activeServiceObj,
                TransportService: res.data,
              });
            })
            .catch((err) => {
              console.log("error al actualizar");
            });
        } else {
          console.log("Evidence already started. Skipping evidence start.");
          if (filledObj.evidenceEnd) {
          }
        }
      } else {
        console.log("Escaping evidence start due to conditions not met.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onWaitStop = async () => {
    let newDate = new Date();
    let isoDate = toIsoDate(newDate);

    try {
      // Fetch the data before executing the logic
      let obj = await getServiceById(userid, userid, activeServiceId);
      obj = obj.data; // Access the data property of the response
      let filledObj = calculateTimeConditionals(obj);

      if (filledObj.accessStart) {
        updateTransportService(
          userid,
          userid,
          activeServiceId,
          createPayload(obj, "Accessed", isoDate)
        )
          .then((res) => {
            setWaitTimerProps({
              ...waitTimerProps,
              endTime: newDate,
            });
            setManeuverTimerProps({
              ...maneuverTimerProps,
              startTime: newDate,
            });
            setActiveServiceObj({
              ...activeServiceObj,
              TransportService: res.data,
            });
            setActiveService({
              ...activeServiceObj,
              TransportService: res.data,
            });
            onLoadingStart();
          })
          .catch((err) => {
            console.log("error al actualizar");
          });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onLoadingStop = async () => {
    let newDate = new Date();
    let isoDate = toIsoDate(newDate);

    try {
      // Fetch the data before executing the logic
      let obj = await getServiceById(userid, userid, activeServiceId);
      obj = obj.data; // Access the data property of the response
      let filledObj = calculateTimeConditionals(obj);

      if (
        filledObj.accessStart &&
        filledObj.accessEnd &&
        filledObj.loadStart &&
        !filledObj.loadEnd
      ) {
        updateTransportService(
          userid,
          userid,
          activeServiceId,
          createPayload(obj, "RealTime", isoDate)
        )
          .then((res) => {
            setManeuverTimerProps({
              ...maneuverTimerProps,
              endTime: newDate,
            });
            setEvidenceTimerProps({
              ...evidenceTimerProps,
              startTime: newDate,
            });
            setActiveServiceObj({
              ...activeServiceObj,
              TransportService: res.data,
            });
            setActiveService({
              ...activeServiceObj,
              TransportService: res.data,
            });
            onEvidenceStart();
          })
          .catch((err) => {
            console.log("error al actualizar");
          });
      } else {
        console.log("Escaping loading stop due to conditions not met.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onEvidenceStop = async () => {
    let newDate = new Date();
    let isoDate = toIsoDate(newDate);

    try {
      // Fetch the data before executing the logic
      let obj = await getServiceById(userid, userid, activeServiceId);
      obj = obj.data; // Access the data property of the response
      let filledObj = calculateTimeConditionals(obj);

      if (
        filledObj.accessStart &&
        filledObj.accessEnd &&
        filledObj.loadStart &&
        filledObj.loadEnd &&
        filledObj.evidenceStart
      ) {
        if (!filledObj.evidenceEnd) {
          updateTransportService(
            userid,
            userid,
            activeServiceId,
            createPayload(obj, "ReceivedDocs", isoDate)
          )
            .then((res) => {
              setEvidenceTimerProps({
                ...evidenceTimerProps,
                startTime: obj.TransportService[type].StartedWaitingDocs,
                endTime: isoDate,
              });
              setActiveService({
                ...activeServiceObj,
                TransportService: res.data,
              });
            })
            .catch((err) => {
              console.log("error al actualizar");
            });
        } else {
          console.log("Evidence already received. Skipping evidence stop.");
        }
      } else {
        console.log("Escaping evidence stop due to conditions not met.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getTransporServiceLoadTimes = (TransportService) => {
    console.log("Transport Service", TransportService);
    const { Load, UnLoad } = TransportService;

    let activeManeuver = type === "UnLoad" ? UnLoad : Load;

    console.log("activeManeuver", type, activeManeuver);

    let struct = { startTime: null, endTime: null };
    let accessTimes = { ...struct };
    let maneuverTimes = { ...struct };
    let evidenceTimes = { ...struct };

    accessTimes.startTime = activeManeuver?.StartedWaitingAccess;
    accessTimes.endTime = activeManeuver?.Accessed;

    maneuverTimes.startTime = activeManeuver?.StartedWaiting;
    maneuverTimes.endTime = activeManeuver?.RealTime;

    evidenceTimes.startTime = activeManeuver?.StartedWaitingDocs;
    evidenceTimes.endTime = activeManeuver?.ReceivedDocs;

    return { accessTimes, maneuverTimes, evidenceTimes };
  };
  const calculateTimeConditionals = (activeServiceObj) => {
    //state and object, but state was mostly useless
    let maneuverObj = activeServiceObj.TransportService[type];
    let finalObj = {
      accessStart: false,
      accessEnd: false,
      loadStart: false,
      loadEnd: false,
      evidenceStart: false,
      evidenceEnd: false,
    };
    if (maneuverObj.StartedWaitingAccess) {
      setFilledTimes({ ...filledTimes, accessStart: true });
      finalObj = { ...finalObj, accessStart: true };
    }
    if (maneuverObj.Accessed) {
      setFilledTimes({ ...filledTimes, accessEnd: true });
      finalObj = { ...finalObj, accessEnd: true };
    }
    if (maneuverObj.StartedWaiting) {
      setFilledTimes({ ...filledTimes, loadStart: true });
      finalObj = { ...finalObj, loadStart: true };
    }
    if (maneuverObj.RealTime) {
      setFilledTimes({ ...filledTimes, loadEnd: true });
      finalObj = { ...finalObj, loadEnd: true };
    }
    if (maneuverObj.StartedWaitingDocs) {
      setFilledTimes({ ...filledTimes, evidenceStart: true });
      finalObj = { ...finalObj, evidenceStart: true };
    }
    if (maneuverObj.ReceivedDocs) {
      setFilledTimes({ ...filledTimes, evidenceEnd: true });
      finalObj = { ...finalObj, evidenceEnd: true };
    }

    return finalObj;
  };
  const onWaitStart = (activeService) => {
    let newDate = new Date();
    let isoDate = toIsoDate(newDate);

    if (!activeService.TransportService[type].StartedWaitingAccess) {
      setWaitTimerProps((prevProps) => ({
        ...prevProps,
        startTime: newDate,
      }));

      if (!isEmptyObject(activeService)) {
        updateTransportService(
          userid,
          userid,
          activeServiceId,
          createPayload(activeService, "StartedWaitingAccess", isoDate)
        )
          .then((res) => {
            setActiveServiceObj((prevObj) => ({
              ...prevObj,
              TransportService: res.data,
            }));
            setActiveService((prevObj) => ({
              ...prevObj,
              TransportService: res.data,
            }));
          })
          .catch((err) => {
            console.log("error al actualizar");
          });
      }
    } else {
      if (filledTimes.accessStart && !filledTimes.accessEnd) {
        //onLoadingStart();
      } else if (filledTimes.accessStart && filledTimes.accessEnd) {
        onLoadingStart();
      }
    }
  };

  useEffect(() => {
    if (!isEmptyObject(activeServiceObj)) {
      let { accessTimes, maneuverTimes, evidenceTimes } =
        getTransporServiceLoadTimes(activeServiceObj.TransportService);

      console.log("times", { accessTimes, maneuverTimes, evidenceTimes });

      setWaitTimerProps({
        ...waitTimerProps,
        ...accessTimes,
      });

      setManeuverTimerProps({
        ...maneuverTimerProps,
        ...maneuverTimes,
      });

      setEvidenceTimerProps({
        ...evidenceTimerProps,
        ...evidenceTimes,
      });

      calculateTimeConditionals(activeServiceObj);
      onWaitStart(activeServiceObj);
    }
  }, [activeServiceObj]);

  useEffect(() => {
    getServiceById(userid, userid, activeServiceId)
      .then((res) => {
        setActiveServiceObj(res.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, []);

  return (
    <Box sx={{ width: "100%", minHeight: "50vh", outline: "4px" }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <TimerCC key={`${type}wait`} {...waitTimerProps} />
        </Grid>
        <Grid item xs={6} md={4}>
          <TimerCC key={`${type}load`} {...maneuverTimerProps} />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={6} md={12}>
            <TimerCC key={`${type}evidence`} {...evidenceTimerProps} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ImageUpload />
        </Grid>
      </Grid>
    </Box>
  );
};
