import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
//*Mui
import {
  Pagination,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
  Typography,
  Tooltip,
  Chip,
  Backdrop,
  Drawer,
  Menu,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OutboxIcon from "@mui/icons-material/Outbox";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AppsOutageIcon from "@mui/icons-material/AppsOutage";
import useAuth from "../../../../hooks/useAuth";
import { useNavigate } from "react-router";
import ferbyTheme from "../../../../theme/ferbyTheme";
import { getIssuedDocs } from "../../../../services/modules/invoices/organization";
import { formatDateDDMMYYYY } from "../../../../utils/dateManipulators";
import FilterDrawer from "../../../../components/Drawers/FilterDrawer";
import { searchIssuer } from "../../../../services/modules/invoices/issuers";
import { searchCustomerByName } from "../../../../services/modules/organization/customers";
import {
  DownloadInvoicePDFComponent,
  DownloadXMLComponent,
  MarkAsPaidComponent,
} from "../../postIssue/utils/PostIssueActionsComponents";
import {
  paidInvoice,
  putInvoice,
} from "../../../../services/modules/invoices/invoices";
import { ArrowDropDown } from "@mui/icons-material";
import { AlertDialog } from "../../../../components/userFeedback/AlertDialog";
import ActionsContent from "../../../../components/actions/InvoiceActionsContent";
import { InvoiceDetailsCard } from "../../../../components/Cards/InvoiceDetailsCard";

const RenderActions = (props) => {
  const [openOptionsModal, setOpenOptionsModal] = useState(false);
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const ref = React.useRef(null);
  const modalStyles = {
    container: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: 400,
      width: "90vw",
      backgroundColor: "white",
      border: "5px solid #000",
      boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.4)",
      padding: 16,
      display: "flex",
      flexDirection: "column",
    },
    section: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid #000",
      paddingBottom: 8,
      marginBottom: 8,
    },
  };

  const handleCloseDialogActions = (open) => {
    if (typeof open !== "boolean") {
      setOpenOptionsModal(!openOptionsModal);
      return;
    }
    setOpenOptionsModal(open);
  };

  const handleOpenActionsModal = () => {
    setOpenOptionsModal(true);
  };

  const handleCloseDescriptionModal = () => {
    setOpenDescriptionModal(false);
  };

  const handleOpenDescriptionModal = () => {
    console.log("RenderDescription", props.data.row);
    setOpenDescriptionModal(true);
  };

  const getTypeDescription = (type) => {
    switch (type) {
      case "N":
        return "Nómina";
      case "I":
        return "Ingreso";
      case "P":
        return "Pago";
      default:
        return "General";
    }
  };

  useImperativeHandle(ref, () => ({
    handleCloseDialogActions,
  }));

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Tooltip title="Acciones de factura" arrow>
              <IconButton onClick={handleOpenActionsModal}>
                <AppsOutageIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ver detalles" arrow>
              <IconButton onClick={handleOpenDescriptionModal}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </>
      )}

      <AlertDialog
        open={openOptionsModal}
        updateOpenState={handleCloseDialogActions}
        title="Acciones de factura"
        content={<ActionsContent {...props} ref={ref} />}
        actions={[
          {
            label: "Cerrar",
            execute: handleCloseDialogActions,
          },
        ]}
        keep={false}
      />

      <AlertDialog
        open={openDescriptionModal}
        content={<InvoiceDetailsCard invoice={props.data.row} showActions />}
        actions={[
          {
            label: "Cerrar",
            execute: handleCloseDescriptionModal,
          },
        ]}
        keep={false}
        title={"Detalles de factura"}
        updateOpenState={handleCloseDescriptionModal}
      />
    </div>
  );
};

const RenderDownload = (props) => {
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  const handleCloseDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  return (
    <div>
      <Dialog open={dialog.open} onClose={handleCloseDialog}>
        <DialogTitle>{dialog.title}</DialogTitle>

        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>
      </Dialog>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <DownloadInvoicePDFComponent
              iconView={true}
              cfdiData={props.data.row}
            />
            <DownloadXMLComponent cfdiData={props.data.row} iconView={true} />
          </Box>
        </>
      )}
    </div>
  );
};

const STATIC_COLUMNS = [
  {
    field: "Folio",
    headerName: "Folio",
    flex: 1,
    headerClassName: "header-table",
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      return params.row.GeneralModel.Folio
        ? params.row.GeneralModel.Folio
        : "sin folio"; /*<RenderDescription data={params}/>*/
    },
  },
  {
    field: "Date",
    headerName: "Fecha",
    flex: 1,
    headerClassName: "header-table",
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      return params.row.GeneralModel.Date
        ? formatDateDDMMYYYY(params.row.GeneralModel.Date, false)
        : "Sin fecha";
    },
  },
  {
    field: "TaxName",
    headerName: "Cliente",
    flex: 3,
    headerClassName: "header-table",
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      return params.row.GeneralModel.Receiver.Name
        ? params.row.GeneralModel.Receiver.Name
        : "sin nombre";
    },
  },
  {
    field: "RfcIssuer",
    headerName: "Emisor",
    flex: 2,
    headerClassName: "header-table",
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      return params.row.GeneralModel.Issuer.Rfc
        ? params.row.GeneralModel.Issuer.Rfc
        : "sin rfc"; /*<RenderDescription data={params}/>*/
    },
  },
  {
    field: "Status",
    headerName: "Estatus fiscal",
    flex: 1,
    headerClassName: "header-table",
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      const invoiceStatuses = {
        issued: { label: "Emitida", color: "success" },
        unissued: { label: "No emitida", color: "warning" },
        cancelled: { label: "Cancelada", color: "error" },
      };
      const { label: statusLabel, color: statusColor } = invoiceStatuses[
        params.row.Status?.issuedStatus
      ] || { label: "indefinido" };
      return (
        <Chip label={statusLabel} color={statusColor} variant="outlined" />
      );
    },
  },
  {
    field: "Status de pago",
    headerName: "Pago",
    flex: 1,
    headerClassName: "header-table",
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      const moneyStatuses = {
        paid: { label: "Pagada", color: "success" },
        pending: { label: "Pendiente", color: "warning" },
        unpaid: { label: "No pagada", color: "error" },
      };
      const { label: statusLabel, color: statusColor } =
        moneyStatuses[params.row?.Status?.paymentStatus] ||
        moneyStatuses.unpaid;
      return (
        <Chip label={statusLabel} color={statusColor} variant="outlined" />
      );
    },
  },
  {
    field: "_id",
    headerName: "Acciones",
    flex: 2,
    headerClassName: "header-table",
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <RenderActions data={params} allowed={["admin", "manager"]} />
          </Grid>
          <Grid item xs={6}>
            <RenderDownload data={params} />
          </Grid>
        </Grid>
      );
    },
  },
];

const INVOICE_TYPES = [
  {
    Name: "Ingreso",
    Value: "I",
  },
  {
    Name: "Pagos",
    Value: "P",
  },
  {
    Name: "Nómina",
    Value: "N",
  },
];

const ConsultInvoiceIndex = (props) => {
  const { userid } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [invoiceRows, setInvoiceRows] = useState([]);
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  const [appliedFilters, setAppliedFilters] = useState([]);
  //const [writtenFilter, setwrittenFilter] = useState([]);
  //const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);

  //Filter mods
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [options, setOptions] = useState({
    clients: [],
    issuerList: [],
    invoiceTypes: INVOICE_TYPES,
  });
  const [customerInput, setCustomerInput] = useState("");
  const [issuerInput, setIssuerInput] = useState("");
  const [invoiceTypeInput, setInvoiceTypeInput] = useState("");

  const [filters, setFilters] = useState([]);
  const [drawerFilters, setDrawerFilters] = useState([]);

  const today = new Date();
  const [range, setRange] = useState([
    new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
    today,
  ]);

  const defaultSelectedfilters = {
    client: {},
    issuer: {},
    invoiceType: {},
    folio: "",
    folioFiscal: "",
  };
  const [selectedFilters, setSelectedFilters] = useState(
    defaultSelectedfilters
  );

  const [selectedClientsArray, setSelectedClientsArray] = useState([]);
  const [selectedIssuersArray, setSelectedIssuersArray] = useState([]);
  const [selectedTypesArray, setSelectedTypesArray] = useState([]);
  const handleMarkAsPaid = (
    invoiceId,
    userid,
    dialogActions,
    setDialogActions
  ) => {
    console.log("INVOICE STATUS TO PAID...");
    paidInvoice(userid, userid, invoiceId)
      .then((res) => {
        const rowIndex = invoiceRows.findIndex((row) => row._id === invoiceId);

        if (rowIndex != -1) {
          const newInvoiceRows = [...invoiceRows];
          newInvoiceRows[rowIndex].Status.paymentStatus =
            res.data.Status.paymentStatus;
          setInvoiceRows(newInvoiceRows);
        }
      })
      .catch((err) => {
        setDialogActions({
          ...dialogActions,
          open: true,
          title: "Error al marcar factura como pagada",
          content: err.response.data.message,
          actions: [
            {
              label: "Ok",
              execute: () =>
                setDialogActions({ ...dialogActions, open: false }),
            },
          ],
        });
      });
  };

  const handleApplyStatus = (invoiceId, status) => {
    const rowIndex = invoiceRows.findIndex((row) => row._id === invoiceId);
    if (rowIndex != -1) {
      const newInvoiceRows = [...invoiceRows];
      newInvoiceRows[rowIndex].Status.paymentStatus = status;
      putInvoice(userid, userid, invoiceId, newInvoiceRows[rowIndex])
        .then((res) => {
          setInvoiceRows(newInvoiceRows);
        })
        .catch((err) => {
          setDialog({
            ...dialog,
            open: true,
            title: "Error al marcar factura como " + status,
            content: err?.response?.data?.message || "Error desconocido",
            actions: [
              {
                label: "Ok",
                execute: () => handleCloseDialog(),
              },
            ],
          });
        });
    }
  };

  const RenderFinishDraft = (props) => {
    const [loading, setLoading] = useState(false);

    const [canTimbrar, setCanTimbrar] = useState(true);
    const [dialog, setDialog] = useState({
      open: false,
      title: "",
      content: "",
      actions: [],
    });
    const [localCfdi, setLocalCfdi] = useState({});

    const { userid } = useAuth();

    const handleCloseDialog = () => {
      setDialog({
        ...dialog,
        open: false,
      });
    };
    const handleEditdraft = () => {
      setLoading(true);

      function mapInvoiceObject(sourceObject) {
        return sourceObject;
      }

      const sourceObject = localCfdi;
      const mappedInvoice = mapInvoiceObject(sourceObject);

      window.localStorage.setItem(
        "invoiceModel",
        JSON.stringify(mappedInvoice)
      );
      navigate(`/${userid.claims.rol}/invoices/new/steps`);
    };

    const handleTimbrado = () => {
      setLoading(true);
    };

    useEffect(() => {
      getIssuedDocs(userid, userid, 1, [
        { param: "_id", value: props.data.row._id },
      ])
        .then((res) => {
          setCanTimbrar(true);
          setLocalCfdi(res.data.data[0]);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setCanTimbrar(false);
          setLoading(false);
        });
    }, []);

    return (
      <div>
        <Dialog open={dialog.open} onClose={handleCloseDialog}>
          <DialogTitle>{dialog.title}</DialogTitle>

          <DialogContent>
            <DialogContentText>{dialog.content}</DialogContentText>
          </DialogContent>
        </Dialog>

        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
              <Tooltip title="Completar Factura" arrow>
                <IconButton onClick={handleEditdraft}>
                  <ReceiptIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Emitir" arrow>
                <IconButton onClick={handleTimbrado}>
                  <OutboxIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        )}
      </div>
    );
  };
  const invoiceColumns = [
    {
      field: "Folio",
      headerName: "Folio",
      flex: 1,
      headerClassName: "header-table",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.GeneralModel.Folio
          ? params.row.GeneralModel.Folio
          : "-"; /*<RenderDescription data={params}/>*/
      },
    },
    {
      field: "Date",
      headerName: "Fecha",
      flex: 1,
      headerClassName: "header-table",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.GeneralModel.Date
          ? formatDateDDMMYYYY(params.row.GeneralModel.Date, false)
          : "Sin fecha";
      },
    },
    {
      field: "TaxName",
      headerName: "Cliente",
      flex: 3,
      headerClassName: "header-table",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.GeneralModel?.Receiver?.Name ||
          params.row.GeneralModel?.Receiver?.TaxName
          ? params.row.GeneralModel?.Receiver?.Name ||
              params.row.GeneralModel?.Receiver?.TaxName
          : "-";
      },
    },
    {
      field: "RfcIssuer",
      headerName: "Emisor",
      flex: 2,
      headerClassName: "header-table",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.GeneralModel?.Issuer?.Rfc
          ? params.row.GeneralModel.Issuer.Rfc
          : "-"; /*<RenderDescription data={params}/>*/
      },
    },
    {
      field: "Status",
      headerName: "Estatus fiscal",
      flex: 1,
      headerClassName: "header-table",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const invoiceStatuses = {
          issued: { label: "Emitida", color: "success" },
          unissued: { label: "No emitida", color: "warning" },
          cancelled: { label: "Cancelada", color: "error" },
        };
        const { label: statusLabel, color: statusColor } = invoiceStatuses[
          params.row.Status?.issuedStatus
        ] || { label: "indefinido" };
        return (
          <Chip label={statusLabel} color={statusColor} variant="outlined" />
        );
      },
    },
    {
      field: "Status de pago",
      headerName: "Pago",
      flex: 1,
      headerClassName: "header-table",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const moneyStatuses = {
          paid: { label: "Pagada", color: "success" },
          pending: { label: "Pendiente", color: "warning" },
          unpaid: { label: "No pagada", color: "error" },
        };
        const { label: statusLabel, color: statusColor } =
          moneyStatuses[params.row?.Status?.paymentStatus] ||
          moneyStatuses.unpaid;

        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
          setAnchorEl(null);
        };

        return (
          <>
            <Tooltip
              title={
                statusLabel != moneyStatuses.paid.label
                  ? `Marcar como pagada`
                  : ""
              }
            >
              <Chip
                clickable={statusLabel != moneyStatuses.paid.label}
                onClick={() =>
                  statusLabel != moneyStatuses.paid.label
                    ? handleMarkAsPaid(
                        params.row._id,
                        userid,
                        dialog,
                        setDialog
                      )
                    : null
                }
                label={statusLabel}
                color={statusColor}
                variant="outlined"
              />
            </Tooltip>
            <IconButton onClick={handleClick}>
              <ArrowDropDown />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {Object.entries(moneyStatuses).map(([status, { label }]) => (
                <MenuItem
                  onClick={(e) => handleApplyStatus(params.row._id, status)}
                  key={status}
                >
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </>
        );
      },
    },
    {
      field: "_id",
      headerName: "Acciones",
      flex: 2,
      headerClassName: "header-table",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <RenderActions data={params} allowed={["admin", "manager"]} />
            </Grid>
            {params.row.Status?.issuedStatus !== "unissued" ? (
              <Grid item xs={6}>
                <RenderDownload data={params} />
              </Grid>
            ) : (
              <Grid item xs={6}>
                <RenderFinishDraft data={params} />
              </Grid>
            )}
          </Grid>
        );
      },
    },
  ];

  const toggleFilterDrawer = () => {
    setFilterDrawerOpen(!filterDrawerOpen);
  };

  const handleApplyDrawerFilters = (selectedFilters) => {
    setSelectedFilters(selectedFilters);
    handleApplyFilters();
    setFilterDrawerOpen(false);
  };

  //update options based on input
  useEffect(() => {
    if (customerInput.length > 3) {
      searchCustomerByName(userid, userid, customerInput, "TaxName")
        .then((res) => {
          if (res.data.data.length > 0) {
            setOptions({ ...options, clients: res.data.data });
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      searchCustomerByName(userid, userid, customerInput, "Rfc")
        .then((res) => {
          if (res.data.data.length > 0) {
            setOptions({ ...options, clients: res.data.data });
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [customerInput]);

  useEffect(() => {
    if (issuerInput.length > 3) {
      searchIssuer(userid, userid, issuerInput, "Name")
        .then((res) => {
          if (res.data.data.length > 0) {
            setOptions({ ...options, issuerList: res.data.data });
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
      searchIssuer(userid, userid, issuerInput, "Rfc")
        .then((res) => {
          if (res.data.data.length > 0) {
            setOptions({ ...options, issuerList: res.data.data });
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [issuerInput]);
  //End Filter mods

  const resetFilters = () => {
    setDialog({
      ...dialog,
      open: false,
      title: "",
      content: "",
      actions: [],
    });
    setAppliedFilters([]);
    setDrawerFilters([]);
    setSelectedFilters(defaultSelectedfilters);
    toggleFilterDrawer();
    handleApplyFilters();
    fetchInvoices(1, []);
    localStorage.removeItem("appliedFilters");
  };

  const handleCloseDialog = (e, r) => {
    setDialog({ ...dialog, open: false, title: "", content: "", actions: [] });
  };

  const handleApplyFilters = () => {
    setLoading(true);

    const appliedAllFilters = [...filters, ...drawerFilters];

    localStorage.setItem("appliedFilters", JSON.stringify(appliedAllFilters));

    setAppliedFilters(appliedAllFilters);

    if (currentPage === 1) {
      fetchInvoices(currentPage, appliedAllFilters);
    } else {
      setCurrentPage(1);
    }
  };

  function fetchInvoices(pageNumber, otherFilters) {
    getIssuedDocs(
      userid,
      userid,
      pageNumber,
      otherFilters ? otherFilters : appliedFilters
    )
      .then((response) => {
        console.log(response);
        setLoading((l) => false);
        if (response.data.data.length === 0) {
          setDialog({
            open: true,
            title: "Sin facturas",
            content: "No se encontraron facturas",
            actions: [
              {
                label: "Recargar",
                execute: () => window.location.reload(),
              },
              {
                label: "Salir",
                execute: () => navigate(`/${userid.claims.rol}/welcome`),
              },
              {
                label: "Ok",
                execute: () => setDialog({ ...dialog, open: false }),
              },
            ],
          });
        } else {
          const { data, total_pages, total_records } = response.data;
          setTotalPages(total_pages || 1);
          setTotalRows(total_records);

          // Process the received data and update the DataGrid
          const invoicesData = data;
          const filteredInvoiceRows = invoicesData.filter(
            (item) => item._id !== null
          );
          setInvoiceRows((ir) => filteredInvoiceRows);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading((l) => false);
        if (err.response) {
          switch (err.response.status) {
            case 401:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "No tienes autorización",
                  content:
                    "No puedes ver esta información. Serás retirado de aquí",
                };
              });

              setTimeout(() => {
                navigate("/");
              }, 5000);
              break;
            case 500:
              setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "Error interno",
                  content:
                    "No pudimos obtener las facturas debido a un error interno. Reintenta recargando la página, si el error persiste, contacta a soporte",
                  actions: [
                    {
                      label: "Reintentar",
                      execute: () => window.location.reload(),
                    },
                  ],
                };
              });
              break;
            default:
              break;
          }
          return;
        }
        setDialog((dialog) => {
          return {
            ...dialog,
            open: true,
            title: "Error",
            content:
              "Hay un problema con la petición. Verifica tu internet o contacta a soporte.",
            actions: [
              {
                label: "Ok",
                execute: () => window.location.reload(),
              },
            ],
          };
        });
        setLoading((l) => false);
      });
  }

  useEffect(() => {
    // Retrieve filters from localStorage
    const savedFilters = localStorage.getItem("appliedFilters");

    if (savedFilters) {
      setAppliedFilters(JSON.parse(savedFilters));
      setSelectedFilters(JSON.parse(savedFilters));
    }

    fetchInvoices(1, JSON.parse(savedFilters));
  }, []);

  useEffect(() => {
    // Retrieve filters from localStorage
    const savedFilters = localStorage.getItem("appliedFilters");

    if (savedFilters) {
      setAppliedFilters(JSON.parse(savedFilters));
    }

    fetchInvoices(currentPage, JSON.parse(savedFilters));
  }, [currentPage]);

  return (
    <div>
      <Dialog keepMounted={true} open={dialog.open} onClose={handleCloseDialog}>
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.actions.map((action, index) => {
            console.log(action);
            return (
              <Button key={index} variant="contained" onClick={action.execute}>
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>

      <Backdrop open={loading}>
        <CircularProgress />
      </Backdrop>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: props.paddingTop || "10vh",
          "& .header-table": {
            backgroundColor: ferbyTheme.palette.primary.main,
            color: "white",
          },
        }}
      >
        <Grid container maxWidth={"xl"} spacing={2}>
          <Grid item xs={12} md={8} sx={{ textAlign: "left", padding: "3%" }}>
            <Typography variant="h4" color="primary">
              Consultar Facturas
            </Typography>
          </Grid>
          <Grid item xs={6} md={2} sx={{ textAlign: "right", padding: "3%" }}>
            <Button
              variant="contained"
              onClick={() => navigate(`/${userid.claims.rol}/invoices/new`)}
            >
              Nueva Factura
            </Button>
          </Grid>
          {/* New filters component */}
          <Grid item xs={6} md={2} sx={{ textAlign: "right", padding: "3%" }}>
            <Button variant="contained" onClick={toggleFilterDrawer}>
              Filtrar
            </Button>
          </Grid>
          {/* Filter Drawer */}
          <Drawer
            anchor="right"
            open={filterDrawerOpen}
            onClose={toggleFilterDrawer}
            PaperProps={{
              sx: { width: { xs: "95%", sm: "50%", lg: "33%", xl: "20%" } },
            }}
          >
            <FilterDrawer
              options={options}
              filters={drawerFilters}
              setFilters={setDrawerFilters}
              selected={selectedFilters}
              setSelected={setSelectedFilters}
              selectedClientsArray={selectedClientsArray}
              setSelectedClientsArray={setSelectedClientsArray}
              selectedIssuersArray={selectedIssuersArray}
              setSelectedIssuersArray={setSelectedIssuersArray}
              selectedTypesArray={selectedTypesArray}
              setSelectedTypesArray={setSelectedTypesArray}
              toggleFilterDrawer={toggleFilterDrawer}
              rango={range}
              setRango={setRange}
              customerInput={customerInput}
              setCustomerInput={setCustomerInput}
              issuerInput={issuerInput}
              setIssuerInput={setIssuerInput}
              invoiceTypeInput={invoiceTypeInput}
              setInvoiceTypeInput={setInvoiceTypeInput}
            />
            {/* Apply and Cancel buttons */}
            <Box
              sx={{
                display: "flex",
                marginTop: "2%",
                padding: "4%",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                sx={{ color: "white", height: "60px" }}
                onClick={() => handleApplyDrawerFilters(selectedFilters)}
              >
                Aplicar filtros
              </Button>
              <Button
                disabled={loading}
                variant="contained"
                color="error"
                sx={{ color: "white", height: "60px" }}
                onClick={() => resetFilters()}
              >
                Borrar filtros
              </Button>
            </Box>
          </Drawer>
          {/* End New filters component */}

          <Grid item xs={12} sx={{ minHeight: "60vh" }}>
            {invoiceRows.length > 0 && !loading ? (
              <>
                <DataGrid
                  autoHeight
                  hideFooter
                  columns={invoiceColumns}
                  rows={invoiceRows}
                  page={currentPage - 1}
                  onPageChange={(newPage) => setCurrentPage(newPage + 1)}
                  // pagination
                  pageSize={10}
                  rowCount={totalRows}
                  //keepNonExistentRowsSelected
                  paginationMode="server"
                  getRowId={(row, index) => {
                    return row._id;
                  }}
                  userid={userid}
                  //disableSelectionOnClick
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <Pagination
                    count={Math.ceil(totalRows / 10)}
                    page={currentPage}
                    onChange={(event, newPage) => setCurrentPage(newPage)}
                    siblingCount={3}
                    showFirstButton
                    showLastButton
                  />
                  <Typography sx={{ fontWeight: "bold" }}>
                    Facturas totales: {totalRows}
                  </Typography>
                </Box>
              </>
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ConsultInvoiceIndex;
