import {
  Alert,
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import useAuth from "../../../hooks/useAuth";
import {
  getPaymentForms,
  getPaymentMethods,
  getRegimes,
} from "../../../services/modules/invoices/catalogs";
import {
  getExpeditionPLC,
  getIssuers,
  getNextFolio,
  getSeriesByIssuer,
} from "../../../services/modules/invoices/issuers";
import {getCustomers} from "../../../services/modules/invoices/organization";
import {InitialDialogState} from "../../userFeedback/AlertDialog";
import {isEmptyObject} from "../../../modules/services/utils/ServiceValidationFunctions";
import {PaymentFormsDto} from "../../../utils/dto/paymentForm.dto";
import {FolioSerieForm} from "./FolioSerieForm";
import {isNotEmptyNullOrUndefined} from "../../../utils/validations/commonValidations";

/**
 * Represents a form component for managing issuer and customer data, specifically for issuing an invoice.
 * @component
 * @param {object} props - The props object.
 * @param {React.Ref} ref - The ref object.
 * @returns {JSX.Element} The IssuerAndCustomerForm component.
 */
const IssuerAndCustomerForm = forwardRef((props, ref) => {
  const invoiceJson = window.localStorage.getItem("invoiceModel")
    ? JSON.parse(window.localStorage.getItem("invoiceModel"))
    : null;

  const nextFolioMonitoringInterval = React.useRef(null);
  const {userid, organization} = useAuth();

  const [alert, setAlert] = useState({
    show: false,
    state: "info",
    message: "",
    duration: null,
  });

  const [options, setOptions] = useState({
    issuerList: [],
    seriesList: [],
    expeditionPlc: [],
    clients: [],
    paymentForms: [],
    paymentMethods: [],
    fiscalRegimes: [],
  });

  const [loading, setLoading] = useState({
    issuers: true,
    clients: true,
    expePlc: true,
    paymentMethods: true,
    paymentForms: true,
    fiscalRegimes: true,
  });

  const [selected, setSelected] = useState({
    issuer:
      invoiceJson && !isEmptyObject(invoiceJson.GeneralModel.Issuer)
        ? invoiceJson.GeneralModel.Issuer
        : "",
    place:
      invoiceJson && invoiceJson.GeneralModel.ExpeditionPlace
        ? invoiceJson.GeneralModel.ExpeditionPlace
        : "",
    client:
      invoiceJson && invoiceJson.GeneralModel.Receiver
        ? invoiceJson.GeneralModel.Receiver
        : "",
    paymentForm:
      invoiceJson && invoiceJson.GeneralModel.PaymentForm
        ? invoiceJson.GeneralModel.PaymentForm
        : "",
    paymentMethod:
      invoiceJson && invoiceJson.GeneralModel.PaymentForm
        ? invoiceJson.GeneralModel.PaymentMethod
        : "",
    Folio:
      invoiceJson && invoiceJson.GeneralModel.Folio
        ? invoiceJson.GeneralModel.Folio
        : null,
    Serie:
      invoiceJson && invoiceJson.GeneralModel.Serie
        ? invoiceJson.GeneralModel.Serie
        : null,
    SerieFolio:
      invoiceJson && invoiceJson.GeneralModel.Serie
        ? invoiceJson.GeneralModel.SerieFolio
        : null,
  });

  const [error, setError] = useState({
    Folio: {
      failed: false,
      message: "",
    },
    Serie: {
      failed: false,
      message: "",
    },
    SerieFolio: {
      failed: false,
      message: "",
    },
    issuer: {
      failed: false,
      message: "",
    },
    place: {
      failed: false,
      message: "",
    },
    client: {
      failed: false,
      message: "",
    },
    paymentForm: {
      failed: false,
      message: "",
    },
    paymentMethod: {
      failed: false,
      message: "",
    },
    FiscalRegime: {
      failed: false,
      message: "",
    },
  });
  const [disabled, setDisabled] = useState({
    issuer: false,
    place: false,
    client: false,
    paymentForm: false,
    paymentMethod: false,
    folio: false,
    serie: false,
  });

  const [dialog, setDialog] = useState(InitialDialogState);

  const circularProgressSize = 20;

  const handleErrorUi = (err, messageHash) => {
    let custom = "";
    if (err.response) {
      console.log(err.response);
      switch (err.response.status) {
        case 401:
          custom = messageHash ? messageHash[401] : "";
          setAlert({
            ...alert,
            show: true,
            state: "error",
            message:
              401 + " No tienes permisos para realizar esta acción. " + custom,
          });
          break;
        case 403:
          custom = messageHash ? messageHash[403] : "";
          setAlert({
            ...alert,
            show: true,
            state: "error",
            message:
              403 + " No tienes permisos para realizar esta acción. " + custom,
          });
          break;
        case 500:
          custom = messageHash ? messageHash[500] : "";
          setAlert({
            ...alert,
            show: true,
            state: "error",
            message:
              500 + " Ocurrió un error al realizar esta acción. " + custom,
          });
          break;
        case 404:
          custom = messageHash
            ? messageHash[404]
            : err.response.data?.message
            ? err.response.data.message
            : "";
          setAlert({
            ...alert,
            show: true,
            state: "error",
            message: 404 + " No se encontró el recurso solicitado. " + custom,
          });
          break;
        case 400:
          custom = messageHash ? messageHash[400] : "";
          setAlert({
            ...alert,
            show: true,
            state: "error",
            message:
              400 + " Ocurrió un error al realizar esta acción. " + custom,
          });
          break;
        default:
          break;
      }
    }
  };

  const handleChangeSelection = useCallback((e, v, r) => {
    const id = e.target.id.split("-")[0];
    if (r === "selectOption" && error[id].failed) {
      setError({
        ...error,
        [id]: {
          failed: false,
          message: "",
        },
      });
    }

    switch (r) {
      case "selectOption":
        if (id === "SerieFolio") {
          setSelected({
            ...selected,
            Serie: v.Serie,
            SerieFolio: v,
          });

          return;
        }

        //*TODO: If the input being selected is the fiscal regime of the issuer, validate that it is compatible with the issuer's data.
        if (id === "FiscalRegime") {
          setSelected({
            ...selected,
            issuer: {
              ...selected.issuer,
              FiscalRegime: v,
            },
          });
          return;
        }

        //*TODO: If the input being selected is the issuer, validate that its data is complete and correct for its use in an invoice.
        setSelected({
          ...selected,
          [id]: v,
        });
        break;
      case "clear":
        setSelected({
          ...selected,
          [id]: "",
        });
        break;
      case "input":
        setSelected({
          ...selected,
          [id]: v,
        });
        break;
    }
  });

  const getData = () => {
    setLoading((obj) => {
      return {
        ...obj,
        issuers: true,
        clients: true,
        expePlc: true,
        paymentForms: true,
        paymentMethods: true,
        fiscalRegimes: true,
      };
    });

    const promises = [
      getIssuers(userid),
      getCustomers(userid),
      getExpeditionPLC(userid),
      getRegimes(userid),
    ];

    if (withPayment) {
      promises.push(getPaymentMethods(userid));
      promises.push(getPaymentForms(userid));
    }

    Promise.all(promises)
      .then((responses) => {
        console.log(responses);
        setLoading((obj) => {
          return {
            ...obj,
            issuers: false,
            clients: false,
            expePlc: false,
            paymentForms: false,
            paymentMethods: false,
            fiscalRegimes: false,
          };
        });
        setOptions((options) => {
          let optionsHash = {
            ...options,
            issuerList: responses[0].data.data,
            clients: responses[1].data.data,
            expeditionPlc: responses[2].data,
            fiscalRegimes: responses[3].data,
          };

          if (withPayment) {
            optionsHash.paymentMethods = responses[4].data;
            optionsHash.paymentForms = responses[5].data;
          }

          return optionsHash;
        });
      })
      .catch((err) => {
        console.log(err);
        handleErrorUi(err);
        setLoading((obj) => {
          return {
            ...obj,
            issuers: false,
            clients: false,
            expePlc: false,
            paymentForms: false,
            paymentMethods: false,
            fiscalRegimes: false,
          };
        });
        setDisabled((obj) => {
          return {
            ...obj,
            issuer: true,
            place: true,
            client: true,
            paymentForm: true,
            paymentMethod: true,
          };
        });
        setAlert((prevAlert) => {
          return {
            ...prevAlert,
            message:
              prevAlert.message +
              " Se han deshabilitado los campos de selección",
          };
        });
      });
  };

  const requestNextFolio = () => {
    getNextFolio(userid, userid, {_id: selected.SerieFolio._id})
      .then((response) => {
        console.log(response);
        setSelected((prev) => {
          return {
            ...prev,
            Folio: response.data.FolioNumber,
          };
        });
      })
      .catch((err) => {
        console.log(err);
        setAlert({
          ...alert,
          show: true,
          state: "error",
          message: "Ocurrió un error al obtener el siguiente folio",
          duration: 1000,
        });
      });
  };

  const getAndSetNextFolio = () => {
    requestNextFolio();

    nextFolioMonitoringInterval.current = setInterval(() => {
      requestNextFolio();
    }, 1000);
  };

  const getAndSetSeries = () => {
    if (selected.issuer === "" || selected.issuer === null) {
      return;
    }

    const errorMessageHashStatus = {
      400: "Verifica que has configurado correctamente el emisor",
      404: "Verifica que has configurado correctamente el emisor. No se encontró ninguna serie",
      500: "Ocurrió un error al obtener las seris del emisor",
    };

    getSeriesByIssuer(userid, userid, selected.issuer._id)
      .then((response) => {
        if (response.status === 200) {
          setSelected((prev) => {
            return {
              ...prev,
              SerieFolio: "",
              Folio: "",
              Serie: "",
            };
          });
          setOptions((prev) => {
            return {
              ...prev,
              seriesList: response.data,
            };
          });
          setDisabled((prev) => {
            return {
              ...prev,
              serie: false,
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
        handleErrorUi(err, errorMessageHashStatus);
        setOptions((prev) => {
          return {
            ...prev,
            seriesList: [],
          };
        });
        setDisabled((prev) => {
          return {
            ...prev,
            serie: true,
          };
        });
      });
  };

  const handleInputErrorsUI = () => {
    let newError = {...error};
    if (selected.Folio === null || selected.Folio === "") {
      newError.Folio.failed = true;
      newError.Folio.message = "El folio es requerido";
    } else {
      newError.Folio.failed = false;
    }

    //*TODO: Implement this as a configuration in the organization
    // if (selected.Serie === null || selected.Serie === "") {
    //   newError.Serie.failed = true;
    //   newError.Serie.message = "La serie es requerido";
    // } else {
    //   newError.Serie.failed = false;
    // }

    if (selected.place === null || selected.place === "") {
      newError.place.failed = true;
      newError.place.message = "El lugar de expedición es requerido";
    }

    if (withPayment) {
      if (typeof selected.paymentForm !== "object") {
        newError.paymentForm.failed = true;
        newError.paymentForm.message = "La forma de pago es requerida";
      } else {
        newError.paymentForm.failed = false;
      }
      if (typeof selected.paymentMethod !== "object") {
        newError.paymentMethod.failed = true;
        newError.paymentMethod.message = "El método de pago es requerido";
      } else {
        newError.paymentMethod.failed = false;
      }
    }
    if (selected.issuer === null || selected.issuer === "") {
      newError.issuer.failed = true;
      newError.issuer.message = "El emisor es requerido";
    } else {
      newError.issuer.failed = false;
    }
    if (selected.place === null || selected.place === "") {
      newError.place.failed = true;
      newError.place.message = "El lugar de expedición es requerido";
    } else {
      newError.place.failed = false;
    }
    if (
      selected.client === null ||
      selected.client === "" ||
      selected.client?.Rfc === "" ||
      selected.client?.Rfc === null ||
      selected.client?.Rfc === undefined
    ) {
      newError.client.failed = true;
      newError.client.message = "El cliente es requerido";
    } else {
      newError.client.failed = false;
    }

    setError(newError);
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      let complete = true;

      if (selected.Folio === null || selected.Folio === "") {
        complete = false;
        return complete;
      }

      //*TODO: Implement this as a configuration in the organization
      // if (selected.Serie === null || selected.Serie === "") {
      //   complete = false;
      //   return complete;
      // }
      if (withPayment) {
        if (selected.paymentForm === null || selected.paymentForm === "") {
          complete = false;
          return complete;
        }
        if (selected.paymentMethod === null || selected.paymentMethod === "") {
          complete = false;
          return complete;
        }
      }
      if (selected.issuer === null || selected.issuer === "") {
        complete = false;
        return complete;
      }
      if (selected.place === null || selected.place === "") {
        complete = false;
        return complete;
      }
      if (
        selected.client === null ||
        selected.client === "" ||
        selected.client?.Rfc === "" ||
        selected.client?.Rfc === null ||
        selected.client?.Rfc === undefined
      ) {
        complete = false;
        return complete;
      }

      return complete;
    },
    showErrors: handleInputErrorsUI,
  }));

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert({
      ...alert,
      show: !alert.show,
    });
  };

  let address =
    selected.client !== "" &&
    selected.client?.Addresses &&
    selected?.client?.Addresses.length > 0
      ? selected.client.Addresses[0]
      : selected.client?.Address
      ? selected.client.Address
      : "";

  invoiceJson.GeneralModel.Issuer = selected.issuer;
  invoiceJson.GeneralModel.ExpeditionPlace = selected.place;
  invoiceJson.GeneralModel.Folio = parseInt(selected.Folio);
  invoiceJson.GeneralModel.Serie = selected.Serie;
  invoiceJson.GeneralModel.SerieFolio = selected.SerieFolio;
  invoiceJson.GeneralModel.PaymentForm =
    selected.paymentForm !== "" ? selected.paymentForm : undefined;
  invoiceJson.GeneralModel.PaymentMethod =
    selected.paymentMethod !== "" ? selected.paymentMethod : undefined;
  invoiceJson.GeneralModel.Receiver = {
    ...invoiceJson.GeneralModel.Receiver,
    ...selected.client,
    Address: address,
  };
  window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));

  const withPayment = invoiceJson.GeneralModel.CfdiType.Type !== "P";

  /**
   * Fetches data related to the specified `userid` and sets up initial states.
   * Upon unmounting or when the `userid` changes, it performs cleanup to reset dialog,
   * loading states, and options to their initial values.
   */
  useEffect(() => {
    getData(); // Fetch data based on the current `userid`.

    // Cleanup function to reset states upon component unmount or `userid` change.
    return () => {
      // Resets dialog state to default values.
      setDialog((d) => ({
        ...d,
        open: false,
        title: "",
        message: "",
        actions: [],
      }));

      // Resets loading indicators for various data categories to false.
      setLoading(() => ({
        issuers: false,
        clients: false,
        expePlc: false,
        paymentMethods: false,
        paymentForms: false,
        fiscalRegimes: false,
      }));

      // Clears options arrays for different entities.
      setOptions((options) => ({
        ...options,
        issuerList: [],
        expeditionPlc: [],
        clients: [],
        paymentForms: [],
        paymentMethods: [],
        fiscalRegimes: [],
      }));
    };
  }, [userid]); // Dependency array includes `userid`.

  /**
   * Disables folio input if the organization has `AutomaticFolioManagement` enabled.
   * This effect responds to changes in the `organization` object.
   */
  useEffect(() => {
    // Check if AutomaticFolioManagement is enabled in the organization's fiscal configuration.
    if (organization?.Config?.Fiscal?.AutomaticFolioManagement) {
      // If enabled, set folio-related UI elements to disabled.
      setDisabled((d) => ({
        ...d,
        folio: true,
      }));
    }
  }, [organization]); // Dependency array includes `organization`.

  /**
   * Fetches and sets series data related to the selected issuer if the organization has
   * `AutomaticFolioManagement` enabled and an issuer is selected.
   */
  useEffect(() => {
    // Conditional execution based on organization configuration and if an issuer is selected.
    if (
      organization?.Config?.Fiscal?.AutomaticFolioManagement &&
      isNotEmptyNullOrUndefined(selected.issuer)
    ) {
      getAndSetSeries(); // Fetch and set series data for the selected issuer.
    }
  }, [selected.issuer]); // Dependency array includes `selected.issuer`.

  /**
   * Fetches and sets the next folio number if the organization has enabled
   * `AutomaticFolioManagement` and a series folio is selected. This effect monitors
   * changes to the `selected.SerieFolio` to ensure the folio number is updated
   * accordingly. It also sets up a cleanup mechanism to clear any intervals
   * established during the lifecycle of this effect, ensuring no memory leaks or
   * unintended repetitions of actions occur after the component unmounts or the
   * conditions no longer apply.
   */
  useEffect(() => {
    // Conditional execution based on organization configuration and selected series folio.
    if (
      organization &&
      organization?.Config?.Fiscal?.AutomaticFolioManagement &&
      isNotEmptyNullOrUndefined(selected.SerieFolio)
    ) {
      getAndSetNextFolio(); // Fetch and set the next folio number for the selected series.
    }

    // Cleanup function to clear intervals when the component unmounts or the dependencies change.
    return () => {
      clearInterval(nextFolioMonitoringInterval.current); // Clear the interval using the stored reference.
    };
  }, [selected.SerieFolio]); // Dependency array includes `selected.SerieFolio`.

  /**
   * Updates the selected payment form based on the chosen payment method and persists
   * the current invoice model to local storage. This effect triggers whenever the
   * `selected.paymentMethod` changes. If the payment method is specifically set to "PPD",
   * the payment form is automatically set to a predefined value (`POR_DEFINIR` from
   * `PaymentFormsDto`). Regardless of the payment method condition, the current state of
   * the invoice model (`invoiceJson`) is serialized and stored in local storage to ensure
   * persistence across sessions or page reloads.
   */
  useEffect(() => {
    // Check if a payment method has been selected and if it's specifically "PPD".
    if (
      isNotEmptyNullOrUndefined(selected.paymentMethod) &&
      selected.paymentMethod?.Value === "PPD"
    ) {
      // Automatically set the payment form to `POR_DEFINIR` when "PPD" is selected.
      setSelected({
        ...selected,
        paymentForm: PaymentFormsDto.POR_DEFINIR,
      });
    }

    // Persist the current invoice model to local storage.
    window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));
  }, [selected.paymentMethod]); // Dependency array includes `selected.paymentMethod`.

  return (
    <Grid item xs={12} sm={10} sx={{marginBottom: "1rem"}}>
      <Grid item xs={12}>
        <Autocomplete
          id="issuer"
          options={options.issuerList}
          getOptionLabel={(option) => option.Name || ""}
          isOptionEqualToValue={(option, value) => {
            if (value === "") {
              return true;
            }
            return option.Name === value.Name;
          }}
          value={selected.issuer}
          onChange={handleChangeSelection}
          disabled={disabled.issuer}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Emisor"
              variant="outlined"
              error={error.issuer.failed}
              helperText={error.issuer.failed ? error.issuer.message : ""}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading.issuers && (
                      <CircularProgress size={circularProgressSize} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        {organization?.Config?.Fiscal?.DynamicIssuerRegime && (
          <Autocomplete
            id="FiscalRegime"
            options={options.fiscalRegimes}
            getOptionLabel={(option) => option.Name || ""}
            isOptionEqualToValue={(option, value) => {
              if (value === "") {
                return true;
              }
              return option.Name === value.Name;
            }}
            value={selected?.issuer?.FiscalRegime || ""}
            onChange={handleChangeSelection}
            disabled={disabled.issuer}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Regimen fiscal"
                variant="outlined"
                error={error.FiscalRegime.failed}
                helperText={
                  error.FiscalRegime.failed ? error.FiscalRegime.message : ""
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading.fiscalRegimes && (
                        <CircularProgress size={circularProgressSize} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      </Grid>

      <Grid container spacing={2} sx={{marginBottom: "1rem"}}>
        <FolioSerieForm
          errorManager={error}
          folio={selected.Folio}
          serie={selected.Serie}
          issuer={selected.issuer}
          serieFolio={selected.SerieFolio}
          options={options}
          loading={loading}
          organization={organization}
          handleChange={handleChangeSelection}
        />
      </Grid>

      <Grid container spacing={2} sx={{marginBottom: "1rem"}}>
        <Grid item xs={12} md={6} sx={{marginBottom: "1rem"}}>
          <Autocomplete
            id="place"
            sx={{minWidth: "100%"}}
            options={options.expeditionPlc}
            autoComplete
            clearText="Limpiar"
            onChange={handleChangeSelection}
            disabled={disabled.place}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  required
                  error={error.place.failed}
                  helperText={error.place.message}
                  label="Lugar de expedición (requerido)"
                  name="ExpeditionPlace"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading.expePlc ? (
                          <CircularProgress size={circularProgressSize} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              );
            }}
            getOptionLabel={(option) => option.Name || ""}
            renderOption={(props, option, state) => {
              return <h4 {...props}>{`${option.Name}`}</h4>;
            }}
            isOptionEqualToValue={(option, value) => {
              if (value === "") {
                return true;
              }

              return option.Name === value.Name;
            }}
            value={selected.place}
          />
        </Grid>

        <Grid item xs={12} md={6} sx={{marginBottom: "1rem"}}>
          <Autocomplete
            id="client"
            sx={{width: "100%"}}
            options={options.clients}
            getOptionLabel={(opt) => {
              return opt.TaxName || "";
            }}
            disabled={disabled.client}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  required
                  error={error.client.failed}
                  helperText={error.client.message}
                  label={"Cliente / receptor"}
                  sx={{width: "100%"}}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading.clients ? (
                          <CircularProgress size={circularProgressSize} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              );
            }}
            isOptionEqualToValue={(o, v) => {
              if (v === "") {
                return true;
              }

              return o.Name === v.Name;
            }}
            onChange={handleChangeSelection}
            value={selected.client}
          />
        </Grid>
      </Grid>

      {withPayment && (
        <Grid item xs={12} sx={{marginBottom: "1rem"}}>
          <Box sx={{marginTop: "2vh"}}>
            <Typography variant="h6" gutterBottom>
              Datos del pago
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  id="paymentMethod"
                  autoComplete
                  sx={{width: "100%"}}
                  options={options.paymentMethods}
                  getOptionLabel={(option) => {
                    return option.Name || "";
                  }}
                  disabled={disabled.paymentMethod}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        required
                        error={error.paymentMethod.failed}
                        helperText={error.paymentMethod.message}
                        sx={{width: "100%"}}
                        label="Método de pago"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading.paymentMethods ? (
                                <CircularProgress size={circularProgressSize} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    );
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (value === "") {
                      return true;
                    }

                    return option.Name === value.Name;
                  }}
                  onChange={handleChangeSelection}
                  value={selected.paymentMethod}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  id="paymentForm"
                  autoComplete
                  sx={{width: "100%"}}
                  disabled={
                    selected.paymentMethod.Value === "PPD" ||
                    disabled.paymentForm
                  }
                  options={options.paymentForms}
                  getOptionLabel={(option) => {
                    return option.Name || "";
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        required
                        error={error.paymentForm.failed}
                        helperText={error.paymentForm.message}
                        sx={{width: "100%"}}
                        label="Forma de pago"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading.paymentForms ? (
                                <CircularProgress size={circularProgressSize} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    );
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (value === "") {
                      return true;
                    }
                    return option.Name === value.Name;
                  }}
                  onChange={handleChangeSelection}
                  value={selected.paymentForm}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}

      {/* end New First Step */}

      <Snackbar
        open={alert.show}
        autoHideDuration={alert.duration || 10000}
        onClose={handleCloseAlert}
      >
        <Alert severity={alert.state}>{alert.message}</Alert>
      </Snackbar>
    </Grid>
  );
});

export {IssuerAndCustomerForm};
