import React, { useState, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material";

import useAuth from "../../../hooks/useAuth";
import { getBrands } from "../../../services/modules/brands/brands";

export default function InputSearchBrandsComponent({
  setTransport,
  transport,
}) {
  const { userid } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [brands, setBrands] = useState([]);

  let valueDefault =
    typeof transport.Specs.Brand === "object" ? transport.Specs.Brand : null;

  useEffect(() => {
    getBrands(userid, searchTerm).then((response) => {
      console.log(response);
      setBrands(response.data);
    });
  }, [searchTerm]);

  const handleSearchChange = (event, value) => {
    setSearchTerm(value);
  };

  const handlerAutocompleteChange = (e, v, r) => {
    if (r === "clear") {
      transport.Specs.Brand = "";
    } else {
      console.log(v);
      transport.Specs.Brand = v._id;
    }
    setTransport({ ...transport });
  };

  return (
    <>
      <Autocomplete
        freeSolo
        options={brands}
        getOptionLabel={(option) => option.Name}
        onChange={(e, v, r) => {
          handlerAutocompleteChange(e, v, r);
        }}
        onInputChange={handleSearchChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Marca"
            margin="normal"
            variant="standard"
          />
        )}
        value={valueDefault}
      />
    </>
  );
}
