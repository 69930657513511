import React, { forwardRef, useState } from "react";
//*MUI Components
import { Button, Grid } from "@mui/material";
//*Network, routing and API
import { PreviewCFDI } from "../Previews/PreviewTables.jsx";
import { IssueButtonComponent } from "../../modules/invoices/submodules/issueComponent/IssueComponent.jsx";

const loopObject = (obj) => {
  for (const [key, data] of Object.entries(obj)) {
    if (data !== "" || data !== undefined) {
      if (typeof data === "object") {
        if (Array.isArray(data)) {
          data.forEach((el) => {
            if (typeof el === "string" || typeof el === "number") {
              el = "";
            }
          });
        }
        loopObject(data);
      }
      if (typeof data === "string" || typeof data === "number") {
        obj[key] = "";
      }
    }
  }
};

const InvoiceGenerator = forwardRef((props, ref) => {
  const invoiceJson = JSON.parse(window.localStorage.getItem("invoiceModel"));
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    status: "",
    message: "success",
    open: false,
  });
  const [dialog, setDialog] = useState({
    title: "",
    open: false,
    content: "",
    actions: [],
  });

  function handleCloseDialog() {
    props.setDialog({
      ...dialog,
      open: false,
      message: "",
      actions: [],
      title: "",
      keep: false,
    });
  }

  const handlePreviewInvoice = () => {
    props.setDialog({
      open: true,
      title: "Previsualización",
      message: <PreviewCFDI CFDI={invoiceJson} />,
      actions: [
        {
          label: "Cerrar",
          execute: () => {
            handleCloseDialog();
          },
        },
        <IssueButtonComponent cfdi={invoiceJson} label={"Aceptar y Timbrar"} />,
      ],
    });
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignContent="center"
      width="40vw"
    >
      <Grid item xs={6}>
        <Button
          id="preview"
          onClick={handlePreviewInvoice}
          color="secondary"
          sx={{ color: "white" }}
          disabled={loading}
          variant="contained"
        >
          Previsualizar factura
        </Button>
      </Grid>
    </Grid>
  );
});

export { InvoiceGenerator };
