import {Autocomplete, CircularProgress, Grid, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";

const FolioSerieForm = ({
  organization,
  issuer,
  serie,
  folio,
  serieFolio,
  errorManager,
  options,
  loading,
  handleChange,
  style = {
    SerieFolio: {
      Grid: {
        xs: 12,
        md: 6,
      },
    },
    Serie: {
      Grid: {
        xs: 12,
        md: 6,
      },
    },
    Folio: {
      Grid: {
        xs: 12,
        md: 6,
      },
    },
  },
}) => {
  const circularProgressSize = 20;

  const [disabled, setDisabled] = useState({
    serie: false,
    folio: false,
  });

  const handleChangeSelection = (e, value, type) => {
    handleChange(e, value, type);
  };

  useEffect(() => {
    if (
      organization &&
      organization?.Config?.Fiscal?.AutomaticFolioManagement
    ) {
      setDisabled((d) => {
        return {
          ...d,
          folio: true,
        };
      });
    }
  }, [organization]);

  return (
    <>
      <Grid item xs={style.SerieFolio.Grid.xs} md={style.SerieFolio.Grid.md}>
        {organization &&
          organization?.Config?.Fiscal.AutomaticFolioManagement && (
            <Autocomplete
              options={options.seriesList}
              getOptionLabel={(option) => option.Serie || ""}
              id="SerieFolio"
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    required={
                      organization?.Config?.Fiscal.AutomaticFolioManagement
                    }
                    error={errorManager.Serie.failed}
                    helperText={errorManager.Serie.message}
                    label="Serie"
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading.issuers ? (
                            <CircularProgress size={circularProgressSize} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              disabled={disabled.serie}
              onChange={handleChangeSelection}
              value={serieFolio}
              isOptionEqualToValue={(o, v) => {
                if (v === "") {
                  return true;
                } else {
                  return o.Serie === v.Serie;
                }
              }}
            />
          )}

        {organization &&
          !organization?.Config?.Fiscal.AutomaticFolioManagement && (
            <TextField
              label="Serie (opcional)"
              id="Serie"
              variant="standard"
              error={errorManager.Serie.failed}
              helperText={errorManager.Serie.message}
              sx={{minWidth: "100%"}}
              inputProps={{maxLength: 10}}
              onChange={(e) =>
                handleChangeSelection(e, e.target.value, "input")
              }
              value={serie}
              disabled={disabled.serie}
            />
          )}
      </Grid>
      <Grid item xs={style.SerieFolio.Grid.xs} md={style.SerieFolio.Grid.md}>
        {/* //*TODO: Make the Folio field an optional one. But configurable to make it required */}
        <TextField
          required
          label="Folio"
          id="Folio"
          variant="standard"
          type="number"
          error={errorManager.Folio.failed}
          helperText={errorManager.Folio.message}
          sx={{minWidth: "100%"}}
          inputProps={{maxLength: 10}}
          onChange={(e) => handleChangeSelection(e, e.target.value, "input")}
          value={folio}
          disabled={disabled.folio}
        />
      </Grid>
    </>
  );
};

export {FolioSerieForm};
