import { Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import {
  getInvoiceFiles,
  paidInvoice,
} from "../../../../services/modules/invoices/invoices";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CodeIcon from "@mui/icons-material/Code";
import React, { useState } from "react";
import {
  AlertDialog,
  InitialDialogState,
} from "../../../../components/userFeedback/AlertDialog";
import useAuth from "../../../../hooks/useAuth";

const MarkAsPaidComponent = ({ invoiceId }) => {
  const [dialog, setDialog] = useState({
    ...InitialDialogState,
    updateOpenState: (bool) => setDialog({ ...dialog, open: bool }),
  });
  const [loading, setLoading] = useState(false);
  const { userid } = useAuth();

  const handleMarkAsPaid = () => {
    console.log("INVOICE STATUS TO PAID...");
    setLoading(true);
    paidInvoice(userid, userid, invoiceId)
      .then((res) => {
        setLoading(false);
        setDialog({
          ...dialog,
          open: true,
          title: "Factura pagada exitosamente",
          actions: [
            {
              label: "Ok",
              execute: () => window.location.reload(),
            },
          ],
        });
      })
      .catch((err) => {
        setLoading(false);
        setDialog({
          ...dialog,
          open: true,
          title: "Error al marcar factura como pagada",
          content: err.response.data.message,
          actions: [
            {
              label: "Ok",
              execute: () => setDialog({ ...dialog, open: false }),
            },
          ],
        });
      });
  };

  return (
    <div>
      <AlertDialog {...dialog} />
      <Button variant="contained" color="primary" onClick={handleMarkAsPaid}>
        Marcar como Pagada
      </Button>
    </div>
  );
};

const DownloadInvoicePDFComponent = ({ iconView, cfdiData }) => {
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({
    ...InitialDialogState,
    updateOpenState: (bool) => setDialog({ ...dialog, open: bool }),
  });
  const { userid } = useAuth();

  const handleDownloadPDF = () => {
    setLoading(true);

    if (cfdiData.status !== "unissued") {
      getInvoiceFiles(userid, userid, "pdf", cfdiData.invoiceId)
        .then((data) => {
          setLoading(false);
          console.log(data);
          if (
            data.data.pdf === undefined ||
            data.data.pdf === null ||
            data.data.length === 0
          ) {
            setDialog({
              ...dialog,
              open: true,
              title: "Error descargando la factura",
              content:
                "Hubo un error al descargar la factura. Contacta a soporte.",
              actions: [],
            });
            return;
          }

          const binary = atob(data.data.pdf.replace(/\s/g, ""));
          const buffer = new ArrayBuffer(binary.length);
          const view = new Uint8Array(buffer);

          for (let i = 0; i < binary.length; i++) {
            view[i] = binary.charCodeAt(i);
          }

          const blob = new Blob([view], { type: "application/pdf" });

          const source = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          const filename = `Factura - ${cfdiData.GeneralModel.Folio} - ${cfdiData._id}.pdf`;

          link.href = source;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          setLoading(false);
          console.log("error downloading invoice");
          console.log(err);
          setDialog({
            ...dialog,
            open: true,
            title: "Error descargando la factura",
            content:
              "Hubo un error al descargar la factura, recarga la página e inténtalo de nuevo",
            actions: [],
          });
        });
    } else {
      setLoading(false);
      setDialog({
        ...dialog,
        open: true,
        title: "Factura sin timbrar",
        content:
          "Esta factura no se ha timbrado, por lo tanto no se puede descargar",
        actions: [
          {
            label: "Ok",
            execute: () => setDialog({ ...dialog, open: false }),
          },
        ],
      });
    }
  };

  return (
    <div>
      <AlertDialog {...dialog} />
      {loading && <CircularProgress />}
      {!iconView && !loading && (
        <Button variant="contained" color="primary" onClick={handleDownloadPDF}>
          Descargar PDF
        </Button>
      )}
      {iconView && !loading && (
        <Tooltip title="Descargar PDF" arrow>
          <IconButton onClick={handleDownloadPDF}>
            <PictureAsPdfIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

const DownloadXMLComponent = ({ cfdiData, iconView }) => {
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({
    ...InitialDialogState,
    updateOpenState: (bool) => setDialog({ ...dialog, open: bool }),
  });
  const { userid } = useAuth();

  const handleDownloadXML = () => {
    setLoading(true);

    getInvoiceFiles(userid, userid, "xml", cfdiData.invoiceId)
      .then((res) => {
        console.log(res.data);
        if (typeof res.data.xml === "string") {
          // Handle base64 string
          const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/;
          const matches = res.data.xml.match(base64Regex);
          if (matches && matches.length > 0) {
            const mimeType = matches[1];
            const base64Data = res.data.xml
            const binaryData = atob(base64Data); // Decode base64 (convert ascii to binary)
            const length = binaryData.length;
            const buffer = new Uint8Array(new ArrayBuffer(length));

            // Convert binary string to buffer.
            for (let i = 0; i < length; i++) {
              buffer[i] = binaryData.charCodeAt(i);
            }

            const url = window.URL.createObjectURL(
              new Blob([buffer], { type: mimeType })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Factura - ${cfdiData.GeneralModel.Folio} - ${cfdiData._id}.xml`
            );
            document.body.appendChild(link);
            link.click();
          } else {
            console.error("Invalid base64 string");
            setDialog({
              ...dialog,
              open: true,
              title: "Error descargando la factura",
              content: "El archivo XML no es válido. Contacta a soporte.",
              actions: [],
            });
          }
        } else {
          // Handle XML
          const xmlRegex = /^<\?xml.*?\?>/;
          const matches = res.data.xml.match(xmlRegex);
          if (matches && matches.length > 0) {
            const url = window.URL.createObjectURL(
              new Blob([res.data.xml], { type: "text/xml" })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Factura - ${cfdiData.GeneralModel.Folio} - ${cfdiData._id}.xml`
            );
            document.body.appendChild(link);
            link.click();
          } else {
            console.error("Invalid XML data");
            setDialog({
              ...dialog,
              open: true,
              title: "Error descargando la factura",
              content: "El archivo XML no es válido. Contacta a soporte.",
              actions: [],
            });
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setDialog({
          ...dialog,
          open: true,
          title: "Error descargando la factura",
          content:
            "Hubo un error al descargar la factura, recarga la página e inténtalo de nuevo",
          actions: [],
        });
      });
  };

  return (
    <div>
      <AlertDialog {...dialog} />
      {loading && <CircularProgress />}
      {!iconView && !loading && (
        <Button variant="contained" color="primary" onClick={handleDownloadXML}>
          Descargar XML
        </Button>
      )}
      {iconView && !loading && (
        <Tooltip title="Descargar XML" arrow>
          <IconButton onClick={handleDownloadXML}>
            <CodeIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export {
  MarkAsPaidComponent,
  DownloadInvoicePDFComponent,
  DownloadXMLComponent,
};
