import React from "react";
import PropTypes from "prop-types";
import { List, Typography, Box, IconButton } from "@mui/material";

const listStyles = {
  container: {
    maxWidth: "100%",
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px 10px 0 0",
    top: 0,
    zIndex: 1,
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "17px",
  },
  emptyMsg: {
    fontSize: "14px",
    color: "#777",
    textAlign: "center",
    marginTop: "20px",
  },
  dataItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: 1,
  },
  dataItemValue: {
    flex: "1",
    textAlign: "center",
    minWidth: "50px",
  },
  listContainer: {
    overflow: "auto",
  },
};

const ListTable = ({
  title,
  data,
  emptyMsg,
  sx,
  actions,
  tableFn,
  keysToRender = null,
}) => {
  console.log("data", data);

  const renderContent = () => {
    if (data.length === 0) {
      return (
        <Typography variant="body1" sx={listStyles.emptyMsg}>
          <b>{emptyMsg}</b>
        </Typography>
      );
    }

    return data.map((item, index) => (
      <>
        {!keysToRender && (
          <Box key={index} sx={listStyles.dataItem}>
            <Typography
              variant="body2"
              sx={{ ...listStyles.dataItemValue, fontSize: "16px" }}
            >
              <b>{item.ExecutionStatus}</b>
            </Typography>
            {item.description && (
              <Typography
                variant="body2"
                sx={{
                  ...listStyles.dataItemValue,
                  color: "#757575",
                  fontSize: "12px",
                }}
              >
                {item.description}
              </Typography>
            )}
            {(item.updatedBy || item.updatedAt) && (
              <Typography
                variant="body2"
                sx={{
                  ...listStyles.dataItemValue,
                  color: "#757575",
                  fontSize: "12px",
                }}
              >
                {item.updatedBy ? `Por: ${item.updatedBy}` : "Por: -"}
                <br />
                {item.updatedAt ? `(${item.updatedAt})` : "(-)"}
              </Typography>
            )}
            {actions && (
              <Box sx={listStyles.actions}>
                {Object.entries(actions).map(([key, action], actionIndex) => (
                  <IconButton key={actionIndex} onClick={() => action.fn(item)}>
                    {addSmallFontSizeToIcon(action.icon)}
                  </IconButton>
                ))}
              </Box>
            )}
          </Box>
        )}
        {keysToRender && (
          <Box key={index} sx={listStyles.dataItem}>
            {keysToRender.map((key, keyIndex) => (
              <Typography
                variant="body2"
                sx={{ ...listStyles.dataItemValue, fontSize: "16px" }}
                key={keyIndex}
              >
                <b>{item[key]}</b>
              </Typography>
            ))}
            {actions && (
              <Box sx={listStyles.actions}>
                {Object.entries(actions).map(([key, action], actionIndex) => (
                  <IconButton key={actionIndex} onClick={() => action.fn(item)}>
                    {addSmallFontSizeToIcon(action.icon)}
                  </IconButton>
                ))}
              </Box>
            )}
          </Box>
        )}
      </>
    ));
  };

  const addSmallFontSizeToIcon = (icon) => {
    return React.cloneElement(icon, {
      fontSize: "small",
    });
  };

  return (
    <Box sx={{ ...listStyles.container, ...sx }}>
      <Box sx={listStyles.header}>
        {title && (
          <Typography variant="body2" sx={listStyles.title}>
            {title}
          </Typography>
        )}
        {tableFn && (
          <IconButton onClick={tableFn.fn}>{tableFn.icon}</IconButton>
        )}
      </Box>
      <List sx={listStyles.listContainer}>{renderContent()}</List>
    </Box>
  );
};

ListTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      updatedAt: PropTypes.string,
      updatedBy: PropTypes.string,
    })
  ).isRequired,
  tableFn: PropTypes.shape({
    fn: PropTypes.func.isRequired,
    icon: PropTypes.node.isRequired,
  }),
  emptyMsg: PropTypes.string.isRequired,
  sx: PropTypes.object,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      fn: PropTypes.func.isRequired,
      icon: PropTypes.node.isRequired,
    })
  ),
};

export default ListTable;
