import React from "react";
import {
  Paper,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Divider,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import PlaceIcon from "@mui/icons-material/Place";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ClearIcon from "@mui/icons-material/Clear";
import EditLocation from "@mui/icons-material/EditLocation";

const DestinationCompanyPaper = ({
  company,
  type,
  handleClose,
  handleEdit,
}) => {
  let title = "Compañía";
  if (type) {
    if (type === "load") {
      title = "Carga en:";
    }
    if (type === "unload") {
      title = "Descarga en:";
    }
  }

  const internalEdit = () => {
    console.log(
      "This is not implemented. Because this should be done by the parent component"
    );
  };

  const composeAddress = (address) => {
    const cp = address?.ZipCode;
    const street = address?.Street;
    const number = address?.ExteriorNumber;
    const neighborhood = address?.Neighborhood;
    const city = address?.City;
    const state = address?.State;
    const country = address?.Country;
    const fullAddress = `${street || "Sin calle"} ${number || "Sin número"}, ${
      neighborhood || "Sin barrio"
    }, ${city || "Sin ciudad"}, ${state || "Sin estado"}, ${
      country || "Sin país?"
    }, ${cp || "Sin código postal"}`;
    return fullAddress;
  };
  return (
    <>
      <Paper>
        <Grid container spacing={1} sx={{ padding: "1.5vh" }}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="h6" color={"primary"}>
              {title}
            </Typography>

            <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Editar destino">
                <IconButton
                  onClick={handleEdit || internalEdit}
                  color="primary"
                >
                  <EditLocation />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar destino">
                <IconButton onClick={handleClose} color="primary">
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <BusinessIcon color="primary" />
            <Typography variant="body2">
              <b>{company.Type}:</b> {company.TaxName}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <PlaceIcon color="primary" />
            <Typography variant="body2">
              <b>Dirección:</b>
              {company.Address.FullAddress
                ? company.Address.FullAddress
                : composeAddress(company.Address)}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <ReceiptLongIcon color="primary" />
            <Typography variant="body2">
              <b>RFC:</b> {company.Rfc}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Divider />
    </>
  );
};

export default DestinationCompanyPaper;
