import React, { useEffect, useState } from "react";
import { Grid, TextField, CircularProgress, Autocomplete } from "@mui/material";
import { getExpeditionPLC } from "../../../services/modules/invoices/issuers";
import useAuth from "../../../hooks/useAuth";

const NominaGeneralCfdiForm = ({ selected, onGeneralCfdiChange, error }) => {
  const { userid } = useAuth();

  const [options, setOptions] = useState({
    paymentMethod: [],
    place: [],
  });
  const [loading, setLoading] = useState({
    uses: false,
    paymentMethod: false,
    place: false,
  });

  const handleSetCfdiData = (e, v, r) => {
    if (e.target.id === "Folio") {
      onGeneralCfdiChange(e, e.target.value, "input");
      return;
    }

    onGeneralCfdiChange(e, v, r);
  };

  const getData = () => {
    setLoading((loading) => {
      return {
        ...loading,
        paymentMethod: true,
        place: true,
      };
    });

    getExpeditionPLC(userid, userid.token)
      .then((responses) => {
        const expeditionPLC = responses;
        setLoading((loading) => {
          return {
            ...loading,
            place: false,
          };
        });

        setOptions((prevOptions) => {
          const newCfdiOptions = { ...prevOptions };
          if (expeditionPLC?.data) {
            newCfdiOptions.place = expeditionPLC.data;
          }

          return newCfdiOptions;
        });

        if (expeditionPLC?.data) {
          handleSetCfdiData(
            { target: { id: "ExpeditionPlace" } },
            expeditionPLC.data[0],
            "selectOption"
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Grid item xs={12} md={5}>
        <TextField
          variant="standard"
          id="PaymentMethod"
          sx={{ width: "100%" }}
          value={selected.PaymentMethod.Name}
          label="Método de pago"
          disabled
        />
      </Grid>

      <Grid item xs={8} md={5}>
        <Autocomplete
          id="ExpeditionPlace"
          autoComplete
          sx={{ width: "100%" }}
          options={options.place}
          getOptionLabel={(option) => {
            return `${option.Name || ""}`;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                sx={{ width: "100%" }}
                label="Lugar de expedición"
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading.uses ? <CircularProgress /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }}
          onChange={handleSetCfdiData}
          isOptionEqualToValue={(option, value) => {
            if (value === "") {
              return true;
            }

            return option.ZipCode === value.ZipCode;
          }}
          value={selected.ExpeditionPlace}
        />
      </Grid>
    </>
  );
};

export default NominaGeneralCfdiForm;
