import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {Autocomplete} from "@mui/material";
import {ReceiverDto} from "../../../utils/dto/receiver.dto";
import {validateRfc} from "../../../modules/invoices/utils/regexp";

const CustomerFiscalForm = React.forwardRef(
  (
    {
      regimenOptions = ["no options available"],
      setter = function test() {
        console.log("setter not implemented");
      },
      typeHook = {error: "error en el hook de cliente"},
    },
    ref
  ) => {
    const [customer, setCustomer] = React.useState(typeHook);
    const [errors, setErrors] = React.useState(
      Object.keys(JSON.parse(JSON.stringify(ReceiverDto))).reduce(
        (accum, current) => {
          accum[current] = {failed: false, message: ""};
          return accum;
        },
        {}
      )
    );

    let options = regimenOptions;

    const handleRfcChange = (e, v, r) => {
      let uppercaseValue = String(v).toUpperCase();
      if (!validateRfc(uppercaseValue)) {
        setErrors((prev) => {
          let newErrors = {...prev};
          newErrors.Rfc = {
            failed: true,
            message: "El RFC no es válido",
          };
          return newErrors;
        });
      } else {
        setErrors((prev) => {
          let newErrors = {...prev};
          newErrors.Rfc = {
            failed: false,
            message: "",
          };
          return newErrors;
        });
      }

      return uppercaseValue;
    };

    const handleTaxNameChange = (e, v, r) => {
      let uppercaseValue = String(v).toUpperCase();
      return uppercaseValue;
    };

    const handleSetCustomer = (e, v, r) => {
      const id = e.target.id.split("-")[0];
      const functionById = {
        TaxName: handleTaxNameChange,
        Rfc: handleRfcChange,
        FiscalRegime: (e, v, r) => v,
      };
      const uppercaseValue = functionById[id](e, v, r);
      if (r === "selectOption" || r === "input") {
        setCustomer((prevCustomer) => {
          return {
            ...prevCustomer,
            [id]: uppercaseValue,
          };
        });
      }
      if (r === "clear") {
        if (id === "FiscalRegime") {
          setCustomer((prevCustomer) => {
            return {
              ...prevCustomer,
              [id]: {Name: "", Value: ""},
            };
          });
          return;
        }
        setCustomer({
          ...customer,
          [id]: "",
        });
      }
      setter(e, uppercaseValue, r);
    };

    const validateAndShow = () => {
      const valid = [];
      setErrors((prev) => {
        let newErrors = {...prev};
        for (let i = 0; i < Object.keys(errors).length; i++) {
          const key = Object.keys(errors)[i];
          if (
            customer[key] === "" ||
            customer[key] === null ||
            customer[key] === undefined ||
            (typeof customer[key] === "object" &&
              Object.values(customer[key]).some((v) => v === ""))
          ) {
            newErrors[key] = {
              failed: true,
              message: "Este campo es requerido",
            };
            valid.push(false);
          }
        }
        return newErrors;
      });
      return !valid.some((v) => v === false);
    };

    React.useImperativeHandle(ref, () => ({
      validateAndShow: validateAndShow,
    }));

    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Información Fiscal
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              id="TaxName"
              name="fiscalName"
              label="Nombre o Razón Social"
              fullWidth
              autoComplete="Nombre"
              variant="standard"
              onChange={(e) => handleSetCustomer(e, e.target.value, "input")}
              error={errors.TaxName.failed}
              helperText={errors.TaxName.message}
              value={customer.TaxName || ""}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              id="Rfc"
              name="rfc"
              label="RFC"
              fullWidth
              autoComplete="RFC"
              variant="standard"
              onChange={(e) => handleSetCustomer(e, e.target.value, "input")}
              error={errors.Rfc.failed}
              helperText={errors.Rfc.message}
              value={customer.Rfc || ""}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              id="FiscalRegime"
              options={options}
              sx={{marginBottom: "4vh", width: "100%"}}
              label="Régimen fiscal"
              onChange={handleSetCustomer}
              renderInput={(params) => (
                <TextField
                  label="Selecciona un regimen"
                  variant="standard"
                  id="RegimenFiscalInput"
                  required={true}
                  error={errors.FiscalRegime.failed}
                  helperText={errors.FiscalRegime.message}
                  {...params}
                />
              )}
              getOptionLabel={(opt) => {
                if (opt && opt.Name && opt.Value) {
                  return `${opt.Name} - ${opt.Value}`;
                }
                return "";
              }}
              isOptionEqualToValue={(opt, val) => {
                if (val.Value === "" && val.Name === "") {
                  return true;
                }
                return opt.Value === val.Value && opt.Name === val.Name;
              }}
              value={customer.FiscalRegime || ""}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
);

export default CustomerFiscalForm;
