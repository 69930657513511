import { getTypeOfInvoice } from "../../../components/Previews/Getters";
import { Locations } from "../../../components/Forms/CartaPorte/Locations";
import { Products } from "../../../components/Forms/CartaPorte/Products";
import PaymentConfig from "../../../components/Forms/Payments/PaymentConfig";
import { InvoiceItemsData } from "../../../components/Forms/Invoices/InvoiceItemsData";
import { InvoiceGenerator } from "../../../components/actions/InvoiceGenerator";
import { IssuerAndCustomerForm } from "../../../components/Forms/Invoices/IssuerAndCustomerForm";
import OperatorsAndTransportForm from "../../../components/Forms/CartaPorte/OperatorAndTransport";

export const stepsObjects = {
  GeneralDataExtra: {
    Factura: [
      {
        name: "PaymentMethod",
      },
      {
        name: "PaymentForm",
      },
    ],
  },
  Gral: {
    label: "Emisor y cliente",
    component: <IssuerAndCustomerForm />,
  },

  "Recibo de Pago": [],
  "Nota de Crédito": [
    {
      label: "Conceptos",
      component: <InvoiceItemsData />,
    },
  ],
  "Recibo de Nómina": [],
  complementos: [
    {
      "Carta Porte": [
        {
          label: "Operadores y vehículos",
          component: <OperatorsAndTransportForm />,
        },
        {
          label: "Mercancias",
          component: <Products />,
        },
        {
          label: "Ruta",
          component: <Locations />,
        },
      ],
    },
    {
      "De pago": [
        {
          label: "Configurar el pago",
          component: <PaymentConfig />,
        },
      ],
    },
  ],
  Factura: [
    {
      label: "Conceptos",
      component: <InvoiceItemsData />,
    },
  ],
  Create: {
    label: "Timbrado",
    component: <InvoiceGenerator />,
  },
};

export const buildStepsFromCFDI = (CFDI) => {
  let type = getTypeOfInvoice(CFDI);
  let pasos = [stepsObjects["Gral"]];

  switch (type) {
    case "pago":
      pasos.push({
        label: "Configurar el pago",
        component: <PaymentConfig />,
      });
      break;
    case "cartaPorte":
      let newSteps = pasos.concat([
        {
          label: "Operadores y transporte",
          component: <OperatorsAndTransportForm />,
        },
        {
          label: "Mercancias",
          component: <Products />,
        },
        {
          label: "Ubicaciones",
          component: <Locations />,
        },
        {
          label: "Conceptos",
          component: <InvoiceItemsData />,
        },
      ]);
      pasos = newSteps;

      break;
    case "facturaIngreso":
      pasos.push({
        label: "Conceptos",
        component: <InvoiceItemsData />,
      });

      break;
    default:
      break;
  }

  pasos.push({
    label: "Timbrado",
    component: <InvoiceGenerator />,
  });

  return pasos;
};
