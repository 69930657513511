import React, { useState } from "react";
import {
  Button,
  Grid,
  Box,
  IconButton,
  Tooltip,
  Typography,
  Container,
  useMediaQuery,
} from "@mui/material";
import { Delete, Edit, PhotoCamera, Save } from "@mui/icons-material";
import {
  AlertDialog,
  InitialDialogState,
} from "../../../../components/userFeedback/AlertDialog";


export const ImageUpload=({updateFunction}) => {
  const [imageURLs, setImageURLs] = useState([]);
  const [editVisible, setEditVisible] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"))

  const [dialog, setDialog] = useState({
    ...InitialDialogState,
    updateOpenState: () => setDialog({ ...dialog, open: false }),
  });

  const handleImageUpload = (selectedImages) => {
    const allowedImageTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/svg+xml",
    ];

    if(selectedImages.length!==0){
        selectedImages.forEach(element => {
            if (allowedImageTypes.includes(element.type)) {
                const imageUrl = URL.createObjectURL(element);
                setImageURLs((prevURLs) => [...prevURLs, imageUrl]);
              } else {
                setDialog({
                  open: true,
                  title: "Formato inválido",
                  content: "Verifique que todos los archivos selecionados son válidos",
                  actions: [
                    {
                      label: "Ok",
                      execute: () => setDialog({ ...dialog, open: false }),
                    },
                  ],
                });
              }
            
        });
          
        }else {
        setDialog({...dialog,open:true,title:"Sin archivos válidos",content:"",actions:[{label:"Ok",execute:()=>setDialog({...dialog,open:false})}]})
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const selectedImage = event.dataTransfer.files[0];
    handleImageUpload(selectedImage);
  };

  const preventDefault = (event) => {
    event.preventDefault();
  };

  const handleDeleteImage = (index) => {
    const updatedURLs = [...imageURLs];
    updatedURLs.splice(index, 1);
    setImageURLs(updatedURLs);
  };

  const handleUpdateImages = () => {
    // Handle the logic to update images here
    updateFunction()
};

  return (
    <div>
      <Tooltip title="Editar">
        <div
          onMouseEnter={() => setImageURLs && setEditVisible(true)}
          onMouseLeave={() => setEditVisible(false)}
          onDragOver={(e) => preventDefault(e)}
          onDragEnter={() => setIsDragging(true)}
          onDragLeave={() => setIsDragging(false)}
          onDrop={handleDrop}
          style={{
            border: isDragging ? "2px dashed #aaa" : "none",
            borderRadius: "5px",
            padding: "16px",
            textAlign: "center",
            marginBottom: "16px",
            zIndex: 5,
            position: "relative",
          }}
        >
          <Grid container>
            {imageURLs.map((url, index) => (
              <Grid item xs={6} md={3}>
                <IconButton
                  onClick={() => handleDeleteImage(index)}
                  style={{
                    position: "relative",
                    top: "10%",
                    right:"-30%",
                    background: "red",
                    color: "white",

                  }}
                  size="small"
                  
                >
                  <Delete />
                </IconButton>
                <Box
                  component="img"
                  sx={{
                    maxWidth: { xs: "95%" },
                  }}
                  alt={`Image ${index + 1}`}
                  src={url}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </Tooltip>

      <Grid container spacing={2}>
        <Grid item xs={12} sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
          {!isMobile &&<div
            onDragOver={(e) => preventDefault(e)}
            onDragEnter={() => setIsDragging(true)}
            onDragLeave={() => setIsDragging(false)}
            onDrop={handleDrop}
            style={{
              border: "2px dashed #aaa",
              borderRadius: "5px",
              padding: "16px",
              textAlign: "center",
              marginTop: "16px",
            }}
          >
            <Typography variant="body1">
              Arrastre una imagen aquí o selecciónela desde su dispositivo
            </Typography>
          </div>}
          <input
            type="file"
            accept="image/*"
            multiple={true}
            id="image-upload"
            style={{ display: "none" }}
            onChange={(e) => {console.log(e.target.files); handleImageUpload(Array.from(e.target.files))}}
          />
          <label htmlFor="image-upload">
            <Button
              variant="contained"
              component="span"
              startIcon={<PhotoCamera />}
              fullWidth={isMobile?true:false}
            >
              Cargar Evidencias
            </Button>
          </label>
        </Grid>
        {imageURLs.length > 0 && (
          <Grid item xs={12} sx={{display:"flex",justifyContent:"center"}}>
            <Button
              variant="contained"
              startIcon={<Save />}
              onClick={handleUpdateImages}
              fullWidth={isMobile?true:false}
            >
              Guardar Imágenes
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export const EvidenceUploadTest = () => {
  return (
    <Container>
      <Grid container maxWidth={"md"}>
        <Grid item xs={6}>
          <ImageUpload />
        </Grid>
      </Grid>
    </Container>
  );
};

