import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Paper,
  Collapse,
  Snackbar,
  Alert,
  AlertTitle,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import useAuth from "../../../hooks/useAuth";
import { getIssuedDocs } from "../../../services/modules/invoices/organization";
import { cancelInvoice } from "../../../services/modules/invoices/invoices";
import CheckBoxesList from "../CheckBoxesList";
import FilterDrawer from "../../Drawers/FilterDrawer";
import CustomTable from "../../Tables/CustomTable";
import GOVERNMENT_CONFIG from "../../../utils/GOV_CONFIG";


const CancelInvoiceComponent = ({
  baseInvoice,
  onFinish,
  onStarted,
  disableUse,
}) => {
  const [cancelState, setCancelState] = useState({
    started: false,
    cancelled: false,
    relatedSearched: false,
  });
  const [selectedWayOfSearch, setSelectedWayOfSearch] = useState({
    Buscar: false,
    Folio: false,
  });
  const [cancelInvoicePayload, setCancelInvoicePayload] = useState({
    id: baseInvoice._id,
    password: "",
    motive: "",
    relation: "",
  });
  const [filterOptions, setFilterOptions] = useState({
    clients: [],
    issuerList: [],
    invoiceTypes: Object.entries(
      GOVERNMENT_CONFIG.INVOICES.GENERAL.INVOICE_TYPES
    ).map((entryArray) => entryArray[1]),
  });
  const [filters, setFilters] = useState([]);
  const defaultSelectedfilters = {
    client: {},
    issuer: {},
    invoiceType: {},
    folio: "",
    folioFiscal: "",
  };
  const [selectedFilters, setSelectedFilters] = useState(
    defaultSelectedfilters
  );
  const [selectedClientsArray, setSelectedClientsArray] = useState([]);
  const [selectedIssuersArray, setSelectedIssuersArray] = useState([]);
  const [selectedTypesArray, setSelectedTypesArray] = useState([]);
  const today = new Date();
  const [range, setRange] = useState([
    new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
    today,
  ]);
  const [customerInput, setCustomerInput] = useState("");
  const [issuerInput, setIssuerInput] = useState("");
  const [invoiceTypeInput, setInvoiceTypeInput] = useState("");
  const [renderInvoiceDetails, setRenderInvoiceDetails] = useState(null);

  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [invoices, setInvoices] = useState([]);
  const { userid } = useAuth();

  const handleShowInvoiceDetails = (invoice) => {
    setRenderInvoiceDetails(invoice);
    if (invoice) {
      if (invoice.uuid == baseInvoice.uuid) {
        setSnack({
          open: true,
          message: "No puedes cancelar una factura con si misma",
          severity: "warning",
        });
        return;
      }

      setCancelInvoicePayload({
        ...cancelInvoicePayload,
        relation: invoice?.uuid,
      });
      return;
    }

    setCancelInvoicePayload({
      ...cancelInvoicePayload,
      relation: "",
    });
  };

  const motives_options = [
    {
      label: "Error en el CFDI (relacionar nueva factura)",
      value:
        GOVERNMENT_CONFIG.INVOICES.OPERATIONS.CANCEL.MOTIVES.ERROR_RELATION,
    },
    {
      label: "Error en el CFDI sin relacionar",
      value:
        GOVERNMENT_CONFIG.INVOICES.OPERATIONS.CANCEL.MOTIVES.ERROR_NO_RELATION,
    },
    {
      label: "Operación no realizada",
      value:
        GOVERNMENT_CONFIG.INVOICES.OPERATIONS.CANCEL.MOTIVES
          .OPERATION_NOT_EXECUTED,
    },
    {
      label: "Relación a factura global",
      value:
        GOVERNMENT_CONFIG.INVOICES.OPERATIONS.CANCEL.MOTIVES
          .GLOBAL_INVOICE_RELATED,
    },
  ];
  const invoiceColumns = [
    {
      field: "Folio",
      headerName: "Folio",
      flex: 2,
      headerClassName: "header-table",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.GeneralModel.Folio;
      },
    },
    {
      field: "Date",
      headerName: "Fecha de emisión",
      flex: 2,
      headerClassName: "header-table",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.GeneralModel.Date;
      },
    },
    {
      field: "Issuer",
      headerName: "Emisor",
      flex: 2,
      headerClassName: "header-table",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.GeneralModel.Issuer.Name;
      },
    },
    {
      field: "Receiver",
      headerName: "Receptor",
      flex: 2,
      headerClassName: "header-table",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.GeneralModel.Receiver.TaxName;
      },
    },
  ];

  const handleFilterDrawer = () => {};

  const handleCloseSnack = (e, r) => {
    if (r && r === "clickaway") {
      return;
    }
    setSnack({
      open: false,
      message: "",
      severity: "success",
    });
  };

  const handlePasswordChange = (e) => {
    const newValue = e.target.value;
    setCancelInvoicePayload({
      ...cancelInvoicePayload,
      password: newValue,
    });
  };

  const handleChangeMotive = (e, v, r) => {
    if (r === "selectOption") {
      setCancelInvoicePayload({
        ...cancelInvoicePayload,
        motive: v,
      });
    }
    if (r === "clear") {
      setCancelInvoicePayload({
        ...cancelInvoicePayload,
        motive: "",
      });
    }
  };

  const motiveRequireRelation = () => {
    if (cancelInvoicePayload.motive === "") {
      return false;
    }

    return (
      cancelInvoicePayload.motive.value ===
      GOVERNMENT_CONFIG.INVOICES.OPERATIONS.CANCEL.MOTIVES.ERROR_RELATION
    );
  };

  const handleFolioChange = (e) => {
    const newValue = e.target.value;
    setCancelInvoicePayload({
      ...cancelInvoicePayload,
      relation: newValue,
    });
  };

  const handleSelectWayOfSearch = (e, index) => {
    const key = Object.keys(selectedWayOfSearch)[index];
    setSelectedWayOfSearch({
      ...selectedWayOfSearch,
      [key]: e.target.checked,
    });
  };

  const handleSearchInvoice = () => {
    setLoading(true);
    fetchInvoices();
  };

  const fetchInvoices = () => {
    getIssuedDocs(userid, userid, 1, filters)
      .then((response) => {
        setLoading(false);
        if (response.data.total_records > 0 && response.data.data.length > 0) {
          setCancelState({
            ...cancelState,
            relatedSearched: true,
          });
          setInvoices(response.data.data);
          return;
        }
        setCancelState({
          ...cancelState,
          relatedSearched: false,
        });
        setInvoices([]);
        setSnack({
          open: true,
          message: "No se encontraron facturas con los filtros seleccionados",
          severity: "warning",
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        if (err.response && err.response.data && err.response.data.message) {
          console.log(err?.response);
          setSnack({
            open: true,
            message: "Error " + err.response.status + err.response.data.message,
            severity: "error",
          });
          return;
        }

        setSnack({
          open: true,
          message: "Error buscando facturas",
          severity: "error",
        });
      });
  };

  const handleConfirm = () => {
    if (
      !cancelInvoicePayload.password ||
      cancelInvoicePayload.password === ""
    ) {
      setSnack({
        open: true,
        message: "La contraseña es requerida",
        severity: "warning",
      });
      return;
    }

    if (!cancelInvoicePayload.motive || cancelInvoicePayload.motive === "") {
      setSnack({
        open: true,
        message: "El motivo de cancelación es requerido",
        severity: "warning",
      });
      return;
    }

    if (
      motiveRequireRelation() &&
      (!cancelInvoicePayload.relation || cancelInvoicePayload.relation === "")
    ) {
      setSnack({
        open: true,
        message: "Selecciona una factura para relacionarla!",
        severity: "warning",
      });
      return;
    }

    setLoading(true);
    cancelInvoice(userid, userid, {
      ...cancelInvoicePayload,
      motive: cancelInvoicePayload.motive.value,
    })
      .then((res) => {
        setLoading(false);
        setCancelState({
          ...cancelState,
          cancelled: true,
        });
        setSnack({
          open: true,
          message: "Factura cancelada exitosamente",
          severity: "success",
        });
        onFinish();
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.data && err.response.data.message) {
          console.log(err?.response);
          setSnack({
            open: true,
            message: "Error " + err.response.status + err.response.data.message,
            severity: "error",
          });
          return;
        }

        setSnack({
          open: true,
          message: "Error cancelando factura",
          severity: "error",
        });
      });
  };

  const handleCancelButton = () => {
    if (!cancelState.started) {
      setCancelState({
        ...cancelState,
        started: true,
      });
      onStarted();
    }

    if (cancelState.started && !cancelState.cancelled) {
      setCancelState({
        ...cancelState,
        started: false,
      });
      onStarted();
      setCancelInvoicePayload({
        ...cancelInvoicePayload,
        password: "",
        motive: "",
        relation: "",
      });
      setSelectedWayOfSearch({
        Buscar: false,
        Folio: false,
      });
    }

    if (cancelState.started && cancelState.cancelled) {
      setSnack({
        open: true,
        message: "Proceso de cancelación finalizado",
        severity: "info",
      });
    }
  };

  return (
    <div>
      {loading && <CircularProgress />}
      {!loading && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              onClick={handleCancelButton}
              variant="contained"
              color="error"
              disabled={loading || cancelState.cancelled || disableUse}
            >
              {(!cancelState.started &&
                !cancelState.cancelled &&
                "Iniciar proceso de cancelación") ||
                (cancelState.started &&
                  !cancelState.cancelled &&
                  "Cancelar proceso") ||
                (cancelState.started &&
                  cancelState.cancelled &&
                  "Proceso de cancelación finalizado")}
            </Button>
          </Grid>
          <br />

          {cancelState.started && !cancelState.cancelled && (
            <>
              <Grid item xs={12}>
                <Alert severity="warning">
                  <AlertTitle>Advertencia</AlertTitle>
                  Se cancelará ante la autoridad fiscal y es irreversible. Por
                  favor, asegúrate de que realmente deseas cancelar la factura.
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  autoComplete
                  options={motives_options}
                  getOptionLabel={(option) => {
                    if (!option || option === "") {
                      return "";
                    }
                    return option.label;
                  }}
                  onChange={handleChangeMotive}
                  renderInput={(params) => (
                    <TextField {...params} label="Motivo de cancelación" />
                  )}
                  isOptionEqualToValue={(option, value) => {
                    if (value !== "") {
                      return option.value === value.value;
                    }
                    return true;
                  }}
                />
              </Grid>
              {motiveRequireRelation() && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        Selecciona la factura con la que se relacionará la
                        factura cancelada. Puedes buscarla o introducir el FOLIO
                        FISCAL directamente
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <CheckBoxesList
                        items={Object.keys(selectedWayOfSearch)}
                        checkedItems={Object.values(selectedWayOfSearch)}
                        setCheckedItems={handleSelectWayOfSearch}
                        emptyListMessage={
                          "No hay opciones de búsqueda disponibles"
                        }
                      />
                    </Grid>
                    {(selectedWayOfSearch.Buscar ||
                      selectedWayOfSearch.Folio) && (
                      <>
                        <Grid item xs={12}>
                          {selectedWayOfSearch.Buscar && (
                            <>
                              <Grid item xs={12}>
                                <FilterDrawer
                                  hideHeader
                                  options={filterOptions}
                                  filters={filters}
                                  setFilters={setFilters}
                                  selected={selectedFilters}
                                  setSelected={setSelectedFilters}
                                  selectedClientsArray={selectedClientsArray}
                                  setSelectedClientsArray={
                                    setSelectedClientsArray
                                  }
                                  selectedIssuersArray={selectedIssuersArray}
                                  setSelectedIssuersArray={
                                    setSelectedIssuersArray
                                  }
                                  selectedTypesArray={selectedTypesArray}
                                  setSelectedTypesArray={setSelectedTypesArray}
                                  toggleFilterDrawer={handleFilterDrawer}
                                  rango={range}
                                  setRango={setRange}
                                  customerInput={customerInput}
                                  setCustomerInput={setCustomerInput}
                                  issuerInput={issuerInput}
                                  setIssuerInput={setIssuerInput}
                                  invoiceTypeInput={invoiceTypeInput}
                                  setInvoiceTypeInput={setInvoiceTypeInput}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleSearchInvoice}
                                >
                                  Buscar
                                </Button>
                              </Grid>
                              {invoices.length > 0 && (
                                <Grid
                                  item
                                  xs={12}
                                  sx={
                                    selectedWayOfSearch.Buscar
                                      ? { width: "100%" }
                                      : undefined
                                  }
                                >
                                  <Collapse
                                    in={
                                      cancelState.relatedSearched &&
                                      cancelInvoicePayload.relation === ""
                                    }
                                  >
                                    <Alert severity="info">
                                      <AlertTitle>
                                        SELECCIONA UNA DE LAS FACTURAS
                                      </AlertTitle>
                                      Debes seleccionar una factura para
                                      relacionarla con la factura que se
                                      cancelará
                                    </Alert>
                                  </Collapse>
                                  <CustomTable
                                    columns={invoiceColumns}
                                    rows={invoices}
                                    handleSelect={handleShowInvoiceDetails}
                                    emptyMsg="No se encontraron facturas con los filtros seleccionados"
                                  />
                                  {renderInvoiceDetails && (
                                    <>
                                      <Paper elevation={3}>
                                        <Typography variant="h6">
                                          Detalles de la factura seleccionada
                                        </Typography>
                                        <Typography variant="body1">
                                          Folio fiscal:{" "}
                                          {renderInvoiceDetails.uuid}
                                        </Typography>
                                        {renderInvoiceDetails.Models &&
                                          renderInvoiceDetails.Models?.I && (
                                            <Typography variant="body1">
                                              Conceptos:{" "}
                                              {renderInvoiceDetails.Models?.I?.Items.map(
                                                (item) => {
                                                  return `
                                                 Descripción: ${
                                                   item.description
                                                 };
                                                 Cantidad: ${item.quantity}
                                                 Precio: ${item.price}
                                                 Importe: ${item.total}
                                                 Impuestos: ${item.taxes.map(
                                                   (tax) => {
                                                     return `Impuesto: ${tax.Name}, Tasa: ${tax.Rate}, Importe: ${tax.Total}`;
                                                   }
                                                 )};
                                                 `;
                                                }
                                              )}
                                            </Typography>
                                          )}
                                      </Paper>
                                    </>
                                  )}
                                </Grid>
                              )}
                            </>
                          )}

                          {selectedWayOfSearch.Folio && (
                            <TextField
                              autoFocus
                              margin="dense"
                              label="Folio Fiscal"
                              type="text"
                              fullWidth
                              onChange={handleFolioChange}
                              InputProps={{
                                endAdornment: <LockIcon />,
                              }}
                            />
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              )}
              {cancelInvoicePayload.motive !== "" && (
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Contraseña"
                    type="password"
                    fullWidth
                    onChange={handlePasswordChange}
                    disabled={
                      cancelInvoicePayload.motive === "" ||
                      (motiveRequireRelation() &&
                        cancelInvoicePayload.relation === "")
                    }
                    InputProps={{
                      endAdornment: <LockIcon />,
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  onClick={handleConfirm}
                  variant="contained"
                  color="warning"
                  disabled={
                    loading ||
                    cancelInvoicePayload.password === "" ||
                    cancelState.cancelled ||
                    !cancelState.started
                  }
                >
                  Confirmar cancelación
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      )}

      <Snackbar
        open={snack.open}
        onClose={handleCloseSnack}
        autoHideDuration={10000}
      >
        <Alert onClose={handleCloseSnack} severity={snack.severity}>
          {snack.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CancelInvoiceComponent;
