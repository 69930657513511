import { API_CONTEXT } from "../invoices/invoices.js";

export const getExpense = (credential, expenseId) => {
    const getPromise = API_CONTEXT("expenses", "getExpense");
    return getPromise(credential, expenseId);
};

export const getExpenses = (credential, pageNumber = 1, Description) => {
    const getPromise = API_CONTEXT("expenses", "getExpenses");
    return getPromise(credential, pageNumber, Description);
};

export const getProductOService = (credential, query) => {
    const getPromise = API_CONTEXT("catalog", "productsOrServices");
    return getPromise(credential, credential, query);
};

export const calculateTax = (credential, body) => {
    const getPromise = API_CONTEXT("expenses", "calculateTax");
    return getPromise(credential, body);
}

export const saveExpense = (credential, body) => {
    const getPromise = API_CONTEXT("expenses", "save");
    return getPromise(credential, body);
}
export const editExpense = (credential, expenseId, body) => {
    const getPromise = API_CONTEXT("expenses", "edit");
    return getPromise(credential, expenseId, body);
}
export const deleteExpense = (credential, expenseId) => {
    const getPromise = API_CONTEXT("expenses", "delete");
    return getPromise(credential, expenseId);
}
export const uploadExpenseFile = (credential, expenseId, formdata) => {
    const getPromise = API_CONTEXT("expenses", "uploadFile");
    return getPromise(credential, expenseId, formdata);
}
export const downloadExpenseFile = (credential, expenseId, expenseFileId) => {
    const getPromise = API_CONTEXT("expenses", "downloadFile");
    return getPromise(credential, expenseId, expenseFileId);
}
export const deleteExpenseFile = (credential, expenseId, expenseFileId) => {
    const getPromise = API_CONTEXT("expenses", "deleteFile");
    return getPromise(credential, expenseId, expenseFileId);
}