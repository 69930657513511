class OrganizationObservable {
  constructor() {
    if (OrganizationObservable.instance) {
      return OrganizationObservable.instance;
    }
    this.observers = [];
    OrganizationObservable.instance = this;
  }

  subscribe(observer) {
    this.observers.push(observer);
  }

  unSubscribe(observer) {
    this.observers = this.observers.filter((obs) => obs !== observer);
  }

  notify(data) {
    this.observers.forEach((observer) => observer(data));
  }
}
const organizationObservable = new OrganizationObservable();
export { organizationObservable };
