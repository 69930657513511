import { useEffect, useState } from "react";
import { getCoordinatesForPostalCode } from "../../../../services/modules/services/mapsGeneral";


const Map = ({centerPostalCode,mapOptions,isRoute,startAddress,endAddress,height,width}) => {
    const [position, setPosition] = useState(null)

    let directionsService
    let directionsRenderer

    const getGoogleAddressStringFromObject = (addressObj) =>{
        const {Street, ExteriorNumber, Neighborhood, ZipCode, State, Country} = addressObj
        //"Guadalcazar del receptor 300, Las lomas, 65000, NLE, México"
        return `${Street} ${ExteriorNumber}, ${Neighborhood}, ${ZipCode}, ${State}, ${Country}`
    }

    function calculateAndDisplayRoute(start, end) {
        directionsService.route(
          {
            origin: start,
            destination: end,
            travelMode: window.google.maps.TravelMode.DRIVING,
            
             
          },
          (response, status) => {
            if (status === "OK") {
                console.log("ok status",response)
              directionsRenderer.setDirections(response);
            } else {
              window.alert("Directions request failed due to " + status);
            }
          }
        );
      }

      function calculateAndDisplayGPSRoute(end) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const start = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              };
      
              directionsService.route(
                {
                  origin: start,
                  destination: end,
                  travelMode: google.maps.TravelMode.DRIVING,
                },
                (response, status) => {
                  if (status === "OK") {
                    directionsRenderer.setDirections(response);
                  } else {
                    window.alert("Directions request failed due to " + status);
                  }
                }
              );
            },
            (error) => {
              console.error("Error getting user's location:", error);
              // Handle error getting user's location
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser");
          // Handle geolocation not supported
        }
      }

    useEffect(() => {

      const map = new window.google.maps.Map(document.getElementById("map"), {
        ...mapOptions,
        zoom: 20,
        streetViewControl: false,
        mapTypeControl: false,
        avoidTolls:true,
        drivingOptions: {
          departureTime: new Date(),
          trafficModel: 'pessimistic'
        }
      });
      if(isRoute){
        
      directionsService = new window.google.maps.DirectionsService();
      directionsRenderer = new window.google.maps.DirectionsRenderer({ map });

      console.log(startAddress)
        if(startAddress === "current"){
        console.log("geolocation")
          calculateAndDisplayGPSRoute(getGoogleAddressStringFromObject(endAddress))
        }
        else{
          console.log("deterministic")
          calculateAndDisplayRoute(getGoogleAddressStringFromObject(startAddress), getGoogleAddressStringFromObject(endAddress))
        }
      }
  
    }, [position]);


    useEffect(()=>{
        if(!isRoute){
            getCoordinatesForPostalCode(centerPostalCode).then((res)=>setPosition(res)).catch((err)=>console.log("Error al obtener coordenadas"))
        }
    },[])

    return <div id="map" style={{ height: height, width: width }}></div>;
  };

  Map.defaultProps = {
    height:"400px",
    width:"100%",
    isRoute:false,
  }
  
  export default Map;