import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import useAuth from "../../../hooks/useAuth";
import {uploadProductsCsv} from "../../../services/modules/services/products";
import {DragAndDrop, DisplayFilePreview} from "../../DragAndDrop/DragAndDrop";
import {getProductsServices} from "../../../services/modules/invoices/catalogs";
import {Delete} from "@mui/icons-material";

let products = {
  Mercancia: [],
};

const Products = forwardRef((props, ref) => {
  //*TODO: UPDATE THIS COMPONENT TO RETURN TransportServiceProductsForm in JSX
  const invoiceJson = JSON.parse(window.localStorage.getItem("invoiceModel"));

  const {userid} = useAuth();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [csv, setCsv] = useState();
  const [candAddFile, setCandAddFile] = useState(false);
  const [showInputs, setShowInputs] = useState(false);

  //*the options of the autocomplete
  const [productsList, setProductsList] = useState([]);
  const [items, setItems] = useState(0);
  const [invoiceProducts, setInvoiceProducts] = useState(
    invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia
  );

  const [showTable, setShowTable] = useState(invoiceProducts.length > 0);

  const [inputProduct, setInputProduct] = useState({
    UnidadPeso: invoiceJson.Complementos.CartaPorte.Mercancias.UnidadPeso
      ? invoiceJson.Complementos.CartaPorte.Mercancias.UnidadPeso
      : "KGM",
    PesoBrutoTotal: invoiceJson.Complementos.CartaPorte.Mercancias
      .PesoBrutoTotal
      ? invoiceJson.Complementos.CartaPorte.Mercancias.PesoBrutoTotal
      : 0,
    Descripcion: "",
    BienesTransp: "",
    ClaveUnidad: "",
    MaterialPeligroso: "",
    Cantidad: 0,
    PesoEnKg: 0,
    ValorMercancia: 0,
    Moneda: "MXN",
  });
  const [alert, setAlert] = useState({
    show: false,
    state: "",
    message: "",
  });

  const handleSubmit = (e) => {
    setLoadingCSV(true);
    if (inputProduct.UnidadPeso === "") {
      setAlert({
        show: true,
        state: "warning",
        message: "Completa los datos generales",
      });
      setLoadingCSV(false);
      return;
    } else {
      uploadProductsCsv(e, userid, userid)
        .then((response) => {
          const convertedArray = response.data.map((obj) => ({
            ...obj,
            Cantidad: Number(obj.Cantidad),
            PesoEnKg: Number(obj.PesoEnKg),
            MaterialPeligroso: obj?.MaterialPeligroso,
          }));
          invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia =
            invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia.concat(
              convertedArray
            );

          invoiceJson.Complementos.CartaPorte.Mercancias.PesoBrutoTotal =
            calculateTotalWeight(
              invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia
            );

          invoiceJson.Complementos.CartaPorte.Mercancias.NumTotalMercancias =
            invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia.length;

          window.localStorage.setItem(
            "invoiceModel",
            JSON.stringify(invoiceJson)
          );

          setInvoiceProducts(
            invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia
          );
          setItems(
            invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia.length
          );
          setLoadingCSV(false);
        })
        .catch((err) => {
          setAlert({
            ...alert,
            state: "error",
            show: true,
            message:
              "Hubo un problema al procesar el archivo. Intentalo de nuevo.",
          });
          setLoadingCSV(false);
          return;
        });
    }
  };

  const handleSearch = () => {
    setShowInputs(!showInputs);
  };

  const calculateTotalWeight = (products) => {
    return products.reduce(
      (total, objeto) =>
        total + Number((Number(objeto.PesoEnKg) || 0).toFixed(3)),
      0
    );
  };

  const handleSubmitManualInput = (e) => {
    if (
      inputProduct.UnidadPeso &&
      inputProduct.Descripcion &&
      inputProduct.Cantidad &&
      inputProduct.PesoEnKg &&
      inputProduct.ClaveUnidad
    ) {
      const productModel = {
        Cantidad: inputProduct.Cantidad * 1,
        BienesTransp: inputProduct.BienesTransp,
        Descripcion: inputProduct.Descripcion,
        ClaveUnidad: inputProduct.ClaveUnidad,
        PesoEnKg: inputProduct.PesoEnKg * 1,
        MaterialPeligroso: inputProduct.MaterialPeligroso,
        ValorMercancia: 100,
        Moneda: "MXN",
      };

      console.log(productModel);

      invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia.push(
        productModel
      );
      invoiceJson.Complementos.CartaPorte.Mercancias.UnidadPeso =
        inputProduct.UnidadPeso;
      invoiceJson.Complementos.CartaPorte.Mercancias.PesoBrutoTotal =
        inputProduct.PesoBrutoTotal * 1;
      invoiceJson.Complementos.CartaPorte.Mercancias.NumTotalMercancias =
        invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia.length;
      invoiceJson.Complementos.CartaPorte.Mercancias.PesoBrutoTotal =
        calculateTotalWeight(
          invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia
        );
      window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));

      setInvoiceProducts(
        invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia
      );
      setItems(invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia.length);
      setInputProduct({
        ...inputProduct,
        PesoBrutoTotal: calculateTotalWeight(
          invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia
        ),
      });
      setShowTable(true);

      setAlert({
        show: true,
        state: "success",
        message: "Item cargado exitosamente",
      });
    } else {
      setAlert({
        ...alert,
        show: true,
        state: "error",
        message:
          "No has completado los datos de la mercancía, por favor completa antes de agregar",
      });
    }
  };

  const handleDeleteItem = (product) => {
    let tempArr = [...invoiceProducts];
    let index = tempArr.indexOf(product);
    if (index !== -1) {
      tempArr.splice(index, 1);
    }

    invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia = tempArr;
    invoiceJson.Complementos.CartaPorte.Mercancias.PesoBrutoTotal =
      calculateTotalWeight(
        invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia
      );
    invoiceJson.Complementos.CartaPorte.Mercancias.NumTotalMercancias =
      tempArr.length;

    window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));

    setInvoiceProducts(tempArr);

    if (tempArr.length === 0) {
      setShowTable(false);
    }
  };

  const validateNecessaryDataAndSetCSV = (file) => {
    console.log(file);
    console.log(inputProduct);
    if (inputProduct.UnidadPeso === "") {
      setAlert({
        show: true,
        state: "warning",
        message: "Completa los datos generales",
      });
      setLoadingData(false);
      setLoadingCSV(false);
      return;
    } else {
      setCsv(file);
    }
  };

  const handleInputs = (e) => {
    if (e.target.id === "ClaveUnidad" || e.target.id === "Unidad") {
      setInputProduct({
        ...inputProduct,
        [e.target.id]: e.target.value.toUpperCase(),
      });
      return;
    }
    setInputProduct({...inputProduct, [e.target.id]: e.target.value});
  };

  const handleAutoComplete = (e, v, r) => {
    if (r === "selectOption") {
      setInputProduct({
        ...inputProduct,
        BienesTransp: v.Value,
        Descripcion: v.Name,
        MaterialPeligroso: v.DangerousMaterial,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setAlert({
        ...alert,
        show: false,
      });
      return;
    }

    setAlert({
      ...alert,
      show: true,
    });
  };

  const validateMercancia = () => {
    if (invoiceProducts.length === 0) {
      return false;
    }
    return true;
  };

  const handleInputErrorsUi = () => {
    if (invoiceProducts.length === 0) {
      setAlert({
        ...alert,
        show: true,
        state: "error",
        message: "Agrega al menos una mercancia",
      });
    }
  };

  const handleDeleteAllProducts = () => {
    setInvoiceProducts([]);
    setShowTable(false);
    setInputProduct({
      ...inputProduct,
      PesoBrutoTotal: 0,
    });

    invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia = [];
    invoiceJson.Complementos.CartaPorte.Mercancias.PesoBrutoTotal = 0;
    invoiceJson.Complementos.CartaPorte.Mercancias.NumTotalMercancias = 0;
    window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));
  };

  useImperativeHandle(ref, () => ({
    validate: validateMercancia,
    showErrors: handleInputErrorsUi,
  }));

  useEffect(() => {
    if (
      inputProduct.Descripcion !== undefined &&
      inputProduct.Descripcion.length > 3
    ) {
      setLoadingData(true);

      getProductsServices(userid, userid, inputProduct.Descripcion)
        .then((response) => {
          console.log(response.data);
          if (response.data.length > 0) {
            setProductsList(response.data);
            setLoadingData(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoadingData(false);
        });
    }
    return () => {
      setProductsList([]);
      setLoadingData(false);
    };
  }, [inputProduct.Descripcion]);

  useEffect(() => {
    if (invoiceProducts.length > 0) {
      setShowTable(true);
      setInputProduct({
        ...inputProduct,
        PesoBrutoTotal:
          invoiceJson.Complementos.CartaPorte.Mercancias.PesoBrutoTotal,
      });
    } else {
      setShowTable(false);
    }
  }, [invoiceProducts]);

  useEffect(() => {
    if (inputProduct.UnidadPeso !== undefined) {
      setCandAddFile(true);
    }
    return () => {
      setCandAddFile(false);
    };
  }, [inputProduct.UnidadPeso]);

  useEffect(() => {
    console.log(inputProduct.PesoBrutoTotal);
    if (inputProduct.PesoBrutoTotal != 0) {
      invoiceJson.Complementos.CartaPorte.Mercancias.PesoBrutoTotal = Number(
        Number(inputProduct.PesoBrutoTotal).toFixed(3)
      );
      window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));
    }
  }, [inputProduct.PesoBrutoTotal]);

  useEffect(() => {
    setInputProduct({...inputProduct, ValorMercancia: inputProduct.PesoEnKg});
  }, [inputProduct.PesoEnKg]);

  useEffect(() => {
    invoiceJson.Complementos.CartaPorte.Mercancias.UnidadPeso =
      inputProduct.UnidadPeso;
    invoiceJson.Complementos.CartaPorte.Mercancias.PesoBrutoTotal =
      calculateTotalWeight(
        invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia
      );
    invoiceJson.Complementos.CartaPorte.Mercancias.NumTotalMercancias =
      invoiceJson.Complementos.CartaPorte.Mercancias.Mercancia.length;
    window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));
  }, [inputProduct.UnidadPeso]);

  useEffect(() => {
    if (csv) {
      handleSubmit(csv);
    }
  }, [csv, inputProduct.UnidadPeso]);

  return (
    <>
      <Grid
        container
        spacing={2}
        component="Box"
        sx={{display: "flex", alignItems: "center", justifyContent: "center"}}
      >
        <Backdrop
          sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
          open={loadingCSV}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid
          container
          xs={12}
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="body2"
            sx={{fontSize: "1.5vh", fontWeight: "bold"}}
          >
            Peso de la caja
          </Typography>

          <Grid
            container
            xs={12}
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              marginTop: "2.5vh",
            }}
          >
            <Grid item xs={6} sm={3}>
              <TextField
                onChange={handleInputs}
                disabled={loadingData}
                id="UnidadPeso"
                label="Unidad de peso"
                value={inputProduct.UnidadPeso}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                onChange={handleInputs}
                disabled={loadingData}
                id="PesoBrutoTotal"
                label="Peso bruto total"
                type="number"
                value={inputProduct.PesoBrutoTotal}
              />
            </Grid>
          </Grid>
        </Grid>

        {!showInputs ? (
          <Grid item xs={12}>
            <Box
              sx={{
                width: "65%",
                margin: "auto",
                minHeight: "150",
                display: "flex",
                flexDirection: "Column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{fontSize: "1.5vh", fontWeight: "bold"}}
              >
                Mercancias
              </Typography>
              <DragAndDrop
                fileExtension={".csv"}
                componentId={"contained-button-file"}
                sx={{width: "10%"}}
                submit={validateNecessaryDataAndSetCSV}
                limit={1}
              />
              {csv && <DisplayFilePreview file={csv} />}
            </Box>
          </Grid>
        ) : null}

        <Grid item xs={showInputs ? 12 : 6}>
          {/* CONDITIONALLY SHOW UPLOAD FILE FOR PROCESSING PRODUCTS DATA */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleSearch}
              disabled={loadingData}
              sx={{color: "white"}}
              variant="contained"
              color="secondary"
            >
              {showInputs ? "Cancelar Búsqueda" : "Buscar manualmente"}
            </Button>
          </Box>
        </Grid>

        {showInputs && (
          <Grid item xs={12}>
            <Box
              component="div"
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Autocomplete
                sx={{width: "50%"}}
                closeText="Limpiar"
                id="Descripcion"
                clearOnBlur={true}
                value={{
                  Name: inputProduct.Descripcion,
                  Value: inputProduct.BienesTransp,
                }}
                onChange={handleAutoComplete}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Nombre del producto"
                      name="satCode"
                      onChange={handleInputs}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingData ? <CircularProgress /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  );
                }}
                options={productsList}
                getOptionLabel={(option) => `${option.Name}`}
                isOptionEqualToValue={(option, value) => {
                  return option.Name === value.Name;
                }}
              />
              <TextField
                onChange={handleInputs}
                sx={{width: "50%", margin: "2vh"}}
                value={inputProduct.ClaveUnidad}
                autoCapitalize="characters"
                id="ClaveUnidad"
                label="Clave unidad peso"
              />
              <TextField
                onChange={handleInputs}
                sx={{width: "50%", margin: "2vh"}}
                id="Cantidad"
                type="number"
                label="Cantidad"
                value={inputProduct.Cantidad}
              />
              <TextField
                onChange={handleInputs}
                sx={{width: "50%", margin: "2vh"}}
                id="PesoEnKg"
                type="number"
                label="Peso en KG"
                value={inputProduct.PesoEnKg}
              />
              <Button
                id="add_item"
                onClick={handleSubmitManualInput}
                sx={{margin: "2vh", background: "green", color: "white"}}
                variant="contained"
              >
                Agregar item
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>

      <Box sx={{display: "flex", align: "center", justifyContent: "center"}}>
        <Snackbar
          open={alert.show}
          autoHideDuration={2500}
          onClose={handleClose}
        >
          <Alert severity={alert.state || "info"}>{alert.message}</Alert>
        </Snackbar>
      </Box>

      {
        showTable && (
          <Box marginTop="6vh">
            {" "}
            {/*<Grid item xs={12}>*/}
            <Typography
              variant="body2"
              sx={{fontSize: "1.5vh", fontWeight: "bold"}}
            >
              Número total de mercancias: {invoiceProducts.length}
            </Typography>
            <Button
              startIcon={<Delete />}
              onClick={handleDeleteAllProducts}
              variant="contained"
              color="error"
            >
              Eliminar todos
            </Button>
            <DynamicMerchTable
              data={invoiceProducts}
              handleDelete={handleDeleteItem}
            />
          </Box>
        ) //</Grid>
      }
    </>
  );
});

function DynamicMerchTable({data, handleDelete}) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="dynamic table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Typography fontWeight={600} color="primary">
                {" "}
                Clave Mercancía
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="primary">
                {" "}
                Cantidad
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="primary">
                {" "}
                Unidad de peso
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="primary">
                {" "}
                Descripción
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="primary">
                Peso en KG
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="primary">
                {" "}
                Opciones
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row" align="center">
                {item.BienesTransp || item?.productCode?.Value || "-"}
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                {item.Cantidad || item?.cantidad || "-"}
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                {item.ClaveUnidad || item?.claveUnidad?.Value || "-"}
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                {item.Descripcion || item?.productCode?.Name || "-"}
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                {item.PesoEnKg || item?.pesoEnKg || "-"}
              </TableCell>
              <TableCell align="center">
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => handleDelete(item)}
                >
                  Eliminar
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export {Products, DynamicMerchTable, products};
