/**
 * Checks if a value is not empty, null, or undefined.
 * If the value is not a plain object (eg. Date, Array) will return true.
 * @param value - The value to be checked.
 * @returns Returns true if the value is not empty, null, or undefined. Otherwise, returns false.
 */
export const isNotEmptyNullOrUndefined = (value: string | object) => {
  if (value === null || value === undefined) {
    return false;
  }

  if (typeof value === "string" && value.trim().length === 0) {
    return false;
  }

  if (
    typeof value === "object" &&
    Object.keys(value).length === 0 &&
    value.constructor === Object
  ) {
    return false;
  }

  return true;
};
