import React, { useState, forwardRef } from "react";
import {
  Grid,
  CircularProgress,
  TextField,
  Button,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import { Send as SendIcon, Close } from "@mui/icons-material";
import useAuth from "../../../hooks/useAuth";
import { isValidEmail } from "../../../utils/validations/commonRegexp";
import sendInvoiceById from "../../../services/email/sendInvoiceById";
import { DragAndDrop } from "../../DragAndDrop/DragAndDrop";

const SendInvoiceComponent = forwardRef(
  ({ invoice, fromFiles = false, onFinish, onStarted, disableUse }, ref) => {
    const { userid } = useAuth();
    const [loading, setLoading] = useState(false);
    const [emailConfig, setEmailConfig] = useState({
      emailFrom: userid?.claims?.email || "",
      to: "",
      message: "",
    });
    const [show, setShow] = useState(false);
    const [snack, setSnack] = useState({
      open: false,
      message: "",
      severity: "success",
    });
    const [emailErrors, setEmailErrors] = useState({
      emailFrom: !isValidEmail(emailConfig.emailFrom) || false,
      to: !isValidEmail(emailConfig.to) || false,
    });

    const handleChangeEmailConfig = (e) => {
      const id = e.target.id;
      const value = e.target.value;

      setEmailConfig({
        ...emailConfig,
        [id]: value,
      });

      // Validate email and set error state
      if (id === "emailFrom" || id === "to") {
        setEmailErrors({
          ...emailErrors,
          [id]: !isValidEmail(value),
        });
      }
    };

    const sendInvoice = () => {
      setLoading(true);
      if (fromFiles) {
        console.log("SENDING FILES...");
        return;
      }
      const payload = {
        from: emailConfig.emailFrom,
        to: emailConfig.to,
        message: emailConfig.message,
      };

      const handleErrorUi = (err) => {
        if (err.response) {
          setSnack({
            open: true,
            message: "Error " + err.response.status + err.response.data.message,
            severity: "error",
          });
        }

        if (!err.response) {
          setSnack({
            open: true,
            message: "Error enviando factura",
            severity: "error",
          });
        }
      };

      if (emailErrors.emailFrom || emailErrors.to) {
        setSnack({
          open: true,
          message:
            "La información introducida no es válida verifica los campos marcados en rojo",
          severity: "warning",
        });
        setLoading(false);
        return;
      }

      sendInvoiceById(userid, userid, invoice._id, payload)
        .then(() => {
          setLoading(false);
          setSnack({
            open: true,
            message: "Factura enviada exitosamente",
            severity: "success",
          });
          setShow(false);
          setEmailConfig({
            emailFrom: "",
            to: "",
            message: "",
          });
          onFinish();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          handleErrorUi(err);
        });
    };

    const handleShow = () => {
      setShow(!show);
      onStarted();
    };

    return (
      <>
        <Grid container spacing={2}>
          {loading && (
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          )}
          {!loading && show && (
            <>
              {fromFiles && (
                <Grid item xs={12}>
                  <Alert severity="warning">
                    Selecciona los archivos que vas a enviar
                  </Alert>
                  <DragAndDrop
                    componentId="emailInvoiceDnD"
                    disabled={false}
                    limit={2}
                    fileExtension=".pdf,.xml"
                    submit={(file) => {
                      console.log(file);
                    }}
                    validFile={(file) => {
                      console.log(file);
                    }}
                  />
                </Grid>
              )}
              {!fromFiles && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      id="emailFrom"
                      label="De:"
                      variant="standard"
                      error={emailErrors.emailFrom}
                      helperText={
                        emailErrors.emailFrom && "Invalid email address"
                      }
                      type="email"
                      onChange={handleChangeEmailConfig}
                      value={emailConfig.emailFrom}
                      width="90%"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="to"
                      variant="standard"
                      type="email"
                      label="Para:"
                      error={emailErrors.to}
                      helperText={emailErrors.to && "Invalid email address"}
                      onChange={handleChangeEmailConfig}
                      value={emailConfig.to}
                      width="90%"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="message"
                      type="text"
                      multiline
                      variant="outlined"
                      label="Mensaje:"
                      onChange={handleChangeEmailConfig}
                      value={emailConfig.message}
                      width="90%"
                    />
                  </Grid>
                </>
              )}
            </>
          )}
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "row" }}>
            {show && (
              <Grid item xs={3}>
                <Tooltip title="Enviar Factura" arrow>
                  <IconButton color="success" onClick={sendInvoice}>
                    <SendIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleShow}
                endIcon={show ? <Close /> : <SendIcon />}
                disabled={disableUse}
              >
                {show ? "Cancelar" : "Enviar Factura"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar open={snack.open} autoHideDuration={10000}>
          <Alert severity={snack.severity}>{snack.message}</Alert>
        </Snackbar>
      </>
    );
  }
);

export default SendInvoiceComponent;
