import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  saveIssuerInPAC,
  updateIssuerCertificate,
  updateIssuerKey,
} from "../../../../../services/modules/invoices/issuers";
import useAuth from "../../../../../hooks/useAuth";
import {
  DragAndDrop,
  DisplayFilePreview,
} from "../../../../../components/DragAndDrop/DragAndDrop";

const CsdForm = ({ issuerRFC }) => {
  const { userid } = useAuth();
  const navigate = useNavigate();

  const [cert, setCert] = useState();
  const [key, setKey] = useState();
  const [keypass, setKeyPass] = useState();
  const [message, setMessage] = useState({
    key: "",
    cert: "",
  });
  const [loading, setLoading] = useState(true);

  const [errorSaving, setErrorSaving] = useState({
    state: false,
    origin: "",
  });
  const [alert, setAlert] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  const [hasSucceded, setHasSucceded] = useState({
    cert: false,
    key: false,
  });

  const handleReset = () => {
    setCert(null);
    setKey(null);
    setKeyPass("");
    setMessage({
      key: "",
      cert: "",
      canRetry: "",
    });
    setAlert({
      open: false,
      title: "",
      content: "",
      actions: [],
    });
    setDialog({
      open: false,
      title: "",
      content: "",
      actions: [],
    });
  };

  const handleSend = (e) => {
    e.preventDefault();
    console.log("enviando");
    setLoading(true);
    let msg = { key: "", cert: "" };
    let success = { key: "", cert: "" };
    if (!hasSucceded.cert) {
      let certFormData = new FormData();
      certFormData.append("Cer", cert);

      updateIssuerCertificate(userid, userid, certFormData, issuerRFC)
        .then((response) => {
          if (response.status === 201) {
            setLoading(false);
            success.cert = true;
            setAlert({
              ...alert,
              open: true,
              message: "Certificado cargado con éxito",
              status: "success",
            });
            msg.cert = "El certificado fue cargado con éxito";
            if (!hasSucceded.key) {
              let keyFormData = new FormData();
              keyFormData.append("Key", key);
              keyFormData.append("Password", keypass);
              updateIssuerKey(userid, userid, keyFormData, issuerRFC)
                .then((response) => {
                  if (response.status === 201) {
                    setLoading(false);
                    success.key = true;
                    setAlert({
                      ...alert,
                      open: true,
                      message: "Llave cargada con éxito",
                      status: "success",
                    });
                    msg.key = "La llave fue cargada con éxito";
                  }
                })
                .catch((err) => {
                  console.log(err);
                  console.log(err.response);
                  if(err.response){
                    setAlert({
                      ...alert,
                      open: true,
                      message: err.response.data.error + " " + err.response.data.message,
                      status: "error",
                    });
                    msg.key = err.response.data.error + " " + err.response.data.message;
                    return
                  }
                  setAlert({
                    ...alert,
                    open: true,
                    message: "Error inesperado al cargar la llave privada",
                    status: "error",
                  });       
                });
            }
          }
        })
        .catch((err) => {
          console.log(err)
          if(err.response){
            setAlert({
              ...alert,
              open: true,
              message: err.response.data.error + " " + err.response.data.message,
              status: "error",
            });
            msg.cert = err.response.data.error + " " + err.response.data.message;
            return
          }
          setAlert({
            ...alert,
            open: true,
            message: "Error inesperado al cargar el certificado",
            status: "error",
          });          
        });
    }

    setMessage(msg);
    setHasSucceded(success);
  };

  const botonesFallido = [
    { label: "Reintentar", execute: (e) => handleSend(e) },
    {
      label: "Agregar otro emisor",
      execute: () => {
        navigate(`/${userid.claims.rol}/invoices/NuevoEmisor`);
        window.location.reload();
      },
    },
    {
      label: "Volver a inicio",
      execute: () => navigate(`/${userid.claims.rol}/welcome`),
    },
  ];

  const botonesExito = [
    {
      label: "Agregar otro emisor",
      execute: () => {
        navigate(`/${userid.claims.rol}/invoices/NuevoEmisor`);
        window.location.reload();
      },
    },
    {
      label: "Volver a inicio",
      execute: () => navigate(`/${userid.claims.rol}/welcome`),
    },
  ];

  useEffect(() => {
    setDialog({
      ...dialog,
      open: message.key !== "" || message.cert !== "",
      title: "Resultados",
      content: (
        <>
          {Object.keys(message).map((llave) => {
            return <Typography>{message[llave]}</Typography>;
          })}
        </>
      ),
      actions:
        !hasSucceded.cert || !hasSucceded.key ? botonesFallido : botonesExito,
    });
  }, [hasSucceded.cert, hasSucceded.key, message.key, message.cert]);

  useEffect(() => {
    if (hasSucceded.cert && hasSucceded.key) {
      saveIssuerInPAC(userid, userid, {}, issuerRFC)
        .then((response) => {
          console.log("respuesta pac", response);
          setDialog({
            ...dialog,
            open: message.key !== "" || message.cert !== "",
            title: "Emisor activado",
            content: "El emisor ha sido activado en el PAC",
            actions: botonesExito,
          });
        })
        .catch((err) => {
          console.log("error pac", err, err.response);
          setDialog({
            ...dialog,
            open: message.key !== "" || message.cert !== "",
            title: "Error activando emisor",
            content: err.response.data.error + " " + err.response.data.message,
            actions: botonesFallido,
          })
        });
    }

    setDialog({
      ...dialog,
      open: message.key !== "" || message.cert !== "",
      title: "Resultados",
      content: (
        <>
          {Object.keys(message).map((llave) => {
            return <Typography>{message[llave]}</Typography>;
          })}
        </>
      ),
      actions:
        !hasSucceded.cert || !hasSucceded.key ? botonesFallido : botonesExito,
    });
  }, [hasSucceded.cert, hasSucceded.key]);

  return (
    <Grid
      container
      maxWidth="sm"
      spacing={2}
      sx={{ alignItems: "center", justifyContent: "center" }}
    >
      <Dialog
        open={dialog.open}
        onClose={() => {
          setDialog({
            ...dialog,
            open: false,
          });
        }}
      >
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.actions.map((action, index) => {
            return (
              <Button
                key={index}
                onClick={action.execute}
                variant={"contained"}
              >
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>

      <Grid
        item
        xs={6}
        sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}
      >
        <DragAndDrop
          setFile={setCert}
          fileExtension={".cer"}
          componentId={"upload-cert-btn"}
        />
      </Grid>

      <Grid
        item
        xs={6}
        sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}
      >
        <DragAndDrop
          setFile={setKey}
          fileExtension={".key"}
          componentId={"upload-key-btn"}
        />
      </Grid>

      <Grid
        item
        xs={6}
        sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}
      >
        {cert && <DisplayFilePreview file={cert} />}
      </Grid>

      <Grid
        item
        xs={6}
        sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}
      >
        {key && <DisplayFilePreview file={key} />}
      </Grid>

      <Grid item xs={12}>
        <TextField
          //disabled={errorSaving.state}
          label="Contraseña de llave privada"
          id="keypass"
          type="password"
          autoComplete="off"
          sx={{ minWidth: "100%" }}
          onChange={(e) => setKeyPass(e.target.value)}
        />
      </Grid>

      <Grid item xs={12} sx={{ justifyContent: "right", display: "flex" }}>
        <Button
          variant="contained"
          color="success"
          onClick={
            errorSaving.state
              ? () => console.log("save again") //saveAgain(errorSaving.origin)
              : (e) => handleSend(e)
          }
        >
          {errorSaving.state ? "Intentar de nuevo" : "Enviar"}
        </Button>
      </Grid>

      {alert.open && (
        <Snackbar
          open={alert.open}
          autoHideDuration={5000}
          onClose={() => {
            setAlert({
              ...alert,
              open: false,
            });
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => {
              setAlert({
                ...alert,
                open: false,
              });
            }}
            elevation={6}
            severity={alert.status}
            sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </Grid>
  );
};

export default CsdForm;
