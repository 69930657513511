export const createVehicleDto = {
  Niv: "",//required
  Plate: "",//required
  Price: "",
  Usage: "New",//required
  BoughtDate: new Date(),
  KmTraveled: 0,
  OperationStatus: "Operational",//required
  Valuation: null,
  Specs: {
    Type: {
      Code: "",//required
      Description: null,
      Name: null,
    },
    Body: null,
    ModelYear: "",//required
    Brand: "",//required
    Model: "",//required
    FuelSpecs: {//no required in create
      NumberTanks: "",
      CapacityInLiters: "",
      KmLtrSpec: "",
      KmLtrReal: "",
    },
  },
  Policy: {
    NoPoliza: "",//required
    InsuranceCompany: "",//required
    ExpiryDate: "",//required
    LastRenewal: "",
    PrimaCost: "",
    TotalCost: "",
    Document: null,
  },
  SCTPermission: {
    Number: "",
    Type: "---",
    IssuedDate: "",
    ExpirationDate: "",
  },
};
