import { Autocomplete, Grid, TextField } from "@mui/material";
import { DynamicTable } from "../CartaPorte/Operator";
import { useState } from "react";

const OperatorServiceComponent = ({
  setDialog,
  operatorsData,
  selected,
  setSelected,
  externalError = undefined,
}) => {
  const handleDeleteOperator = (operatorToDelete) => {
    setSelected(
      { target: { id: "Operadores", value: "" } },
      operatorToDelete,
      "clear"
    );
  };

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={3}
        justifyContent="center"
        minWidth={"xs"}
      >
        <Grid item xs={12} md={12}>
          <Autocomplete
            id="Operadores"
            autoComplete
            options={operatorsData}
            sx={{ width: "100%" }}
            onChange={setSelected}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Operador"
                name="Operator"
                error={externalError?.failed}
                helperText={externalError?.message}
              />
            )}
            getOptionLabel={(option) => {
              return !option
                ? ""
                : `${option.Nombre} ${option.ApellidoP} ${option.ApellidoM}`;
            }}
            isOptionEqualToValue={(o, v) => {
              if (v === "" || v === null || v === undefined) {
                return true;
              }

              return v.Rfc == o.Rfc;
            }}
            value={selected[selected.length - 1] || ""}
          />
        </Grid>
        {selected.length > 0 && (
          <Grid item xs={12}>
            <DynamicTable
              name="Operadores"
              data={selected}
              handleDelete={handleDeleteOperator}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export { OperatorServiceComponent };
