import {
  CircularProgress,
  Grid,
  Autocomplete,
  TextField,
  Button,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Backdrop,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import useAuth from "../../../hooks/useAuth";
import {getOperators} from "../../../services/modules/organization/operators";
import {useNavigate} from "react-router";
const datosOperadores = [];

const SelectOperator = ({props}) => {
  const invoiceJson = JSON.parse(window.localStorage.getItem("invoiceModel"));

  const [operatorRows, setOperatorRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const {userid} = useAuth();
  const [operatorsList, setOperatorList] = useState([]);
  const [selected, setSelected] = useState();
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  const getData = async () => {
    let operatorData = await getOperators(userid);
    return operatorData;
  };

  const handleSelectOperator = (e, v, r) => {
    if (r === "selectOption") {
      const operatorModelForInvoice = {
        TipoFigura: v.tipoFigura,
        RFCFigura: v.RFC,
        NombreFigura: v.firstName + " " + v.lastName,
        NumLicencia: v.NumLicencia,
      };

      setSelected(operatorModelForInvoice);
      if (operatorsList.length === 0) {
        let tempArr = [];
        tempArr.push(operatorModelForInvoice);
        setOperatorList(tempArr);
        setSelected();
        invoiceJson.Complementos.CartaPorte.FiguraTransporte = tempArr;

        window.localStorage.setItem(
          "invoiceModel",
          JSON.stringify(invoiceJson)
        );
        window.dispatchEvent(new Event("haCambiao"));
      }
    }
  };

  const handleAddOperator = () => {
    if (selected) {
      operatorsList.push(selected);
      setOperatorList(operatorsList);
      setSelected();
      invoiceJson.Complementos.CartaPorte.FiguraTransporte = operatorsList;

      window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));
      window.dispatchEvent(new Event("haCambiao"));
    }
  };

  const handleDeleteOperator = (operator) => {
    let tempArr = [...operatorsList];
    let index = tempArr.indexOf(operator);
    if (index !== -1) {
      tempArr.splice(index, 1);
    }

    invoiceJson.Complementos.CartaPorte.FiguraTransporte = tempArr;

    window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));

    setOperatorList(tempArr);
  };

  useEffect(() => {
    console.log("operators list true?", operatorsList);
    if (operatorsList.length > 0) {
      props.setComplete(true);
      setError(false);
      console.log("operators true");
    }
  }, [operatorsList]);

  useEffect(() => {
    if (invoiceJson.Complementos.CartaPorte.FiguraTransporte?.length !== 0)
      setOperatorList(invoiceJson.Complementos.CartaPorte.FiguraTransporte);
    else setOperatorList([]);
  }, []);

  useEffect(() => {
    getData()
      .then((res) => {
        if (res.data.data.length > 0) {
          try {
            const mappedResponse = res.data.data.map((obj) => {
              return {
                id: obj._id,
                firstName: obj.Nombre,
                lastName: obj.ApellidoP + " " + obj.ApellidoM,
                RFC: obj.Rfc,
                NumLicencia: obj.Licencia,
                NSS: obj.NSS,
                tipoFigura: obj.TipoContrato,
              };
            });
            setLoading(() => false);
            setOperatorRows(() => mappedResponse);
            return;
          } catch (err) {
            console.log(err);
            props.setDialog((dialog) => {
              return {
                ...dialog,
                open: true,
                title: "Error",
                message:
                  "Ocurrió un error inesperado al obtener los datos de los operadores",
              };
            });
            setOperatorRows([]);
          }
        }
        props.setDialog((dialog) => {
          return {
            ...dialog,
            open: true,
            title: "Sin empleados",
            message: "Agrega empleados a tu organizacion",
            actions: [
              {
                label: "Agregar",
                execute: () => {
                  props.setDialog((dialog) => {
                    return {
                      ...dialog,
                      open: false,
                    };
                  });
                  navigate(`/${userid.claims.rol}/empleados/crear`);
                },
              },
            ],
          };
        });
        setOperatorRows([]);
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 401:
              props.setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "Error",
                  message: "No tienes permiso para ver esta información",
                };
              });
              break;
            case 403:
              props.setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: "Error",
                  message: "No tienes permiso para ver esta información",
                };
              });
              break;
            case 404:
              props.setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: `Error ${err.response.status}`,
                  message: "Ocurrió un error al encontrar la ruta solicitada",
                };
              });
              break;
            case 500:
              props.setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: `Error ${err.response.status}`,
                  message: "Ocurrió un error en el servidor",
                };
              });
              break;
            default:
              props.setDialog((dialog) => {
                return {
                  ...dialog,
                  open: true,
                  title: `Error ${err.response.status}`,
                  message: "Ocurrió un error",
                };
              });
              break;
          }
          return;
        }

        setOperatorRows([]);
        props.setDialog((dialog) => {
          return {
            ...dialog,
            open: true,
            title: "Error",
            message:
              "Ocurrió un error inesperado al obtener los datos de los operadores",
          };
        });
      });

    return () => setOperatorRows((o) => [...o]);
  }, [userid]);

  useEffect(() => {
    if (props.hasTried && operatorsList.length === 0) {
      setError(true);
    }
  }, [props?.hasTried]);

  return (
    <Grid item xs={12}>
      <Grid item xs={12} md={9}>
        <Autocomplete
          autoComplete
          options={operatorRows}
          sx={{width: "100%"}}
          clearText="Limpiar"
          onChange={handleSelectOperator}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label="Operador"
                name="Operator"
                error={error}
                helperText={error ? "Seleccione al menos un operador" : null}
              />
            );
          }}
          getOptionLabel={(option) =>
            option.firstName + " " + option.lastName || ""
          }
          isOptionEqualToValue={(o, v) => {
            if (v === "") {
              return true;
            }

            return v.id === o.id;
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleAddOperator}
          sx={{color: "white", minHeight: "100%"}}
        >
          Añadir Operador
        </Button>
      </Grid>
      {operatorsList.length > 0 && (
        <Grid item xs={12}>
          <DynamicTable
            data={operatorsList}
            handleDelete={handleDeleteOperator}
          />
        </Grid>
      )}
    </Grid>
  );
};

function DynamicTable({data, handleDelete}) {
  console.log(data);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="dynamic table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Typography fontWeight={600} color="primary">
                {" "}
                Operadores
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="primary">
                {" "}
                Opciones
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {item?.NombreFigura ||
                  `${item?.Nombre || "-"} ${item?.ApellidoP || "-"} ${
                    item?.ApellidoM || "-"
                  }`}
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => handleDelete(item)}
                >
                  Eliminar
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export {SelectOperator, datosOperadores, DynamicTable};
