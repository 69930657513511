import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router";
import {
  Box,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import useAuth from "../../../../../hooks/useAuth";
import EditComponent from "../../../../../components/actions/EditComponent";
import {
  deleteExpeditionPLC,
  getExpeditionPLC,
} from "../../../../../services/modules/invoices/issuers";
import { AlertDialog } from "../../../../../components/userFeedback/AlertDialog";
import ferbyTheme from "../../../../../theme/ferbyTheme";
import { getErrorMessageFromStatus } from "../../../../../utils/httpResponses";

const ExpeditionPlacesIndex = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { userid } = useAuth();

  const goToCreate = () => {
    navigate(`/${userid.claims.rol}/issuers/places/new`);
  };

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
    duration: 0,
  });
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
    updateOpenState: (v) => setDialog({ ...dialog, open: v }),
  });

  const columns = [
    {
      field: "Name",
      headerName: "Nombre",
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "ZipCode",
      headerName: "Código Postal",
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "id",
      headerName: "Dirección",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return `${params.row.Street} #${params.row.ExteriorNumber}, Colonia ${params.row.Neighborhood}`;
      },
    },
    {
      field: "Actions",
      headerName: "Opciones",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return (
          <EditComponent
            editionRoute={`/${userid.claims.rol}/issuers/places/update`}
            objName={"Lugar"}
            onDelete={() => deletePlaces(params.row.id)}
            rowParams={params.row}
          />
        );
      },
    },
  ];

  const handleErrorUI = (err) => {
    if (err.response) {
      setAlert({
        ...alert,
        open: true,
        message: getErrorMessageFromStatus(err.response.status),
        severity: "error",
        duration: 6000,
      });
      return;
    }

    setDialog({
      ...dialog,
      open: true,
      title: "Error",
      content: "Ocurrió un error",
      actions: [
        {
          label: "Cerrar",
          onClick: () => {
            setDialog({ ...dialog, open: false });
          },
        },
      ],
    });
  };

  const getExpeditionPlaces = () => {
    const rowsToSet = [];
    getExpeditionPLC(userid)
      .then((res) => {
        res.data.forEach((element) => {
          console.log("elementor", element);
          rowsToSet.push({
            ...element,
            id: element._id || `x${element.ZipCode}`,
          });
        });
        setRows((ir) => rowsToSet);
        setLoading((l) => false);
      })
      .catch((err) => {
        console.log(err);
        setLoading((l) => false);
        handleErrorUI(err);
      });
  };

  const deletePlaces = (id) => {
    deleteExpeditionPLC(userid, id)
      .then((response) => {
        console.log(response);
        setAlert({
          ...alert,
          open: true,
          message: "Lugar eliminado con éxito",
          severity: "success",
          duration: 6000,
        });
        getExpeditionPlaces();
      })
      .catch((err) => {
        console.log(err.response);
        handleErrorUI(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    getExpeditionPlaces();
  }, []);

  return (
    <>
      <Backdrop open={loading}>
        <CircularProgress />
      </Backdrop>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "10vh",
          "& .header-table": {
            backgroundColor: ferbyTheme.palette.primary.main,
            color: "white",
          },
        }}
      >
        <AlertDialog {...dialog} />
        <Grid container spacing={2} maxWidth={"lg"}>
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="primary"
            >
              Consultar Lugares de Expedición
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" onClick={goToCreate} color="primary">
                Crear nuevo
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <DataGrid
              sx={{ minHeight: "50vh" }}
              columns={columns}
              rows={rows}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ExpeditionPlacesIndex;
