import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
//*MUI COMPONENTS
import {
  TextField,
  Alert,
  Autocomplete,
  Typography,
  CircularProgress,
  Button,
  Container,
  Paper,
  Grid,
  Backdrop,
  Collapse,
} from "@mui/material";
//*Hooks
import useAuth from "../../../hooks/useAuth.js";
//*Network, routing and API
import {useNavigate} from "react-router";
import {getIssuers} from "../../../services/modules/invoices/issuers.js";
import {
  createExpeditionPlace,
  saveExpeditionPlaceInPac,
} from "../../../services/modules/invoices/organization.js";
import {AlertDialog} from "../../userFeedback/AlertDialog.jsx";
import {
  getPlaceDetails,
  getPlacePrediction,
} from "../../../services/maps/mapsApi.js";
import GoogleMapsWrapper from "../../maps/GoogleMapsWrapper.jsx";
import AddressDto from "../../../utils/dto/address.dto.js";

const initialAddressState = {
  Name: "",
  Street: "",
  ExteriorNumber: "",
  InteriorNumber: "",
  Neighborhood: "",
  ZipCode: "",
  Municipality: "",
  Locality: "",
  State: "",
  Country: "México",
};

const initialAddressMapState = {
  ZipCode: "",
  Country: "",
  Street: "",
  StreetNumber: "",
  State: "",
  Locality: "",
  FullAddress: "",
  lattitude: "",
  longitude: "",
  PlaceId: "",
  description: "",
};

const ExpeditionPlaceForm = () => {
  const navigate = useNavigate();

  const {userid} = useAuth();

  const [formData, setFormData] = useState(initialAddressState);

  const validateAddress = (address) => {
    let valid = true;
    let invalid = {};

    for (const key in address) {
      if (address.hasOwnProperty(key)) {
        const value = address[key];
        if (
          key !== "Locality" &&
          key !== "InteriorNumber" &&
          (!value || value.trim() === "")
        ) {
          valid = false;
          invalid[key] = "Invalid field";
        }
      }
    }

    return {valid, invalid};
  };

  const [selected, setSelected] = useState({
    issuer: "",
  });

  const [backdrop, setBackdrop] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [{label: "", execute: () => {}}],
    keep: false,
  });

  const [issuersList, setIssuersList] = useState([]);

  const handleSavePlace = () => {
    console.log(formData, selected.issuer.Rfc);
    setBackdrop(true);
    //if everything is valid
    if (selected.issuer.Rfc !== "" && selected.issuer.Rfc !== undefined) {
      let {valid, invalid} = validateAddress(formData);
      console.log("valid invalid", valid, invalid);
      if (valid) {
        createExpeditionPlace(userid, userid, selected.issuer.Rfc, formData)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              saveExpeditionPlaceInPac(userid, userid, res.data.id)
                .then(() => {
                  setDialog({
                    ...dialog,
                    open: true,
                    title: "Éxito",
                    content:
                      "La dirección se ha guardado y activado con éxito!",
                    actions: [
                      {
                        label: "Continuar",
                        execute: () =>
                          navigate(`/${userid.claims.rol}/welcome`),
                      },
                    ],
                    keep: true,
                  });
                })
                .catch((err) => {
                  setDialog({
                    ...dialog,
                    open: true,
                    title: "Error",
                    content:
                      "La dirección se ha guardado pero no se ha podido activar, favor de contactar a soporte",
                    actions: [
                      {
                        label: "Continuar",
                        execute: () =>
                          navigate(`/${userid.claims.rol}/welcome`),
                      },
                    ],
                    keep: true,
                  });
                });
            }
            setBackdrop(false);
          })
          .catch((err) => {
            console.log("err", err.response, err.message);
            setBackdrop(false);
            setDialog({
              ...dialog,
              open: true,
              title: `Error (${err.response.status})`,
              content: `${err.response.data.message} ${err.response.data.error}`,
              actions: [
                {
                  label: "Reintentar",
                  execute: () => handleSavePlace(),
                },
                {
                  label: "Cerrar",
                  execute: () => setDialog({...dialog, open: false}),
                },
              ],
              keep: false,
            });
          });
      } else {
        setBackdrop(false);
        setDialog({
          ...dialog,
          open: true,
          title: "Completar Campos",
          content: "Favor de completar los campos requeridos",
          actions: [
            {
              label: "Ok",
              execute: () => setDialog({...dialog, open: false}),
            },
          ],
        });
      }
    } else {
      setBackdrop(false);
      setDialog({
        ...dialog,
        open: true,
        title: "Seleccione un emisor",
        content: "Es necesario seleccionar un emisor",
        actions: [
          {label: "Ok", execute: () => setDialog({...dialog, open: false})},
        ],
      });
    }
  };

  useEffect(() => {
    getIssuers(userid, userid)
      .then((res) => {
        console.log(res);
        setIssuersList(res.data.data);
      })
      .catch((err) => {
        setDialog({
          ...dialog,
          open: true,
          title: "Error obteniendo emisores",
          actions: [
            {
              label: "Recargar",
              execute: () => window.location.reload(),
            },
            {
              label: "Salir",
              execute: () => navigate(`/${userid.claims.rol}/welcome`),
            },
          ],
          keep: true,
        });
      });
  }, []);

  const handleShowDialog = () => {
    setDialog({...dialog, open: true});
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AlertDialog
          updateOpenState={handleShowDialog}
          open={dialog.open}
          title={dialog.title}
          content={dialog.content}
          actions={dialog.actions}
          keep={dialog.keep}
        />
        <Backdrop
          sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
          open={backdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Container component="main" maxWidth="sm" sx={{mb: 4}}>
          <Paper
            variant="outlined"
            sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}
          >
            <Typography component="h1" variant="h4" align="center">
              Nuevo Lugar de Expedición
            </Typography>

            <Grid container spacing={2} marginTop={"3vh"}>
              <Grid item xs={12}>
                <Autocomplete
                  id="issuer"
                  sx={{minWidth: "100%"}}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        //error={error.issuer.failed}
                        //helperText={error.issuer.message}
                        label="Emisor"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {backdrop ? <CircularProgress /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    );
                  }}
                  getOptionLabel={(option) => option.Name || ""}
                  options={issuersList}
                  onChange={(e, v, r) => {
                    switch (r) {
                      case "selectOption":
                        setSelected({...selected, issuer: v});

                        break;
                      case "clear":
                        setSelected({...selected, issuer: ""});
                        break;

                      default:
                        break;
                    }
                  }}
                  isOptionEqualToValue={(o, v) => {
                    if (v === "") {
                      return true;
                    } else {
                      return o.Name === v.Name;
                    }
                  }}
                  value={selected.issuer}
                  clearOnBlur
                />
                <Alert severity="info">
                  Es necesario vincular el lugar con un Emisor — Asegúrate de
                  seleccionarlo! o{" "}
                  <span
                    onClick={() =>
                      navigate(`/${userid.claims.rol}/invoices/NuevoEmisor`)
                    }
                    style={{cursor: "pointer", marginLeft: "5px"}}
                  >
                    {"<<Registra un nuevo emisor>>"}
                  </span>
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <GeneralAddressForm
                  formData={formData}
                  setFormData={setFormData}
                  handleSavePlace={handleSavePlace}
                  setLoading={setBackdrop}
                />
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </div>
    </>
  );
};

const GeneralAddressForm = forwardRef(
  (
    {formData, setFormData, showMap, handleSavePlace, userid, required = []},
    ref
  ) => {
    const defaultMapPosition = {
      lat: 19.4326,
      lng: -99.133217,
    };
    const [displayedPosition, setDisplayedPosition] =
      useState(defaultMapPosition);
    const [placePredictions, setPlacePredictions] = useState([]);
    const [placeDetails, setPlaceDetails] = useState(
      formData || JSON.parse(JSON.stringify(AddressDto))
    );
    const [errors, setErrors] = useState(
      Object.keys(JSON.parse(JSON.stringify(AddressDto))).reduce(
        (acum, current) => {
          acum[current] = {failed: false, message: ""};
          return acum;
        },
        {}
      )
    );
    const [mapSearchAlert, setMapSearchAlert] = useState({
      open: false,
      severity: "warning",
      title: "",
      message: "",
    });
    const [loadingOptions, setLoadingOptions] = useState(false);

    const handleMapsAutoCompleteChange = (e, v, r) => {
      if (r === "selectOption") {
        getPlaceDetails(userid, userid, v.place_id)
          .then((response) => {
            if (response.data.status === "OK") {
              const addressObject = {
                ...placeDetails,
              };
              console.log(response.data.result);
              response.data.result.address_components.forEach((component) => {
                if (component.types.indexOf("postal_code") !== -1) {
                  let cp = component.long_name;
                  addressObject.ZipCode = cp;
                }

                if (
                  component.types.indexOf("administrative_area_level_1") !== -1
                ) {
                  let state =
                    component.types.indexOf("administrative_area_level_1") !==
                    -1
                      ? component.long_name
                      : "";
                  addressObject.State = state;
                }

                if (component.types.indexOf("locality") !== -1) {
                  let locality = component.long_name;
                  addressObject.Locality = locality;
                }

                if (component.types.indexOf("route") !== -1) {
                  let street = component.long_name;
                  addressObject.Street = street;
                }

                if (component.types.indexOf("street_number") !== -1) {
                  let streetNumber = component.long_name;
                  addressObject.StreetNumber = streetNumber;
                }

                if (component.types.indexOf("country") !== -1) {
                  let country = component.long_name;
                  addressObject.Country = country;
                }
              });
              addressObject.FullAddress =
                response.data.result.formatted_address;
              addressObject.description = v.description;
              addressObject.PlaceId = v.place_id;
              addressObject.lattitude =
                response.data.result.geometry.location.lat;
              addressObject.longitude =
                response.data.result.geometry.location.lng;
              setPlaceDetails(addressObject);
              setFormData({target: {id: "Address"}}, addressObject, r);
              setDisplayedPosition({
                lat: response.data.result.geometry.location.lat,
                lng: response.data.result.geometry.location.lng,
              });
            }
          })
          .catch((err) => {
            if (err.response && err.response.status === 404) {
              setMapSearchAlert({
                ...alert,
                open: true,
                severity: "warning",
                message: "No se encontraron detalles de lugar",
                title: "Sin detalles",
              });
              return;
            }

            if (err.response && err.response.status === 500) {
              setMapSearchAlert({
                ...alert,
                open: true,
                severity: "error",
                message:
                  "Error obteniendo detalles de lugar. Contacta a soporte",
                title: "Error de servidort",
              });
              return;
            }

            if (err.response && err.response.status === 401) {
              setMapSearchAlert({
                ...alert,
                open: true,
                severity: "error",
                message: "Error de autorización",
                title: "Error de autorización",
              });
              return;
            }

            setMapSearchAlert({
              ...alert,
              open: true,
              severity: "error",
              message: "Error obteniendo detalles de lugar",
              title: "Error",
            });
            console.log(err);
          });
      }
    };

    const handleUserSearchInput = (e) => {
      const value = e.target.value;
      if (value.length > 4) {
        setLoadingOptions(() => true);
        getPlacePrediction(userid, userid, value)
          .then((response) => {
            if (
              !response?.data?.predictions ||
              response.data.status === "ZERO_RESULTS" ||
              response.data.status === "INVALID_REQUEST"
            ) {
              console.log("Having no predictions");
              setLoadingOptions(false);
              setPlacePredictions([]);
              return;
            }
            if (
              response.data &&
              response.data.predictions &&
              response.data.status === "OK" &&
              response.data.predictions.length > 0
            ) {
              console.log("Having good predictions");
              console.log(response.data.predictions);
              setLoadingOptions(() => false);
              setPlacePredictions(() => {
                return response.data.predictions;
              });
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response && err.response.status === 404) {
              setPlacePredictions([]);
              setMapSearchAlert({
                ...alert,
                open: true,
                severity: "warning",
                message: "No se encontraron coincidencias en la busqueda",
                title: "Sin coincidencias",
              });
              return;
            }

            if (err.response && err.response.status === 500) {
              setMapSearchAlert({
                ...alert,
                open: true,
                severity: "error",
                message: "Error obteniendo predicciones",
                title: "Error",
              });
              return;
            }

            if (err.response && err.response.status === 401) {
              setMapSearchAlert({
                ...alert,
                open: true,
                severity: "error",
                message: "Error de autorización",
                title: "Error",
              });
              return;
            }

            if (err.response && err.response.status === 400) {
              setMapSearchAlert({
                ...alert,
                open: true,
                severity: "error",
                message: "Introdcir un valor de busqueda valido",
                title: "Error",
              });
              return;
            }

            setMapSearchAlert({
              ...alert,
              open: true,
              severity: "error",
              message: "Error desconocido",
              title: "Error",
            });
          });
        return;
      }
      console.log("No valid input");
    };

    const handleChangeTextfields = (e, v, r) => {
      const id = e.target.id.split("-")[0];
      setPlaceDetails({...placeDetails, [id]: v});

      const propertiesAllowedToEdit = [
        "ZipCode",
        "ExteriorNumber",
        "InteriorNumber",
        "Neighborhood",
        "Street",
      ];
      if (propertiesAllowedToEdit.includes(id)) {
        setFormData(e, v, r);
      }
    };

    const validateAndShow = () => {
      let valid = [];
      if (required && ref) {
        setErrors((errors) => {
          let newErrors = {...errors};
          for (let i = 0; i < required.length; i++) {
            const elementId = required[i];
            if (
              placeDetails[elementId] === "" ||
              placeDetails[elementId] === null ||
              placeDetails[elementId] === undefined
            ) {
              valid.push(false);
              newErrors[elementId] = {
                failed: true,
                message: "Campo requerido",
              };
            }
          }
          return newErrors;
        });
        return !valid.some((v) => v === false);
      }
    };

    useEffect(() => {
      navigator.geolocation.getCurrentPosition((position) => {
        setDisplayedPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }, []);

    useImperativeHandle(ref, () => ({
      validateAndShow: validateAndShow,
    }));

    useEffect(() => {
      if (formData) {
        setPlaceDetails(formData);
      }
    }, [formData]);

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Autocomplete
            options={placePredictions}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Buscar lugar"
                  required={required.indexOf("placePrediction") !== -1}
                  id="predictionInput"
                  variant="filled"
                  onChange={handleUserSearchInput}
                />
              );
            }}
            id="placePrediction"
            sx={{minWidth: "100%"}}
            getOptionLabel={(option) => {
              console.log(option);
              return option?.description || "-";
            }}
            onChange={handleMapsAutoCompleteChange}
            autoComplete
            loading={loadingOptions}
            filterOptions={(x) => x}
          />
          <Collapse in={mapSearchAlert.open}>
            <Alert
              severity={mapSearchAlert.severity}
              title={mapSearchAlert.title}
            >
              {mapSearchAlert.message}
            </Alert>
          </Collapse>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Calle"
            id="Street"
            variant="standard"
            sx={{minWidth: "100%"}}
            onChange={(e) => handleChangeTextfields(e, e.target.value, "input")}
            value={placeDetails.Street || ""}
            required={required.indexOf("Street") !== -1}
            helperText={errors.Street.message}
            error={errors.Street.failed}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Número Exterior"
            id="ExteriorNumber"
            variant="standard"
            sx={{minWidth: "100%"}}
            onChange={(e) => handleChangeTextfields(e, e.target.value, "input")}
            value={placeDetails.ExteriorNumber || ""}
            required={required.indexOf("ExteriorNumber") !== -1}
            helperText={errors.ExteriorNumber.message}
            error={errors.ExteriorNumber.failed}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Número Interior (opcional)"
            id="InteriorNumber"
            variant="standard"
            sx={{minWidth: "100%"}}
            onChange={(e) => handleChangeTextfields(e, e.target.value, "input")}
            value={placeDetails.InteriorNumber || ""}
            required={required.indexOf("InteriorNumber") !== -1}
            helperText={errors.InteriorNumber.message}
            error={errors.InteriorNumber.failed}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Colonia"
            id="Neighborhood"
            variant="standard"
            sx={{minWidth: "100%"}}
            onChange={(e) => handleChangeTextfields(e, e.target.value, "input")}
            value={placeDetails.Neighborhood || ""}
            required={required.indexOf("Neighborhood") !== -1}
            helperText={errors.Neighborhood.message}
            error={errors.Neighborhood.failed}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Código Postal"
            id="ZipCode"
            variant="standard"
            type="number"
            sx={{minWidth: "100%"}}
            onChange={(e) => handleChangeTextfields(e, e.target.value, "input")}
            value={placeDetails.ZipCode || ""}
            required={required.indexOf("ZipCode") !== -1}
            helperText={errors.ZipCode.message}
            error={errors.ZipCode.failed}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Municipio o Localidad"
            id="Municipality"
            variant="standard"
            disabled
            sx={{minWidth: "100%"}}
            onChange={(e) => handleChangeTextfields(e, e.target.value, "input")}
            value={placeDetails.Municipality || ""}
            required={required.indexOf("Municipality") !== -1}
            helperText={errors.Municipality.message}
            error={errors.Municipality.failed}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Estado"
            id="State"
            variant="standard"
            disabled
            sx={{minWidth: "100%"}}
            onChange={(e) => handleChangeTextfields(e, e.target.value, "input")}
            value={placeDetails.State || ""}
            required={required.indexOf("State") !== -1}
            helperText={errors.State.message}
            error={errors.State.failed}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Localidad"
            id="Locality"
            variant="standard"
            disabled
            sx={{minWidth: "100%"}}
            onChange={(e) => handleChangeTextfields(e, e.target.value, "input")}
            value={placeDetails.Locality || ""}
            required={required.indexOf("Locality") !== -1}
            helperText={errors.Locality.message}
            error={errors.Locality.failed}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="País"
            id="Country"
            variant="standard"
            disabled
            sx={{minWidth: "100%"}}
            onChange={(e) => handleChangeTextfields(e, e.target.value, "input")}
            value={placeDetails.Country || ""}
            required={required.indexOf("Country") !== -1}
            helperText={errors.Country.message}
            error={errors.Country.failed}
          />
        </Grid>

        <Grid item xs={12} sx={{width: "20vh", height: "40vh"}}>
          <GoogleMapsWrapper location={displayedPosition} />
        </Grid>
        {handleSavePlace && (
          <Grid item xs={12} align="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSavePlace}
            >
              Guardar Dirección
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }
);

export {
  ExpeditionPlaceForm,
  initialAddressState,
  initialAddressMapState,
  GeneralAddressForm,
};
