import React from "react";
import { Grid, Typography } from "@mui/material";

const OperatorVehicleCard = ({ operator, vehicle }) => {
  return (
    <Grid container spacing={2}>
      {/* Operator Section */}
      <Grid item xs={12} md={6}>
        <Typography variant="body1">
          <b>Operador:</b>{" "}
          {`${operator?.firstName || operator.Nombre || "-"} ${
            operator?.lastName || operator.ApellidoP || ""
          } ${operator?.ApellidoM || ""}`}
        </Typography>
        <Typography variant="body2">
          <b>Rfc:</b> {operator?.RFC || operator?.Rfc || "-"}
        </Typography>
      </Grid>

      {/* Vehicle and Remolques Section */}
      <Grid item xs={12} md={6}>
        {/* Vehicle */}
        <Typography variant="body1">
          <b>Vehículo asignado:</b>
        </Typography>
        <Typography variant="body1">
          Modelo: {vehicle?.Tractor?.model || "-"}
        </Typography>
        <Typography variant="body1">
          Placas: {vehicle?.Tractor?.placaTractor || "-"}
        </Typography>

        {/* Remolques */}
        <Typography variant="body1" sx={{ mt: 2 }}>
          <b>Remolques:</b>
        </Typography>
        {vehicle?.Remolques?.length > 0 ? (
          vehicle?.Remolques?.map((remolque, index) => (
            <div key={index}>
              <Typography variant="body1">
                Tipo: {remolque?.tipoRem || "-"}
              </Typography>
              <Typography variant="body1">
                Placas: {remolque?.placaCaja || "-"}
              </Typography>
            </div>
          ))
        ) : (
          <Typography variant="body1">Sin remolques</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default OperatorVehicleCard;
