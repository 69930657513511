import React, {useEffect, useState} from "react";
import {
  GeneralAddressForm,
  initialAddressMapState,
} from "../Places/ExpeditionPlaceForm";
import {validateRfc} from "../../../modules/invoices/utils/regexp";
import {
  Alert,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const LoadingCompanyForm = ({
  handleAccept,
  isEdition,
  formData,
  setFormData,
  editObj,
  userid,
  type,
}) => {
  //*TODO: improve this component, make it more generic and reusbale on both services and invoices. As well as making it forwaredRef
  const [showAlert, setShowAlert] = useState(false);
  const [errorText, setErrorText] = useState({
    Rfc: null,
  });

  //*This is required because the update to the formData is not reflected inside the dialog
  //* because the parent component re-renders the content, and the parent of the parent re-renders the content
  //* this nested update is not triggering an update inside the dialog, because the dialog on the root parent react node
  //* doesnt detect a change directly into the dialog state ({open:true, title:"title", content:ReactNode, actions:[]}), these
  //* properties keeps the same, so no update iside is triggered, thats why a local update is needed. This may just be needed
  //* when the Loading CompanyForm is renddered inside a dialog or modal, or other component that is not on the same root node.
  const [localFormData, setLocalFormData] = useState({
    Rfc: formData ? formData?.Rfc : "",
    TaxName: formData ? formData?.TaxName : "",
    Type: formData ? formData?.Type : "",
    Address: {
      ZipCode: formData ? formData?.Address?.ZipCode : "",
      Country: formData ? formData?.Address?.Country : "",
      State: formData ? formData?.Address?.State : "",
      Street: formData ? formData?.Address?.Street : "",
      InteriorNumber: formData ? formData?.Address?.InteriorNumber : "",
      ExteriorNumber: formData ? formData?.Address?.ExteriorNumber : "",
      Neighborhood: formData ? formData?.Address?.Neighborhood : "",
      Locality: formData ? formData?.Address?.Locality : "",
      Municipality: formData ? formData?.Address?.Municipality : "",
      placeId: formData ? formData?.Address?.placeId : "",
      lattitude: formData ? formData?.Address?.lattitude : "",
      longitude: formData ? formData?.Address?.longitude : "",
    },
  });

  function validateCompany(obj) {
    console.log("objeto a validar", obj);
    const optionalProperties = [
      "Neighborhood",
      "Municipality",
      "Locality",
      "InteriorNumber",
    ];

    function isNullOrWhitespace(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === "string" && value.trim() === "")
      );
    }

    function validateProperties(obj) {
      for (const prop in obj) {
        if (optionalProperties.includes(prop)) {
          continue;
        }

        const value = obj[prop];

        if (typeof value === "object") {
          if (!validateProperties(value)) {
            return false;
          }
        } else if (isNullOrWhitespace(value)) {
          return false;
        }
      }
      return true;
    }

    return validateProperties(obj);
  }

  const handleChangeDestinies = (e, v, r, type) => {
    const key = type;
    const id = e.target.id;

    function isOutSideAddress(id) {
      const outOfAddressProps = ["Rfc", "TaxName", "Type"];
      return outOfAddressProps.includes(id);
    }

    // setFormData(e, v, r, type);

    if (id !== "Address") {
      if (isOutSideAddress(id)) {
        const newFormData = {
          ...localFormData,
          [id]: v,
        };
        setLocalFormData(newFormData);
        setFormData(newFormData, type);
        return;
      }
      const newAddress = {
        ...localFormData,
        Address: {
          ...localFormData.Address,
          [id]: v,
        },
      };
      setLocalFormData(newAddress);
      setFormData(newAddress, type);
      return;
    }

    setLocalFormData({
      ...localFormData,
      [id]: v,
    });
    setFormData({
      ...localFormData,
      [id]: v,
    }, type);
  };

  const handleRfc = (e, v, r, type) => {
    if (validateRfc(String(v).toUpperCase())) {
      setErrorText({...errorText, Rfc: null});
    } else {
      setErrorText({...errorText, Rfc: "Formato inválido"});
    }
    handleChangeDestinies(e, v, r, type);
  };

  useEffect(() => {
    if (isEdition) {
      setLocalFormData(editObj);
    }
  }, [isEdition, editObj]);

  return (
    <Grid container spacing={2} sx={{paddingTop: "2vh"}}>
      <Grid item xs={12}>
        <TextField
          id="TaxName"
          label="Nombre Fiscal"
          onChange={(e) =>
            handleChangeDestinies(e, e.target.value, "input", type)
          }
          sx={{width: "100%"}}
          value={localFormData.TaxName || ""}
        ></TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="Rfc"
          error={errorText.Rfc !== null}
          label={errorText.Rfc ? errorText.Rfc : "RFC"}
          onChange={(e) => handleRfc(e, e.target.value, "input", type)}
          sx={{width: "100%"}}
          value={localFormData.Rfc}
        ></TextField>
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{width: "100%"}}>
          <InputLabel>Tipo de compañía</InputLabel>
          <Select
            label="Tipo de compañía"
            onChange={(e) =>
              handleChangeDestinies(
                {target: {id: "Type"}},
                e.target.value,
                "selectOption",
                type
              )
            }
            value={localFormData.Type}
          >
            <MenuItem value="Almacenadora">Almacenadora</MenuItem>
            <MenuItem value="CEDIS">CEDIS</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <GeneralAddressForm
          formData={localFormData.Address}
          setFormData={(e, v, r) => handleChangeDestinies(e, v, r, type)}
          showMap
          userid={userid}
          handleSavePlace={handleAccept}
        />
      </Grid>
      {showAlert && (
        <Alert severity="error">
          Asegúrate de que se completaron todos los campos
        </Alert>
      )}
    </Grid>
  );
};

export {LoadingCompanyForm};
