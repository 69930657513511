import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Chip,
  Grid,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import {useNavigate} from "react-router";

import {
  getAnalyticsByRange,
  getCustomers,
  getIssuedDocs,
} from "../../../services/modules/invoices/organization";
import {toIsoHelper} from "../../../components/Forms/Nomina/NominaDateTypeComponent";
import {
  FacturadoTotalComponent,
  IngresoTotalComponent,
} from "./components/cards/Totales";
import {WelcomeMessage} from "./components/WelcomeMessage";
import {WelcomePage} from "./components/WelcomePage";
import {DateRangeSlider} from "./components/DateRangeSlider";
import {
  DashboardDataGrid,
  RenderActions,
  getDashboardMappedRows,
} from "./components/DashboardDatagrid";
import DashboardChartComponent from "./components/charts/InvoicingDashboardChartComponent";
import WelcomeView from "../../views/WelcomeView";
import {getIssuers} from "../../../services/modules/invoices/issuers";
import {FilteringComponent} from "./components/utils/FilteringComponent";
import {
  getFilteredServices,
  getServices,
} from "../../../services/modules/services/servicesGeneral";

import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import TimerIcon from "@mui/icons-material/Timer";
import {OperatorsDashboardComponent} from "../../services/dashboard/OperatorsDashboard";

// Main container component
const Dashboard = ({components}) => {
  return (
    <Box
      sx={{
        paddingY: "5vh",
        paddingLeft: "3vh",
      }}
    >
      <Grid
        container
        spacing={2}
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"row"}
        sx={{maxWidth: "100%", padding: "auto"}}
      >
        {components.map((component, index) => (
          <Grid item {...component.gridWidth} key={index}>
            <component.Component />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

//Full width data card to put components in
const DataComponent = ({title, data, sx}) => {
  return (
    <Grid item xs={12} sx={sx}>
      <Typography variant="h5" color="primary">
        {title}
      </Typography>
      <Grid container spacing={3}>
        {data.map((component, index) => (
          <Grid item {...component.gridWidth} key={index}>
            <component.Component />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

//THIS* Dashboard
const InvoiceDashboard = () => {
  const [loading, setLoading] = useState(false);

  const [showAmountChart, setShowAmountChart] = useState(false);

  const today = new Date();
  const [rango, setRango] = useState([
    new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
    today,
  ]);

  const [showChunksTextfield, setShowChunksTextfield] = useState(false);
  const [graphValue, setGraphValue] = useState(4);
  const [debouncedGraphValue, setDebouncedGraphValue] = useState(4);

  const [testResponse, setTestResponse] = useState();

  const {userid} = useAuth();
  const navigate = useNavigate();
  const userRole = userid.claims.rol;

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  const [rawRows, setRawRows] = useState([]);
  const [datagridRows, setDatagridRows] = useState([]);

  const [options, setOptions] = useState({clients: [], issuerList: []});

  const [filters, setFilters] = useState([
    {
      label: "tipo",
      param: "GeneralModel.CfdiType.Type",
      value: "I",
    },
  ]);

  const [selectedFilters, setSelectedFilters] = useState({
    client: {},
    issuer: {},
  });

  const [selectedClientsArray, setSelectedClientsArray] = useState([]);

  const dashboardColumns = [
    {
      field: "id",
      headerName: "Id",
      maxWidth: 60,
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "emisor",
      headerName: "Emisor",
      width: 360,
      height: "auto",
      flex: 1,
      headerClassName: "header-table",

      renderCell: (params) => {
        const {emisorName, emisorRfc, emisorRegistration} = params.row;
        const emisorText = `${emisorName} (${emisorRfc}) - ${emisorRegistration}`;
        return <Typography variant="caption">{emisorText}</Typography>;
      },
    },
    {
      field: "receptor",
      headerName: "Receptor",
      width: 300,
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        const {receptorName, receptorRfc, receptorRegistration} = params.row;
        const receptorText = `${receptorName} (${receptorRfc}) - ${receptorRegistration}`;
        return <Typography variant="caption">{receptorText}</Typography>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "_id",
      headerName: "Acciones",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return (
          <RenderActions
            data={params}
            dialog={dialog}
            setDialog={setDialog}
            rawRows={rawRows}
          />
        );
      },
    },
  ];

  const dashboardCardSX = {
    p: 2,
    borderRadius: 2,
    boxShadow: 1,
    backgroundColor: "#fff",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  };

  const getComponents = (user) => {
    switch (user) {
      case "admin":
        return [
          {
            gridWidth: {xs: 6},
            Component: () => (
              <WelcomePage title={<WelcomeMessage userName={userRole} />} />
            ),
          },
          {
            gridWidth: {xs: 6},
            Component: () => (
              <Box display="flex" justifyContent={"center"}>
                <Button
                  variant="contained"
                  onClick={() => navigate(`/${userRole}/invoices`)}
                >
                  Nueva Factura
                </Button>
              </Box>
            ),
          },
          {
            /*Test composition */
            gridWidth: {xs: 12},
            Component: () => (
              <DataComponent
                title={"Periodo seleccionado"}
                sx={dashboardCardSX}
                data={[
                  {
                    gridWidth: {xs: 12, md: 4},
                    Component: () => (
                      <DateRangeSlider
                        dateRange={rango}
                        setDateRange={setRango}
                        minimumDisplay="01/01/2023"
                      />
                    ),
                  },
                  {
                    gridWidth: {xs: 12, sm: 6, md: 4},
                    Component: () => (
                      <FacturadoTotalComponent
                        total={
                          testResponse ? testResponse.totalIssuedIncome : 0
                        }
                      />
                    ),
                  },
                  {
                    gridWidth: {xs: 12, sm: 6, md: 4},
                    Component: () => (
                      <IngresoTotalComponent
                        total={testResponse ? testResponse.totalPaidIncome : 0}
                      />
                    ),
                  },

                  {
                    gridWidth: {xs: 12, lg: 4},
                    Component: () => (
                      <Container>
                        <FilteringComponent
                          options={options}
                          filters={filters}
                          setFilters={setFilters}
                          selected={selectedFilters}
                          setSelected={setSelectedFilters}
                          setSelectedClientsArray={setSelectedClientsArray}
                          selectedClientsArray={selectedClientsArray}
                        />
                        <Box sx={{minWidth: "100%", marginTop: "3vh"}}>
                          <Typography variant="button" color="primary">
                            Últimas Facturas
                          </Typography>
                          <DashboardDataGrid
                            columns={dashboardColumns}
                            max={10}
                            rows={datagridRows}
                            locked={true}
                            height={500}
                          />
                        </Box>
                      </Container>
                    ),
                  },
                  {
                    gridWidth: {xs: 12, lg: 8},
                    Component: () => (
                      <DashboardChartComponent
                        testResponse={testResponse}
                        showAmountChart={showAmountChart}
                        showChunksTextfield={showChunksTextfield}
                        graphValue={graphValue}
                        debouncedGraphValue={debouncedGraphValue}
                        handleChange={debouncedChangeHandler}
                        setShowAmountChart={setShowAmountChart}
                        setShowChunksTextfield={setShowChunksTextfield}
                      />
                    ),
                  },
                ]}
              />
            ),
          },
        ];
      case "operator":
        return [
          {
            gridWidth: {xs: 12},
            Component: () => <OperatorsDashboardComponent />,
          },
        ];
      case "employee":
        return [
          {
            gridWidth: {xs: 12},
            Component: () => <WelcomeView />,
          },
        ];
      default:
        return [
          {
            gridWidth: {xs: 12},
            Component: () => <WelcomeView />,
          },
        ];
        break;
    }
  };

  const components = getComponents(userRole);

  useEffect(
    () => {
      if (userRole === "admin") {
        let newFilters = filters.concat([
          {
            label: "inicio",
            param: "GeneralModel.Date$gte",
            value: toIsoHelper(rango[0]),
          },
          {
            label: "fin",
            param: "GeneralModel.Date$lte",
            value: toIsoHelper(rango[1]),
          },
        ]);

        const formatDate = (date) => {
          return date.toLocaleDateString("en-CA", {
            year: "numeric",
            day: "2-digit",
            month: "2-digit",
          });
        };

        const isoRangeArray = [formatDate(rango[0]), formatDate(rango[1])];
        getIssuedDocs(userid, userid, 1, newFilters)
          .then((response) => {
            setRawRows(response.data.data);
            setDatagridRows(getDashboardMappedRows(response.data.data));
          })
          .catch((err) => console.log(err.response));

        setLoading(true);
        if (debouncedGraphValue !== "0" && debouncedGraphValue !== 0) {
          getAnalyticsByRange(
            userid,
            userid,
            isoRangeArray,
            showChunksTextfield ? null : debouncedGraphValue,
            showChunksTextfield ? debouncedGraphValue : null,
            filters
          )
            .then((response) => {
              setTestResponse(response.data);
              setLoading(false);
            })
            .catch((err) => {
              console.log("error");
              setLoading(false);
            });
        } else {
          setLoading(false);
          setDialog({
            ...dialog,
            open: true,
            title: "El valor no puede ser cero",
            actions: [
              {
                label: "Ok",
                execute: () => {
                  setGraphValue(4);
                  setDialog({...dialog, open: false});
                },
              },
            ],
          });
        }
      }
    }, // Adjust the delay as needed
    [rango, showChunksTextfield, debouncedGraphValue, filters]
  );

  const changeHandler = (event) => {
    setDebouncedGraphValue(event.target.value);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 400), []);

  useEffect(() => {
    getCustomers(userid)
      .then(({data}) => {
        setOptions((obj) => {
          return {...obj, clients: data.data};
        });
        setLoading((obj) => {
          return false;
        });
      })
      .catch((err) => {
        console.log("customers", err);
      });

    getIssuers(userid)
      .then((res) => {
        setLoading((obj) => {
          return false;
        });
        if (res.data.data.length > 0) {
          setOptions((obj) => {
            return {...obj, issuerList: res.data.data};
          });
        }
      })
      .catch((err) => {
        console.log("issuers", err);
        setDialog({
          ...dialog,
          open: true,
          title: "Error al cargar los emisores",
          content: "Por favor intente de nuevo más tarde",
          actions: [
            {
              label: "Ok",
              execute: () => {
                setDialog({...dialog, open: false});
              },
            },
          ],
        });
      });
  }, []);

  return (
    <>
      <Backdrop
        sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dashboard components={components} />
    </>
  );
};

export default InvoiceDashboard;
