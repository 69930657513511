import apiObj from "../../axios";

const { api, version } = apiObj;

const API_CONTEXT = (where, what) => {
  const prediction = {
    PredictByInput: (usr, cred, query) =>
      api.get(`${version}/maps/place/autocomplete?input=${query}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
  };

  const details = {
    DetailsByPlaceId: (usr, cred, placeId) =>
      api.get(`${version}/maps/place/details/${placeId}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
  };

  const directions = {
    Route: (userid, credential, origin, destination, params) => {
      return api.get(`${version}/maps/directions/route`, {
        headers: {
          user: userid,
          authorization: userid.token,
        },
        params: {
          origin: origin,
          destination: destination,
        },
      });
    },
  };

  const methods = {
    Predictions: prediction,
    Details: details,
    Directions: directions,
  };

  return methods[where][what];
};

export const getPlacePrediction = (usr, cred, query) => {
  const method = API_CONTEXT("Predictions", "PredictByInput");

  return method(usr, cred, query);
};

export const getPlaceDetails = (usr, cred, placeId) => {
  const method = API_CONTEXT("Details", "DetailsByPlaceId");

  return method(usr, cred, placeId);
};

export const getRoute = (usr, cred, origin, destination, params) => {
  const method = API_CONTEXT("Directions", "Route");
  return method(usr, cred, origin, destination, params);
};
