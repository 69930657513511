import { Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";

const UpdateIssuerSeriesForm = ({
  handleInputChange,
  handleInputDateChange,
  errorManager,
  externalValue,
}) => {
  return (
    <>
      <Grid
        container
        spacing={2}
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        justifyItems="center"
        mb="2vh"
      >
        <Grid item xs={4}>
          <TextField
            id="Serie"
            label="Serie"
            variant="standard"
            required
            onChange={handleInputChange}
            error={errorManager.Serie}
            helperText={errorManager.Serie ? "La serie es invalida" : ""}
            value={externalValue.Serie}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="InitFolio"
            label="Folio inicial"
            variant="standard"
            required
            onChange={handleInputChange}
            error={errorManager.InitFolio}
            helperText={errorManager.InitFolio ? "El folio es inválido" : ""}
            type="number"
            value={externalValue.InitFolio}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="EndFolio"
            label="Folio final"
            variant="standard"
            required
            onChange={handleInputChange}
            error={errorManager.EndFolio}
            helperText={errorManager.EndFolio ? "El folio es inválido" : ""}
            type="number"
            value={externalValue.EndFolio}
          />
        </Grid>
      </Grid>

      {/* FOLIO ACTUAL Y VIGENCIA */}
      <Grid
        container
        spacing={2}
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        justifyItems="center"
        mb="2vh"
      >
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Vigencia hasta:"
              onChange={handleInputDateChange}
              value={externalValue.ValidUntilAt}
              disablePast
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="Folio"
            label="Folio actual"
            variant="standard"
            required={false}
            onChange={handleInputChange}
            error={errorManager.Folio}
            helperText={errorManager.Folio ? "El folio actual es invalido" : ""}
            type="number"
            value={externalValue.Folio}
          />
        </Grid>
      </Grid>
    </>
  );
};

export { UpdateIssuerSeriesForm };
