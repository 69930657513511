import apiObj from "../../../axios"
import axios from "axios";

const {api, version} = apiObj;

const APIKEY = process.env.GOOGLE_MAPS_API_KEY;
const WORKING = "AIzaSyBkQYV-dHwYcpxW5t4kchmX4KWxvBsXk5k"

export const MAPS_API_CONTEXT = (where, what) => {

    const Qsoft = {
        "getCoordinatesForPostalCode": (postalCode) => {
            console.log(APIKEY)
            return axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode}&components=country:MX&key=${WORKING}`)
              .then(response => {
                const results = response.data.results;
                if (results.length > 0 && results[0].types.includes("postal_code")) {
                  const location = results[0].geometry.location;
                  return { lat: location.lat, lng: location.lng };
                } else {
                  throw new Error("Invalid postal code or location not found");
                }
              })
              .catch(error => {
                throw new Error("Error fetching coordinates from Google Maps API: " + error.message);
              });
          },
    }
    

    const methods = {
        "qsoft":             Qsoft,

    }
    
    return methods[where][what]
}