import ListTable from "../Tables/ListTable";

const ExecutionStatusCard = ({ data }) => {
  return (
    <ListTable
      emptyMsg="Sin estatus de ejecución"
      data={data}
      sx={{ height: "80%" }}
    />
  );
};

export default ExecutionStatusCard;
