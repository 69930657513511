import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const AlertDialog = ({
  updateOpenState,
  open,
  title,
  content,
  actions,
  keep,
}) => {
  const handleClose = (e, r) => {
    if (r === "backdropClick" && keep) {
      return;
    }
    if (updateOpenState) {
      updateOpenState(false);
    }
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>

      <DialogActions>
        {actions?.map((act, key) => {
          if (React.isValidElement(act)) {
            return act;
          }

          return (
            <Button key={key} variant="contained" onClick={act.execute}>
              {act.label}
            </Button>
          );
        })}
      </DialogActions>
    </Dialog>
  );
};

export const InitialDialogState = {
  open: false,
  keep: false,
  title: "",
  content: "",
  actions: [],
  updateOpenState: () => {
    return;
  },
};

export const getGenericErrorDialogContent = (status) => {
  switch (status) {
    case 401:
      return "No tienes autorización para consultar esta información, verifica que tienes conexión y tu sesión sigue activa.";
    case 400:
      return "El servidor no ha comprendido lo que necesitas, por favor intenta de nuevo, o contacta a soporte.";
    case 500:
      return "Hubo un error en el servidor al obtener los datos. Inténtalo de nuevo más tarde, o recarga la página. Si el error persiste contacta a soporte.";
    default:
      return "Hubo un error desconocido. Inténtalo de nuevo más tarde, o recarga la página. Si el error persiste contacta a soporte.";
  }
};
