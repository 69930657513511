import React, { useState } from "react";

//*MUI COMPONENTS
import { Box } from "@mui/system";
import {
  Button,
  Tab,
  TextField,
  Typography,
  Alert,
  AlertTitle,
  Collapse,
  CircularProgress,
  Grid,
  Snackbar,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

//*Responsive config
import * as Responsive from "../ResponsiveOptions";

//*assets
import LoginIcon from "@mui/icons-material/Login";

//*Auth
import useAuth from "../hooks/useAuth";
import "../firebase";
import { getAuth, signInWithCustomToken, signOut } from "firebase/auth";

//*Network and api
import { useNavigate } from "react-router-dom";
import apiObj from "../axios";
const { api, apiVersion } = apiObj;

const FervyLogo = "";

const auth = getAuth();

const Login = () => {
  const [value, setValue] = useState("0");
  const [signInData, setSignInData] = useState({});
  const [alert, setAlert] = useState({
    open: false,
    status: "info",
    title: "",
    slideMessage: "",
    slide: false,
  });
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  const { logout, googleSignIn, isValidCredential } = useAuth();

  const handleTabChange = (e, nv) => {
    setValue(nv);
  };

  const handleSignInData = (e) => {
    setSignInData({
      ...signInData,
      [e.target.id]: e.target.value,
    });
  };

  const handleRegisterRedirect = () => {
    navigate("/signup");
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await api.post(
        `/api/v1/auth/8pCaRC=PHF.aUD$pOxkY}A1ZC[N*YMtNGf&&aUPSJxs51DC8f/signin`,
        signInData
      );

      const token = data;

      const credential = await signInWithCustomToken(auth, token);

      const { isValid, reason } = isValidCredential(credential);

      if (!isValid) {
        setAlert({
          ...alert,
          open: true,
          status: "warning",
          title: "Error de acceso",
          slideMessage: reason,
          slide: true,
        });
      }

      auth.currentUser
        .getIdTokenResult(false)
        .then((user) => {
          const rol = user.claims.rol;
          navigate(`/${rol}/welcome`);
        })
        .catch((err) => {
          console.log(err);
          setAlert({
            ...alert,
            status: "error",
            slide: true,
            open: false,
            slideMessage: "Hubo un problema en el acceso, intenta de nuevo.",
            message: "",
          });
        });
    } catch (err) {
      setLoading(false);
      console.log(err);

      switch (err.code) {
        case "auth/user-not-found":
          setAlert({
            ...alert,
            status: "error",
            slide: true,
            open: false,
            slideMessage: "Error: no estás registrado",
            message: "",
          });
          break;
        case "auth/wrong-password":
          setAlert({
            ...alert,
            status: "error",
            slide: true,
            open: false,
            slideMessage: "Error: Revisa el correo o la contraseña",
            message: "",
          });
          break;
        default:
          setAlert({
            ...alert,
            status: "error",
            slide: true,
            open: false,
            slideMessage: `Error: ${err.message}`,
            message: "",
          });
          break;
      }
    }
  };

  const handleCloseAlert = () => {
    setAlert({
      ...alert,
      open: false,
      slide: false,
    });
  };

  return (
    <>
      <form onSubmit={handleSignIn}>
        <Grid
          container
          sx={{
            justifyContent: "center", // Center horizontally
            justifyItems: "center", // Center horizontally
            alignItems: "center", // Center vertically
            alignContent: "center", // Center vertically
            height: "100vh",
            padding: "0",
          }}
          spacing={2}
        >
          <Grid
            item
            xs={8}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "1vh 0",
            }}
          >
            <Typography
              variant="h3"
              sx={{ fontSize: "4vh", fontWeight: "light", margin: "2vh 0" }}
            >
              Bienvenido, inicia sesión
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              variant="outlined"
              name="user"
              id="email"
              label="Correo electrónico"
              autoComplete="on"
              onChange={handleSignInData}
              sx={{ width: "50%" }}
            />
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{ width: "50%" }}
              variant="outlined"
              name="pass"
              type="password"
              id="password"
              label="Contraseña"
              autoComplete="off"
              onChange={handleSignInData}
            />
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              sx={{ width: "50%" }}
              disabled={loading}
              endIcon={loading ? <CircularProgress /> : <LoginIcon />}
              variant="contained"
              type="submit"
            >
              Iniciar sesión
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar open={alert.slide} autoHideDuration={5000}>
        <Alert
          severity={alert.status}
          onClose={handleCloseAlert}
          open={alert.open}
        >
          <AlertTitle>{alert.status}</AlertTitle>
          {alert.slideMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Login;
