import React from "react";
//*mui components
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Typography,
  Box,
} from "@mui/material";
//*mui Icons
import AttributionIcon from "@mui/icons-material/Attribution";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PlaceIcon from "@mui/icons-material/Place";
import PeopleIcon from "@mui/icons-material/People";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Add from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import PaymentsIcon from "@mui/icons-material/Payments";
import TocIcon from "@mui/icons-material/Toc";

//*mui system
//*navigation
import {useNavigate} from "react-router";
import {useNavContext} from "../../hooks/useNavContext";
import {Dashboard, Search} from "@mui/icons-material";

const actionStructure = [
  {
    id: "services_button",
    label: "Servicios",
    link: "",
    icon: <LocalShippingIcon />,
    access: ["admin", "coordinator"],
    items: [
      {
        id: "services_index",
        label: "Dashboard",
        link: "/serviceIndex",
        icon: <Dashboard />,
        access: ["admin", "coordinator"],
      },
      {
        id: "new_service",
        label: "Crear",
        link: "/services/new",
        icon: <Add />,
        access: ["admin", "coordinator"],
      },
      {
        id: "destinations",
        label: "Destinos",
        link: "/destinations",
        icon: <PlaceIcon />,
        access: ["admin", "coordinator"],
      },
    ],
  },
  {
    id: "customers",
    label: "Clientes",
    link: "/customers",
    icon: <AttributionIcon />,
    access: ["admin", "coordinator"],
  },
  {
    id: "invoicing_collapse",
    label: "Facturación",
    link: "",
    icon: <ReceiptIcon />,
    access: ["manager", "admin", "employee"],
    items: [
      {
        id: "consult_button",
        label: "Busqueda",
        link: "/consultInvoices",
        icon: <Search />,
        access: ["admin", "manager", "employee"],
      },
      {
        id: "emmit_button",
        label: "Nueva factura",
        link: "/invoices/new",
        icon: <Add />,
        access: ["admin", "manager"],
      },
      {
        id: "issuers_collapse",
        label: "Emisores",
        link: "",
        icon: <PeopleIcon />,
        access: ["admin", "manager"],
        items: [
          {
            id: "consult_button",
            label: "Consultar",
            link: "/invoices/consultIssuers",
            icon: <PersonIcon />,
            access: ["admin", "manager"],
          },
          {
            id: "register_button",
            label: "Registrar",
            link: "/invoices/NuevoEmisor",
            icon: <Add />,
            access: ["admin", "manager"],
          },
        ],
      },
      {
        id: "issuers_collapse",
        label: "Sucursales",
        link: "",
        icon: <PlaceIcon />,
        access: ["admin", "manager"],
        items: [
          {
            id: "consult_button",
            label: "Consultar",
            link: "/issuers/places",
            icon: <NotListedLocationIcon />,
            access: ["admin", "manager"],
          },
          {
            id: "register_button",
            label: "Registrar",
            link: "/issuers/places/new",
            icon: <Add />,
            access: ["admin", "manager"],
          },
        ],
      },
    ],
  },
  {
    id: "transports_button",
    label: "Vehículos",
    link: "",
    icon: <LocalShippingIcon />,
    access: ["manager", "coordinator", "admin"],
    items: [
      {
        id: "services_index",
        label: "Consulta",
        link: "/transports",
        icon: <DashboardIcon />,
        access: ["admin", "coordinator"],
      },
      {
        id: "new_truck",
        label: "Nuevo vehículo",
        link: "/transports/newTruck",
        icon: <Add />,
        access: ["admin", "coordinator"],
      },
      {
        id: "new_tow",
        label: "Nuevo remolque",
        link: "/transports/newTow",
        icon: <Add />,
        access: ["admin", "coordinator"],
      },
    ],
  },
  {
    id: "employees_collapse",
    label: "Empleados",
    link: "",
    icon: <PersonIcon />,
    access: ["admin"],
    items: [
      {
        id: "seeAll_button",
        label: "Ver todos",
        link: "/empleados/",
        icon: <GroupsIcon />,
        access: ["admin"],
      },
      {
        id: "payroll_button",
        label: "Nómina rápida",
        link: "/empleados/nominaIndex",
        icon: <ReceiptIcon />,
        access: ["admin"],
      },
      {
        id: "register_button",
        label: "Registrar",
        link: "/empleados/crear",
        icon: <Add />,
        access: ["admin"],
      },
    ],
  },
  {
    id: "expenses_collapse",
    label: "Gastos",
    link: "",
    icon: <PaymentsIcon />,
    access: ["admin", "employee"],
    items: [
      {
        id: "seeAll_button",
        label: "Ver Gastos",
        link: "/expenses/",
        icon: <TocIcon />,
        access: ["admin"],
      },
      {
        id: "expenses_collapse",
        label: "Nuevo gasto",
        link: "/expenses/new",
        icon: <Add />,
        access: ["admin", "employee"],
      },
    ],
  },
];

function SidebarItem({
  depthStep = 20,
  depth = 0,
  expanded,
  rol,
  item,
  ...rest
}) {
  const [collapsed, setCollapsed] = React.useState(true);
  const {label, items, icon, link: onClickProp} = item;

  const {toggleDrawer, setSelectedAction} = useNavContext();

  const navigate = useNavigate();

  const handleRedirect = (route, label) => {
    setSelectedAction(label);
    navigate(`/${rol}${route}`);
    toggleDrawer(false);
  };

  function toggleCollapse() {
    setCollapsed((prevValue) => !prevValue);
  }

  function onClick(e) {
    if (Array.isArray(items)) {
      toggleCollapse();
    }
    if (onClickProp) {
      handleRedirect(item.link, item.label);
    }
  }

  let expandIcon;

  if (Array.isArray(items) && items.length) {
    expandIcon = !collapsed ? (
      <ExpandLess
        className={"sidebar-item-expand-arrow"}
        sx={{color: "warning.light", fontWeight: "bold"}}
      />
    ) : (
      <ExpandMore className="sidebar-item-expand-arrow" />
    );
  }

  return (
    <>
      <ListItemButton
        className="sidebar-item"
        onClick={onClick}
        //dense
        {...rest}
        sx={{display: !item.access.includes(rol) ? "none" : ""}}
      >
        <div
          style={{paddingLeft: depth * depthStep}}
          className="sidebar-item-content"
        >
          <ListItemIcon
            className="sidebar-item-icon"
            sx={{color: "primary.light"}}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="h6">{label}</Typography>}
            disableTypography
            sx={{fontWeight: 700, fontSize: "2.5vh"}}
          />
        </div>
        {expandIcon}
      </ListItemButton>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        {Array.isArray(items) ? (
          <List disablePadding dense>
            {items.map((subItem, index) => (
              <React.Fragment key={`${subItem.name}${index}`}>
                {subItem === "divider" ? (
                  <Divider style={{margin: "6px 0"}} />
                ) : (
                  <SidebarItem
                    depth={depth + 1}
                    depthStep={depthStep}
                    item={subItem}
                    rol={rol}
                  />
                )}
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Collapse>
    </>
  );
}

export function Sidebar({items, depthStep, depth, expanded, rol}) {
  items = actionStructure;
  const {open, toggleDrawer} = useNavContext();
  //console.log("rol en sidebar",rol)
  return (
    <SwipeableDrawer
      open={open}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
    >
      <Box sx={{width: 250}}>
        {/* cajita para que se vea mejor el menú, puede o no llevar texto */}
        <Box
          sx={{
            color: "white",
            backgroundColor: "primary.dark",
            maxWidth: 250,
            minHeight: "2vh",
            maxHeight: "3vh",
            padding: 3,
          }}
        >
          <Typography variant="h5"> Q-Soft</Typography>
        </Box>

        <List disablePadding dense>
          {items.map((sidebarItem, index) => (
            <React.Fragment key={`${sidebarItem.name}${index}`}>
              {sidebarItem === "divider" ? (
                <Divider style={{margin: "6px 0"}} />
              ) : (
                <SidebarItem
                  depthStep={depthStep}
                  depth={depth}
                  expanded={expanded}
                  item={sidebarItem}
                  rol={rol}
                />
              )}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </SwipeableDrawer>
  );
}
