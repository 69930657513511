import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Modal,
  TextField,
  Autocomplete,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {getAllEmployees} from "../../../services/modules/employees/employees";
import useAuth from "../../../hooks/useAuth";
import {createUser} from "../../../services/modules/users/users";
import ProfilePic from "./LogoComponent";

export const DashboardIndex = () => {
  const {userid} = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allEmployees, setAllEmployees] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    maxWidth: "xs",
    actions: [],
  });
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const initialStateUser = {
    role: "",
    name: "",
    lastName: "",
    email: "",
    password: "",
    employeeId: "",
  };
  const [newUserData, setNewUserData] = useState(initialStateUser);
  const rolesOptions = [
    {label: "Administrador", value: "admin"},
    {label: "Gerente", value: "manager"},
    {label: "Coordinador", value: "coordinator"},
    {label: "Empleado", value: "employee"},
  ];

  const handleCreateUser = () => {
    console.log("[NEW USER DATA]", newUserData);
    if (!newUserData.employeeId) delete newUserData.employeeId;
    handleCloseModal();

    createUser(userid, userid, newUserData)
      .then((res) => {
        setDialog({
          ...dialog,
          open: true,
          title: "Usuario registrado exitosamente",
          actions: [
            {
              label: "OK",
              execute: () => window.location.reload(),
            },
          ],
        });
      })
      .catch((err) => {
        console.log("Error al crear usuario:", err);
        setDialog({
          ...dialog,
          open: true,
          title: "Error al registrar usuario",
          content: "Revisa los datos del formulario o intenta mas tarde",
          actions: [
            {
              label: "OK",
              execute: () => window.location.reload(),
            },
          ],
        });
      });
  };

  const handleInputChange = (e, v, r) => {
    const {id, value} = e.target;

    setNewUserData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const updateSaveButtonStatus = () => {
    const {employeeId, ...restData} = newUserData;
    const isDisabled = Object.values(restData).some((value) => !value);
    setIsSaveButtonDisabled(isDisabled);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewUserData((prevData) => ({...prevData, ...initialStateUser}));
  };

  const handleCloseDialog = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setDialog({
      ...dialog,
      open: false,
    });
  };

  useEffect(() => {
    getAllEmployees(userid, userid)
      .then((res) => {
        setAllEmployees(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userid]);

  useEffect(() => {
    updateSaveButtonStatus();
  }, [newUserData]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "10vh",
      }}
    >
      <Grid container spacing={2} maxWidth={"lg"} sx={{marginX: "auto"}}>
        <Grid item xs={6}>
          <Typography variant="h4" color="primary">
            Mi empresa
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOpenModal}
              startIcon={<AddCircleOutlineIcon />}
              sx={{color: "white"}}
            >
              Agregar usuario
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{height: "100%", display: "flex", flexDirection: "column"}}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    sx={{fontSize: 14}}
                    color="secondary"
                    gutterBottom
                  >
                    Datos de empresa
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    sx={{fontSize: 14}}
                    color="primary"
                    variant="button"
                    gutterBottom
                  >
                    Logotipo
                  </Typography>
                  <ProfilePic
                    feedbackDialog={dialog}
                    setFeedbackDialog={setDialog}
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant="h5" component="div">
                    PROXIMAMENTE
                  </Typography>
                  <Typography variant="body2">
                    Informacion de la empresa
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{marginTop: "auto", justifyContent: "right"}}>
              <Button size="small">Configurar</Button>
            </CardActions>
          </Card>
        </Grid>

        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6" sx={{mb: 2, textAlign: "center"}}>
              Agregar nuevo usuario
            </Typography>
            <TextField
              id="name"
              label="Nombre"
              value={newUserData.name}
              onChange={handleInputChange}
              fullWidth
              sx={{mb: 2}}
            />

            <TextField
              id="lastName"
              label="Apellido"
              value={newUserData.lastName}
              onChange={handleInputChange}
              fullWidth
              sx={{mb: 2}}
            />

            <Autocomplete
              autoComplete
              id="role"
              sx={{width: "100%", mb: 2}}
              options={rolesOptions}
              getOptionLabel={(role) => {
                return `${role.label}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    id="role"
                    sx={{width: "100%"}}
                    label="Rol"
                    required
                    value={newUserData.role || ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              value={
                newUserData.role
                  ? rolesOptions.find(
                      (option) => option.value === newUserData.role
                    )
                  : null
              }
              onChange={(e, v, r) => {
                const updatedUser = {
                  ...newUserData,
                  role: r === "clear" ? "" : v.value,
                };
                setNewUserData(updatedUser);
              }}
            />

            <TextField
              id="email"
              label="Correo"
              value={newUserData.email}
              onChange={handleInputChange}
              fullWidth
              sx={{mb: 2}}
            />

            <TextField
              id="password"
              label="Contraseña"
              type={showPassword ? "text" : "password"}
              value={newUserData.password}
              onChange={handleInputChange}
              fullWidth
              sx={{mb: 2}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Autocomplete
              autoComplete
              id="employeeId"
              sx={{width: "100%", mb: 2}}
              options={allEmployees}
              getOptionLabel={(employee) => {
                return `${employee.Nombre} ${employee.ApellidoP} ${employee.ApellidoM}`;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    id="emlpoyeeId"
                    sx={{width: "100%"}}
                    label="Empleado relacionado"
                    required
                    value={newUserData.employeeId || ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              value={
                newUserData.employeeId
                  ? allEmployees.find(
                      (employee) => employee._id === newUserData.employeeId
                    )
                  : null
              }
              onChange={(e, v, r) => {
                const updatedUser = {
                  ...newUserData,
                  employeeId: r === "clear" ? "" : v._id,
                };
                setNewUserData(updatedUser);
              }}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateUser}
              disabled={isSaveButtonDisabled}
              sx={{mt: 2}}
            >
              Guardar
            </Button>
          </Box>
        </Modal>

        <Dialog
          open={dialog.open}
          onClose={handleCloseDialog}
          fullWidth={true}
          maxWidth={dialog.maxWidth}
        >
          <DialogTitle>{dialog.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialog.content}</DialogContentText>
          </DialogContent>
          <DialogActions>
            {dialog.actions.map((action, index) => {
              return (
                <Button
                  key={index}
                  variant="contained"
                  onClick={action.execute}
                >
                  {action.label}
                </Button>
              );
            })}
          </DialogActions>
        </Dialog>
      </Grid>
    </Box>
  );
};
