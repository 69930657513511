import React, { useEffect, useMemo, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material";
import { Grid } from "@mui/material";
import {
  getBanks,
  getContractTypes,
  getPaymentFrequencies,
  getPositionRisks,
  getPostalCodesCatalog,
  getRegimenTypes,
  getRegimes,
  getTypesOfJourney,
} from "../../../services/modules/invoices/catalogs";
import useAuth from "../../../hooks/useAuth";
import {
  DeductionsTable,
  PerceptionsTable,
} from "../../../components/Forms/Nomina/NominaTables";
import {
  validateCLABE,
  validateCurp,
  validateRfc,
} from "../../invoices/utils/regexp";
import {
  createEmployee,
  searchEmployeeByKey,
  updateEmployee,
} from "../../../services/modules/employees/employees";
import { useNavigate } from "react-router";
import { EmployeesPositionOptions } from "../../services/utils/ServiceItemsENUM";
import employeeBaseStructure from "../../../utils/dto/employee.json";
import PersonalDataForm from "../../../components/Forms/Employees/PersonalDataForm";
import EmployeeAddress from "../../../components/Forms/Employees/EmployeeAddress";
import WorkDataForm from "../../../components/Forms/Employees/WorkDataForm";
import EmployeePaymentsForm from "../../../components/Forms/Employees/EmployeePaymentsForm";

const EmployeeRegistrationForm = React.memo(() => {
  const { userid } = useAuth();

  const navigate = useNavigate();

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    maxWidth: "md",
    actions: [],
  });

  const [employeeData, setEmployeeData] = useState(employeeBaseStructure);

  const [options, setOptions] = useState({
    regimeType: [],
    fiscalRegimes: [],
    risk: [],
    journey: [],
    frequency: [],
    bank: [],
    entityKey: [],
    contractType: [],
    sindicato: [],
    departments: [],
    positions: [],
  });

  const [loading, setLoading] = useState(false);

  const [errorText, setErrorText] = useState({
    Rfc: null,
    Curp: null,
    BankAccount: null,
  });

  const successMsgMap = {
    update: {
      title: "Éxito",
      message: "El empleado ha sido modificado con éxito",
      actions: [
        {
          label: "Continuar",
          execute: () => navigate(`/${userid.claims.rol}/empleados/`),
        },
      ],
    },
    create: {
      title: "Éxito",
      message: "El empleado ha sido agregado con éxito",
      actions: [
        {
          label: "Agregar Otro",
          execute: () => setEmployeeData(employeeBaseStructure),
        },
        {
          label: "Continuar",
          execute: () => navigate(`/${userid.claims.rol}/empleados/`),
        },
      ],
    },
  };

  const errorMsgMap = {
    422: {
      title: "Error",
      message: "Algo ha salido mal",
      actions: [
        {
          label: "ok",
          execute: () =>
            setDialog({
              ...dialog,
              open: false,
              title: "",
              content: "",
              actions: [],
            }),
        },
      ],
    },
    500: {
      title: "Error",
      message: "Ocurrió un fallo en el servidor",
      actions: [
        {
          label: "Reintentar",
          execute: () => handleSaveEmployee(employeeData),
        },
        {
          label: "Cerrar",
          execute: () =>
            setDialog({
              ...dialog,
              open: false,
              title: "",
              content: "",
              actions: [],
            }),
        },
      ],
    },
    400: {
      title: "Error 400",
      message: "Los datos proporcionados no son válidos",
      actions: [
        {
          label: "ok",
          execute: () =>
            setDialog({
              ...dialog,
              open: false,
              title: "",
              content: "",
              actions: [],
            }),
        },
      ],
    },
    401: {
      title: `Error ${status}`,
      message: "No tienes permiso para realizar esta acción o tu sesión expiró",
      actions: [
        {
          label: "ok",
          execute: () =>
            setDialog({
              ...dialog,
              open: false,
              title: "",
              content: "",
              actions: [],
            }),
        },
      ],
    },
    403: {
      title: `Error ${status}`,
      message: "No tienes permiso para realizar esta acción o tu sesión expiró",
      actions: [
        {
          label: "ok",
          execute: () =>
            setDialog({
              ...dialog,
              open: false,
              title: "",
              content: "",
              actions: [],
            }),
        },
      ],
    },
    default: {
      title: "Error",
      message: "Hubo un fallo desconocido",
      actions: [
        {
          label: "ok",
          execute: () =>
            setDialog({
              ...dialog,
              open: false,
              title: "",
              content: "",
              actions: [],
            }),
        },
      ],
    },
  };

  const id = useMemo(
    () => new URLSearchParams(window.location.search).get("Id"),
    [window.location.search]
  );
  let isEdition = Boolean(id);

  const handlePromiseError = () => {
    setDialog({
      open: true,
      title: "Error",
      content: `Hubo un error al obtener los datos de los catálogos`,
      actions: [
        {
          label: "Intentar de nuevo",
          execute: () => {
            setDialog({
              ...dialog,
              open: false,
              title: "",
              content: "",
              actions: [],
            });
            getOptions();
          },
        },
        {
          label: "OK",
          execute: () => {
            setDialog({
              ...dialog,
              open: false,
              title: "",
              content: "",
              actions: [],
            });
          },
        },
      ],
    });
  };

  const positions = useMemo(() => EmployeesPositionOptions, []);

  const getContractTypesMemo = useMemo(
    () => () => getContractTypes(userid, userid),
    [userid]
  );
  const getTypesOfJourneyMemo = useMemo(
    () => () => getTypesOfJourney(userid, userid),
    [userid]
  );
  const getPaymentFrequenciesMemo = useMemo(
    () => () => getPaymentFrequencies(userid, userid),
    [userid]
  );
  const getBanksMemo = useMemo(() => () => getBanks(userid, userid), [userid]);
  const getRegimenTypesMemo = useMemo(
    () => () => getRegimenTypes(userid, userid),
    [userid]
  );
  const getPositionRisksMemo = useMemo(
    () => () => getPositionRisks(userid, userid),
    [userid]
  );
  const getFiscalRegimesMemo = useMemo(
    () => () => getRegimes(userid, userid),
    [userid]
  );
  const getSindicato = useMemo(
    () => [
      { Name: "Sí", Value: true },
      { Name: "No", Value: false },
    ],
    []
  );
  const getDepartments = useMemo(
    () => [
      { value: "LOGISTICA", label: "LOGISTICA" },
      {
        value: "ADMINISTRACION FINANCIERA",
        label: "ADMINISTRACION FINANCIERA",
      },
      { value: "OPERACIONES", label: "OPERACIONES" },
      { value: "DIRECCION GENERAL", label: "DIRECCION GENERAL" },
    ],
    []
  );
  const getPositions = useMemo(
    () => [
      { value: "OPERADOR", label: "OPERADOR" },
      {
        value: "GESTOR DE DOCUMENTACION DE SERVICIOS",
        label: "GESTOR DE DOCUMENTACION DE SERVICIOS",
      },
      { value: "COORDINADOR", label: "COORDINADOR" },
      { value: "ADMINISTRADOR FINANCIEROL", label: "ADMINISTRADOR FINANCIERO" },
    ],
    []
  );

  async function getOptions() {
    try {
      const responses = await Promise.allSettled([
        getContractTypesMemo(),
        getTypesOfJourneyMemo(),
        getPaymentFrequenciesMemo(),
        getBanksMemo(),
        getRegimenTypesMemo(),
        getPositionRisksMemo(),
        getFiscalRegimesMemo(),
      ]);

      console.log(responses);

      const responsesErrors = responses
        .filter((response) => response.status === "rejected")
        .map((error) => error.reason);

      if (responsesErrors.length === 0) {
        const respOptions = {
          contractType: responses[0].value.data,
          journey: responses[1].value.data,
          frequency: responses[2].value.data,
          bank: responses[3].value.data,
          regimeType: responses[4].value.data,
          risk: responses[5].value.data,
          fiscalRegimes: responses[6].value.data,
          sindicato: getSindicato,
          departments: getDepartments,
          positions: getPositions,
        };
        setOptions(respOptions);
      } else {
        handlePromiseError(responsesErrors);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const handleRfcChange = (event, actualValue) => {
    let uppercaseValue = String(event.target.value).toUpperCase();
    actualValue[event.target.name] = uppercaseValue;
    if (!validateRfc(String(event.target.value).toUpperCase())) {
      setErrorText({ ...errorText, Rfc: "Formato inválido" });
    } else {
      setErrorText({ ...errorText, Rfc: null });
    }
  };

  const handleCurpChange = (event, actualValue) => {
    let uppercaseValue = String(event.target.value).toUpperCase();
    actualValue[event.target.name] = uppercaseValue;
    if (!validateCurp(String(event.target.value).toUpperCase())) {
      setErrorText({ ...errorText, Curp: "Formato inválido" });
    } else {
      setErrorText({ ...errorText, Curp: null });
    }
  };

  const handleCtaBancoChange = (event, actualValue) => {
    let uppercaseValue = String(event.target.value).toUpperCase();
    actualValue[event.target.name] = uppercaseValue;
    if (!validateCLABE(event.target.value)) {
      setErrorText({ ...errorText, BankAccount: "Formato inválido" });
    }
  };

  const handleSalaryChange = (event, actualValue) => {
    let numberSalary = Number(event.target.value);
    actualValue[event.target.name] = numberSalary;
  };

  const handleNameChange = (event, actualValue) => {
    let uppercaseValue = String(event.target.value).toUpperCase();
    actualValue[event.target.name] = uppercaseValue;
  };

  const handleInputChange = (event) => {
    setEmployeeData((prevEmployeeData) => {
      let newEmployeeData = { ...prevEmployeeData };

      const functionByInputName = {
        Rfc: handleRfcChange,
        CURP: handleCurpChange,
        CtaBanco: handleCtaBancoChange,
        Sueldo: handleSalaryChange,
        ApellidoM: handleNameChange,
        ApellidoP: handleNameChange,
        Nombre: handleNameChange,
      };

      if (functionByInputName[event.target.name]) {
        functionByInputName[event.target.name](event, newEmployeeData);
      } else {
        newEmployeeData[event.target.name] = event.target.value;
      }
      return newEmployeeData;
    });
  };

  const handleAutoCompleteChange = (e, v, r) => {
    //*TODO: HANDLE CHANGE OF THE BANCO AUTOCOMPLETE (NEEDS UPDATE ON DB SCHEMAS AND DTOS)
    const id = e.target.id.split("-")[0];
    setEmployeeData({
      ...employeeData,
      [id]: r === "clear" ? "" : v,
    });
  };

  const handleDateChange = (newValue, antig) => {
    setEmployeeData({
      ...employeeData,
      FechaInicio: newValue,
      Antiguedad: antig,
    });
  };

  // 1. Helper function to set dialog
  function showDialog(title, message, actions) {
    setDialog({
      ...dialog,
      title,
      content: message,
      actions,
      open: true,
      maxWidth: "sm",
    });
  }

  function checkData2(data, path = "", emptyFields = []) {
    const optionalRoutes = [
      "DomFiscal.Localidad",
      "DomFiscal.Municipio",
      "DomFiscal.NumInt",
    ];
    for (const key in data) {
      const newPath = path === "" ? key : `${path}.${key}`;
      if (typeof data[key] === "object" && data[key] !== null) {
        checkData2(data[key], newPath, emptyFields);
      } else if (data[key] === "" || data[key] === null) {
        if (!optionalRoutes.includes(newPath)) {
          emptyFields.push(newPath);
        }
      }
    }
    return emptyFields;
  }

  function handleError(err) {
    if (err.response) {
      const status = err.response.status;
      const msgObj = errorMsgMap[status]
        ? typeof errorMsgMap[status] === "function"
          ? errorMsgMap[status](status)
          : errorMsgMap[status]
        : errorMsgMap.default;

      showDialog(msgObj.title, msgObj.message, msgObj.actions);
    }
  }

  const handleSaveEmployee = () => {
    let msgObj;
    let emptyEmployee = checkData2(employeeData);
    const MissingList = () => {
      return (
        <Grid container maxWidth={"md"} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" color="error.main">
              Falta información en los siguientes campos:
            </Typography>
          </Grid>
          {emptyEmployee.map((MF, idx /*Missing Field, mofoca */) => {
            if (typeof MF !== "object")
              return (
                <Grid key={idx} item xs={3}>
                  <Typography>-{MF}</Typography>
                </Grid>
              );
          })}
        </Grid>
      );
    };

    if (emptyEmployee.length !== 0) {
      setDialog({
        open: true,
        title: "Datos Faltantes",
        maxWidth: "md",
        content: MissingList(),
        actions: [
          {
            label: "ok",
            execute: () => {
              setDialog({ ...dialog, open: false });
            },
          },
        ],
      });
    } else {
      console.log("🚀 [EMPLOYEE DATA]", employeeData);
      // 3. Separate functions
      function handleUpdateEmployee() {
        updateEmployee(userid, userid, employeeData)
          .then((res) => {
            const msgObj =
              res.status === 201 ? successMsgMap.update : errorMsgMap.default;
            showDialog(msgObj.title, msgObj.message, msgObj.actions);
          })
          .catch(handleError);
      }

      function handleCreateEmployee() {
        createEmployee(userid, userid, employeeData)
          .then((res) => {
            const msgObj =
              res.status === 201 ? successMsgMap.create : errorMsgMap.default;
            showDialog(msgObj.title, msgObj.message, msgObj.actions);
          })
          .catch(handleError);
      }

      // Main logic
      if (isEdition) {
        handleUpdateEmployee();
      } else {
        handleCreateEmployee();
      }
    }
  };

  const handleCloseDialog = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setDialog({
      ...dialog,
      open: false,
    });
  };

  //Locations handlers
  const handleLocations = (event) => {
    employeeData.DomFiscal = {
      ...employeeData.DomFiscal,
      [event.target.name]: event.target.value,
    };
    setEmployeeData({
      ...employeeData,
    });
  };

  const validateAndSetLocationsFromResponse = (res) => {
    const isValid = (code) => {
      return code !== "" && code !== "NULL";
    };
    let prevState = employeeData;
    prevState.DomFiscal.Estado = res.data[0].StateCode;
    setEmployeeData({
      ...prevState,
    });

    if (isValid(res.data[0].LocationCode)) {
      prevState.DomFiscal.Localidad = res.data[0].LocationCode;
      setEmployeeData({
        ...prevState,
      });
    }

    if (isValid(res.data[0].MunicipalityCode)) {
      prevState.DomFiscal.Municipio = res.data[0].MunicipalityCode;
      setEmployeeData({
        ...prevState,
      });
    }
  };

  //Perceptions and deductions
  function setPerceptions(arr) {
    console.log(arr);
    setEmployeeData({
      ...employeeData,
      Perceptions: TableToConceptos("Perception", arr),
    });
  }
  function setDeductions(arr) {
    setEmployeeData({
      ...employeeData,
      Deductions: TableToConceptos("Deduction", arr),
    });
  }
  const ConceptosToTable = (type, array) => {
    //types are "Perception" and "Deduction"
    let mappedPerceptions = [];
    let mappedDeductions = [];

    if (type === "Perception") {
      array.map((DBperception) => {
        mappedPerceptions.push({
          PerceptionType: DBperception.Value,
          Code: "",
          Description: DBperception.Name,
          TaxedAmount: DBperception.TaxedTotal,
          ExemptAmount: DBperception.ExemptTotal,
        });
      });

      return mappedPerceptions;
    }

    if (type === "Deduction") {
      array.map((DBdeduction) => {
        mappedDeductions.push({
          DeduccionType: DBdeduction.Value,
          Code: "",
          Description: DBdeduction.Name,
          Amount: DBdeduction.Amount,
        });
      });

      return mappedDeductions;
    }
  };
  const TableToConceptos = (type, array) => {
    let mappedPerceptions = [];
    let mappedDeductions = [];

    if (type === "Perception") {
      array.map((TBperception) => {
        mappedPerceptions.push({
          Value: TBperception.PerceptionType,
          Name: TBperception.Description,
          TaxedTotal: TBperception.TaxedAmount,
          ExemptTotal: TBperception.ExemptAmount,
        });
      });

      return mappedPerceptions;
    }

    if (type === "Deduction") {
      array.map((TBdeduction) => {
        mappedDeductions.push({
          Value: TBdeduction.DeduccionType,
          Name: TBdeduction.Description,
          Amount: TBdeduction.Amount,
        });
      });

      return mappedDeductions;
    }
  };

  useEffect(() => {
    setLoading(true);
    getOptions();
  }, []);

  useEffect(() => {
    if (id) {
      searchEmployeeByKey(userid, userid, "_id", id)
        .then((response) => {
          const mappedEmployeeData = response.data.data[0];
          setEmployeeData(mappedEmployeeData);
        })
        .catch((error) => {
          console.error(error);
          setDialog({
            open: true,
            title: "Error al obtener empleado",
            content: "No se pudieron cargar los datos del empleado",
            actions: [
              {
                label: "Intentar de nuevo",
                execute: () => {
                  setDialog({ ...dialog, open: false });
                  window.location.reload();
                },
              },
              {
                label: "OK",
                execute: () => {
                  navigate(`/${userid.claims.rol}/empleados`);
                },
              },
            ],
          });
        });
    }
  }, []);

  useEffect(() => {
    if (employeeData.DomFiscal.CP.length > 3) {
      getPostalCodesCatalog(userid, userid, employeeData.DomFiscal.CP)
        .then((result) => {
          if (result.data.length === 1) {
            validateAndSetLocationsFromResponse(result);
          }
        })
        .catch((err) => {
          setDialog({
            ...dialog,
            title: "Error",
            content: "Hubo un error al obtener los datos del código postal",
            actions: [
              {
                label: "ok",
                execute: () => setDialog({ ...dialog, open: false }),
              },
            ],
            open: true,
            maxWidth: "sm",
          });
        });
    }
  }, [employeeData.DomFiscal.CP]);

  return (
    <Grid
      container
      spacing={3}
      sx={{
        display: "flex",
        justifyContent: "right",
        margin: "auto",
        mb: 3,
        pr: 1,
        width: { xs: "100%", md: "70%" },
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={dialog.open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth={dialog.maxWidth}
      >
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.actions.map((action, index) => {
            return (
              <Button key={index} variant="contained" onClick={action.execute}>
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>

      <Grid item xs={12} sx={{ align: "center" }}>
        <Typography color="primary" variant="h5">
          {isEdition ? "Editar" : "Agregar"} empleado
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          component={Paper}
          sx={{ p: 1, justifyContent: "center", alignItems: "center" }}
        >
          <PersonalDataForm
            employeeData={employeeData}
            onInputChange={handleInputChange}
            onAutoCompleteChange={handleAutoCompleteChange}
            fiscalRegimes={options.fiscalRegimes}
            errorText={errorText}
          />
        </Grid>
      </Grid>

      {/*Domicilio*/}
      <Grid item xs={12}>
        <Grid container spacing={2} component={Paper} sx={{ padding: 1 }}>
          <EmployeeAddress
            employeeData={employeeData}
            onLocationChange={handleLocations}
          />
        </Grid>
      </Grid>

      {/* Datos Laborales */}
      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          component={Paper}
          sx={{ p: 1, justifyContent: "center", alignItems: "center" }}
        >
          <WorkDataForm
            employeeData={employeeData}
            onWorkDataChange={handleInputChange}
            onAutoCompleteChange={handleAutoCompleteChange}
            onDateChange={handleDateChange}
            options={options}
          />
        </Grid>
      </Grid>

      {/* Datos del pago */}
      <Grid item xs={12}>
        <Grid container spacing={2} component={Paper} sx={{ padding: 1 }}>
          <EmployeePaymentsForm
            options={options}
            employeeData={employeeData}
            onAutoCompleteChange={handleAutoCompleteChange}
            onInputChange={handleInputChange}
          />
        </Grid>
      </Grid>

      {/* Percepciones */}
      <Grid item xs={12}>
        <PerceptionsTable
          perceptionsArray={ConceptosToTable(
            "Perception",
            employeeData.Perceptions || []
          )}
          setPerceptionArray={setPerceptions}
        />
      </Grid>

      {/* Deducciones */}
      <Grid item xs={12}>
        <DeductionsTable
          deductionsArray={ConceptosToTable(
            "Deduction",
            employeeData.Deductions || []
          )}
          setDeductionsArray={setDeductions}
        />
      </Grid>

      <Grid
        item
        xs={10}
        md={4}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: { xs: 5, md: 2 },
        }}
      >
        <Button variant="contained" onClick={handleSaveEmployee}>
          {isEdition ? "Editar" : "Agregar"} empleado
        </Button>
      </Grid>
    </Grid>
  );
});

export default EmployeeRegistrationForm;
