import React, { useState } from "react";
import { forwardRef } from "react";
import useAuth from "../../hooks/useAuth";
import SendInvoiceComponent from "./invoices/SendInvoiceByEmailAction";
import CancelInvoiceComponent from "./invoices/CancelInvoiceComponent";
import { Grid, Typography } from "@mui/material";

const ActionsContent = forwardRef((props, ref) => {
  const { userid } = useAuth();
  const handleCloseDialogActions = (open) => {
    if (typeof open !== "boolean") {
      ref.current.handleCloseDialogActions(false);
      return;
    }
    ref.current.handleCloseDialogActions(open);
  };
  const [startedProcess, setStartedProcess] = useState({
    send: false,
    cancel: false,
  });

  const handleStarted = (process) => () => {
    setStartedProcess((sp) => ({ ...sp, [process]: !sp[process] }));
  };

  return (
    <Grid container spacing={2}>
      {props.allowed.includes(userid.claims.rol) ? (
        <Grid item xs={12}>
          <Grid item xs={12} mb={2}>
            <SendInvoiceComponent
              invoice={props.data.row}
              onFinish={handleCloseDialogActions}
              onStarted={handleStarted("send")}
              disableUse={startedProcess.cancel}
              ref={ref}
            />
          </Grid>
          <Grid item xs={12}>
            <CancelInvoiceComponent
              baseInvoice={props.data.row}
              onFinish={handleCloseDialogActions}
              onStarted={handleStarted("cancel")}
              disableUse={startedProcess.send}
            />
          </Grid>
        </Grid>
      ) : (
        <Typography variant="h3">
          No tienes autorización para ejecutar acciones sobre las facturas
        </Typography>
      )}
    </Grid>
  );
});

export default ActionsContent;