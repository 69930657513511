import React from "react";
import { Grid, Button } from "@mui/material";

const ExtraFieldsButtons = ({
  showField,
  handleShowField,
  handleMerchForm,
}) => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={6}
          md={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="warning"
            onClick={() => handleShowField("Operadores")}
          >
            {`${!showField.Operadores ? "Añadir" : "Eliminar"}`} Operadores
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          order={{ xs: 2, md: 1 }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleShowField("Unidad")}
          >
            {`${!showField.Unidad ? "Añadir" : "Eliminar"}`} vehiculos
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
          md={4}
          order={{ xs: 1, md: 2 }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color={!showField.merch ? "primary" : "error"}
            onClick={() => {
              handleMerchForm();
            }}
          >
            {`${!showField.merch ? "Añadir" : "Eliminar"}`} Mercancías
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExtraFieldsButtons;
