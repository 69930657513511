import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  Grid,
  Paper,
  Switch,
  Snackbar,
  Alert,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useAuth from "../../../hooks/useAuth";
import {
  deleteRemolque,
  deleteVehicle,
  filterRemolques,
  filterVehicles,
  getAllRemolques,
  getAllVehicles,
} from "../../../services/modules/transports/vehicles";
import { useNavigate } from "react-router";
import ferbyTheme from "../../../theme/ferbyTheme";
import EditComponent from "../../../components/actions/EditComponent";
import {
  AlertDialog,
  InitialDialogState,
  getGenericErrorDialogContent,
} from "../../../components/userFeedback/AlertDialog";
import { ConsultSearchComponent } from "../../../components/actions/ConsultSearchComponent";
import { getErrorMessageFromStatus } from "../../../utils/httpResponses";

//*TODO: Separar remolques y camiones en dos componentes separados
const TransportsIndex = () => {
  const navigate = useNavigate();

  const { userid } = useAuth();

  const [alert, setAlert] = useState({
    open: false,
    duration: 0,
    severity: "success",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const [dialog, setDialog] = useState({
    ...InitialDialogState,
    updateOpenState: () => setDialog({ ...dialog, open: false }),
  });

  const [camionesRows, setCamionesRows] = useState([]);

  const [remolquesRows, setRemolquesRows] = useState([]);

  const [showRemolques, setShowRemolques] = useState(false);

  const [searched, setSearched] = useState({
    camiones: false,
    remolques: false,
  });

  const [searchedTerm, setSearchedTerm] = useState({
    camiones: "",
    remolques: "",
  });

  const camionesColumns = [
    {
      field: "type",
      headerName: "Tipo de camión",
      width: 300,
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "model",
      headerName: "Modelo",
      editable: true,
      width: 150,
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "placaTractor",
      headerName: "Placas",
      width: 150,
      editable: true,
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "polizaName",
      headerName: "Aseguradora",
      width: 150,
      editable: true,
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "_id",
      headerName: "Acciones",
      renderCell: (params) => {
        return (
          <EditComponent
            editionRoute={`/${userid.claims.rol}/transports/updateTruck`}
            objName={"Vehículo"}
            onDelete={() => deleteVehicles(params.row.id)}
            rowParams={params.row}
          />
        );
      },
      flex: 1,
      headerClassName: "header-table",
    },
  ];

  const deleteVehicles = (id) => {
    deleteVehicle(userid, userid, id, { reasonByDelete: "default" })
      .then((response) => {
        setAlert({
          open: true,
          duration: 6000,
          severity: "success",
          message: "Vehículo eliminado",
        });
        getData();
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          setAlert({
            open: true,
            duration: 6000,
            severity: "error",
            message: getErrorMessageFromStatus(err.response.status),
          });
          return;
        }

        setAlert({
          open: true,
          duration: 6000,
          severity: "error",
          message: "Ocurrió un error al eliminar el vehículo",
        });
      });
  };

  const deleteRemolques = (id) => {
    deleteRemolque(userid, userid, id, { reasonByDelete: "default" })
      .then((response) => {
        console.log(response);
        setAlert({
          open: true,
          duration: 6000,
          severity: "success",
          message: "Remolque eliminado",
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          setAlert({
            open: true,
            duration: 6000,
            severity: "error",
            message: getErrorMessageFromStatus(err.response.status),
          });
          return;
        }
        setAlert({
          open: true,
          duration: 6000,
          severity: "error",
          message: "Ocurrió un error al eliminar el remolque",
        });
      });
  };

  const remolquesColumns = [
    {
      field: "tipoRem",
      headerName: "Tipo de remolque",
      width: 300,
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "placaCaja",
      headerName: "Placas",
      width: 150,
      editable: true,
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "_id",
      headerName: "Acciones",
      renderCell: (params) => {
        return (
          <EditComponent
            editionRoute={`/${userid.claims.rol}/transports/updateTow`}
            objName={"Remolque"}
            onDelete={() => deleteRemolques(params.row.id)}
            rowParams={params.row}
          />
        );
      },
      flex: 1,
      headerClassName: "header-table",
    },
  ];

  const clearSerch = () => {
    if (showRemolques) {
      setSearched({ ...searched, remolques: false });
      if (searched.remolques) {
        getData("remolques");
      }
    } else {
      setSearched({ ...searched, camiones: false });
      if (searched.camiones) {
        getData("camiones");
      }
    }
  };

  const getRowsFromResponse = (vehiclesData, type) => {
    let rowsToSet = [];
    if (type === "remolques") {
      vehiclesData.forEach((element, index) => {
        rowsToSet.push({
          id: element._id ? element._id : "provid" + index,
          _id: element._id,
          tipoRem: element.tipoRem,
          placaCaja: element.placaCaja,
        });
      });
    } else if (type === "camiones") {
      vehiclesData.forEach((element) => {
        rowsToSet.push({
          id: element._id,
          _id: element._id,
          type: element.type,
          placaTractor: element.placaTractor,
          model: element.model,
          polizaName: element.polizaName,
        });
      });
    }

    return rowsToSet;
  };

  const handleOuterSearch = (term) => {
    if (term !== "") {
      const queryFilter = {
        label: "query",
        param: "q",
        value: term,
      };
      if (showRemolques) {
        setLoading(true);
        setSearchedTerm({ ...searchedTerm, remolques: term });

        filterRemolques(userid, [queryFilter])
          .then((res) => {
            let filteredData = res.data;

            if (filteredData.length > 0) {
              let newRemolques = getRowsFromResponse(filteredData, "remolques");
              setRemolquesRows((ir) => newRemolques);
              setLoading((l) => false);
              setSearched({ ...searched, remolques: true });
            } else {
              setAlert({
                open: true,
                duration: 6000,
                severity: "info",
                message: "No se encontraron datos con los valores ingresados",
              });
              setLoading((l) => false);
              setSearched({ ...searched, remolques: false });
            }
          })
          .catch((err) => {
            setLoading((l) => false);
            setSearched({ ...searched, remolques: false });
            if (err.response) {
              setAlert({
                open: true,
                duration: 6000,
                severity: "error",
                message: getErrorMessageFromStatus(err.response.status),
              });
              return;
            }

            setAlert({
              open: true,
              duration: 6000,
              severity: "error",
              message: "Ocurrió un error al buscar los remolques",
            });
          });
      } else {
        setSearchedTerm({ ...searchedTerm, camiones: term });
        setLoading(true);
        filterVehicles(userid, [queryFilter])
          .then((res) => {
            let filteredData = res.data;
            console.log(res.data);
            if (filteredData.length > 0) {
              let newRows = getRowsFromResponse(filteredData, "camiones");

              setCamionesRows((ir) => newRows);
              setLoading((l) => false);
              setSearched({ ...searched, camiones: true });
            } else {
              console.log("no data");
              setLoading(false);
              setAlert({
                open: true,
                duration: 6000,
                severity: "info",
                message: "No se encontraron datos con los valores ingresados",
              });
            }
          })
          .catch((err) => {
            console.log("err filter", err);
            setSearched({ ...searched, camiones: false });
            setLoading((l) => false);

            if (err.response) {
              setAlert({
                open: true,
                duration: 6000,
                severity: "error",
                message: getErrorMessageFromStatus(err.response.status),
              });
              return;
            }

            setAlert({
              open: true,
              duration: 6000,
              severity: "error",
              message: "Ocurrió un error al buscar los camiones",
            });
          });
      }
    }
  };

  const getData = (data) => {
    const getVehicles = () => {
      getAllVehicles(userid, userid)
        .then((res) => {
          console.log("Troques aquí");
          console.log("respuesta camiones ", res);
          //let issuersData = res.data
          let issuersData = res.data.map(vehicle=>{
            return {
              _id: vehicle._id,
              type: vehicle.Specs.Type.Code,
              model: vehicle.Specs.Model,
              poliza: vehicle.Policy.NoPoliza,
              polizaName: vehicle.Policy.InsuranceCompany,
              permisoSCT: vehicle.SCTPermission.Type,
              placaTractor: vehicle.Plate,
              numPermisoSCT: vehicle.SCTPermission.Number,
            }
          });
          console.log('issuersData',issuersData);


          let rowsToSet = getRowsFromResponse(issuersData, "camiones");

          setCamionesRows((ir) => rowsToSet);
          setLoading((l) => false);
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
            setLoading((l) => false);
            setAlert({
              open: true,
              duration: 6000,
              severity: "error",
              message: getErrorMessageFromStatus(err.response.status),
            });
            return;
          }
          setAlert({
            open: true,
            duration: 6000,
            severity: "error",
            message: "Ocurrió un error al obtener los camiones",
          });
        });
    };
    const getRemolques = () => {
      getAllRemolques(userid, userid)
        .then((res) => {
          let issuersData = res.data;
          let rowsToSet = getRowsFromResponse(issuersData, "remolques");
          setRemolquesRows((ir) => rowsToSet);
          setLoading((l) => false);
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            setLoading((l) => false);
            setAlert({
              open: true,
              duration: 6000,
              severity: "error",
              message: getErrorMessageFromStatus(err.response.status),
            });
            return;
          }
          setAlert({
            open: true,
            duration: 6000,
            severity: "error",
            message: "Ocurrió un error al obtener los remolques",
          });
        });
    };

    setLoading(true);

    switch (data) {
      case "camiones":
        getVehicles();

        break;
      case "remolques":
        getRemolques();

        break;

      default:
        getVehicles();
        getRemolques();
        break;
    }
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: !alert.open });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <AlertDialog {...dialog} />
      <Snackbar
        open={alert.open}
        autoHideDuration={alert.duration}
        onClose={handleCloseAlert}
      >
        <Alert severity={alert.severity}>{alert.message}</Alert>
      </Snackbar>
      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: "center",
          justifyItems: "center",
          alignItems: "center",
          alignContent: "center",
          padding: "2rem",
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h4" align="center" color="primary.dark">
            {showRemolques ? "Remolques" : "Camiones"}
          </Typography>
        </Grid>

        <Grid item xs={6} md={3} display="flex" justifyContent={"left"}>
          <Typography variant="body1" color="primary">
            {"Camiones"}
          </Typography>
          <Switch
            checked={showRemolques}
            onChange={() => setShowRemolques(!showRemolques)}
            inputProps={{ "aria-label": "controlled" }}
            size="small"
          />
          <Typography variant="body1" color="primary">
            {"Remolques"}
          </Typography>
        </Grid>
        <Grid item md={6} display="flex" justifyContent={"left"}>
          <ConsultSearchComponent
            label={"Buscar (Placa, Tipo)"}
            onSearch={handleOuterSearch}
            handleClearSerch={clearSerch}
          />
        </Grid>
        <Grid item xs={12} md={3} display="flex" justifyContent={"right"}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ color: "white" }}
            onClick={
              showRemolques
                ? () => navigate(`/${userid.claims.rol}/transports/newTow`)
                : () => navigate(`/${userid.claims.rol}/transports/newTruck`)
            }
          >
            {showRemolques ? "Registrar Remolque" : "Registrar Camión"}
          </Button>
        </Grid>
        {!showRemolques && (
          <Grid
            item
            xs={12}
            sx={{
              "& .header-table": {
                backgroundColor: ferbyTheme.palette.primary.main,
                color: "white",
              },
            }}
          >
            <DataGrid
              sx={{ width: "100%" }}
              rows={camionesRows}
              autoHeight
              pageSize={10}
              columns={camionesColumns}
              disableSelectionOnClick
            />
          </Grid>
        )}

        {showRemolques && (
          <Grid
            item
            xs={12}
            sx={{
              "& .header-table": {
                backgroundColor: ferbyTheme.palette.primary.main,
                color: "white",
              },
            }}
          >
            <DataGrid
              sx={{ width: "100%" }}
              rows={remolquesRows}
              autoHeight
              pageSize={10}
              columns={remolquesColumns}
              disableSelectionOnClick
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default TransportsIndex;
