import React from "react";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import {LocalizationProvider, DatePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

interface FormFieldProps {
  inputType: React.HTMLInputTypeAttribute; // Extend as needed
  name: string;
  label?: string;
  value?: any;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement> | any,
    value?: any,
    reason?: any
  ) => void;
  options?: {label: string; value: any}[]; // For select fields
  multiline?: boolean;
  id: string;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  // Add other common props as needed

  //MUI PROPS
  variant?: "standard" | "outlined" | "filled";
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  sx?: any;
}

const DynamicFormField: React.FC<FormFieldProps> = ({
  inputType,
  name,
  label,
  value,
  onChange,
  options,
  variant,
  multiline,
  error,
  helperText,
  id,
  isOptionEqualToValue,
  disabled,
  sx,
}) => {
  const renderTextField = () => {
    console.log(error, helperText);

    return (
      <TextField
        type={inputType}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        fullWidth
        variant={variant}
        multiline={multiline}
        error={error}
        helperText={helperText}
        id={id}
        disabled={disabled}
      />
    );
  };

  const renderCheckbox = () => (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox checked={value} onChange={onChange} name={name} id={id} />
        }
        label={label}
        disabled={disabled}
        sx={sx}
      />
    </FormGroup>
  );

  const renderAutocomplete = () => (
    <Autocomplete
      id={id}
      options={options || []}
      getOptionLabel={(option) => option.label}
      onChange={(event, newValue, reason) => onChange(event, newValue, reason)}
      value={options?.find((option) => option.value === value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
      fullWidth
      isOptionEqualToValue={isOptionEqualToValue ? isOptionEqualToValue : null}
      disabled={disabled}
    />
  );

  const renderDatePicker = () => (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={value}
        onChange={(newValue) => onChange({target: {id}}, newValue)}
        disabled={disabled}
      />
    </LocalizationProvider>
  );

  switch (inputType) {
    case "text":
      return renderTextField();
    case "number":
      return renderTextField();
    case "checkbox":
      return renderCheckbox();
    case "select":
      return renderAutocomplete();
    case "date":
      return renderDatePicker();
    default:
      return null;
  }
};

export default DynamicFormField;
