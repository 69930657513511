const BACKEND_CONFIG = {
  API_URL: "http://localhost:3000",
  COMPONENTS: {
    EMPLOYEES: {
      ABSTRACT_SEARCH_KEYS: [
        "Rfc",
        "Nombre y apellidos",
        "CURP",
        "NSS",
        "Puesto",
        "Dpto",
        "No. Licencia",
        "Banco",
        "CtaBanco",
      ],
    },
  },
};

export default BACKEND_CONFIG;
