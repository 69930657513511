export function getFiscalRegimeNameFromKey(key) {
  switch (String(key)) {
    case "612":
      return "Personas físicas con actividad empresarial";
    case "601":
      return "Régimen General de Ley para personas morales";
    case "626":
      return "Régimen simplificado de confianza";
    default:
      return "Régimen no soportado para previsualizar";
  }
}

export const getTypeOfInvoice = (jsonCFDI) => {
  console.log(jsonCFDI);
  if (jsonCFDI.Complementos) {
    if (
      Object.keys(jsonCFDI.Complementos).length === 0 &&
      jsonCFDI.GeneralModel.CfdiType.Type == "I"
    ) {
      return "facturaIngreso";
    }
    if (jsonCFDI.Complementos.CartaPorte) {
      return "cartaPorte";
    }
    if (jsonCFDI.Complementos.Payments) {
      return "pago";
    }
    if (jsonCFDI.Complementos.Payroll) {
      return "nomina";
    }
  }

  if (jsonCFDI.GeneralModel.CfdiType.Type == "I") {
    return "facturaIngreso";
  }
  return "invalid";
};
