import {DynamicFieldConfig} from "../../../../components/layout/interfaces/DynamicFieldConfig.interface";
import {FiscalConfigurationDto} from "./fiscalConfiguration.dto";

export const InitialFiscalConfigurationFormsDto: FiscalConfigurationDto = {
  AutomaticFolioManagement: false,
  DynamicIssuerRegime: false,
};

export const KeysFromDto = Object.keys(InitialFiscalConfigurationFormsDto);

export const EnableAutomaticFolioManagementCheckboxConfigDto: DynamicFieldConfig<boolean> =
  {
    type: "checkbox",
    label: "Gestión automática de Folios",
    name: "automaticFolioManagent",
    id: KeysFromDto[0],
    validator: (value: boolean) => {
      // Add your validation logic for the checkbox field here
      return {failed: false, message: ""};
    },
  };

export const EnableDynamicIssuerRegimeCheckboxConfigDto: DynamicFieldConfig<boolean> =
  {
    type: "checkbox",
    label: "Régimen de emisor dinámico",
    name: "dynamicIssuerFiscalRegime",
    id: KeysFromDto[1],
    validator: (value: boolean) => {
      // Add your validation logic for the checkbox field here
      return {failed: false, message: ""};
    },
  };
