import apiObj from "../../../axios";
import { MexicoFederalEntityKeysCatalog } from "../../../modules/invoices/utils/staticCatalogs";

const {api, version, version2} = apiObj;

export const API_CONTEXT = (where, what) => {
    const catalog = {
        //"invoiceTypes":(usr, cred) => api.get(`${version}/invoices/catalog/invoiceTypes`,{
        "invoiceTypes":(usr, cred) => api.get(`${version2}/catalog/invoiceTypes`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        //"banks":(usr, cred) => api.get(`${version}/invoices/catalog/banks`,{
        "banks":(usr, cred) => api.get(`${version2}/catalog/banks`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        //"fiscalRegimes":(usr,cred) => api.get(`${version}/invoices/catalog/fiscalRegimes`,{
        "fiscalRegimes":(usr,cred) => api.get(`${version2}/catalog/fiscalRegimes`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        //"regimenTypes":(usr,cred) => api.get(`${version}/invoices/catalog/regimenTypes`,{
        "regimenTypes":(usr,cred) => api.get(`${version2}/catalog/regimenTypes`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        //"paymentMethods":(usr, cred) => api.get(`${version}/invoices/catalog/paymentMethods`,{
        "paymentMethods":(usr, cred) => api.get(`${version2}/catalog/paymentMethods`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        //"paymentForms":(usr, cred) => api.get(`${version}/catalog/paymentForms`,{
        "paymentForms":(usr, cred) => api.get(`${version2}/catalog/paymentForms`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        //"paymentFrequencies":(usr, cred) => api.get(`${version}/invoices/catalog/paymentFrequencies`,{
        "paymentFrequencies":(usr, cred) => api.get(`${version2}/catalog/paymentFrequencies`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        //"currencies":(usr, cred) => api.get(`${version}/invoices/catalog/currencies`,{
        "currencies":(usr, cred) => api.get(`${version2}/catalog/currencies`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        //"uses":(usr, cred) => api.get(`${version}/invoices/catalog/uses`,{
        "uses":(usr, cred) => api.get(`${version2}/catalog/uses`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        //"perceptions":(usr, cred) => api.get(`${version}/invoices/catalog/perceptions`,{
        "perceptions":(usr, cred) => api.get(`${version2}/catalog/perceptions`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        //"deductions":(usr, cred) => api.get(`${version}/invoices/catalog/deductions`,{
        "deductions":(usr, cred) => api.get(`${version2}/catalog/deductions`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        //"otherPayments":(usr, cred) => api.get(`${version}/invoices/catalog/otherPayments`,{
        "otherPayments":(usr, cred) => api.get(`${version2}/catalog/otherPayments`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        //"productsOrServices":(usr,cred, query) => api.get(`${version}/invoices/catalog/ProductsOrServices?q=${query}`,{
        "productsOrServices":(usr,cred, query) => api.get(`${version2}/catalog/ProductsOrServices?q=${query}`,{
            headers:{
                user:usr,
                authorization:usr.token,
            }
        }),
        "productsOrServicesCcp":(usr,cred, query) => api.get(`${version2}/catalog/ProductsOrServices?q=${query}`,{
            headers:{
                user:usr,
                authorization:usr.token,
                prodservccp: true
            }
        }),
        //"units":(usr,cred, query) => api.get(`${version}/invoices/catalog/units?q=${query}`,{
        "units":(usr,cred, query) => api.get(`${version2}/catalog/units?q=${query}`,{
            headers:{
                user:usr,
                authorization:usr.token
            }
        }),
        //"postalCodes":(usr, cred, query) => api.get(`${version}/invoices/catalog/postalCodes?q=${query}`,{
        "postalCodes":(usr, cred, query) => api.get(`${version2}/catalog/postalCodes?q=${query}`,{
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),
        //"positionRisks":(usr, cred, query) => api.get(`${version}/invoices/catalog/positionRisks`,{
        "positionRisks":(usr, cred, query) => api.get(`${version2}/catalog/positionRisks`,{
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),  
        //"contractTypes":(usr, cred, query) => api.get(`${version}/invoices/catalog/contractTypes`,{
        "contractTypes":(usr, cred, query) => api.get(`${version2}/catalog/contractTypes`,{
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),  
        //"typesOfJourney":(usr, cred, query) => api.get(`${version}/invoices/catalog/typesOfJourney`,{
        "typesOfJourney":(usr, cred, query) => api.get(`${version2}/catalog/typesOfJourney`,{
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),  
        "federalEntityKeys":MexicoFederalEntityKeysCatalog,
        //"transportServicesCodes":(usr, cred, query) => api.get(`${version}/invoices/productsServicesTransport/all`,{
        "transportServicesCodes":(usr, cred, query) => api.get(`${version2}/catalog/productsServicesTransport/all`,{
            headers:{
                authorization:usr.token,
                user:usr
            }
        }),  
    }

  const issuers = {
    expeditionPlc: (usr, cred) =>
      api.get(`${version}/invoices/expeditionPlaces`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    deleteExpeditionPlc: (usr, id) =>
      api.delete(`${version}/invoices/expeditionPlaces/${id}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    list: (usr, cred) =>
      api.get(`${version}/issuers`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    save: (usr, cred, body) =>
      api.post(`${version}/issuers`, body, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    cert: (usr, cred, body, rfc) =>
      api.patch(`${version}/issuers/cer/${rfc}`, body, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    delete: (usr, cred, rfc) =>
      api.delete(`${version}/issuers/${rfc}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    key: (usr, cred, body, rfc) =>
      api.patch(`${version}/issuers/key/${rfc}`, body, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    pac: (usr, cred, body, rfc) =>
      api.post(`${version}/issuers/save-pac/${rfc}`, body, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    search: (usr, cred, nameString, searchField) =>
      api.get(`${version}/issuers`, {
        params: {
          [searchField]: nameString,
        },
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    nextFolio: (usr, cred, query) =>
      api.get(`${version}/folios/next-folio?${query}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),

    createSerieFolio: (usr, cred, data) =>
      api.post(`${version}/folios/`, data, {
        headers: {
          user: usr,
          authorization: cred.token,
        },
      }),

    seriesFolios: (usr, cred, id) =>
      api.get(`${version}/folios/series/byIssuer?Issuer=${id}`, {
        headers: {
          user: usr,
          authorization: cred.token,
        },
      }),
    updateSerieFolio: (usr, cred, id, data) =>
      api.put(`${version}/folios/${id}`, data, {
        headers: {
          user: usr,
          authorization: cred.token,
        },
      }),
    resetSerieFolio: (usr, cred, id) =>
      api.patch(
        `${version}/folios/reset-folios/${id}`,
        {},
        {
          headers: {
            user: usr,
            authorization: cred.token,
          },
        }
      ),
    deleteSerieFolio: (usr, cred, id) =>
      api.delete(`${version}/folios/${id}`, {
        headers: {
          user: usr,
          authorization: cred.token,
        },
      }),
  };

  const employees = {
    all: (usr, cred) =>
      api.get(`${version}/organization/employees/all`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    new: (usr, cred, employeeJson) =>
      api.post(`${version}/organization/employees/`, employeeJson, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    filterEmployees: (usr, filters) =>
      api.get(`${version}/organization/employees/?&${filters.join("&")}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    searchByKey: (usr, cred, keyString, valueString) =>
      api.get(`${version}/organization/employees?${keyString}=${valueString}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    updateEmployee: (usr, cred, body) =>
      api.put(`${version}/organization/employees`, body, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    deleteEmployee: (usr, cred, rfc, body) =>
      api.delete(`${version}/organization/employees/${rfc}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
        data: body,
      }),
  };

  const organization = {
    customers: (usr, cred) =>
      api.get(`${version}/customers/?isPagination=false`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    filterCustomers: (usr, filters) =>
      api.get(`${version}/customers/?isPagination=false&${filters.join("&")}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    findCustomer: (usr, customerId) =>
      api.get(`${version}/customers/${customerId}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    saveCustomer: (body, usr, cred) =>
      api.post(`${version}/customers/`, body, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    editCustomer: (customerId, body, usr, cred) =>
      api.put(`${version}/customers/${customerId}`, body, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    deleteCustomer: (usr, cred, id, body) =>
      api.delete(`${version}/customers/${id}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
        data: body,
      }),
    operators: (usr, cred) =>
      api.get(
        `${version}/organization/employees/?Puesto=OPERADOR&isPagination=false`,
        {
          headers: {
            user: usr,
            authorization: usr.token,
          },
        }
      ),
    newPlace: (usr, cred, rfc, addressObj) =>
      api.post(`${version}/address?rfc=${rfc}`, addressObj, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    activatePlace: (usr, cred, id, addressObj) =>
      api.post(`${version}/address/saveInPac?addressId=${id}`, addressObj, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    emitidos: (usr, cred, pageNumber, filters) =>
      api.get(
        `${version}/invoices?pageNumber=${pageNumber}&${filters.join("&")}`,
        {
          headers: {
            user: usr,
            authorization: usr.token,
          },
        }
      ),
    analytics: (usr, cred, range, chunks, days, filters) =>
      api.get(
        `${version}/invoices/analytics/chunks?${range.join("&")}${
          chunks ? `&numberChunk=${chunks}` : `&daysByChunk=${days}`
        }&${filters.join("&")}`,
        {
          headers: {
            user: usr,
            authorization: usr.token,
          },
        }
      ),

    getLogo: (usr, cred) =>
      api.get(`${version}/logos/`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    updateLogo: (usr, logo) =>
      api.patch(`${version}/logos/upload`, logo, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
  };

  const customers = {
    searchName: (usr, cred, nameString, searchField) =>
      api.get(`${version}/customers`, {
        params: {
          [searchField]: nameString,
        },
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
  };

  const transports = {
    vehicles: (usr, cred) =>
      api.get(`${version2}/vehicles/`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    remolques: (usr, cred) =>
      api.get(`${version}/remolques/`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    getVehicle: (usr, cred, id) =>
      api.get(`${version2}/vehicles/id/${id}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    getRemolque: (usr, cred, id) =>
      api.get(`${version}/remolques/${id}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    filterVehicle: (usr, filters) =>
      api.get(`${version2}/vehicles/?${filters.join("&")}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    filterRemolque: (usr, filters) =>
      api.get(`${version}/remolques/?&${filters.join("&")}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    newVehicle: (usr, cred, body) =>
      api.post(`${version2}/vehicles/`, body, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    uploadPolicyVehicle: (usr, cred, id, body) =>
      api.patch(`${version2}/vehicles/policy/${id}`, body, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    newRemolque: (usr, cred, body) =>
      api.post(`${version}/remolques/`, body, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    updateVehicle: (usr, cred, id, body) =>
      api.put(`${version2}/vehicles/${id}`, body, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    updateRemolque: (usr, cred, id, body) =>
      api.put(`${version}/remolques/${id}`, body, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    deleteVehicle: (usr, cred, id, body) =>
      api.delete(`${version2}/vehicles/${id}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
        data: body,
      }),
    deleteRemolque: (usr, cred, id, body) =>
      api.delete(`${version}/remolques/${id}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
        data: body,
      }),
  };

  const expenses = {
    getExpenses: (usr, pageNumber, Description) =>{
      let q = '';
      if(Description && Description.length > 1){
        q += `&Description=${Description}`
      }

      return api.get(`${version}/expenses?isPagination=true&pageNumber=${pageNumber}${q}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      })
    },
    getExpense: (usr, expenseId) =>
      api.get(`${version}/expenses/${expenseId}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    calculateTax: (usr, body) =>
      api.post(`${version}/expenses/calculate-tax`, body,  {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    save: (usr, body) =>
      api.post(`${version}/expenses/save`, body,  {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    edit: (usr, expenseId, body) =>
      api.put(`${version}/expenses/${expenseId}`, body,  {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    delete: (usr, expenseId) =>
      api.delete(`${version}/expenses/${expenseId}`,  {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    uploadFile: (usr, expenseId, formdata) =>
      api.patch(`${version}/expenses/upload-file/${expenseId}`, formdata, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    downloadFile: (usr, expenseId, expenseFileId) =>
      api.get(`${version}/expenses/download-file/${expenseId}/${expenseFileId}`,  {
        headers: {
          user: usr,
          authorization: usr.token,
        },
        responseType: 'blob',
      }),
    deleteFile: (usr, expenseId, expenseFileId) =>
      api.delete(`${version}/expenses/delete-file/${expenseId}/${expenseFileId}`,  {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
  }

  const issuing = {
    parseXML: (usr, cred, complemento, empresa, formData) =>
      api.post(
        `${version}/invoices/files/complementos/${complemento}/${empresa}`,
        formData,
        {
          headers: {
            user: usr,
            authorization: usr.token,
            "Content-type": "multipart/form-data",
          },
        }
      ),
    build: (usr, cred, invoiceJson) =>
      api.post(`${version}/invoices/preview`, invoiceJson, {
        headers: {
          authorization: usr.token,
          user: usr,
        },
      }),
    calculateTax: (usr, cred, taxObject) =>
      api.post(`${version}/invoices/calculation/tax`, taxObject, {
        headers: {
          authorization: usr.token,
          user: usr,
        },
      }),
    sellar: (usr, cred, buildedModel, builded) =>
      api.post(`${version}/invoices/sellar`, buildedModel, {
        headers: {
          authorization: usr.token,
          user: usr,
        },
      }),
    files: (usr, cred, fileType, invoiceId) =>
      api.get(`${version}/invoices/files/invoice/${fileType}/${invoiceId}`, {
        headers: {
          authorization: usr.token,
          user: usr,
        },
      }),
    paid: (usr, cred, invoiceId) =>
      api.patch(
        `${version}/invoices/paid/${invoiceId}`,
        {},
        {
          headers: {
            authorization: usr.token,
            user: usr,
          },
        }
      ),
    put: (usr, cred, invoiceId, data) =>
      api.put(`${version}/invoices/${invoiceId}`, data, {
        headers: {
          authorization: usr.token,
          user: usr,
        },
      }),
    cancel: (usr, cred, cancelInvoicePayload) =>
      api.patch(
        `${version}/invoices/cancel`,
        {
          id: cancelInvoicePayload.id,
          motive: cancelInvoicePayload.motive,
          relation: cancelInvoicePayload.relation,
          password: cancelInvoicePayload.password,
        },
        {
          headers: {
            authorization: usr.token,
            user: usr,
          },
        }
      ),
  };

  const brands = {
    findBrands: (usr, search = null) => {
      const term = search ? `?Name=${search}` : '';
      return api.get(`${version2}/vehicles/brands${term}`, {
        headers: {
          authorization: usr.token,
          user: usr,
        },
      })
    }
  }

  const methods = {
    catalog: catalog,
    issuers: issuers,
    organization: organization,
    customers: customers,
    issuing: issuing,
    employees: employees,
    transports: transports,
    expenses: expenses,
    brands: brands
  };

  return methods[where][what];
};

/**
 *
 * @param {*} user that must have a rol in the actual context
 * @param {*} context NOT IMPLEMENTED but is thought as a way to build the invoice instance based on certain context
 * @param {*} redirectFunction is the react router hook passed as param
 */
export const buildInstanceAndRedirect = (user, redirectFunction, context) => {
  redirectFunction(`/${user.claims.rol}/invoices/new`);
};

export const parseXMLCartaPorte = (usr, cred, company, formData) => {
  const parseXMLComplement = API_CONTEXT("issuing", "parseXML");

  return parseXMLComplement(usr, cred, "cartaPorte", company, formData);
};

export const buildModelForTimbrado = (user, credential, invoiceJson) => {
  const buildModel = API_CONTEXT("issuing", "build");

  return buildModel(user, credential, invoiceJson);
};

export const issueInvoice = (user, credential, invoiceJson, builded) => {
  const issueFunction = API_CONTEXT("issuing", "sellar");
  return issueFunction(user, credential, invoiceJson, builded);
};
export const getInvoiceFiles = (user, credential, fileType, invoiceId) => {
  const fileFunction = API_CONTEXT("issuing", "files");
  return fileFunction(user, credential, fileType, invoiceId);
};

export const paidInvoice = (user, credential, invoiceId) => {
  const paidInvoiceFunction = API_CONTEXT("issuing", "paid");
  return paidInvoiceFunction(user, credential, invoiceId);
};

export const putInvoice = (user, credential, invoiceId, data) => {
  const paidInvoiceFunction = API_CONTEXT("issuing", "put");
  return paidInvoiceFunction(user, credential, invoiceId, data);
};

export const cancelInvoice = (user, credential, cancelInvoicePayload) => {
  const cancelInvoiceFunction = API_CONTEXT("issuing", "cancel");
  return cancelInvoiceFunction(user, credential, cancelInvoicePayload);
};
