import React, {useEffect, useState} from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import {IssuerSeriesViewer} from "../../../components/module specific/Configuration/IssuerSerieViewer";
import {DynamicFieldConfig} from "../../../components/layout/interfaces/DynamicFieldConfig.interface";
import {
  EnableAutomaticFolioManagementCheckboxConfigDto,
  EnableDynamicIssuerRegimeCheckboxConfigDto,
  InitialFiscalConfigurationFormsDto,
} from "./ComponentConfig/FiscalFormFieldsConfig";
import DynamicFormField from "../../../components/Forms/DynamicFormField";
import useAppContext from "../../../hooks/useAppContext";
import useAuth from "../../../hooks/useAuth";
import {updateUserOrganizationConfig} from "../../../services/modules/users/users";
import {httpErrorResponses} from "../../../components/userFeedback/httpErrorResponses";
import {IOrganizationConfigurationDto} from "../ComponentsConfig/organizationConfiguration.dto";

interface IFiscalConfigurationProps {}

/**
 * Component for configuring fiscal settings.
 *
 * @component
 * @param {IFiscalConfigurationProps} organization - The organization object.
 * @returns {JSX.Element} The rendered component.
 */
export const FiscalConfiguration = ({}: IFiscalConfigurationProps) => {
  const {dialog, setDialog, setAlert} = useAppContext();
  const {userid, organization} = useAuth();

  // Estado para controlar el checkbox (opcional)
  const [checked, setChecked] = useState({
    [EnableAutomaticFolioManagementCheckboxConfigDto.name]: false,
    [EnableDynamicIssuerRegimeCheckboxConfigDto.name]: false,
  });
  const [config, setConfig] = useState(InitialFiscalConfigurationFormsDto);
  const [loading, setLoading] = useState(false);
  const [canSetConfig, setCanSetConfig] = useState(true);
  const [showConsultIssuers, setShowConsultIssuers] = useState(false);

  const updateOrganization = (newConfig: IOrganizationConfigurationDto) => {
    if (newConfig) {
      updateUserOrganizationConfig(
        userid,
        userid,
        userid.claims.user_id,
        newConfig
      )
        .then((response) => {
          setAlert({
            open: true,
            message: "Se actualizó la configuración",
            severity: "success",
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setDialog({
            open: true,
            title: "Error al actualizar la configuración",
            message: httpErrorResponses(err),
            actions: [
              {
                label: "Cerrar",
                execute: () => {
                  setDialog({open: false});
                },
              },
            ],
          });
        });
    }
  };

  const handleConfigUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (config) {
      const newConfig = {...config, [event.target.id]: event.target.checked};
      setConfig({...config, [event.target.id]: event.target.checked});
      setLoading(true);
      updateOrganization({Fiscal: newConfig});
    }
  };

  const handleCheckBoxesChanges = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setChecked({...checked, [event.target.name]: event.target.checked});

    if (
      event.target.name === EnableDynamicIssuerRegimeCheckboxConfigDto.name &&
      event.target.checked
    ) {
      setDialog({
        ...dialog,
        open: true,
        title:
          "ALERTA: Esta configuración puede afectar la facturación de tu organización.",
        message:
          "Habilitar esta opción puede incrementar el numero de errores en la facturación, ¿Deseas activarla realmente?",
        actions: [
          {
            label: "Cancelar",
            execute: () => {
              setChecked({
                ...checked,
                [EnableAutomaticFolioManagementCheckboxConfigDto.name]: false,
              });
              setDialog({open: false});
            },
          },
          {
            label: "Aceptar",
            execute: () => {
              handleConfigUpdate(event);
              setDialog({open: false});
            },
          },
        ],
      });
      return;
    }

    handleConfigUpdate(event);
  };

  const handleShowManageIssuers = () => {
    setShowConsultIssuers((prev) => !prev);
  };

  const handleOpenSnackBar = (data: {message: string; severity: string}) => {
    setAlert({
      open: true,
      message: data.message,
      severity: data.severity,
    });
  };

  const handleSetLoading = (value: boolean) => {
    setLoading(value);
  };

  const automatiFolioManagementCheckBox: DynamicFieldConfig<boolean> = {
    ...EnableAutomaticFolioManagementCheckboxConfigDto,
    onChange: handleCheckBoxesChanges,
    disabled: loading || !canSetConfig,
    sx: {
      "&:hover": {
        backgroundColor: "rgba(0, 128, 255, 0.1)",
        transition: "background-color 0.3s ease",
        borderRadius: "5px",
      },
      width: "fit-content",
    },
  };

  const dynamicIssuerFiscalRegimeCheckBox: DynamicFieldConfig<boolean> = {
    ...EnableDynamicIssuerRegimeCheckboxConfigDto,
    onChange: handleCheckBoxesChanges,
    disabled: loading || !canSetConfig,
    sx: {
      "&:hover": {
        backgroundColor: "rgba(0, 128, 255, 0.1)",
        transition: "background-color 0.3s ease",
        borderRadius: "5px",
      },
      width: "fit-content",
    },
  };

  useEffect(() => {
    if (organization) {
      setLoading(false);
      setChecked({
        ...checked,
        [EnableAutomaticFolioManagementCheckboxConfigDto.name]:
          organization.Config.Fiscal.AutomaticFolioManagement,
        [EnableDynamicIssuerRegimeCheckboxConfigDto.name]:
          organization.Config.Fiscal.DynamicIssuerRegime,
      });
      setCanSetConfig(true);
      setConfig(organization.Config.Fiscal);
    }

    if (!organization) {
      setLoading(true);
    }
  }, [organization]);

  return (
    <Grid item xs={12}>
      {loading && (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )}
      {!loading && (
        <React.Fragment>
          <Grid item xs={6} mb={"2vh"}>
            <DynamicFormField
              inputType={automatiFolioManagementCheckBox.type}
              label={automatiFolioManagementCheckBox.label}
              id={automatiFolioManagementCheckBox.id}
              name={automatiFolioManagementCheckBox.name}
              value={checked[automatiFolioManagementCheckBox.name]}
              onChange={automatiFolioManagementCheckBox.onChange}
              disabled={automatiFolioManagementCheckBox.disabled}
              sx={automatiFolioManagementCheckBox.sx}
              error={automatiFolioManagementCheckBox.error}
              helperText={automatiFolioManagementCheckBox.helperText}
            />
          </Grid>
          {checked.automaticFolioManagent && (
            <>
              <Button
                variant="contained"
                onClick={handleShowManageIssuers}
                sx={{marginBottom: 2}}
              >
                Configurar folios y series por emisor
              </Button>
              {showConsultIssuers && (
                <IssuerSeriesViewer
                  alert={handleOpenSnackBar}
                  loading={handleSetLoading}
                />
              )}
            </>
          )}

          <Grid item xs={6} mb={"2vh"}>
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="body2">
                    Permite seleccionar el regimen fiscal durante el proceso de
                    facturación, en lugar de asignarlo de forma estática.
                  </Typography>
                </React.Fragment>
              }
            >
              <DynamicFormField
                inputType={dynamicIssuerFiscalRegimeCheckBox.type}
                label={dynamicIssuerFiscalRegimeCheckBox.label}
                id={dynamicIssuerFiscalRegimeCheckBox.id}
                name={dynamicIssuerFiscalRegimeCheckBox.name}
                value={checked[dynamicIssuerFiscalRegimeCheckBox.name]}
                onChange={dynamicIssuerFiscalRegimeCheckBox.onChange}
                disabled={dynamicIssuerFiscalRegimeCheckBox.disabled}
                sx={dynamicIssuerFiscalRegimeCheckBox.sx}
                error={dynamicIssuerFiscalRegimeCheckBox.error}
                helperText={dynamicIssuerFiscalRegimeCheckBox.helperText}
              />
            </Tooltip>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};
