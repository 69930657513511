import React, { createContext, useEffect, useState } from "react";
import auth from "../firebase.js";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { getUserOrganization } from "../services/modules/users/users.js";
import { AlertDialog } from "../components/userFeedback/AlertDialog.jsx";
import { organizationObservable } from "../components/observables/organizationObservable.jsx";

const context = createContext();

const AuthContext = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userid, setUserid] = useState("");
  const [organization, setOrganization] = useState(null);
  const intervalRef = React.useRef();
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    message: "",
  });

  const signUp = (email, pass) =>
    createUserWithEmailAndPassword(auth, email, pass);

  const logout = () => signOut(auth);

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const isValidCredential = (credential) => {
    const responseModel = {
      isValid: false,
      reason: "Unverified validation",
    };

    const emailVerified = credential.user.emailVerified;

    if (!emailVerified) {
      responseModel.isValid = !emailVerified;
      responseModel.reason = "Email no verificado";
    }

    return responseModel;
  };

  const handleErrorWithHTTPStatus = (status, error) => {
    const message = error.response.data.message;

    switch (status) {
      case 401:
        setDialog((prevDialog) => {
          return {
            ...prevDialog,
            open: true,
            title: "Error de autenticación",
            message: "No autorizado para ver la información. " + message,
          };
        });
        break;
      case 404:
        setDialog((prevDialog) => {
          return {
            ...prevDialog,
            open: true,
            title: "Error autenticación",
            message: "No se encontró el recurso. " + message,
          };
        });
        break;
      case 500:
        setDialog((prevDialog) => {
          return {
            ...prevDialog,
            open: true,
            title: "Error autenticación",
            message: "Error interno del servidor. " + message,
          };
        });
        break;
      default:
        setDialog((prevDialog) => {
          return {
            ...prevDialog,
            open: true,
            title: "Error autenticación",
            message: "Error desconocido. " + message,
          };
        });
        break;
    }
  };

  const fetchUserOrganization = (user) => {
    getUserOrganization(user, user, user.claims.user_id)
      .then((response) => {
        setOrganization(response.data);
        console.log(response);
      })
      .catch((err) => {
        console.log(err.message);
        if (err.response) {
          if (err.response.status) {
            handleErrorWithHTTPStatus(err.response.status, err);
            return;
          }

          setDialog((prevDialog) => {
            return {
              ...prevDialog,
              open: true,
              title: "Error",
              message: err.response.data.message,
            };
          });
          return;
        }

        if (err.message === "Network Error") {
          setDialog((prevDialog) => {
            return {
              ...prevDialog,
              open: true,
              title: "Error de conexión",
              message: "Verifica tu conexión o contacta a soporte",
            };
          });
          return;
        }

        setDialog((prevDialog) => {
          return {
            ...prevDialog,
            open: true,
            title: "Fallo de autentiación",
            message: "Error desconocido al obtener la organización.",
          };
        });
      });
  };

  const refreshUserId = () => {
    if (user) {
      auth.currentUser.getIdTokenResult(false).then((token) => {
        if (token != userid) {
          fetchUserOrganization(token);
          setUserid(token);
        }
      });
    }
  };

  const handleDialogOpenState = () => {
    setDialog({
      open: !dialog.open,
      title: "",
      message: "",
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      auth.currentUser
        .getIdTokenResult(false)
        .then((token) => {
          fetchUserOrganization(token);
          setUserid(token);
          setPersistence(auth, browserSessionPersistence);
        })
        .catch((err) => {
          console.log(err);
          setDialog((prevDialog) => {
            return {
              ...prevDialog,
              open: true,
              title: "Error de autenticación",
              message:
                "Error para verificar tu identidad, por favor contacta a soporte.",
            };
          });
        });
    }
    intervalRef.current = setInterval(refreshUserId, 1000 * 60 * 5);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [user]);

  useEffect(() => {
    organizationObservable.subscribe(fetchUserOrganization);
    return () => {
      organizationObservable.unSubscribe(fetchUserOrganization);
    };
  }, []);

  return (
    <context.Provider
      value={{
        signUp,
        logout,
        user,
        userid,
        organization,
        loading,
        googleSignIn,
        isValidCredential,
      }}
    >
      <AlertDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.message}
        updateOpenState={handleDialogOpenState}
        keep
      />
      {children}
    </context.Provider>
  );
};

export { AuthContext, context };
