import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
  Typography,
  Pagination,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import ShowColumns from "./Actions/ShowColumns";
import OrderBy from "./Actions/OrderBy";
import FilterBy from "./Actions/FilterBy";
import {GridRenderCellParams} from "@mui/x-data-grid";

export interface RenderCellParams {
  value: any;
  row: any;
}

export interface TableColumnDef {
  field: string;
  headerName: string;
  sx?: object;
  renderCell?: (params: RenderCellParams) => React.ReactNode;
}

interface CustomTableProps {
  title?: string;
  hasOptions?: boolean;
  checkBoxSelection?: boolean;
  columns: Array<TableColumnDef>;
  rows: Array<any> | {data: Array<any>; total_records: number};
  emptyMsg?: string;
  hasPagination?: boolean;
  isPaginationModeAPI?: boolean;
  defaultLocalRowsPerPage?: number;
  paginationDataState?: React.ComponentState;
  isFilterModeAPI?: boolean;
  filtersDataState?: React.ComponentState;
  disableSelectionOnClick?: boolean;
  selectionState?: Array<any>;
  setFiltersDataState?: (filters: object) => void;
  cellPadding?: string;
  setPaginationDataState?: (paginationData: {
    pageSize: number;
    pageNumber: number;
  }) => void;
  handlePageChange?: (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => void;
  handleRowsPerPageChange?: (
    event: React.ChangeEvent<{value: unknown}>
  ) => void;
  applyFilters?: (filteredData: Array<any>, filters: any) => void;
  handleSelectedRow?: (
    rowIndex: number,
    row: any,
    fromCheckBox?: boolean
  ) => void;
  handleSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectCheckBox?: (
    event: React.ChangeEvent<HTMLInputElement>,
    row: any,
    rowIndex: number
  ) => void;
  setSelectionState?: React.SetStateAction<any>;
  handleSelect?: (selected: any) => void;
  getRowId?: string;
  containerStyle?: object;
  useStyles?: object;
  hoveredRow?: number | null;
  setHoveredRow?: (rowIndex: number | null) => void;
  appliedFilters?: object;
  setAppliedFilters?: (filters: object) => void;
  dialogProps?: {
    open: boolean;
    title: string;
    content: React.ReactNode;
  };
  handleDialogOpen?: (title: string, content: React.ReactNode) => void;
  handleDialogClose?: () => void;
}

const CustomTable = React.memo(
  ({
    title,
    columns,
    rows,
    getRowId = "_id",
    emptyMsg = "No se encontraron datos",
    hasOptions = false,
    hasPagination = true,
    isPaginationModeAPI = false,
    defaultLocalRowsPerPage = 25,
    paginationDataState,
    setPaginationDataState,
    isFilterModeAPI = false,
    filtersDataState,
    setFiltersDataState,
    cellPadding = "5px 30px 5px 30px",
    containerStyle,
    handleSelect,
    disableSelectionOnClick = false,
    checkBoxSelection = false,
    selectionState = null,
    setSelectionState = null,
  }: CustomTableProps) => {
    // STYLES
    const scrollStyles = {
      "&::-webkit-scrollbar": {
        width: 8,
        height: 8,
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 8,
        backgroundColor: "rgba(0 0 0 / 0.5)",
        border: "1px solid #e7ebf0",
      },

      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "rgba(0 0 0 / 0.6)",
      },
    };
    const useStyles = {
      headerContainer: {
        display: "flex",
        justifyContent: title ? "space-between" : "flex-end",
        alignItems: "center",
        flexWrap: "wrap",
      },
      headerOptions: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      },
      tableContainer: {
        maxHeight: "100%",
        borderRadius: 8,
        boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.15)",
        ...scrollStyles,
      },
      tableHeaderCell: {
        fontWeight: "bold",
        background: "white",
        color: "black",
        borderBottom: "3px solid black",
        textAlign: "center",
        verticalAlign: "middle",
        position: "sticky",
        top: 0,
        zIndex: 1,
      },
      tableCell: {
        textAlign: "center",
        verticalAlign: "middle",
        borderBottom: "1px solid #e0e0e0",
        padding: cellPadding,
      },
      emptyMessage: {
        textAlign: "center",
        color: "#9e9e9e",
      },
      selectedRow: {
        background: "#E9F6FF",
      },
      hoveredRow: {
        background: "#f5f5f5",
        cursor: "pointer",
      },
      footer: {
        display: "flex",
        justifyContent: hasPagination ? "space-between" : "flex-end",
        alignItems: "center",
        whiteSpace: "nowrap",
        gap: 2,
        mt: 1,
        overflowX: "auto",
        overflowY: "hidden",
        ...scrollStyles,
      },
      rowsPageSelect: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
      },
      dialogTitle: {
        bgcolor: "#01579b",
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
      },
    };

    // FILTER
    const [appliedFilters, setAppliedFilters] = useState({
      selectedColumns: isFilterModeAPI
        ? filtersDataState.selectedColumns || []
        : [],
      filterValues: isFilterModeAPI ? filtersDataState.filterValues || {} : {},
    });
    const [filteredRows, setFilteredRows] = useState(
      isPaginationModeAPI && !Array.isArray(rows) ? rows.data : rows
    );
    // PAGINATION
    const [totalRows, setTotalRows] = useState(
      isPaginationModeAPI && !Array.isArray(rows)
        ? rows.total_records
        : Array.isArray(filteredRows)
        ? filteredRows.length
        : 0
    );
    const [page, setPage] = useState(
      isPaginationModeAPI ? paginationDataState.pageNumber : 0
    );
    const [rowsPerPage, setRowsPerPage] = useState(
      isPaginationModeAPI
        ? paginationDataState.pageSize
        : defaultLocalRowsPerPage
    );
    // SELECTION
    const [selectedRow, setSelectedRow] = useState(null);
    const [hoveredRow, setHoveredRow] = useState(null);
    // DIALOG
    const [dialogProps, setDialogProps] = useState({
      open: false,
      title: "",
      content: null,
    });
    // COLUMNS
    const [columnVisibility, setColumnVisibility] = useState({});

    const [selected, setSelected] = useState([]);

    const handleDialogOpen = (title, content) => {
      setDialogProps({
        open: true,
        title,
        content,
      });
    };

    const handleDialogClose = () => {
      setDialogProps({
        ...dialogProps,
        open: false,
      });
    };

    const getTotalPages = () => Math.ceil(totalRows / rowsPerPage);

    const handlePageChange = (event, newPage) => {
      if (isPaginationModeAPI) {
        setPaginationDataState({
          ...paginationDataState,
          pageNumber: newPage,
        });
      } else {
        setPage(newPage - 1);
      }
    };

    const handleRowsPerPageChange = (event) => {
      const selectedRowsPerPage = parseInt(event.target.value, 10);
      if (isPaginationModeAPI) {
        setPaginationDataState({
          ...paginationDataState,
          pageSize: selectedRowsPerPage,
          pageNumber: 1,
        });
      } else {
        setRowsPerPage(selectedRowsPerPage);
        setPage(0);
      }
    };

    const applyFilters = (filteredData, filters) => {
      if (isFilterModeAPI) {
        setPage(1);
        setFiltersDataState({
          selectedColumns: filters.selectedColumns,
          filterValues: filters.filterValues,
          query: filteredData,
        });
      } else {
        setPage(0);
        setFilteredRows(filteredData);
        setTotalRows(filteredData.length);
      }
    };

    const handleSelectedRow =
      (rowIndex, row, fromCheckBox = false) =>
      () => {
        if ((!handleSelect || disableSelectionOnClick) && !fromCheckBox) return;

        if (rowIndex !== null && rowIndex !== undefined) {
          if (selectedRow === rowIndex) {
            setSelectedRow(null);
            if (handleSelect) {
              handleSelect(null);
            }
            return;
          }

          setSelectedRow(rowIndex);
          if (handleSelect) {
            handleSelect(row);
          }
          return;
        }
      };

    const handleSelectAllClick = (event) => {
      if (
        event.target.checked &&
        !(selected.length > 0 && selected.length < totalRows)
      ) {
        setSelected(() => {
          if (Array.isArray(filteredRows)) {
            return filteredRows;
          }
        });
        if (setSelectionState) {
          if (Array.isArray(filteredRows)) {
            setSelectionState(filteredRows);
          }
        }

        return;
      }

      if (setSelectionState) {
        setSelectionState([]);
      }
      setSelected([]);
    };

    const handleSelectCheckBox = (event, row, rowIndex) => {
      if (event.target.checked) {
        setSelected((prevSelected) => [...prevSelected, row]);
        handleSelectedRow(rowIndex, row, true)();
        return;
      }
      setSelected((prevSelected) => {
        const newSelected = [...prevSelected];
        const index = newSelected.indexOf(row);
        if (index !== -1) {
          newSelected.splice(index, 1);
        }
        return newSelected;
      });
      setSelectionState((prevSelected) => {
        const newSelected = [...prevSelected];
        const index = newSelected.indexOf(row);
        if (index !== -1) {
          newSelected.splice(index, 1);
        }
        return newSelected;
      });
      setSelectedRow(null);
      handleSelect(null);
    };

    useEffect(() => {
      setPage(isPaginationModeAPI ? 1 : 0);
      setFilteredRows(
        isPaginationModeAPI && !Array.isArray(rows) ? rows.data : rows
      );
      setTotalRows(
        isPaginationModeAPI && !Array.isArray(rows)
          ? rows.total_records
          : Array.isArray(rows)
          ? rows.length
          : 0
      );
    }, [rows]);

    useEffect(() => {
      const initialColumnVisibility = {};
      columns.forEach((column) => {
        initialColumnVisibility[column.field] = true;
      });
      setColumnVisibility(initialColumnVisibility);
    }, [columns]);

    return (
      <Box sx={{...containerStyle}}>
        {/* HEADER */}
        <Box sx={useStyles.headerContainer}>
          {title && (
            <Typography variant="h6" fontWeight="bold" color="primary">
              {title}
            </Typography>
          )}
          {hasOptions && (
            <Box sx={useStyles.headerOptions}>
              <Chip
                label="Filtrar"
                variant="outlined"
                color="primary"
                onClick={() =>
                  handleDialogOpen(
                    "Filtros",
                    <FilterBy
                      columns={columns}
                      originalRows={rows}
                      onApplyFilter={applyFilters}
                      parentAppliedFilters={appliedFilters}
                      setParentAppliedFilters={setAppliedFilters}
                      isFilterModeAPI={isFilterModeAPI}
                      onClose={handleDialogClose}
                    />
                  )
                }
                sx={{mx: 1}}
              />
              <Chip
                label="Ordenar"
                variant="outlined"
                color="primary"
                onClick={() =>
                  handleDialogOpen(
                    "Ordenamiento",
                    <OrderBy columns={columns} />
                  )
                }
                sx={{mx: 1}}
              />
              <Chip
                label="Columnas"
                variant="outlined"
                color="primary"
                onClick={() =>
                  handleDialogOpen(
                    "Columnas visibles",
                    <ShowColumns
                      columns={columns}
                      columnVisibility={columnVisibility}
                      setColumnVisibility={setColumnVisibility}
                    />
                  )
                }
                sx={{mx: 1}}
              />
            </Box>
          )}
        </Box>

        {/* TABLE */}
        <TableContainer component={Paper} sx={useStyles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                {totalRows > 0 && (
                  <>
                    {checkBoxSelection && (
                      <>
                        <TableCell
                          padding="checkbox"
                          sx={{
                            "& .MuiSvgIcon-root": {
                              color: "primary.main",
                            },
                            ...useStyles.tableHeaderCell,
                          }}
                        >
                          <Checkbox
                            indeterminate={
                              selected.length > 0 && selected.length < totalRows
                            }
                            checked={
                              totalRows > 0 && selected.length === totalRows
                            }
                            onChange={handleSelectAllClick}
                          />
                        </TableCell>
                      </>
                    )}
                    {columns.map((column) =>
                      columnVisibility[column.field] ? (
                        <TableCell
                          key={column.field}
                          sx={{
                            ...useStyles.tableHeaderCell,
                            ...column.sx,
                          }}
                        >
                          {column.headerName}
                        </TableCell>
                      ) : null
                    )}
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {totalRows === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    sx={useStyles.emptyMessage}
                  >
                    {emptyMsg}
                  </TableCell>
                </TableRow>
              ) : (
                (hasPagination
                  ? isPaginationModeAPI && Array.isArray(filteredRows)
                    ? filteredRows
                    : Array.isArray(filteredRows)
                    ? filteredRows.slice(
                        page * rowsPerPage,
                        (page + 1) * rowsPerPage
                      )
                    : []
                  : Array.isArray(filteredRows)
                  ? filteredRows
                  : []
                ).map((row, rowIndex) => (
                  <TableRow
                    onClick={handleSelectedRow(rowIndex, row)}
                    key={row[getRowId]}
                    selected={selectedRow === rowIndex}
                  >
                    {checkBoxSelection && (
                      <>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selected.indexOf(row) !== -1}
                            onChange={(event) =>
                              handleSelectCheckBox(event, row, rowIndex)
                            }
                          />
                        </TableCell>
                      </>
                    )}
                    {columns.map((column) =>
                      columnVisibility[column.field] ? (
                        <TableCell
                          key={column.field}
                          sx={{
                            ...useStyles.tableCell,
                            ...column.sx,
                          }}
                          onMouseEnter={() => setHoveredRow(rowIndex)}
                          onMouseLeave={() => setHoveredRow(null)}
                        >
                          {column.renderCell
                            ? column.renderCell({value: row[column.field], row})
                            : row[column.field]}
                        </TableCell>
                      ) : null
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* FOOTER */}
        <Box sx={useStyles.footer}>
          {hasPagination && (
            <>
              <Box sx={useStyles.rowsPageSelect}>
                <Typography variant="body2">Filas por página:</Typography>
                <Select
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  sx={{border: "1px solid #ccc", height: "35px"}}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Box>
              <Pagination
                count={getTotalPages()}
                page={isPaginationModeAPI ? page : page + 1}
                onChange={handlePageChange}
                siblingCount={3}
                showFirstButton
                showLastButton
                sx={{flexShrink: 0}}
              />
            </>
          )}
          <Typography
            variant="body2"
            fontWeight="bold"
          >{`${totalRows} filas totales`}</Typography>
        </Box>

        {/* DIALOG */}
        <Dialog open={dialogProps.open} onClose={handleDialogClose}>
          <DialogTitle sx={useStyles.dialogTitle}>
            {dialogProps.title}
          </DialogTitle>
          <DialogContent>{dialogProps.content}</DialogContent>
        </Dialog>
      </Box>
    );
  }
);

// CustomTable.propTypes = {
//   title: PropTypes.string,
//   columns: PropTypes.arrayOf(
//     PropTypes.shape({
//       field: PropTypes.string.isRequired,
//       headerName: PropTypes.string.isRequired,
//       renderCell: PropTypes.func.isRequired,
//       type: PropTypes.oneOf([
//         "autoText", // Autocomplete
//         "text", // TextField
//         "date", // DateRangeSlider
//         "option", // Checkbox
//         "none", // No filter
//       ]).isRequired,
//       path: PropTypes.string,
//       typeValue: PropTypes.oneOfType([
//         PropTypes.arrayOf(
//           PropTypes.shape({
//             label: PropTypes.string.isRequired,
//             value: PropTypes.string.isRequired,
//           })
//         ), // option: [{label:'',value:''}]
//         PropTypes.shape({
//           endpoint: PropTypes.func, // If not, search on actual render data from paths
//           keyToLabel: PropTypes.string.isRequired, // Propiedad a mostrarse en el autocomplete
//           keyToValue: PropTypes.string.isRequired, // Propiedad a almacenarse para query
//         }),
//       ]),
//       sx: PropTypes.object,
//     })
//   ).isRequired,
//   rows: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
//   getRowId: PropTypes.string,
//   emptyMsg: PropTypes.string,
//   hasOptions: PropTypes.bool,
//   hasPagination: PropTypes.bool,
//   isPaginationModeAPI: PropTypes.bool,
//   defaultLocalRowsPerPage: PropTypes.number,
//   paginationDataState: PropTypes.shape({
//     isPagination: PropTypes.bool.isRequired,
//     pageSize: PropTypes.number.isRequired,
//     pageNumber: PropTypes.number.isRequired,
//   }),
//   setPaginationDataState: PropTypes.func,
//   isFilterModeAPI: PropTypes.bool,
//   filtersDataState: PropTypes.shape({
//     selectedColumns: PropTypes.array.isRequired,
//     filterValues: PropTypes.object.isRequired,
//     query: PropTypes.array.isRequired,
//   }),
//   setFiltersDataState: PropTypes.func,
//   cellPadding: PropTypes.string,
//   containerStyle: PropTypes.object,
// };

export default CustomTable;
