import React, { useEffect, useState } from "react";
import { Form, useNavigate } from "react-router-dom";
//* MUI Components
import {
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  Alert,
  Chip,
  IconButton,
  CircularProgress,
  Backdrop,
  Tooltip,
  Snackbar,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";

//* MUI Icons
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
//* Local
import useAuth from "../../hooks/useAuth";
import {
  buildInvoiceFromService,
  createServiceEvidence,
  getAllServices,
  updateServiceEvidence,
} from "../../services/modules/services/servicesGeneral";
import { searchCustomerByName } from "../../services/modules/organization/customers";
import { getAllDestinations } from "../../services/modules/services/destination";
import CustomTable from "../../components/Tables/CustomTable";
import CardContainer from "../../components/Cards/CardContainer";
import CardTitle from "../../components/Cards/CardTitle";
import ListTable from "../../components/Tables/ListTable";
import InvoiceTable from "../../components/Tables/InvoiceTable";
import { AlertDialog } from "../../components/userFeedback/AlertDialog";
import { formatDateDDMMYYYY } from "../../utils/dateManipulators.js";
import {
  ExecutionStatusType,
  StatusEvidenceType,
  ExecutionStatusOptions,
  EmployeesPositionOptions,
} from "./utils/ServiceItemsENUM";
import { isEmptyObject } from "./utils/ServiceValidationFunctions";
import CheckBoxesList from "../../components/actions/CheckBoxesList";
import SectionTitle from "../../components/Boxes/SectionTitle";
import ServiceDataCard from "../../components/module specific/ServiceDataCard";
import ServiceInvoicesCard from "../../components/module specific/ServiceInvoicesCard";
import OperatorVehicleCard from "../../components/module specific/OperatorServiceCard";
import DesintationCompaniesCard from "../../components/module specific/DestinationsCompaniesCard";
import ExecutionStatusCard from "../../components/module specific/ExecutionStatusCard";
import { DocumentScanner, Info, ManageAccounts } from "@mui/icons-material";
import EvidencesCard from "../../components/module specific/EvidencesCard";
import EvidenceManagement from "../../components/module specific/EvidenceManagement";
import EmptyEvidenceDialogContent from "../../components/module specific/EmptyEvidenceDialog";

function PaymentCard({ data }) {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      {/* PAYMENT DATA */}
      <Grid item xs={12} md={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={12}>
            <Typography variant="body1" align="center">
              Subtotal: ${data?.SubTotal || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={12}>
            <Typography variant="body1" align="center">
              IVA: ${data?.IVA || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={12}>
            <Typography variant="body1" align="center">
              IVA RET: ${data?.IVARet || "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={12}>
            <Typography variant="body1" align="center">
              Total: ${data?.Total || "-"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* PAYMENT PROCESS STATUS */}
      <Grid item xs={12} md={5}>
        <ListTable
          title="Status proceso de pago"
          emptyMsg="No existen procesos de pago registrados"
          // TODO: Esta propiedad le faltan datos (pendiente)
          data={data?.PaymentProcessStep?.History || []}
          sx={{ height: "300px" }}
        />
      </Grid>

      {/* PAYMENT STATUS */}
      <Grid item xs={12} md={5}>
        <ListTable
          title="Status de pago"
          emptyMsg="Sin pago por el momento"
          // TODO: Esta propiedad le faltan datos (pendiente)
          data={data?.PaymentStatus?.History || []}
          sx={{ height: "300px" }}
        />
      </Grid>
    </Grid>
  );
}

function MerchandiseCard({ columns, rows }) {
  const mappedRows = [];
  rows.forEach((element) => {
    mappedRows.push({
      _id: element._id,
      BienesTransp: element?.BienesTransp || "-",
      Descripcion: element?.Descripcion || "-",
      type: element?.type || "-", // TODO: (Pendiente)
      Cantidad: element?.Cantidad || "-",
      ClaveUnidad: element?.ClaveUnidad || "-", // TODO: (Pendiente)
      data: element,
    });
  });

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        {/* MERCANCIA TABLE */}
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomTable
            columns={columns}
            rows={mappedRows}
            emptyMsg="No existe mercancia registrada por el momento"
            hasOptions
            containerStyle={{ height: "520px", width: "98%" }}
          />
        </Grid>
      </Grid>
    </>
  );
}

function TransportInvoicesCard({ invoicesIds }) {
  return (
    <Box sx={{ height: "70%", width: "95%" }}>
      <InvoiceTable
        invoicesIds={invoicesIds || []}
        columnsToShow={["id", "actions"]}
        minHeight={"150px"}
        hasTitle={false}
      />
    </Box>
  );
}

function ManiobrasCard({ invoicesIds, employeeColumns, employeeRows }) {
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        {/* INVOICES */}
        <Grid item xs={10}>
          <InvoiceTable
            invoicesIds={invoicesIds || []}
            columnsToShow={["id", "actions"]}
            minHeight={"200px"}
          />
        </Grid>
        {/* EMPLOYEES TABLE */}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomTable
            title={"Empleados"}
            columns={employeeColumns}
            rows={employeeRows}
            emptyMsg="Sin empleados registrados"
            hasOptions
            containerStyle={{ height: "320px", width: "98%" }}
          />
        </Grid>
      </Grid>
    </>
  );
}

function DevolutionsCard({ invoicesIds, devolutionColumns, devolutionRows }) {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      {/* INVOICES */}
      <Grid item xs={10}>
        <InvoiceTable
          invoicesIds={invoicesIds || []}
          columnsToShow={["id", "actions"]}
          minHeight={"200px"}
        />
      </Grid>
      {/* EMPLOYEES TABLE */}
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CustomTable
          title={"Devoluciones"}
          columns={devolutionColumns}
          rows={devolutionRows}
          emptyMsg="No existe mercancia registrada por el momento"
          hasOptions
          containerStyle={{ height: "320px", width: "98%" }}
        />
      </Grid>
    </Grid>
  );
}

function EstadiasCard({ data }) {
  const invoicesIds = data?.Invoices || [];

  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      {/* INFO AND CHART */}
      <Grid item xs={12} md={5}>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          {/* INFO */}
          <Grid item xs={6} md={12}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Grid item xs={12}>
                <Typography variant="body2" align="center">
                  Comienzo: {data?.StartTime || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" align="center">
                  Termino: {data?.FinishTime || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" align="center">
                  Tiempo: {data?.Time || "-"} minutos
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* CHART */}
          <Grid item xs={6} md={12}>
            <Typography variant="body2" align="center">
              <b>Grafica de riesgo</b>: {data?.Risk >= 0 ? data?.Risk : "-"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* INVOICES */}
      <Grid item xs={12} md={7}>
        <Box sx={{ width: "90%" }}>
          <InvoiceTable
            invoicesIds={invoicesIds || []}
            columnsToShow={["id", "actions"]}
            minHeight={"240px"}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

function ItemsCard({ invoicesData, itemsColumns, itemsRows }) {
  const invoicesIds = invoicesData?.data || [];

  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      {/* ITEMS TABLE */}
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CustomTable
          title={"Devoluciones"}
          columns={itemsColumns}
          rows={itemsRows || []}
          emptyMsg="No existen items registrados por el momento"
          hasOptions
          containerStyle={{ height: "320px", width: "98%" }}
        />
      </Grid>
      {/* INVOICES */}
      {invoicesData.isSelected && (
        <Grid item xs={10}>
          <Box sx={{ height: "60%", width: "95%" }}>
            <InvoiceTable
              invoicesIds={invoicesIds || []}
              columnsToShow={["id", "actions"]}
              minHeight={"150px"}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

const getServiceConcepts = (service) => {
  const concepts = [];
  console.log(service);

  if (service?.TransportService) {
    concepts.push(
      service.TransportService?.Code?.Name || "Servicio de transporte"
    );
  }

  if (service?.Maneuver) {
    concepts.push("Servicio de maniobra");
  }

  if (service?.Devolution) {
    concepts.push("Devolución de mercancia");
  }

  if (service?.Staying) {
    concepts.push("Servicio de estadia");
  }

  if (service?.Items?.length > 0) {
    concepts.push("Otros conceptos (items)");
  }

  return concepts;
};

const AdminServiceIndex = () => {
  const navigate = useNavigate();
  const { userid } = useAuth();

  const [serviceFilters, setServiceFilters] = useState({
    selectedColumns: [],
    filterValues: {},
    query: [],
  });
  const [servicePagination, setServicePagination] = useState({
    isPagination: true,
    pageSize: 100,
    pageNumber: 1,
  });
  const [allServices, setAllServices] = useState({
    total_pages: 0,
    total_records: 0,
    data: [],
  });
  const [selectedService, setSelectedService] = useState(
    window.localStorage.getItem("selectedService") || {}
  );
  const [selectedItemsInvoices, setSelectedItemsInvoices] = useState({
    isSelected: false,
    data: [],
  });
  const [checkedServicesForInvoice, setCheckedServicesForInvoice] = useState(
    new Array(getServiceConcepts(selectedService).length).fill(false)
  );
  const [showEvidenceManagement, setShowEvidenceManagement] = useState(false);

  const isXsScreen = useMediaQuery("(max-width:870px)");

  const [dialog, setDialog] = useState({
    open: false,
    keep: true,
    title: "",
    content: "",
    actions: [],
  });

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [loading, setLoading] = useState(false);
  const [loadingEvidence, setLoadingEvidence] = useState(false);

  const resetDialog = () => {
    setDialog({
      open: false,
      keep: true,
      title: "",
      content: "",
      actions: [],
    });
  };

  const handleCloseAlert = () => {
    setAlert({
      ...alert,
      open: false,
      message: "",
      severity: "success",
    });
  };

  const getCustomersFromAPI = (valueToFilter) => {
    return new Promise((resolve, reject) => {
      searchCustomerByName(userid, userid, valueToFilter, "TaxName")
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getCompanyFromAPI = () => {
    return new Promise((resolve, reject) => {
      getAllDestinations(userid)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleCloseDialog = () => {
    setDialog({ ...dialog, open: !dialog.open });
  };

  const serviceColumns = [
    {
      field: "Folio",
      headerName: "Folio",
      type: "text",
      path: "TransportService.Folio",
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "Customer",
      headerName: "Cliente",
      type: "autoText",
      path: "Customer.TaxName$in",
      typeValue: {
        endpoint: getCustomersFromAPI,
        keyToLabel: "TaxName",
        keyToValue: "TaxName",
      },
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "loadDate",
      headerName: "Cita carga",
      type: "date",
      path: "TransportService.Load.ScheduleTime",
      renderCell: (params) =>
        `${
          params.value !== "-" ? formatDateDDMMYYYY(params.value, true) : "-"
        }`,
      sx: { minWidth: "95px" },
    },
    {
      field: "loadRealDate",
      headerName: "Carga real",
      type: "date",
      path: "TransportService.Load.RealTime",
      renderCell: (params) =>
        `${
          params.value !== "-" ? formatDateDDMMYYYY(params.value, true) : "-"
        }`,
      sx: { minWidth: "95px" },
    },
    {
      field: "unloadDate",
      headerName: "Cita descarga",
      type: "date",
      path: "TransportService.Unload.ScheduleTime",
      renderCell: (params) =>
        `${
          params.value !== "-" ? formatDateDDMMYYYY(params.value, true) : "-"
        }`,
      sx: { minWidth: "95px" },
    },
    {
      field: "unloadRealDate",
      headerName: "Descarga real",
      type: "date",
      path: "TransportService.Unload.RealTime",
      renderCell: (params) =>
        `${
          params.value !== "-" ? formatDateDDMMYYYY(params.value, true) : "-"
        }`,
      sx: { minWidth: "95px" },
    },
    {
      field: "loadCompany",
      headerName: "Empresa carga",
      type: "autoText",
      path: "TransportService.CompanyLoad$in",
      typeValue: {
        endpoint: getCompanyFromAPI,
        keyToLabel: "TaxName",
        keyToValue: "_id",
      },
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "unloadCompany",
      headerName: "Empresa Descarga",
      type: "autoText",
      path: "TransportService.CompanyUnload$in",
      typeValue: {
        endpoint: getCompanyFromAPI,
        keyToLabel: "TaxName",
        keyToValue: "_id",
      },
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "status",
      headerName: "Estatus de ejecucion",
      type: "option",
      path: "TransportService.ExecutionStatus.isActive$in",
      typeValue: ExecutionStatusOptions,
      renderCell: (params) => {
        const getColorForStatus = (status) => {
          const { warningValues, errorValues, successValues } =
            ExecutionStatusType;

          const colorMapping = {
            ...successValues.reduce(
              (obj, value) => ({ ...obj, [value]: "success" }),
              {}
            ),
            ...warningValues.reduce(
              (obj, value) => ({ ...obj, [value]: "warning" }),
              {}
            ),
            ...errorValues.reduce(
              (obj, value) => ({ ...obj, [value]: "error" }),
              {}
            ),
          };

          return colorMapping[status] || "default";
        };

        return (
          <Chip label={params.value} color={getColorForStatus(params.value)} />
        );
      },
    },
    {
      field: "view",
      headerName: "Ver",
      type: "none",
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            console.log("SELECTED SERVICE", params.row.data);
            setSelectedItemsInvoices({
              isSelected: false,
              data: [],
            });
            setSelectedService(params.row.data);
          }}
          color="primary"
        >
          <VisibilityIcon />
        </IconButton>
      ),
    },
  ];

  const merchandiseColumns = [
    {
      field: "BienesTransp",
      headerName: "Código SAT",
      type: "text",
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "Descripcion",
      headerName: "Descripción",
      type: "text",
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "type",
      headerName: "Tipo de embalaje",
      type: "text",
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "Cantidad",
      headerName: "Cantidad",
      type: "text",
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "ClaveUnidad",
      headerName: "Unidad",
      type: "text",
      renderCell: (params) => `${params.value}`,
    },
  ];

  const employeesColumns = [
    {
      field: "Nombre",
      headerName: "Nombre",
      type: "autoText",
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "Puesto",
      headerName: "Puesto",
      type: "option",
      typeValue: EmployeesPositionOptions,
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "Rfc",
      headerName: "RFC",
      type: "text",
      renderCell: (params) => `${params.value}`,
    },
  ];

  const devolutionColumns = [
    {
      field: "code",
      headerName: "Código SAT",
      type: "text",
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "description",
      headerName: "Descripción",
      type: "text",
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "type",
      headerName: "Tipo de embalaje",
      type: "text",
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "qty",
      headerName: "Cantidad",
      type: "text",
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "unit",
      headerName: "Unidad",
      type: "text",
      renderCell: (params) => `${params.value}`,
    },
  ];

  const devolutionRows = [
    {
      _id: 1,
      code: "ABC123",
      description: "Producto A",
      type: "Caja",
      qty: 10,
      unit: "Kg",
    },
    {
      _id: 2,
      code: "DEF456",
      description: "Producto B",
      type: "Bolsa",
      qty: 5,
      unit: "Kg",
    },
    {
      _id: 3,
      code: "GHI789",
      description: "Producto C",
      type: "Botella",
      qty: 15,
      unit: "L",
    },
    {
      _id: 4,
      code: "JKL012",
      description: "Producto D",
      type: "Caja",
      qty: 8,
      unit: "Kg",
    },
    {
      _id: 5,
      code: "MNO345",
      description: "Producto E",
      type: "Bolsa",
      qty: 7,
      unit: "Kg",
    },
    {
      _id: 6,
      code: "PQR678",
      description: "Producto F",
      type: "Botella",
      qty: 20,
      unit: "L",
    },
    {
      _id: 7,
      code: "STU901",
      description: "Producto G",
      type: "Caja",
      qty: 12,
      unit: "Kg",
    },
    {
      _id: 8,
      code: "VWX234",
      description: "Producto H",
      type: "Bolsa",
      qty: 6,
      unit: "Kg",
    },
    {
      _id: 9,
      code: "YZA567",
      description: "Producto I",
      type: "Botella",
      qty: 18,
      unit: "L",
    },
    {
      _id: 10,
      code: "BCD890",
      description: "Producto J",
      type: "Caja",
      qty: 9,
      unit: "Kg",
    },
    {
      _id: 11,
      code: "EFG123",
      description: "Producto K",
      type: "Bolsa",
      qty: 4,
      unit: "Kg",
    },
    {
      _id: 12,
      code: "HIJ456",
      description: "Producto L",
      type: "Botella",
      qty: 22,
      unit: "L",
    },
    {
      _id: 13,
      code: "KLM789",
      description: "Producto M",
      type: "Caja",
      qty: 11,
      unit: "Kg",
    },
    {
      _id: 14,
      code: "NOP012",
      description: "Producto N",
      type: "Bolsa",
      qty: 3,
      unit: "Kg",
    },
    {
      _id: 15,
      code: "QRS345",
      description: "Producto O",
      type: "Botella",
      qty: 25,
      unit: "L",
    },
  ];

  const itemsColumns = [
    {
      field: "Code",
      headerName: "Código",
      type: "text",
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "SubTotal",
      headerName: "Subtotal",
      type: "text",
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "Total",
      headerName: "Total",
      type: "text",
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "Type",
      headerName: "Tipo",
      type: "text",
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "facturas",
      headerName: "Facturas",
      type: "none",
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            console.log(params.row);
            setSelectedItemsInvoices({
              isSelected: true,
              data: params.row?.Invoices || [],
            });
          }}
          color="primary"
        >
          <VisibilityIcon />
        </IconButton>
      ),
    },
  ];

  const handleSetChekedItemsForInvoices = () => {};

  const getEvidenceChipColor = (status) => {
    const statusColors = {
      [StatusEvidenceType.LIBERADA]: "success",
      [StatusEvidenceType.INCOMPLETA]: "error",
      [StatusEvidenceType.PROCESO_DE_LIBERACION]: "warning",
      [StatusEvidenceType.RECIBIDA]: "warning",
      [StatusEvidenceType.RECIBIDA_INCOMPLETA]: "error",
    };
    return statusColors[status] || "default";
  };

  const handleEdit = () => {
    window.localStorage.setItem(
      "serviceFormData",
      JSON.stringify(selectedService)
    );
    navigate(`/${userid.claims.rol}/services/edit/${selectedService._id}`);
  };

  const getSelectedServiceInvoices = (service) => {
    let finalArray = [];
    let result = null;

    if (service?.Invoices && service?.Invoices?.length > 0) {
      finalArray.push(...service?.Invoices);
    }

    if (
      service?.TransportService &&
      service?.TransportService?.Invoices &&
      service?.TransportService?.Invoices?.length > 0
    ) {
      finalArray.push(...service?.TransportService?.Invoices);
    }

    if (
      service?.Maneuver &&
      service?.Maneuver?.Invoices &&
      service?.Maneuver?.Invoices?.length > 0
    ) {
      finalArray.push(...service?.Maneuver?.Invoices);
    }

    if (
      service?.Devolution &&
      service?.Devolution?.Invoices &&
      service?.Devolution?.Invoices?.length > 0
    ) {
      finalArray.push(...service?.Devolution?.Invoices);
    }

    if (service?.Items && service?.Items?.length > 0) {
      service?.Items.forEach((item) => {
        if (item?.Invoices && item?.Invoices?.length > 0) {
          finalArray.push(...item?.Invoices);
        }
      });
    }

    finalArray = finalArray.flatMap((element) => element);
    return finalArray;
  };

  const handleAddTransportServiceInvoice = (serviceData) => () => {
    console.log(serviceData);
    setAlert({
      ...alert,
      open: true,
      message: "No disponible por el momento",
      severity: "info",
    });
    // buildInvoiceFromService(userid, userid, serviceData._id)
    //   .then((response) => {
    //     console.log(response);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const handleShowEvidenceManagement = () => {
    setAlert({
      ...alert,
      open: true,
      message: "No disponible por el momento",
      severity: "info",
    });
  };

  const createTheService = (service, evidence, status) => {
    // ACTUALIZAR EL STATUS EVIDENCIA A TRAVES DEL API
    // SI ES EXITOSO ACTUALIZAR ESTADO DEL SERVICIO ACTUAL EN DASHBOARD (PARA EVITAR SOLICITAR INFO AL API)
    // SI ES EXITOSO, MOSTRAR ALERTA TIPO SNACK DE EXITO Y CERRAR EL DIALOGO
    // SI FALLA, MOSTRAR ALERTA TIPO SNACK DE ERROR Y CERRAR EL DIALOGO
    const formData = new FormData();
    evidence.forEach((element) => {
      formData.append("File", element);
    });
    formData.append("StatusEvidence", status);

    createServiceEvidence(userid, userid, service._id, formData)
      .then((response) => {
        setAlert({
          ...alert,
          open: true,
          message: "Evidencia creada con éxito",
          severity: "success",
        });
        resetDialog();
      })
      .catch((err) => {
        setAlert({
          ...alert,
          open: true,
          message: "Error al crear la evidencia",
          severity: "error",
        });
      });
  };

  const handleCreateEvidence = (callback) => {
    return (service, evidence, status) => {
      callback(service, evidence, status);
    };
  };

  const handleUpdateEvidenceStatus = (serviceData) => () => {
    //TODO: Verificar si la organizaciòn a contratado el modulo de servicios para gestionar de forma avanzada las evidencias, caso contrario, solo puede actualizar el status de las evidencias a "Liberada" y "En proceso de liberaciòn"
    if (!serviceData?.TransportService?.Evidence) {
      setDialog({
        open: true,
        keep: true,
        title: "Error",
        content: (
          <>
            La gestión de evidencias no ha iniciado para este servicio. Para
            iniciarla, deberás cargar los archivos.
            <EmptyEvidenceDialogContent
              service={serviceData}
              handleCreateEvidence={handleCreateEvidence(createTheService)}
            />
          </>
        ),
        actions: [
          {
            label: "Cancelar",
            execute: () => resetDialog(),
          },
        ],
      });
    }

    if (serviceData?.TransportService?.Evidence) {
    }
  };

  const handleApplyEvidenceStatus = (option) => {
    if (
      option !==
      selectedService?.TransportService?.Evidence?.Status?.StatusEvidence
    ) {
      setLoadingEvidence(true);
      const newEvidence = {
        ...selectedService?.TransportService?.Evidence,
        Status: {
          ...selectedService?.TransportService?.Evidence?.Status,
          StatusEvidence: option,
        },
      };
      updateServiceEvidence(userid, userid, selectedService._id, newEvidence)
        .then((response) => {
          setLoadingEvidence(false);
          setSelectedService({
            ...selectedService,
            TransportService: {
              ...selectedService?.TransportService,
              Evidence: {
                ...selectedService?.TransportService?.Evidence,
                StatusEvidence: option,
              },
            },
          });
        })
        .catch((err) => {
          setLoadingEvidence(false);
          console.log(err);
        });
      return;
    }

    setAlert({
      ...alert,
      open: true,
      message: "Selecciona un status diferente",
      severity: "error",
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const showDialogData = (data) => {
        setDialog((dialog) => {
          return {
            ...dialog,
            open: true,
            title: data.title,
            content: data.content,
            actions: data.actions,
          };
        });
      };
      try {
        const res = await getAllServices(
          userid,
          userid,
          servicePagination,
          serviceFilters.query
        );
        const servicesData = res.data.data;

        const allServicesArray = servicesData.map((element) => ({
          _id: element._id,
          Folio: element.Folio || "-",
          Customer: element.Customer?.TaxName || "-",
          loadDate: element.TransportService?.DateTimeLoad || "-",
          loadRealDate: element.TransportService?.DateTimeLoad || "-",
          unloadDate: element.TransportService?.DateTimeUnload || "-",
          unloadRealDate: element.TransportService?.DateTimeUnload || "-",
          loadCompany: element.TransportService?.CompanyLoad?.TaxName || "-",
          unloadCompany:
            element.TransportService?.CompanyUnload?.TaxName || "-",
          status: element.TransportService?.ExecutionStatus?.isActive || "-",
          data: element,
        }));

        setAllServices({
          ...allServices,
          total_records: res.data.total_records,
          total_pages: res.data.total_pages,
          data: allServicesArray,
        });

        setLoading(false);
      } catch (err) {
        setLoading(false);

        if (err.response) {
          switch (err.response.status) {
            case 401:
              showDialogData({
                title: "ERROR Obteniendo servicios",
                content:
                  "No tienes autorización para consultar esta información, verifica que tienes conexión y tu sesión sigue activa.",
                actions: [
                  {
                    label: "Recargar sesión",
                    execute: () => window.location.reload(),
                  },
                  {
                    label: "Terminar",
                    execute: () => navigate(`/`),
                  },
                ],
              });
              break;
            case 400:
              showDialogData({
                title: "ERROR Obteniendo servicios",
                content:
                  "El servidor no ha comprendido lo que necesitas, por favor intenta de nuevo, o contacta a soporte.",
                actions: [
                  {
                    label: "Recargar sesión",
                    execute: () => window.location.reload(),
                  },
                  {
                    label: "Intentar más tarde",
                    execute: () => navigate(`/${userid.claims.rol}/welcome`),
                  },
                ],
              });
              break;
            case 500:
              showDialogData({
                title: "ERROR de servidor",
                content:
                  "Hubo un error al obtener los datos de los servicios. Intentalo de nuevo más tarde, o recarga la página. Si el error persiste contacta a soporte.",
                actions: [
                  {
                    label: "Recargar sesión",
                    execute: () => window.location.reload(),
                  },
                  {
                    label: "Intentar más tarde",
                    execute: () => navigate(`/${userid.claims.rol}/welcome`),
                  },
                ],
              });
              break;
            default:
              showDialogData({
                title: "ERROR Obteniendo servicios",
                content:
                  "Hubo un error desconocido. Intentalo de nuevo más tarde, o recarga la página. Si el error persiste contacta a soporte.",
                actions: [
                  {
                    label: "Recargar sesión",
                    execute: () => window.location.reload(),
                  },
                  {
                    label: "Intentar más tarde",
                    execute: () => navigate(`/${userid.claims.rol}/welcome`),
                  },
                ],
              });
              break;
          }
        } else {
          showDialogData({
            title: "ERROR Obteniendo servicios",
            content:
              "Hubo un error desconocido. Intentalo de nuevo más tarde, o recarga la página. Si el error persiste contacta a soporte.",
            actions: [
              {
                label: "Recargar",
                execute: () => window.location.reload(),
              },
              {
                label: "Continuar",
                execute: () => navigate(`/`),
              },
            ],
          });
        }
      }
    };

    setLoading(true);
    fetchData();
  }, [servicePagination, serviceFilters.query]);

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        pt: 3,
        mb: 8,
      }}
    >
      {/* Header */}
      <Grid
        container
        spacing={2}
        alignItems="flex-end"
        sx={{ mb: 3, width: "98%", pl: "19%" }}
      >
        <Grid item xs={12} sm={9}>
          <Typography variant="h4" color="primary" align="center">
            Resumen de servicios
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} container justifyContent="flex-end">
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            sx={{ boxShadow: 8 }}
            onClick={() => {
              navigate(`/${userid.claims.rol}/services/new`);
            }}
          >
            Nuevo servicio
          </Button>
        </Grid>
      </Grid>

      {/* CONTENT */}
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "98%",
        }}
      >
        {/* SERVICE TABLE */}
        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomTable
              columns={serviceColumns}
              rows={allServices}
              isPaginationModeAPI
              paginationDataState={servicePagination}
              setPaginationDataState={setServicePagination}
              isFilterModeAPI
              filtersDataState={serviceFilters}
              setFiltersDataState={setServiceFilters}
              hasOptions
              containerStyle={{ height: "620px", width: "98%" }}
            />
          )}
        </Grid>

        {/* SERVICE INFO */}
        {!isEmptyObject(selectedService) && (
          <>
            {showEvidenceManagement && (
              <EvidenceManagement
                serviceId={selectedService._id}
                serviceData={selectedService}
              />
            )}
            <Grid
              item
              xs={12}
              sx={{
                width: "90%",
                minHeight: "300px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                p: 2,
              }}
            >
              {/* TITLE */}
              <Box
                sx={{
                  p: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Typography variant="h5" color="primary">
                  Servicio
                </Typography>
                <Typography variant="body1" sx={{ ml: 1 }}>
                  Id: {selectedService?._id || "-"}
                </Typography>
                <Typography variant="body1" sx={{ ml: 1 }}>
                  Status:
                  {selectedService?.Status.isActive
                    ? "Activo"
                    : "Inactivo" || "-"}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ ml: "auto" }}
                  onClick={handleEdit}
                >
                  Editar
                </Button>
              </Box>
              <Divider sx={{ bgcolor: "#01579b", height: 2, mb: 2 }} />

              {/* CONTENT */}
              <Grid
                container
                spacing={2}
                sx={{
                  minWidth: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                {/* SERVICE DATA */}
                <Grid item xs={12} md={4}>
                  <CardContainer>
                    <CardTitle title="Datos generales" />
                    <ServiceDataCard data={selectedService} />
                  </CardContainer>
                </Grid>
                {/* INVOICES */}
                <Grid item xs={12} md={4}>
                  <CardContainer sx={{ height: "230px" }}>
                    <CardTitle
                      title="Facturas"
                      action={{
                        icon: <AddIcon />,
                        fn: () => {
                          setDialog({
                            ...dialog,
                            open: true,
                            keep: false,
                            title:
                              "Selecciona que conceptos quieres tomar en cuenta:",
                            content: (
                              <CheckBoxesList
                                items={getServiceConcepts(selectedService)}
                                checkedItems={checkedServicesForInvoice}
                                setChekedItems={handleSetChekedItemsForInvoices}
                              />
                            ),
                          });
                        },
                      }}
                    />
                    {/* TODO: De donde se obtiene esta propiedad? */}
                    <ServiceInvoicesCard
                      invoicesIds={
                        getSelectedServiceInvoices(selectedService) || []
                      }
                    />
                  </CardContainer>
                </Grid>

                {/* ALERT INFO */}
                <Grid item xs={12} md={4}>
                  <Alert
                    severity="info"
                    variant="outlined"
                    sx={{ ml: 4, width: "80%" }}
                  >
                    <Typography variant="body1">
                      Genera facturas configurando que conceptos quieres tomar
                      en cuenta a partir de este servicio.
                    </Typography>
                  </Alert>
                </Grid>
              </Grid>
            </Grid>
            {/* TRANSPORT SERVICE INFO */}
            <Grid
              item
              xs={12}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                p: 2,
              }}
            >
              {/* TITLE */}
              <Box
                sx={{
                  p: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Typography variant="h5" color="primary">
                  Transporte
                </Typography>
                {selectedService?.TransportService ? (
                  <>
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      Folio: {selectedService?.Folio || "-"}
                    </Typography>
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      Id Externo:{" "}
                      {selectedService?.TransportService?.IdExterno || "-"}
                    </Typography>
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      Código/Nombre del servicio:{" "}
                      {selectedService?.TransportService?.Code?.Value || "-"}
                      {" - "}
                      {selectedService?.TransportService?.Code?.Name || "-"}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="body1" color="primary" sx={{ ml: 1 }}>
                    Servicio de transporte no configurado
                  </Typography>
                )}
              </Box>
              <Divider sx={{ bgcolor: "#01579b", height: 2, mb: 4 }} />

              {/* CONTENT */}
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                {selectedService?.TransportService && (
                  <>
                    {/* SECTION 1 */}
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            {/* OPERATOR - VEHICLE */}
                            <Grid item xs={12} md={4}>
                              <CardContainer>
                                <CardTitle
                                  title="Operadores y vehiculos"
                                  action={{
                                    name: "Editar",
                                    fn: handleEdit,
                                  }}
                                />

                                {selectedService?.TransportService?.Operadores
                                  ?.length > 0 ? (
                                  selectedService.TransportService.Operadores.map(
                                    (operator, index) => (
                                      <React.Fragment key={index}>
                                        {index > 0 && (
                                          <Divider
                                            sx={{
                                              my: 2,
                                              backgroundColor: "blue",
                                            }}
                                          />
                                        )}
                                        <OperatorVehicleCard
                                          operator={operator}
                                          vehicle={
                                            selectedService.TransportService
                                              .Vehicles[index]
                                          }
                                        />
                                      </React.Fragment>
                                    )
                                  )
                                ) : (
                                  <Typography>
                                    "Sin operadores configurados"
                                  </Typography>
                                )}
                              </CardContainer>
                            </Grid>
                            {/* EXECUTION STATUS */}
                            <Grid item sm={12} md={4}>
                              <CardContainer>
                                <CardTitle title="Estatus de ejecución" />
                                {/* TODO: Checar propiedades de History */}
                                <ExecutionStatusCard
                                  data={selectedService?.Status?.History || []}
                                />
                              </CardContainer>
                            </Grid>
                            {/* EVIDENCES */}
                            <Grid item xs={12} md={4}>
                              <CardContainer>
                                <CardTitle
                                  title="Evidencias"
                                  action={{
                                    icon: (
                                      <>
                                        <Tooltip title="Gestionar evidencias">
                                          <DocumentScanner />
                                        </Tooltip>
                                      </>
                                    ),
                                    fn: handleShowEvidenceManagement,
                                  }}
                                  complementChip={{
                                    label:
                                      selectedService?.TransportService
                                        ?.Evidence?.StatusEvidence ||
                                      "Iniciar proceso",
                                    color: getEvidenceChipColor(
                                      selectedService?.TransportService
                                        ?.Evidence?.StatusEvidence || "-"
                                    ),
                                    clickable:
                                      !selectedService?.TransportService
                                        ?.Evidence,
                                    chipOnClick:
                                      handleUpdateEvidenceStatus(
                                        selectedService
                                      ),
                                    //*ANCHOR MENU PROPERTIES
                                    anchorMenu:
                                      selectedService?.TransportService
                                        ?.Evidence,
                                    options: Object.values(StatusEvidenceType),
                                    menuSelectionHandler:
                                      handleApplyEvidenceStatus,
                                    externalDisabled: loadingEvidence,
                                  }}
                                />
                                <EvidencesCard
                                  data={selectedService?.TransportService}
                                  fullService={selectedService}
                                  externalLoading={loadingEvidence}
                                />
                              </CardContainer>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* SECTION 2 */}
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        {/* CARGA y descarga*/}
                        <Grid item xs={12} md={12}>
                          <CardContainer>
                            <CardTitle
                              title="Ubicaciones de carga y descarga"
                              action={{
                                name: "Editar",
                                fn: () => {
                                  console.log("EDITAR");
                                },
                              }}
                            />
                            <DesintationCompaniesCard
                              loadCompany={
                                selectedService?.TransportService?.CompanyLoad
                              }
                              unloadCompany={
                                selectedService?.TransportService?.CompanyUnload
                              }
                              times={{
                                load: selectedService?.TransportService?.Load,
                                unload:
                                  selectedService?.TransportService?.Unload,
                              }}
                            />
                          </CardContainer>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* SECTION 3 */}
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            {/* PAYMENT */}
                            <Grid item xs={12} md={8}>
                              <CardContainer>
                                <CardTitle
                                  title="Datos de pago"
                                  action={{
                                    name: "Editar",
                                    fn: () => {
                                      console.log("EDITAR");
                                    },
                                  }}
                                />
                                <PaymentCard
                                  data={selectedService?.TransportService}
                                />
                              </CardContainer>
                            </Grid>
                            {/* TRANSPORT SERVICES INVOICES */}
                            <Grid item xs={12} md={4}>
                              <CardContainer>
                                <CardTitle
                                  title="Facturas (CCP)"
                                  action={{
                                    icon: (
                                      <>
                                        <Tooltip title="Unicamente facturas que en conceptos tienen servicios de transporte de carga">
                                          <AddIcon />
                                        </Tooltip>
                                      </>
                                    ),
                                    fn: handleAddTransportServiceInvoice(
                                      selectedService
                                    ),
                                  }}
                                  complementChip={{
                                    label: (
                                      <>
                                        <Tooltip title="Unicamente facturas que en conceptos tienen servicios de transporte de carga">
                                          <Info />
                                        </Tooltip>
                                      </>
                                    ),
                                    color: "info",
                                  }}
                                />
                                <TransportInvoicesCard
                                  invoicesIds={
                                    selectedService?.TransportService
                                      ?.Invoices || []
                                  }
                                />
                              </CardContainer>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* SECTION 4 */}
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        {/* LEFT/UP SIDE */}
                        <Grid item xs={12} md={12}>
                          {/* MERCHANDISE */}
                          <CardContainer>
                            <CardTitle
                              title="Mercancia"
                              action={{
                                name: "Editar",
                                fn: () => {
                                  console.log("EDITAR");
                                },
                              }}
                            />
                            <MerchandiseCard
                              columns={merchandiseColumns}
                              rows={
                                selectedService?.TransportService
                                  ?.Merchandise || []
                              }
                            />
                          </CardContainer>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}

                {!selectedService?.TransportService && (
                  <Grid item xs={12} md={4} sx={{ mb: 2 }}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Grid item xs={12} md={8}>
                        <CardContainer>
                          <Button onClick={handleEdit}>
                            Configurar servicio de transporte
                          </Button>
                        </CardContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                p: 2,
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-evenly",
                }}
              >
                {/* LEFT/UP SIDE */}
                <Grid item sm={12} md={6}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {/* MANIOBRAS */}
                    <Grid item xs={12}>
                      <SectionTitle
                        title={"Maniobras"}
                        type={selectedService?.Maneuver?.TypeManeuver}
                        code={selectedService?.Maneuver?.Code}
                        SubTotal={selectedService?.Maneuver?.SubTotal}
                        Total={selectedService?.Maneuver?.Total}
                        hasItems={selectedService.Maneuver}
                      />
                      <CardContainer sx={{ height: "750px" }}>
                        <ManiobrasCard
                          invoicesIds={
                            selectedService?.Maneuver?.Invoices || []
                          }
                          employeeColumns={employeesColumns}
                          employeeRows={
                            selectedService?.Maneuver?.Employees || []
                          }
                        />
                      </CardContainer>
                    </Grid>
                    {/* DEVOLUCIONES */}
                    <Grid item xs={12}>
                      <SectionTitle
                        title={"Devoluciones"}
                        type={selectedService?.Devolution?.TypeDevolution}
                        code={selectedService?.Devolution?.Code}
                        SubTotal={selectedService?.Devolution?.SubTotal}
                        Total={selectedService?.Devolution?.Total}
                        hasItems={selectedService.Devolution}
                      />
                      <CardContainer sx={{ height: "750px" }}>
                        <DevolutionsCard
                          invoicesIds={
                            selectedService?.Devolution?.Invoices || []
                          }
                          devolutionColumns={devolutionColumns}
                          devolutionRows={devolutionRows}
                        />
                      </CardContainer>
                    </Grid>
                  </Grid>
                </Grid>

                {/* RIGHT/DOWN SIDE */}
                <Grid item sm={12} md={6}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {/* ESTADIAS */}
                    <Grid item xs={12}>
                      <SectionTitle
                        title={"Estadias"}
                        type={selectedService?.Staying?.TypeStaying}
                        code={selectedService?.Staying?.Code}
                        SubTotal={selectedService?.Staying?.SubTotal}
                        Total={selectedService?.Staying?.Total}
                        hasItems={selectedService.Staying}
                      />
                      <CardContainer
                        sx={{ height: isXsScreen ? "500px" : "350px" }}
                      >
                        <EstadiasCard data={selectedService?.Staying} />
                      </CardContainer>
                    </Grid>
                    {/* ITEMS */}
                    <Grid item xs={12}>
                      <SectionTitle title={"Items"} />
                      <CardContainer sx={{ height: "650px" }}>
                        <ItemsCard
                          invoicesData={selectedItemsInvoices}
                          itemsColumns={itemsColumns}
                          itemsRows={selectedService?.Items || []}
                        />
                      </CardContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      {/* LOADER */}
      <Backdrop open={loading} sx={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>

      {/* DIALOG */}
      <AlertDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        actions={dialog.actions}
        updateOpenState={handleCloseDialog}
        keep={dialog.keep}
      />

      {/* ALERT */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminServiceIndex;
