const hasObjectValue = (obj) => {
  if (obj) {
    return Object.keys(obj).length > 0;
  }
};

const isEmpty = (obj) => {
  for (let key in obj) {
    if (typeof obj[key] === "object") {
      if (!isEmpty(obj[key])) {
        return false;
      }
    } else if (obj[key] !== "") {
      return false;
    }
  }
  return true;
};


export { hasObjectValue, isEmpty };