import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Box } from "@mui/system";
import {
  Button,
  Backdrop,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Grid,
  Paper,
} from "@mui/material";
import useAppContext from "../../../hooks/useAppContext";
import useAuth from "../../../hooks/useAuth";
import {
  DragAndDrop,
  DisplayFilePreview,
} from "../../../components/DragAndDrop/DragAndDrop";
import { createEmployeesFromFile } from "../../../services/modules/employees/employees";

export const UploadEmployees = () => {
  const [file, setFile] = useState();
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    status: "",
  });
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });
  const [loading, setLoading] = useState(false);

  const { employeesVerificationResponse } = useAppContext();
  const { userid } = useAuth();
  const navigate = useNavigate();

  const handleSend = () => {
    setLoading(true);

    const csv = new FormData();

    csv.append("file", file);
    createEmployeesFromFile(userid, userid, csv)
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        employeesVerificationResponse.push(res.data.data);

        setDialog({
          ...dialog,
          open: true,
          title: "Los empleados han sido procesados con éxito",
          content: `Ahora podrá visualizar y editarlos desde el panel de manejo empleados`,
          actions: [
            {
              label: "Cerrar",
              execute: () => {
                window.location.reload();
              },
            },
            {
              label: "Ir al manejo de empleados",
              execute: () => {
                //navigate('verifyEmployees')
                console.log("navegando...ruta aún no implementada");
              },
            },
          ],
        });
      })
      .catch((err) => {
        const { response } = err;
        console.log(response);
        setLoading(false);
        setDialog({
          ...dialog,
          open: true,
          title: `Error registrando a los empleados (${response.status})`,
          content: `${response.statusText}, ¿deseas intentar de nuevo?`,
          actions: [
            {
              label: "Reintentar",
              execute: () => {
                window.location.reload();
              },
            },
          ],
        });
      });
  };

  const handleCloseDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  const handleClick = (e) => {
    const type = e.target.id;
    switch (type) {
      case "manual":
        console.log(type);
        break;
      case "csv":
        if (e.target.files.length === 1) {
          setFile(e.target.files[0]);
        } else {
          setAlert({
            ...alert,
            open: true,
            message: "Estás subiendo demasiados archivos.",
            status: "warning",
          });
        }
        break;
      default:
        break;
    }
  };
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={dialog.open} onClose={handleCloseDialog}>
        <DialogTitle>{dialog.title}</DialogTitle>

        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>

        <DialogActions>
          {dialog.actions.map((action, index) => {
            return (
              <Button key={index} onClick={action.execute}>
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>

      {/* Lo nuevo */}
      <Box
        display="flex"
        flexDirection={"column"}
        justifyContent="center"
        alignItems="center"
        paddingY={"5%"}
      >
        <Typography variant="h5" color={"primary.dark"}>
          Creación de Empleados
        </Typography>
        <Paper sx={{ maxWidth: "sm", padding: "20px" }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={0} sm={3}></Grid>
            <Grid item xs={12} sm={6}>
              <DragAndDrop
                fileExtension=".csv"
                setFile={setFile}
                componentId="csv"
              />
            </Grid>
            <Grid item xs={0} sm={3}></Grid>
            {file && (
              <>
                <Grid item xs={0} sm={3}></Grid>
                <Grid item xs={12} sm={6} textAlign={"center"}>
                  <DisplayFilePreview file={file} />
                </Grid>
                <Grid item xs={0} sm={3}></Grid>
              </>
            )}

            <Grid item xs={12} textAlign={"center"}>
              <Typography variant="body1">Ó</Typography>
            </Grid>

            <Grid item xs={12} textAlign={"center"}>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  file
                    ? handleSend()
                    : navigate(`/${userid.claims.rol}/empleados/crear/nuevo`);
                }}
              >
                {file ? "Procesar" : "Registrar manualmente"}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </div>
  );
};
