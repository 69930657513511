import React, {useState, useRef} from "react";
import {Button, Typography, Container, Grid, Paper} from "@mui/material";

import {
  createVehicle,
  uploadPolicyVehicle,
} from "../../../services/modules/transports/vehicles";
import useAuth from "../../../hooks/useAuth";
import {useNavigate} from "react-router";
import {AlertDialog} from "../../../components/userFeedback/AlertDialog";

import TruckInfoComponent from "../../../components/Forms/Transports/TruckInfoComponent";
import SCTPermissionComponent from "../../../components/Forms/Transports/SCTPermissionComponent";
import PolicyComponent from "../../../components/Forms/Transports/PolicyComponent";
import FuelSpecsComponent from "../../../components/Forms/Transports/FuelSpecsComponent";

import {createVehicleDto} from "./dto/create-vehicle.dto";
import {createVehicleValidation} from "./validations/create-vehicle.validation";

const CreateTransport = () => {
  const {userid} = useAuth();
  const navigate = useNavigate();

  const formRef = useRef(null);
  const policyComponentRef = useRef();

  const [transport, setTransport] = useState({
    ...createVehicleDto,
  });
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  function handlerAccessPropertyInTransport(path, newValue) {
    const splits = path.split(".");
    const finalProperty = splits.pop();

    const finalObject = splits.reduce((acc, clave) => {
      acc[clave] = acc[clave] || {};
      return acc[clave];
    }, transport);

    finalObject[finalProperty] = newValue;
  }

  const handleChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    handlerAccessPropertyInTransport(id, value);
    setTransport({
      ...transport,
    });
    console.log(id, transport);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const file = policyComponentRef.current.getFile();
    if (file == null) {
      alert("no se selecciono un archivo");
      return;
    }

    transport.Price = parseFloat(transport.Price);
    transport.Specs.ModelYear = parseInt(transport.Specs.ModelYear);
    transport.SCTPermission.Number = parseInt(transport.SCTPermission.Number);
    transport.Policy.NoPoliza = transport.Policy.NoPoliza;

    if (!transport.Policy.LastRenewal) {
      delete transport.Policy.LastRenewal;
    }
    if (!transport.Policy.PrimaCost) {
      delete transport.Policy.PrimaCost;
    }
    if (!transport.Policy.TotalCost) {
      delete transport.Policy.TotalCost;
    }

    if (transport.Specs.FuelSpecs.NumberTanks) {
      transport.Specs.FuelSpecs.NumberTanks = parseInt(
        transport.Specs.FuelSpecs.NumberTanks
      );
    } else {
      transport.Specs.FuelSpecs.NumberTanks = 0;
    }
    if (transport.Specs.FuelSpecs.CapacityInLiters) {
      transport.Specs.FuelSpecs.CapacityInLiters = parseInt(
        transport.Specs.FuelSpecs.CapacityInLiters
      );
    } else {
      transport.Specs.FuelSpecs.CapacityInLiters = 0;
    }
    if (transport.Specs.FuelSpecs.KmLtrSpec) {
      transport.Specs.FuelSpecs.KmLtrSpec = parseFloat(
        transport.Specs.FuelSpecs.KmLtrSpec
      );
    } else {
      transport.Specs.FuelSpecs.KmLtrSpec = 0;
    }
    if (transport.Specs.FuelSpecs.KmLtrReal) {
      transport.Specs.FuelSpecs.KmLtrReal = parseFloat(
        transport.Specs.FuelSpecs.KmLtrReal
      );
    } else {
      transport.Specs.FuelSpecs.KmLtrReal = 0;
    }

    createVehicle(userid, userid, transport)
      .then((res) => {
        console.log("respuesta remolques ", res);

        const formData = new FormData();
        formData.append("file", file);

        uploadPolicyVehicle(userid, userid, res.data._id, formData)
          .then((resUploadPolicy) => {
            console.log(resUploadPolicy);
            navigate(`/${userid.claims.rol}/transports`);
          })
          .catch((err) => {
            console.error(err);
            navigate(`/${userid.claims.rol}/transports`);
          });
      })
      .catch((err) => {
        alert("ocurrio un error al guardar el vehiculo");
        console.log(err.response);
      });
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AlertDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        actions={dialog.actions}
      />
      <form ref={formRef}>
        <Container component="main" maxWidth="sm" sx={{mb: 4}}>
          <Paper
            variant="outlined"
            sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}
          >
            <Typography variant="h4" align="center" color="primary.dark">
              Registra un Vehículo
            </Typography>

            <TruckInfoComponent
              transport={transport}
              handleChange={handleChange}
              setTransport={setTransport}
            />

            <br />

            <FuelSpecsComponent
              transport={transport}
              handleChange={handleChange}
            />

            <br />

            <SCTPermissionComponent
              transport={transport}
              handleChange={handleChange}
            />

            <br />

            <PolicyComponent
              ref={policyComponentRef}
              transport={transport}
              handleChange={handleChange}
            />

            <Grid container spacing={2} sx={{mt: "3vh"}}>
              <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  {"Registrar vehículo"}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </form>
    </div>
  );
};

export default CreateTransport;
