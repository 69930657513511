import React from "react";
import { Grid, Box, Typography } from "@mui/material";

const ServiceDataCard = ({ data }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1" align="center">
              Nombre del cliente:
            </Typography>
            <Typography variant="body1" align="center">
              {data?.Customer?.TaxName || "-"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1" align="center">
              Rfc del cliente:
            </Typography>
            <Typography variant="body1" align="center">
              <b>{data?.Customer?.Rfc || "-"}</b>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={6} md={6}>
          <Typography variant="body1" align="center">
            Subtotal: $
            {data?.SubTotal.toFixed(2) !== null
              ? data?.SubTotal.toFixed(2)
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography variant="body1" align="center">
            IVA: ${data?.IVA.toFixed(2) !== null ? data?.IVA.toFixed(2) : "-"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography variant="body1" align="center">
            IVA RET: ${data?.IVARet.toFixed(2) ? data?.IVARet.toFixed(2) : "-"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography variant="body1" align="center">
            Total: ${data?.Total.toFixed(2) ? data?.Total.toFixed(2) : "-"}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ServiceDataCard;
