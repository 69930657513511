const NominaPaymentMethod = {
  DEFAULT_PAYMENT_METHOD: {
    Name: "Pago en una sola exhibición",
    Value: "PUE",
  },
  PARCIALIDADES_PAYMENT_METHOD: {
    Name: "Pago en parcialidades o diferido",
    Value: "PPD",
  },
};

export default NominaPaymentMethod;
