import React, {useEffect, useRef} from "react";

const MapComponent = ({location, directions}) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const google = window.google;
    //* DEFAULT CENTER IN MEXICO CITY
    const map = new google.maps.Map(mapRef.current, {
      center: {lat: 19.4326, lng: -99.1332},
      zoom: 14,
    });

    if (!location && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          map.setCenter(pos);
        },
        () => {
          console.log("Error: The Geolocation service failed.");
        }
      );
    }

    if (location) {
      map.setCenter({lat: location.lat, lng: location.lng});
      new google.maps.Marker({
        map: map,
        position: {lat: location.lat, lng: location.lng},
      });
    }

    // Add any additional map features or markers here
    if (directions) {
      const directionsRenderer = new google.maps.DirectionsRenderer();
      directionsRenderer.setMap(map);
      const directionsService = new google.maps.DirectionsService();
      directionsService.route(
        {
          origin: directions.origin,
          destination: directions.destinations[0],
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          console.log(result);
          if (status === "OK") {
            directionsRenderer.setDirections(result);
          }
        }
      );
    }

    return () => {
      // Clean up any map-related resources here
    };
  }, [location, directions]);

  return <div ref={mapRef} style={{height: "100%", width: "100%"}} />;
};

export default MapComponent;
