import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  MenuItem,
  Typography,
  Alert,
} from "@mui/material";

const HorasExtraForm = ({
  MotherPerception,
  setMotherPerception,
  submitMotherPerception,
}) => {
  const [extraHours, setExtraHours] = useState([
    { hoursType: "01", days: 0, hours: 0, paidAmount: 0 },
    { hoursType: "02", days: 0, hours: 0, paidAmount: 0 },
    { hoursType: "03", days: 0, hours: 0, paidAmount: 0 },
  ]);

  const hoursNames = {
    "01": "Dobles",
    "02": "Triples",
    "03": "Simples",
  };

  const [alertMessage, setAlertMessage] = useState({
    open: false,
    message: "",
  });

  const handleChangeHours = (value, index, field) => {
    const updatedExtraHours = [...extraHours];
    updatedExtraHours[index][field] = value;
    setExtraHours(updatedExtraHours);
  };

  function filterExtraHoursArray(array) {
    return array.filter((obj) => obj.days !== 0 || obj.hours !== 0);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (setMotherPerception) {
      let filteredExtraHours = filterExtraHoursArray(extraHours);
      if (filteredExtraHours.length > 0) {
        console.log("mother perception", MotherPerception);
        MotherPerception.ExtraHours = filterExtraHoursArray(extraHours);
        setMotherPerception({ ...MotherPerception });

        if (submitMotherPerception) {
          submitMotherPerception();
        }
      } else {
        setAlertMessage({
          open: true,
          message: "se necesita al menos un día y una hora extra",
        });
      }
    }

    console.log(extraHours);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>Tipo de Horas</Typography>
        </Grid>
        {extraHours.map((hours, index) => (
          <Grid container item spacing={2} key={index}>
            <Grid item xs={3}>
              <Typography>{hoursNames[hours.hoursType]}</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Días"
                type="number"
                fullWidth
                value={hours.days}
                onChange={(event) =>
                  handleChangeHours(event.target.value, index, "days")
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Horas"
                type="number"
                fullWidth
                value={hours.hours}
                onChange={(event) =>
                  handleChangeHours(event.target.value, index, "hours")
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Importe pagado"
                type="number"
                fullWidth
                value={hours.paidAmount}
                onChange={(event) =>
                  handleChangeHours(event.target.value, index, "paidAmount")
                }
              />
            </Grid>
          </Grid>
        ))}
        {alertMessage.open ? (
          <Alert severity="warning">{`${alertMessage.message}`}</Alert>
        ) : null}
        <Grid item xs={7}></Grid>

        <Grid item xs={5}>
          <Button type="submit" variant="contained" color="primary">
            Agregar Percepción
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default HorasExtraForm;
