export const expenseItemDto = {
  Type: "product",
  Description: "",
  Quantity: 1,
  Price: 0,
  Subtotal: "",
  IVA: "0",
  Total: "0",
  Taxes: [],
};
