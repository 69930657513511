import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Chip,
  Container,
  Grid,
  TextField,
  Typography,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DateRangeSlider } from '../../../modules/invoices/dashboard/components/DateRangeSlider';
import { AlertDialog } from '../../userFeedback/AlertDialog';

const FilterBy = ({
  columns,
  isFilterModeAPI,
  originalRows,
  parentAppliedFilters,
  setParentAppliedFilters,
  onApplyFilter,
  onClose,
}) => {
  const [selectedColumns, setSelectedColumns] = useState(
    parentAppliedFilters.selectedColumns || []
  );
  const [filterValues, setFilterValues] = useState(
    parentAppliedFilters.filterValues || {}
  );

  const today = new Date();
  const weekAgo = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7
  );
  const dateColumns = columns.filter((column) => column.type === 'date');

  const initialDateRanges = {};
  dateColumns.forEach(
    (column) =>
      (initialDateRanges[column.field] = [
        filterValues[column.field]?.[0] || weekAgo,
        filterValues[column.field]?.[1] || today,
      ])
  );
  const [range, setRange] = useState(initialDateRanges);

  const [dialog, setDialog] = useState({
    open: false,
    title: '',
    content: '',
    actions: [],
  });
  const [apiOptions, setApiOptions] = useState({});

  const getKeyValueFromPaths = (rows, keyToLabel, keyToValue) => {
    const getNestedValue = (obj, path) =>
      path.split('.').reduce((acc, curr) => (acc ? acc[curr] : ''), obj);

    const seenValues = new Set();
    const seenLabels = new Set();

    return rows.reduce((result, row) => {
      const value = getNestedValue(row, keyToValue) || '';
      const label = getNestedValue(row, keyToLabel) || '';

      if (value !== '' && !seenValues.has(value) && !seenLabels.has(label)) {
        seenValues.add(value);
        seenLabels.add(label);
        result.push({
          label: label,
          value: value,
        });
      }

      return result;
    }, []);
  };

  useEffect(() => {
    if (
      isFilterModeAPI &&
      selectedColumns.some((column) => column.type === 'autoText') &&
      selectedColumns.some((column) => column?.typeValue?.endpoint)
    ) {
      const promises = selectedColumns
        .filter((column) => column.type === 'autoText')
        .map((column) => {
          return column.typeValue
            .endpoint('')
            .then((result) => ({
              [column.field]: result,
            }))
            .catch((err) => {
              console.error(`Error for column "${column.field}":`, err);
              setDialog({
                open: true,
                title: `Error obteniendo los datos`,
                content: 'No se pudieron obtener los datos correctamente',
                actions: [
                  {
                    label: 'Recargar',
                    execute: () => window.location.reload(),
                  },
                ],
              });
              return {};
            });
        });

      Promise.all(promises)
        .then((results) => {
          const combinedOptions = results.reduce(
            (accumulator, currentValue) => ({
              ...accumulator,
              ...currentValue,
            }),
            {}
          );
          setApiOptions(combinedOptions);
        })
        .catch((err) => {
          console.error('Error:', err);
        });
    }
  }, [selectedColumns]);

  const renderFilterComponent = (column) => {
    switch (column.type) {
      case 'autoText':
        const allOptions = isFilterModeAPI
          ? column.typeValue.endpoint
            ? apiOptions[column.field]
            : getKeyValueFromPaths(
                originalRows.data,
                column.typeValue.keyToLabel,
                column.typeValue.keyToValue
              )
          : [...new Set(originalRows.map((row) => row[column.field]))].sort();

        return (
          <Autocomplete
            multiple
            label={`Filtrar por ${column.headerName}`}
            name={column.field}
            value={filterValues[column.field] || []}
            onChange={(event, newValue) => {
              handleAutoTextChange(newValue, column.field);
            }}
            options={allOptions || []}
            getOptionLabel={
              isFilterModeAPI
                ? column.typeValue.endpoint
                  ? (option) => option[column.typeValue.keyToLabel]
                  : (option) => option.label
                : undefined
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={`Filtrar por ${column.headerName}`}
                variant='outlined'
              />
            )}
            sx={{ width: '100%' }}
          />
        );

      case 'text':
        return (
          <TextField
            label={`Filtrar por ${column.headerName}`}
            name={column.field}
            value={filterValues[column.field] || ''}
            onChange={(event) => handleStringChange(event, column)}
            variant='outlined'
            sx={{ width: '100%' }}
          />
        );

      case 'date':
        return (
          <DateRangeSlider
            id={'fechas'}
            dateRange={range[column.field]}
            setDateRange={(newRange) => updateRange(column.field, newRange)}
            minimumDisplay='01/01/2023'
          />
        );

      case 'option':
        return (
          <FormControl
            sx={{
              width: '100%',
              maxHeight: '150px',
              overflowY: 'auto',
              border: 1,
              borderColor: 'grey.400',
              borderRadius: '16px',
            }}
          >
            <FormGroup
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                p: 2,
              }}
            >
              {column.typeValue.map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={
                        filterValues[column.field]?.includes(option.value) ||
                        false
                      }
                      onChange={(event) => handleOptionChange(event, column)}
                      name={option.value}
                      color='primary'
                    />
                  }
                  label={option.label}
                />
              ))}
            </FormGroup>
          </FormControl>
        );

      default:
        return null;
    }
  };

  const handleAutoTextChange = (newValues, fieldName) => {
    setFilterValues((prevFilterValues) => ({
      ...prevFilterValues,
      [fieldName]: newValues,
    }));
  };

  const updateRange = (field, newRange) => {
    setRange((prevRange) => ({
      ...prevRange,
      [field]: [new Date(newRange[0]), new Date(newRange[1])],
    }));
  };

  const handleColumnChange = (event, newValue) => {
    setSelectedColumns(newValue);
  };

  const handleStringChange = (event, column) => {
    const { name, value } = event.target;
    setFilterValues((prevFilterValues) => ({
      ...prevFilterValues,
      [column.field]: value,
    }));
  };

  const handleOptionChange = (event, column) => {
    const { name, checked } = event.target;
    const columnName = column.field;

    setFilterValues((prevFilterValues) => {
      const updatedFilterValues = { ...prevFilterValues };

      if (!updatedFilterValues[columnName]) {
        updatedFilterValues[columnName] = [];
      }

      if (checked) {
        updatedFilterValues[columnName].push(name);
      } else {
        updatedFilterValues[columnName] = updatedFilterValues[
          columnName
        ].filter((value) => value !== name);
      }

      return updatedFilterValues;
    });
  };

  const applyFilter = () => {
    let filteredData;

    if (isFilterModeAPI) {
      filteredData = selectedColumns
        .map((column) => {
          const columnPath = column.path;
          const filterValue = filterValues[column.field];

          switch (column.type) {
            case 'autoText':
              const keyValue = filterValue.map((item) =>
                column.typeValue.endpoint
                  ? item[column.typeValue.keyToValue]
                  : item.value
              );

              return {
                label: column.field,
                param: columnPath,
                value: keyValue,
              };

            case 'text':
            case 'option':
              return {
                label: column.field,
                param: columnPath,
                value: filterValue,
              };

            case 'date':
              const [startDate, endDate] = range[column.field];
              const startOfDay = new Date(startDate);
              startOfDay.setHours(0, 0, 0, 0);
              const endOfDay = new Date(endDate);
              endOfDay.setHours(23, 59, 59, 999);
              return [
                {
                  label: column.field,
                  param: columnPath + '$gte',
                  value: [startOfDay],
                },
                {
                  label: column.field,
                  param: columnPath + '$lte',
                  value: [endOfDay],
                },
              ];

            default:
              return null;
          }
        })
        .filter((item) => item !== null)
        .flat();
    } else {
      filteredData = [...originalRows];

      selectedColumns.forEach((column) => {
        const filterValue = filterValues[column.field];

        switch (column.type) {
          case 'autoText':
            if (filterValue && filterValue.length > 0) {
              filteredData = filteredData.filter((row) => {
                const cellValue = String(row[column.field]).toLowerCase();
                return filterValue.some((selectedValue) =>
                  cellValue.includes(selectedValue.toLowerCase())
                );
              });
            }
            return;

          case 'text':
            if (filterValue !== undefined && filterValue !== '') {
              filteredData = filteredData.filter((row) =>
                String(row[column.field])
                  .toLowerCase()
                  .includes(filterValue.toLowerCase())
              );
            }
            return;

          case 'date':
            const [startDate, endDate] = range[column.field];
            const startOfDay = new Date(startDate);
            startOfDay.setHours(0, 0, 0, 0);
            const endOfDay = new Date(endDate);
            endOfDay.setHours(23, 59, 59, 999);

            filteredData = filteredData.filter((row) => {
              const rowDate = new Date(row[column.field]);
              return rowDate >= startOfDay && rowDate <= endOfDay;
            });
            return;

          case 'option':
            if (filterValue && filterValue.length > 0) {
              filteredData = filteredData.filter((row) =>
                filterValue.includes(row[column.field])
              );
            }
            return;

          default:
            return;
        }
      });
    }

    setSelectedColumns(selectedColumns);
    const filters = {
      selectedColumns,
      filterValues,
    };
    setParentAppliedFilters(filters);
    onApplyFilter(filteredData, filters);
    onClose();
  };

  const clearFilters = () => {
    setSelectedColumns([]);
    setFilterValues({});
    const filters = {
      selectedColumns: [],
      filterValues: {},
    };
    setParentAppliedFilters(filters);
    onApplyFilter(isFilterModeAPI ? [] : originalRows, filters);
    onClose();
  };

  useEffect(() => {
    const dateTypeColumns = selectedColumns.filter(
      (column) => column.type === 'date'
    );

    if (dateTypeColumns.length > 0) {
      setFilterValues((prevFilterValues) => {
        const newFilterValues = { ...prevFilterValues };

        dateTypeColumns.forEach((column) => {
          newFilterValues[column.field] = range[column.field];
        });

        return newFilterValues;
      });
    }
  }, [range, selectedColumns]);

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 2,
        marginBottom: 2,
        textAlign: 'center',
      }}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Grid item xs={12} sx={{ minWidth: '450px' }}>
          <Autocomplete
            multiple
            id='columns-select'
            options={columns.filter((column) => column?.type !== 'none')}
            getOptionLabel={(option) => option.headerName}
            value={selectedColumns}
            onChange={handleColumnChange}
            renderOption={(props, option) => (
              <li {...props}>
                <span>
                  {selectedColumns.includes(option) ? (
                    <CheckBoxIcon
                      fontSize='small'
                      color='primary'
                      style={{ marginRight: 8 }}
                    />
                  ) : (
                    <CheckBoxOutlineBlankIcon
                      fontSize='small'
                      style={{ marginRight: 8 }}
                    />
                  )}
                </span>
                {option.headerName}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Seleccione los filtros'
                variant='outlined'
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.field}
                  label={option.headerName}
                  {...getTagProps({ index })}
                />
              ))
            }
            sx={{
              '& ul': { listStyleType: 'none', padding: 0, width: '100%' },
            }}
          />
        </Grid>
        {selectedColumns.map((column) => (
          <Grid
            item
            xs={12}
            key={column.field}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
              width: '100%',
            }}
          >
            <Typography variant={'button'} color={'primary'}>
              {column.headerName.toUpperCase()}
            </Typography>
            {renderFilterComponent(column)}
          </Grid>
        ))}
        <Grid item xs={12} sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Button onClick={clearFilters} variant='outlined' color='primary'>
              Limpiar
            </Button>
            <Button onClick={applyFilter} variant='contained' color='primary'>
              Aplicar
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* DIALOG */}
      <AlertDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        actions={dialog.actions}
      />
    </Container>
  );
};

export default FilterBy;
