import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';

const CardContainer = ({ sx, children }) => {
  const defaultStyles = {
    borderRadius: '16px',
    p: 2,
    ...sx,
  };

  return (
    <Paper elevation={2} sx={defaultStyles}>
      {children}
    </Paper>
  );
};

CardContainer.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
};

export default CardContainer;
