import React, {useState, useEffect} from "react";
import {
  Typography,
  Container,
  Grid,
  Paper,
  Button,
  TextField,
  FormControl,
} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {useNavigate} from "react-router";

import useAuth from "../../../hooks/useAuth";
import {getColumns} from "./dto/expenses.columns";
import {getExpenses} from "../../../services/modules/expenses/expenses";

export function ExpensesIndex() {
  const navigate = useNavigate();
  const {userid} = useAuth();

  const [data, setData] = useState([]);

  const [pageNumber, setPageNumber] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [description, setDescription] = useState("");
  const [pageSize, setPageSize] = useState(100);

  const handlerPageChange = (newPage) => {
    console.log(newPage);
    setPageNumber(newPage);
    fillTableExpenses();
  };

  const handlerOnRegisterExpense = () => {
    navigate(`/${userid.claims.rol}/expenses/new`);
  };

  const fillTableExpenses = () => {
    getExpenses(userid, pageNumber + 1, description)
      .then((response) => {
        if (response.status === 200) {
          const {data, total_pages, total_records} = response.data;
          setData(data || []);
          setTotalRecords(total_records);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = getColumns(userid, fillTableExpenses);

  const handlerInputSearch = (event) => {
    const {value} = event.target;
    console.log(value, value.length);
    if (value) {
      setDescription(value);
    }
  };

  const handleKeyDownEnter = (event) => {
    if (event.key === "Enter") {
      fillTableExpenses();
    }
  };

  useEffect(() => {
    fillTableExpenses();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container component="main" maxWidth="lg" sx={{mb: 4}}>
        <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
              <Typography variant="h5">Gastos</Typography>
            </Grid>

            <Grid item xs={9} sx={{display: "flex", justifyContent: "right"}}>
              <FormControl fullWidth>
                <TextField
                  label="Buscar por descripción"
                  variant="outlined"
                  onChange={handlerInputSearch}
                  onKeyDown={handleKeyDownEnter}
                />
              </FormControl>

              <Button variant="contained" onClick={fillTableExpenses}>
                Buscar
              </Button>
            </Grid>
            <Grid item xs={3} sx={{display: "flex", justifyContent: "right"}}>
              <Button variant="contained" onClick={handlerOnRegisterExpense}>
                Registrar Gasto
              </Button>
            </Grid>

            <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
              <DataGrid
                sx={{width: "100%"}}
                rows={data}
                autoHeight
                columns={columns}
                getRowId={(row) => row._id}
                disableSelectionOnClick
                pageSize={pageSize}
                page={pageNumber}
                onPageChange={(params) => handlerPageChange(params)}
                rowCount={totalRecords}
              />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
}
