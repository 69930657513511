import React, {useEffect, useState, useRef} from "react";
import {Button, Container, Grid, Paper, Typography} from "@mui/material";
import {BrowserRouter as Router, Route, useParams} from "react-router-dom";

import {
  getVehicle,
  updateVehicle,
  uploadPolicyVehicle,
} from "../../../services/modules/transports/vehicles";
import useAuth from "../../../hooks/useAuth";
import {useNavigate} from "react-router";
import {AlertDialog} from "../../../components/userFeedback/AlertDialog";

import TruckInfoComponent from "../../../components/Forms/Transports/TruckInfoComponent";
import SCTPermissionComponent from "../../../components/Forms/Transports/SCTPermissionComponent";
import PolicyComponent from "../../../components/Forms/Transports/PolicyComponent";
import FuelSpecsComponent from "../../../components/Forms/Transports/FuelSpecsComponent";

import {createVehicleDto} from "./dto/create-vehicle.dto";

export default function EditTransport() {
  const {userid} = useAuth();
  const {vehicleId} = useParams();
  const navigate = useNavigate();

  const formRef = useRef(null);
  const policyComponentRef = useRef();

  const [transport, setTransport] = useState({
    ...createVehicleDto,
  });
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  function handlerAccessPropertyInTransport(path, newValue) {
    const splits = path.split(".");
    const finalProperty = splits.pop();

    const finalObject = splits.reduce((acc, clave) => {
      acc[clave] = acc[clave] || {};
      return acc[clave];
    }, transport);

    finalObject[finalProperty] = newValue;
  }

  const handleChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    handlerAccessPropertyInTransport(id, value);
    setTransport({
      ...transport,
    });
    console.log(id, transport);
  };

  const handleErrorsUI = (err) => {
    if (err.response) {
      if (err.response.status) {
        switch (err.response.status) {
          case 400:
            const {data} = err.response;
            if (data && data.error && data.error.Descriptions) {
              const mapErrors = data.error.Descriptions.map(
                (validationError) => {
                  return validationError.Reason;
                }
              );
              setDialog({
                open: true,
                title: "Error",
                content: `Verifica los datos: ${mapErrors.join(", ")}`,
                actions: [
                  {
                    label: "Cerrar",
                    execute: () =>
                      setDialog({open: false, title: "", content: ""}),
                  },
                ],
              });
              return;
            }
            return data.message;
          case 401:
            setDialog({
              open: true,
              title: "Error",
              content: err.response.data.message || err.response.statusText,
            });
            return;
          case 403:
            setDialog({
              open: true,
              title: "Error",
              content: err.response.data.message || err.response.statusText,
            });
            return;
          case 404:
            setDialog({
              open: true,
              title: "Error",
              content: err.response.data.message || err.response.statusText,
            });
            return;
          case 500:
            setDialog({
              open: true,
              title: "Error",
              content: err.response.data.message || err.response.statusText,
            });
            return;
        }

        return;
      }

      setDialog({
        open: true,
        title: "Error",
        content: "Ocurrió un error de conexión con el servidor",
      });
      return;
    }

    setDialog({
      open: true,
      content: "Ocurrió un error al procesar la solicitud",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formRef.current && !formRef.current.checkValidity()) {
      console.log("form not valid");
      return;
    }

    const file = policyComponentRef.current.getFile();

    transport.Price = parseFloat(transport.Price);
    transport.Specs.ModelYear = parseInt(transport.Specs.ModelYear);
    transport.SCTPermission.Number = transport.SCTPermission.Number;

    if (!transport.Policy.LastRenewal) {
      delete transport.Policy.LastRenewal;
    }
    if (!transport.Policy.PrimaCost) {
      delete transport.Policy.PrimaCost;
    }
    if (!transport.Policy.TotalCost) {
      delete transport.Policy.TotalCost;
    }

    if (transport.Specs.FuelSpecs.NumberTanks) {
      transport.Specs.FuelSpecs.NumberTanks = parseInt(
        transport.Specs.FuelSpecs.NumberTanks
      );
    }
    if (transport.Specs.FuelSpecs.CapacityInLiters) {
      transport.Specs.FuelSpecs.CapacityInLiters = parseInt(
        transport.Specs.FuelSpecs.CapacityInLiters
      );
    }
    if (transport.Specs.FuelSpecs.KmLtrSpec) {
      transport.Specs.FuelSpecs.KmLtrSpec = parseFloat(
        transport.Specs.FuelSpecs.KmLtrSpec
      );
    }
    if (transport.Specs.FuelSpecs.KmLtrReal) {
      transport.Specs.FuelSpecs.KmLtrReal = parseFloat(
        transport.Specs.FuelSpecs.KmLtrReal
      );
    }

    if (typeof transport.Specs.Brand === "object") {
      transport.Specs.Brand = transport.Specs.Brand._id;
    }

    updateVehicle(userid, userid, transport._id, transport)
      .then((res) => {
        console.log("respuesta vehicle ", res);

        if (!file) {
          console.log("vehiculo editado con exito");
          navigate(`/${userid.claims.rol}/transports`);
        } else {
          const formData = new FormData();
          formData.append("file", file);

          uploadPolicyVehicle(userid, userid, transport._id, formData)
            .then((resUploadPolicy) => {
              console.log(resUploadPolicy);
              navigate(`/${userid.claims.rol}/transports`);
            })
            .catch((err) => {
              console.error(err);
              navigate(`/${userid.claims.rol}/transports`);
            });
        }
      })
      .catch((err) => {
        console.error(err);
        handleErrorsUI(err);
      });
  };

  useEffect(() => {
    getVehicle(userid, userid, vehicleId)
      .then((response) => {
        console.log("respuesta a editar", response);
        setTransport(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AlertDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        actions={dialog.actions}
      />
      <form ref={formRef}>
        <Container component="main" maxWidth="sm" sx={{mb: 4}}>
          <Paper
            variant="outlined"
            sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}
          >
            <Typography variant="h4" align="center" color="primary.dark">
              Editar Vehículo
            </Typography>

            <TruckInfoComponent
              transport={transport}
              handleChange={handleChange}
              setTransport={setTransport}
            />

            <br />

            <FuelSpecsComponent
              transport={transport}
              handleChange={handleChange}
            />

            <br />

            <SCTPermissionComponent
              transport={transport}
              handleChange={handleChange}
            />

            <br />

            <PolicyComponent
              ref={policyComponentRef}
              transport={transport}
              handleChange={handleChange}
            />

            <Grid container spacing={2} sx={{mt: "3vh"}}>
              <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  {"Actualizar vehículo"}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </form>
    </div>
  );
}
