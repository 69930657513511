import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { useNavigate } from "react-router";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import EditComponent from "../../../components/actions/EditComponent";
import {
  deleteDestination,
  getAllDestinations,
} from "../../../services/modules/services/destination";
import ferbyTheme from "../../../theme/ferbyTheme";
import { AlertDialog } from "../../../components/userFeedback/AlertDialog";

const DestinationsIndex = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { userid } = useAuth();

  const goToCreate = () => {
    navigate(`/${userid.claims.rol}/destinations/new`);
  };

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
    updateOpenState: (v) => setDialog({ ...dialog, open: v }),
  });

  const columns = [
    {
      field: "TaxName",
      headerName: "Nombre",
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "Rfc",
      headerName: "RFC",
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "Type",
      headerName: "Tipo",
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "Actions",
      headerName: "Opciones",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return (
          <EditComponent
            editionRoute={`/${userid.claims.rol}/destinations/update`}
            useParams
            objName={"Destino"}
            onDelete={() => deleteVehicles(params.row.id)}
            rowParams={params.row}
          />
        );
      },
    },
  ];

  const deleteVehicles = (id) => {
    console.log("userid", userid);
    deleteDestination(userid, id)
      .then((response) => {
        console.log(response);
        setDialog({
          ...dialog,
          open: true,
          title: "Destino eliminado",
          content: "",
          actions: [{ label: "Ok", execute: () => window.location.reload() }],
        });
      })
      .catch((err) => {
        console.log(err.response);
        setDialog({
          ...dialog,
          open: true,
          title: "Ocurrió un error al eliminar el Destino",
          content: "Inténtelo más tarde",
          actions: [
            { label: "Ok", execute: () => window.location.reload() },
            { label: "Reintentar", execute: () => deleteVehicles(id) },
          ],
        });
      });
  };

  useEffect(() => {
    const rowsToSet = [];
    setLoading(true);
    getAllDestinations(userid)
      .then((res) => {
        res.data.forEach((element) => {
          console.log("elementor", element);
          rowsToSet.push({
            id: element._id,
            _id: element._id,
            TaxName: element.TaxName,
            Rfc: element.Rfc,
            Address: element.Address,
            Type: element.Type,
          });
        });
        setRows((ir) => rowsToSet);
        setLoading((l) => false);
      })
      .catch((err) => {
        console.log(err);
        setLoading((l) => false);
      });
  }, []);

  return (
    <>
      <Backdrop open={loading}>
        <CircularProgress />
      </Backdrop>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "10vh",
          "& .header-table": {
            backgroundColor: ferbyTheme.palette.primary.main,
            color: "white",
          },
        }}
      >
        <AlertDialog {...dialog} />
        <Grid container spacing={2} maxWidth={"lg"}>
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="primary"
            >
              Consultar Destinos
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" onClick={goToCreate} color="primary">
                Crear nuevo
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <DataGrid
              sx={{ minHeight: "50vh" }}
              columns={columns}
              rows={rows}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DestinationsIndex;
