import React, { useState } from "react";
import { Grid, TextField, CircularProgress, Autocomplete } from "@mui/material";
import { MexicoFederalEntityKeysCatalog } from "../../../modules/invoices/utils/staticCatalogs";
import { searchEmployeeByKey } from "../../../services/modules/employees/employees";
import useAuth from "../../../hooks/useAuth";

const NominaEmployeeReceiverForm = ({
  employee,
  onEmployeeChange,
  alert,
  setAlert,
}) => {
  const { userid } = useAuth();
  const [employees, setEmployees] = useState([]);
  const [employeeInput, setEmployeeInput] = useState("");
  const [loading, setLoading] = useState({
    employeesInput: false,
  });
  const handleEmployeeInput = (e) => {
    setEmployeeInput(e.target.value);
    if (e.target.value !== undefined && e.target.value.length > 3) {
      setLoading((load) => {
        return {
          ...load,
          employeesInput: true,
        };
      });

      searchEmployeeByKey(userid, userid, "Nombre", e.target.value)
        .then((res) => {
          if (res.data.data.length > 0) {
            setEmployees(res.data.data);
          }
          setLoading((load) => {
            return {
              ...load,
              employeesInput: false,
            };
          });
        })
        .catch((err) => {
          setAlert((alert) => {
            return {
              ...alert,
              state: "error",
              message: "Error obteniendo a los Empleados",
            };
          });
        });
    }
  };

  const handleSelectEmployee = (e, v, r) => {
    onEmployeeChange(e, v, r);
  };

  return (
    <>
      <Grid item xs={12} md={7}>
        <Autocomplete
          id="Employee"
          options={employees}
          getOptionLabel={(option) => {
            if (option === "") {
              return "";
            }
            return `${option.Nombre} ${option.ApellidoP} ${option.ApellidoM}`;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                sx={{ width: "100%" }}
                value={employeeInput}
                label="Empleado"
                onChange={handleEmployeeInput}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading.employeesInput ? <CircularProgress /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }}
          isOptionEqualToValue={(o, v) => {
            if (v === "") {
              return true;
            }

            return o.Nombre === v.Nombre && o.Rfc === v.Rfc;
          }}
          onChange={handleSelectEmployee}
          value={employee}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Autocomplete
          id="FederalEntityKey"
          sx={{ width: "100%" }}
          options={MexicoFederalEntityKeysCatalog}
          getOptionLabel={(option) => {
            if (!option) {
              return "";
            }
            return `${option.Name}`;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                id="FederalEntityKey"
                sx={{ width: "100%" }}
                label="Lugar donde labora"
                required
                variant="standard"
                value={employee.FederalEntityKey || ""}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }}
          isOptionEqualToValue={(o, v) => {
            console.log(typeof v);
            if (v === "") {
              return true;
            }

            return o.Name === v.Name;
          }}
          onChange={handleSelectEmployee}
          value={employee?.FederalEntityKey || ""}
        />
      </Grid>
    </>
  );
};

export default NominaEmployeeReceiverForm;
