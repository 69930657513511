import { API_CONTEXT } from "./invoices";

export const getCustomers = (user, credential) => {
  const getCustomersPromise = API_CONTEXT("organization", "customers");

  return getCustomersPromise(user, credential);
};

export const filterCustomers = (user, filters) => {
  const getCustomersPromise = API_CONTEXT("organization", "filterCustomers");
  const stringifiedFilter = filters.map((filter) => {
    return `${filter.param}=${filter.value}`;
  });

  return getCustomersPromise(user, stringifiedFilter);
};

export const getIssuedDocs = (user, credential, pageNumber, filters) => {
  const getCustomersPromise = API_CONTEXT("organization", "emitidos");
  const stringifiedFilter = filters.map((filter) => {
    return `${filter.param}=${filter.value}`;
  });

  return getCustomersPromise(user, credential, pageNumber, stringifiedFilter);
};
export const getAnalyticsByRange = (
  user,
  credential,
  range,
  chunks,
  days,
  filters
) => {
  const anayliticsPromise = API_CONTEXT("organization", "analytics");
  const stringifiedRange = [`initialDate=${range[0]}`, `finalDate=${range[1]}`];
  const stringifiedFilter = filters.map((filter) => {
    return `${filter.param}=${filter.value}`;
  });

  return anayliticsPromise(
    user,
    credential,
    stringifiedRange,
    chunks,
    days,
    stringifiedFilter
  );
};
export const createExpeditionPlace = (user, credential, Rfc, AddressJson) => {
  const getPromise = API_CONTEXT("organization", "newPlace");

  return getPromise(user, credential, Rfc, AddressJson);
};

export const saveExpeditionPlaceInPac = (user, credential, addressId) => {
  const getPromise = API_CONTEXT("organization", "activatePlace");

  return getPromise(user, credential, addressId);
};
