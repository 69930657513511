import {
  Alert,
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Snackbar,
  TextField,
  Typography,
  Button,
  Grid,
  Paper,
  Box,
  Backdrop,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useAuth from "../../../hooks/useAuth";
import {
  getExpeditionPLC,
  getIssuers,
} from "../../../services/modules/invoices/issuers";
import { NominaDateWithType } from "./NominaDateTypeComponent";
import {
  DeductionsTable,
  OtherPaymentsTable,
  PerceptionsTable,
} from "./NominaTables";
import { EmployeeForm } from "./EmployeeForm";
import { PreviewCFDI } from "../../Previews/PreviewTables";
import { issueInvoice } from "../../../services/modules/invoices/invoices";
import { getPaymentMethods } from "../../../services/modules/invoices/catalogs";
import { getTypeOfInvoice } from "../../Previews/Getters";

//Reference Objects Can be deleted as they are not used anywhere
const Employee = {
  Id: "",
  Rfc: "",
  Nombre: "",
  ApellidoP: "",
  ApellidoM: "",
  DomFiscal: {
    CP: "",
    Estado: "",
    Calle: "",
    Localidad: "",
    Municipio: "",
    NumExt: "",
    NumInt: "",
  },
  CURP: "",
  NSS: "",
  FechaInicio: new Date(),
  Antiguedad: "",
  TipoContrato: "",
  Sindicato: false,
  TipoJornada: "",
  TipoRegimen: "",
  Dpto: "",
  Puesto: "",
  Riesgo: "",
  Licencia: "",
  Periodicidad: "",
  BancoClave: "",
  Banco: "",
  CtaBanco: "",
  Sueldo: 0,
  SueldoBase: 0,
  SueldoDiario: 0,
  Perceptions: [
    {
      Value: "",
      Name: "",
      ExemptTotal: 0,
      TaxedTotal: 0,
    },
  ],
  Deductions: [
    {
      Value: "",
      Name: "",
      Amount: 0,
    },
  ],
  ConfigStatus: {
    progress: 0,
    leftValues: [],
  },
};
const initialNomina = {
  NameId: 16,
  ExpeditionPlace: "",
  CfdiType: "N",
  PaymentForm: "99",
  PaymentMethod: "",
  Folio: 0,
  Receiver: {
    Rfc: "",
    Name: "",
    CfdiUse: "CN01",
  },
  Issuer: {
    EmployerRegistration: "",
  },
  Complemento: {
    Payroll: {
      Type: "O",
      PaymentDate: "",
      InitialPaymentDate: "",
      FinalPaymentDate: "2019-01-15T13:43:59.4011985-06:00",
      DaysPaid: 0,
      Issuer: {
        EmployerRegistration: "",
      },
      Employee: {
        Curp: "",
        SocialSecurityNumber: "",
        StartDateLaborRelations: "",
        ContractType: "",
        RegimeType: "",
        Unionized: false,
        TypeOfJourney: "",
        EmployeeNumber: "",
        Department: "",
        Position: "",
        PositionRisk: "",
        FrequencyPayment: "",
        Bank: "",
        BankAccount: "",
        BaseSalary: 0,
        DailySalary: 0,
        FederalEntityKey: "",
      },
      Perceptions: {
        Details: [
          {
            PerceptionType: "001",
            Code: "00500",
            Description: "Salario Diario",
            TaxedAmount: 1,
            ExemptAmount: 1,
          },
        ],
      },
      Deductions: {
        Details: [
          {
            DeduccionType: "001",
            Code: "IMSS",
            Description: "Seguridad Social",
            Amount: 1,
          },
          {
            DeduccionType: "002",
            Code: "ISR",
            Description: "Impuesto Sobre la Renta",
            Amount: 1,
          },
          {
            DeduccionType: "003",
            Code: "RETIRO",
            Description: "Aportacion a retiro",
            Amount: 1,
          },
        ],
      },
      OtherPayments: [
        {
          OtherPaymentType: "002",
          Code: "00101",
          Description: "otro pago",
          Amount: 110,
          EmploymentSubsidy: {
            Amount: 110,
          },
        },
      ],
    },
  },
};

const SelectIssuersAutocomplete = ({ selected, setSelected }) => {
  const { userid } = useAuth();

  const [options, setOptions] = useState({
    issuerList: [],
  });

  const [loading, setLoading] = useState({
    issuers: true,
  });

  const [error, setError] = useState({
    issuer: {
      failed: false,
      message: "",
    },
  });

  const handleChangeIssuer = (e, v, r) => {
    if (r === "clear") {
      selected.issuer.FromEmployerRfc = "";
    } else {
      selected.issuer.FromEmployerRfc = v.Rfc;
    }

    setSelected({ ...selected });
  };

  const getData = () => {
    return new Promise((resolve, reject) => {
      getIssuers(userid).then(({ data }) => {
        setLoading((obj) => {
          return { ...obj, issuers: false };
        });
        if (data.data.length === 0) {
          console.log("no issuers");
        }
        if (data.data.length > 0) {
          setOptions((obj) => {
            return { ...obj, issuerList: data.data };
          });
          selected.issuer.FromEmployerRfc = data.data[0].Rfc;
          setSelected((obj) => {
            return {
              ...obj,
              issuer: { ...data.data[0], EmployerRegistration: "" },
            };
          });
        }
      });
    }).catch((err) => {
      console.log("issuers error", err);
    });
  };

  useEffect(() => {
    getData().then((resolved) => {});
  }, [userid]);

  return (
    <Autocomplete
      id="issuer"
      sx={{ minWidth: "100%" }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={error.issuer.failed}
            helperText={error.issuer.message}
            //label="Emisor"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {/*loading.issuers ? <CircularProgress /> : null*/}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
      getOptionLabel={(option) => option.Name || ""}
      options={options.issuerList}
      onChange={handleChangeIssuer}
      isOptionEqualToValue={(o, v) => {
        if (v === "") {
          return true;
        } else {
          return o.Name === v.Name;
        }
      }}
      value={selected.issuer}
      clearOnBlur
    />
  );
};

export const NominaLibre = () => {
  //*Sabemos que estamos en el paso 0, porque el stepper, devuelve el Componente NominaGeneralData, solo cuando estamos en el paso 0
  //const step = 0
  //*States

  const [nominaOrdinaria, setNominaOrdinaria] = useState(true);

  const [employee, setEmployee] = useState({
    Curp: "",
    SocialSecurityNumber: "",
    StartDateLaborRelations: "",
    ContractType: "",
    RegimeType: "",
    Unionized: false,
    TypeOfJourney: "",
    EmployeeNumber: "",
    Department: "",
    Position: "",
    PositionRisk: "",
    FrequencyPayment: "",
    Bank: "",
    BankAccount: "",
    BaseSalary: 0,
    DailySalary: 0,
    FederalEntityKey: "",
  });

  const [datosCfdi, setDatosCfdi] = useState({
    NameId: "16",
    ExpeditionPlace: "",
    CfdiType: "N",
    PaymentForm: "99",
    PaymentMethod: "PUE",
    Folio: 0,
  });

  const [receiver, setReceiver] = useState({
    Rfc: "",
    Name: "",
    CfdiUse: "CN01",
  });

  const [issuer, setIssuer] = useState({
    EmployerRegistration: "",
    Rfc: "",
    Curp: "",
  });

  const [payrollGeneral, setPayrollGeneral] = useState({
    Type: "O",
    PaymentDate: "",
    InitialPaymentDate: "",
    FinalPaymentDate: "",
    DaysPaid: 0,
    Employee: "",
    Perceptions: { Details: "" },
    Deductions: { Details: "" },
    OtherPayments: { Details: "" },
  });

  const [loading, setLoading] = useState({
    employeesInput: false,
    nameId: false,
    cfdiOptions: {
      uses: false,
      paymentForm: false,
      paymentMethod: false,
    },
    place: false,
    employeeUpdate: false,
  });

  const [loading2, setLoading2] = useState(false);

  const [selected, setSelected] = useState({
    issuer: {
      EmployerRegistration: "",
      FromEmployerRfc: "",
      Curp: "",
    },
    place: "",
    paymentMethod: {
      Name: "Pago en una sola exhibición",
      Value: "PUE",
    },
    perception: "",
    deduction: {
      DeduccionType: "",
      Code: "",
      Description: "",
      Amount: "",
    },
  });
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    status: "",
  });
  const [cfdiOptions, setCfdiOptions] = useState({
    names: [],
    uses: [],
    type: "",
    place: [],
    paymentMethod: [],
  });
  //const [retry, setRetry] = useState(false)
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  const [dialog2, setDialog2] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });
  //const [modal, setModal] = useState({
  //    open:false,
  //    leftValues:[]
  //})

  //*Contexts
  const { userid } = useAuth();

  //Not used ... yet
  const navigate = useNavigate();

  //*dataModels
  //const NominaModel = invoicesModelBySteps.find(object => object.Nomina).Nomina

  const [commonDeductions, setCommonDeductions] = useState([
    {
      DeduccionType: "001",
      Code: "001",
      Description: "Seguridad Social",
      Amount: 0,
    },
    {
      DeduccionType: "002",
      Code: "002",
      Description: "ISR",
      Amount: 1,
    },
    {
      DeduccionType: "003",
      Code: "003",
      Description: "Aportaciones a retiro, cesantía en edad avanzada y vejez.",
      Amount: 1,
    },
  ]);

  const [commonPerceptions, setCommonPerceptions] = useState([
    {
      PerceptionType: "001",
      Code: "001",
      Description: "Sueldos, Salarios  Rayas y Jornales",
      TaxedAmount: 0,
      ExemptAmount: 0,
    },
  ]);

  const [commonPayments, setCommonPayments] = useState([
    {
      OtherPaymentType: "002",
      Code: "",
      Description: "Subsidio para el empleo",
      Amount: 0,
      EmploymentSubsidy: { Amount: 0 },
    },
  ]);

  const [hasOtherPayments, setHasOtherPayments] = useState(false);

  const [fechas, setFechas] = useState({
    PaymentDate: "",
    InitialPaymentDate: "",
    FinalPaymentDate: "",
    DaysPaid: "",
  });

  //*Input Functions
  const handleSetFolio = (e) => {
    //NominaModel[step].dataModel["Folio"] = e.target.value
    datosCfdi.Folio = e.target.value;
    setDatosCfdi({ ...datosCfdi });
  };

  const handleWithOtherPayments = () => {
    setHasOtherPayments(!hasOtherPayments);
  };

  let total = getTotal();

  function getTotal() {
    let total = 0;
    for (const array in commonPerceptions) {
      total =
        total +
        Number(commonPerceptions[array].TaxedAmount) +
        Number(commonPerceptions[array].ExemptAmount);
    }
    for (const array in commonDeductions) {
      total = total - Number(commonDeductions[array].Amount);
    }
    if (hasOtherPayments) {
      for (const array in commonPayments) {
        total = total + Number(commonPayments[array].Amount);
      }
    }

    return total;
  }

  const handleCloseAlert = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({
      ...alert,
      open: false,
      message: "",
      status: "",
    });
  };

  const handleCloseDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  const validateConfigAndGetUses = (employeeObject) => {
    if (employeeObject.ConfigStatus.progress !== 1) {
      setDialog({
        ...dialog,
        open: true,
        title: "El empleado no tiene la configuración completa",
        content:
          "Es necesario completar la configuración del empleado para que el proceso de nómina sea adecuado",
        actions: [
          {
            label: "Completar configuración",
            execute: () => {
              setDialog({
                ...dialog,
                open: false,
              });

              //setModal({
              //    open:true,
              //    leftValues:employeeObject.ConfigStatus.leftValues
              //})
            },
          },
        ],
      });
    }
  };

  const keyMap = {
    NameId: "Identificador factura:",
    ExpeditionPlace: "C.P Expedición:",
    CfdiType: "Tipo de CFDI",
    PaymentForm: "Forma de Pago",
    PaymentMethod: "Método de Pago",
    Folio: "Folio",
    Receiver: {
      Rfc: "RFC",
      Name: "Nombre",
      CfdiUse: "Uso de CFDI",
      FiscalRegime: "Regimen fiscal del receptor",
    },
    Issuer: {
      Curp: "Curp del emisor",
      EmployerRegistration: "Registro Patronal",
      Rfc: "RFC emisor",
      Name: "Nombre emisor",
      FiscalRegime: "Regimen fiscal emisor",
    },
    Complemento: {
      Payroll: {
        Type: "Tipo",
        PaymentDate: "Fecha de Pago",
        InitialPaymentDate: "Inicio de Pago",
        FinalPaymentDate: "Final de Pago",
        DaysPaid: "Días Pagados",
        Employee: {
          Curp: "CURP",
          SocialSecurityNumber: "NSS",
          StartDateLaborRelations: "Fecha inicio relaciones laborales",
          ContractType: "Tipo de Contrato",
          RegimeType: "Tipo de Régimen",
          Unionized: "Sindicalizado",
          TypeOfJourney: "Tipo de jornada",
          EmployeeNumber: "Número de empleado",
          Department: "Departamento",
          Position: "Puesto",
          PositionRisk: "Riesgo del puesto",
          FrequencyPayment: "Periodicidad del pago",
          Bank: "Banco",
          BankAccount: "CLABE",
          BaseSalary: "Salario Base",
          DailySalary: "Salario Diario",
          FederalEntityKey: {
            Id: "Federal Entity ID",
            Name: "Federal Entity Name",
            Description: "Federal Entity Description",
            Address: {
              Street: "Street",
              ExteriorNumber: "Exterior Number",
              Neighborhood: "Neighborhood",
              ZipCode: "Zip Code",
              Locality: "Locality",
              Municipality: "Municipality",
              State: "State",
              Country: "Country",
            },
          },
        },
        Perceptions: {
          Details: [
            {
              PerceptionType: "Tipo de Percepción",
              Code: "Código",
              Description: "Descripción",
              TaxedAmount: "Monto Gravado",
              ExemptAmount: "Monto Exento",
            },
          ],
        },
        Deductions: {
          Details: [
            {
              DeduccionType: "Tipo de Deducción",
              Code: "Código",
              Description: "Descripción",
              Amount: "Cantidad",
            },
          ],
        },
        OtherPayments: {
          Details: [],
        },
      },
    },
  };

  function validateData(data) {
    let missingValues = [];

    if (!data.NameId) {
      missingValues.push("NameId");
    }
    if (!data.ExpeditionPlace) {
      missingValues.push("ExpeditionPlace");
    }
    if (!data.CfdiType) {
      missingValues.push("CfdiType");
    }
    if (!data.PaymentForm) {
      missingValues.push("PaymentForm");
    }
    if (!data.PaymentMethod) {
      missingValues.push("PaymentMethod");
    }
    if (!data.Folio) {
      missingValues.push("Folio");
    }
    if (!data.Receiver.Rfc) {
      missingValues.push("Receiver.Rfc");
    }
    if (!data.Receiver.Name) {
      missingValues.push("Receiver.Name");
    }
    if (!data.Receiver.CfdiUse) {
      missingValues.push("Receiver.CfdiUse");
    }
    if (!data.Complemento.Payroll.Type) {
      missingValues.push("Complemento.Payroll.Type");
    }
    if (!data.Complemento.Payroll.PaymentDate) {
      missingValues.push("Complemento.Payroll.PaymentDate");
    }
    if (!data.Complemento.Payroll.InitialPaymentDate) {
      missingValues.push("Complemento.Payroll.InitialPaymentDate");
    }
    if (!data.Complemento.Payroll.FinalPaymentDate) {
      missingValues.push("Complemento.Payroll.FinalPaymentDate");
    }
    if (!data.Complemento.Payroll.DaysPaid) {
      missingValues.push("Complemento.Payroll.DaysPaid");
    }
    if (!data.Issuer.EmployerRegistration) {
      missingValues.push("Issuer.EmployerRegistration");
    }
    if (!data.Issuer.Rfc) {
      missingValues.push("Issuer.Rfc");
    }
    if (!data.Complemento.Payroll.Employee.Curp) {
      missingValues.push("Complemento.Payroll.Employee.Curp");
    }
    if (!data.Complemento.Payroll.Employee.SocialSecurityNumber) {
      missingValues.push("Complemento.Payroll.Employee.SocialSecurityNumber");
    }
    if (!data.Complemento.Payroll.Employee.StartDateLaborRelations) {
      missingValues.push(
        "Complemento.Payroll.Employee.StartDateLaborRelations"
      );
    }
    if (!data.Complemento.Payroll.Employee.ContractType) {
      missingValues.push("Complemento.Payroll.Employee.ContractType");
    }
    if (!data.Complemento.Payroll.Employee.RegimeType) {
      missingValues.push("Complemento.Payroll.Employee.RegimeType");
    }
    if (typeof data.Complemento.Payroll.Employee.Unionized !== "boolean") {
      missingValues.push("Complemento.Payroll.Employee.Unionized");
    }
    if (!data.Complemento.Payroll.Employee.TypeOfJourney) {
      missingValues.push("Complemento.Payroll.Employee.TypeOfJourney");
    }
    if (!data.Complemento.Payroll.Employee.EmployeeNumber) {
      missingValues.push("Complemento.Payroll.Employee.EmployeeNumber");
    }
    if (!data.Complemento.Payroll.Employee.Department) {
      missingValues.push("Complemento.Payroll.Employee.Department");
    }
    if (!data.Complemento.Payroll.Employee.Position) {
      missingValues.push("Complemento.Payroll.Employee.Position");
    }
  }

  function checkData(data) {
    const emptyFields = [];
    for (const key in data) {
      if (typeof data[key] === "object" && data[key] !== null) {
        const result = checkData(data[key]);
        if (result.length > 0) {
          emptyFields.push(...result);
        }
      } else if (data[key] === "" || data[key] === null) {
        emptyFields.push(key);
      }
    }
    return emptyFields;
  }

  function checkData2(data, path = "", emptyFields = []) {
    for (const key in data) {
      const newPath = path === "" ? key : `${path}.${key}`;
      if (typeof data[key] === "object" && data[key] !== null) {
        checkData2(data[key], newPath, emptyFields);
      } else if (data[key] === "" || data[key] === null) {
        emptyFields.push(newPath);
      }
    }
    return emptyFields;
  }

  const deep_value = (obj, peth) => {
    for (let i = 0, path = peth.split("."); i < path.length; i++) {
      obj = obj[path[i]];
    }
    return obj;
  };

  const handleTimbrado = async (cfdi) => {
    let emptyFR = checkData2(cfdi);
    let y = [];

    for (const route in emptyFR) {
      y.push(deep_value(keyMap, emptyFR[route]));
    }

    const MissingList = () => {
      return (
        <Grid container maxWidth={"md"} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" color="error.main">
              Falta información en los siguientes campos:
            </Typography>
          </Grid>
          {y.map((MF, idx /*Missing Field, mofoca */) => {
            if (typeof MF !== "object")
              return (
                <Grid key={idx} item xs={3}>
                  <Typography>-{MF}</Typography>
                </Grid>
              );
          })}
        </Grid>
      );
    };

    if (emptyFR.length !== 0) {
      setDialog({
        open: true,
        title: "Datos Faltantes",
        content: MissingList(),
        actions: [
          {
            label: "ok",
            execute: () => {
              setDialog({ ...dialog, open: false });
            },
          },
        ],
      });
    } else {
      issueInvoice(userid, userid, cfdi, true, getTypeOfInvoice(cfdi))
        .then((response) => {
          console.log("respuesta timbrado try", response);
        })
        .catch((err) => {
          if (err.response) {
            console.log("err response", err.response);
            return;
          }
          if (err.request) {
            console.log("err req", err.response.request);
            return;
          }
          if (err.message) {
            console.log("err msg", err.response.message);
            return;
          }
        });
    }
  };

  const [incomplete, setIncomplete] = useState({
    perceptions: false,
    deductions: false,
  });

  useEffect(() => {
    if (commonPerceptions.length === 0) {
      incomplete.perceptions = true; //setIncomplete({...incomplete,perceptions:true})
    } else {
      incomplete.perceptions = false;
    }
    if (commonDeductions.length === 0) {
      setIncomplete({ ...incomplete, deductions: true });
    } else {
      setIncomplete({ ...incomplete, deductions: false });
    }
  }, [commonPerceptions.length, commonDeductions.length]);

  const handlePreview = () => {
    console.log("incomplete", incomplete);

    if (incomplete.perceptions || incomplete.deductions) {
      setDialog2({
        ...dialog2,
        open: true,
        title: "Información requerida",
        content: (
          <>
            {incomplete.perceptions ? (
              <Typography>-Se requiere al menos una percepción</Typography>
            ) : null}

            {incomplete.deductions ? (
              <Typography>-Se requiere al menos una deducción</Typography>
            ) : null}
          </>
        ),
        actions: [
          {
            label: "Cerrar y Corregir",
            execute: () => {
              setDialog2({
                ...dialog,
                open: false,
              });

              //setModal({
              //    open:true,
              //    leftValues:employeeObject.ConfigStatus.leftValues
              //})
            },
          },
        ],
      });
    } else {
      setDialog2({ ...dialog2, open: false });
    }

    //I'm second guessing this implementation
    //seems pretty well handled and not likely to crash at this
    //point, but needs some validations before assigning the values
    //once its in production

    //This object has everything SPECIFIC to PAYROLL
    //needed in the invoice
    let initNom = {
      Receiver: "",
      Issuer: "",
      Complemento: {
        Payroll: {
          Employee: {},
          Perceptions: { Details: [] },
          Deductions: { Details: [] },
          OtherPayments: { Details: [] },
        },
      },
    };
    initNom.Receiver = receiver;
    initNom.Issuer = issuer;
    initNom.Complemento.Payroll = payrollGeneral;
    initNom.Complemento.Payroll.Employee = employee;

    initNom.Complemento.Payroll.Perceptions.Details = commonPerceptions;
    initNom.Complemento.Payroll.Deductions.Details = commonDeductions;
    initNom.Complemento.Payroll.OtherPayments.Details = hasOtherPayments
      ? commonPayments
      : [];

    //This is what the full invoice will look like
    let x = Object.assign(datosCfdi, initNom);
    console.log("full invoice test", x);

    setDialog({
      open: !(incomplete.perceptions || incomplete.deductions),
      title: "Previsualizar",
      content: (
        <>
          <PreviewCFDI CFDI={x} />
        </>
      ),
      actions: [
        {
          label: "Cerrar y Corregir",
          execute: () => {
            setDialog({
              ...dialog,
              open: false,
            });
          },
        },
        {
          label: "Aceptar y Timbrar",
          execute: () => {
            handleTimbrado(x);
          },
        },
      ],
    });

    console.log("issuer in selected", selected.issuer);

    console.log(JSON.stringify(x));
  };

  useEffect(() => {
    datosCfdi.PaymentMethod = selected.paymentMethod.Value;
  }, [selected.paymentMethod]);

  //set fechas from component into state
  useEffect(() => {
    payrollGeneral.InitialPaymentDate = fechas.InitialPaymentDate;
    payrollGeneral.FinalPaymentDate = fechas.FinalPaymentDate;
    payrollGeneral.DaysPaid = fechas.DaysPaid;
    payrollGeneral.PaymentDate = fechas.PaymentDate;
  }, [fechas]);

  useEffect(() => {
    issuer.Rfc = selected.issuer.Rfc;
    issuer.Name = selected.issuer.Name;
    issuer.Curp = selected.issuer.CURP;
    issuer.FiscalRegime = selected.issuer.FiscalRegime;
    setIssuer({ ...issuer });
  }, [selected.issuer]);

  useEffect(() => {
    issuer.EmployerRegistration = selected.issuer.EmployerRegistration;
    setIssuer({ ...issuer });
  }, [selected.issuer.EmployerRegistration]);

  //Effects to control the behavior of children components,
  //doesnt affect the app process
  /* useEffect(()=>{
        console.log("datos cfdi", datosCfdi)
        console.log("receiver", receiver)
        console.log("oayroll general", payrollGeneral)
        console.log("selected",selected)

    },[datosCfdi,receiver,payrollGeneral])
    useEffect(()=>{
        console.log("employee state",employee)
    },[employee])

    useEffect(()=>{
        console.log("fechas state",fechas)
    },[fechas])

    useEffect(()=>{
        console.log("receiver", receiver)
    },[receiver])

    useEffect(()=>{
        console.log("selected",selected)
    },[selected])
*/

  //*Data fetching functions
  const getData = () => {
    let reqErrors = [];

    return Promise.all([
      //*Métodos de pago
      getPaymentMethods(userid, userid.token)
        .then((response) => {
          setCfdiOptions({ ...cfdiOptions, paymentMethod: response.data });
        })
        .catch((err) => {
          if (err.response || err.request || err.message) {
            reqErrors.push("Métodos de Pago");
          }
        }),

      //*Lugares de expedición
      getExpeditionPLC(userid, userid.token)
        .then((response) => {
          console.log("plc", response);
          setCfdiOptions((options) => {
            return { ...options, place: response.data };
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response || err.request || err.message) {
            reqErrors.push("Lugares de expedición");
          }
        }),
    ]);
  };

  useEffect(() => {
    /*
    getOptions()
      .then((res) => {
        console.log("res get options",res)
        setLoading((loading) => {
          return {
            ...loading,
            nameId: false,
            cfdiOptions: {
              ...loading.cfdiOptions,
              paymentForm: false,
              paymentMethod: false,
              uses: false,
            },
            place: false,
          };
        });
      })
      .catch((err) => {
        console.log("el error",err);
        setAlert((alert) => {
          return {
            ...alert,
            open: true,
            message:
              "Error obteniendo alguna de las opciones del catálogo del sat",
            status: "error",
          };
        });
      });
      */
    setLoading2(true);
    getData()
      .then((resolved) => {
        console.log("q di c", resolved, cfdiOptions);
        setLoading2(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading2(false);
        setAlert((alert) => {
          return {
            ...alert,
            open: true,
            message:
              "Error obteniendo alguna de las opciones del catálogo del sat",
            status: "error",
          };
        });
      });
  }, []);

  useEffect(() => {
    if (cfdiOptions.place.length !== 0) {
      const newSelected = { ...selected, place: cfdiOptions.place[0] };
      setSelected(newSelected);
      setDatosCfdi((datosCfdi) => {
        return { ...datosCfdi, ExpeditionPlace: cfdiOptions.place[0]?.ZipCode };
      });
    }
  }, [cfdiOptions.place]);

  useEffect(() => {
    if (nominaOrdinaria) {
      setPayrollGeneral({ ...payrollGeneral, Type: "O" });
      setCommonPerceptions([
        {
          PerceptionType: "001",
          Code: "001",
          Description: "Sueldos, Salarios  Rayas y Jornales",
          TaxedAmount: 0,
          ExemptAmount: 0,
        },
      ]);

      setCommonDeductions([
        {
          DeduccionType: "001",
          Code: "IMSS",
          Description: "Seguridad Social",
          Amount: 0,
        },
        {
          DeduccionType: "002",
          Code: "ISR",
          Description: "Impuesto Sobre la Renta",
          Amount: 0,
        },
        {
          DeduccionType: "003",
          Code: "RETIRO",
          Description: "Aportacion a retiro",
          Amount: 0,
        },
      ]);
    } else {
      setPayrollGeneral({ ...payrollGeneral, Type: "E" });
      setCommonPerceptions([
        {
          PerceptionType: "002",
          Code: "002",
          Description: "Gratificación Anual (Aguinaldo)",
          TaxedAmount: 0,
          ExemptAmount: 0,
        },
      ]);

      setCommonDeductions([]);
    }
  }, [nominaOrdinaria]);

  const finishedLoading = () => {
    if (
      loading.cfdiOptions.uses ||
      loading.cfdiOptions.paymentForm ||
      loading.cfdiOptions.paymentMethod ||
      loading.nameId ||
      loading.place
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* EXTRAS UI
            (Backdrop, dialog, snackbar, etc)
        */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading2}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        open={dialog.open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.actions.map((action, index) => {
            return (
              <Button key={index} variant="contained" onClick={action.execute}>
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialog2.open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>{dialog2.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog2.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog2.actions.map((action, index) => {
            return (
              <Button key={index} variant="contained" onClick={action.execute}>
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>

      <Snackbar open={alert.open} onClose={handleCloseAlert}>
        <Alert severity={alert.open ? alert.status : "info"} variant="filled">
          {alert.message}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "6vh",
        }}
      >
        {/* //*Este componente se puede optimizar para consumir menos recursos pues al ser un componente exclusivo de la expedición de CFDI
            //*de nómina, entonces el nameId y el type siempre son iguales */}

        {/* En eso andamios, como el objeto y los campos son relativamente estáticos
                se concentraron esfuerzos por eliminar el contexto y la dependencia que este generaba.
                Los componentes hijos también han sido declarados con la posibilidad de ser usados 
                y controlados por componentes padre, sin "tronar" aunque no se les declare los 
                estados para controlar información en el componente padre     
            */}

        {/* Se eliminó el selector de nómina que quedaba (LEGACY) */}

        {/* INICIA GRID DE INPUTS */}
        <Grid
          container
          maxWidth={"xl"}
          alignItems="stretch"
          spacing={3}
          sx={{ padding: 2, height: "100%" }}
        >
          <Grid item xs={12} md={9} textAlign="left">
            <Typography variant="h4" color="primary">
              Factura Recibo de Nómina
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sx={{ alignItems: "center" }}>
            <Box display="flex" flexDirection={"row"}>
              <Typography variant="body1" color="primary">
                {"Extraordinaria"}
              </Typography>
              <Switch
                checked={nominaOrdinaria}
                onChange={() => setNominaOrdinaria(!nominaOrdinaria)}
                inputProps={{ "aria-label": "controlled" }}
                size="small"
              />
              <Typography variant="body1" color="primary">
                {"Ordinaria"}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ minHeight: "100%" }}>
            <Grid container spacing={2} sx={{ padding: 2, minHeight: "100%" }}>
              <Grid item xs={12} md={4} sx={{ minHeight: "100%" }}>
                <Grid
                  container
                  spacing={1}
                  component={Paper}
                  sx={{ padding: 2, height: "100%" }}
                >
                  <Grid item xs={12}>
                    <Divider
                      variant="fullWidth"
                      flexItem
                      alignItems="left"
                      textAlign="left"
                    >
                      <Typography
                        variant="h6"
                        sx={{ marginBottom: 1 }}
                        color="secondary.dark"
                      >
                        Emisor
                      </Typography>
                    </Divider>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectIssuersAutocomplete
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      label="Registro del patrón"
                      required
                      defaultValue={issuer !== undefined ? issuer.Folio : ""}
                      onChange={(e, v, r) => {
                        //console.log("registro del patron", e.target.value);
                        selected.issuer.EmployerRegistration = e.target.value;
                        setSelected({ ...selected });
                      }}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={4} sx={{ minHeight: "100%" }}>
                {/* INICIA DATOS DEL COMPROBANTE */}

                <Grid
                  container
                  spacing={1}
                  component={Paper}
                  sx={{ padding: 2, minHeight: "100%" }}
                >
                  <Grid item xs={12}>
                    <Divider
                      variant="fullWidth"
                      flexItem
                      alignItems="left"
                      textAlign="left"
                    >
                      <Typography
                        variant="h6"
                        color="secondary.dark"
                        sx={{ marginBottom: 1 }}
                      >
                        Datos del comprobante
                      </Typography>
                    </Divider>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      variant="standard"
                      label="Folio"
                      defaultValue={issuer !== undefined ? issuer.Folio : ""}
                      onChange={handleSetFolio}
                      sx={{ width: "100%" }}
                    />
                  </Grid>

                  <Grid item xs={8}>
                    <Autocomplete
                      sx={{ width: "100%" }}
                      options={cfdiOptions.place}
                      getOptionLabel={(option) => {
                        return `${option.Name}`;
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            sx={{ width: "100%" }}
                            label="Lugar de expedición"
                            required
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        );
                      }}
                      onChange={(e, v, r) => {
                        if (r === "clear") {
                          setSelected({ ...selected, place: "" });
                          setDatosCfdi({
                            ...datosCfdi,
                            ExpeditionPlace: "",
                          });
                        } else {
                          setSelected({ ...selected, place: v });
                          setDatosCfdi({
                            ...datosCfdi,
                            ExpeditionPlace: v.ZipCode,
                          });
                        }
                      }}
                      value={
                        cfdiOptions.place?.length > 0
                          ? cfdiOptions.place[0]
                          : { Name: "Cargando..." }
                      }
                    />
                  </Grid>

                  {/* <Grid item xs={12}>
                            <Autocomplete
                                autoComplete
                                sx={{width:"100%"}}
                                options={cfdiOptions.paymentForm}
                                getOptionLabel={(option) =>{
                                    return `${option.Name}`
                                }}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                        {...params}
                                        sx={{width:"100%"}}
                                        label="Forma de pago"
                                        variant="standard"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment:(
                                                <React.Fragment>
                                                    {loading.cfdiOptions.paymentForm ? <CircularProgress/> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            )
                                        }}
                                        />
                                    )
                                }}
                                isOptionEqualToValue={(option, value) => {
                                    return option.Name === value.Name
                                }}
                                defaultValue={NominaModel[step]["dataModel"]["PaymentForm"]}
                                onChange={(e,v,r) => {
                                    switch(r){
                                        case "selectOption":
                                            NominaModel[step].dataModel["PaymentForm"] = v
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                            />
                        </Grid> */}

                  <Grid item xs={12}>
                    <Autocomplete
                      autoComplete
                      sx={{ width: "100%" }}
                      options={cfdiOptions.paymentMethod}
                      getOptionLabel={(option) => {
                        return `${option.Name}`;
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            sx={{ width: "100%" }}
                            label="Método de pago"
                            required
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {/*loading.cfdiOptions.paymentMethod ? <CircularProgress/> : null*/}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        );
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.Name === value.Name
                      }
                      value={selected.paymentMethod}
                      onChange={(e, v, r) => {
                        switch (r) {
                          case "selectOption":
                            selected.paymentMethod = v;
                            setSelected({ ...selected });
                            break;
                          case "clear":
                            selected.paymentMethod = { Name: "", Value: "" };
                            setSelected({ ...selected });
                            break;
                          default:
                            break;
                        }
                      }}
                    />
                  </Grid>
                </Grid>

                {/* TERMINA DATOS DEL COMPROBANTE */}
              </Grid>

              {/* INICIA DATES Periodo nominal*/}
              <Grid item xs={12} md={4} sx={{ minHeight: "100%" }}>
                <NominaDateWithType
                  fechasState={fechas}
                  setFechas={setFechas}
                />
              </Grid>

              {/* TERMINA DATES Periodo nominal*/}
            </Grid>
          </Grid>

          {/* INICIA EMPLEADO RECEPTOR */}
          <Grid item xs={12}>
            <Grid container spacing={1} sx={{ padding: 2, height: "100%" }}>
              <EmployeeForm
                employee={employee}
                setEmployee={setEmployee}
                setReceiver={setReceiver}
                setLoading={setLoading2}
              />
            </Grid>
          </Grid>
          {/* TERMINA EMPLEADO RECEPTOR */}

          {/* INICIA PERCEPTIONS PERCEPCIONES */}
          <Grid item xs={12} md={6}>
            <PerceptionsTable
              key={commonPerceptions[commonPerceptions.length - 1]}
              perceptionsArray={commonPerceptions}
              setPerceptionArray={setCommonPerceptions}
            />
          </Grid>

          {/* TERMINA PERCEPTIONS PERCEPCIONES */}

          {/* INICIA DEDUCTIONS DEDUCCIONES */}
          <Grid item xs={12} md={6}>
            <DeductionsTable
              deductionsArray={commonDeductions}
              setDeductionsArray={setCommonDeductions}
            />
          </Grid>

          {/* TERMINA DEDUCTIONS DEDUCCIONES*/}

          {/* INICIA OTROS PAGOS */}

          <Grid item xs={12} md={6}>
            {!!hasOtherPayments && (
              <OtherPaymentsTable
                otherPaymentsArray={commonPayments}
                setOtherPaymentssArray={setCommonPayments}
              />
            )}
          </Grid>

          <Grid item xs={6} sx={{ marginBottom: "4vh" }}>
            <FormControlLabel
              control={
                <Checkbox
                  name={"otrosPagos"}
                  checked={hasOtherPayments}
                  onChange={handleWithOtherPayments}
                />
              }
              label="Añadir Otros Pagos"
              labelPlacement="end"
            />
          </Grid>

          {/* TERMINA OTROS PAGOS */}

          {/* FINALIZAR */}

          <Grid item xs={6} md={8}></Grid>
          <Grid item xs={6} md={4}>
            <Typography color="primary.main">
              Total Percibido: ${total}
            </Typography>
            <Button variant="contained" color="primary" onClick={handlePreview}>
              Aceptar y previsualizar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
