//*React
import React, { useState } from "react";
//*MUI
import { Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { daysToMonday } from "../../../utils/dates";

//*Date ISO formatter
export const toIsoHelper = (date) => {
  var pad = function (num) {
    var norm = Math.floor(Math.abs(num));
    return (norm < 10 ? "0" : "") + norm;
  };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds())
  );
};

export const NominaDateWithType = ({
  setFechas = () => {
    console.log("set fechas component");
  },
  fechasState = {
    PaymentDate: null,
    InitialPaymentDate: null,
    FinalPaymentDate: null,
    DaysPaid: null,
  },
  defaultDays = 7,
}) => {
  const initialDate = () => {
    return new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - daysToMonday()
    );
  };
  const [daysValue, setDaysValue] = useState(defaultDays);

  const dates = {
    starts: new Date(fechasState.InitialPaymentDate) || initialDate(),
    final: fechasState.FinalPaymentDate
      ? new Date(fechasState.FinalPaymentDate)
      : new Date(
          initialDate().getFullYear(),
          initialDate().getMonth(),
          initialDate().getDate() + (daysValue - 1)
        ),
    pay: new Date(fechasState.PaymentDate) || new Date(),
    days: new Date(fechasState.DaysPaid) || 7,
  };

  const handleDaysValue = (e) => {
    let value = Number(e.target.value);
    setDaysValue(value);
    setFechas({
      ...fechasState,
      DaysPaid: value,
      FinalPaymentDate: new Date(
        dates.starts.getFullYear(),
        dates.starts.getMonth(),
        dates.starts.getDate() + value - 1
      ),
    });
  };

  const handleChangeStartDate = (newValue) => {
    setFechas({
      ...fechasState,
      InitialPaymentDate: newValue,
      FinalPaymentDate: new Date(
        newValue.getFullYear(),
        newValue.getMonth(),
        newValue.getDate() + daysValue - 1
      ),
    });
  };

  const handleDayPay = (newValue) => {
    setFechas({
      ...fechasState,
      PaymentDate: newValue,
    });
  };

  return (
    <Grid
      container
      spacing={1}
      columns={18}
      sx={{ padding: 2, alignItems: "stretch", height: "100%" }}
      component={Paper}
    >
      <Grid item xs={18}>
        <Divider
          variant="fullWidth"
          flexItem
          alignItems="left"
          textAlign="left"
        >
          <Typography
            variant="h6"
            color="secondary.dark"
            sx={{ marginBottom: 1 }}
          >
            Periodo nominal
          </Typography>
        </Divider>
      </Grid>
      <Grid item xs={4}>
        <TextField
          variant="standard"
          label="Días pagados"
          value={daysValue}
          type="number"
          onChange={handleDaysValue}
        />
      </Grid>
      <Grid item xs={7}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            id="d_date"
            label="Inicio"
            slotProps={{ textField: { variant: "standard" } }}
            sx={{ width: "100%" }}
            value={dates.starts}
            onChange={handleChangeStartDate}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={7}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disabled
            id="d_date"
            slotProps={{ textField: { variant: "standard" } }}
            sx={{ width: "100%" }}
            label="Fin"
            value={dates.final}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item xs={18}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            id="d_date"
            slotProps={{ textField: { variant: "standard" } }}
            sx={{ width: "100%" }}
            label="Fecha del pago"
            value={dates.pay}
            onChange={handleDayPay}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};
