import React, {useState, useEffect, useCallback, forwardRef} from "react";
import {
  TextField,
  Autocomplete,
  Typography,
  CircularProgress,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Grid,
  Box,
  Stack,
} from "@mui/material";
import {AlertDialog} from "../../userFeedback/AlertDialog.jsx";
import useAuth from "../../../hooks/useAuth.js";
import {useNavigate} from "react-router";
import {getUses} from "../../../services/modules/invoices/catalogs.js";
//*INVOICES OBJECTS
import invoiceJson from "../../../modules/invoices/utils/invoiceModels.json";
import {stepsObjects} from "../../../modules/invoices/utils/stepsObjects.js";
import {FileUploadButton, XMLinvoice} from "../CartaPorte/UploadXMLCCP.jsx";
import {isSATCodesUsesInput} from "../../../modules/invoices/utils/regexp.js";
import CFDI_USES from "../../../utils/enum.js/CfdiUses.js";

const NewInvoiceGeneralData = forwardRef((props, ref) => {
  const invoiceModel = {
    GeneralModel: {
      CfdiType: {},
      PaymentForm: "",
      PaymentMethod: "",
      ExpeditionPlace: "",
      Date: "",
      Folio: "",
    },
    Models: {
      I: {
        Items: [],
      },
      E: {
        Items: [],
      },
    },
    Complementos: {
      CartaPorte: {
        TranspInternac: "No",
        Ubicaciones: [
          {
            TipoUbicacion: "Origen",
            RFCRemitenteDestinatario: "",
            FechaHoraSalidaLlegada: "",
            Domicilio: {
              Pais: "MEX",
              CodigoPostal: "",
              Estado: "",
              Municipio: "",
              Localidad: "",
              Colonia: "",
              Calle: "",
            },
          },
          {
            TipoUbicacion: "Destino",
            RFCRemitenteDestinatario: "",
            FechaHoraSalidaLlegada: "",
            DistanciaRecorrida: "1",
            Domicilio: {
              Pais: "MEX",
              CodigoPostal: "",
              Estado: "",
              Municipio: "",
              Localidad: "",
              Colonia: "",
              Calle: "",
            },
          },
        ],
        Mercancias: {
          UnidadPeso: "",
          PesoBrutoTotal: 0,
          NumTotalMercancias: 0,
          Mercancia: [],
          Autotransporte: [],
        },
        FiguraTransporte: [],
      },
      Payroll: {
        Type: "",
        PaymentDate: "",
        InitialPaymentDate: "",
        FinalPaymentDate: "",
        DaysPaid: 0,
        Issuer: {
          EmployerRegistration: "",
        },
        Employee: {
          Curp: "",
          SocialSecurityNumber: "",
          StartDateLaborRelations: "",
          ContractType: "",
          RegimeType: "",
          Unionized: false,
          TypeOfJourney: "",
          EmployeeNumber: "",
          Department: "",
          Position: "",
          PositionRisk: "",
          FrequencyPayment: "",
          Bank: "",
          BankAccount: "",
          BaseSalary: 0,
          DailySalary: 0,
          FederalEntityKey: "",
        },
        Perceptions: {
          Details: [
            {
              PerceptionType: "",
              Code: "",
              Description: "",
              TaxedAmount: 0,
              ExemptAmount: 0,
            },
          ],
        },
        Deductions: {
          Details: [
            {
              DeduccionType: "",
              Code: "",
              Description: "",
              Amount: 0,
            },
            {
              DeduccionType: "",
              Code: "",
              Description: "",
              Amount: 0,
            },
            {
              DeduccionType: "",
              Code: "",
              Description: "",
              Amount: 0,
            },
          ],
        },
        OtherPayments: [
          {
            OtherPaymentType: "",
            Code: "",
            Description: "",
            Amount: 0,
            EmploymentSubsidy: {
              Amount: 0,
            },
          },
        ],
      },
      Payments: [
        {
          Date: "",
          PaymentForm: "",
          Amount: "",
          RelatedDocuments: [
            {
              Uuid: "",
              Folio: "",
              PaymentMethod: "",
              PartialityNumber: "",
              PreviousBalanceAmount: "",
              AmountPaid: "",
            },
          ],
        },
      ],
    },
  };

  //*DATA CONTEXTS HOOKS
  const {userid, logout} = useAuth();
  //*DATA STATES
  const [options, setOptions] = useState({
    typesList: [
      {Name: "Factura", Type: "I", Value: "1"},
      {Name: "Recibo de Pago", Type: "P", Value: "18"},
    ],
    usesOptions: [],
  });
  const [usesInput, setUsesInput] = useState("");

  //*RENDER STATES
  const [selected, setSelected] = useState({
    type: "",
    use: {Value: "", Name: ""},
    complement: "",
  });
  const [loading, setLoading] = useState({
    nameIds: true,
    uses: true,
  });
  const [error, setError] = useState({
    type: {
      failed: false,
      message: "",
    },
    use: {
      failed: false,
      message: "",
    },
    complement: {
      failed: false,
      message: "",
    },
  });
  const [withComplement, setWithComplement] = useState(
    selected.complement !== undefined && selected.complement !== ""
  );
  const [renderComplementInput, setRenderComplementInput] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    message: "",
    actions: [{label: "", execute: () => {}}],
    keep: false,
  });

  const navigate = useNavigate();

  const handleUsesInput = (e) => {
    setUsesInput(e.target.value);
  };

  //steps begin
  const getUseFromType = (type) => {
    switch (type.Name) {
      case "Recibo de Nómina":
        setSelected({...selected, use: options.usesOptions[0]});
        return options.usesOptions[0];
      default:
        return "";
    }
  };

  const handleShowDialog = (bool) => {
    setDialog({
      ...dialog,
      open: bool,
    });
  };

  const handleTypeSelection = (v) => {
    if (v && v.Type === "I") {
      //*If type selected === I, then show payment methopds and forms autocomplete input

      setWithComplement(false);
      //*TODO: CHANGE THIS TO EXTRACT THE DATA FROM THE CATALOG, NOT FROM THE ENUM
      setSelected({
        ...selected,
        use: CFDI_USES.GASTOS_GENERAL,
        type: v,
        complement: "",
      });
      return;
    }

    if (v && v.Type === "P") {
      //*If type selected === P, then configure the next fields

      setSelected({
        ...selected,
        use: CFDI_USES.PAGOS,
        type: v,
        complement: "De pago",
      });

      setWithComplement(true);
      return;
    }
  };

  const handleChangeSelection = useCallback((e, v, r) => {
    const id = e.target.id.split("-")[0];

    switch (r) {
      case "selectOption":
        if (error[id].failed) {
          setError({
            ...error,
            [id]: {
              failed: false,
              message: "",
            },
          });
        }

        setSelected({
          ...selected,
          [id]: v,
        });

        if (id === "type") {
          handleTypeSelection(v);
        }
        break;
      case "input":
        setSelected({
          ...selected,
          [id]: v,
        });
        break;

      default:
        break;
    }
  });

  const mapErrors = (values) => {
    const errors = [];
    let errorMapped = {};

    const checkField = (field) => {
      return field === "" || field === undefined || field === null;
    };

    const mapNestedErrors = (nestedValues) => {
      if (nestedValues) {
        const {use} = nestedValues;
        if (use && (checkField(use.Value) || checkField(use.Name))) {
          errors.push(["use", use]);
        }

        Object.entries(nestedValues).forEach((entry) => {
          if (typeof entry[1] === "object" && entry[0] !== "use") {
            mapNestedErrors(entry[1]);
          } else {
            if (checkField(entry[1])) {
              errors.push(entry);
            }
          }
        });
      }
    };

    // Exclude payment-related fields from Object.entries
    Object.entries(values).forEach((entry) => {
      const fieldName = entry[0];
      const fieldValue = entry[1];

      if (fieldName === "use") {
        if (checkField(values.use.Name) && checkField(values.use.Value)) {
          errors.push(entry);
        }
      } else {
        if (checkField(fieldValue)) {
          if (fieldName === "complement" && !withComplement) {
            return;
          }
          errors.push(entry);
        }
      }
    });

    if (errors.length > 0) {
      errors.forEach((err) => {
        errorMapped = {
          ...errorMapped,
          [err[0]]: {
            failed: true,
            message: "Corrige este valor.",
          },
        };
      });
    }

    return {errors, errorMapped};
  };

  const addGeneralToInvoiceModel = () => {
    try {
      //*General data
      invoiceModel.GeneralModel.CfdiType = selected.type;

      invoiceModel.Models = {
        [selected.type.Type]: invoiceJson.Models[selected.type.Type],
      };

      invoiceModel.GeneralModel.Receiver = {
        CfdiUse: selected.use,
      };

      if (selected.type.Type === "I") {
        delete invoiceModel.Models.E;
      }

      if (selected.type.Type === "P") {
        delete invoiceModel.Models.E;
        delete invoiceModel.Models.I;
      }

      if (selected.type.Type === "E") {
        delete invoiceModel.Models.I;
      }

      if (withComplement) {
        //*If it is required, the complement must be added to the invoice object
        if (
          selected.complement === "Carta Porte" &&
          Object.keys(XMLinvoice).length > 0
        ) {
          XMLinvoice.Mercancias.Mercancia.forEach((object, index) => {
            XMLinvoice.Mercancias.Mercancia[
              index
            ].BienesTransp = `${object.BienesTransp}`;
          });

          invoiceModel.Complementos.CartaPorte = {
            ...invoiceModel.Complementos.CartaPorte,
            Mercancias: {
              ...invoiceModel.Complementos.CartaPorte.Mercancias,
              Mercancia: XMLinvoice.Mercancias.Mercancia,
            },
          };

          delete invoiceModel.Complementos.Payroll;
          delete invoiceModel.Complementos.Payments;
          return;
        }

        if (selected.complement === "Carta Porte") {
          invoiceModel.Complementos.CartaPorte =
            invoiceJson.Complementos.CartaPorte;
          delete invoiceModel.Complementos.Payroll;
          delete invoiceModel.Complementos.Payments;
          return;
        }

        if (selected.complement === "De pago") {
          invoiceModel.Complementos.Payments =
            invoiceJson.Complementos.Payments;
          delete invoiceModel.Complementos.CartaPorte;
          delete invoiceModel.Complementos.Payroll;
          return;
        }
      } else {
        delete invoiceModel.Complementos.Payments;
        delete invoiceModel.Complementos.CartaPorte;
        delete invoiceModel.Complementos.Payroll;
      }
    } catch (err) {
      console.log("Error while mapping data to invoice model json");
      console.log(err);
    }
  };

  const handleInvoiceSteps = () => {
    const {errors, errorMapped} = mapErrors(selected);

    if (errors.length > 0) {
      setError({
        ...error,
        ...errorMapped,
      });
      setDialog({
        open: true,
        title: "Datos faltantes",
        message:
          "Revisa la configuración, puede que falte información en el paso anterior",
        actions: [
          {
            label: "ok",
            execute: () => {
              setDialog({...dialog, open: false});
            },
          },
        ],
      });
      return;
    }

    try {
      addGeneralToInvoiceModel();
      window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceModel));
    } catch (err) {
      setDialog({
        ...dialog,
        open: true,
        title: "Hubo un error al mapear la configuracion.",
        message: "Contacta a soporte.",
      });
    }
    navigate(`/${userid.claims.rol}/invoices/new/steps`);
  };

  const getData = () => {
    setLoading((obj) => {
      return {...obj, nameIds: false, uses: true};
    });
    getUses(userid)
      .then(({data}) => {
        setLoading((obj) => {
          return {...obj, nameIds: false, uses: false};
        });
        setOptions((obj) => {
          return {...obj, usesOptions: data};
        });
      })
      .catch((err) => {
        setLoading((obj) => {
          return {...obj, nameIds: false, uses: false};
        });
        setError((obj) => {
          return {
            ...obj,
            use: {
              failed: true,
              message: "No se pudieron obtener los usos del CFDI",
            },
          };
        });
      });
  };

  const getComplements = (type) => {
    if (type.Type == "P") {
      return ["De pago"];
    }

    if (type.Type == "I") {
      return ["Carta Porte"];
    }

    return ["Carta Porte"];
  };

  const complements = getComplements(selected.type);

  const handleWithComplement = () => {
    setWithComplement(!withComplement);
    setRenderComplementInput(!withComplement);
    if (!withComplement) {
      setSelected({
        ...selected,
        complement: complements[0],
      });
    }

    if (withComplement && selected.complement !== "") {
      setSelected({
        ...selected,
        complement: "",
      });
    }
  };

  useEffect(async () => {
    getData();
    return () => {
      setDialog((d) => {
        return {
          ...d,
          open: false,
          title: "",
          message: "",
          actions: [],
        };
      });

      setLoading(() => {
        return {
          nameIds: false,
          uses: false,
        };
      });

      setOptions((options) => {
        return {
          ...options,
          typesList: [],
          usesOptions: [],
        };
      });
    };
  }, [userid, logout, navigate]);

  useEffect(() => {
    let GeneralDataCheck = JSON.parse(
      window.localStorage.getItem("invoiceModel")
    );
    if (GeneralDataCheck) {
      window.localStorage.removeItem("invoiceModel");
      window.localStorage.removeItem("relDocs");
      window.localStorage.removeItem("steps");
    }
  }, []);

  return (
    <>
      <AlertDialog
        updateOpenState={handleShowDialog}
        open={dialog.open}
        title={dialog.title}
        content={dialog.message}
        actions={dialog.actions}
        keep={dialog.keep}
      />
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} md={4} lg={4} xl={4}>
          <Paper
            variant="outlined"
            sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}
          >
            <Typography component="h1" variant="h4" align="center">
              Generar Factura
            </Typography>

            <div style={{outline: "none", marginTop: "2vh"}}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Tipo de operación
                  </Typography>
                  <Autocomplete
                    id="type"
                    sx={{width: "100%"}}
                    options={options.typesList}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          error={error.type.failed}
                          name="type"
                          helperText={error.type.message}
                          label="Nombre del CFDI"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading.nameIds ? (
                                  <CircularProgress size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      );
                    }}
                    getOptionLabel={(option) => {
                      return option.Name || "";
                    }}
                    onChange={(e, v, r) => {
                      switch (r) {
                        case "selectOption":
                          handleChangeSelection(e, v, r);
                          break;
                        case "clear":
                          setSelected({...selected, type: ""});
                          break;
                        default:
                          break;
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6" gutterBottom>
                    Uso
                  </Typography>
                  <Autocomplete
                    id="use"
                    sx={{width: "100%"}}
                    autoComplete
                    clearText="Limpiar"
                    onChange={(e, v, r) => {
                      switch (r) {
                        case "selectOption":
                          handleChangeSelection(e, v, r);
                          break;
                        case "clear":
                          setSelected({...selected, type: ""});
                          break;
                        default:
                          break;
                      }
                    }}
                    options={options.usesOptions}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          error={error.use.failed}
                          helperText={error.use.message}
                          label="Uso del CFDI"
                          name="Cfdiuse"
                          onChange={handleUsesInput}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading.uses ? (
                                  <CircularProgress size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      );
                    }}
                    getOptionLabel={(option) => {
                      if (isSATCodesUsesInput(usesInput)) {
                        return option.Value;
                      }

                      return option.Name;
                    }}
                    renderOption={(props, option, state) => {
                      return (
                        <li {...props}>{`${option.Name} - ${option.Value}`}</li>
                      );
                    }}
                    isOptionEqualToValue={(option, value) => {
                      if (value.Value === "" && value.Name === "") {
                        return true;
                      }
                      return (
                        option.Name === value.Name ||
                        option.Value === value.Value
                      );
                    }}
                    value={selected.use || getUseFromType(selected.type)}
                  />
                </Grid>
                <Grid item xs={12} sx={{marginBottom: "4vh"}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={"complemento"}
                        checked={withComplement}
                        onChange={handleWithComplement}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            handleWithComplement();
                          }
                        }}
                      />
                    }
                    label="Añadir Complemento"
                    labelPlacement="end"
                  />
                </Grid>

                {!!withComplement && (
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      sx={{width: "100%", marginY: "1vh"}}
                      id="complement"
                      options={complements}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            error={error.complement.failed}
                            helperText={error.complement.message}
                            label="Tipo de complemento"
                          />
                        );
                      }}
                      onChange={handleChangeSelection}
                      value={complements[0]}
                    />
                  </Grid>
                )}
              </Grid>
            </div>

            {!!(
              !!renderComplementInput && selected.complement === "Carta Porte"
            ) && (
              <Grid item xs={12}>
                <Stack alignItems="center" sx={{marginY: "1vh"}} spacing={2}>
                  <Typography variant="p" color="GrayText">
                    Puede seleccionar un archivo XML o aceptar la configuración
                    para completar la información manualmente
                  </Typography>
                  <FileUploadButton />
                </Stack>
              </Grid>
            )}

            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
              <Button
                variant="contained"
                focusRipple
                onClick={handleInvoiceSteps}
                sx={{mt: 3, ml: 1}}
              >
                Aceptar Configuración
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
});

export {NewInvoiceGeneralData};
