const relatedDocumentsDto = {
  officialId: "",
  serie: "",
  folio: "",
  currency: "",
  tipoCambio: "",
  parcialidad: "",
  saldoAnterior: "",
  total: "",
  saldoInsoluto: "",
  taxObject: {},
  taxes: [],
};

export { relatedDocumentsDto };
