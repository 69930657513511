import { Wrapper, Status } from "@googlemaps/react-wrapper";
import MapComponent from "./MapComponent";
import { CircularProgress } from "@mui/material";

const Loading = () => <CircularProgress />;

const ErrorComponent = () => (
  <div>Oops! Something went wrong while loading the map.</div>
);

const render = (status, location, directions) => {
  switch (status) {
    case Status.LOADING:
      return <Loading />;
    case Status.FAILURE:
      return <ErrorComponent />;
    case Status.SUCCESS:
      return <MapComponent location={location} directions={directions}/>;
  }
};

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const GoogleMapsWrapper = ({ location, directions }) => (
  <Wrapper
    apiKey={apiKey}
    render={(status) => render(status, location, directions)}
  />
);

export default GoogleMapsWrapper;
