import { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import {
  Box,
  Button,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  getAllServices,
  getServices,
} from "../../../services/modules/services/servicesGeneral";
import { DashboardDataGrid } from "../../invoices/dashboard/components/DashboardDatagrid";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import TimerIcon from "@mui/icons-material/Timer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Card } from "@material-ui/core";
import {
  AlertDialog,
  InitialDialogState,
} from "../../../components/userFeedback/AlertDialog";
import { ArrowCircleRight } from "@mui/icons-material";
import { formatDateDDMMYYYY } from "../../../utils/dateManipulators";
import { useNavigate } from "react-router";
import { DetailsComponent } from "./ServiceDetailsComponent";

export const OperatorsDashboardComponent = () => {
  const { userid } = useAuth();
  const navigate = useNavigate();
  const [operatorsRows, setOperatorsRows] = useState([]);

  const [activeServicesArray, setActiveServicesArray] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const OperatorServicesColumns = [
    {
      field: "Status",
      headerName: "Status",
      minWidth: 100,
      flex: 1,
      headerClassName: "header-table",
      renderCell: ({ value }) => {
        let renderObject = {
          color: "warning",
          state: "undefined",
          icon: <PendingIcon />,
        };
        switch (value) {
          case "AceptadoSF":
            renderObject.color = "warning";
            renderObject.state = value;
            renderObject.icon = <PendingIcon />;
            break;
          case "En curso":
            renderObject.color = "primary";
            renderObject.state = value;
            renderObject.icon = <TimerIcon />;
            break;
          case "Completo":
            renderObject.color = "success";
            renderObject.state = value;
            renderObject.icon = <CheckCircleIcon />;
            break;
          default:
            break;
        }
        console.log("row", value);
        return (
          <Chip
            color={renderObject.color}
            label={renderObject.state}
            icon={renderObject.icon}
          />
        );
      },
    },
    {
      field: "id",
      headerName: "Service ID",
      minWidth: 100,
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "Fecha",
      headerName: "Fecha",
      minWidth: 100,
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "Empresa",
      headerName: "Empresa/cliente",
      minWidth: 100,
      flex: 1,
      headerClassName: "header-table",
      renderCell: ({ row }) => {
        console.log("value", row);
        return <Typography variant="body2">{row.Customer.TaxName}</Typography>;
      },
    },
    {
      field: "Origen",
      headerName: "Origen",
      minWidth: 100,
      flex: 1,
      headerClassName: "header-table",
      renderCell: ({ value }) => {
        return (
          <Typography variant="body2">
            {" "}
            {value ? value.Calle : "No asignado"} No.{" "}
            {value ? value.Numero : "No asignado"}
          </Typography>
        );
      },
    },
    {
      field: "Destino",
      headerName: "Destino",
      minWidth: 100,
      flex: 1,
      headerClassName: "header-table",
      renderCell: ({ value }) => {
        return (
          <Typography variant="body2">
            {" "}
            {value ? value.Calle : "No asignado"} No.{" "}
            {value ? value.Numero : "No asignado"}
          </Typography>
        );
      },
    },
    {
      field: "test",
      headerName: "Actions",
      minWidth: 100,
      flex: 1,
      headerClassName: "header-table",
      renderCell: ({ row }) => {
        console.log("larow", row);
        return (
          <Grid item xs={12}>
            <VerDetallesDesktopButton data={row} />
            <Button
              onClick={() =>
                navigate(`/${userid.claims.rol}/services/active?Id=${row._id}`)
              }
            >
              Iniciar
            </Button>
          </Grid>
        );
      },
    },
  ];

  const VerDetallesDesktopButton = ({ data }) => {
    const [dialog, setDialog] = useState({
      ...InitialDialogState,
      updateOpenState: (v) => setDialog({ ...dialog, open: v }),
    });

    const onClick = (data) => {
      console.log("service", data);
      setDialog({
        ...dialog,
        open: true,
        title: "",
        content: <DetailsComponent serviceObj={data} />,
        actions: [
          {
            label: "Cerrar",
            execute: () => setDialog({ ...dialog, open: false }),
          },
        ],
      });
    };

    return (
      <>
        <AlertDialog {...dialog} />
        <Button onClick={() => onClick(data)}>Ver Detalles</Button>
      </>
    );
  };

  const OperatorServicesMobileColumns = [
    {
      field: "Status",
      headerName: "Servicios",
      //minWidth: 100,
      //flex: 1,
      headerClassName: "header-table",
      renderCell: ({ row }) => {
        let value = row;
        console.log("row", value);
        return (
          <ServiceActionCard
            data={row}
            onDetailsClick={() => console.log("details")}
            onNavigateClick={(value) =>
              navigate(`/${userid.claims.rol}/services/active?Id=${value}`)
            }
          />
        );
      },
    },
  ];

  const ServiceActionCard = ({ data, onNavigateClick }) => {
    const { _id, Status, Customer, TransportService } = data;
    const [dialog, setDialog] = useState({
      ...InitialDialogState,
      updateOpenState: () => setDialog({ ...dialog, open: false }),
    });

    const onDetailsClick = (service) => {
      console.log("service", service);
      setDialog({
        ...dialog,
        open: true,
        title: "",
        content: <DetailsComponent serviceObj={service} />,
        actions: [
          {
            label: "Cerrar",
            execute: () => setDialog({ ...dialog, open: false }),
          },
        ],
      });
    };

    return (
      <>
        <AlertDialog {...dialog} />
        <Card variant="outlined" sx={{ marginBottom: "2vh", width: "100%" }}>
          <CardContent>
            <Grid
              container
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Chip
                  color={Status.isActive ? "warning" : "warning"}
                  label={Status.isActive ? "En curso" : "Pendiente"}
                  icon={Status.isActive ? <TimerIcon /> : <PendingIcon />}
                />
                <Typography variant="caption" color="textSecondary">
                  ID:{_id}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="Button" color={"primary.dark"}>
                  Cliente: {Customer.TaxName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" color={"primary.dark"}>
                  Cita carga:{" "}
                  {formatDateDDMMYYYY(TransportService.DateTimeLoad, true)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <IconButton
                variant={"contained"}
                onClick={() => onDetailsClick(data)}
                aria-label="Ver Detalles"
                size="large"
                color="primary"
              >
                <VisibilityIcon sx={{ height: "36px", width: "36px" }} />
              </IconButton>
              <IconButton
                variant={"contained"}
                onClick={() => onNavigateClick(_id)}
                aria-label="Ir a Servicio"
                size="large"
                color={Status.isActive ? "warning" : "success"}
              >
                <ArrowCircleRight sx={{ height: "36px", width: "36px" }} />
              </IconButton>{" "}
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  };

  const getActiveServices = (services) => {
    let active = []; //services.filter ExecutionStatus valid states

    return active;
  };

  useEffect(() => {
    // Transportservice.Operator
    console.log("userid", userid);
    let operatorId = userid.claims.user_id;

    //TODO implement logic to get all services related to the operator
    // let operatorServiceFilter = {
    //   param: "TransportService.Operator$eq",
    //   value: "63cb10137eb620db3d3ce296",
    // };

    //getFilteredServices(userid,[operatorServiceFilter])

    getAllServices(userid, userid, true, [])
      .then((res) => {
        console.log("res", res);
        let rows = res.data.data.filter((obj) => {
          if (obj._id !== null) {
            obj.id = obj._id;
            return true;
          }
          return false;
        });
        setOperatorsRows(rows);
        setActiveServicesArray(getActiveServices(rows));
      })
      .catch((err) => {
        err.response ? console.log(err.response) : console.log(err);
      });
  }, []);
  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}
      >
        <Grid item xs={12}>
          <Typography variant="h4" color={"primary"}>
            Bienvenido, Operador
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DashboardDataGrid
            columns={
              isMobile ? OperatorServicesMobileColumns : OperatorServicesColumns
            }
            rows={operatorsRows}
            height={1000}
            locked={false}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
