import { API_CONTEXT } from "./invoices";

export const getExpeditionPLC = (user, credential) => {
  const getExpeditionPlcPromise = API_CONTEXT("issuers", "expeditionPlc");

  return getExpeditionPlcPromise(user, credential);
};

export const deleteExpeditionPLC = (user, id) => {
  const getExpeditionPlcPromise = API_CONTEXT("issuers", "deleteExpeditionPlc");

  return getExpeditionPlcPromise(user, id);
};

export const getIssuers = (user, credential) => {
  const getPromise = API_CONTEXT("issuers", "list");

  return getPromise(user, credential);
};

export const getNextFolio = (user, credential, data) => {
  const getPromise = API_CONTEXT("issuers", "nextFolio");

  const query = Object.keys(data)
    .map((key) => key + "=" + data[key])
    .join("&");

  return getPromise(user, credential, query);
};

export const getSeriesByIssuer = (userid, cred, id) => {
  const getPromise = API_CONTEXT("issuers", "seriesFolios");

  return getPromise(userid, cred, id);
};

export const updateSerie = (userid, cred, id, data) => {
  const getPromise = API_CONTEXT("issuers", "updateSerieFolio");

  return getPromise(userid, cred, id, data);
};

export const createSerieFolio = (user, cred, data) => {
  const getPromise = API_CONTEXT("issuers", "createSerieFolio");

  return getPromise(user, cred, data);
};

export const resetSerieFolio = (user, cred, id) => {
  const getPromise = API_CONTEXT("issuers", "resetSerieFolio");

  return getPromise(user, cred, id);
};

export const deleteSerieFolio = (user, cred, id) => {
  const getPromise = API_CONTEXT("issuers", "deleteSerieFolio");

  return getPromise(user, cred, id);
};

export const saveIssuer = (user, credential, body) => {
  const getPromise = API_CONTEXT("issuers", "save");

  return getPromise(user, credential, body);
};

export const deleteIssuer = (user, credential, rfc) => {
  const getPromise = API_CONTEXT("issuers", "delete");

  return getPromise(user, credential, rfc);
};

export const updateIssuerCertificate = (user, credential, body, rfc) => {
  const getPromise = API_CONTEXT("issuers", "cert");

  return getPromise(user, credential, body, rfc);
};

export const updateIssuerKey = (user, credential, body, rfc) => {
  const getPromise = API_CONTEXT("issuers", "key");

  return getPromise(user, credential, body, rfc);
};

export const saveIssuerInPAC = (user, credential, body, rfc) => {
  const getPromise = API_CONTEXT("issuers", "pac");

  return getPromise(user, credential, body, rfc);
};

export const searchIssuer = (user, credential, string, value) => {
  const getPromise = API_CONTEXT("issuers", "search");

  return getPromise(user, credential, string, value);
};
