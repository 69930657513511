import React, { useEffect, useState } from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import {
  getIssuers,
  getNextFolio,
  getSeriesByIssuer,
} from "../../../services/modules/invoices/issuers";
import { FolioSerieForm } from "../Invoices/FolioSerieForm";

const NominaIssuerForm = ({
  selected,
  setSelected,
  setAlert,
  onIssuerChange,
  organization,
}) => {
  const { userid } = useAuth();
  const intervalRef = React.useRef(null);
  const [options, setOptions] = useState({
    issuerList: [],
    seriesList: [],
  });

  const [loading, setLoading] = useState({
    issuers: true,
    series: false,
  });

  const [error, setError] = useState({
    issuer: {
      failed: false,
      message: "",
    },
    Folio: {
      failed: false,
      message: "",
    },
    Serie: {
      failed: false,
      message: "",
    },
    SerieFolio: {
      failed: false,
      message: "",
    },
  });

  const handleSetAlert = (alert) => {
    setAlert({
      open: alert.show,
      status: alert.state,
      message: alert.message,
    });
  };

  const handleErrorUi = (err, messageHash) => {
    let custom = "";
    if (err.response) {
      switch (err.response.status) {
        case 401:
          custom = messageHash ? messageHash[401] : "";
          handleSetAlert({
            show: true,
            state: "error",
            message:
              401 + " No tienes permisos para realizar esta acción. " + custom,
          });
          break;
        case 403:
          custom = messageHash ? messageHash[403] : "";
          handleSetAlert({
            show: true,
            state: "error",
            message:
              403 + " No tienes permisos para realizar esta acción. " + custom,
          });
          break;
        case 500:
          custom = messageHash ? messageHash[500] : "";
          handleSetAlert({
            show: true,
            state: "error",
            message:
              500 + " Ocurrió un error al realizar esta acción. " + custom,
          });
          break;
        case 404:
          custom = messageHash ? messageHash[404] : "";
          handleSetAlert({
            show: true,
            state: "error",
            message: 404 + " No se encontró el recurso solicitado. " + custom,
            duration: 1000,
          });
          break;
        case 400:
          custom = messageHash ? messageHash[400] : "";
          handleSetAlert({
            show: true,
            state: "error",
            message:
              400 + " Ocurrió un error al realizar esta acción. " + custom,
          });
          break;
        default:
          break;
      }
    }
  };

  const handleChangeSerieFolio = (e, v, r) => {
    const id = e.target.id.split("-")[0];

    if (id === "SerieFolio") {
      setSelected((prev) => {
        return {
          ...prev,
          [id]: v,
          Serie: v.Serie,
        };
      });
      return;
    }

    setSelected((prev) => {
      return {
        ...prev,
        [id]: v,
        Serie: v.Serie,
      };
    });
  };

  const handleChangeIssuer = (e, v, r) => {
    if (e.target.id === "EmployerRegistration") {
      onIssuerChange(e, e.target.value, "input");
      return;
    }
    onIssuerChange(e, v, r);
  };

  const getData = () => {
    getIssuers(userid)
      .then(({ data }) => {
        setLoading((obj) => {
          return { ...obj, issuers: false };
        });
        if (data.data.length === 0) {
          console.log("no issuers");
        }
        if (data.data.length > 0) {
          setOptions((obj) => {
            return { ...obj, issuerList: data.data };
          });
          handleChangeIssuer(
            { target: { id: "issuer" } },
            data.data[0],
            "selectOption"
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAndSetFolio = () => {
    getNextFolio(userid, userid, { _id: selected.SerieFolio._id })
      .then((response) => {
        console.log(response);
        setSelected((prev) => {
          return {
            ...prev,
            Folio: response.data.FolioNumber,
          };
        });
      })
      .catch((err) => {
        console.log(err);
        handleSetAlert({
          show: true,
          state: "error",
          message: "Ocurrió un error al obtener el siguiente folio",
          duration: 1000,
        });
      });
  };

  const getAndSetNextFolio = () => {
    getAndSetFolio();

    intervalRef.current = setInterval(() => {
      getAndSetFolio();
    }, 1000);
  };

  const getAndSetSeries = () => {
    if (selected.issuer === "" || selected.issuer === null) {
      return;
    }

    const errorMessageHashStatus = {
      400: "Verifica que has configurado correctamente el emisor",
      404: "Verifica que has configurado correctamente el emisor. No se encontró ninguna serie",
      500: "Ocurrió un error al obtener las seris del emisor",
    };

    getSeriesByIssuer(userid, userid, selected.issuer._id)
      .then((response) => {
        if (response.status === 200) {
          setSelected((prev) => {
            return {
              ...prev,
              SerieFolio: "",
              Folio: "",
              Serie: "",
            };
          });
          setOptions((prev) => {
            return {
              ...prev,
              seriesList: response.data,
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
        handleErrorUi(err, errorMessageHashStatus);
        setOptions((prev) => {
          return {
            ...prev,
            seriesList: [],
          };
        });
      });
  };

  useEffect(() => {
    getData();
  }, [userid]);

  useEffect(() => {
    if (selected.issuer !== "") {
      getAndSetSeries();
    }
  }, [selected.issuer]);

  useEffect(() => {
    if (selected.SerieFolio !== "") {
      getAndSetNextFolio();
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [selected.SerieFolio]);

  return (
    <>
      <Grid item xs={12}>
        <Autocomplete
          id="issuer"
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                error={error.issuer.failed}
                helperText={error.issuer.message}
                //label="Emisor"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {/*loading.issuers ? <CircularProgress /> : null*/}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }}
          getOptionLabel={(option) => {
            return option.Name || "";
          }}
          options={options.issuerList}
          onChange={handleChangeIssuer}
          isOptionEqualToValue={(o, v) => {
            if (v.Name === "" && v.Rfc === "") {
              return true;
            } else {
              return o.Name === v.Name;
            }
          }}
          value={selected.issuer}
        />
      </Grid>
      <Grid container spacing={2}>
        <FolioSerieForm
          folio={selected.Folio}
          serie={selected.Serie}
          serieFolio={selected.SerieFolio}
          errorManager={error}
          options={options}
          organization={organization}
          loading={loading}
          handleChange={handleChangeSerieFolio}
          style={{
            SerieFolio: {
              Grid: {
                xs: 6,
                md: 6,
              },
            },
            Folio: {
              Grid: {
                xs: 6,
                md: 6,
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="standard"
          id="EmployerRegistration"
          label="Registro del patrón"
          onChange={handleChangeIssuer}
          error={error.issuer.failed}
          helperText={error.issuer.message}
          sx={{ width: "100%" }}
          value={selected.EmployerRegistration || ""}
        />
      </Grid>
    </>
  );
};

export default NominaIssuerForm;
