import { Accordion, AccordionDetails, AccordionSummary, Container, Grid, Typography } from "@mui/material";
import { getInvoiceFiles } from "../../../services/modules/invoices/invoices";
import { AlertDialog, InitialDialogState } from "../../../components/userFeedback/AlertDialog";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import useAuth from "../../../hooks/useAuth";
import { useEffect, useState } from "react";



const DetailsComponent = ({serviceObj}) => {
    const { TransportService, Maneuver, Staying, Items } = serviceObj;
    const { CompanyLoad, CompanyUnload } = TransportService;

    return (
      <>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h5" color={"primary.dark"}>
              Servicio {serviceObj.Folio ? serviceObj.Folio : null}
            </Typography>
            <Typography variant="h5" color={"primary.dark"}>
              {serviceObj._id}
            </Typography>
            {TransportService.Invoices.length > 0 && (
              <>
                <Typography>Facturas</Typography>
                {TransportService.Invoices.map((inv) => {
                  return <ViewPdfIdButtonComponent id={inv} />;
                })}
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <DetailsAccordionComponent
              title={"Lugar de Carga"}
              description={
                CompanyLoad?.Address?.Name
                  ? CompanyLoad?.Address.Name
                  : CompanyLoad?.TaxName
              }
              details={displayAddressDetails(CompanyLoad?.Address)}
            />
          </Grid>
          <Grid item xs={12}>
            <DetailsAccordionComponent
              title={"Lugar de Descarga"}
              description={
                CompanyUnload?.Address?.Name
                  ? CompanyUnload?.Address?.Name
                  : CompanyUnload?.TaxName
              }
              details={displayAddressDetails(CompanyUnload?.Address)}
            />
          </Grid>
          {Maneuver !== null && (
            <Grid item xs={12}>
              <DetailsAccordionComponent
                title={"Maniobra"}
                description={Maneuver.TypeManeuver}
                details={<ManeuverDetailsDisplay maneuver={Maneuver} />}
              />
            </Grid>
          )}
          {Staying !== null && (
            <Grid item xs={12}>
              <DetailsAccordionComponent
                title={"Estadía"}
                description={Staying.TypeStaying}
                details={<StayingDetailsDisplay staying={Staying} />}
              />
            </Grid>
          )}
          {Items.length > 0 && (
            <Grid item xs={12}>
              <DetailsAccordionComponent
                title={"Conceptos"}
                description={"Se han añadido: " + Items.length}
                details={<ItemsDisplay items={Items} />}
              />
            </Grid>
          )}
        </Grid>
      </>
    );
    
  };


  const DetailsAccordionComponent = ({ title, description, details }) => {
    return (
      <>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel-header"
          >
            <Typography
              color={"primary.dark"}
              sx={{ width: "33%", flexShrink: 0 }}
            >
              {title}
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {description}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ maxHeight: "25vh", overflow: "auto" }}>
            {details}
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  const ManeuverDetailsDisplay = ({ maneuver }) => {
    const { Employees, Code, Invoices } = maneuver;
    const { userid } = useAuth();
    const [employeesObjects, setemployeesObjects] = useState([]);

    useEffect(() => {
      if (Employees !== null && Employees.length > 0) {
        console.log("searching");
        Employees.forEach((value) => {
          let objArr = [];
          searchEmployeeByKey(userid, userid, "_id", value)
            .then((res) => {
              console.log("res", res);
              if (res.data.data.length === 1) {
                objArr.push(res.data.data[0]);
                setemployeesObjects((prev) => prev.concat(objArr));
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      }
    }, []);

    useEffect(() => {
      console.log("employeesObjects", employeesObjects);
    }, [employeesObjects]);

    return (
      <>
        {employeesObjects.length > 0 && (
          <>
            <Typography variant="body2">Designados:</Typography>
            {employeesObjects.map((obj) => {
              return (
                <Typography>
                  <b>{obj.Dpto}</b>
                  {" - "}
                  {obj.Nombre} {obj.ApellidoM} {obj.ApellidoP}
                </Typography>
              );
            })}
          </>
        )}
      </>
    );
  };

  const StayingDetailsDisplay = ({ staying }) => {
    const { Time, Risk } = staying;

    return (
      <>
        <Typography>Detalles</Typography>
        <Typography>
          <b>Tiempo de espera: </b>
          {formatMinutes(Time)}
          <br></br>
        </Typography>

        <Typography>
          <b>Riesgo: </b>
          {Risk}
        </Typography>
      </>
    );
  };

  const ViewPdfIdButtonComponent = ({ id }) => {
    const { userid } = useAuth();
    const [pdfSource, setPdfSource] = useState();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState({
      ...InitialDialogState,
      updateOpenState: () => setDialog({ ...dialog, open: false }),
    });

    const loadPdf = (id) => {
      getInvoiceFiles(userid, userid, "pdf", id)
        .then((data) => {
          const binary = atob(data.data.pdf.replace(/\s/g, ""));
          const buffer = new ArrayBuffer(binary.length);
          const view = new Uint8Array(buffer);

          for (let i = 0; i < binary.length; i++) {
            view[i] = binary.charCodeAt(i);
          }

          const blob = new Blob([view], { type: "application/pdf" });
          const source = window.URL.createObjectURL(blob);
          setPdfSource(source);
          setOpen(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log("pdf error", err.response);
          setDialog({
            ...dialog,
            open: true,
            title: "Error al obtener el PDF",
            content: err.response?.status
              ? "error" /* this function is not yet merged getGenericErrorDialogContent(err.response.status)*/
              : "",
            actions: [
              {
                label: "Ok",
                execute: () => setDialog({ ...dialog, open: false }),
              },
            ],
          });
          setLoading(false);
        });
    };

    return (
      <>
        <AlertDialog {...dialog} />
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          sx={{ height: "85%" }}
        >
          <Box sx={{ width: "auto", height: "90%", mx: "auto", my: "auto" }}>
            <Container sx={{ height: "100%" }}>
              {pdfSource ? (
                <embed
                  src={pdfSource}
                  width="100%"
                  height="100%"
                  type="application/pdf"
                  controls={false}
                />
              ) : (
                <div>Loading PDF...</div>
              )}
            </Container>
          </Box>
        </Modal>
        <Button onClick={() => loadPdf(id)} endIcon={<OpenInNewIcon />}>
          {id}
        </Button>
      </>
    );
  };

  const ItemsDisplay = ({ items }) => {
    return (
      <>
        {items.map((item) => {
          return (
            <>
              <Typography>
                {item.Type} ${item.Total}
              </Typography>
              {item.Invoices.length > 0
                ? item.Invoices.length.map((invoice) => {
                    ViewPdfIdButtonComponent(invoice);
                  })
                : null}
            </>
          );
        })}
      </>
    );
  };

  const displayAddressDetails = (Address) => {
    return (
      <>
        {Address?.Street} {"No. "}
        {Address?.ExteriorNumber}
        {Address?.InteriorNumber ? "-" + Address?.InteriorNumber : ""}{" "}
        {"Colonia: "}
        {Address?.Neighborhood} {"C.P: "}
        {Address?.ZipCode}
        {", "}
        {Address?.State}
      </>
    );
  };

  function formatMinutes(minutes) {
    minutes = parseInt(minutes);

    if (isNaN(minutes) || minutes < 0) {
      return "Invalid";
    }

    const weeks = Math.floor(minutes / (7 * 24 * 60));
    const days = Math.floor((minutes % (7 * 24 * 60)) / (24 * 60));
    const hours = Math.floor((minutes % (24 * 60)) / 60);
    const remainingMinutes = minutes % 60;

    let result = "";

    if (weeks > 0) {
      result += weeks + " semana" + (weeks > 1 ? "s" : "") + " ";
    }
    if (days > 0) {
      result += days + " día" + (days > 1 ? "s" : "") + " ";
    }
    if (hours > 0) {
      result += hours + " hora" + (hours > 1 ? "s" : "") + " ";
    }
    if (remainingMinutes > 0) {
      result +=
        remainingMinutes + " minuto" + (remainingMinutes > 1 ? "s" : "");
    }

    return result.trim();
  }

  export {DetailsComponent, formatMinutes,displayAddressDetails,ViewPdfIdButtonComponent}