import apiObj from "../../../axios";

const {api, version} = apiObj;

export const SERVICES_API_CONTEXT = (where, what) => {
  const main = {
    create: (usr, cred, body) =>
      api.post(`${version}/services`, body, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    update: (usr, cred, serviceId, body) => {
      return api.put(`${version}/services/${serviceId}`, body, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      });
    },
    delete: (usr, cred, id) =>
      api.delete(`${version}/services/${id}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    getAll: (usr, cred, pagination, filters) =>
      api.get(`${version}/services?${pagination}&${filters}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    getFiltered: (usr, cred, filters) =>
      api.get(`${version}/services/?${filters.join("&")}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    getAllPagination: (usr, cred, pagination, filters) =>
      api.get(`${version}/services?${pagination}&${filters}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    getOne: (usr, cred, serviceId) =>
      api.get(`${version}/services/${serviceId}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    getLatestFolio: (userid, credential) =>
      api.get(`${version}/services/folios/latest`, {
        headers: {
          user: userid,
          authorization: credential.token,
        },
      }),
    buildInvoiceFromService: (userid, credential, serviceId) =>
      api.get(`${version}/services/build-invoice/${serviceId}`, {
        headers: {
          user: userid,
          authorization: credential.token,
        },
      }),
    createEvidence: (userid, credential, serviceId, data) =>
      api.post(`${version}/services/evidence/${serviceId}`, data, {
        headers: {
          user: userid,
          authorization: credential.token,
        },
      }),
    downloadEvidence: (userid, credential, serviceId, fileId) => {
      return api.get(
        `${version}/services/evidence/file/download/${serviceId}/${fileId}`,
        {
          headers: {
            user: userid,
            authorization: credential.token,
          },
          responseType: "blob",
        }
      );
    },
    updateEvidence: (userid, credential, serviceId, newEvidence) =>
      api.put(`${version}/services/evidence/${serviceId}`, newEvidence, {
        headers: {
          user: userid,
          authorization: credential.token,
        },
      }),
  };

  const destination = {
    get: (usr) =>
      api.get(`${version}/destinations`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    getFiltered: (usr, filters) =>
      api.get(`${version}/destinations/filter?${filters.join("&")}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    getOne: (usr, id) =>
      api.get(`${version}/destinations/filter?_id=${id}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    create: (usr, body) =>
      api.post(`${version}/destinations`, body, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
    update: (usr, id, body) => {
      console.log(body)
      return api.put(`${version}/destinations/${id}`, body, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      });
    },
    delete: (usr, id) =>
      api.delete(`${version}/destinations/${id}`, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      }),
  };

  const transportService = {
    update: (usr, cred, id, body) => {
      return api.put(`${version}/services/transport-service/${id}`, body, {
        headers: {
          user: usr,
          authorization: usr.token,
        },
      });
    },
  };

  const methods = {
    main: main,
    destination: destination,
    transportService: transportService,
  };

  return methods[where][what];
};
