// Import necessary dependencies
import React, {ReactNode} from "react";
import {
  Grid as MuiGrid,
  GridSize,
  GridProps as MuiGridProps,
  SxProps,
} from "@mui/material";

// Define the specific props you expect to use in your Grid component
interface EvolveGridProps {
  container?: boolean;
  item?: boolean;
  xs?: boolean | number;
  sm?: boolean | number;
  md?: boolean | number;
  lg?: boolean | number;
  xl?: boolean | number;
  maxWidth?: GridSize;
  spacing?: number;
  alignContent?:
    | "stretch"
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around";
  alignItems?: "flex-start" | "center" | "flex-end" | "stretch" | "baseline";
  justifyItems?: "flex-start" | "center" | "flex-end" | "stretch" | "baseline";
  direction?: "row" | "row-reverse" | "column" | "column-reverse";
  justifyContent?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  wrap?: "nowrap" | "wrap" | "wrap-reverse";
  sx?: SxProps;
  component?: React.ElementType;
  children?: ReactNode;
  padding?:
    | number
    | {xs?: number; sm?: number; md?: number; lg?: number; xl?: number};
  mb?: string;
}

// Adapt the props to the new Grid component
const adaptPropsToNewGrid = (props: EvolveGridProps): MuiGridProps => {
  const {component, alignContent, ...rest} = props;
  return {
    ...rest,
    padding: props.padding
      ? props.padding
      : {xs: 2, sm: 2, md: 2, lg: 2, xl: 2},
  };
};

// Define the Grid component
const Grid: React.FC<EvolveGridProps> = (params: EvolveGridProps) => {
  const newProps = adaptPropsToNewGrid(params);
  return <MuiGrid {...newProps}>{params.children}</MuiGrid>;
};

export {Grid, type EvolveGridProps};
