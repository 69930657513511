import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {FormExpenseComponent} from "../../../components/Forms/Expenses/FormExpenseComponent";
import FormItemExpenseComponent from "../../../components/Forms/Expenses/FormItemExpenseComponent";

import useAuth from "../../../hooks/useAuth";
import {useNavigate} from "react-router";
import {ExpenseDto} from "./dto/expenses.dto";
import {
  getExpense,
  editExpense,
  deleteExpenseFile,
  uploadExpenseFile,
} from "../../../services/modules/expenses/expenses";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

export function EditExpense() {
  const {userid} = useAuth();
  const navigate = useNavigate();

  //* GET ID OF EXPENSE
  const {expenseId} = useParams();

  //* USE FOR SHOW O HIDE FORM EXPENSE
  const [showFormItemExpense, setShowFormItemExpense] = useState(false);

  //* USER FOR HANDLER DATA EXPENSES
  const [expense, setExpense] = useState({...ExpenseDto});

  //* USE TO KWON ORIGINAL ARRAY OF FILES
  const [originalFiles, setOriginalFiles] = useState([]);

  //* USE FOR SHOW MESSAGE FOR WAITING
  const [isLoading, setIsLoading] = useState(false);

  //* USE FOR SHOW FORM ADD EXPENSE
  const onOpenFormItemExpense = () => {
    setShowFormItemExpense(true);
  };

  //* USE FOR CLOSE FORM EXPENSE
  const onCloseFormItemExpense = () => {
    setShowFormItemExpense(false);
  };

  //* USE FOR ADD NEW VALUES IN EXPENSE
  const handlerInputChange = (event) => {
    const {name, value} = event.target;
    setExpense({
      ...expense,
      [name]: value,
    });
  };

  //* USE FOR GET DOCUMENT EXPENSE
  useEffect(() => {
    getExpense(userid, expenseId)
      .then((response) => {
        const {data} = response;
        console.log(data);
        data.CreateAt = new Date(data.CreateAt);
        setExpense({...data});
        setOriginalFiles([...data.Files]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onAddItemExpense = (itemExpense) => {
    setExpense((prevExpense) => {
      const items = [...prevExpense.Items, itemExpense];
      return {
        ...prevExpense,
        Items: items,
      };
    });
  };

  const onSubmit = () => {
    console.log(expense);
    console.log(originalFiles);

    //WE REMOVE ARRAY FILES FOR UPLOAD FOR OTHER ENDPOINT
    const payload = {...expense};
    const currentFiles = [...payload.Files];
    delete payload.Files;

    setIsLoading(true);
    editExpense(userid, expenseId, expense)
      .then(async (response) => {
        console.log(response);

        if (expense.Files.length === 0) {
          navigate(`/${userid.claims.rol}/expenses`);
        } else {
          //WE DELETE FILE IF DON'T EXIST IN ORIGINAL FILES
          for (const file of originalFiles) {
            try {
              const currentFile = currentFiles.find((f) => f._id === file._id);

              //* IF DON'T EXISTE IN ARRAY, WE DELETE
              if (!currentFile)
                await deleteExpenseFile(userid, expenseId, file._id);
            } catch (err) {}
          }

          //WE NEED TO UPLOAD SELECTED FILES
          for (const file of expense.Files) {
            try {
              if (!file._id) {
                const formData = new FormData();
                formData.append("file", file.File);
                const response = await uploadExpenseFile(
                  userid,
                  expenseId,
                  formData
                );
              }
            } catch (err) {}
          }

          navigate(`/${userid.claims.rol}/expenses`);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!showFormItemExpense ? (
        <FormExpenseComponent
          expenseId={expenseId}
          expense={expense}
          setExpense={setExpense}
          handlerInputChange={handlerInputChange}
          onSubmit={onSubmit}
          onOpenFormItemExpense={onOpenFormItemExpense}
          isLoading={isLoading}
        />
      ) : (
        <FormItemExpenseComponent
          onAddItemExpense={onAddItemExpense}
          onCloseFormItemExpense={onCloseFormItemExpense}
        />
      )}
    </div>
  );
}
