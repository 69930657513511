export const ServiceItemGeneric = {
  SubTotal: 0,
};

export const ManeuverItem = {
  TipoManiobra: "descarga",
  Empleados: [],
};

export const StayItem = {
  TipoEstadia: "",
  Tiempo: 0,
  Riesgo: 0,
  Code: "76122401",
};

export const ExtraItem = {
  Tipo: "",
};

export const ReturnItem = {
  Tipo: "",
  Mercancia: [],
};

export const TransportItem = {
  Operadores: [],
  Vehicles: [{ Tractor: {}, Remolques: [{}] }],
  DateTimeLoad: "",
  DateTimeUnload: "",
  Load: {
    ScheduleTime: null,
    RealTime: null,
    StartedWaiting: null,
    StartedWaitingAccess: null,
    Accessed: null,
    StartedWaitingDocs: null,
    ReceivedDocs: null,
  },
  Unload: {
    ScheduleTime: null,
    RealTime: null,
    StartedWaiting: null,
    StartedWaitingAccess: null,
    Accessed: null,
    StartedWaitingDocs: null,
    ReceivedDocs: null,
  },
  Code: {},
  CompanyLoad: {
    TaxName: "",
    Rfc: "",
    Type: "",
    Address: {
      ZipCode: "",
      Country: "",
      State: "",
      Street: "",
      InteriorNumber: "",
      ExteriorNumber: "",
      Neighborhood: "",
      Locality: "",
      Municipality: "",
      placeId: "",
      lattitude: "",
      longitude: "",
      description: "",
    },
  },
  CompanyUnload: {
    TaxName: "",
    Rfc: "",
    Type: "",
    Address: {
      ZipCode: "",
      Country: "",
      State: "",
      InteriorNumber: "",
      ExteriorNumber: "",
      Locality: "",
      Municipality: "",
      placeId: "",
      lattitude: "",
      longitude: "",
      description: "",
    },
  },
  Mercancias: {
    Mercancia: [],
    PesoBrutoTotal: 0,
    UnidadPeso: {
      ShortName: "Kilogramo",
      Name: "Kilogramo",
      Value: "KGM",
    },
    NumTotalMercancias: 0,
  },
};

export const BaseServiceStruct = {
  Folio: "",
  Customer: {},
};

export const TrackingStruct = {
  Unidad: {},
  Direccion: {},
  EstimatedDistance: 0,
  FechaHoraPartida: "",
  FechaHoraResguardo: "",
  Route: "",
};

export const ExecutionStatusType = {
  warningValues: [
    "ESPERA DE SALIDA",
    "SALIENDO DE PATIO",
    "EN CAMINO A CARGA",
    "LLEGADA A CLIENTE",
    "ESPERA CARGA",
    "CARGANDO",
    "CARGADO",
    "CARGADO EN ESPERA",
    "CARGADO EN PATIO",
    "CAMINO A DESCARGA",
    "EN DESCARGA",
    "ESPERA EVIDENCIAS",
    "EVIDENCIAS RECIBIDAS",
    "EN CAMINO A RESGUARDO",
    "PROCESANDO EVIDENCIA",
  ],
  errorValues: ["PROCESO DE DEVOLUCION"],
  successValues: ["FINALIZADO"],
};

export const ExecutionStatusOptions = [
  { label: "Espera de salida", value: "ESPERA DE SALIDA" },
  { label: "Saliendo de patio", value: "SALIENDO DE PATIO" },
  { label: "En camino a carga", value: "EN CAMINO A CARGA" },
  { label: "Llegada a cliente", value: "LLEGADA A CLIENTE" },
  { label: "Espera carga", value: "ESPERA CARGA" },
  { label: "Cargando", value: "CARGANDO" },
  { label: "Cargado", value: "CARGADO" },
  { label: "Cargado en espera", value: "CARGADO EN ESPERA" },
  { label: "Cargado en patio", value: "CARGADO EN PATIO" },
  { label: "Camino a descarga", value: "CAMINO A DESCARGA" },
  { label: "En descarga", value: "EN DESCARGA" },
  { label: "Espera evidencias", value: "ESPERA EVIDENCIAS" },
  { label: "Evidencias recibidas", value: "EVIDENCIAS RECIBIDAS" },
  { label: "En camino a resguardo", value: "EN CAMINO A RESGUARDO" },
  { label: "Procesando evidencia", value: "PROCESANDO EVIDENCIA" },
  { label: "Proceso de devolucion", value: "PROCESO DE DEVOLUCION" },
  { label: "Finalizado", value: "FINALIZADO" },
];

export const StatusEvidenceType = {
  LIBERADA: "Liberada",
  RECIBIDA_INCOMPLETA: "Recibida Incompleta",
  PROCESO_DE_LIBERACION: "Proceso de Liberacion",
  RECIBIDA: "Recibida",
};

export const EmployeesPositionOptions = [
  { label: "OPERADOR", value: "OPERADOR" },
  {
    label: "GESTOR DE DOCUMENTACION DE SERVICIOS",
    value: "GESTOR DE DOCUMENTACION DE SERVICIOS",
  },
  { label: "COORDINADOR", value: "COORDINADOR" },
  {
    label: "ADMINISTRADOR FINANCIERO",
    value: "ADMINISTRADOR FINANCIERO",
  },
  { label: "AYUDANTE EN GENERAL", value: "AYUDANTE EN GENERAL" },
  { label: "SECRETARIA/O", value: "SECRETARIA/O" },
];
