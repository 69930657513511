import React from "react";
import { Grid, Typography, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const PersonalDataForm = ({
  employeeData,
  onInputChange,
  onAutoCompleteChange,
  fiscalRegimes,
  errorText,
}) => {
  const handleInputChange = (e) => {
    onInputChange(e);
  };

  const handleAutoCompleteChange = (e, v, r) => {
    onAutoCompleteChange(e, v, r);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography color="primary">Datos Personales</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          variant="standard"
          id="Nombre"
          name="Nombre"
          label="Nombre(s)"
          fullWidth
          autoComplete="given-name"
          value={employeeData.Nombre || ""}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          variant="standard"
          id="ApellidoP"
          name="ApellidoP"
          label="Apellido Paterno"
          fullWidth
          autoComplete="family-name"
          value={employeeData.ApellidoP || ""}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          variant="standard"
          id="ApellidoM"
          name="ApellidoM"
          label="Apellido Materno"
          fullWidth
          autoComplete="family-name"
          value={employeeData.ApellidoM || ""}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          autoComplete
          id="RegimenFiscal"
          name="RegimenFiscal"
          sx={{ width: "100%" }}
          options={fiscalRegimes}
          value={employeeData.RegimenFiscal || ""}
          getOptionLabel={(option) => {
            return `${option.Name}`;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                multiline
                id="FiscalRegime"
                name="FiscalRegime"
                sx={{ width: "100%" }}
                label="Régimen Fiscal"
                required
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }}
          onChange={handleAutoCompleteChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          variant="standard"
          id="Rfc"
          name="Rfc"
          label="RFC"
          fullWidth
          helperText={errorText.Rfc}
          error={errorText.Rfc}
          autoComplete="XXXXXX991231"
          inputProps={{
            style: {
              "&:invalid": {
                border: "red solid 2px !important",
              },
              textTransform: "uppercase",
            },
            maxLength: 13,
          }}
          value={employeeData.Rfc || ""}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          variant="standard"
          id="CURP"
          name="CURP"
          label="CURP"
          fullWidth
          inputProps={{
            style: {
              "&:invalid": {
                border: "red solid 2px !important",
              },
              textTransform: "uppercase",
            },
            maxLength: 18,
          }}
          helperText={errorText.Curp}
          error={errorText.Curp}
          autoComplete="off"
          value={employeeData.CURP || ""}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          variant="standard"
          id="NSS"
          name="NSS"
          label="No. Seguridad Social"
          fullWidth
          autoComplete="off"
          value={employeeData.NSS || ""}
          onChange={handleInputChange}
        />
      </Grid>
    </>
  );
};

export default PersonalDataForm;
