import React, { useState } from "react";
import { Grid, TextField, IconButton } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { DisplayFilePreview, DragAndDrop } from "../DragAndDrop/DragAndDrop";
import { StatusEvidenceType } from "../../modules/services/utils/ServiceItemsENUM";

const EmptyEvidenceDialogContent = ({ service, handleCreateEvidence }) => {
  const [internalStatusEvidence, setInternalStatusEvidence] = useState("");
  const [files, setFiles] = useState([]);

  const handleChangeStatusEvidence = (event, value, reason) => {
    if (reason === "selectOption") {
      setInternalStatusEvidence(value);
    }
  };

  const handleSubmitEvidence = (files) => {
    setFiles(files);
  };

  const handleClick = () => {
    handleCreateEvidence(service, files, internalStatusEvidence)
  }
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          options={Object.values(StatusEvidenceType)}
          getOptionLabel={(option) => option}
          sx={{ width: "100%" }}
          onChange={handleChangeStatusEvidence}
          renderInput={(params) => (
            <TextField {...params} label="Status de evidencia" />
          )}
          value={internalStatusEvidence}
        />
      </Grid>
      <Grid item xs={12} sx={{ width: "100%" }}>
        <DragAndDrop
          submit={handleSubmitEvidence}
          fileExtension="image/*,.pdf,.jpg,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          limit={20}
        />
        <Grid item xs={3} sx={{ display: "flex", flexDirection: "row" }}>
          {files.map((file) => {
            return <DisplayFilePreview file={file} key={file.name} />;
          })}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <IconButton
          onClick={handleClick}
        >
          Crear evidencia
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default EmptyEvidenceDialogContent;
