import React from "react";
import { TextField, Typography, Grid } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function SCTPermissionComponent({ transport, handleChange }) {
  const handleDateChangeIssuedDate = (stringDate) => {
    transport.SCTPermission.IssuedDate = new Date(stringDate).toISOString();
  };

  const handleDateChangeExpirationDate = (stringDate) => {
    transport.SCTPermission.ExpirationDate = new Date(stringDate).toISOString();
  };

  return (
    <>
      <Typography variant="h6" align="center" color="primary.dark">
        Permiso SCT
      </Typography>

      <Grid container sx={{ mt: "3vh" }}>
        <Grid item xs={6}>
          <TextField
            onChange={handleChange}
            id="SCTPermission.Number"
            label="No. del Permiso"
            value={transport.SCTPermission.Number}
            InputLabelProps={{
              shrink: Boolean(transport.SCTPermission.Number),
            }}
            variant="standard"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="SCTPermission.IssuedDate"
              name="SCTPermission.IssuedDate"
              label="Fecha de emisión"
              required
              slotProps={{ textField: { variant: "standard" } }}
              sx={{ width: "100%" }}
              value={transport.SCTPermission.IssuedDate ? new Date(transport.SCTPermission.IssuedDate) : ''}
              onChange={handleDateChangeIssuedDate}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="SCTPermission.ExpirationDate"
              name="SCTPermission.ExpirationDate"
              label="Fecha de expiración"
              slotProps={{ textField: { variant: "standard" } }}
              sx={{ width: "100%" }}
              value={transport.SCTPermission.ExpirationDate ? new Date(transport.SCTPermission.ExpirationDate) : ''}
              onChange={handleDateChangeExpirationDate}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </>
  );
}
