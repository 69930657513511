import { Checkbox, FormControlLabel, Typography } from "@mui/material";

const CheckBoxesList = ({
  items,
  checkedItems,
  setCheckedItems,
  emptyListMessage,
}) => {
  const handleChecked = (index) => (e) => {
    return setCheckedItems(e, index);
  };

  return (
    <div>
      {items.length === 0 && (
        <Typography>{`${emptyListMessage || "No items"}`}</Typography>
      )}
      {items.map((item, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              onChange={handleChecked(index)}
              checked={checkedItems[index]}
            />
          }
          label={item}
        />
      ))}
    </div>
  );
};

export default CheckBoxesList;
