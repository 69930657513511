import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  Paper,
  Container,
  Snackbar,
  Alert,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditComponent from "../../../components/actions/EditComponent";
import useAuth from "../../../hooks/useAuth";
import ferbyTheme from "../../../theme/ferbyTheme";
import {
  getAllEmployees,
  deleteEmployee,
  getFilteredEmployees,
} from "../../../services/modules/employees/employees";
import { formatDateDDMMYYYY } from "../../../utils/dateManipulators.js";
import { ConsultSearchComponent } from "../../../components/actions/ConsultSearchComponent";
import {
  AlertDialog,
  InitialDialogState,
  getGenericErrorDialogContent,
} from "../../../components/userFeedback/AlertDialog";
import BACKEND_CONFIG from "../../../utils/BACKEND_CONFIG.js";
import { getErrorMessageFromStatus } from "../../../utils/httpResponses.js";

export const EmployeesIndex = () => {
  const navigate = useNavigate();

  const { userid } = useAuth();
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
    duration: 0,
  });
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searched, setSearched] = useState(false);
  const [searchedTerm, setSearchedTerm] = useState("");
  const [dialog, setDialog] = useState({
    ...InitialDialogState,
    updateOpenState: () => setDialog({ ...dialog, open: false }),
  });

  const employeesColumns = [
    {
      field: "Nombre",
      sortable: false,
      headerName: "Nombre completo",
      headerClassName: "header-table",
      headerAlign: "center",
      flex: 2,
      align: "center",
      renderCell: (params) =>
        `${params.row.Nombre} ${params.row.ApellidoP} ${params.row.ApellidoM}`,
    },
    {
      field: "Rfc",
      Description: "RFC del empleado",
      sortable: false,
      headerName: "RFC",
      headerClassName: "header-table",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "Puesto",
      Description: "El área de trabajo del empleado",
      sortable: false,
      headerName: "Puesto",
      headerClassName: "header-table",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "Dpto",
      Description: "Departamento del área",
      sortable: false,
      headerName: "Dpto",
      headerClassName: "header-table",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "FechaInicio",
      Description: "Fecha de inicio del contrato",
      sortable: false,
      headerName: "Fecha de inicio",
      headerClassName: "header-table",
      headerAlign: "center",
      flex: 1,
      align: "center",
      renderCell: (params) => `${formatDateDDMMYYYY(params.value, false)}`,
    },
    {
      field: "_id",
      Description: "Acciones de fila",
      sortable: false,
      headerName: "Acciones",
      headerClassName: "header-table",
      headerAlign: "center",
      flex: 1,
      align: "center",
      renderCell: (params) => {
        params.row.id = params.row._id; // id assignation to EditComponent
        return (
          <EditComponent
            editionRoute={`/${userid.claims.rol}/empleados/actualizar`}
            objName={"Empleado"}
            onDelete={() => deleteEmployees(params.row.Rfc)}
            rowParams={params.row}
          />
        );
      },
    },
  ];

  const deleteEmployees = (rfc) => {
    deleteEmployee(userid, userid, rfc, {})
      .then((response) => {
        console.log(response);
        setDialog({
          ...dialog,
          open: true,
          title: "Empleado eliminado",
          content: "",
          actions: [{ label: "Ok", execute: () => window.location.reload() }],
        });
      })
      .catch((err) => {
        console.log(err.response);
        setDialog({
          ...dialog,
          open: true,
          title: "Ocurrió un error al eliminar al empleado",
          content: "Inténtelo más tarde",
          actions: [
            { label: "Ok", execute: () => window.location.reload() },
            { label: "Reintentar", execute: () => deleteEmployees(rfc) },
          ],
        });
      });
  };

  const getResponseFromError = (err) => {
    setLoading((l) => false);
    if (err.response) {
      const status = err.response.status;
      const message = getErrorMessageFromStatus(status);
      setAlert({
        open: true,
        duration: 6000,
        title: `Error ${status}`,
        message: message,
        severity: "error",
      });
      return;
    }

    setDialog((dialog) => {
      return {
        ...dialog,
        open: true,
        title: "ERROR Obteniendo empleados",
        content: getGenericErrorDialogContent("default"),
        actions: [
          {
            label: "Recargar sesión",
            execute: () => window.location.reload(),
          },
          {
            label: "Intentar más tarde",
            execute: () => navigate(`/${userid.claims.rol}/welcome`),
          },
        ],
      };
    });
  };

  const manualSearch = (term) => {
    if (term !== "") {
      setSearchedTerm((prev) => term);
      const EmployeesNameFilter = {
        label: "Nombre",
        param: "q",
        value: term,
      };
      setLoading(true);

      getFilteredEmployees(userid, [EmployeesNameFilter])
        .then((res) => {
          let employeesData = res.data.data;
          if (employeesData.length > 0) {
            setRows((ir) => employeesData);
            setSearched(true);
            setLoading(false);
          } else {
            setLoading(false);
            setAlert({
              open: true,
              message: "No se encontraron empleados con los valores ingresados",
              severity: "warning",
              duration: 6000,
            });
            setRows((ir) => []);
          }
        })
        .catch((err) => {
          console.log(err);
          setSearched(false);
          getResponseFromError(err);
        });
    }
  };

  const handleDeleteSearchTerm = () => {
    getAllEmployees(userid, userid)
      .then((res) => setRows(res.data))
      .catch((err) => setDialog({ ...dialog, open: true, title: "Error" }));
    setSearched(false);
    setSearchedTerm("");
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: !alert.open });
  };

  useEffect(() => {
    const getData = async () => {
      const employeesData = await getAllEmployees(userid, userid.token);
      try {
        if (employeesData.status === 200) {
          setLoading(false);
          setRows(employeesData.data);
        }
      } catch (err) {
        throw new Error(err);
      }
    };

    getData().catch((err) => {
      console.log(err);
      getResponseFromError(err);
    });
  }, []);

  return (
    <>
      <AlertDialog {...dialog} />
      <Snackbar
        open={alert.open}
        autoHideDuration={alert.duration}
        onClose={handleCloseAlert}
      >
        <Alert severity={alert.severity}>{alert.message}</Alert>
      </Snackbar>
      <Grid
        container
        spacing={2}
        sx={{
          padding: "2rem",
          justifyContent: "center",
          alignItems: "center",
          justifyItems: "center",
          alignContent: "center",
        }}
      >
        <Grid item xs={3} display="flex" justifyContent={"left"}>
          <Typography variant="h4" align="center" color="primary.dark">
            Empleados
          </Typography>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent={"left"}>
          <ConsultSearchComponent
            label={`Buscar empleado por: ${BACKEND_CONFIG.COMPONENTS.EMPLOYEES.ABSTRACT_SEARCH_KEYS.join(
              ", "
            )}`}
            onSearch={manualSearch}
            handleClearSerch={handleDeleteSearchTerm}
          />
        </Grid>
        <Grid item xs={3} display="flex" justifyContent={"right"}>
          <Button
            variant="contained"
            color="secondary"
            sx={{ color: "white" }}
            onClick={() => navigate(`/${userid.claims.rol}/empleados/crear`)}
          >
            Registrar empleado
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            "& .header-table": {
              backgroundColor: ferbyTheme.palette.primary.main,
              color: "white",
            },
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <DataGrid
              columns={employeesColumns}
              rows={rows}
              getRowId={(row) => row._id}
              onSelectionModelChange={(params) => {
                console.log(params);
              }}
              disableSelectionOnClick
              disableColumnMenu
              autoHeight
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
