import React, {useState} from "react";
import {AlertDialog} from "../components/userFeedback/AlertDialog";
import {Alert, Snackbar} from "@mui/material";

const AppContext = React.createContext();

const AppContextProvider = ({children}) => {
  let employeesVerificationResponse = [];
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    message: "",
    actions: [],
  });
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleDialogState = () => {
    setDialog((dialogState) => {
      return {...dialogState, open: !dialogState.open};
    });
  };

  const resetDialog = () => {
    setDialog({
      open: false,
      title: "",
      message: "",
      actions: [],
    });
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({...alert, open: false});
  };

  return (
    <AppContext.Provider
      value={{
        employeesVerificationResponse,
        dialog,
        setDialog,
        resetDialog,
        alert,
        setAlert,
      }}
    >
      <Snackbar
        open={alert.open}
        autoHideDuration={5000}
        onClose={handleCloseSnackBar}
      >
        <Alert severity={alert.severity}>{alert.message}</Alert>
      </Snackbar>
      <AlertDialog
        open={dialog.open}
        updateOpenState={handleDialogState}
        actions={dialog.actions}
        title={dialog.title}
        content={dialog.message}
      />
      {children}
    </AppContext.Provider>
  );
};

export {AppContext, AppContextProvider};
