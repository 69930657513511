export const VehicleValidation = {
    Type: {
        isTouched: false,
        isValid: false,
        message: 'El tipo de vehiculo es requerido'
    },
    Niv: {
        isTouched: false,
        isValid: true,
        message: 'El número de Identificación Vehicular es requerido'
    }
}