import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, FormControl, Typography } from "@mui/material";

const ConsultSearchComponent = ({
  width,
  label,
  onSearch,
  handleClearSerch,
  disabled,
}) => {
  //** The state to store the search string from user input
  const [searchTerm, setSearchTerm] = useState("");

  //** The function to handle the input change (Textfield onChange event)
  const handleInputChange = (event) => {
    if (event.target.value === "") {
      handleClear();
    }
    setSearchTerm(event.target.value);
    onSearch(event.target.value);
  };

  //* Parent component can pass a prop to search a value in its own API based of the search term
  const handleSearch = () => {
    onSearch(searchTerm);
  };

  //* Clears the internal user input string state and calls the parent component function to clear the search in its own internal state
  const handleClear = () => {
    setSearchTerm("");
    handleClearSerch();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", width: width }}>
      <TextField
        label={label}
        variant="outlined"
        value={searchTerm}
        onChange={handleInputChange}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        sx={{ width: "100%" }}
        InputProps={{
          endAdornment: (
            <div style={{ display: "flex", alignItems: "center" }}>
              {searchTerm && (
                <IconButton onClick={handleClear}>
                  <CloseIcon />
                </IconButton>
              )}
              <IconButton onClick={handleSearch} color="primary">
                <SearchIcon />
              </IconButton>
            </div>
          ),
        }}
      />
    </div>
  );
};

ConsultSearchComponent.defaultProps = {
  label: "Buscar",
  onSearch: () => {},
  width: "100%",
};

const SelectedValueComponent = ({ value, deleteFunction, label }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid #ccc",
          padding: "1vh",
        }}
      >
        <div style={{ marginRight: "20px" }}>
          <Typography variant="caption">{label}</Typography>
          <Typography variant="h6" color="primary">
            {value}
          </Typography>
        </div>

        <IconButton onClick={deleteFunction} aria-label="delete" size="large">
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

SelectedValueComponent.defaultProps = {
  label: "Valor",
  deleteFunction: () => {
    return;
  },
  value: "",
};

export { ConsultSearchComponent, SelectedValueComponent };
