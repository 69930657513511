import { SERVICES_API_CONTEXT } from "./services";
import { objectToQueryString, filtersToQueryString } from "../helpers";

export const createService = (user, credential, body) => {
  const getPromise = SERVICES_API_CONTEXT("main", "create");

  return getPromise(user, credential, body);
};

export const updateService = (user, credential, serviceId, body) => {
  const getPromise = SERVICES_API_CONTEXT("main", "update");

  return getPromise(user, credential, serviceId, body);
};

export const updateTransportService = (user, credential, serviceId, body) => {
  const getPromise = SERVICES_API_CONTEXT("transportService", "update");

  return getPromise(user, credential, serviceId, body);
};

export const getServices = (user, credential, pagination, filters) => {
  const getPromise = SERVICES_API_CONTEXT("main", "getAll");

  return getPromise(user, credential, pagination, filters);
};

export const getFilteredServices = (user, filters) => {
  const getPromise = SERVICES_API_CONTEXT("main", "getFiltered");
  const stringifiedFilter = filters.map((filter) => {
    return `${filter.param}=${filter.value}`;
  });

  return getPromise(user, stringifiedFilter);
};

export const getAllServices = (user, credential, pagination, filters) => {
  const getPromise = SERVICES_API_CONTEXT("main", "getAll"); //getAll ??

  return getPromise(
    user,
    credential,
    objectToQueryString(pagination),
    filtersToQueryString(filters)
  );
};

export const getServiceById = (user, credential, serviceId) => {
  const getPromise = SERVICES_API_CONTEXT("main", "getOne");

  return getPromise(user, credential, serviceId);
};

export const getLatestServiceFolio = (userid, credential) => {
  const getPromise = SERVICES_API_CONTEXT("main", "getLatestFolio");
  return getPromise(userid, credential);
};

export const buildInvoiceFromService = (userid, credential, serviceId) => {
  const thePromise = SERVICES_API_CONTEXT("main", "buildInvoiceFromService");
  return thePromise(userid, credential, serviceId);
};

export const createServiceEvidence = (userid, credential, serviceId, data) => {
  const thePromise = SERVICES_API_CONTEXT("main", "createEvidence");
  return thePromise(userid, userid, serviceId, data);
};

export const downloadEvidence = (userid, credential, serviceId, fileId) => {
  const thePromise = SERVICES_API_CONTEXT("main", "downloadEvidence");
  return thePromise(userid, credential, serviceId, fileId);
};

export const updateServiceEvidence = (
  userid,
  credential,
  serviceId,
  newEvidence
) => {
  const thePromise = SERVICES_API_CONTEXT("main", "updateEvidence");
  return thePromise(userid, credential, serviceId, newEvidence);
};
