import GOVERNMENT_CONFIG from "../../../utils/GOV_CONFIG";

function isEmptyObject(obj) {
  if (typeof obj === "object" && obj !== null && obj !== undefined) {
    return Object.keys(obj).length === 0;
  }
  return true;
}
function notNull(value) {
  return value !== null;
}
function notUndefined(value) {
  return value !== undefined;
}
function notEmptyString(string) {
  return string !== "" && string !== "";
}
function allValuesTrue(obj) {
  let isOK = false;
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      if (
        obj[key].every((value) => typeof value === "boolean") &&
        obj[key].every((value) => value === true)
      ) {
        isOK = true;
      }

      if (obj[key].every((value) => typeof value === "object")) {
        obj[key].forEach((obj2) => {
          if (obj2.valid) {
            isOK = true;
          } else {
            isOK = false;
            console.log("Invalid value in: " + key);
            console.log(obj2);
          }
        });
      }
    }

    if (obj.hasOwnProperty(key) && obj[key] !== true) {
      isOK = false;
    }
  }
  return isOK;
}

function validateTransportItem(item, objects) {
  let {CompanyLoad, CompanyUnload, Operadores, SubTotal, Vehicles, Code} = item;
  let valid = false;
  let validStates = {
    "Compañía de carga": false,
    "Compañía de descarga": false,
    Operadores: new Array(Operadores?.length || 0).fill(false),
    "SubTotal-Transporte": false,
    Unidades: new Array(Vehicles?.length || 0).fill({
      valid: false,
      validStates: {Remolque: false, Tractor: false},
    }),
    Code: false,
  };

  //validate service code
  if (!isEmptyObject(Code)) {
    validStates.Code = true;
  }

  //Validate Loading Companies
  const {Address} = CompanyLoad;
  if (
    !isEmptyObject(CompanyLoad) &&
    CompanyLoad.hasOwnProperty("Rfc") &&
    CompanyLoad.hasOwnProperty("TaxName") &&
    CompanyLoad.hasOwnProperty("Address") &&
    Address.hasOwnProperty("ZipCode")
  ) {
    validStates["Compañía de carga"] = true;
  }
  if (
    !isEmptyObject(CompanyUnload) &&
    CompanyUnload.hasOwnProperty("Rfc") &&
    CompanyUnload.hasOwnProperty("TaxName") &&
    CompanyUnload.hasOwnProperty("Address") &&
    Address.hasOwnProperty("ZipCode")
  ) {
    validStates["Compañía de descarga"] = true;
  }

  //validate subtotal
  if (!isNaN(SubTotal) && SubTotal > 0) {
    validStates["SubTotal-Transporte"] = true;
  }

  //validate Operator id (just that it exists)
  if (Operadores && Operadores.length > 0) {
    Operadores.forEach((operator, index) => {
      if (
        notNull(operator) &&
        notUndefined(operator) &&
        notEmptyString(operator)
      ) {
        validStates.Operadores[index] = true;
      }
    });
  }

  //validate vehicle
  if (Vehicles && Vehicles.length > 0) {
    Vehicles.forEach((vehicle, index) => {
      if (
        !isEmptyObject(vehicle.Tractor) &&
        notNull(vehicle.Tractor) &&
        notUndefined(vehicle.Tractor) &&
        notEmptyString(vehicle.Tractor)
      ) {
        validStates.Unidades[index].validStates.Tractor = true;
      }
      //Validate Remolque if needed
      if (
        GOVERNMENT_CONFIG.INVOICES.COMPLEMENTS.CARTA_PORTE.VEHICLES_REQUIREMENTS
          .REQUIRE_REMOLQUE_BY_TYPE[vehicle.Tractor.type]
      ) {
        // TODO: REMOVE THIS HARDCODED ARRAY. INSTEAD ADD REMOLQUES AS ARRAY IN THE FORM
        [vehicle.Remolque].forEach((remo, remo_index) => {
          if (notNull(remo) && notUndefined(remo) && notEmptyString(remo)) {
            validStates.Unidades[index].validStates.Remolque = true;
          } else {
            validStates.Unidades[index].validStates.Remolque = false;
            return;
          }
        });
      }

      if (
        validStates.Unidades[index].validStates.Remolque &&
        validStates.Unidades[index].validStates.Tractor
      ) {
        validStates.Unidades[index].valid = true;
      }
    });
  }

  if (allValuesTrue(validStates)) {
    valid = true;
  }

  return {valid, validStates};
}

function validateManeuverItem(item) {
  let {TipoManiobra, Empleados, SubTotal} = item;
  let valid = false;
  let validStates = {
    TipoManiobra: false,
    Empleados: false,
    "SubTotal-Maniobra": false,
  };

  //validate subtotal
  if (!isNaN(SubTotal) && SubTotal > 0) {
    validStates["SubTotal-Maniobra"] = true;
  }
  //validate Empleados
  if (Array.isArray(Empleados)) {
    if (Empleados.length > 0) {
      validStates.Empleados = true;
    }
  }
  //validate tipo maniobra
  if (
    notNull(TipoManiobra) &&
    notUndefined(TipoManiobra) &&
    notEmptyString(TipoManiobra)
  ) {
    validStates.TipoManiobra = true;
  }

  if (allValuesTrue(validStates)) {
    valid = true;
  }

  return {valid, validStates};
}

function validateStayItem(item) {
  let {TipoEstadia, Code, SubTotal, Tiempo, Riesgo} = item;
  let valid = false;
  let validStates = {
    TipoEstadia: false,
    Code: false,
    SubTotal: false,
    Tiempo: false,
    Riesgo: false,
  };

  //validate subtotal
  if (!isNaN(SubTotal) && SubTotal > 0) {
    validStates.SubTotal = true;
  }
  //validate tiempo
  if (!isNaN(Tiempo) && Tiempo > 0) {
    validStates.Tiempo = true;
  }
  //validate riesgo
  if (!isNaN(Riesgo) && Riesgo > 0 && Riesgo <= 10) {
    validStates.Riesgo = true;
  }

  //validate tipo estadia
  if (
    notNull(TipoEstadia) &&
    notUndefined(TipoEstadia) &&
    notEmptyString(TipoEstadia)
  ) {
    validStates.TipoEstadia = true;
  }
  //validate code
  if (notNull(Code) && notUndefined(Code) && notEmptyString(Code)) {
    validStates.Code = true;
  }

  if (allValuesTrue(validStates)) {
    valid = true;
  }

  return {valid, validStates};
}

export {
  validateTransportItem,
  validateManeuverItem,
  validateStayItem,
  isEmptyObject,
  notUndefined,
};
