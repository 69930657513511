import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { DragAndDrop, DisplayFilePreview } from "../../DragAndDrop/DragAndDrop";
import useAuth from "../../../hooks/useAuth";
import {
  getProductsServices,
  getProductsServicesCcp,
  getUnitsCatalog,
} from "../../../services/modules/invoices/catalogs";
import CheckBoxesList from "../../actions/CheckBoxesList";
import { TransportItem } from "../../../modules/services/utils/ServiceItemsENUM";

const TransportServiceProductsForm = ({
  addProduct,
  handleChangeWeightUnit,
  sendFile,
  loading,
}) => {
  const { userid } = useAuth();
  const [loadingData, setLoadingData] = useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const [showDangerousMaterial, setShowDangerousMaterial] = useState(false);
  const [csv, setCsv] = useState();
  const [internalLoadedProducts, setInternalLoadedProducts] = useState([]);
  const [options, setOptions] = useState({
    weightUnits: [],
    products: [],
    dangerousMaterials: [],
    embalajes: [],
  });
  const [selectedUnit, setSelectedUnit] = useState(
    TransportItem.Mercancias.UnidadPeso
  );
  const [inputProduct, setInputProduct] = useState({
    productCode: "",
    claveUnidad: "",
    dangerousMaterialCode: "",
    embalaje: "",
    pesoBrutoTotal: "",
    cantidad: "",
    pesoEnKg: "",
    moneda: "MXN",
  });
  const [alert, setAlert] = useState({
    show: false,
    state: "",
    message: "",
  });
  const [checkedItems, setCheckedItems] = useState([false, false]);

  const handleSearch = () => {
    setShowInputs(!showInputs);
  };

  const handleErrorUi = (err) => {
    if (err.response.status === 400) {
      setAlert({
        ...alert,
        show: true,
        state: "error",
        message: "Error de autorización. Recarga la página",
      });
      return;
    }

    if (err.response.status === 404) {
      setAlert({
        ...alert,
        show: true,
        state: "error",
        message: "No se encontraron datos con esa descripción",
      });
      return;
    }

    if (err.response.status === 500) {
      setAlert({
        ...alert,
        show: true,
        state: "error",
        message: "Error del servidor. Intentalo de nuevo más tarde.",
      });
      return;
    }

    setAlert({
      ...alert,
      show: true,
      state: "error",
      message: "Hubo un error desconocido. Contacta a soporte.",
    });
  };

  const deleteEmptyValues = (product) => {
    const updatedProduct = { ...product };
    for (const key in updatedProduct) {
      if (updatedProduct.hasOwnProperty(key)) {
        const value = updatedProduct[key];
        if (typeof value === "string" && value.trim() === "") {
          updatedProduct[key] = undefined;
        } else if (typeof value === "object" && !Array.isArray(value)) {
          const isEmptyObject = Object.keys(value).length === 0;
          if (isEmptyObject) {
            updatedProduct[key] = undefined;
          }
        }
      }
    }
    return updatedProduct;
  };

  const handleAddProduct = (e) => {
    // Check if any of the required states in inputProduct is empty string or 0
    const isDangrousAndDontHaveDangerousMaterial = () => {
      return (
        isDangerousMaterial(inputProduct) &&
        checkedItems[0] &&
        (inputProduct.dangerousMaterialCode.Name === "" ||
          inputProduct.dangerousMaterialCode.Value === "" ||
          inputProduct.embalaje === "" ||
          inputProduct.embalaje === "0")
      );
    };
    const isInvalid =
      isDangrousAndDontHaveDangerousMaterial(inputProduct) &&
      (inputProduct.productCode.Value === "" ||
        inputProduct.productCode.Name === "" ||
        inputProduct.claveUnidad.Name === "" ||
        inputProduct.claveUnidad.Value === "" ||
        inputProduct.pesoBrutoTotal === "" ||
        inputProduct.pesoBrutoTotal === 0 ||
        inputProduct.cantidad === 0 ||
        inputProduct.pesoEnKg === 0);

    if (isInvalid) {
      // Handle the case when any of the required or non-required states is empty string or 0
      // Display an error message or perform any other action
      setAlert({
        show: true,
        state: "warning",
        message: "Completa los datos de la mercancía",
      });
    } else {
      // Call the addProduct function and pass the inputProduct state

      const copy = [...internalLoadedProducts, inputProduct];

      const totalWeigth = copy.reduce((prev, acc) => {
        return prev + acc.pesoEnKg * acc.cantidad;
      }, 0);
      setInternalLoadedProducts(copy);
      setInputProduct({
        ...inputProduct,
        pesoBrutoTotal: totalWeigth,
      });

      const emptyDeletedInput = deleteEmptyValues(inputProduct);
      addProduct(emptyDeletedInput);
    }
  };

  const submitCSVProducts = (file) => {
    if (
      selectedUnit.Value === undefined ||
      selectedUnit.Name === undefined ||
      selectedUnit.Value === "" ||
      selectedUnit.Name === ""
    ) {
      setAlert({
        show: true,
        state: "warning",
        message: "Completa los datos generales",
      });
      return;
    } else {
      setCsv(file);
    }
  };

  const handleSendFile = () => {
    sendFile(csv);
  };

  const handleInputs = (e) => {
    if (e.target.id === "cantidad" || e.target.id === "pesoEnKg") {
      setInputProduct({
        ...inputProduct,
        [e.target.id]: Number(e.target.value),
      });
      return;
    }

    setInputProduct({ ...inputProduct, [e.target.id]: e.target.value });
  };

  const handleClose = () => {
    setAlert({ ...alert, show: !alert.show, title: "", message: "" });
  };

  const handleChangeAutoComplete = (e, v, r) => {
    const id = e.target.id.split("-")[0];
    if (r === "selectOption" && id !== "claveUnidad") {
      if (id === "internalClaveUnidad") {
        setInputProduct({
          ...inputProduct,
          claveUnidad: v,
        });
        return;
      }

      if (id === "productCode") {
        setShowDangerousMaterial(isDangerousMaterial(v));
      }

      setInputProduct({
        ...inputProduct,
        [id]: v,
      });
    }

    if (id === "claveUnidad") {
      setSelectedUnit(v);
      handleChangeWeightUnit(e, v, r);
    }
  };

  //*TODO: UPDATE METHOD TO GET THE EMBALAJES CATALOG (IMPORT FROM SERVICES)
  const getEmbalajesCatalog = (userid, companyid, search) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve([
          {
            Name: "Caja",
            Value: "Caja",
          },
          {
            Name: "Bolsa",
            Value: "Bolsa",
          },
          {
            Name: "Sobre",
            Value: "Sobre",
          },
        ]);
      }, 1000);
    });
  };

  const fetchCatalogData = (category, searchTerm) => {
    let apiMethod;

    switch (category) {
      case "weightUnits":
        apiMethod = getUnitsCatalog;
        break;
      case "products":
        apiMethod = getProductsServicesCcp;
        break;
      case "dangerousMaterials":
        //*TODO: UPDATE METHOD TO GET THE DANGEROUS MATERIALS CATALOG
        apiMethod = getProductsServicesCcp;
        break;
      case "embalajes":
        apiMethod = getEmbalajesCatalog;
        break;
      default:
        break;
    }

    if (searchTerm && searchTerm.length > 2) {
      apiMethod(userid, userid, searchTerm)
        .then((response) => {
          if (response.data.length > 0) {
            setOptions({ ...options, [category]: response.data });
            setLoadingData(false);
          }
        })
        .catch((err) => {
          handleErrorUi(err);
        });
    }
  };

  const handleChangeUnitInputTextField = (e, newValue) => {
    if (e && e?.target?.value) {
      fetchCatalogData("weightUnits", newValue);
    }
  };

  const isDangerousMaterial = (product) => {
    return (
      product.DangerousMaterial !== "0" || product.DangerousMaterial !== ""
    );
  };

  const handleChangeProductTextField = (e) => {
    if (e && e?.target?.value?.length > 2) {
      fetchCatalogData("products", e.target.value);
    }
  };

  const handleChangeDangerousMaterialTextField = (e) => {
    if (e && e?.target?.value?.length > 2) {
      fetchCatalogData("dangerousMaterials", e.target.value);
    }
  };

  const handleCheckDangerousMaterial = useCallback(
    (e, index) => {
      setCheckedItems((prevChecked) => {
        const copy = [...prevChecked];
        copy[index] = !copy[index];

        // Update the inverse value of all other indexes
        for (let i = 0; i < copy.length; i++) {
          if (i !== index) {
            copy[i] = !copy[index];
          }
        }

        return copy;
      });
    },
    [setCheckedItems]
  );

  const handleChangeEmbalajeTextField = (e) => {
    if (e && e?.target?.value?.length > 2) {
      fetchCatalogData("embalajes", e.target.value);
    }
  };

  const handleChangeEmbalajeDescription = (e) => {
    setInputProduct({
      ...inputProduct,
      embalaje: {
        ...inputProduct.embalaje,
        Description: e.target.value,
      },
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Tooltip title="Unidad de medida estandar para el peso bruto total">
            <Autocomplete
              id="claveUnidad"
              options={options.weightUnits}
              sx={{ width: "65%" }}
              autoComplete
              clearText="Limpiar"
              onChange={handleChangeAutoComplete}
              onInputChange={handleChangeUnitInputTextField}
              filterOptions={(x) => x}
              includeInputInList
              filterSelectedOptions
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Unidad de peso"
                    name="Load"
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              getOptionLabel={(option) => {
                if (!option) {
                  return "";
                }

                return `${option?.Name} - ${option?.Value}`;
              }}
              renderOption={(props, option) => {
                return <h4 {...props}>{`${option.Name} - ${option.Value}`}</h4>;
              }}
              isOptionEqualToValue={(option, value) => {
                if (value === "") {
                  return true;
                }

                return option.Value === value.Value;
              }}
              value={selectedUnit || null}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <TextField
            id="pesoBrutoTotal"
            value={inputProduct.pesoBrutoTotal}
            label="Peso bruto total"
            variant="outlined"
            disabled
          />
        </Grid>
        {!showInputs ? (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <DragAndDrop
              submit={submitCSVProducts}
              fileExtension={".csv"}
              componentId={"contained-button-file"}
              disabled={loading}
            />
            {csv && (
              <>
                <DisplayFilePreview file={csv} />
                <Button
                  onClick={handleSendFile}
                  disabled={loading}
                  sx={{ color: "white", width: "100%" }}
                  variant="contained"
                  color="secondary"
                >
                  Procesar archivo
                </Button>
              </>
            )}
          </Grid>
        ) : null}

        {!csv && (
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={handleSearch}
              disabled={loadingData}
              sx={{ color: "white" }}
              variant="contained"
              color="secondary"
            >
              {showInputs ? "Cancelar Búsqueda" : "Buscar manualmente"}
            </Button>
          </Grid>
        )}

        {showInputs && (
          <>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Autocomplete
                id="productCode"
                options={options.products}
                sx={{ width: "65%" }}
                autoComplete
                clearText="Limpiar"
                onChange={handleChangeAutoComplete}
                onInputChange={handleChangeProductTextField}
                filterOptions={(x) => x}
                includeInputInList
                filterSelectedOptions
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Mercancia"
                      name="Load"
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  );
                }}
                getOptionLabel={(option) => {
                  if (!option) {
                    return "";
                  }

                  return `${option?.Name} - ${option?.Value}`;
                }}
                renderOption={(props, option) => {
                  return (
                    <h4 {...props}>{`${option.Name} - ${option.Value}`}</h4>
                  );
                }}
                isOptionEqualToValue={(option, value) => {
                  if (value === "") {
                    return true;
                  }

                  return option.Value === value.Value;
                }}
                disabled={loadingData}
                value={inputProduct?.productCode || null}
              />
            </Grid>
            {showDangerousMaterial && (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Collapse in={showDangerousMaterial}>
                  <Alert severity="warning">
                    <AlertTitle>Advertencia</AlertTitle>
                    {inputProduct?.productCode?.DangerousMaterial == "1"
                      ? `La mercancía seleccionada es peligrosa. Debes configurar algunos datos adicionales.`
                      : `La mercancía seleccionada no es peligrosa de acuerdo a la ley, sin embargo debes determinar 
                        si tu la consideras peligrosa y completar los datos adicionales.`}
                  </Alert>
                </Collapse>

                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ margin: "2vh", textAlign: "center" }}
                  >
                    ¿La mercancía es peligrosa?
                  </Typography>
                  <CheckBoxesList
                    checkedItems={checkedItems}
                    items={["Sí", "No"]}
                    setCheckedItems={handleCheckDangerousMaterial}
                  />
                </Grid>
                {checkedItems[0] && (
                  <>
                    <Autocomplete
                      id="dangerousMaterialCode"
                      options={options.dangerousMaterials}
                      sx={{ width: "65%" }}
                      autoComplete
                      clearText="Limpiar"
                      onChange={handleChangeAutoComplete}
                      onInputChange={handleChangeDangerousMaterialTextField}
                      filterOptions={(x) => x}
                      includeInputInList
                      filterSelectedOptions
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label="Material peligroso"
                            name="Load"
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        );
                      }}
                      getOptionLabel={(option) => {
                        if (!option) {
                          return "";
                        }

                        return `${option?.Name} - ${option?.Value}`;
                      }}
                      renderOption={(props, option) => {
                        return (
                          <h4
                            {...props}
                          >{`${option.Name} - ${option.Value}`}</h4>
                        );
                      }}
                      isOptionEqualToValue={(option, value) => {
                        if (value === "") {
                          return true;
                        }

                        return option.Value === value.Value;
                      }}
                      disabled={loadingData}
                      value={
                        inputProduct?.dangerousMaterialCode?.Value !== "" &&
                        inputProduct?.dangerousMaterialCode?.Name !== ""
                          ? inputProduct?.dangerousMaterialCode
                          : null
                      }
                    />
                    <Autocomplete
                      id="embalaje"
                      options={options.embalajes}
                      sx={{ width: "65%" }}
                      autoComplete
                      clearText="Limpiar"
                      onChange={handleChangeAutoComplete}
                      onInputChange={handleChangeEmbalajeTextField}
                      filterOptions={(x) => x}
                      includeInputInList
                      filterSelectedOptions
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label="Embalaje"
                            name="Embalaje"
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        );
                      }}
                      getOptionLabel={(option) => {
                        if (!option) {
                          return "";
                        }

                        return `${option?.Name} - ${option?.Value}`;
                      }}
                      renderOption={(props, option) => {
                        return (
                          <h4
                            {...props}
                          >{`${option.Name} - ${option.Value}`}</h4>
                        );
                      }}
                      isOptionEqualToValue={(option, value) => {
                        if (value === "") {
                          return true;
                        }

                        return option.Value === value.Value;
                      }}
                      disabled={loadingData}
                      value={
                        inputProduct?.embalaje?.Value !== "" &&
                        inputProduct?.embalaje?.Name !== ""
                          ? inputProduct?.embalaje
                          : null
                      }
                    />
                    <TextField
                      variant="outlined"
                      label="Descripciòn del embalaje"
                      multiline
                      value={
                        inputProduct?.embalaje !== ""
                          ? inputProduct?.embalaje?.Description
                          : ""
                      }
                      onChange={handleChangeEmbalajeDescription}
                    />
                  </>
                )}
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Autocomplete
                id="internalClaveUnidad"
                options={options.weightUnits}
                sx={{ width: "65%" }}
                autoComplete
                clearText="Limpiar"
                onChange={handleChangeAutoComplete}
                onInputChange={handleChangeUnitInputTextField}
                filterOptions={(x) => x}
                includeInputInList
                filterSelectedOptions
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Unidad de peso"
                      name="Load"
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  );
                }}
                getOptionLabel={(option) => {
                  if (!option) {
                    return "";
                  }

                  return `${option?.Name} - ${option?.Value}`;
                }}
                renderOption={(props, option) => {
                  return (
                    <h4 {...props}>{`${option.Name} - ${option.Value}`}</h4>
                  );
                }}
                isOptionEqualToValue={(option, value) => {
                  if (value === "") {
                    return true;
                  }

                  return option.Value === value.Value;
                }}
                value={inputProduct.claveUnidad || null}
                disabled={loadingData}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <TextField
                onChange={handleInputs}
                sx={{ width: "65%", margin: "2vh" }}
                id="cantidad"
                type="number"
                label="Cantidad"
                variant="standard"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Tooltip title="El peso es por unidad (ej. Peso de una tarima de cajas de refresco)">
                <TextField
                  onChange={handleInputs}
                  sx={{ width: "65%", margin: "2vh" }}
                  variant="standard"
                  id="pesoEnKg"
                  type="number"
                  label={`Peso en ${
                    inputProduct.claveUnidad.Value || "la unidad de medida"
                  }`}
                />
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                id="add_item"
                onClick={handleAddProduct}
                sx={{ margin: "2vh", background: "green", color: "white" }}
                variant="contained"
              >
                Agregar mercancía
              </Button>
            </Grid>
          </>
        )}
      </Grid>

      {internalLoadedProducts.length > 0 && (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{ margin: "2vh", textAlign: "center" }}
          >
            Mercancías agregadas
          </Typography>
          {internalLoadedProducts.map((product, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{ margin: "2vh", textAlign: "center" }}
              >
                {product.productCode.Name} - {product.productCode.Value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
      <Snackbar open={alert.show} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity={alert.state || "info"}>{alert.message}</Alert>
      </Snackbar>
    </>
  );
};

export { TransportServiceProductsForm };
