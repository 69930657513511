//*this should come from the backend
const GOVERNMENT_CONFIG = {
  INVOICES: {
    GENERAL: {
      DECIMALS: 6,
      INVOICE_TYPES: {
        INCOME: {
          Name: "Ingreso",
          Value: "I",
        },
        PAYMENTS: {
          Name: "Pagos",
          Value: "P",
        },
        NOMINA: {
          Name: "Nómina",
          Value: "N",
        },
      },
    },
    OPERATIONS: {
      CANCEL: {
        MOTIVES: {
          ERROR_RELATION: "01",
          ERROR_NO_RELATION: "02",
          OPERATION_NOT_EXECUTED: "03",
          GLOBAL_INVOICE_RELATED: "04",
        },
      },
    },
    COMPLEMENTS: {
      CARTA_PORTE: {
        VEHICLES_REQUIREMENTS: {
          REQUIRE_REMOLQUE_BY_TYPE: {
            VL: false,
            C2: false,
            C3: false,
            C2R2: true,
            C3R2: true,
            C2R3: true,
            C3R3: true,
            T2S1: true,
            T2S2: true,
            T2S3: true,
            T3S1: true,
            T3S2: true,
            T3S3: true,
            T2S1R2: true,
            T2S2R2: true,
            T2S1R3: true,
            T3S1R2: true,
            T3S1R3: true,
            T3S2R2: true,
            T3S2R3: true,
            T3S2R4: true,
            T2S2S2: true,
            T3S2S2: true,
            T3S3S2: true,
            OTROEVGP: true,
            OTROSG: true,
            GPLUTA: true,
            GPLUTB: true,
            GPLUTC: true,
            GPLUTD: true,
            GPLATA: true,
            GPLATB: true,
            GPLATC: true,
            GPLATD: true,
          },
        },
      },
    },
  },
};

export default GOVERNMENT_CONFIG;
