import AddressDto from "./address.dto";
import CfdiTypeDto from "./cfdiType.dto";
import PaymentMethodDto from "./paymentMethod.dto";
import IssuerDto from "./issuer.dto.json";
import ReceiverDto from "./invoice-receiver.dto.json";

const InvoiceGeneralModelDto = {
  CfdiType: CfdiTypeDto,
  PaymentMethod: PaymentMethodDto,
  ExpeditionPlace: AddressDto,
  Date: new Date(),
  Folio: "",
  Issuer: IssuerDto,
  Receiver: ReceiverDto,
};

export default InvoiceGeneralModelDto;
