import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Button, InputAdornment, LinearProgress } from "@mui/material";
import { FormControl } from "@mui/material";
import { getPostalCodesCatalog } from "../../../services/modules/invoices/catalogs";
import useAuth from "../../../hooks/useAuth";
import { GeneralAddressForm } from "../Places/ExpeditionPlaceForm";
import AddressDto from "../../../utils/dto/address.dto";

//*TODO: RENAME TO FISCAL ADDRESS FORM (THIS SHOULD FOLLOW THE GOVERNMENT'S FORMAT AND ALSO HAVE THE lattitude and longitude properties)
const CustomerAddressForm = React.forwardRef(
  (
    {
      type = "Cliente",
      setter = function test() {
        console.log("algo falló mijo");
      },
      label = null,
      dialog,
      setDialog,
      typeHook,
    },
    ref
  ) => {

    let addressType = type;
    let name = label !== null ? label : addressType;

    const { userid } = useAuth();

    const addressDto = typeHook.Address || JSON.parse(JSON.stringify(AddressDto));
    const [address, setAddress] = React.useState(
      addressDto
    );

    const handleAddress = (e, v, r) => {
      setter(e, v, r);
    };

    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Dirección fiscal de {name}
        </Typography>
        <GeneralAddressForm
          formData={address}
          setFormData={handleAddress}
          userid={userid}
          required={["ZipCode"]}
          ref={ref}
        />
      </React.Fragment>
    );
  }
);

export default CustomerAddressForm;
