import React, { useState } from 'react';
import { List, ListItem, Checkbox, ListItemText, Divider } from '@mui/material';

const ShowColumns = ({ columns, columnVisibility, setColumnVisibility }) => {
  const [columnVisibilityLocal, setColumnVisibilityLocal] =
    useState(columnVisibility);

  const handleColumnToggle = (columnField) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [columnField]: !prevVisibility[columnField],
    }));

    const updatedVisibility = { ...columnVisibilityLocal };
    updatedVisibility[columnField] = !updatedVisibility[columnField];
    setColumnVisibilityLocal(updatedVisibility);
  };

  return (
    <List>
      {columns.map((column) => (
        <div key={column.field}>
          <ListItem onClick={() => handleColumnToggle(column.field)}>
            <Checkbox checked={columnVisibilityLocal[column.field]} />
            <ListItemText primary={column.headerName} />
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  );
};

export default ShowColumns;
