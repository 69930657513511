import { Button, Grid, TextField, Typography } from "@mui/material";
import { AlertDialog, InitialDialogState } from "../userFeedback/AlertDialog";
import useAuth from "../../hooks/useAuth";
import { useState } from "react";
import {
  DownloadInvoicePDFComponent,
  DownloadXMLComponent,
  MarkAsPaidComponent,
} from "../../modules/invoices/postIssue/utils/PostIssueActionsComponents";

const PostIssuingActions = ({ cfdi, type, loading, setLoading }) => {
  const [postIssueDialog, setPostIssueDialog] = useState({
    ...InitialDialogState,
    updateOpenState: (bool) =>
      setPostIssueDialog({ ...postIssueDialog, open: bool }),
  });
  const { userid } = useAuth();

  function handleLinkService() {
    setPostIssueDialog({
      ...postIssueDialog,
      open: true,
      content: (
        <>Este servicio está en desarrollo. Estamos trabajando para ti.</>
      ),
      title: "No disponible",
      actions: [
        {
          label: "Cerrar",
          execute: () =>
            setPostIssueDialog({ ...postIssueDialog, open: false }),
        },
      ],
    });
  }
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", textAlign: "center", justifyContent: "center" }}
      >
        <AlertDialog {...postIssueDialog} />
        <Grid item xs={12}>
          <Button
            onClick={handleLinkService}
            variant="contained"
            color="primary"
          >
            Vincular a Servicio
          </Button>
        </Grid>
        <Grid item xs={12}>
          <MarkAsPaidComponent invoiceId={cfdi.invoiceId} />
        </Grid>
        <Grid item xs={12}>
          <DownloadInvoicePDFComponent cfdiData={cfdi} />
        </Grid>
        <Grid item xs={12}>
          <DownloadXMLComponent cfdiData={cfdi} />
        </Grid>
      </Grid>
    </>
  );
};

PostIssuingActions.defaultProps = {
  type: "unset",
  loading: false,
  setLoading: () => {
    return;
  },
};

export default PostIssuingActions;
