//*React
import React, { useState } from "react";
//*MUI
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
//*Network and navigation
import { useNavigate } from "react-router";
//*App components
import { AlertDialog } from "../../../components/userFeedback/AlertDialog";
//*Context hooks
import useAuth from "../../../hooks/useAuth";

const InvoicesIndex = () => {
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    message: "",
    actions: [{ label: "", execute: () => {} }],
    keep: false,
  });

  //*Contexts
  const { userid } = useAuth();
  const navigate = useNavigate();

  const handleShowDialog = (bool) => {
    setDialog({
      ...dialog,
      open: bool,
    });
  };

  const WorkInProgressToGeneralData = () => {
    setDialog({
      open: true,
      title: "Seguimos Mejorando",
      message:
        "Estamos trabajando para hacer la emision mas eficiente para tu organización, por el momento este tipo de factura se debe configurar manualmente",
      actions: [
        {
          label: "Cerrar",
          execute: () => setDialog({ ...dialog, open: false }),
        },
        {
          label: "Continuar",
          execute: () => navigate(`/${userid.claims.rol}/invoices/new`),
        },
      ],
    });
  };

  const handleTransporte = () => {
    WorkInProgressToGeneralData();
  };

  const handlePago = () => {
    WorkInProgressToGeneralData();
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "10vh",
      }}
    >
      <AlertDialog
        updateOpenState={handleShowDialog}
        open={dialog.open}
        title={dialog.title}
        content={dialog.message}
        actions={dialog.actions}
        keep={dialog.keep}
      />

      <Grid container spacing={2} maxWidth={"lg"} sx={{ marginX: "auto" }}>
        <Grid item xs={12}>
          <Typography variant="h4" color="primary">
            Nueva Factura
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="secondary" gutterBottom>
                Factura de tipo
              </Typography>
              <Typography variant="h5" component="div">
                Configuración General
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="secondary">
                Activa
              </Typography>
              <Typography variant="body2">
                Genera una factura desde cero
                <Tooltip title="*Facturas soportadas" placement="right-start">
                  <Button>*</Button>
                </Tooltip>
              </Typography>
            </CardContent>
            <CardActions sx={{ marginTop: "auto", justifyContent: "right" }}>
              <Button
                onClick={() => navigate(`/${userid.claims.rol}/invoices/new`)}
                size="small"
              >
                Configurar
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="secondary" gutterBottom>
                Factura de tipo
              </Typography>
              <Typography variant="h5" component="div">
                Nómina
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="secondary">
                Activa
              </Typography>
              <Typography variant="body2">
                Para pagos de trabajadores
              </Typography>
            </CardContent>
            <CardActions sx={{ marginTop: "auto", justifyContent: "right" }}>
              <Button
                onClick={() =>
                  navigate(`/${userid.claims.rol}/empleados/nominaIndex`)
                }
                size="small"
              >
                Configurar
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="secondary" gutterBottom>
                Factura de tipo
              </Typography>
              <Typography variant="h5" component="div">
                Ingreso por Servicio de Transporte
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="secondary">
                Activa
              </Typography>
              <Typography variant="body2">
                Para facturas de servicios de traslado de mercancías
              </Typography>
            </CardContent>
            <CardActions sx={{ marginTop: "auto", justifyContent: "right" }}>
              <Button onClick={handleTransporte} size="small">
                Configurar
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="secondary" gutterBottom>
                Factura de tipo
              </Typography>
              <Typography variant="h5" component="div">
                Complemento de Pago
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="secondary">
                Activa
              </Typography>
              <Typography variant="body2">
                Para facturas que requieran este complemento, como la recepción
                de pagos en parcialidades
              </Typography>
            </CardContent>
            <CardActions sx={{ marginTop: "auto", justifyContent: "right" }}>
              <Button onClick={handlePago} size="small">
                Configurar
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/*<Grid item xs={12}>
                <ConsultInvoiceIndex paddingTop={"0vh"}/>
</Grid>*/}
      </Grid>
    </Box>
  );
};

export { InvoicesIndex };
