import React from "react";
import { Grid, Typography } from "@mui/material";

const EvidenceManagement = () => {
  return (
    <Grid container spacing={2}>
      {/* Your grid items go here */}
      <Typography variant="h4">
        Proximamente podras gestionar de forma avanzada las evidencias
      </Typography>
    </Grid>
  );
};

export default EvidenceManagement;
