import { Typography } from '@mui/material';
import { Box, Button, CircularProgress, Container, Grid } from '@mui/material';
import { useLocation } from 'react-router';

//*STRIPE CONFIG COMPONENTS
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { StripeElementsProvider } from '../components/stripe/StripeElementsProvider';
import { useState } from 'react';
import useAuth from '../hooks/useAuth';
import { StripeForm } from '../components/stripe/StripeForm';

export const PaymentFormContainer = () => {
  const { state } = useLocation();

  return (
    <section>
      <Container maxWidth='lg'>
        <Box py={8} textAlign='center'>
          <Box mb={3}>
            <Container maxWidth='sm'>
              <Typography variant='overline' color='textSecondary'>
                Qsoft
              </Typography>
              <Typography variant='h3' component='h2' gutterBottom={true}>
                <Typography variant='h5' component='span' color='primary'>
                  Introduce los datos de pago
                </Typography>
              </Typography>
            </Container>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={4}>
              <StripeElementsProvider
                stripeOptions={{
                  clientSecret: state.clientSecret,
                  fonts: [
                    {
                      cssSrc:
                        'https://fonts.googleapis.com/css?family=Open+Sans',
                    },
                  ],
                }}
              >
                <StripeForm />
              </StripeElementsProvider>
            </Grid>
            <Grid item xs={12} md={4}></Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
};
