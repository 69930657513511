import React, {useState} from "react";
import {Grid, Typography, Button} from "@mui/material";
import DynamicFormField from "../DynamicFormField";
import ListItemExpensesComponent from "../../../modules/admin/expenses/components/ListItemExpensesComponent";
import {
  ExpenseDto,
  IExpense,
  ExpensesFormConfigsById,
  ExpenseFormDateInputFieldsConfigDto,
  ExpenseFormDescriptionInputFieldsConfigDto,
  ExpensesFormTotalInputFieldConfigDto,
  ExpensesFormLinkedVehicleCheckboxConfigDto,
  ExpensesFormLinkedTrailerCheckboxConfigDto,
  ExpensesFormCategoryInputFieldConfigDto,
  ExpensesFormLinkedDriverCheckboxConfigDto,
} from "../../../modules/admin/expenses/dto/expenses.dto";
import {DynamicFieldConfig} from "../../layout/interfaces/DynamicFieldConfig.interface";
import FormItemExpenseComponent from "./FormItemExpenseComponent";
import {DragAndDrop} from "../../DragAndDrop/DragAndDrop";
import useAppContext from "../../../hooks/useAppContext";
import {toIsoDate} from "../../../utils/dateManipulators";

/**
 * Props for the FormExpenseComponent.
 */
interface FormExpenseComponentProps {
  expenseId?: string;
  expense?: IExpense;
  setExpense: React.Dispatch<React.SetStateAction<any>>;
  /**
   * Optional function to handle form submission.
   */
  onSubmit?: () => void;
  isLoading: boolean;
}

interface InputErrorHashMapInterface {
  [key: string]: {
    failed: boolean;
    message: string;
  };
}

/**
 * Represents a form component for managing expenses.
 * @component
 * @param {FormExpenseComponentProps} props - The component props.
 * @param {string} props.expenseId - The ID of the expense.
 * @param {IExpense} props.expense - The expense data.
 * @param {Function} props.setExpense - The function to set the expense data.
 * @param {boolean} props.isLoading - Indicates whether the component is in a loading state.
 * @param {React.Ref} ref - The ref object.
 * @returns {JSX.Element} The rendered form component.
 */
export const FormExpenseComponent = React.forwardRef(
  (
    {expenseId, expense, setExpense, isLoading}: FormExpenseComponentProps,
    ref
  ) => {
    const {dialog, setDialog} = useAppContext();
    const [expenseData, setExpenseData] = useState<IExpense>({...ExpenseDto});
    const [inputErrorMap, setInputErrorMap] =
      useState<InputErrorHashMapInterface>(
        Object.keys(ExpensesFormConfigsById).reduce((acc, key) => {
          acc[key] = {failed: false, message: ""};
          return acc;
        }, {})
      );
    const [showLinkedItemsForm, setShowLinkedItemsForm] = useState(false);

    const [categoryOptions, setCategoryOptions] = useState<string[]>([
      "Mantenimiento",
      "Combustible",
      "Llantas",
      "Refacciones",
      "Otros",
    ]);

    const [linkedVehicle, setLinkedVehicle] = useState<boolean>(false);
    const [linkedTrailer, setLinkedTrailer] = useState<boolean>(false);
    const [linkedDriver, setLinkedDriver] = useState<boolean>(false);

    // Directly set the disabled state for these features to true
    const [linkedVehicleDisabled, setLinkedVehicleDisabled] = useState(true);
    const [linkedTrailerDisabled, setLinkedTrailerDisabled] = useState(true);
    const [addAssociatedConceptsDisabled, setAddAssociatedConceptsDisabled] =
      useState(true);

    /**
     * Validates an input field value based on its ID and updates the input error map.
     * @param id - The ID of the input field.
     * @param data - The expense data object.
     * @returns Whether the input field value is valid or not.
     */
    const validateInputById = (id: string, data: IExpense): boolean => {
      const value = data[id];
      let isValid = true;
      if (
        ExpensesFormConfigsById[id] &&
        ExpensesFormConfigsById[id].validator
      ) {
        const {validator} = ExpensesFormConfigsById[id];

        const {failed, message} = validator(value as never);

        setInputErrorMap((prevMap) => {
          console.log("Setting error map for id: ", id, "with value: ", failed);
          return {
            ...prevMap,
            [id]: {failed, message},
          };
        });
        return !failed;
      }

      return isValid;
    };

    /**
     * Updates the expense data with a new date.
     * @param {Date} newDate - The new date value.
     */
    const handleInputDates = (event, newDate: Date) => {
      setExpenseData((prevExpense) => {
        return {
          ...prevExpense,
          Date: toIsoDate(new Date(newDate), true),
        };
      });
    };

    /**
     * Handles the input change event for the expense form.
     * @param event - The change event object.
     * @param value - The optional value parameter.
     * @param reason - The optional reason parameter.
     */
    const handleInputChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      value?: string,
      reason?: string
    ) => {
      //*STATE IS NOT UPDATED YET SO WE NEED TO USE THE VALUE PARAMETER
      validateInputById(event.target.id, {
        ...expenseData,
        [event.target.id]: event.target.value,
      });

      setExpenseData((prevExpense) => {
        return {
          ...prevExpense,
          [event.target.id]: event.target.value,
        };
      });
    };

    /**
     * Handles the change event of the autocomplete input field.
     * @param event - The change event object.
     * @param value - The selected value from the autocomplete.
     * @param reason - The reason for the change event.
     */
    const handleAutoCompleteChange = (
      event: React.ChangeEvent,
      value?: {value: string; label: string},
      reason?: string
    ) => {
      const id = event.target.id.split("-")[0];
      //*STATE IS NOT UPDATED YET SO WE NEED TO USE THE VALUE PARAMETER
      validateInputById(id, {
        ...expenseData,
        [id]: value.value,
      });

      setExpenseData((prevExpense) => {
        return {
          ...prevExpense,
          [id]: value.value,
        };
      });
    };

    const handleShowLinkedVehicle = () => {};

    const handleShowLinkedTrailer = () => {};

    const descriptionField: DynamicFieldConfig<string> = {
      ...ExpenseFormDescriptionInputFieldsConfigDto,
      onChange: handleInputChange,
    };

    const dateField: DynamicFieldConfig<string> = {
      ...ExpenseFormDateInputFieldsConfigDto,
      onChange: handleInputDates,
    };

    const totalField: DynamicFieldConfig<number> = {
      ...ExpensesFormTotalInputFieldConfigDto,
      onChange: handleInputChange,
    };

    const linkedVehicleCheckbox: DynamicFieldConfig<boolean> = {
      ...ExpensesFormLinkedVehicleCheckboxConfigDto,
      onChange: handleShowLinkedVehicle,
    };

    const linkedTrailerCheckbox: DynamicFieldConfig<boolean> = {
      ...ExpensesFormLinkedTrailerCheckboxConfigDto,
      onChange: handleShowLinkedTrailer,
    };

    const categoryField: DynamicFieldConfig<object> = {
      ...ExpensesFormCategoryInputFieldConfigDto,
      onChange: handleAutoCompleteChange,
      options: categoryOptions.map((category) => ({
        label: category,
        value: category,
      })),
    };

    //*TODO: IMPLEMENT THIS CHECKBOX, LOGIC, DTOS, SCHEMAS, BACKEND ETC
    const linkedDriverCheckbox: DynamicFieldConfig<boolean> = {
      ...ExpensesFormLinkedDriverCheckboxConfigDto,
      onChange: handleInputChange,
    };

    /**
     * Validates the expense data from the parent component.
     * @returns {boolean} True if all expense data is valid, false otherwise.
     */
    const validateFromParent = () => {
      const validations = new Array<boolean>(
        Object.keys(expenseData).length
      ).fill(true);

      Object.keys(expenseData).forEach((key, idx) => {
        const isValid = validateInputById(key, expenseData);

        validations[idx] = isValid;
      });

      return validations.every((isValid) => isValid);
    };

    /**
     * Toggles the visibility of the linked items form.
     */
    const handleShowLinkedItemsForm = () => {
      setShowLinkedItemsForm(!showLinkedItemsForm);
    };

    /**
     * Adds an item expense associated to the expense data.
     * @param {object} itemExpense - The item expense to be added.
     */
    const onAddItemExpense = (itemExpense) => {
      setExpenseData((prevExpense) => {
        const items = [...prevExpense.Items, itemExpense];
        return {
          ...prevExpense,
          Items: items,
        };
      });
    };

    const handleSubmitFiles = (files: File[] | File) => {
      const formData = new FormData();
      if (Array.isArray(files)) {
        files.forEach((file) => {
          formData.append("file", file);
        });
        setExpenseData((prevExpense) => {
          return {
            ...prevExpense,
            Files: files,
          };
        });
      }

      if (files instanceof File) {
        formData.append("file", files);
        setExpenseData((prevExpense) => {
          return {
            ...prevExpense,
            Files: [files],
          };
        });
      }
    };

    const onCloseFormItemExpense = () => {
      setShowLinkedItemsForm(false);
    };

    React.useImperativeHandle(ref, () => ({
      VALIDATE: validateFromParent,
      dataState: expenseData,
    }));

    return (
      <Grid
        container
        spacing={2}
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        justifyItems="center"
        padding={2}
      >
        <Grid item xs={12}>
          <Grid item xs={12} sx={{textAlign: "center"}}>
            {!expenseId ? (
              <Typography variant="h5">Registrar Gasto</Typography>
            ) : (
              <Typography variant="h5">Editar Gasto</Typography>
            )}
          </Grid>
          <Grid item xs={12} sx={{marginY: "2rem"}}>
            {/* //* Date field for expense creation */}
            <DynamicFormField
              inputType={dateField.type}
              key={dateField.name}
              id={dateField.id}
              label={dateField.label}
              name={dateField.name}
              value={dateField.value}
              onChange={dateField.onChange}
              error={inputErrorMap[dateField.id].failed}
              helperText={inputErrorMap[dateField.id].message}
              variant="standard"
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
              {/* //* Description field  */}
              <DynamicFormField
                inputType={descriptionField.type}
                id={descriptionField.id}
                key={descriptionField.name}
                label={descriptionField.label}
                name={descriptionField.name}
                value={descriptionField.value}
                onChange={descriptionField.onChange}
                multiline={descriptionField.multiline}
                variant="outlined"
                error={inputErrorMap[descriptionField.id].failed}
                helperText={inputErrorMap[descriptionField.id].message}
              />
            </Grid>
            <Grid item xs={3} md={3}>
              {/* //* Total field */}
              <DynamicFormField
                inputType={totalField.type}
                id={totalField.id}
                key={totalField.name}
                label={totalField.label}
                name={totalField.name}
                value={totalField.value}
                onChange={totalField.onChange}
                variant="standard"
                error={inputErrorMap[totalField.id].failed}
                helperText={inputErrorMap[totalField.id].message}
              />
            </Grid>
            <Grid item xs={3} md={3}>
              {/* //*Linked vehicle checkbox */}
              <DynamicFormField
                id={linkedVehicleCheckbox.id}
                inputType={linkedVehicleCheckbox.type}
                key="asociarVehiculo"
                label={linkedVehicleCheckbox.label}
                name={linkedVehicleCheckbox.name}
                onChange={linkedVehicleCheckbox.onChange}
                disabled={linkedVehicleDisabled}
              />
              {/* //*Linked trailer checkbox */}
              <DynamicFormField
                id={linkedTrailerCheckbox.id}
                inputType={linkedTrailerCheckbox.type}
                key="asociarRemolque"
                label={linkedTrailerCheckbox.label}
                name={linkedTrailerCheckbox.name}
                onChange={linkedTrailerCheckbox.onChange}
                disabled={linkedTrailerDisabled}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {/* //*Category select field */}
              <DynamicFormField
                inputType={categoryField.type}
                id={categoryField.id}
                key="category"
                label={categoryField.label}
                name={categoryField.name}
                value={expense.Category}
                onChange={categoryField.onChange}
                options={categoryField.options}
                error={inputErrorMap[categoryField.id].failed}
                helperText={inputErrorMap[categoryField.id].message}
                isOptionEqualToValue={(o, v) => {
                  if (v && v !== "") {
                    return o.value === v.value;
                  }
                  return true;
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{marginY: "2rem"}}>
            <Typography variant="h6">Archivos asociados</Typography>
            <DragAndDrop
              componentId="expenseFiles"
              fileExtension="image/*,application/pdf,.pdf"
              limit={5}
              submit={handleSubmitFiles}
              key={"expenseFiles"}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleShowLinkedItemsForm}
              disabled={addAssociatedConceptsDisabled || isLoading}
            >
              Agregar conceptos asociados
            </Button>
          </Grid>

          {
            //? If showLinkedItemsForm is true, then render the form item expense component
            showLinkedItemsForm && (
              <>
                <Typography variant="h6">Gastos asociados</Typography>
                <FormItemExpenseComponent
                  onAddItemExpense={onAddItemExpense}
                  onCloseFormItemExpense={onCloseFormItemExpense}
                />
                <ListItemExpensesComponent
                  expense={expenseData}
                  setExpense={setExpenseData}
                />
              </>
            )
          }
        </Grid>
      </Grid>
    );
  }
);
