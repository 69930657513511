import React from "react";
import { Box } from "@mui/material";
import InvoiceTable from "../Tables/InvoiceTable";

const ServiceInvoicesCard = ({ invoicesIds }) => {
  return (
    <Box sx={{ height: "60%", width: "95%" }}>
      <InvoiceTable
        invoicesIds={invoicesIds || []}
        columnsToShow={["id", "actions"]}
        minHeight={"150px"}
        hasTitle={false}
      />
    </Box>
  );
};

export default ServiceInvoicesCard;
