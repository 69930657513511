import React, {useState} from "react";
import {
  Grid,
  TextField,
  Autocomplete,
  InputLabel,
  NativeSelect,
} from "@mui/material";

import {TruckTypesCatalog} from "../../../modules/invoices/utils/staticCatalogs";

import {LocalizationProvider, DatePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

import InputSearchBrandsComponent from "./InputSearchBrandsComponent";

import {VehicleValidation} from "../../../modules/admin/trucksControl/validations/vehicle.validation";

export default function TruckInfoComponent({
  transport,
  handleChange,
  setTransport,
}) {
  const [validations, setValidations] = useState({...VehicleValidation});

  const handleDateChange = (stringDate) => {
    transport.BoughtDate = new Date(stringDate);
  };

  const handlerOnTouch = (event) => {
    const name = event.target.name;
    validations[name].isTouched = true;
    setValidations({...validations});
  };

  const handlerOnValid = (event) => {
    const name = event.target.name;
    const value = event.target.value || "";
    if (value.length === 0 && !validations[name].isTouched) {
      validations[name].isValid = true;
      return;
    }

    if (!value) {
      validations[name].isValid = false;
      return;
    }

    validations[name].isValid = true;
    setValidations({...validations});
    console.log(validations);
  };

  return (
    <>
      <Grid container spacing={2} sx={{mt: "3vh"}}>
        <Grid item xs={12}>
          <Autocomplete
            autoComplete
            sx={{width: "100%"}}
            options={TruckTypesCatalog}
            getOptionLabel={(option) => {
              return `${option.value} - ${option.label}`;
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  id="type"
                  sx={{width: "100%"}}
                  label="Tipo de Vehículo"
                  required
                  value={transport.Specs.Type.Code || ""}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              );
            }}
            value={
              transport.Specs.Type.Code
                ? TruckTypesCatalog.find(
                    (option) => option.value === transport.Specs.Type.Code
                  )
                : null
            }
            onChange={(e, v, r) => {
              if (r === "clear") {
                transport.Specs.Type.Code = "";
                transport.Specs.Type.Description = "";
                transport.Specs.Type.Name = "";
              } else {
                transport.Specs.Type.Code = v.value;
                transport.Specs.Type.Description = v.label;
                transport.Specs.Type.Name = v.value;
              }
              setTransport({...transport});
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={handleChange}
            id="Niv"
            name="Niv"
            label="Número de Identificación Vehicular (NIV)"
            value={transport.Niv}
            InputLabelProps={{
              shrink: Boolean(transport.Niv),
            }}
            variant={"standard"}
            fullWidth
            required
            onFocus={handlerOnTouch}
            onInput={handlerOnValid}
            error={validations.Niv.isTouched && !validations.Niv.isValid}
            helperText={validations.Niv.message}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            onChange={handleChange}
            id="Specs.Model"
            label="Modelo"
            type="text"
            value={transport.Specs.Model}
            InputLabelProps={{
              shrink: Boolean(transport.Specs.Model),
            }}
            variant={"standard"}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={handleChange}
            id="Specs.ModelYear"
            label="Modelo (Año)"
            type="number"
            value={transport.Specs.ModelYear}
            InputLabelProps={{
              shrink: Boolean(transport.Specs.ModelYear),
            }}
            variant={"standard"}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={6}>
          <InputSearchBrandsComponent
            setTransport={setTransport}
            transport={transport}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={handleChange}
            id="Plate"
            label="Placa"
            value={transport.Plate.toUpperCase()}
            InputLabelProps={{
              shrink: Boolean(transport.Plate),
            }}
            variant={"standard"}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="BoughtDate"
              name="BoughtDate"
              label="Fecha de compra"
              required
              disableFuture
              slotProps={{textField: {variant: "standard"}}}
              sx={{width: "100%"}}
              value={new Date(transport.BoughtDate || new Date())}
              onChange={handleDateChange}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={handleChange}
            id="Price"
            label="Precio"
            type="number"
            value={transport.Price}
            InputLabelProps={{
              shrink: Boolean(transport.Price),
            }}
            variant={"standard"}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel variant="standard" htmlFor="OperationStatus">
            Estado de Operación
          </InputLabel>
          <NativeSelect
            defaultValue={transport.OperationStatus}
            onChange={handleChange}
            inputProps={{
              name: "OperationStatus",
              id: "OperationStatus",
            }}
            fullWidth
            required
          >
            <option value={"Operational"}>Operacional</option>
            <option value={"Maintenance"}>En Mantenimiento</option>
            <option value={"Out of service"}>Fuera de Servicio</option>
          </NativeSelect>
        </Grid>
        <Grid item xs={6}>
          <InputLabel variant="standard" htmlFor="Usage">
            Estado
          </InputLabel>
          <NativeSelect
            defaultValue={transport.Usage}
            onChange={handleChange}
            inputProps={{
              name: "Usage",
              id: "Usage",
            }}
            value={transport.Usage}
            fullWidth
            required
          >
            <option value={"New"}>Nuevo</option>
            <option value={"Used"}>Usado</option>
          </NativeSelect>
        </Grid>
      </Grid>
    </>
  );
}
