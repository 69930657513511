import React, {useState, useEffect, useRef} from "react";
import {
  Alert,
  AlertTitle,
  Badge,
  Box,
  Button,
  Container,
  Paper,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import {useNavigate} from "react-router";
import useAuth from "../../../hooks/useAuth";
import {AlertDialog} from "../../userFeedback/AlertDialog";
import ferbyTheme from "../../../theme/ferbyTheme";
import {buildStepsFromCFDI} from "../../../modules/invoices/utils/stepsObjects";
import {IssueButtonComponent} from "../../../modules/invoices/submodules/issueComponent/IssueComponent";
import {Grid} from "../../layout/Grid";

//*Los datos generales incluyen datos de la factura, del emisor, y del receptor
export const InvoiceSteps = () => {
  //*The invoiceModel must exist in localStorage in order to render all the inputs in this component
  const invoiceModel = window.localStorage.getItem("invoiceModel");
  const contentRef = useRef(null);
  const childRef = useRef(null);
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    message: "",
    actions: [{label: "", execute: () => {}}],
    keep: false,
  });
  const [snack, setSnack] = useState({
    open: false,
    message: "",
    severity: "error",
    duration: 10000,
  });
  const [complete, setComplete] = useState({
    General: false,
    "Configurar el pago": false,
    "Operadores y transporte": false,
    Mercancias: false,
    Ubicaciones: false,
    Conceptos: false,
    Timbrado: true,
  });
  const [hasTried, setHasTried] = useState({
    General: false,
    "Configurar el pago": false,
    "Operadores y transporte": false,
    Mercancias: false,
    Ubicaciones: false,
    Conceptos: false,
    Timbrado: true,
  });
  const [steps, setSteps] = useState([]);
  const {userid} = useAuth();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack({...snack, open: false});
  };

  const handleNext = () => {
    if (childRef.current && childRef.current.validate()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      if (childRef.current) {
        childRef.current.showErrors();
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate(`/${userid.claims.rol}/invoices/new`);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const StepRender = (activeStep) => {
    return steps[activeStep].component;
  };

  const handleShowDialog = () => {
    setDialog({
      ...dialog,
      open: !dialog.open,
    });
  };

  useEffect(() => {
    if (!invoiceModel) {
      setDialog((dlg) => {
        return {
          ...dlg,
          open: true,
          title: "Sin configuración de factura.",
          message: "No has iniciado el proceso configuración de la factura.",
          actions: [
            {
              label: "Configurar factura",
              execute: () => {
                navigate(`/${userid.claims.rol}/invoices/new`);
              },
            },
          ],
          keep: true,
        };
      });
    } else {
      let cfdi = JSON.parse(invoiceModel);
      let pasos = buildStepsFromCFDI(cfdi);
      setSteps(pasos);
    }
    return () => {
      window.localStorage.removeItem("invoiceModel");
    };
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.focus();
    }
  }, [activeStep]);

  return (
    <>
      <AlertDialog
        updateOpenState={handleShowDialog}
        open={dialog.open}
        title={dialog.title}
        content={dialog.message}
        actions={dialog.actions}
        keep={dialog.keep}
      />
      <Snackbar
        open={snack.open}
        autoHideDuration={snack.duration}
        onClose={handleCloseSnack}
      >
        <Alert severity={snack.severity}>{snack.message}</Alert>
      </Snackbar>
      <Grid container justifyContent="center" alignContent="center" spacing={2}>
        <Grid item xs={12} sx={{mb: "4vh"}}>
          <Typography variant="h4" align="center">
            Generar Factura
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{mb: "4vh"}}>
          <Stepper
            connector={null}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignSelf: "center",
              textAlign: "center",
              justifyContent: "center",
            }}
            activeStep={activeStep}
          >
            {steps.map((obj, idx) => {
              return (
                <Grid key={idx} item xs={4} md={2}>
                  <Step>
                    <StepLabel>
                      <Badge
                        badgeContent={
                          activeStep === idx || idx > activeStep ? idx + 1 : "✓"
                        }
                        color={"primary"}
                      />
                      <Typography
                        fontSize={"14px"}
                        color={activeStep === idx ? "primary" : "textSecondary"}
                      >
                        {obj.label}
                      </Typography>
                    </StepLabel>
                  </Step>
                </Grid>
              );
            })}
          </Stepper>
        </Grid>

        <Grid container>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{mt: 2, mb: 1}}>
                Sin configuración de factura
              </Typography>
            </React.Fragment>
          ) : (
            <>
              {invoiceModel && (
                <>
                  <Grid
                    container
                    ref={contentRef}
                    tabIndex={activeStep}
                    style={{outline: "none"}}
                    justifyContent="center"
                    alignContent="center"
                    justifyItems="center"
                    alignItems="center"
                  >
                    {React.cloneElement(StepRender(activeStep), {
                      dialog: dialog,
                      setDialog: setDialog,
                      complete: complete[steps[activeStep]?.label],
                      snack: snack,
                      setSnack: setSnack,
                      setComplete: (val) => {
                        setComplete({
                          ...complete,
                          [steps[activeStep]?.label]: val,
                        });
                      },
                      hasTried: hasTried[steps[activeStep]?.label],
                      ref: childRef,
                    })}
                  </Grid>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={4}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        variant="contained"
                        color="secondary"
                        sx={{
                          color: "white",
                          boxShadow: ferbyTheme.shadows[4],
                          width: "100%",
                        }}
                      >
                        Regresar
                      </Button>
                    </Grid>

                    <Grid item xs={4}>
                      {activeStep === steps.length - 1 ? (
                        <IssueButtonComponent
                          cfdi={JSON.parse(
                            window.localStorage.getItem("invoiceModel")
                          )}
                          label="Timbrar"
                          dialog={dialog}
                          setDialog={setDialog}
                          snack={snack}
                          setSnack={setSnack}
                        />
                      ) : (
                        <Button
                          id="next_button"
                          onClick={handleNext}
                          variant="contained"
                          color="primary"
                          sx={{
                            boxShadow: ferbyTheme.shadows[4],
                            width: "100%",
                          }}
                        >
                          Siguiente
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};
