import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import useAuth from "../../../hooks/useAuth";
import DestinationCompanies from "../Services/TransportItem/DestinationsCompanies";
import {
  createDestination,
  getFilteredDestinations,
} from "../../../services/modules/services/destination";
import {TransportItem} from "../../../modules/services/utils/ServiceItemsENUM";
import {LoadingCompanyForm} from "../Services/LoadingCompanyForm";
import {Alert, Snackbar} from "@mui/material";

let locationsData = [];

const Locations = forwardRef(({setDialog, dialog}, ref) => {
  const invoiceJson = JSON.parse(window.localStorage.getItem("invoiceModel"));
  const transportItemStruct = JSON.parse(JSON.stringify({...TransportItem}));

  const {userid} = useAuth();

  const [destinations, setDestinations] = useState({
    CompanyLoad: invoiceJson.Complementos.CartaPorte.Ubicaciones
      .CompanyLoad || {...transportItemStruct.CompanyLoad},
    CompanyUnload: invoiceJson.Complementos.CartaPorte.Ubicaciones
      .CompanyUnload || {...transportItemStruct.CompanyUnload},
    DateTimeLoad:
      invoiceJson.Complementos.CartaPorte.Ubicaciones.DateTimeLoad || "",
    DateTimeUnload:
      invoiceJson.Complementos.CartaPorte.Ubicaciones.DateTimeUnload || "",
  });
  const [loadingInput, setLoadingInput] = useState("");
  const [loadingDestinations, setLoadingDestinations] = useState([]);

  const [unloadingInput, setUnloadingInput] = useState("");
  const [unLoadingDestinations, setUnloadingDestinations] = useState([]);

  const [showCreateDestinationForm, setShowCreateDestinationForm] = useState({
    CompanyLoad: false,
    CompanyUnload: false,
  });

  const [snack, setSnack] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleChangeSelection = (e, v, r) => {
    const id = e.target.id.split("-")[0];
    if (r === "selectOption") {
      setDestinations((prevSelected) => {
        const copy = {...prevSelected};
        copy[id] = v;
        return copy;
      });
    }

    if (r === "clear") {
      console.log("clear");
      console.log(transportItemStruct[id]);
      setDestinations((prevSelected) => {
        const copy = {...prevSelected};
        //*SO NOW THIS IS THE SAME STRUCTURE AS THE SERVICE FOR CONSISTENCY
        copy[id] = transportItemStruct[id];
        return copy;
      });
    }
  };

  const handleErrorsRequestUi = (err) => {
    if (err.response) {
      console.log(err.response);
      if (err.response.status === 404) {
        setSnack({
          open: true,
          message: "No se encontraron resultados.",
          severity: "warning",
        });
        return;
      }

      if (err.response.status === 500) {
        setSnack({
          open: true,
          message:
            "Ocurrió un error al obtener los destinos. Contacte a soporte",
          severity: "error",
        });
        return;
      }

      if (err.response.status === 401) {
        setSnack({
          open: true,
          message: "No tienes permisos para realizar esta acción.",
          severity: "error",
        });
        return;
      }

      if (err.response.status === 403) {
        setSnack({
          open: true,
          message: "No tienes permisos para realizar esta acción.",
          severity: "error",
        });
        return;
      }

      if (err.response.status === 400) {
        setSnack({
          open: true,
          message: "Verifica los datos ingresados.",
          severity: "error",
        });
        return;
      }
    }

    setSnack({
      open: true,
      message: "Ocurrió un error de conexión al obtener los destinos.",
      severity: "error",
    });
  };

  const validateLocations = () => {
    if (destinations.CompanyLoad.TaxName.length === 0) {
      return false;
    }
    if (destinations.CompanyUnload.TaxName.length === 0) {
      return false;
    }
    if (destinations.CompanyLoad.Address.ZipCode.length === 0) {
      return false;
    }
    if (destinations.CompanyUnload.Address.ZipCode.length === 0) {
      return false;
    }
    if (destinations.CompanyLoad.Address.Country.length === 0) {
      return false;
    }
    if (destinations.CompanyUnload.Address.Country.length === 0) {
      return false;
    }
    if (destinations.CompanyLoad.Address.State.length === 0) {
      return false;
    }
    if (destinations.CompanyUnload.Address.State.length === 0) {
      return false;
    }
    if (destinations.DateTimeLoad.length === 0) {
      return false;
    }
    if (destinations.DateTimeUnload.length === 0) {
      return false;
    }
    return true;
  };

  const handleInputErrorsUi = () => {
    setDialog({
      ...dialog,
      open: true,
      title: "Datos incompletos",
      message: "Por favor completa los campos obligatorios.",
      actions: [
        {
          label: "Cerrar",
          execute: resetDialog,
        },
      ],
      keep: true,
    });
  };

  const handleSetLoadingCompany = (type) => () => {
    console.log("destinations", destinations);
    createDestination(userid, destinations[type])
      .then((response) => {
        if (response.status === 201) {
          setSnack({
            open: true,
            message: "Destino creado correctamente.",
            severity: "success",
          });
          resetDialog();
        }
      })
      .catch((err) => {
        console.log(err);
        handleErrorsRequestUi(err);
      })
      .finally(() => {
        handleShowForm(type);
      });
  };

  const resetDialog = () => {
    setDialog({
      ...dialog,
      open: false,
      title: "",
      message: null,
      actions: [],
      keep: false,
    });
  };

  const handleChangeNewAddress = (newValue, type) => {
    setDestinations((prevDestinations) => {
      let copy = {...prevDestinations};
      copy[type] = newValue;
      console.log(newValue);
      console.log(type);
      return copy;
    });
  };

  const handleShowForm = (type) => {
    setShowCreateDestinationForm((prev) => {
      const copy = {...prev};
      copy[type] = !copy[type];
      return copy;
    });
  };

  const handleCloseSnack = () => {
    setSnack({
      open: false,
      message: "",
      severity: "success",
    });
  };

  useImperativeHandle(ref, () => ({
    validate: validateLocations,
    showErrors: handleInputErrorsUi,
  }));

  invoiceJson.Complementos.CartaPorte.Ubicaciones = destinations;
  window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));

  useEffect(() => {
    if (loadingInput.length > 3) {
      let filters = [
        {
          label: "q",
          param: "q",
          value: loadingInput,
        },
      ];

      getFilteredDestinations(userid, filters)
        .then((response) => {
          if (response.data.length > 0) {
            setLoadingDestinations(response.data);
          }

          if (response.data.length === 0) {
            setSnack({
              open: true,
              message: "No se encontraron resultados.",
              severity: "warning",
            });
          }
        })
        .catch((err) => {
          handleErrorsRequestUi(err);
        });
    }
  }, [loadingInput]);

  useEffect(() => {
    if (unloadingInput.length > 3) {
      let filters = [
        {
          label: "q",
          param: "q",
          value: unloadingInput,
        },
      ];

      getFilteredDestinations(userid, filters)
        .then((response) => {
          if (response.data.length > 0) {
            setUnloadingDestinations(response.data);
          }

          if (response.data.length === 0) {
            setSnack({
              open: true,
              message: "No se encontraron resultados.",
              severity: "warning",
            });
          }
        })
        .catch((err) => {
          handleErrorsRequestUi(err);
        });
    }
  }, [unloadingInput]);

  useEffect(() => {
    if (Object.values(showCreateDestinationForm).some((v) => v === true)) {
      let type = Object.keys(showCreateDestinationForm).find(
        (v) => showCreateDestinationForm[v] === true
      );
      setDialog({
        ...dialog,
        open: true,
        title: "Nuevo destino",
        message: (
          <LoadingCompanyForm
            formData={destinations[type]}
            setFormData={handleChangeNewAddress}
            type={type}
            userid={userid}
          />
        ),
        actions: [
          {
            label: "Guardar destino",
            execute: handleSetLoadingCompany(type),
          },
          {
            label: "Cancelar",
            execute: () => {
              handleChangeSelection(
                {
                  target: {
                    id: type,
                    value: TransportItem[type],
                  },
                },
                TransportItem[type],
                "clear"
              );
              resetDialog();
            },
          },
        ],
        keep: true,
      });
    }
  }, [showCreateDestinationForm, destinations]);
  return (
    <>
      <Snackbar autoHideDuration={10000} open={snack.open}>
        <Alert severity={snack.severity} onClose={handleCloseSnack}>
          {snack.message}
        </Alert>
      </Snackbar>
      <DestinationCompanies
        //*DATA IS FORM DATA AND OPTIONS THE OPTIONS OF THE AUTOCOMPLETES (LOCATIONS)
        CompanyLoad={destinations.CompanyLoad}
        CompanyUnload={destinations.CompanyUnload}
        dateTimeLoad={destinations.DateTimeLoad}
        dateTimeUnload={destinations.DateTimeUnload}
        options={[loadingDestinations, unLoadingDestinations]}
        handleChangeSelection={handleChangeSelection}
        setLoadingCompanyInput={setLoadingInput}
        setUnloadingCompanyInput={setUnloadingInput}
        handleShowForm={handleShowForm}
        snack={snack}
        setSnack={setSnack}
      />
    </>
  );
});

export {Locations, locationsData};
