import React, {useEffect, useState} from "react";
import {Box} from "@mui/system";
import {Container, Grid, Paper, Typography} from "@mui/material";
import useAuth from "../../../../hooks/useAuth";
import {
  createDestination,
  getDestinationById,
  updateDestination,
} from "../../../../services/modules/services/destination";
import ferbyTheme from "../../../../theme/ferbyTheme";
import {AlertDialog} from "../../../userFeedback/AlertDialog";
import {LoadingCompanyForm} from "../../Services/LoadingCompanyForm";
import { useNavigate } from "react-router";

const CreateDestination = () => {
  const {userid} = useAuth();
  const navigate = useNavigate();

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });
  const [destination, setDestination] = useState({});
  const [isEdition, setIsEdition] = useState(false);

  const handleCreateLoadingCompany = () => {
    if (isEdition) {
      updateDestination(userid, destination._id, destination)
        .then((res) => {
          console.log("destination update response", res);
          if (res.status === 200) {
            setDialog({
              ...dialog,
              open: true,
              title: "Éxito al actualizar el destino",
              content: "",
              actions: [
                {
                  label: "ok",
                  execute: () => navigate(`/${userid.claims.rol}/destinations`),
                },
              ],
            });
          }
        })
        .catch((err) => {
          setDialog({
            ...dialog,
            open: true,
            title: "Ocurrió un error al actualizar el destino",
            content: "",
            actions: [
              {
                label: "ok",
                execute: () => setDialog({...dialog, open: false}),
              },
            ],
          });
          console.log("error", err);
        });
      return;
    }

    createDestination(userid, destination)
      .then((res) => {
        console.log("destination creation response", res);
        if (res.status === 201) {
          setDialog({
            ...dialog,
            open: true,
            title: "Éxito al crear la compañía",
            content: "",
            actions: [
              {
                label: "ok",
                execute: () => setDialog({...dialog, open: false}),
              },
            ],
          });
        }
      })
      .catch((err) => {
        setDialog({
          ...dialog,
          open: true,
          title: "Ocurrió un error al crear la compañía",
          content: "",
          actions: [
            {
              label: "ok",
              execute: () => setDialog({...dialog, open: false}),
            },
          ],
        });
        console.log("error", err);
      });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("Id");

    //if the component gets an Id in the URL, is supposed to edit that object
    if (id) {
      setIsEdition((e) => true);
      getDestinationById(userid, id)
        .then((response) => {
          setDestination(response.data[0]);
        })
        .catch((error) => {
          console.error(error);
          setDialog({
            ...dialog,
            open: true,
            title: "Ocurrió un error al obtener los datos.",
            content: error.message,
            actions: [
              {
                label: "ok",
                execute: () =>
                  setDialog({
                    ...dialog,
                    open: false,
                    title: "",
                    content: "",
                    actions: [],
                  }),
              },
            ],
          });
        });
    }
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "10vh",
        "& .header-table": {
          backgroundColor: ferbyTheme.palette.primary.main,
          color: "white",
        },
      }}
    >
      <AlertDialog
        {...dialog}
        updateOpenState={(v) => setDialog({...dialog, open: v})}
      />
      <Container component="main" maxWidth="md" sx={{mb: 4}}>
        <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
          <Grid container spacing={2} maxWidth={"lg"}>
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h4"
                align="center"
                color="primary"
              >
                {isEdition ? "Actualizar Destino" : "Crear Nuevo Destino"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <LoadingCompanyForm
                handleAccept={handleCreateLoadingCompany}
                isEdition={isEdition}
                editObj={destination}
                setFormData={setDestination}
              />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default CreateDestination;
