import Login from "./views/Login";
import {RedirectComponent} from "./components/navigation/RedirectComponent";
import {CreateCustomer} from "./modules/customers/CreateCustomer/CreateCustomer";
import {EditCustomer} from "./modules/customers/EditCustomer/EditCustomer";
import {InvoicesIndex} from "./modules/invoices/issue/InvoicesIndex";
import {InvoiceSteps} from "./components/Forms/Invoices/InvoiceSteps";
import {ConsultIssuers} from "./modules/invoices/submodules/issuers/consult/ConsultIssuers";
import {PaymentFormContainer} from "./views/Checkout";
import {NominaSingleEmployee} from "./components/Forms/Nomina/NominaSingleEmployee";
import {EmployeesNominaIndex} from "./modules/invoices/issue/EmployeesNominaIndex";
import {NominaLibre} from "./components/Forms/Nomina/NominaLibre";
import {UploadEmployees} from "./modules/admin/employees/UploadEmployees";
import {EmployeesIndex} from "./modules/admin/employees/EmployeesIndex";
import {UpdateCsds} from "./modules/invoices/submodules/issuers/New/UpdateIssuerCsd";
import {ExpeditionPlaceForm} from "./components/Forms/Places/ExpeditionPlaceForm";
import {NewService} from "./modules/services/NewService";
import {DashboardIndex} from "./modules/admin/dashboard/DashboardIndex";
import EmployeeRegistrationForm from "./modules/admin/employees/CreateEmployee";
import IssuerData from "./modules/invoices/submodules/issuers/New/Issuer";
import CustomersIndex from "./modules/customers/CustomersIndex";
import ConsultInvoiceIndex from "./modules/invoices/submodules/consult/ConsultInvoiceIndex";
import FirstStepsForm from "./modules/registration/FirstStepsForm";
import AdminServiceIndex from "./modules/services/ServiceIndex";
import RegisterIndex from "./modules/invoices/submodules/issuers/RegisterIndex";
import CreateTransport from "./modules/admin/trucksControl/CreateTransport";
import EditTransport from "./modules/admin/trucksControl/EditTransport";
import TransportsIndex from "./modules/admin/trucksControl/TransportsIndex";
import AdminRegisterRemolques from "./modules/admin/trucksControl/AdminRegisterRemolques";
import InvoiceDashboard from "./modules/invoices/dashboard/InvoiceDashboard";
import DestinationsIndex from "./modules/services/destinations/DestinationsIndex";
import CreateDestination from "./components/Forms/Places/Routes/CreateDestination";
import ExpeditionPlacesIndex from "./modules/invoices/submodules/issuers/consult/ConsultExpeditionPlaces";
import {OperatorActiveService} from "./modules/services/OperatorActiveService";
import {NewInvoiceGeneralData} from "./components/Forms/Invoices/NewInvoiceGeneralData";
import {OrganizationConfigComponent} from "./modules/configuration/OrganizationConfig";
import {ExpensesIndex} from "./modules/admin/expenses/ExpensesIndex";
import {CreateExpense} from "./modules/admin/expenses/CreateExpense";
import {EditExpense} from "./modules/admin/expenses/EditExpense";

export const privateRoutes = [
  {
    path: "/:rol/welcome",
    element: <InvoiceDashboard />,
  },
  {
    path: "/:rol/checkout",
    element: <PaymentFormContainer />,
  },
  {
    path: "/:rol/order/",
    element: <RedirectComponent />,
  },
  {
    path: "/:rol/businessData",
    element: <DashboardIndex />,
  },
  {
    path: "/:rol/settings",
    element: <OrganizationConfigComponent />,
  },
  {
    path: "/:rol/issuers/places",
    element: <ExpeditionPlacesIndex />,
  },
  {
    path: "/:rol/issuers/places/new",
    element: <ExpeditionPlaceForm />,
  },
  {
    path: "/:rol/invoices",
    element: <InvoicesIndex />,
  },
  {
    path: "/:rol/invoices/dashboard",
    element: <InvoiceDashboard />,
  },
  {
    path: "/:rol/invoices/new",
    element: <NewInvoiceGeneralData />,
  },
  {
    path: "/:rol/invoices/new/steps",
    element: <InvoiceSteps />,
  },
  {
    path: "/:rol/empleados/nominaIndividual",
    element: <NominaSingleEmployee />,
  },
  {
    path: "/:rol/empleados/",
    element: <EmployeesIndex />,
  },
  {
    path: "/:rol/empleados/crear",
    element: <UploadEmployees />,
  },
  {
    path: "/:rol/empleados/crear/nuevo",
    element: <EmployeeRegistrationForm />,
  },
  {
    path: "/:rol/empleados/actualizar",
    element: <EmployeeRegistrationForm />,
  },
  {
    path: "/:rol/empleados/nominaIndex",
    element: <EmployeesNominaIndex />,
  },
  {
    path: "/:rol/invoices/nomina",
    element: <NominaLibre />,
  },
  {
    path: "/:rol/invoices/consultIssuers",
    element: <ConsultIssuers />,
  },
  {
    path: "/:rol/invoices/NuevoEmisor",
    element: <IssuerData />,
  },
  {
    path: "/:rol/invoices/ActualizarCSD",
    element: <UpdateCsds />,
  },
  {
    path: "/:rol/consultInvoices",
    element: <ConsultInvoiceIndex />,
  },
  {
    path: "/:rol/customers",
    element: <CustomersIndex />,
  },
  {
    path: "/:rol/customers/new",
    element: <CreateCustomer />,
  },

  {
    path: "/:rol/customers/update/:id",
    element: <EditCustomer />,
  },
  {
    path: "/:rol/serviceIndex",
    element: <AdminServiceIndex />,
  },
  {
    path: "/:rol/services/new",
    element: <NewService />,
  },
  {
    path: "/:rol/services/active",
    element: <OperatorActiveService />,
  },
  {
    path: "/:rol/services/edit/:id",
    element: <NewService />,
  },
  {
    path: "/:rol/destinations",
    element: <DestinationsIndex />,
  },
  {
    path: "/:rol/destinations/new",
    element: <CreateDestination />,
  },
  {
    path: "/:rol/destinations/update",
    element: <CreateDestination />,
  },
  {
    path: "/:rol/signup",
    element: <FirstStepsForm />,
  },
  {
    path: "/:rol/transports/newTruck",
    element: <CreateTransport />,
  },
  {
    path: "/:rol/transports/newTow",
    element: <AdminRegisterRemolques />,
  },
  {
    path: "/:rol/transports/updateTruck/:vehicleId",
    element: <EditTransport />,
  },
  {
    path: "/:rol/transports/updateTow",
    element: <AdminRegisterRemolques />,
  },
  {
    path: "/:rol/transports",
    element: <TransportsIndex />,
  },
  {
    path: "/register",
    element: <RegisterIndex />,
  },
  {
    path: "/:rol/expenses",
    element: <ExpensesIndex />,
  },
  {
    path: "/:rol/expenses/new",
    element: <CreateExpense />,
  },
  {
    path: "/:rol/expenses/edit/:expenseId",
    element: <EditExpense />,
  },
];

export const publicRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <FirstStepsForm />,
  },
];
