import React, { useState, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material";

import useAuth from "../../../../hooks/useAuth";

import { getProductOService } from "../../../../services/modules/expenses/expenses";

export default function InputSearchProductComponent({ item, setItem }) {
  const { userid } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);

  /*let valueDefault =
    typeof transport.Specs.Brand === "object" ? transport.Specs.Brand : null;*/

  useEffect(() => {
    getProductOService(userid, searchTerm).then((response) => {
      console.log(response);
      setProducts(response.data);
    });
  }, [searchTerm]);

  const handleSearchChange = (event, value) => {
    setSearchTerm(value);
  };

  const handlerAutocompleteChange = (e, v, r) => {
    if (r === "clear") {
      item.Name = "";
      item.Value = "";
      item.Complement = "";
    } else {
      console.log(v);
      item.Name = v.Name;
      item.Value = v.Value;
      item.Complement = v.Name;
    }
    setItem({ ...item });
  };

  return (
    <>
      <Autocomplete
        freeSolo
        required
        options={products}
        getOptionLabel={(option) => option.Name}
        onChange={(e, v, r) => {
          handlerAutocompleteChange(e, v, r);
        }}
        onInputChange={handleSearchChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Producto o Servicio"
            margin="normal"
            variant="standard"
          />
        )}
        //value={valueDefault}
      />
    </>
  );
}
