import React from 'react';
//*mui
import { CircularProgress } from '@mui/material';
//*Router
import {Navigate} from 'react-router-dom'
//*Auth context
import useAuth from '../hooks/useAuth';
import { DashboardNav } from './navigation/DashboardNav';
import { ModulesAccessView } from '../views/ModulesAccessView';
//**Views */

export const PrivateRoute = ({children}) => {
    const {user, loading, userid} = useAuth()

    if(loading) {
        return <CircularProgress />
    }
    
    if(!user){
        return <Navigate to='/'/>
    }

    if(!userid){
        return <CircularProgress/>
    }

    if (userid.claims.isRoot && !userid.claims.customerInPayments.subscription.hasPayed){
        if(window.location.pathname.split('/')[2] === "checkout"){
            return <>{children}</>
        }

        if(window.location.pathname.split('/')[2] === "order"){
            return <>{children}</>
        }

        // if(userid.claims.customerInPayments.isCustomer){
        //     return( 
        //         <>
        //             <DashboardNav userid={userid}/>
        //             {children}
        //         </>
        //     )
        // }

        return <ModulesAccessView/>
    }

    return( 
        <>
            <DashboardNav userid={userid}/>
            {children}
        </>
    )

}
;
