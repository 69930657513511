import React, {useState} from "react";
import {
  Typography,
  Container,
  Grid,
  Paper,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";

import {expenseItemDto} from "../../../modules/admin/expenses/dto/expense-item.dto";
import {calculateTax} from "../../../services/modules/expenses/expenses";

import useAuth from "../../../hooks/useAuth";

import InputSearchProductComponent from "../../../modules/admin/expenses/components/InputSearchProductComponent";

export default function FormItemExpenseComponent({
  onAddItemExpense,
  onCloseFormItemExpense,
}) {
  const {userid} = useAuth();

  const [item, setItem] = useState({...expenseItemDto});

  const calculatedAmounts = (item) => {
    item.Name = "-";
    item.Value = "-";
    item.Complement = "-";
    calculateTax(userid, item).then((response) => {
      if (response.status !== 200) return;

      const {Taxes, Total, IVA, Subtotal} = response.data;
      setItem((prevValue) => {
        const newValue = {...prevValue};

        newValue.Taxes = Taxes;
        newValue.Subtotal = Subtotal;
        newValue.IVA = IVA;
        newValue.Total = Total;

        return newValue;
      });
    });
  };

  const handlerInputChange = (event) => {
    const {name, value} = event.target;

    const newItem = {...item};
    newItem[name] = value;

    newItem.Quantity = parseFloat(newItem.Quantity);
    newItem.Price = parseFloat(newItem.Price);

    setItem({
      ...newItem,
    });

    if (name === "Quantity" || name === "Price") {
      calculatedAmounts(newItem);
    }
  };

  const handlerSubmit = (event) => {
    event.preventDefault();
    onAddItemExpense(item);
    onCloseFormItemExpense();
  };
  return (
    <>
      <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
        <Container component="main" maxWidth="lg" sx={{mb: 1}}>
          <Paper
            variant="outlined"
            sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}
          >
            <form onSubmit={handlerSubmit}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sx={{display: "flex", justifyContent: "center"}}
                >
                  <Typography variant="h5" component="h5">
                    Concepto asociado
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <InputSearchProductComponent item={item} setItem={setItem} />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="Type">Tipo</InputLabel>
                    <Select
                      labelId="Type"
                      id="Type"
                      name="Type"
                      label="Tipo"
                      onChange={handlerInputChange}
                      value={item.Type}
                    >
                      <MenuItem value={"product"}>Producto</MenuItem>
                      <MenuItem value={"service"}>Servicio</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      name="Quantity"
                      label="Cantidad"
                      type="number"
                      onChange={handlerInputChange}
                      value={item.Quantity}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      name="Price"
                      label="Precio Unitario"
                      onChange={handlerInputChange}
                      value={item.Price}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      name="Subtotal"
                      label="Sub-Total"
                      value={item.Subtotal}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{display: "flex", justifyContent: "right"}}
                >
                  <Typography variant="h6" component="h4">
                    IVA: {item.IVA}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{display: "flex", justifyContent: "right"}}
                >
                  <Typography variant="h4" component="h4">
                    Total: {item.Total}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{display: "flex", justifyContent: "center"}}
                >
                  <Button variant="contained" type="submit">
                    Agregar
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onCloseFormItemExpense}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
      </Grid>
    </>
  );
}
