import { Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

export const TimerCC = ({
  label,
  startTime,
  endTime,
  onStart,
  onStop,
  canStart,
  canStop,
}) => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const [formattedTime, setFormattedTime] = useState("00:00:00");
  const [isRunning, setIsRunning] = useState(false);
  
  const updateFormattedTime = (elapsed) => {
    const hours = Math.floor(elapsed / 3600);
    const minutes = Math.floor((elapsed % 3600) / 60);
    const seconds = elapsed % 60;

    setFormattedTime(
      `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`
    );
  };

  useEffect(() => {
    let interval;
  
    if (isRunning) {
      if (startTime && !endTime) {
        const now = new Date();
        const diff = Math.floor((now.getTime() - new Date(startTime).getTime()) / 1000);
        setElapsedTime(diff);
        updateFormattedTime(diff);
        interval = setInterval(() => {
          setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
          updateFormattedTime(elapsedTime + 1);
        }, 1000);
      } else {
        interval = setInterval(() => {
          setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
          updateFormattedTime(elapsedTime + 1);
        }, 1000);
      }
    } else {

      clearInterval(interval);
    }
  
    return () => {
      clearInterval(interval);
    };
  }, [isRunning, elapsedTime]);

  useEffect(() => { 
    if (startTime !== null && endTime === null) {
      setIsRunning(prev=>true);
    } else {
      if(startTime && endTime){
        setIsRunning(prev=>false);
        const end = new Date(endTime);
        const start = new Date(startTime);
        const diff = Math.floor((end.getTime() - start.getTime()) / 1000);
        //setElapsedTime(diff);
        console.log("diff",diff)
        updateFormattedTime(diff);
      }
    }
  }, [startTime, endTime]);

  const handleStartStop = () => {
    if (isRunning) {
      setIsRunning(false);
      onStop();
    } else {
      setIsRunning(true);
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ position: "relative", width: "100%", height: "auto" }}>
        <CircularProgress
          variant={isRunning ? "indeterminate" : "determinate"}
          value={isRunning ? null : 100}
          size={"100%"}
          disableShrink
          sx={{ animationDuration: "2s" }}
          thickness={2}
        />

        <Typography
          variant="h6"
          style={{
            position: "absolute",
            top: 20,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {label}
        </Typography>
        <Typography
          variant="h5"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {formattedTime}
        </Typography>

        <Button
          variant="contained"
          disabled={(isRunning && !canStop) || (!isRunning && !canStart)}
          color="primary"
          onClick={handleStartStop}
        >
          {isRunning ? "Listo" : "Iniciar"}
        </Button>
      </div>
    </div>
  );
};

TimerCC.defaultProps = {
  canStart: true,
  canStop: true,
  onStart: () => console.log("start timer function not declared"),
  onStop: () => console.log("end timer function not declared"),
  label: "Timer",
};

