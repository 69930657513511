import React, { useCallback, useEffect, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ListTable from "../Tables/ListTable";
import { getUserById } from "../../services/modules/users/users";
import { CircularProgress } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { downloadEvidence } from "../../services/modules/services/servicesGeneral";

const EvidencesCard = ({ data, fullService, externalLoading }) => {
  const { userid } = useAuth();
  const [user, setUser] = useState({
    name: null,
    lastName: null,
    email: null,
  });
  const [loading, setLoading] = useState(true);

  const getUser = (user) => {
    console.log(user);
    getUserById(userid, userid, user)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setUser({ ...user, email: res.data.email });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDownloadEvidence = (item) => {
    downloadEvidence(userid, userid, fullService._id, item._id)
      .then((response) => {
        console.log(response);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        const contentDisposition = response.headers["Content-Disposition"];
        let filename = "download";
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
          if (filenameMatch.length === 2) {
            filename = filenameMatch[1];
          }
        }

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useMemo(() => {
    console.log(data);
    getUser(data?.Evidence?.Status?.createdBy);
  }, [data]);

  return (
    <>
      {/* DATES */}
      {(loading || externalLoading) && <CircularProgress />}
      {!(loading || externalLoading) && (
        <>
          <Typography variant="body1" align="center">
            Recepción en físico: {data?.Evidence?.Status?.createdAt || "-"}
          </Typography>
          <Typography variant="body1" align="center">
            Recibidas por: {user.email || "-"}
          </Typography>
          <Typography variant="body1" align="center" mb={1}>
            Liberación: {"-"}
          </Typography>
          <Typography variant="body1" align="center" mb={1}>
            Liberadas por: {"-"}
          </Typography>

          <ListTable
            emptyMsg="Sin evidencias"
            data={data?.Evidence?.Files || []}
            sx={{ height: "150px" }}
            actions={[
              {
                name: "Descargar evidencia",
                icon: <FileDownloadIcon />,
                fn: handleDownloadEvidence,
              },
            ]}
            keysToRender={["FileName"]}
          />
        </>
      )}
    </>
  );
};

export default EvidencesCard;
