import useAuth from "../../../../hooks/useAuth";
import {
  AlertDialog,
  InitialDialogState,
} from "../../../../components/userFeedback/AlertDialog";
import { React, forwardRef, useState } from "react";
import { useNavigate } from "react-router";
import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import { issueInvoice } from "../../../../services/modules/invoices/invoices";
import { getTypeOfInvoice } from "../../../../components/Previews/Getters";
import PostIssuingActions from "../../../../components/actions/PostIssuing";

const IssueButtonComponent = forwardRef(
  ({ cfdi, label, dialog, setDialog, snack, setSnack }, ref) => {
    const { userid } = useAuth();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [internalAlerts, setInternalAlerts] = useState({
      open: false,
      status: "success",
      message: "",
    });
    const [internalDialog, setInternalDialog] = useState(
      JSON.parse(JSON.stringify(InitialDialogState))
    );

    const navigate = useNavigate();

    const handleErrorResponse = (err) => {
      if (err.response) {
        if (err.response.status === 400) {
          const { data } = err.response;
          if (data && data.error && data.error.Descriptions) {
            const mapErrors = data.error.Descriptions.map((validationError) => {
              return validationError.Reason;
            });
            return `Verifica los datos: ${mapErrors.join(", ")}`;
          }

          return data.message;
        }

        if (err.response.status === 401) {
          return "No tienes permisos para realizar esta acción";
        }

        if (err.response.status === 404) {
          return "No se encontró el recurso";
        }

        if (err.response.status === 500) {
          return "Ocurrió un error en el servidor";
        }

        if (err.response.status === 403) {
          return "No tienes permisos para realizar esta acción";
        }

        return "Ocurrió un error al emitir la factura";
      }

      return "Ocurrió un error de conexión";
    };

    const handleUpdateOpenState = (open) => {
      if (open !== null && open !== undefined) {
        setInternalDialog({ ...internalDialog, open });
      }

      setInternalDialog({
        ...internalDialog,
        open: !internalDialog.open,
        title: "",
        content: "",
        actions: [],
        keep: false,
      });
    };

    const handleTimbrado = () => {
      setLoading(true);
      setDisabled(true);
      if (cfdi.GeneralModel.Date === "") {
        delete cfdi.GeneralModel.Date;
      }
      issueInvoice(userid, userid, cfdi, true)
        .then((response) => {
          console.log(response.data);
          if (dialog && setDialog) {
            setDialog({
              ...dialog,
              title: "Factura emitida",
              open: true,
              message: (
                <>
                  <Typography>
                    La factura se ha emitido correctamente
                  </Typography>
                  <PostIssuingActions
                    cfdi={{ ...cfdi, ...response.data }}
                    type={getTypeOfInvoice(cfdi)}
                  />
                </>
              ),
              actions: [
                {
                  label: "Continuar",
                  execute: () => {
                    window.localStorage.removeItem("invoiceModel");
                    navigate(`/${userid.claims.rol}/consultInvoices`);
                  },
                },
              ],
            });
          }
          if (!dialog && !setDialog) {
            setInternalAlerts({
              ...internalAlerts,
              open: true,
              status: "success",
              message: "Factura emitida correctamente",
            });
            setInternalDialog({
              ...internalDialog,
              open: true,
              title: "Factura emitida",
              content: (
                <>
                  <Typography>
                    La factura se ha emitido correctamente
                  </Typography>
                  <PostIssuingActions
                    cfdi={{ ...cfdi, ...response.data }}
                    type={getTypeOfInvoice(cfdi)}
                  />
                </>
              ),
              actions: [
                {
                  label: "Continuar",
                  execute: () => {
                    window.localStorage.removeItem("invoiceModel");
                    navigate(`/${userid.claims.rol}/consultInvoices`);
                  },
                },
              ],
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (dialog && setDialog) {
            setDialog({
              ...dialog,
              open: true,
              title: "Error",
              message: `
            ${handleErrorResponse(err)}
            `,
              actions: [
                {
                  label: "Reintentar",
                  execute: () =>
                    handleTimbrado(
                      cfdi,
                      userid,
                      dialog,
                      setDialog,
                      loading,
                      setLoading
                    ),
                },
                {
                  label: "Cerrar",
                  execute: () => {
                    setDialog({
                      ...dialog,
                      open: false,
                      title: "",
                      message: "",
                      actions: [],
                      keep: false,
                    });
                  },
                },
              ],
            });
            setLoading(false);
            setDisabled(false);
            return;
          }
          setInternalAlerts({
            ...internalAlerts,
            open: true,
            status: "error",
            message: handleErrorResponse(err),
          });
          setLoading(false);
          setDisabled(false);
        });
    };

    return (
      <>
        <AlertDialog
          updateOpenState={handleUpdateOpenState}
          open={internalDialog.open}
          title={internalDialog.title}
          content={internalDialog.content}
          actions={internalDialog.actions}
          keep={internalDialog.keep}
        />
        <Snackbar open={internalAlerts.open} autoHideDuration={10000}>
          <Alert severity={internalAlerts.status}>
            {internalAlerts.message}
          </Alert>
        </Snackbar>
        <Button
          disabled={disabled}
          variant="contained"
          onClick={handleTimbrado}
          width="100%"
        >
          {loading ? <CircularProgress size={"3vh"} /> : label}
        </Button>
      </>
    );
  }
);

export { IssueButtonComponent };
