import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Chip,
  Typography,
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Grid,
} from "@mui/material";
import { ArrowDropDown, MoreVert } from "@mui/icons-material";

const CardTitle = ({ title, action, complementChip }) => {
  const { name, fn, icon } = action || {};
  const {
    label,
    color,
    clickable,
    chipOnClick,
    options,
    anchorMenu,
    menuSelectionHandler,
    externalDisabled,
  } = complementChip || {};
  const [anchorEl, setAnchorEl] = useState(null);
  const handleAnchorMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenMenu = () => {
    setAnchorEl(null);
  };

  const handleApplyStatus = (option) => () => {
    if (menuSelectionHandler) {
      menuSelectionHandler(option);
    }
  };
  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h6" textAlign="center" flex="1">
          {title}
        </Typography>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item xs={6}>
          {complementChip && (
            <Chip
              label={label}
              color={color || undefined}
              sx={{ fontSize: "12px" }}
              clickable={clickable || false}
              onClick={chipOnClick || undefined}
            />
          )}
          {complementChip && anchorMenu && (
            <>
              <IconButton
                aria-label="list-icon"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleAnchorMenu}
                disabled={externalDisabled || false}
                size="small"
              >
                <ArrowDropDown />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleOpenMenu}
              >
                {options.map((option) => (
                  <MenuItem key={option} onClick={handleApplyStatus(option)}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </Grid>
        <Grid item xs={6} sx={{display:"flex", justifyContent:"flex-end"}}>
          {icon ? (
            <IconButton onClick={fn}>{icon}</IconButton>
          ) : (
            name && (
              <Button variant="outlined" onClick={fn}>
                {name}
              </Button>
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

CardTitle.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.shape({
    name: PropTypes.string,
    fn: PropTypes.func.isRequired,
    icon: PropTypes.node,
  }),
  complementChip: PropTypes.shape({
    label: PropTypes.string.isRequired,
    color: PropTypes.string,
  }),
};

export default CardTitle;
