import React, {useEffect, useState} from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Card,
  CardContent,
  Typography,
  Snackbar,
  Alert,
  Grid,
  CircularProgress,
  Button,
  Tooltip,
} from "@mui/material";
import useAuth from "../../hooks/useAuth";
import {updateUserOrganizationConfig} from "../../services/modules/users/users";
import {organizationObservable} from "../../components/observables/organizationObservable";
import {IssuerSeriesViewer} from "../../components/module specific/Configuration/IssuerSerieViewer";
import useAppContext from "../../hooks/useAppContext";
import {FiscalConfiguration} from "./Fiscal/FiscalConfiguration";

//TODO: IMPLEMENT THE INPUT FORM ABSCTRACTION AND IMLEMENT TYPESCRIPT, DTO AND COMPONENT CONFIGURATION (See Expenses module for reference)
/**
 * Renders the Organization Configuration component.
 * This component allows users to configure their organization settings.
 *
 * @returns {JSX.Element} The rendered Organization Configuration component.
 */
const OrganizationConfigComponent = () => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const handleCloseSnackBar = () => {
    setAlert({
      open: false,
      message: "",
      severity: "success",
    });
  };

  return (
    <div style={{padding: 16}}>
      <Snackbar
        open={alert.open}
        autoHideDuration={5000}
        onClose={handleCloseSnackBar}
      >
        <Alert severity={alert.severity}>{alert.message}</Alert>
      </Snackbar>
      <Grid
        container
        spacing={2}
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        justifyItems="center"
      >
        {/* WE SHOULD MOVE THIS TO GLOBAL COMPONENTS */}
        {loading ? (
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Configura tu organización
            </Typography>

            <Grid item xs={12}>
              <Card variant="outlined" style={{marginBottom: 16}}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Sección Fiscal
                  </Typography>
                  <FiscalConfiguration />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Grid>

      {/* Futuras secciones irán aquí, cada una dentro de su propio Card */}
    </div>
  );
};
export {OrganizationConfigComponent};
