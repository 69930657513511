import React, {useEffect, useState} from "react";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import {ConsultIssuers} from "../../../modules/invoices/submodules/issuers/consult/ConsultIssuers";
import {Add, Delete, Edit, Restore, Save} from "@mui/icons-material";
import {
  createSerieFolio,
  deleteSerieFolio,
  getSeriesByIssuer,
  resetSerieFolio,
  updateSerie,
} from "../../../services/modules/invoices/issuers";
import useAuth from "../../../hooks/useAuth";
import {CreateIssuerSeriesForm} from "../../Forms/Configuration/CreateIssuerSeriesForm";
import {UpdateIssuerSeriesForm} from "../../Forms/Configuration/UpdateIssuerSeriesForm";
import {httpErrorResponses} from "../../userFeedback/httpErrorResponses";
import {Grid} from "../../layout/Grid";

export interface IIssuerSeriesViewerComponentProps {
  alert: (data: {message: string; severity: string}) => void;
  loading: (value: boolean) => void;
}

/**
 * Renders the IssuerSeriesViewer component.
 *
 * @component
 * @param {IIssuerSeriesViewerComponentProps} props - The component props are alert and loading, so the parent should handle this states.
 * @returns {JSX.Element} The rendered IssuerSeriesViewer component.
 */
const IssuerSeriesViewer = ({
  alert,
  loading,
}: IIssuerSeriesViewerComponentProps) => {
  const {userid} = useAuth();

  const iconButtonsStyles = {
    "&:hover": {
      backgroundColor: "#3f51b5",
      color: "#fff",
      transition: "background-color 0.3s ease",
    },
  };

  //* DTO OBJECT FOR THE FOLIO AND SERIES FIELDS (USED TO CREATE OR UPDATE A NEW SERIE AND SOME LOGIC TO DETERMINE IF ITS UPDATING OR NOT)
  //*TODO: MOVE THE OBJECT TO A SEPARATE FILE (THERE IS A FOLDER FOR DTOS)
  const createFolioDto = {
    _id: "",
    Serie: "",
    Issuer: "",
    ValidUntilAt: new Date(),
    InitFolio: "",
    EndFolio: "",
    edit: false,
  };
  const [selectedIssuers, setSelectedIssuers] = useState([]);

  //*USED TO BUILD THE STRUCTURE FOR MAPPING THE VALUES IN THE FIELDS FOR CREATING OR UPDATING A SERIE FOR A SPECIFIC (INDEX) ISSUER BASED OF SELECTED ISSUERS LENGTH
  const [issuersSerieFolioInput, setIssuersSerieFolioInput] = useState([]);
  //* used to store the values of the inputs for updating a serie for a specific (index based) issuer so its a n * m matrix where n is the number of selected issuers and m is the number of series for each issuer
  const [issuersSerieFolioInputUpdate, setIssuersSerieFolioInputUpdate] =
    useState([]);

  //*USED TO DETERMINE IF THE USER IS CREATING OR UPDATING A SERIE FOR A SPECIFIC (INDEX) ISSUER BASED OF SELECTED ISSUERS LENGTH
  const [creatingSeriesAndFolios, setCreatingSeriesAndFolios] = useState([]);
  const [updatingSeriesAndFolios, setUpdatingSeriesAndFolios] = useState([]);
  /* */

  //* USED TO STORE THE DATA OF ALL THE SERIES OF ALL THE SELECTED ISSUERS (N*M MATRIX) WHERE N IS THE NUMBER OF SELECTED ISSUERS AND M IS THE NUMBER OF SERIES FOR EACH ISSUER
  const [fetchedSeries, setFetchedSeries] = useState([]);

  //* USED TO STORE THE LOADING STATE OF THE SERIES OF ALL THE SELECTED ISSUERS (N*M MATRIX) WHERE N IS THE NUMBER OF SELECTED ISSUERS AND M IS THE NUMBER OF SERIES FOR EACH ISSUER
  const [loadingSeries, setLoadingSeries] = useState([]);

  //* USED TO STORE THE ERROR STATE OF THE INPUT FIELDS OF ALL THE SELECTED ISSUERS (USER CAN CREATE JUST ONE SERIE BY SELECTED ISSUERS IN THE INTERACTION SO IS A ONE DIMENSION ARRAY)
  const [folioSerieErrorMap, setFolioSerieErrorMap] = useState([]);

  //* USED TO STORE THE ERROR STATE OF THE UPDATE INPUT FIELDS OF ALL THE SELECTED ISSUERS (N*M MATRIX) WHERE N IS THE NUMBER OF SELECTED ISSUERS AND M IS THE NUMBER OF SERIES FOR EACH ISSUER
  const [folioSerieUpdateInputErrorMap, setFolioSerieUpdateInputErrorMap] =
    useState([]);

  //* DTO OBJECT FOR THE SERIES FIELDS (USED TO CREATE OR UPDATE A NEW SERIE)
  //*TODO: MOVE THE OBJECT TO A SEPARATE FILE (THERE IS A FOLDER FOR DTOS)
  const baseFolioSerieErrorMap = {
    create: {
      Serie: false,
      Issuer: false,
      ValidUntilAt: false,
      InitFolio: false,
      EndFolio: false,
      Folio: false,
    },
    update: {
      Serie: false,
      Issuer: false,
      ValidUntilAt: false,
      InitFolio: false,
      EndFolio: false,
      Folio: false,
    },
  };

  /**
   * Resets the states of the IssuerSerieViewer component.
   */
  const resetStates = () => {
    setSelectedIssuers([]);
    setCreatingSeriesAndFolios([]);
    setUpdatingSeriesAndFolios([]);
    setIssuersSerieFolioInput([]);
    setFolioSerieErrorMap([]);
    setFetchedSeries([]);
  };

  /**
   * Handles UI errors and displays an alert message.
   * @param err - The error object.
   */
  const handleErrorsUI = (err: Error) => {
    alert({
      message: httpErrorResponses(err),
      severity: "error",
    });
  };

  /**
   * Handles the selection of issuers for issuer folio management.
   * Manage the state of the selected issuers and the state of the inputs for creating or updating a serie for each issuer.
   * Also can delete the inputs for creating or updating a serie for each issuer. (example: if the user deselects an issuer the input state for creating or updating a serie for that issuer is deleted)
   * @param issuers An array of issuers.
   */
  const handleIssuerFolioManagementSelection = (issuers: any[]) => {
    if (issuers.length < selectedIssuers.length) {
      const deletedIndexes = selectedIssuers.reduce((acc, issuer, idx) => {
        if (
          !issuers.some((selectedIssuer) => selectedIssuer._id === issuer._id)
        )
          acc.push(idx);
        return acc;
      }, []);
      setCreatingSeriesAndFolios((prev) => {
        const newSelected = [...prev];
        for (let i = 0; i < deletedIndexes.length; i++) {
          newSelected.splice(deletedIndexes[i], 1);
        }
        return newSelected;
      });
      setUpdatingSeriesAndFolios((prev) => {
        const newSelected = [...prev];
        for (let i = 0; i < deletedIndexes.length; i++) {
          newSelected.splice(deletedIndexes[i], 1);
        }
        return newSelected;
      });
      setFetchedSeries((prev) => {
        const newSelected = [...prev];
        for (let i = 0; i < deletedIndexes.length; i++) {
          newSelected.splice(deletedIndexes[i], 1);
        }
        return newSelected;
      });
    }

    if (issuers.length > selectedIssuers.length) {
      let series;
      setLoadingSeries(() => {
        const newSelected = new Array(issuers.length).fill(false);
        newSelected[issuers.length - 1] = true;
        return newSelected;
      });

      getSeriesByIssuer(userid, userid, issuers[issuers.length - 1]._id)
        .then((response) => {
          if (response.data.length > 0) {
            const responses = response.data.map((folioSchema) => {
              folioSchema.ValidUntilAt = new Date(folioSchema.ValidUntilAt);
              return folioSchema;
            });

            setFetchedSeries((prevFetched) => {
              if (issuers.length > selectedIssuers.length) {
                const newSelected = [...prevFetched];
                newSelected.push(responses);
                return newSelected;
              }
              const newSelected = new Array(issuers.length).fill([]);
              return newSelected;
            });
          }
          setSelectedIssuers(() => {
            const newSelected = issuers;
            return newSelected;
          });
          setCreatingSeriesAndFolios((prevArray) => {
            const copyArray = [...prevArray];
            // if there is a new issuer, add a new input
            if (issuers.length > copyArray.length) {
              copyArray.push(false);
            }
            return copyArray;
          });
          setUpdatingSeriesAndFolios((prevArray) => {
            const copyArray = [...prevArray];
            // if there is a new issuer, add a new input
            if (issuers.length > copyArray.length) {
              copyArray.push(false);
            }
            return copyArray;
          });
          setLoadingSeries(() => {
            const newSelected = new Array(issuers.length).fill(false);
            return newSelected;
          });
        })
        .catch((err) => {
          console.log(err);
          setFetchedSeries((prevFetched) => {
            if (issuers.length > selectedIssuers.length) {
              const newSelected = [...prevFetched];
              newSelected.push([]);
              return newSelected;
            }
            const newSelected = new Array(issuers.length).fill([]);
            return newSelected;
          });
          setSelectedIssuers(() => {
            const newSelected = issuers;
            return newSelected;
          });

          setFolioSerieErrorMap(() => {
            return new Array(issuers.length).fill(
              JSON.parse(JSON.stringify(baseFolioSerieErrorMap))
            );
          });
          setCreatingSeriesAndFolios((prevArray) => {
            const copyArray = [...prevArray];
            // if there is a new issuer, add a new input
            if (issuers.length > copyArray.length) {
              copyArray.push(false);
            }
            return copyArray;
          });
          setUpdatingSeriesAndFolios((prevArray) => {
            const copyArray = [...prevArray];
            // if there is a new issuer, add a new input
            if (issuers.length > copyArray.length) {
              copyArray.push(false);
            }
            return copyArray;
          });

          setLoadingSeries(() => {
            const newSelected = new Array(issuers.length).fill(false);
            return newSelected;
          });
          handleErrorsUI(err);
        });
      return;
    }

    setSelectedIssuers(() => {
      const newSelected = issuers;
      return newSelected;
    });

    setCreatingSeriesAndFolios((prevArray) => {
      const copyArray = [...prevArray];
      // if there is a new issuer, add a new input
      if (issuers.length > copyArray.length) {
        copyArray.push(false);
      }
      return copyArray;
    });
    setUpdatingSeriesAndFolios((prevArray) => {
      const copyArray = [...prevArray];
      // if there is a new issuer, add a new input
      if (issuers.length > copyArray.length) {
        copyArray.push(false);
      }
      return copyArray;
    });
  };

  const handleShowCreateSerieFolio = (idx) => () => {
    try {
      setIssuersSerieFolioInput((prevSelected) => {
        let newSelected = [...prevSelected];
        if (selectedIssuers.length === 0) {
          newSelected = new Array(1);
        }
        newSelected[idx] = JSON.parse(JSON.stringify(createFolioDto));
        newSelected[idx]._id = selectedIssuers[idx]._id;
        console.log(newSelected);
        return newSelected;
      });

      setFolioSerieErrorMap(() => {
        return new Array(selectedIssuers.length).fill(
          JSON.parse(JSON.stringify(baseFolioSerieErrorMap))
        );
      });

      setCreatingSeriesAndFolios((prev) => {
        const newSelected = [...prev];
        newSelected[idx] = true;
        return newSelected;
      });
    } catch (error) {
      console.log(error);
      alert({
        message: "Ocurrió un error al mostrar el formulario",
        severity: "error",
      });
    }
  };

  const handleShowUpdateSerieFolio = (idx, fetchedSerieIndex) => () => {
    setUpdatingSeriesAndFolios((prevArray) => {
      const copyArray = [...prevArray];
      copyArray[idx] = true;
      return copyArray;
    });

    setIssuersSerieFolioInputUpdate((prevSelected) => {
      const newInputMatrix =
        prevSelected.length > 0
          ? [...prevSelected]
          : new Array(selectedIssuers.length);

      newInputMatrix[idx] = new Array(fetchedSeries[idx].length);

      newInputMatrix[idx][fetchedSerieIndex] =
        fetchedSeries[idx][fetchedSerieIndex];

      return newInputMatrix;
    });

    setFolioSerieUpdateInputErrorMap((prev) => {
      const newErrorMap = new Array(selectedIssuers.length).fill(
        new Array(fetchedSeries.length).fill(
          JSON.parse(JSON.stringify(baseFolioSerieErrorMap))
        )
      );
      return newErrorMap;
    });

    setFetchedSeries((prev) => {
      const newSelected = [...prev];
      newSelected[idx][fetchedSerieIndex].edit = true;
      return newSelected;
    });
  };

  const handleInputUpdateSerieChange = (idx, fetchedSerieIndex) => (event) => {
    const id = event.target.id;
    let value = event.target.value;

    if (event.target.type === "number") {
      value = parseInt(value);
    }

    setIssuersSerieFolioInputUpdate((prevSelected) => {
      const newSelected = [...prevSelected];

      newSelected[idx][fetchedSerieIndex] = {
        ...newSelected[idx][fetchedSerieIndex],
        [id]: value,
      };
      console.log(newSelected);
      return newSelected;
    });
  };

  const handleInputUpdateDateFolioSerieChange =
    (idx, fetchedSerieIndex) => (date) => {
      setIssuersSerieFolioInputUpdate((prevSelected) => {
        const newSelected = [...prevSelected];
        newSelected[idx][fetchedSerieIndex] = {
          ...newSelected[idx][fetchedSerieIndex],
          ValidUntilAt: date,
        };
        return newSelected;
      });
    };

  const handleInputSerieFolioChange = (idx) => (event) => {
    const id = event.target.id;
    let value = event.target.value;

    if (event.target.type === "number") {
      value = parseInt(value);
    }
    setIssuersSerieFolioInput((prevSelected) => {
      const newSelected = [...prevSelected];
      newSelected[idx] = {
        ...newSelected[idx],
        [id]: value,
      };
      return newSelected;
    });
  };

  const handleInputDateFolioSerieChange = (idx) => (date) => {
    setIssuersSerieFolioInput((prevSelected) => {
      const newSelected = [...prevSelected];
      newSelected[idx] = {
        ...newSelected[idx],
        ValidUntilAt: date,
      };
      return newSelected;
    });
  };

  const validateAllFolioSerieInputs = () => {
    function isValidFolioSerieInput(folioSerieInput) {
      const validSerie =
        folioSerieInput.Serie !== "" &&
        folioSerieInput.Serie !== null &&
        folioSerieInput.Serie !== undefined &&
        /^([^|]){1,25}$/.test(folioSerieInput.Serie);

      const validFolio =
        folioSerieInput.Folio !== "" &&
        folioSerieInput.Folio !== null &&
        folioSerieInput.Folio !== undefined;

      const validInitFolio =
        folioSerieInput.InitFolio !== "" &&
        folioSerieInput.InitFolio !== null &&
        folioSerieInput.InitFolio !== undefined;

      const validEndFolio =
        folioSerieInput.EndFolio !== "" &&
        folioSerieInput.EndFolio !== null &&
        folioSerieInput.EndFolio !== undefined;

      const validExpirationDate =
        folioSerieInput.ValidUntilAt instanceof Date ||
        (typeof folioSerieInput.ValidUntilAt === "string" &&
          !isNaN(Date.parse(folioSerieInput.ValidUntilAt)));

      const currentDate = new Date();
      const selectedDate = new Date(folioSerieInput.ValidUntilAt);

      const validDate =
        selectedDate > currentDate &&
        selectedDate.toDateString() !== currentDate.toDateString();

      return {
        isValid:
          validSerie &&
          validFolio &&
          validInitFolio &&
          validEndFolio &&
          validExpirationDate &&
          validDate,
        Serie: validSerie,
        Folio: validFolio,
        InitFolio: validInitFolio,
        EndFolio: validEndFolio,
        ValidUntilAt: validExpirationDate && validDate,
      };
    }

    const creationValidations = issuersSerieFolioInput.map(
      (folioSerieInput, idx) => {
        if (folioSerieInput) {
          const validationResult = isValidFolioSerieInput(folioSerieInput);
          const valid = validationResult.isValid;
          if (!valid) {
            setFolioSerieErrorMap((prev) => {
              const type = {
                update: {
                  Serie: false,
                  Folio: false,
                  InitFolio: false,
                  EndFolio: false,
                  ValidUntilAt: false,
                },
                create: {
                  Serie: false,
                  Folio: false,
                  InitFolio: false,
                  EndFolio: false,
                  ValidUntilAt: false,
                },
              };
              const errors = [...prev];
              const typeKey = "create";

              errors[idx][typeKey].Serie = !validationResult.Serie;
              errors[idx][typeKey].Folio = !validationResult.Folio;
              errors[idx][typeKey].InitFolio = !validationResult.InitFolio;
              errors[idx][typeKey].EndFolio = !validationResult.EndFolio;
              errors[idx][typeKey].ValidUntilAt =
                !validationResult.ValidUntilAt;
              return errors;
            });
          }
          return valid;
        }

        //*IF FOLIO SERIE INPUT IS UNNDEFINED THE VALIDATION IS TRUE BECAUSE THE USER IS NOT CREATING A NEW SERIE FOR THIS ISSUER
        return true;
      }
    );

    const updateValidations = issuersSerieFolioInputUpdate.map(
      (series, idx) => {
        if (series && series.length > 0) {
          let validationResult;
          validationResult = series.map((folioSerieInput, internalIndex) => {
            if (folioSerieInput) {
              const validation = isValidFolioSerieInput(folioSerieInput);

              return {
                isValid: validation.isValid,
                Serie: !validation.Serie,
                Folio: !validation.Folio,
                InitFolio: !validation.InitFolio,
                EndFolio: !validation.EndFolio,
                ValidUntilAt: !validation.ValidUntilAt,
              };
            }

            //* IF FOLIO SERIE INPUT IS UNNDEFINED THE VALIDATION IS TRUE BECAUSE THE USER IS NOT UPDATING A SERIE FOR THIS ISSUER
            return {
              isValid: true,
            };
          });

          if (
            validationResult.some((validation) => validation.isValid === false)
          ) {
            setFolioSerieUpdateInputErrorMap((prev) => {
              const prevErrors = [...prev];
              prevErrors[idx] = validationResult;
              return prevErrors;
            });
          }
          return {
            isValid:
              validationResult &&
              validationResult.every((validation) => validation.isValid),
          };
        }
        return {
          isValid: true,
        };
      }
    );

    return (
      !creationValidations.some((validation) => validation === false) &&
      !updateValidations.some((validation) => validation.isValid === false)
    );
  };

  const handleGetSerieByIssuer = (issuer) => {
    getSeriesByIssuer(userid, userid, selectedIssuers[issuer]._id)
      .then((response) => {
        if (response.data.length > 0) {
          const responses = response.data.map((folioSchema) => {
            folioSchema.ValidUntilAt = new Date(folioSchema.ValidUntilAt);
            return folioSchema;
          });

          setFetchedSeries((prevFetched) => {
            const newSelected = [...prevFetched];
            newSelected[issuer] = responses;
            return newSelected;
          });
        }
        setLoadingSeries((prev) => {
          const newSelected = [...prev];
          newSelected[issuer] = false;
          return newSelected;
        });
      })
      .catch((err) => {
        console.log(err);
        setLoadingSeries((prev) => {
          const newSelected = [...prev];
          newSelected[issuer] = false;
          return newSelected;
        });
        setFetchedSeries((prevFetched) => {
          const newSelected = [...prevFetched];
          newSelected[issuer] = [];
          return newSelected;
        });
        handleErrorsUI(err);
      });
  };

  const handleSaveFolioSerieInputs = () => {
    if (!validateAllFolioSerieInputs()) {
      alert({
        message: "Hay errores en los folios y series",
        severity: "error",
      });
      return;
    }
    loading(true);

    const promises = [];
    issuersSerieFolioInput.forEach((folioSerieInput, idx) => {
      if (creatingSeriesAndFolios[idx]) {
        const createFolioDto = {
          Serie: folioSerieInput.Serie,
          Issuer: selectedIssuers[idx]._id,
          ValidUntilAt: folioSerieInput.ValidUntilAt,
          InitFolio: folioSerieInput.InitFolio,
          EndFolio: folioSerieInput.EndFolio,
          Folio: folioSerieInput.Folio,
        };

        promises.push(createSerieFolio(userid, userid, createFolioDto));
      }
    });

    issuersSerieFolioInputUpdate.forEach((seriesInputs, idx) => {
      if (updatingSeriesAndFolios[idx]) {
        seriesInputs.forEach((folioSerieInput, internalIndex) => {
          console.log(folioSerieInput);

          if (folioSerieInput) {
            const updateFolioDto = {
              _id: folioSerieInput._id,
              Serie: folioSerieInput.Serie,
              ValidUntilAt: folioSerieInput.ValidUntilAt,
              InitFolio: folioSerieInput.InitFolio,
              EndFolio: folioSerieInput.EndFolio,
              Folio: folioSerieInput.Folio,
            };

            // Use the updateFolioDto to call the API for updating the folio
            promises.push(
              updateSerie(userid, userid, updateFolioDto._id, updateFolioDto)
            );
          }
        });
      }
    });

    Promise.allSettled(promises)
      .then((responses) => {
        if (responses.every((response) => response.status === "fulfilled")) {
          alert({
            message: "Se actualizaron los folios y series",
            severity: "success",
          });

          resetStates();

          loading(false);
          return;
        }

        let mappedMessage: any[] = [];

        if (responses.every((response) => response.status === "rejected")) {
          mappedMessage = responses.map((response, idx) => {
            //*THIS IF STATEMENT IS DUE TO TYPESCRIPT COMPLAINING ABOUT THE RESPONSE BEING A PROMISE REJECTED RESULT
            //* IF THIS IS NOT HERE THE RESPONSE ASSUMES THAT THE STATUS IS FULFILLED AND TYPE FOR FULFILLED DOES NOT CONTAIN THE REASON PROPERTY
            if (response.status === "rejected") {
              return {
                message: `Fallo al realizar solicitud para el emisor: ${
                  selectedIssuers[idx].Nombre
                } Error:${httpErrorResponses(response.reason)}`,
              };
            }
          });
        }

        if (responses.some((response) => response.status === "rejected")) {
          mappedMessage = responses.map((response, idx) => {
            //*THIS IF STATEMENT IS DUE TO TYPESCRIPT COMPLAINING ABOUT THE RESPONSE BEING A PROMISE REJECTED RESULT
            //* IF THIS IS NOT HERE THE RESPONSE ASSUMES THAT THE STATUS IS FULFILLED AND TYPE FOR FULFILLED DOES NOT CONTAIN THE REASON PROPERTY
            if (response.status === "rejected") {
              return {
                message: `Fallo al realizar solicitud para el emisor: ${
                  selectedIssuers[idx].Nombre
                } Error:${httpErrorResponses(response.reason)}`,
              };
            }

            if (response.status === "fulfilled") {
              setSelectedIssuers((prev) => {
                const newSelected = [...prev];
                newSelected.splice(idx, 1);
                return newSelected;
              });
              setIssuersSerieFolioInput((prev) => {
                const newSelected = [...prev];
                newSelected.splice(idx, 1);
                return newSelected;
              });
              setFolioSerieErrorMap((prev) => {
                const newSelected = [...prev];
                newSelected.splice(idx, 1);
                return newSelected;
              });
            }

            return {
              message: `Algo salió mal al actualizar los folios y series para el emisor: ${selectedIssuers[idx].Nombre}`,
            };
          });
        }

        alert({
          message:
            "Ocurrió un error al actualizar los folios y series. " +
            mappedMessage.map((msg) => msg.message).join(" "),
          severity: "error",
        });
        loading(false);
      })
      .catch((err) => {
        handleErrorsUI(err);
        loading(false);
      });
  };

  const handleResetFolioSerie = (idx, fetchedSeriesIndex) => () => {
    setLoadingSeries((prev) => {
      const newSelected = [...prev];
      newSelected[idx] = true;
      return newSelected;
    });

    resetSerieFolio(userid, userid, fetchedSeries[idx][fetchedSeriesIndex]._id)
      .then(() => {
        alert({
          message: "Se reinició la serie",
          severity: "success",
        });
        handleGetSerieByIssuer(idx);
      })
      .catch((err) => {
        console.log(err);
        handleErrorsUI(err);
      });
  };

  const handleDeleteSerie = (idx, fetchedSeriesIndex) => () => {
    setLoadingSeries((prev) => {
      const newSelected = [...prev];
      newSelected[idx] = true;
      return newSelected;
    });

    deleteSerieFolio(userid, userid, fetchedSeries[idx][fetchedSeriesIndex]._id)
      .then(() => {
        alert({
          message: "Se eliminó la serie",
          severity: "success",
        });
        handleGetSerieByIssuer(idx);
      })
      .catch((err) => {
        console.log(err);
        setLoadingSeries((prev) => {
          const newSelected = [...prev];
          newSelected[idx] = false;
          return newSelected;
        });
        handleErrorsUI(err);
      });
  };

  useEffect(() => {
    return resetStates;
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        justifyItems="center"
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "1vh 0",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Configuración de folios y series por emisor
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ConsultIssuers
            visibility={{
              _id: false,
              Nombre: true,
              Rfc: true,
              ValidUntilAt: false,
              EmployerRegistration: false,
            }}
            checkBoxSelection
            hideRegister
            onChangeSelection={handleIssuerFolioManagementSelection}
            clearSelection={selectedIssuers.length === 0}
          />
        </Grid>
        {loadingSeries.some((loading) => loading) && (
          <Grid item xs={12}>
            <LinearProgress variant="indeterminate" />
          </Grid>
        )}
        {selectedIssuers.length > 0 &&
          !loadingSeries.some((loading) => loading) && (
            <>
              {selectedIssuers.map((issuer, idx) => {
                return (
                  <React.Fragment key={idx}>
                    {/**NOMBRE Y RFC */}
                    <Grid
                      container
                      spacing={2}
                      mb={"2vh"}
                      alignContent="center"
                      justifyContent="center"
                      alignItems="center"
                      justifyItems="center"
                    >
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          {updatingSeriesAndFolios[idx] ||
                          creatingSeriesAndFolios[idx]
                            ? "Editando series del "
                            : ""}
                          Emisor:{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="body2"
                          gutterBottom
                          display="inline"
                        >
                          <strong>Nombre: </strong>
                          {issuer.Nombre || "Sin nombre"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="body2"
                          gutterBottom
                          display="inline"
                        >
                          <strong>Rfc: </strong>
                          {issuer.Rfc || "Sin nombre"}
                        </Typography>
                      </Grid>

                      {fetchedSeries[idx].length > 0 && !loadingSeries[idx] && (
                        <>
                          {fetchedSeries[idx].map(
                            (folioSchema, internalIndex) => {
                              return (
                                <Grid item xs={12} key={internalIndex}>
                                  <Card>
                                    <CardContent>
                                      {folioSchema.edit && (
                                        <Grid item xs={12}>
                                          <Typography variant="h6" gutterBottom>
                                            Editor de series
                                          </Typography>
                                          <UpdateIssuerSeriesForm
                                            handleInputChange={handleInputUpdateSerieChange(
                                              idx,
                                              internalIndex
                                            )}
                                            handleInputDateChange={handleInputUpdateDateFolioSerieChange(
                                              idx,
                                              internalIndex
                                            )}
                                            errorManager={
                                              folioSerieUpdateInputErrorMap[
                                                idx
                                              ][internalIndex]
                                            }
                                            externalValue={
                                              issuersSerieFolioInputUpdate[idx][
                                                internalIndex
                                              ]
                                            }
                                          />
                                        </Grid>
                                      )}

                                      {!folioSchema.edit && (
                                        <>
                                          <Grid container spacing={2}>
                                            <Grid
                                              item
                                              xs={6}
                                              sx={{flex: "1 1 auto"}}
                                            >
                                              <strong>Serie: </strong>
                                              {folioSchema.Serie}{" "}
                                              <strong>
                                                Último folio usado:{" "}
                                              </strong>
                                              {folioSchema.Folio}{" "}
                                              <strong>Vigencia: </strong>
                                              {folioSchema.ValidUntilAt.toString()}{" "}
                                            </Grid>
                                            <Grid
                                              item
                                              xs={6}
                                              sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              <Tooltip title="Editar serie">
                                                <IconButton
                                                  onClick={handleShowUpdateSerieFolio(
                                                    idx,
                                                    internalIndex
                                                  )}
                                                  color="info"
                                                  sx={
                                                    loadingSeries[idx]
                                                      ? {}
                                                      : iconButtonsStyles
                                                  }
                                                  disabled={loadingSeries[idx]}
                                                >
                                                  <Edit />
                                                </IconButton>
                                              </Tooltip>
                                              <Tooltip title="Reiniciar serie">
                                                <IconButton
                                                  onClick={handleResetFolioSerie(
                                                    idx,
                                                    internalIndex
                                                  )}
                                                  color="info"
                                                  sx={
                                                    loadingSeries[idx]
                                                      ? {}
                                                      : iconButtonsStyles
                                                  }
                                                  disabled={loadingSeries[idx]}
                                                >
                                                  <Restore />
                                                </IconButton>
                                              </Tooltip>
                                              <Tooltip title="Eliminar serie">
                                                <IconButton
                                                  onClick={handleDeleteSerie(
                                                    idx,
                                                    internalIndex
                                                  )}
                                                  color="warning"
                                                  sx={
                                                    loadingSeries[idx]
                                                      ? {}
                                                      : iconButtonsStyles
                                                  }
                                                  disabled={loadingSeries[idx]}
                                                >
                                                  <Delete />
                                                </IconButton>
                                              </Tooltip>
                                            </Grid>
                                          </Grid>
                                        </>
                                      )}
                                    </CardContent>
                                  </Card>
                                </Grid>
                              );
                            }
                          )}
                        </>
                      )}
                    </Grid>

                    {creatingSeriesAndFolios[idx] && !loadingSeries[idx] && (
                      <Grid item xs={12} key={idx}>
                        <Card>
                          <CardContent>
                            <Typography variant="h6" gutterBottom>
                              Creando series
                            </Typography>
                            <CreateIssuerSeriesForm
                              handleInputChange={handleInputSerieFolioChange(
                                idx
                              )}
                              handleInputDateChange={handleInputDateFolioSerieChange(
                                idx
                              )}
                              errorManager={folioSerieErrorMap[idx].create}
                              externalValue={issuersSerieFolioInput[idx]}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                    {!creatingSeriesAndFolios[idx] && (
                      <>
                        <Grid container spacing={2} mb="3vh">
                          <Grid item xs={6}>
                            <Button
                              variant="outlined"
                              endIcon={<Add />}
                              color="primary"
                              sx={{
                                "&:hover": {
                                  backgroundColor: "#3f51b5",
                                  color: "#fff",
                                },
                              }}
                              onClick={handleShowCreateSerieFolio(idx)}
                            >
                              Crear nueva serie para {issuer.Nombre}
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <div
                      style={{
                        borderTop: "2px solid blue",
                        margin: "20px 0",
                        width: "100%",
                      }}
                    ></div>
                  </React.Fragment>
                );
              })}
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleSaveFolioSerieInputs}
                  color="success"
                  endIcon={<Save />}
                  disabled={
                    !(
                      creatingSeriesAndFolios.some((createState) => {
                        return createState === true;
                      }) ||
                      updatingSeriesAndFolios.some(
                        (updateState) => updateState === true
                      )
                    )
                  }
                >
                  Guardar series
                </Button>
              </Grid>
            </>
          )}
      </Grid>
    </>
  );
};

export {IssuerSeriesViewer};
