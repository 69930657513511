import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { getIssuedDocs } from '../../services/modules/invoices/organization';
import useAuth from '../../hooks/useAuth';
import { RenderActions } from '../../modules/invoices/dashboard/components/DashboardDatagrid';
import { getDashboardMappedRows } from '../../modules/invoices/dashboard/components/DashboardDatagrid';
import CustomTable from './CustomTable';

const InvoiceTable = ({
  invoicesIds,
  columnsToShow = ['id', 'emisor', 'receptor', 'status', 'actions'],
  minHeight = '300px',
  hasTitle = true,
}) => {
  const { userid } = useAuth();
  const [dialog, setDialog] = useState({
    open: false,
    title: '',
    content: '',
    actions: [],
  });
  const [rawRows, setRawRows] = useState([]);
  const [datagridRows, setDatagridRows] = useState([]);

  const invoicesColumns = [
    {
      field: '_id',
      headerName: 'Id',
      type: 'text',
      renderCell: (params) => `${params.value}`,
    },
    {
      field: 'Folio',
      headerName: 'Folio',
      type: 'text',
      renderCell: (params) => `${params.value}`,
    },
    {
      field: 'emisor',
      headerName: 'Emisor',
      type: 'text',
      renderCell: (params) => {
        const { emisorName, emisorRfc, emisorRegistration } = params.row;
        const emisorText = `${emisorName} (${emisorRfc}) - ${emisorRegistration}`;
        return <Typography variant='caption'>{emisorText}</Typography>;
      },
    },
    {
      field: 'receptor',
      headerName: 'Receptor',
      type: 'text',
      renderCell: (params) => {
        const { receptorName, receptorRfc, receptorRegistration } = params.row;
        const receptorText = `${receptorName} (${receptorRfc}) - ${receptorRegistration}`;
        return <Typography variant='caption'>{receptorText}</Typography>;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'text',
      renderCell: (params) => `${params.value}`,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type: 'none',
      renderCell: (params) => {
        return (
          <RenderActions
            data={params}
            dialog={dialog}
            setDialog={setDialog}
            rawRows={rawRows}
          />
        );
      },
    },
  ].filter((column) => columnsToShow.includes(column.field));

  useEffect(() => {
    const fetchData = async () => {
      const allResponses = [];

      for (const id of invoicesIds) {
        try {
          const filters = [
            {
              label: '_id',
              param: '_id',
              value: id,
            },
          ];

          const response = await getIssuedDocs(userid, userid, 1, filters);
          allResponses.push(response.data.data);
        } catch (err) {
          console.log(err.response);
        }
      }

      setRawRows(allResponses.flat());
      setDatagridRows(getDashboardMappedRows(allResponses.flat()));
    };

    fetchData();
  }, [invoicesIds]);

  return (
    <CustomTable
      title={hasTitle ? 'Facturas' : undefined}
      columns={invoicesColumns}
      rows={datagridRows}
      emptyMsg='Sin facturas'
      getRowId='id'
      hasPagination={false}
      containerStyle={{ minHeight: minHeight, width: '98%' }}
    />
  );
};

export default InvoiceTable;
