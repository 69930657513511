const AddressDto = {
  ZipCode: "",
  Country: "",
  State: "",
  Street: "",
  InteriorNumber: "",
  ExteriorNumber: "",
  Neighborhood: "",
  Locality: "",
  Municipality: "",
  placeId: "",
  lattitude: "",
  longitude: "",
};

export default AddressDto;
