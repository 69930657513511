import React from "react";
import { useNavigate } from "react-router";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  Button,
  Grid,
  Paper,
  Card,
  CardContent,
  CardActions,
  IconButton,
  CardActionArea,
  Box,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import HorasExtraForm from "./HorasExtraForm";
import {
  getDeductions,
  getOtherPayments,
  getPerceptions,
} from "../../../services/modules/invoices/catalogs";
import useAuth from "../../../hooks/useAuth";

const PerceptionsTable = ({
  perceptionsArray = [
    {
      PerceptionType: "001",
      Code: "001",
      Description: "Sueldos, Salarios  Rayas y Jornales",
      TaxedAmount: 0,
      ExemptAmount: 0,
    },
  ],
  setPerceptionArray = () => {},
}) => {
  const navigate = useNavigate();
  const { userid } = useAuth();

  const [arr, setArr] = useState([
    {
      PerceptionType: "001",
      Code: "001",
      Description: "Sueldos, Salarios  Rayas y Jornales",
      TaxedAmount: null,
      ExemptAmount: null,
    },
  ]);

  const blankPerception = {
    PerceptionType: "",
    Code: "",
    Description: "",
    TaxedAmount: 0,
    ExemptAmount: 0,
  };

  const [selectedPerception, setSelectedPerception] = useState(blankPerception);
  const [hasSelected, setHasSelected] = useState(false);
  const [perceptions, setPercetions] = useState([]);
  //Checkbox control the actions of the button
  const [addAnother, setAddAnother] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  let total = getTotal();
  function getTotal() {
    let total = 0;
    for (const array in arr) {
      if (arr[array].TaxedAmount == null || arr[array].ExemptAmount == null) {
        arr[array].TaxedAmount = 0;
      }

      total =
        total +
        parseFloat(arr[array].TaxedAmount) +
        parseFloat(arr[array].ExemptAmount);
    }
    return total.toFixed(2);
  }

  const addPerception = () => {
    if (selectedPerception.Description !== "") {
      arr.push(selectedPerception);
      setPerceptionArray([...arr]);
      setArr([...arr]);
      setSelectedPerception(blankPerception);
      setDialog({ ...dialog, open: addAnother });
      setShowErrorMsg(false);
    } else {
      setShowErrorMsg(true);
    }
  };

  const addFunction = () => {
    setDialog({
      open: true,
      title: "Buscar Percepción",
      content: <PerceptionMiniForm />,
      actions: [],
    });
  };

  const deleteFunction = (idx) => {
    let tempArr = [...arr];
    if (idx !== -1) {
      tempArr.splice(idx, 1);
    }

    setPerceptionArray([...tempArr]);

    setArr(tempArr);
  };

  const handleCloseDialog = () => {
    setSelectedPerception(blankPerception);
    setDialog({
      ...dialog,
      open: false,
    });
    setShowErrorMsg(false);
  };

  useEffect(() => {
    setDialog({ ...dialog });
  }, [selectedPerception]);

  /* Perception Mini Form
  This is the content inside the dialog that allows the user to set
  the amounts and accept or add another perception */
  const PerceptionMiniForm = () => {
    const [showExtraHours, setShowExtraHours] = useState(false);

    return (
      <Grid
        container
        spacing={2}
        maxWidth="md"
        sx={{ display: "flex", justifyContent: "right" }}
      >
        <Grid item xs={12}>
          <Autocomplete
            autoComplete
            sx={{ width: "100%" }}
            options={perceptions}
            getOptionLabel={(option) => {
              return `${option.Name}`;
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  multiline
                  sx={{ width: "100%" }}
                  label="Percepciones"
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              );
            }}
            onChange={(e, v, r) => {
              switch (r) {
                case "selectOption":
                  selectedPerception.Description = v.Name;
                  selectedPerception.PerceptionType = v.Value;
                  selectedPerception.Code = v.Value;
                  //enable()
                  if (v.Value === "019") {
                    let extraHours = [];
                    selectedPerception.ExtraHours = extraHours;
                    setShowExtraHours(true);
                  }
                  setSelectedPerception({ ...selectedPerception });
                  break;
                case "clear":
                  setSelectedPerception(blankPerception);
                  setShowExtraHours(false);

                  break;
                default:
                  break;
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            label="Código (control interno)"
            sx={{ width: "100%" }}
            onChange={(e, v, r) => {
              selectedPerception.Code = e.target.value;
              setSelectedPerception({ ...selectedPerception });
            }}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            label="Gravado"
            InputProps={{ startAdornment: "$" }}
            placeholder={"0.00"}
            sx={{ width: "100%" }}
            onChange={(e, v, r) => {
              // selectedPerception.TaxedAmount = Number(e.target.value);
              // Convert string to number only when necessary
              let inputValue = e.target.value;
              if (!isNaN(inputValue)) {
                selectedPerception.TaxedAmount = Number(inputValue);
              } else {
                selectedPerception.TaxedAmount = inputValue;
              }

              setSelectedPerception({ ...selectedPerception });
            }}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            label="Exento"
            InputProps={{ startAdornment: "$" }}
            placeholder={"0.00"}
            sx={{ width: "100%" }}
            onChange={(e, v, r) => {
              let inputValue = e.target.value;

              if (!isNaN(inputValue)) {
                selectedPerception.ExemptAmount = Number(inputValue);
              } else {
                selectedPerception.ExemptAmount = inputValue;
              }

              setSelectedPerception({ ...selectedPerception });
            }}
          ></TextField>
        </Grid>

        {showExtraHours && (
          <Grid item xs={12}>
            <HorasExtraForm
              MotherPerception={selectedPerception}
              setMotherPerception={setSelectedPerception}
              submitMotherPerception={addPerception}
            />
          </Grid>
        )}

        {showErrorMsg && (
          <Grid item xs={12}>
            <Alert severity="error">Favor de seleccionar una percepcion</Alert>
          </Grid>
        )}

        <Grid item xs={9} md={5}>
          {selectedPerception.PerceptionType !== "019" && (
            <Button variant="contained" color="primary" onClick={addPerception}>
              Agregar Percepción
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    let newArr = perceptionsArray;
    setArr(newArr);
  }, [perceptionsArray, arr]);

  useEffect(() => {
    getPerceptions(userid, userid.token)
      .then((perceptions) => {
        setPercetions(perceptions.data);
      })
      .catch((err) => {
        setDialog({
          ...dialog,
          open: true,
          title: "Error obteniendo percepciones",
          content:
            "No se pudieron obtener las percepciones. Intentelo mas tarde",
          actions: [
            {
              label: "ok",
              execute: () =>
                setDialog({
                  ...dialog,
                  open: false,
                  title: "",
                  content: "",
                  actions: [],
                }),
            },
          ],
        });
      });
  }, []);

  useEffect(() => {
    if (showErrorMsg) {
      addFunction();
    }
  }, [showErrorMsg]);

  return (
    <Grid
      container
      spacing={1}
      component={Paper}
      sx={{ padding: 2, maxHeight: "100%" }}
    >
      <Dialog open={dialog.open} modal={true} onClose={handleCloseDialog}>
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.actions.map((action, index) => {
            return (
              <Button key={index} variant="contained" onClick={action.execute}>
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <Grid container sx={{ minWidth: "300px", textAlign: "center" }}>
          <Grid item xs={1}>
            <Divider variant="fullWidth" sx={{ marginTop: 2.5 }}></Divider>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="h6"
              color="secondary.dark"
              sx={{ marginBottom: 1 }}
            >
              Percepciones
            </Typography>
          </Grid>
          <Grid item xs={4} md={5}>
            <Divider variant="fullWidth" sx={{ marginTop: 2.5 }}></Divider>
          </Grid>
          <Grid item xs={3} md={2}>
            <Typography
              variant="h6"
              color="secondary.dark"
              sx={{ marginBottom: 1 }}
            >
              Total: ${total}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Divider variant="fullWidth" sx={{ marginTop: 2.5 }}></Divider>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ height: "100%" }}>
        <Grid container spacing={1} sx={{ height: "100%" }}>
          {arr.map((perception, idx) => {
            return (
              <>
                <Grid
                  key={perception.PerceptionType + idx}
                  item
                  xs={6}
                  sm={4}
                  md={3}
                >
                  <Card
                    sx={{
                      minHeight: "240px",
                      maxHeight: "240px",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      overflowY: "auto",
                    }}
                  >
                    <CardContent>
                      <Box display="flex" justifyContent={"space-between"}>
                        <Typography
                          sx={{ fontSize: 13 }}
                          color="secondary"
                          gutterBottom
                        >
                          Código: {arr[idx].PerceptionType}
                        </Typography>
                        <IconButton
                          onClick={() => deleteFunction(idx)}
                          aria-label="delete"
                          size="small"
                        >
                          <DeleteIcon sx={{ height: "18px", width: "18px" }} />
                        </IconButton>
                      </Box>
                      <Typography variant="subtitle" component="div">
                        {arr[idx].Description}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ mb: 1 }}
                        color="success.light"
                      >
                        Total: +$
                        {(arr[idx].TaxedAmount + arr[idx].ExemptAmount).toFixed(
                          2
                        )}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{
                        marginTop: "auto",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="standard"
                            label="Gravado"
                            InputProps={{
                              startAdornment: "$",
                              inputProps: {
                                step: "0.01",
                                type: "number",
                                pattern: "[0-9]*",
                              },
                            }}
                            placeholder={"0.00"}
                            value={arr[idx].TaxedAmount}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const sanitizedValue = inputValue.replace(
                                /[^0-9.]/g,
                                ""
                              );
                              arr[idx].TaxedAmount = parseFloat(sanitizedValue);
                              setPerceptionArray([...arr]);
                            }}
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="standard"
                            label="Exento"
                            InputProps={{
                              startAdornment: "$",
                              inputProps: {
                                step: "0.01",
                                type: "number",
                                pattern: "[0-9]*",
                              },
                            }}
                            placeholder={"0.00"}
                            value={arr[idx].ExemptAmount}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const sanitizedValue = inputValue.replace(
                                /[^0-9.]/g,
                                ""
                              );
                              arr[idx].ExemptAmount =
                                parseFloat(sanitizedValue);
                              setPerceptionArray([...arr]);
                            }}
                          ></TextField>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
                {idx === arr.length - 1 ? (
                  <Grid item xs={6} sm={4} md={3}>
                    <Card onClick={addFunction} sx={{ height: "100%" }}>
                      <CardActionArea sx={{ height: "100%" }}>
                        <CardActions>
                          <Typography fontSize={"72px"} margin="auto">
                            +
                          </Typography>
                        </CardActions>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ) : null}
              </>
            );
          })}

          {!arr.length ? (
            <Grid item xs={6} sm={4} md={3}>
              <Card
                onClick={addFunction}
                sx={{ minHeight: "240px", maxHeight: "240px", height: "100%" }}
              >
                <CardActionArea sx={{ height: "100%" }}>
                  <CardActions>
                    <Typography fontSize={"72px"} margin="auto">
                      +
                    </Typography>
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

const DeductionsTable = ({
  deductionsArray = [
    {
      DeduccionType: "002",
      Code: "ISR",
      Description: "Impuesto Sobre la Renta",
      Amount: 0,
    },
  ],
  setDeductionsArray = () => {},
}) => {
  const navigate = useNavigate();
  const { userid } = useAuth();

  const [arr, setArr] = useState([
    {
      DeduccionType: "002",
      Code: "ISR",
      Description: "Impuesto Sobre la Renta",
      Amount: null,
    },
  ]);

  const blankDeduction = {
    DeduccionType: "",
    Code: "",
    Description: "",
    Amount: 0,
  };

  const [selectedDeduction, setSelectedDeduction] = useState(blankDeduction);
  const [hasSelected, setHasSelected] = useState(false);
  const [deductions, setDeductions] = useState([]);
  const [addAnother, setAddAnother] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  let total = getTotal();
  function getTotal() {
    let total = 0;
    for (const array in arr) {
      total = total + parseFloat(arr[array].Amount);
    }
    return total.toFixed(2);
  }

  const addDeduction = () => {
    if (selectedDeduction.Description !== "") {
      arr.push(selectedDeduction);
      setDeductionsArray([...arr]);
      setArr([...arr]);
      setSelectedDeduction(blankDeduction);
      setDialog({ ...dialog, open: addAnother });
      setShowErrorMsg(false);
    } else {
      setShowErrorMsg(true);
    }
  };

  const addFunction = () => {
    setDialog({
      open: true,
      title: "Buscar Deducción",
      content: <DeductionMiniForm />,
      actions: [],
    });
  };

  const deleteFunction = (idx) => {
    let tempArr = [...arr];
    if (idx !== -1) {
      tempArr.splice(idx, 1);
    }

    setDeductionsArray([...tempArr]);

    setArr(tempArr);
  };

  const handleCloseDialog = () => {
    setSelectedDeduction(blankDeduction);
    setDialog({
      ...dialog,
      open: false,
    });
    setShowErrorMsg(false);
  };

  const DeductionMiniForm = () => {
    return (
      <Grid
        container
        spacing={2}
        maxWidth="md"
        sx={{ display: "flex", justifyContent: "right" }}
      >
        <Grid item xs={12}>
          <Autocomplete
            autoComplete
            sx={{ width: "100%" }}
            options={deductions}
            getOptionLabel={(option) => {
              return `${option.Name}`;
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  multiline
                  sx={{ width: "100%" }}
                  label="Deducciones"
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              );
            }}
            onChange={(e, v, r) => {
              switch (r) {
                case "selectOption":
                  selectedDeduction.Description = v.Name;
                  selectedDeduction.DeduccionType = v.Value;
                  selectedDeduction.Code = v.Value;

                  setSelectedDeduction({ ...selectedDeduction });
                  break;
                default:
                  break;
              }
            }}
            //value={cfdiOptions.place.length>0 ? cfdiOptions.place[0] : {"Name":"Cargando..."}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="standard"
            label="Código (control interno)"
            //disabled={!hasSelected}
            //value={selected.deduction.Code}
            sx={{ width: "100%" }}
            onChange={(e, v, r) => {
              selectedDeduction.Code = e.target.value;
              setSelectedDeduction({ ...selectedDeduction });
            }}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="standard"
            label="Cantidad"
            InputProps={{ startAdornment: "$" }}
            placeholder={"0.00"}
            sx={{ width: "100%" }}
            onChange={(e, v, r) => {
              let inputValue = e.target.value;

              if (!isNaN(inputValue)) {
                selectedDeduction.Amount = Number(inputValue);
              } else {
                selectedDeduction.Amount = inputValue;
              }

              setSelectedDeduction({ ...selectedDeduction });
            }}
          ></TextField>
        </Grid>

        {showErrorMsg && (
          <Grid item xs={12}>
            <Alert severity="error">Favor de seleccionar una deduccion</Alert>
          </Grid>
        )}

        <Grid item xs={9} md={5}>
          <Button variant="contained" color="primary" onClick={addDeduction}>
            Agregar Deducción
          </Button>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    let newArr = deductionsArray;
    setArr(newArr);
  }, [deductionsArray, arr]);

  useEffect(() => {
    getDeductions(userid, userid.token)
      .then((deductions) => {
        setDeductions(deductions.data);
      })
      .catch((err) => {
        setDialog({
          ...dialog,
          open: true,
          title: "Error obteniendo deducciones",
          content:
            "No se pudieron obtener las deducciones. Intentelo mas tarde",
          actions: [
            {
              label: "ok",
              execute: () =>
                setDialog({
                  ...dialog,
                  open: false,
                  title: "",
                  content: "",
                  actions: [],
                }),
            },
          ],
        });
      });
  }, []);

  useEffect(() => {
    if (showErrorMsg) {
      addFunction();
    }
  }, [showErrorMsg]);

  return (
    <Grid
      container
      spacing={1}
      component={Paper}
      sx={{ padding: 2, maxHeight: "100%" }}
    >
      <Dialog
        open={dialog.open}
        modal={true}
        onClose={handleCloseDialog}
        onBackdropClick={handleCloseDialog}
      >
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.actions.map((action, index) => {
            return (
              <Button key={index} variant="contained" onClick={action.execute}>
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <Grid container sx={{ minWidth: "300px", textAlign: "center" }}>
          <Grid item xs={1}>
            <Divider variant="fullWidth" sx={{ marginTop: 2.5 }}></Divider>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="h6"
              color="secondary.dark"
              sx={{ marginBottom: 1 }}
            >
              Deducciones
            </Typography>
          </Grid>
          <Grid item xs={4} md={5}>
            <Divider variant="fullWidth" sx={{ marginTop: 2.5 }}></Divider>
          </Grid>
          <Grid item xs={3} md={2}>
            <Typography
              variant="h6"
              color="secondary.dark"
              sx={{ marginBottom: 1 }}
            >
              Total: ${total}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Divider variant="fullWidth" sx={{ marginTop: 2.5 }}></Divider>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ height: "100%" }}>
        <Grid container spacing={1} sx={{ height: "100%" }}>
          {arr.map((deduction, idx) => {
            return (
              <>
                <Grid
                  key={deduction.DeduccionType + idx}
                  item
                  xs={6}
                  sm={4}
                  md={3}
                >
                  <Card
                    sx={{
                      minHeight: "240px",
                      maxHeight: "240px",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      overflowY: "auto",
                    }}
                  >
                    <CardContent>
                      <Box display="flex" justifyContent={"space-between"}>
                        <Typography
                          sx={{ fontSize: 13 }}
                          color="secondary"
                          gutterBottom
                        >
                          Código: {deduction.DeduccionType || deduction.Value}
                        </Typography>
                        <IconButton
                          onClick={() => deleteFunction(idx)}
                          aria-label="delete"
                          size="small"
                        >
                          <DeleteIcon sx={{ height: "18px", width: "18px" }} />
                        </IconButton>
                      </Box>

                      <Typography variant="subtitle" component="div">
                        {deduction.Description || deduction.Name}
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color="error.main">
                        {/* Total: -${deduction.Amount} */}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{ marginTop: "auto", justifyContent: "center" }}
                    >
                      {/* <Paper sx={{paddingX:1}}><Typography color="error.main">-${deduction.Amount}</Typography></Paper> */}
                      <TextField
                        variant="standard"
                        label="Total"
                        value={deduction.Amount}
                        InputProps={{
                          startAdornment: "-$",
                          inputProps: {
                            step: "0.01",
                            type: "number",
                            pattern: "[0-9]*",
                          },
                        }}
                        placeholder={"0.00"}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const sanitizedValue = inputValue.replace(
                            /[^0-9.]/g,
                            ""
                          );
                          deductionsArray[idx].Amount = Number(sanitizedValue);
                          setDeductionsArray([...deductionsArray]);
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            "& input": {
                              textAlign: "right",
                              color: "error.main",
                            },
                          },
                          "& .MuiFormLabel-root": {
                            color: "error.main",
                          },
                        }}
                      ></TextField>
                    </CardActions>
                  </Card>
                </Grid>

                {idx === arr.length - 1 ? (
                  <Grid item xs={6} sm={4} md={3}>
                    <Card onClick={addFunction} sx={{ height: "100%" }}>
                      <CardActionArea sx={{ height: "100%" }}>
                        <CardActions>
                          <Typography fontSize={"72px"} margin="auto">
                            +
                          </Typography>
                        </CardActions>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ) : null}
              </>
            );
          })}

          {!arr.length ? (
            <Grid item xs={6} sm={4} md={3}>
              <Card
                onClick={addFunction}
                sx={{ minHeight: "240px", maxHeight: "240px", height: "100%" }}
              >
                <CardActionArea sx={{ height: "100%" }}>
                  <CardActions>
                    <Typography fontSize={"72px"} margin="auto">
                      +
                    </Typography>
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

const OtherPaymentsTable = ({
  otherPaymentsArray = [
    {
      OtherPaymentType: "002",
      Code: "002",
      Description: "Subsidio para el empleo",
      Amount: 0,
      EmploymentSubsidy: { Amount: 0 },
    },
  ],
  setOtherPaymentssArray = () => {},
}) => {
  const [arr, setArr] = useState([
    {
      OtherPaymentType: "002",
      Code: "002",
      Description: "Subsidio para el empleo",
      Amount: null,
      EmploymentSubsidy: { Amount: null },
    },
  ]);

  const { userid } = useAuth();

  let total = getTotal();

  function getTotal() {
    let total = 0;
    for (const array in arr) {
      total = total + parseFloat(arr[array].Amount);
    }
    return total.toFixed(2);
  }

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  const [selectedPayment, setSelectedPayment] = useState({
    OtherPaymentType: "002",
    Code: "002",
    Description: "Subsidio para el empleo",
    Amount: null,
    EmploymentSubsidy: { Amount: null },
  });

  const [otherPayments, setOtherPayments] = useState([]);

  const deleteFunction = (idx) => {
    let tempArr = [...arr];
    if (idx !== -1) {
      tempArr.splice(idx, 1);
    }

    setOtherPaymentssArray([...tempArr]);

    setArr(tempArr);
  };

  //Perception Mini Form
  //This is the content inside the dialog that allows the user to set
  //the amounts and accept or add another perception
  const OtpMiniForm = () => {
    return (
      <Grid container spacing={2} maxWidth="md">
        <Grid item xs={12}>
          <Autocomplete
            autoComplete
            sx={{ width: "100%" }}
            options={otherPayments}
            getOptionLabel={(option) => {
              return `${option.Name}`;
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  sx={{ width: "100%" }}
                  label="Otros Pagos"
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {/*loading.cfdiOptions.uses ? <CircularProgress/> : null*/}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              );
            }}
            onChange={(e, v, r) => {
              switch (r) {
                case "selectOption":
                  selectedPayment.Description = v.Name;
                  selectedPayment.OtherPaymentType = v.Value;
                  selectedPayment.Code = v.Value;
                  setSelectedPayment({ ...selectedPayment });
                  break;
                default:
                  break;
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            label="Código (control interno)"
            //disabled={!hasSelected}
            //value={selectedPerception.Code}
            sx={{ width: "100%" }}
            onChange={(e, v, r) => {
              selectedPayment.Code = e.target.value;
              setSelectedPayment({ ...selectedPayment });
            }}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            label="Cantidad"
            InputProps={{ startAdornment: "$" }}
            placeholder={"0.00"}
            sx={{ width: "100%" }}
            onChange={(e) => {
              const inputValue = e.target.value;
              const sanitizedValue = inputValue.replace(/[^0-9.]/g, "");
              selectedPayment.Amount = parseFloat(sanitizedValue);
              if (selectedPayment.OtherPaymentType === "002") {
                selectedPayment.EmploymentSubsidy.Amount =
                  parseFloat(sanitizedValue);
              }
              setSelectedPayment({ ...selectedPayment });
            }}
          ></TextField>
        </Grid>

        <Grid item xs={7}></Grid>

        <Grid item xs={5}>
          <Button variant="contained" color="primary" onClick={addPayment}>
            Agregar Pago
          </Button>
        </Grid>
      </Grid>
    );
  };

  const addPayment = () => {
    arr.push(selectedPayment);
    setArr([...arr]);
    setSelectedPayment({
      OtherPaymentType: "",
      Code: "",
      Description: "",
      Amount: 0,
      EmploymentSubsidy: { Amount: 0 },
    });
    setDialog({ ...dialog, open: false });
  };

  const addFunction = () => {
    setDialog({
      open: true,
      title: "Seleccionar Pagos",
      content: <OtpMiniForm />,
      actions: [],
    });
  };
  const handleCloseDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    });
    setSelectedPayment({
      OtherPaymentType: "",
      Code: "",
      Description: "",
      Amount: 0,
      EmploymentSubsidy: { Amount: 0 },
    });
  };

  useEffect(() => {
    let newArr = otherPaymentsArray;
    setArr(newArr);
  }, [otherPaymentsArray, arr]);

  useEffect(() => {
    getOtherPayments(userid, userid.token)
      .then((otp) => {
        setOtherPayments(otp.data);
      })
      .catch((err) => {
        setDialog({
          open: true,
          title: "Error",
          content: "No se pudieron obtener los pagos",
          actions: [
            {
              label: "Cerrar",
              execute: () => {
                setDialog({ ...dialog, open: false });
              },
            },
          ],
        });
      });
  }, []);

  return (
    <Grid
      container
      spacing={1}
      component={Paper}
      sx={{ padding: 2, height: "100%" }}
    >
      <Dialog
        open={dialog.open}
        modal={true}
        onClose={handleCloseDialog}
        onBackdropClick={handleCloseDialog}
      >
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.actions.map((action, index) => {
            return (
              <Button key={index} variant="contained" onClick={action.execute}>
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>

      <Grid item xs={12}>
        <Grid container sx={{ minWidth: "300px", textAlign: "center" }}>
          <Grid item xs={1}>
            <Divider variant="fullWidth" sx={{ marginTop: 2.5 }}></Divider>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="h6"
              color="secondary.dark"
              sx={{ marginBottom: 1 }}
            >
              Otros Pagos
            </Typography>
          </Grid>
          <Grid item xs={4} md={5}>
            <Divider variant="fullWidth" sx={{ marginTop: 2.5 }}></Divider>
          </Grid>
          <Grid item xs={3} md={2}>
            <Typography
              variant="h6"
              color="secondary.dark"
              sx={{ marginBottom: 1 }}
            >
              Total: ${total}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Divider variant="fullWidth" sx={{ marginTop: 2.5 }}></Divider>
          </Grid>
        </Grid>
      </Grid>

      {otherPaymentsArray.map((othPay, idx) => {
        return (
          <Grid key={idx + "t"} item xs={6} sm={4} md={3}>
            <Card
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
            >
              <CardContent>
                <Box display="flex" justifyContent={"space-between"}>
                  <Typography
                    sx={{ fontSize: 13 }}
                    color="secondary"
                    gutterBottom
                  >
                    Código: {othPay.OtherPaymentType}
                  </Typography>
                  <IconButton
                    onClick={() => deleteFunction(idx)}
                    aria-label="delete"
                    size="small"
                  >
                    <DeleteIcon sx={{ height: "18px", width: "18px" }} />
                  </IconButton>
                </Box>
                <Typography variant="h6" component="div">
                  {othPay.Description}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="error.main"></Typography>
              </CardContent>
              <CardActions sx={{ marginTop: "auto", justifyContent: "center" }}>
                <TextField
                  variant="standard"
                  label="Total"
                  value={othPay.Amount}
                  InputProps={{ startAdornment: "$" }}
                  onChange={(e, v, r) => {
                    const inputValue = e.target.value;
                    const sanitizedValue = inputValue.replace(/[^0-9.]/g, "");
                    otherPaymentsArray[idx].Amount = parseFloat(sanitizedValue);
                    if (otherPaymentsArray[idx].OtherPaymentType === "002") {
                      otherPaymentsArray[idx].EmploymentSubsidy.Amount =
                        parseFloat(sanitizedValue);
                    }
                    setOtherPaymentssArray([...otherPaymentsArray]);
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      "& input": {
                        textAlign: "right",
                        //color:"error.main"
                      },
                    },
                    "& .MuiFormLabel-root": {
                      //color: 'error.main'
                    },
                  }}
                ></TextField>
              </CardActions>
            </Card>
          </Grid>
        );
      })}

      <Grid item xs={6} sm={4} md={3}>
        <Card onClick={addFunction} sx={{ height: "100%" }}>
          <CardActionArea sx={{ height: "100%" }}>
            <CardActions>
              <Typography fontSize={"72px"} margin="auto">
                +
              </Typography>
            </CardActions>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
};

export { PerceptionsTable, DeductionsTable, OtherPaymentsTable };
