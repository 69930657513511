import {useState, useEffect} from "react";
import {
  Alert,
  Autocomplete,
  Button,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
import GOVERNMENT_CONFIG from "../../../utils/GOV_CONFIG";

const RenderInputAbstract = ({params, label, name}) => {
  return <TextField {...params} label={label} name={name} />;
};

const TransportServiceComponent = ({
  options,
  vehicles,
  setVehicles,
  handleAddNewRemolque,
  addNewVehicle,
  externalError = undefined,
}) => {
  const [showRemolque, setShowRemolque] = useState(
    new Array(vehicles.length).fill(false)
  );
  const [alert, setAlert] = useState(
    new Array(vehicles.length).fill({
      open: false,
      message: "",
      severity: "success",
    })
  );

  const handleDeleteVehicle = (vehicle, index) => {
    setVehicles({target: {id: "Unidad"}}, {...vehicle, index}, "clear", index);
    setShowRemolque((prev) => {
      const copy = [...prev];
      copy.splice(index, 1);
      return copy;
    });
    setAlert((prev) => {
      const copy = [...prev];
      copy.splice(index, 1);
      return copy;
    });
    return;
  };

  const deleteRemolque = (remolque, index) => {
    const truckRequireRemolque =
      GOVERNMENT_CONFIG.INVOICES.COMPLEMENTS.CARTA_PORTE.VEHICLES_REQUIREMENTS
        .REQUIRE_REMOLQUE_BY_TYPE[vehicles[index[0]]?.Tractor?.type];
    if (
      index[1] === 0 &&
      vehicles[index[0]].Remolques.length === 1 &&
      truckRequireRemolque
    ) {
      setAlert((prv) => {
        const copy = [...prv];
        copy[index[0]] = {
          open: true,
          message: "Debes agregar un remolque",
          severity: "info",
        };
        return copy;
      });
    }
    setVehicles({target: {id: "Remolque"}}, remolque, "clear", index);
    return;
  };

  const handleTractorAutocompleteChange = (e, v, r, {index, vehicle}) => {
    //*TODO: Refactor this to validate using SAT updated data
    const truckRequireRemolque =
      GOVERNMENT_CONFIG.INVOICES.COMPLEMENTS.CARTA_PORTE.VEHICLES_REQUIREMENTS
        .REQUIRE_REMOLQUE_BY_TYPE[v?.Specs?.Type?.Code || ""];

    if (
      r === "selectOption" &&
      truckRequireRemolque &&
      vehicle?.Remolques &&
      vehicle.Remolques.some((remolque) => Object.keys(remolque).length === 0)
    ) {
      setVehicles(e, v, r, index);
      setAlert((prv) => {
        const copy = [...prv];
        copy[index] = {
          open: true,
          message: "Debes agregar un remolque",
          severity: "info",
        };
        return copy;
      });
      setShowRemolque((prev) => {
        const newShowRemolque = [...prev];
        newShowRemolque[index] = true;
        return newShowRemolque;
      });
      return;
    }
    setVehicles(e, v, r, index);
  };

  const handleRemolqueAutocompleteChange = (
    e,
    v,
    r,
    {vehicle, remolque, index, REM_IDX}
  ) => {
    if (
      vehicle?.Remolques &&
      vehicle.Remolques.length == 2 &&
      Object.keys(vehicle?.Remolques[vehicle?.Remolques.length - 1]).length > 0
    ) {
      setAlert((prev) => {
        const copy = [...prev];
        copy[index] = {
          open: true,
          message: "No puedes agregar más de dos remolques a una unidad",
          severity: "error",
        };
        return copy;
      });
      return;
    }

    if (vehicle.Remolques[0]?._id === v._id) {
      setAlert((prev) => {
        const copy = [...prev];
        copy[index] = {
          open: true,
          message: "No puedes agregar el mismo remolque dos veces",
          severity: "error",
        };
        return copy;
      });
      return;
    }

    if (alert[index].open) {
      setAlert((prev) => {
        const copy = [...prev];
        copy[index] = {
          open: false,
          message: "",
          severity: "success",
        };
        return copy;
      });
      setVehicles(e, v, r, [index, REM_IDX]);
      return;
    }
    setVehicles(e, v, r, [index, REM_IDX]);
  };

  const handleAddRemolqueClick = (vehicle, index) => {
    if (vehicle?.Remolques.length === 1 || vehicle?.Remolques.length == 0) {
      handleAddNewRemolque(vehicle, index);
      return;
    }

    setAlert((prev) => {
      const copy = [...prev];
      copy[index] = {
        open: true,
        message: "Sólo puedes agregar dos remolques",
        severity: "info",
      };
      return copy;
    });
  };

  const getTractorAutocompleteLabel = (option) => {
    return option
      ? "Modelo: " + option?.Specs?.ModelYear + "   Placa: " + option?.Plate ||
          ""
      : "";
  };

  const customTractorIsOptionEqualToValue = (o, v) => {
    if (v === "") {
      return true;
    }

    return v._id === o._id;
  };

  const getTractorAutocompleteValue = (vehicle) => {
    return vehicle?.Tractor && Object.keys(vehicle?.Tractor).length > 0
      ? vehicle.Tractor
      : "";
  };

  const getRemolqueAutocompleteLabel = (option) => {
    return option && (option?.tipoRem || option?.placaCaja)
      ? "Tipo: " + option.tipoRem + "   Placa: " + option.placaCaja
      : "";
  };

  const customRemolqueIsOptionEqualToValue = (o, v) => {
    if (v === "" || (typeof v === "object" && Object.keys(v).length === 0)) {
      return true;
    }

    return v._id === o._id;
  };

  const getRemolqueAutocompleteValue = (remolque) => {
    return Object.keys(remolque).length > 0 ? remolque : "";
  };

  const isAddRemolqueDisabled = (vehicle) => {
    return (
      vehicle?.Remolques?.length === 2 &&
      Object.keys(vehicle?.Remolques[1]).length > 0
    );
  };

  useEffect(() => {
    setShowRemolque((prevShow) => {
      const copy = [...prevShow];
      vehicles.forEach((truck, index) => {
        copy[index] =
          GOVERNMENT_CONFIG.INVOICES.COMPLEMENTS.CARTA_PORTE.VEHICLES_REQUIREMENTS.REQUIRE_REMOLQUE_BY_TYPE[
            truck?.Tractor?.type
          ];
      });
      return copy;
    });
  }, []);

  useEffect(() => {
    if (vehicles.length > alert.length) {
      setAlert((prevAlerts) => {
        const copy = [...prevAlerts];
        copy.push({
          open: false,
          message: "",
          severity: "success",
        });
        return copy;
      });
    }
  }, [vehicles]);

  return (
    <>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="info"
            endIcon={<Add />}
            onClick={addNewVehicle}
            sx={{margin: "1vh"}}
          >
            Añadir más vehículos
          </Button>
          <Grid item xs={12}>
            <Collapse in={externalError?.failed}>
              <Alert severity="error">
                <strong>{externalError?.message}</strong>
              </Alert>
            </Collapse>
          </Grid>
        </Grid>
        {vehicles.map((vehicle, index) => {
          return (
            <>
              <Grid item xs={12}>
                <Collapse in={alert[index]?.open}>
                  <Alert severity={alert[index]?.severity}>
                    <strong>{alert[index]?.message}</strong>
                  </Alert>
                </Collapse>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="Tractor"
                  autoComplete
                  options={options.vehicles || []}
                  clearText="Limpiar"
                  onChange={(e, v, r) =>
                    handleTractorAutocompleteChange(e, v, r, {index, vehicle})
                  }
                  renderInput={(params) => {
                    return (
                      <TextField {...params} label="Tractor" name="Tractor" />
                    );
                  }}
                  getOptionLabel={(option) =>
                    getTractorAutocompleteLabel(option)
                  }
                  isOptionEqualToValue={(o, v) =>
                    customTractorIsOptionEqualToValue(o, v)
                  }
                  value={getTractorAutocompleteValue(vehicle)}
                />
              </Grid>

              {showRemolque[index] && (
                <Grid item xs={12} md={12}>
                  {vehicle?.Remolques && vehicle.Remolques.length > 0 && (
                    <>
                      {vehicle.Remolques.map((remolque, REM_IDX) => {
                        return (
                          <Grid container spacing={2} key={REM_IDX}>
                            <Grid item xs={10}>
                              <Autocomplete
                                id="Remolque"
                                autoComplete
                                options={options.remolques}
                                sx={{marginY: "1vh"}}
                                clearText="Limpiar"
                                onChange={(e, v, r) =>
                                  handleRemolqueAutocompleteChange(e, v, r, {
                                    vehicle,
                                    remolque,
                                    index,
                                    REM_IDX,
                                  })
                                }
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      label="Remolque"
                                      name="Remolque"
                                    />
                                  );
                                }}
                                getOptionLabel={(option) =>
                                  getRemolqueAutocompleteLabel(option)
                                }
                                isOptionEqualToValue={(o, v) =>
                                  customRemolqueIsOptionEqualToValue(o, v)
                                }
                                value={getRemolqueAutocompleteValue(remolque)}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              sx={{
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Tooltip
                                title={`Eliminar remolque: ${
                                  remolque?.Placa ||
                                  remolque?.placaCaja ||
                                  remolque?.Plate ||
                                  "-"
                                }`}
                              >
                                <IconButton
                                  onClick={() =>
                                    deleteRemolque(remolque, [index, REM_IDX])
                                  }
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </>
                  )}
                  <Button
                    variant="text"
                    endIcon={<Add />}
                    disabled={isAddRemolqueDisabled(vehicle)}
                    sx={{
                      ":hover": {backgroundColor: "#0288d1", color: "#fff"},
                    }}
                    onClick={() => handleAddRemolqueClick(vehicle, index)}
                  >
                    {`Añadir ${
                      vehicle?.Remolques.length == 0 ||
                      (vehicle?.Remolques.length > 0 &&
                        Object.keys(vehicle?.Remolques).length == 0)
                        ? ""
                        : "otro"
                    }
                    remolque `}
                  </Button>
                </Grid>
              )}

              {vehicle?.Tractor && Object.keys(vehicle?.Tractor).length > 0 && (
                <Grid item xs={12}>
                  {/* INFO: This is needed because DyanmicTable expects an array of objects, if receives something else
                    it will be casted to an array of objects,and since we are iterating over the vehicles array and rendering 
                    the table for each vehicle, we need to pass customRowParams to able to delete the correct vehicle, because 
                    the index created by the DynamicTable it wouldt match the real index of the vehicle in the vehicles array
                    */}
                  <VehiclesSelectionDynamicTable
                    data={vehicle}
                    handleDeleteVehicle={() =>
                      handleDeleteVehicle(vehicle, index)
                    }
                    deleteRowCustomParams
                  />
                </Grid>
              )}
            </>
          );
        })}
      </Grid>
    </>
  );
};

function VehiclesSelectionDynamicTable({
  data,
  handleDeleteVehicle,
  deleteRowCustomParams,
}) {
  const TrucksTableRows = ({trucks, remove, customParams}) => {
    return (
      <>
        {trucks?.map((item, index) => {
          console.log("item", item);
          const tractor = item?.Tractor ||
            item?.IdentificacionVehicular || {
              PlacaVM: "-",
            };

          const remolques = item?.Remolque
            ? [item.Remolque]
            : item?.Remolques || data?.Remolques || [];

          return (
            <>
              <TableRow>
                <TableCell align="center">
                  <Typography fontWeight={600} color="primary">
                    {" "}
                    Configuración de unidad
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={customParams ? remove : () => remove(item, index)}
                    endIcon={<Delete />}
                  >
                    Eliminar
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component={"th"} scope="row">
                  <Typography>Vehículo</Typography>
                </TableCell>
                <TableCell component={"th"} scope="row">
                  <Typography>
                    {tractor?.PlacaVM ||
                      tractor?.Plate ||
                      tractor?.placaTractor ||
                      "-"}
                  </Typography>
                </TableCell>
              </TableRow>
              {remolques?.map((remolque, index) => {
                console.log("remolque", remolque);
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <Typography>Remolque</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography>
                        {remolque?.Placa ||
                          remolque?.placaCaja ||
                          remolque?.Plate ||
                          "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell colSpan={3}>
                  <hr style={{borderTop: "1px solid #ccc"}} />
                </TableCell>
              </TableRow>
            </>
          );
        })}
      </>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="dynamic table">
        <TableBody>
          <TrucksTableRows
            trucks={Array.isArray(data) ? data : [data]}
            remove={handleDeleteVehicle}
            customParams={deleteRowCustomParams}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export {TransportServiceComponent};
