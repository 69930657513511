const colors = {
    darkBlue:"#210D49", 
    black:"#101111", 
    limeGreen:"#1CF445",
    darkGreen:"#2D302E", 
    white:"#FFF"};

export const landingColors = {
    titleBlue: "#19A9E8",
    actionBlue: "#08AFF5",
    backgroundBlue: "#050922",
    textGray: "#D3D3D3",
    backgroundTransparent: "rgba(0, 0, 0, 0.92)"
};

export default colors;