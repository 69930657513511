import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import useAuth from "../../../hooks/useAuth";
import { getPaymentForms } from "../../../services/modules/invoices/catalogs";
import { Add as AddIcon, Cancel, ExpandMore } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { getIssuedDocs } from "../../../services/modules/invoices/organization";
import { cfdiPaymentsDto } from "../../../utils/dto/cfdiPayments.dto";
import GOVERNMENT_CONFIG from "../../../utils/GOV_CONFIG";
import FilterDrawer from "../../Drawers/FilterDrawer";
import CustomTable from "../../Tables/CustomTable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { InvoiceDetailsCard } from "../../Cards/InvoiceDetailsCard";
import { relatedDocumentsDto } from "../../../utils/dto/relatedDocuments.dto";
import { TAX_OBJECT } from "../../../utils/enum.js/taxObject";
import Delete from "@mui/icons-material/Delete";

const PaymentConfig = forwardRef((props, ref) => {
  const { userid } = useAuth();
  const invoiceJson = JSON.parse(window.localStorage.getItem("invoiceModel"));

  const [payments, setPayments] = useState(
    invoiceJson.Complementos.Payments.Payments.length > 0
      ? invoiceJson.Complementos.Payments.Payments
      : [JSON.parse(JSON.stringify(cfdiPaymentsDto))]
  );
  const [selectedRelatedDocuments, setSelectedRelatedDocuments] = useState([]);

  const [filterOptions, setFilterOptions] = useState({
    clients: [],
    issuerList: [],
    invoiceTypes: Object.entries(
      GOVERNMENT_CONFIG.INVOICES.GENERAL.INVOICE_TYPES
    ).map((entryArray) => entryArray[1]),
  });
  const [filters, setFilters] = useState([]);
  const defaultSelectedfilters = {
    client: {},
    issuer: {},
    invoiceType: {},
    folio: "",
    folioFiscal: "",
  };
  const [selectedFilters, setSelectedFilters] = useState(
    defaultSelectedfilters
  );
  const [selectedClientsArray, setSelectedClientsArray] = useState([]);
  const [selectedIssuersArray, setSelectedIssuersArray] = useState([]);
  const [selectedTypesArray, setSelectedTypesArray] = useState([]);
  const today = new Date();
  const [range, setRange] = useState([
    new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
    today,
  ]);
  const [customerInput, setCustomerInput] = useState("");
  const [issuerInput, setIssuerInput] = useState("");
  const [invoiceTypeInput, setInvoiceTypeInput] = useState("");

  const [showRelationUI, setShowRelationUI] = useState(
    new Array(payments.length).fill(false)
  );

  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState({
    paymentForms: [],
    currencys: [],
  });
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    maxWidth: "md",
    actions: [],
  });
  const [error, setError] = useState({
    date: false,
    form: false,
    empty: false,
    amountless: [],
  });

  //Related documents hooks

  const [invoiceRows, setInvoiceRows] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const handleRequestErrorUI = (err) => {
    console.log(err);
    if (err.response) {
      switch (err.response.status) {
        case 400:
          props.setSnack({
            ...props.snack,
            open: true,
            message: "Ha ocurrido un error en el filtrado",
            severity: "error",
          });
          break;
        case 401:
          props.setSnack({
            ...props.snack,
            open: true,
            message: "Tu sesión expiró",
            severity: "error",
          });
          break;
        case 500:
          props.setSnack({
            ...props.snack,
            open: true,
            message: "Ha ocurrido un error en el servidor",
            severity: "error",
          });
          break;
        default:
          props.setSnack({
            ...props.snack,
            open: true,
            message: "Ha ocurrido un error",
            severity: "error",
          });
          break;
      }
      return;
    }

    props.setSnack({
      ...props.snack,
      open: true,
      message: "Ha ocurrido un error",
      severity: "error",
    });
  };

  const invoiceColumns = [
    {
      field: "Folio",
      headerName: "Folio",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return params.row.GeneralModel.Folio
          ? params.row.GeneralModel.Folio
          : "sin folio";
      },
      type: "text",
    },
    {
      field: "uuid",
      headerName: "UUID",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return params.row.uuid || "sin uuid";
      },
      type: "text",
    },
    {
      field: "_id",
      headerName: "Detalles",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return (
          <Tooltip title="Ver detalles" arrow>
            <IconButton onClick={handleOpenDescriptionModal(params.row)}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        );
      },
      type: "text",
    },
  ];

  const relatedInvoiceColumns = [
    {
      field: "folio",
      headerName: "Folio",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return params.row.folio ? params.row.folio : "sin folio";
      },
      type: "text",
    },
    {
      field: "officialId",
      headerName: "UUID",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return params.row.officialId || "sin uuid";
      },
      type: "text",
    },
    {
      field: "total",
      headerName: "Monto pago",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return (
          Number(
            Number(params.row.total).toFixed(
              GOVERNMENT_CONFIG.INVOICES.GENERAL.DECIMALS
            )
          ) || 0
        );
      },
      type: "text",
    },
    {
      field: "prevBalance",
      headerName: "Balance previo",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return (
          Number(
            Number(params.row.saldoAnterior).toFixed(
              GOVERNMENT_CONFIG.INVOICES.GENERAL.DECIMALS
            )
          ) || 0
        );
      },
      type: "text",
    },
    {
      field: "saldoInsoluto",
      headerName: "Balance",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return (
          Number(
            Number(params.row.saldoInsoluto).toFixed(
              GOVERNMENT_CONFIG.INVOICES.GENERAL.DECIMALS
            )
          ) || 0
        );
      },
      type: "text",
    },
  ];

  const buildRelatedInvoicesColumns = (index) => {
    try {
      relatedInvoiceColumns.push({
        field: "_id",
        headerName: "",
        flex: 1,
        headerClassName: "header-table",
        renderCell: (params) => {
          return (
            <Tooltip title="Borrar documento relacionado" arrow>
              <IconButton
                onClick={handleDeleteRelatedDocument(index, params.row)}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          );
        },
        type: "text",
      });

      const newColumns = relatedInvoiceColumns.map((column) => {
        if (column.field === "total") {
          column.renderCell = (params) => {
            const indexOfRow = payments[index].relatedDocuments.indexOf(
              params.row
            );
            return (
              <TextField
                id="invoice_total"
                sx={{ width: "100%" }}
                label="Monto del pago"
                variant="standard"
                type="number"
                inputProps={{ step: "0.0000001" }}
                value={payments[index].relatedDocuments[indexOfRow].total || ""}
                onChange={handleChangeRowTotalInputs(index, indexOfRow)}
                disabled={payments[index].relatedDocuments.length === 1}
              />
            );
          };
        }

        return column;
      });

      return newColumns;
    } catch (err) {
      props.setSnack({
        ...props.snack,
        open: true,
        message: "Ha ocurrido un error al cargar las columnas",
        severity: "error",
      });
      console.log(err);
      return relatedInvoiceColumns;
    }
  };

  const handleOpenDescriptionModal = (invoice) => () => {
    props.setDialog({
      open: true,
      title: "Detalles del documento",
      message: <InvoiceDetailsCard invoice={invoice} />,
      keep: false,
      actions: [
        {
          label: "Cerrar",
          execute: () => props.setDialog({ ...props.dialog, open: false }),
        },
      ],
    });
  };

  const handleFilterDrawer = () => {};

  const handleAddPayment = () => {
    setPayments((prevPayments) => {
      const arrayCopy = [...prevPayments];
      //*TODO: CHANGE THIS TO INSERT THE ACTUAL DATA OF THE INPUTS
      arrayCopy.push(JSON.parse(JSON.stringify(cfdiPaymentsDto)));
      return arrayCopy;
    });
  };

  const handleDeletePayment = (paymentIndex) => () => {
    setPayments((prevPayments) => {
      const arrayCopy = [...prevPayments];
      arrayCopy.splice(paymentIndex, 1);
      return arrayCopy;
    });
  };

  const handleChangePayments = (e, v, r, index) => {
    const id = e.target.id.split("-")[0];
    setPayments((prevPayments) => {
      const arrayCopy = [...prevPayments];

      if (id === "total") {
        arrayCopy[index][id] = Number(
          Number(v).toFixed(GOVERNMENT_CONFIG.INVOICES.GENERAL.DECIMALS)
        );
      } else {
        arrayCopy[index][id] = v;
      }

      if (id === "total" && arrayCopy[index].relatedDocuments.length > 0) {
        arrayCopy[index].relatedDocuments.forEach((document) => {
          document.total = Number(
            Number(
              arrayCopy[index].total / arrayCopy[index].relatedDocuments.length
            ).toFixed(GOVERNMENT_CONFIG.INVOICES.GENERAL.DECIMALS)
          );
          document.saldoInsoluto = Number(
            Number(document.saldoAnterior - document.total).toFixed(
              GOVERNMENT_CONFIG.INVOICES.GENERAL.DECIMALS
            )
          );
        });
      }
      return arrayCopy;
    });
  };

  const handleAutocompletesChange = (index) => (e, v, r) => {
    handleChangePayments(e, v, r, index);
  };

  const isValidNumber = (value, threshhold) => {
    const totalRegex = /^[0-9]*\.?[0-9]{0,6}$/;
    if (value === "") {
      console.log("empty");
      return false;
    }

    if (isNaN(parseFloat(value)) || !isFinite(value)) {
      console.log("not a number");
      return false;
    }

    if (value > threshhold) {
      console.log("greater than threshhold");
      console.log(threshhold);
      return false;
    }

    if (value <= 0) {
      console.log("less than 0");
      return false;
    }

    if (!totalRegex.test(value)) {
      console.log("regex failed");
      return false;
    }

    return true;
  };

  const handleChangeInputs = (index) => (e) => {
    const id = e.target.id;

    if (id === "total") {
      if (isValidNumber(e.target.value, 999999999.999999)) {
        handleChangePayments(e, e.target.value, "input", index);
      }
    }
  };

  const handleChangePaymentDate = (index) => (newValue) => {
    handleChangePayments(
      { target: { id: "fechaPago" } },
      newValue,
      "input",
      index
    );
  };

  const getOptions = async () => {
    //set loading
    setLoading(true);

    getPaymentForms(userid, userid)
      .then((paymentForms) => {
        //*TODO: GET THE CURRENCYS FROM THE API
        const currencys = ["MXN", "USD", "EUR"];

        setOptions({
          ...options,
          paymentForms: paymentForms.data,
        });
      })
      .catch((err) => {
        handleRequestErrorUI(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRelationConfig = (paymentIndex) => () => {
    if (
      !showRelationUI[paymentIndex] &&
      showRelationUI.some((value, index) => value && index !== paymentIndex)
    ) {
      props.setSnack({
        ...props.snack,
        open: true,
        message: "Un pago ya se encuentra en configuración de relación",
        severity: "error",
      });
      return;
    }

    setShowRelationUI((prevUIArray) => {
      const arrayCopy = [...prevUIArray];
      arrayCopy[paymentIndex] = !arrayCopy[paymentIndex];
      return arrayCopy;
    });
  };

  const handleAddRelateDocuments = (paymentIndex) => () => {
    setPayments((prevPayments) => {
      //*WE ADD ONE TO THE TOTAL LENGTH BECAUSE WE ARE ADDING A NEW DOCUMENT
      const totalRelatedDocuments =
        prevPayments[paymentIndex].relatedDocuments.length + 1;
      const arrayCopy = [...prevPayments];
      const balancedTotal =
        prevPayments[paymentIndex].total / totalRelatedDocuments;
      const newRelatedDocuments = [...arrayCopy[paymentIndex].relatedDocuments];

      selectedRelatedDocuments.forEach((row) => {
        const mappedDocument = JSON.parse(JSON.stringify(relatedDocumentsDto));
        mappedDocument.officialId = row.uuid;
        mappedDocument.folio = row.GeneralModel.Folio;
        mappedDocument.saldoAnterior = row.Balance;
        mappedDocument.taxObject = row.Models.I.Items.filter(
          (item) => item.taxObject.id === TAX_OBJECT.SI_OBJETO
        ).map((item) => item.taxObject);
        mappedDocument.taxes = row.Models.I.Items.filter(
          (item) => item.taxObject.id === TAX_OBJECT.SI_OBJETO
        )
          .map((item) => {
            return item.taxes;
          })
          .flat();
        mappedDocument._id = row._id;
        newRelatedDocuments.push(mappedDocument);
      });

      arrayCopy[paymentIndex].relatedDocuments = newRelatedDocuments;

      prevPayments[paymentIndex].relatedDocuments.forEach((document) => {
        document.total = Number(
          Number(balancedTotal).toFixed(
            GOVERNMENT_CONFIG.INVOICES.GENERAL.DECIMALS
          )
        );
        document.saldoInsoluto = Number(
          Number(document.saldoAnterior - document.total).toFixed(
            GOVERNMENT_CONFIG.INVOICES.GENERAL.DECIMALS
          )
        );
      });

      return arrayCopy;
    });
    handleRelationConfig(paymentIndex)();
    resetFilters();
    resetInvoiceRows();
    setSelectedRelatedDocuments([]);
  };

  const handleSearch = () => {
    setLoading(true);
    fetchInvoices();
  };

  const fetchInvoices = () => {
    setLoading(true);

    getIssuedDocs(userid, userid, currentPage, filters)
      .then((res) => {
        setLoading(false);
        if (res.data.data.length === 0) {
          setDialog({
            ...dialog,
            open: true,
            title: "Nada encontrado",
            content: res.data.message,
            actions: [
              {
                label: "OK",
                execute: () =>
                  setDialog({
                    ...dialog,
                    open: false,
                    title: "",
                    message: "",
                    actions: [],
                  }),
              },
            ],
          });
        } else {
          setInvoiceRows(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 400) {
          setDialog({
            ...dialog,
            open: true,
            title: "Error de valores",
            content:
              "Ha ocurrido un error en el filtrado relacionado a los valores que colocaste y verifica los valores o tu conexión",
            actions: [
              {
                label: "Ok",
                execute: () => setDialog({ ...dialog, open: false }),
              },
            ],
          });
        }
        if (err.response.status === 401) {
          setDialog({
            ...dialog,
            open: true,
            title: "Tu sesión expiró",
            content: "Recarga la página",
            actions: [
              { label: "Recargar", execute: () => window.location.reload() },
            ],
          });
        }
        if (err.response.status === 500) {
          setDialog({
            ...dialog,
            open: true,
            title: "Error interno",
            content:
              "Ha ocurrido un error en el servidor, intentalo de nuevo más tarde.",
            actions: [
              {
                label: "Ok",
                execute: () => setDialog({ ...dialog, open: false }),
              },
            ],
          });
        }
      });
  };

  const resetFilters = () => {
    setFilters([]);
    setSelectedFilters(defaultSelectedfilters);
    setSelectedClientsArray([]);
    setSelectedIssuersArray([]);
    setSelectedTypesArray([]);
    setFilterOptions({
      clients: [],
      issuerList: [],
      invoiceTypes: Object.entries(
        GOVERNMENT_CONFIG.INVOICES.GENERAL.INVOICE_TYPES
      ).map((entryArray) => entryArray[1]),
    });

    const today = new Date();
    setRange([
      new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
      today,
    ]);
    setCustomerInput("");
    setIssuerInput("");
    setInvoiceTypeInput("");
  };

  const resetInvoiceRows = () => {
    setInvoiceRows([]);
  };

  const handleChangeRowTotalInputs = (paymentIndex, indexOfRow) => (e) => {
    if (payments[paymentIndex].relatedDocuments.length === 1) {
      props.setSnack({
        ...props.snack,
        open: true,
        message:
          "Para modificar el monto asginado a una factura, debes agregar otra factura relacionada.",
        severity: "error",
      });

      return;
    }

    if (isValidNumber(e.target.value, payments[paymentIndex].total)) {
      setPayments((prevPayments) => {
        const arrayCopy = [...prevPayments];
        arrayCopy[paymentIndex].relatedDocuments[indexOfRow].total = Number(
          Number(e.target.value).toFixed(
            GOVERNMENT_CONFIG.INVOICES.GENERAL.DECIMALS
          )
        );

        arrayCopy[paymentIndex].relatedDocuments[indexOfRow].saldoInsoluto =
          Number(
            Number(
              arrayCopy[paymentIndex].relatedDocuments[indexOfRow]
                .saldoAnterior -
                arrayCopy[paymentIndex].relatedDocuments[indexOfRow].total
            ).toFixed(GOVERNMENT_CONFIG.INVOICES.GENERAL.DECIMALS)
          );

        //*adjust the other totals to match the global total
        const globalTotal = arrayCopy[paymentIndex].total;
        const totalRelatedDocuments =
          arrayCopy[paymentIndex].relatedDocuments.length;
        const balancedTotal =
          (globalTotal -
            arrayCopy[paymentIndex].relatedDocuments[indexOfRow].total) /
          (totalRelatedDocuments - 1);
        arrayCopy[paymentIndex].relatedDocuments.forEach((document, index) => {
          if (index !== indexOfRow) {
            document.total = Number(
              Number(balancedTotal).toFixed(
                GOVERNMENT_CONFIG.INVOICES.GENERAL.DECIMALS
              )
            );
            document.saldoInsoluto = Number(
              Number(document.saldoAnterior - document.total).toFixed(
                GOVERNMENT_CONFIG.INVOICES.GENERAL.DECIMALS
              )
            );
          }
        });
        return arrayCopy;
      });
      return;
    }
  };

  const handleRelatedDocumentsChange = (paymentIndex) => (row) => {
    if (row) {
      if (row.GeneralModel.CfdiType.Type !== "I") {
        props.setSnack({
          ...props.snack,
          open: true,
          message: "Solo se pueden relacionar facturas de ingreso",
          severity: "error",
        });
        return;
      }

      if (row.Status.issuedStatus !== "issued") {
        props.setSnack({
          ...props.snack,
          open: true,
          message: "La factura no se ha emitido",
          severity: "error",
        });
        return;
      }

      if (
        payments[paymentIndex].relatedDocuments.filter(
          (document) => document._id === row._id
        ).length > 0
      ) {
        props.setSnack({
          ...props.snack,
          open: true,
          message: "La factura ya se encuentra relacionada",
          severity: "error",
        });
        return;
      }
      setSelectedRelatedDocuments((prevSelected) => {
        const arrayCopy = [...prevSelected];
        arrayCopy.push(row);
        return arrayCopy;
      });
    }
  };

  const handleDeleteRelatedDocument = (paymentIndex, row) => () => {
    setPayments((prevPayments) => {
      const arrayCopy = [...prevPayments];
      const indexToDelete =
        arrayCopy[paymentIndex].relatedDocuments.indexOf(row);
      arrayCopy[paymentIndex].relatedDocuments.splice(indexToDelete, 1);
      const balancedTotal =
        arrayCopy[paymentIndex].total /
        arrayCopy[paymentIndex].relatedDocuments.length;
      arrayCopy[paymentIndex].relatedDocuments.forEach((document) => {
        document.total = Number(
          Number(balancedTotal).toFixed(
            GOVERNMENT_CONFIG.INVOICES.GENERAL.DECIMALS
          )
        );
      });
      return arrayCopy;
    });
    return;
  };

  const handlePaymentsErrorUI = () => {
    const failures = [];
    for (let i = 0; i < payments.length; i++) {
      const pago = payments[i];
      if (pago.fechaPago === null || pago.fechaPago === "") {
        failures.push(`El pago ${i + 1} no tiene fecha de pago`);
      }
      if (pago.formaPago === null || pago.formaPago === "") {
        failures.push(`El pago ${i + 1} no tiene forma de pago`);
      }

      if (!isValidNumber(pago.total, 999999999.999999)) {
        failures.push(`El pago ${i + 1} tiene un monto invalido`);
      }
      if (pago.relatedDocuments.length === 0) {
        failures.push(`El pago ${i + 1} no tiene facturas relacionadas`);
      } else {
        pago.relatedDocuments.forEach((document, index) => {
          if (!isValidNumber(document.total, pago.total)) {
            failures.push(
              `El pago ${i + 1}, factura ${index + 1} tiene un monto inválido`
            );
          }
          if (
            document.saldoInsoluto < 0 ||
            !isValidNumber(document.total, document.saldoAnterior)
          ) {
            failures.push(
              `El pago ${i + 1}, factura ${
                index + 1
              } tiene un monto mayor al saldo anterior (balance negativo)`
            );
          }
        });
      }
    }
    console.log(failures);

    props.setDialog({
      ...props.dialog,
      open: true,
      title: "Errores en los pagos",
      message: (
        <>
          {failures.map((str, index) => (
            <Typography key={index} variant="body1">
              {str}
            </Typography>
          ))}
        </>
      ),
      actions: [
        {
          label: "Ok",
          execute: () => props.setDialog({ ...props.dialog, open: false }),
        },
      ],
    });
  };

  const checkPaymentData = () => {
    const failures = [];
    for (let i = 0; i < payments.length; i++) {
      const pago = payments[i];
      if (pago.fechaPago === null || pago.fechaPago === "") {
        failures.push(true);
      }
      if (pago.formaPago === null || pago.PaymentForm === "") {
        failures.push(true);
      }
      if (pago.relatedDocuments.length === 0) {
        failures.push(true);
      } else {
        pago.relatedDocuments.forEach((document, index) => {
          if (!isValidNumber(document.total, pago.total)) {
            failures.push(true);
          }
          if (
            document.saldoInsoluto < 0 ||
            !isValidNumber(document.total, document.saldoAnterior)
          ) {
            failures.push(true);
          }
        });
      }
    }

    if (failures.length > 0) {
      return false;
    }

    return true;
  };

  invoiceJson.Complementos.Payments.Payments = payments;
  window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));

  useImperativeHandle(ref, () => ({
    validate: checkPaymentData,
    showErrors: handlePaymentsErrorUI,
  }));

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <Grid
      container
      spacing={2}
      alignContent="center"
      justifyContent="center"
      width="80vw"
    >
      {/*General Data*/}
      <Grid item xs={12}>
        <Button startIcon={<AddIcon />} onClick={handleAddPayment}>
          Agregar pago
        </Button>
      </Grid>
      {payments.map((payment, index) => {
        return (
          <Grid item xs={12} key={index}>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h5">
                  <b>Pago {index + 1}</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} mb="2vh">
                  {payments.length > 1 && (
                    <Grid item xs={4}>
                      <Button
                        startIcon={<Delete />}
                        color="error"
                        onClick={handleDeletePayment(index)}
                        variant="contained"
                        size="small"
                      >
                        Eliminar este pago
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography color="primary">
                      Información del pago
                    </Typography>
                  </Grid>
                  {/* FECHA Y FORMA DE PAGO */}
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        id="FechaInicio"
                        name="FechaInicio"
                        label="Fecha del pago"
                        required
                        disableFuture
                        value={
                          payments[index].fechaPago
                            ? new Date(payments[index].fechaPago)
                            : new Date()
                        }
                        slotProps={{ textField: { variant: "standard" } }}
                        sx={{ width: "100%" }}
                        onChange={handleChangePaymentDate(index)}
                      />
                    </LocalizationProvider>
                    <Autocomplete
                      id="formaPago"
                      autoComplete
                      sx={{ width: "100%" }}
                      options={options.paymentForms}
                      getOptionLabel={(option) => {
                        if (!option || option === "" || option.Name === "") {
                          return "";
                        }
                        return `${option.Name}`;
                      }}
                      isOptionEqualToValue={(o, v) => {
                        if (v === "") return true;

                        return o.Value === v.Value;
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            id="PaymentForm"
                            sx={{ width: "100%" }}
                            label="Forma de Pago"
                            required
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                              error: error.form,
                            }}
                          />
                        );
                      }}
                      onChange={handleAutocompletesChange(index)}
                      value={payments[index]?.formaPago || ""}
                    />
                  </Grid>
                  {/* FECHA Y FORMA DE PAGO */}

                  {/* Moneda y tipo de cambio */}
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Autocomplete
                      autoComplete
                      sx={{ width: "100%" }}
                      options={options.currencys}
                      getOptionLabel={(option) => option}
                      disabled
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            id="PaymentForm"
                            sx={{ width: "100%" }}
                            label="Divisa"
                            required
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                              error: error.form,
                            }}
                          />
                        );
                      }}
                      isOptionEqualToValue={(o, v) => {
                        if (v === "") return true;

                        return o === v;
                      }}
                      onChange={handleAutocompletesChange(index)}
                      value={payments[index].currency || ""}
                    />
                    <TextField
                      id="tipoCambio"
                      sx={{ width: "100%" }}
                      label="Tipo de cambio"
                      disabled
                      variant="standard"
                      onChange={handleChangeInputs(index)}
                      value={payments[index].tipoCambio || "0.00"}
                    />
                  </Grid>
                  {/* Moneda y tipo de cambio */}

                  {/* TOTAL */}
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <TextField
                      id="total"
                      sx={{ width: "100%" }}
                      label="Monto del pago"
                      variant="standard"
                      onChange={handleChangeInputs(index)}
                      type="number"
                      inputProps={{ step: "0.0000001" }}
                      value={payments[index].total || ""}
                    />
                  </Grid>
                  {/* TOTAL */}
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignContent="center"
                >
                  <Grid item xs={12}>
                    <Typography color="primary">
                      Facturas relacionadas
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      startIcon={
                        showRelationUI[index] ? <Cancel /> : <AddIcon />
                      }
                      onClick={handleRelationConfig(index)}
                      color={showRelationUI[index] ? "error" : "primary"}
                      variant="outlined"
                      sx={{
                        "&:hover": {
                          backgroundColor: showRelationUI[index]
                            ? "error"
                            : "primary",
                        },
                      }}
                    >
                      {showRelationUI[index] ? "Cancelar" : "Configurar"}{" "}
                      relación
                    </Button>
                  </Grid>

                  {payments[index].relatedDocuments.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <CustomTable
                          columns={buildRelatedInvoicesColumns(index)}
                          rows={payments[index].relatedDocuments}
                          emptyMsg="No se encontraron facturas"
                        />
                      </Grid>
                    </>
                  )}

                  {showRelationUI[index] && (
                    <>
                      {/* FILTER */}
                      <Grid container spacing={2}>
                        <Grid item xs={10}>
                          <FilterDrawer
                            hideHeader
                            options={filterOptions}
                            filters={filters}
                            setFilters={setFilters}
                            selected={selectedFilters}
                            setSelected={setSelectedFilters}
                            selectedClientsArray={selectedClientsArray}
                            setSelectedClientsArray={setSelectedClientsArray}
                            selectedIssuersArray={selectedIssuersArray}
                            setSelectedIssuersArray={setSelectedIssuersArray}
                            selectedTypesArray={selectedTypesArray}
                            setSelectedTypesArray={setSelectedTypesArray}
                            toggleFilterDrawer={handleFilterDrawer}
                            rango={range}
                            setRango={setRange}
                            customerInput={customerInput}
                            setCustomerInput={setCustomerInput}
                            issuerInput={issuerInput}
                            setIssuerInput={setIssuerInput}
                            invoiceTypeInput={invoiceTypeInput}
                            setInvoiceTypeInput={setInvoiceTypeInput}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            endIcon={<SearchIcon />}
                            onClick={handleSearch}
                          >
                            Buscar
                          </Button>
                        </Grid>
                      </Grid>
                      {/* FILTER */}

                      {/* RELATED DOCS TABLE */}
                      <Grid item xs={12}>
                        <CustomTable
                          columns={invoiceColumns}
                          rows={invoiceRows}
                          handleSelect={handleRelatedDocumentsChange(index)}
                          selectionState={selectedRelatedDocuments}
                          setSelectionState={setSelectedRelatedDocuments}
                          emptyMsg="No se encontraron facturas"
                          disableSelectionOnClick
                          checkBoxSelection
                        />
                      </Grid>
                      {/* RELATED DOCS TABLE */}

                      {/* ACCEPT BUTTON */}
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="contained"
                          onClick={handleAddRelateDocuments(index)}
                          disabled={selectedRelatedDocuments.length === 0}
                        >
                          Confirmar selección
                        </Button>
                      </Grid>
                      {/* ACCEPT BUTTON */}
                    </>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        );
      })}
    </Grid>
  );
});

export default PaymentConfig;
