import {AxiosError} from "axios";

/**
 * Returns the appropriate error message based on the given AxiosError.
 * @param error - The AxiosError object.
 * @returns The error message.
 */
export const httpErrorResponses = (error: AxiosError | any): string => {
  if (error.response) {
    if (error.response.status === 404) {
      return "No se encontraron resultados.";
    }

    if (error.response.status === 500) {
      return "Ocurrió un error al obtener los datos. Contacte a soporte";
    }

    if (error.response.status === 401) {
      return "No tienes permisos para realizar esta acción.";
    }

    if (error.response.status === 403) {
      return "No tienes permisos para realizar esta acción.";
    }

    if (error.response.status === 400) {
      const {data} = error.response;
      if (data && data.error && data.error.Descriptions) {
        const mapErrors = data.error.Descriptions.map((validationError) => {
          return validationError.Reason;
        });
        return `Verifica los datos: ${mapErrors.join(", ")}`;
      }

      return "Verifica los datos ingresados.";
    }

    return `Error ${error.response.status}: ${error.response.data}`;
  } else if (error.request) {
    return "No response received from the server";
  } else {
    return error.message;
  }
};
