import ExpenseMenuComponent from "./../components/ExpenseMenuComponent";

export function getColumns(userid, fillTableExpenses) {
  return [
    {
      field: "Date",
      headerName: "Fecha",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        const isoDate = new Date(params.row.Date);
        const formattedDate = isoDate.toLocaleDateString("en-MX", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        return formattedDate;
      },
    },
    {
      field: "Description",
      headerName: "Descripción",
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "Subtotal",
      headerName: "SubTotal",
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "IVA",
      headerName: "IVA",
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "Total",
      headerName: "Total",
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "_id",
      headerName: "Acciones",
      renderCell: (params) => {
        return (
          <ExpenseMenuComponent
            editionRoute={`/${userid.claims.rol}/expenses/edit/${params.id}`}
            id={params.id}
            credentials={userid}
            onUpdates={fillTableExpenses}
          />
        );
      },
      flex: 1,
      headerClassName: "header-table",
    },
  ];
}
