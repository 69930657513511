import React, {
  useState,
  useEffect,
  forwardRef,
  useMemo,
  useImperativeHandle,
} from "react";
import {OperatorServiceComponent} from "../Services/ServiceOperator";
import useAuth from "../../../hooks/useAuth";
import {getOperators} from "../../../services/modules/organization/operators";
import {TransportServiceComponent} from "../Services/ServiceVehicle";
import {
  getAllRemolques,
  getAllVehicles,
} from "../../../services/modules/transports/vehicles";
import {isEmptyObject} from "../../../modules/services/utils/ServiceValidationFunctions";
import GOVERNMENT_CONFIG from "../../../utils/GOV_CONFIG";

const OperatorsAndTransportForm = forwardRef(
  ({dialog, setDialog, complete, setComplete}, ref) => {
    const invoiceJson = JSON.parse(window.localStorage.getItem("invoiceModel"));

    const {userid} = useAuth();
    const [operatorsData, setOperatorsData] = useState([]);
    const [trucks, setTrucks] = useState([]);
    const [remolques, setRemolques] = useState([]);
    const [selected, setSelected] = useState({
      Operadores: invoiceJson?.Complementos?.CartaPorte?.FiguraTransporte || [],
      unidades: invoiceJson?.Complementos?.CartaPorte?.Mercancias
        ?.Autotransporte || [{Tractor: {}, Remolques: [{}]}],
    });
    const [errors, setErrors] = useState({
      Operadores: {
        failed: false,
        message: "",
      },
      Unidades: {
        failed: false,
        message: "",
      },
    });

    const handleChangeOperator = (e, v, r) => {
      const id = "Operadores";
      if (r === "selectOption") {
        if (errors.Operadores.failed) {
          const copyErrors = {
            ...errors,
          };
          copyErrors.Operadores = {
            failed: false,
            message: "",
          };

          setErrors(copyErrors);
        }

        setSelected((prevSelected) => {
          const copy = {...prevSelected};

          prevSelected[id].push(v);

          return copy;
        });
      }

      if (r === "clear") {
        setSelected((prevSelected) => {
          const copy = {...prevSelected};
          const arrayCopy = [...copy[id]];

          if (v !== "" || v !== null || v !== undefined) {
            const index = arrayCopy.findIndex((item) => item.Rfc === v.Rfc);
            arrayCopy.splice(index, 1);
          }

          copy[id] = arrayCopy;

          return copy;
        });
      }
    };

    const handleSetVehicles = (e, v, r, idx) => {
      const id = e.target.id.split("-")[0];
      //*if idx is an array then we are setting remolque in the specified idx[0] truck
      if (r === "selectOption" && !Array.isArray(idx)) {
        setSelected((prevSelected) => {
          const copy = {...prevSelected};
          copy.unidades[idx].Tractor = v;
          //*TODO: DO THIS BY CHECKING SAT SPECIFICATIONS
          if (
            !GOVERNMENT_CONFIG.INVOICES.COMPLEMENTS.CARTA_PORTE
              .VEHICLES_REQUIREMENTS.REQUIRE_REMOLQUE_BY_TYPE[v.Specs.Type.Code]
          ) {
            delete copy.unidades[idx].Remolques;
          }
          console.log(copy);
          return copy;
        });
      }

      if (r === "selectOption" && Array.isArray(idx)) {
        setSelected((prevSelected) => {
          const copy = {...prevSelected};
          copy.unidades[idx[0]].Remolques[idx[1]] = v;
          return copy;
        });
      }

      if (r === "clear" && !Array.isArray(idx)) {
        setSelected((prevSelected) => {
          const copy = {...prevSelected};
          if (copy.unidades.length === 1) {
            copy.unidades[0].Tractor = {};
            copy.unidades[0].Remolques = [{}];
            return copy;
          }

          copy.unidades.splice(idx, 1);
          return copy;
        });
      }

      if (r === "clear" && Array.isArray(idx)) {
        setSelected((prevSelected) => {
          const copy = {...prevSelected};
          copy.unidades[idx[0]].Remolques.splice(idx[1], 1);
          return copy;
        });
      }
    };

    const handleAddNewVehicle = () => {
      setSelected((prevSelected) => {
        const copy = {...prevSelected};
        copy.unidades.push({Tractor: {}, Remolques: [{}]});
        return copy;
      });

      if (errors.Unidades.failed) {
        const copyErrors = {
          ...errors,
        };
        copyErrors.Unidades = {
          failed: false,
          message: "",
        };

        setErrors(copyErrors);
      }
    };

    const handleAddNewRemolque = (vehicle, index) => {
      setSelected((prevSelected) => {
        const copy = {...prevSelected};
        copy.unidades[index].Remolques.push({});
        return copy;
      });
    };

    const handleRequestErrorsUi = (err) => {
      if (err.response) {
        if (err.response.status === 404) {
          setDialog({
            ...dialog,
            open: true,
            title: "Error",
            message: "Recurso no encontrado",
          });
        } else if (err.response.status === 500) {
          setDialog({
            ...dialog,
            open: true,
            title: "Error",
            message: "Error de servidor. Contacta a soporte",
          });
        } else {
          setDialog({
            ...dialog,
            open: true,
            title: "Error",
            message: "Error al obtener los datos",
          });
        }
      } else {
        setDialog({
          ...dialog,
          open: true,
          title: "Error",
          message: "Error de conexión",
        });
      }
    };

    const allVehiclesValid = () => {
      return selected.unidades.some((vehicle) => {
        const validTractor = !isEmptyObject(vehicle.Tractor);

        const validRemolques = vehicle.Remolques.some(
          (remolque) => !isEmptyObject(remolque)
        );

        return validTractor && validRemolques;
      });
    };

    const handleErrorsUi = (err) => {
      const copyErrors = {
        ...errors,
      };
      if (selected.Operadores.length === 0) {
        copyErrors.Operadores = {
          failed: true,
          message: "Debes seleccionar al menos un operador",
        };
      }

      if (!allVehiclesValid()) {
        copyErrors.Unidades = {
          failed: true,
          message:
            "Debes seleccionar al menos una unidad o verificar que has seleccionado un tractor y un remolque para cada unidad",
        };
      }

      setErrors(copyErrors);
    };

    const validateOperatorsAndTransport = () => {
      if (selected.Operadores.length === 0) {
        return false;
      }

      if (selected.unidades.length === 0) {
        return false;
      }

      return true;
    };

    useImperativeHandle(ref, () => ({
      validate: validateOperatorsAndTransport,
      showErrors: handleErrorsUi,
    }));

    invoiceJson.Complementos.CartaPorte.FiguraTransporte = selected.Operadores;

    invoiceJson.Complementos.CartaPorte.Mercancias.Autotransporte =
      selected.unidades;

    window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));

    useEffect(() => {
      Promise.all([
        getOperators(userid),
        getAllVehicles(userid, userid),
        getAllRemolques(userid, userid),
      ])
        .then(([operatorsResponse, vehiclesResponse, remolquesResponse]) => {
          setOperatorsData(() => operatorsResponse.data.data);
          setTrucks(() => vehiclesResponse.data);
          setRemolques(() => remolquesResponse.data);
        })
        .catch((err) => {
          handleRequestErrorsUi(err);
        });
    }, [userid]);

    return (
      <>
        <OperatorServiceComponent
          operatorsData={operatorsData}
          selected={selected.Operadores}
          setSelected={handleChangeOperator}
          externalError={errors.Operadores}
        />
        <TransportServiceComponent
          addNewVehicle={handleAddNewVehicle}
          handleAddNewRemolque={handleAddNewRemolque}
          options={{vehicles: trucks, remolques: remolques}}
          vehicles={selected.unidades}
          setVehicles={handleSetVehicles}
          externalError={errors.Unidades}
        />
      </>
    );
  }
);

export default OperatorsAndTransportForm;
