import React from "react";
import { Grid, TextField, Typography } from "@mui/material";

export default function FuelSpecsComponent({
  transport,
  handleChange,
  setTransport,
}) {
  return (
    <>
      <Typography variant="h6" align="center" color="primary.dark">
        Detalles combustible
      </Typography>

      <Grid container spacing={1} sx={{ mt: "1vh" }}>
        <Grid item xs={6}>
          <TextField
            type="number"
            onChange={handleChange}
            id="Specs.FuelSpecs.NumberTanks"
            label="Número de tanques"
            value={transport.Specs.FuelSpecs.NumberTanks}
            InputLabelProps={{
              shrink: Boolean(transport.Specs.FuelSpecs.NumberTanks),
            }}
            variant={"standard"}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            onChange={handleChange}
            id="Specs.FuelSpecs.CapacityInLiters"
            label="Capacidad en litros"
            value={transport.Specs.FuelSpecs.CapacityInLiters}
            InputLabelProps={{
              shrink: Boolean(transport.Specs.FuelSpecs.CapacityInLiters),
            }}
            variant={"standard"}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            type="number"
            onChange={handleChange}
            id="Specs.FuelSpecs.KmLtrSpec"
            label="Kilometros / litros"
            value={transport.Specs.FuelSpecs.KmLtrSpec}
            InputLabelProps={{
              shrink: Boolean(transport.Specs.FuelSpecs.KmLtrSpec),
            }}
            variant={"standard"}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            onChange={handleChange}
            id="Specs.FuelSpecs.KmLtrReal"
            label="Kilometros / litros Real"
            value={transport.Specs.FuelSpecs.KmLtrReal}
            InputLabelProps={{
              shrink: Boolean(transport.Specs.FuelSpecs.KmLtrReal),
            }}
            variant={"standard"}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}
