import React, { useState, useEffect } from "react";
//*MUI COMPONENTS
import {
  Typography,
  CircularProgress,
  Button,
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Box,
  Backdrop,
  Alert,
  AlertTitle,
  TextField,
  Grid,
  StepContent,
} from "@mui/material";
import useAuth from "../../hooks/useAuth";
import {
  AlertDialog,
  InitialDialogState,
} from "../../components/userFeedback/AlertDialog";
import { getFilteredServices, getServiceById, updateTransportService } from "../../services/modules/services/servicesGeneral";
import { useNavigate } from "react-router";
import { isEmptyObject } from "./utils/ServiceValidationFunctions";
import { ActiveServiceManeuverView } from "./OperatorLoadingView";
import Map from "./utils/components/MapTest";
import { ImageUpload } from "./utils/components/EvidenceUpload";
import MapComponent from "../../components/maps/MapComponent";
import { isEmpty } from "../../services/helpers/objects/isEmpty";

export const OperatorActiveService = () => {
  const { userid } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({
    ...InitialDialogState,
    updateOpenState: () => setDialog({ ...dialog, open: false }),
  });

  const [alertState, setAlertState] = useState({
    severity: "info",
    title: "",
    message: "",
    open: false,
  });

  const [serviceId, setServiceId] = useState("");
  const [activeService, setActiveService] =
    useState({}); 

  const [goingBack, setGoingBack] = useState(false);
  const [goingBack2, setGoingBack2] = useState(false);
  const [devolution, setDevolution] = useState(false);
  // Stepper management
  const [activeStep, setActiveStep] = useState(0);

  const ReturnComponent = () =>{
    return(
      <>
      <div style={{height:"400px"}}>
              <MapComponent key={"toStorage"} directions={
                {
                  //origin:activeService.TransportService.CompanyLoad.Address,
                  destinations:[activeService.TransportService.CompanyUnload.Address]
                }
              }/>
              
            </div>
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  setDialog({
                    ...dialog,
                    open: true,
                    title: "Has regresado a la bodega",
                    content:
                      "Regresa cuando sea momento de partir hacia el lugar de descarga",
                    actions: [
                      { label: "Ir a Descarga" },
                      {
                        label: "Ok",
                        execute: () =>
                          navigate(`/${userid.claims.rol}/welcome`),
                      },
                    ],
                  })
                }
              >
                Aceptar
              </Button>
              </>
    )
  }

  const LocalLastStepsComponent = () =>{

    return (
      <>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setDevolution((prev)=>true);
                  handleNext();
                }}
              >
                Devolución?
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button variant="contained" onClick={() => {setGoingBack2(prev=>true);handleNext()}}>
                Completar y regresar a patio
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                onClick={() => navigate(`/${userid.claims.rol}/welcome`)}
              >
                Iniciar nuevo servicio
              </Button>
            </Grid>
          </Grid>
        </>
    )
  }

  const steps = [
    {
      label: "Salida a Carga",
      description: (
        <>
          <TextField
            variant="outlined"
            multiline
            sx={{ width: "100%" }}
            inputProps={{ sx: { minHeight: 200 } }}
            onChange={(e) => console.log("change", e.target.value)}
            label={"Notas"}
          />
        </>
      ),
    },
    /*Conditionally add steps */
    true
      ? {
          label: "En Camino a Carga",
          description: (
            <>
              <div style={{ height: "400px" }}>
                <MapComponent
                  key={"toLoad"}
                  directions={{
                    //origin:activeService.TransportService.CompanyLoad.Address,
                    destinations: [
                      activeService?.TransportService?.CompanyLoad.Address,
                    ],
                  }}
                />
              </div>
              <Button
                onClick={() =>
                  window.open(
                    `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${activeService.TransportService.CompanyLoad.Address.lattitude},${activeService.TransportService?.CompanyLoad.Address.longitude}`
                  )
                }
              >
                IR A MAPS
              </Button>
            </>
          ),
        }
      : null,
    {
      label: "En Cliente Para Carga",
      description: (
        <ActiveServiceManeuverView
          key={"load"}
          activeServiceId={activeService?._id}
          setActiveService={setActiveService}
          type={"Load"}
        />
      ),
    },
    {
      label: "Cargado",
      description: (
        <>
          <Container>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setGoingBack(true);
                    handleNext();
                  }}
                >
                  Regresar a Almacén
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    handleNext();
                  }}
                >
                  Ir a Descarga
                </Button>
              </Grid>
            </Grid>
          </Container>
        </>
      ),
    },
    goingBack
      ? {
          label: "Regreso a la bodega",
          description: <ReturnComponent />,
        }
      : null,
    true
      ? {
          label: "En Camino a Descarga",
          description: (
            <>
              <div style={{ height: "400px" }}>
                <MapComponent
                  key={"toUnload"}
                  directions={{
                    //origin:activeService.TransportService.CompanyLoad.Address,
                    destinations: [
                      activeService?.TransportService?.CompanyUnload.Address,
                    ],
                  }}
                />
              </div>
              <Button
              align={"right"}
                onClick={() =>
                  window.open(
                    `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${activeService.TransportService?.CompanyUnload.Address.lattitude},${activeService.TransportService.CompanyUnload.Address.longitude}`
                  )
                }
              >
                IR A MAPS
              </Button>
            </>
            ),
        }
      : null,
    {
      label: "En Cliente Para Descarga",
      description: (
        <ActiveServiceManeuverView
          key={"unload"}
          activeServiceId={activeService?._id}
          setActiveService={setActiveService}
          type={"UnLoad"}
        />
      ),
    },
    {
      label: "Descarga completa - Últimos pasos",
      description: <LocalLastStepsComponent />,
    },
    devolution
      ? {
          label: "Devolución",
          description: (
            <>
              <ImageUpload
                updateFunction={() => console.log("actualizando evidencias")}
              />
            </>
          ),
        }
      : null,
    goingBack2
      ? {
          label: "Regreso a la bodega",
          description: <ReturnComponent />,
        }
      : null,
  ].filter((step) => step !== null);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  //autoclose feedback alert
  useEffect(() => {
    if (alertState.open) {
      const timer = setTimeout(() => {
        setAlertState({
          severity: "info",
          title: "",
          message: "",
          open: false,
        });
      }, 5000); // Set the duration in milliseconds (e.g., 5000ms = 5 seconds)

      return () => clearTimeout(timer);
    }
  }, [alertState.open]);

  const stepHardJump = (ExecutionStatus) =>{
    const optionsArr =[
      "ESPERA DE SALIDA", //activeStep 0
      "SALIENDO DE PATIO", //activeStep 0
      "EN CAMINO A CARGA", // 1
      "LLEGADA A CLIENTE", // 2
      "ESPERA CARGA", // 2
      "CARGANDO", //2
      "CARGADO", //2
      //evidencias carga different state?
      "EN CAMINO A RESGUARDO", //this should trigger a hook and then set the active step to the newly appeared step
      "CARGADO EN PATIO", // this should be the highest priority active status
      "CAMINO A DESCARGA", // 4
      "ESPERA DESCARGA",//5
      "EN DESCARGA",//5
      "ESPERA EVIDENCIAS",//5
      "EVIDENCIAS RECIBIDAS",//5
      "PROCESO DE DEVOLUCION",//same, first hook and then setting the active step to the correct index
      "PROCESANDO EVIDENCIA",//not really in stepper i guess
      "FINALIZADO"//filter from dashboard
    ]
    //in theory, all timers and other values (like the notes) should already be set to the service object values
    switch (ExecutionStatus) {
      case "":
        
        break;
    
      default:
        break;
    }
  }

  //Changes
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("Id");
    setServiceId(id);
    //if the component gets an Id in the URL, is supposed to edit that object
    if (id) {
      getServiceById(userid, userid, id)
        .then((res) => {
          console.log("getting actual service");
          console.log("res", res);
          let service = res.data;
          if (!isEmptyObject(service)) {
            setActiveService(service);
            //stepHardJump(service.TransportService.ExecutionStatus)
          } else {
            if (servicesArr.length > 0) {
              setDialog({
                ...dialog,
                open: true,
                title: "Servicio Duplicado",
                content:
                  "El servicio existe varias veces en la base de datos, contacte a soporte.",
                actions: [
                  {
                    label: "Salir",
                    execute: () => navigate(`${userid.claims.rol}/welcome`),
                  },
                ],
              });
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      setDialog({
        ...dialog,
        open: true,
        title: "Error",
        content: "No se Puede obtener el servicio",
        actions: [
          {
            label: "Salir",
            execute: () => navigate(`${userid.claims.rol}/welcome`),
          },
        ],
      });
    }
  }, []);



  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <AlertDialog {...dialog} />

      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" color={"primary"} align="center">
            Servicio
          </Typography>
          <Typography component="h1" variant="h5" align="center">
           {serviceId}
          </Typography>

          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step.label}</StepLabel>
                <StepContent>{step.description}</StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <div>
              <Container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <Typography variant="h5">Service End</Typography>
              </Container>

              {alertState.open && (
                <Alert
                  severity={alertState.severity}
                  onClose={() => setAlertState({ ...alertState, open: false })}
                >
                  <AlertTitle>{alertState.title}</AlertTitle>
                  {alertState.message}
                </Alert>
              )}
              {/* Stepper Buttons */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={handleBack} disabled={activeStep === 0}>
                  Anterior
                </Button>

                <Button onClick={handleNext} disabled={true}>
                  Siguiente
                </Button>
              </Box>
            </div>
          ) : (
            <div>
              {/* Content for the current step 
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "2vh",
                  marginTop: "3vh",
                }}
              >
                {steps[activeStep].content}
              </Box>
              */}

              {/* Stepper Buttons */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={handleBack} disabled={activeStep === 0}>
                  Anterior
                </Button>

                <Button onClick={handleNext}>Siguiente</Button>
              </Box>
            </div>
          )}
        </Paper>
      </Container>
    </div>
  );
};
