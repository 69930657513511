import React, { useEffect, useState } from "react";
import {
  ServiceItemGeneric,
  TransportItem,
} from "../../../modules/services/utils/ServiceItemsENUM";
import {
  createDestination,
  getFilteredDestinations,
} from "../../../services/modules/services/destination";
import {
  Accordion,
  AccordionSummary,
  Alert,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { MerchForm } from "./ServiceMerch";
import { DynamicMerchTable } from "../CartaPorte/Products";
//icons
import { LoadingCompanyForm } from "./LoadingCompanyForm";
import { OperatorServiceComponent } from "./ServiceOperator";
import { TransportServiceComponent } from "./ServiceVehicle";
import { ExpandMore } from "@mui/icons-material";
import BaseTransportItem from "./TransportItem/BaseTransportItem";
import { hasObjectValue } from "../../../services/helpers/objects/isEmpty";
import DestinationCompanies from "./TransportItem/DestinationsCompanies";
import ExtraFieldsButtons from "../../actions/TransportItem/ExtraFieldsButtons";

const TransportItemForm = ({
  userid,
  data,
  setData,
  options,
  dialog,
  setDialog,
  alert,
  setAlert,
  snack,
  setSnack,
}) => {
  //*This base structure is set in this way
  //* because the ENUM is an object, and the object is passed by reference, so if the object is modified
  //* the value in the ENUM will be modified too, and that is not the desired behavior.
  const TransportServiceStruct = JSON.parse(
    JSON.stringify({ ...ServiceItemGeneric, ...TransportItem })
  );

  const [selected, setSelected] = useState({ ...TransportServiceStruct });
  const [showField, setShowField] = useState({
    Operadores: data?.TransportService?.Operadores?.length > 0 || false,
    Unidad:
      data?.TransportService?.Vehicles?.length != 0 &&
      hasObjectValue(
        data?.TransportService?.Vehicles
          ? data?.TransportService?.Vehicles[0].Tractor
          : {}
      ),
    merch: false,
  });
  const [loadingInput, setLoadingInput] = useState("");
  const [loadingCompaniesOptions, setLoadingCompaniesOptions] = useState([]);
  const [unloadingInput, setUnloadingInput] = useState("");
  const [unloadingCompaniesOptions, setUnloadingCompaniesOptions] = useState(
    []
  );

  const handleSetLoadingCompany = (type, v = undefined) => {
    //*THE VALUE TO SET THE LOADING COMPANY IS UNDEFINED
    //*SO THE PARENT COMPONENT MANAGES GETTING THE VALUE FROM THE STATES IT HAS DECLARED
    //*IF VALUE IS PROVIDED, THEN IT WILL BE USED.

    if (v) {
      //*TODO:save the company when the value is provided
      return;
    }

    if (!v) {
      const value = window.localStorage.getItem("serviceFormData")
        ? JSON.parse(window.localStorage.getItem("serviceFormData"))
        : data;
      createDestination(userid, value?.TransportService[type])
        .then((result) => {
          resetDialog();
          setSnack({
            ...snack,
            open: true,
            message: "Destino guardado con éxito",
            severity: "success",
            withAlert: true,
          });
          return;
        })
        .catch((err) => {
          if (err.response && err.response.status === 400) {
            setSnack({
              ...snack,
              open: true,
              message:
                err.response?.data?.message ||
                "Error de validación. Verifica la información",
              severity: "warning",
              withAlert: true,
            });
            return;
          }

          if (err.response && err.response.status === 401) {
            setSnack({
              ...snack,
              open: true,
              message: "Error de autorización",
              withAlert: true,
              severity: "error",
            });
            return;
          }

          if (err.response && err.response.status === 404) {
            setSnack({
              ...snack,
              open: true,
              message: "No se ha encontrado el recurso solicitado",
              withAlert: true,
              severity: "error",
            });
            return;
          }

          if (err.response && err.response.status === 500) {
            setSnack({
              ...snack,
              open: true,
              message: "Error de servidor. Intenta de nuevo más tarde.",
              withAlert: true,
              severity: "error",
            });
            return;
          }

          setSnack({
            ...snack,
            open: true,
            message: "Error desconocido. Contacta a soporte",
            severity: "error",
            withAlert: true,
          });
          console.log(err);
        });
    }
  };

  const handleChangeServiceCode = (e, v, r) => {
    let targetValue = e.target.value;
    let id = "Code";

    switch (r) {
      case "selectOption":
        r = "x";
        targetValue = v.Value;
        setSelected({ ...selected, [id]: targetValue });
        setData({
          ...data,
          TransportService: { ...data?.TransportService, [id]: v },
        });
        break;
      case "clear":
        setSelected({ ...selected, [id]: "" });
        setData({
          ...data,
          TransportService: { ...data?.TransportService, [id]: {} },
        });
      default:
        setSelected({ ...selected, [id]: targetValue });
        setData({
          ...data,
          TransportService: {
            ...data?.TransportService,
            [id]: targetValue,
          },
        });
        break;
    }
  };

  const handleChangeSelection = (e, v, r) => {
    let id = e.target.id.split("-")[0];
    let targetValue = e.target.value;

    switch (id) {
      case "SubTotal":
        targetValue = parseFloat(e.target.value);
        setData({
          ...data,
          TransportService: {
            ...data?.TransportService,
            SubTotal: targetValue,
          },
        });
        return;
      case "Operadores":
        switch (r) {
          case "selectOption":
            setSelected({ ...selected, [id]: v });
            setData({
              ...data,
              TransportService: {
                ...data?.TransportService,
                [id]: [...data.TransportService[id], v],
              },
            });
            return;
          case "clear":
            setSelected({ ...selected, [id]: {} });

            //*Set the default value to the defined in the base structure when cleared
            let arrayCopy = data?.TransportService[id];
            if (v === "" || v === undefined) {
              arrayCopy.pop();
            } else if (v !== "" || v !== undefined) {
              arrayCopy = arrayCopy.filter(
                (operator) => operator.Rfc !== v.Rfc
              );
            }

            setData({
              ...data,
              TransportService: {
                ...data?.TransportService,
                [id]: arrayCopy,
              },
            });
            return;
          default:
            break;
        }
        break;
      case "DateTimeLoad":
        if (r === "selectOption") {
          setData({
            ...data,
            TransportService: {
              ...data?.TransportService,
              Load: { ...data?.TransportService?.Load, ScheduleTime: v },
            },
          });
        }
        return;
      case "DateTimeUnload":
        if (r === "selectOption") {
          setData({
            ...data,
            TransportService: {
              ...data?.TransportService,
              Unload: { ...data?.TransportService?.Load, ScheduleTime: v },
            },
          });
        }
        return;
      default:
        break;
    }

    switch (r) {
      case "selectOption":
        setSelected({ ...selected, [id]: v });
        setData({
          ...data,
          TransportService: { ...data?.TransportService, [id]: v },
        });
        break;
      case "clear":
        setSelected({
          ...selected,
          TransportService: {
            ...selected.TransportService,
            [id]: TransportServiceStruct[id],
          },
        });

        //*Set the default value to the defined in the base structure when cleared
        setData({
          ...data,
          TransportService: {
            ...data?.TransportService,
            [id]: TransportServiceStruct[id],
          },
        });
        break;
      default:
        setSelected({ ...selected, [id]: targetValue });
        break;
    }
  };

  const handleChangeWeightUnit = (e, v, r) => {
    setData({
      ...data,
      TransportService: {
        ...data?.TransportService,
        Mercancia: {
          UnidadPeso: v,
        },
      },
    });
  };

  const handleAddProduct = (value, array = null) => {
    if (array && !value) {
      setData((prevData) => {
        const copy = { ...prevData };
        const transportService = { ...copy.TransportService };
        const merch = { ...transportService.Mercancias };
        const mercancia = [...merch.Mercancia];
        let totalWeigth = 0;
        array.forEach((product) => {
          mercancia.push(product);
          merch.Mercancia = mercancia;
          merch.NumTotalMercancias = mercancia.length;
          transportService.Mercancias = merch;
          copy.TransportService = transportService;
          totalWeigth += product.pesoEnKg * product.cantidad;
        });
        transportService.Mercancias.PesoBrutoTotal = totalWeigth;

        return copy;
      });

      return;
    }

    setData((prevData) => {
      const copy = { ...prevData };
      const transportService = { ...copy.TransportService };
      const merch = { ...transportService.Mercancias };
      const mercancia = [...merch.Mercancia];
      let totalWeight = 0;
      mercancia.push(value);
      merch.Mercancia = mercancia;
      merch.NumTotalMercancias = mercancia.length;
      transportService.Mercancias = merch;
      copy.TransportService = transportService;
      totalWeight += value.pesoEnKg * value.cantidad;
      transportService.Mercancias.PesoBrutoTotal = totalWeight;
      return copy;
    });
  };

  const handleChangeUnidad = (e, v, r, index) => {
    //*Index could be an integer or an array of integers. First index is for the vehicle, second index is for the remolque
    const id = e.target.id.split("-")[0];

    switch (r) {
      case "selectOption":
        if (id === "Tractor") {
          let extraKey = "Remolque";

          setData((prev) => {
            const copy = [...prev.TransportService.Vehicles];
            copy[index][id] = v;

            return {
              ...prev,
              TransportService: {
                ...prev.TransportService,
                Vehicles: copy,
              },
            };
          });
        }

        if (id === "Remolque") {
          setData((prev) => {
            const copyVehicles = [...prev.TransportService.Vehicles];
            const copyId = [...copyVehicles[index[0]]["Remolques"]];
            copyId[index[1]] = v;

            copyVehicles[index[0]] = {
              ...copyVehicles[index[0]],
              ["Remolques"]: copyId,
            };

            return {
              ...prev,
              TransportService: {
                ...prev.TransportService,
                Vehicles: copyVehicles,
              },
            };
          });
        }
        break;
      case "clear":
        if (id === "Unidad") {
          setData((prev) => {
            const index = v.index;
            //*INDEX is assigned when the user deletes a vehicle in the ServiceVehicle component
            const copyVehicles = [...prev.TransportService.Vehicles];
            copyVehicles.splice(index, 1);
            return {
              ...prev,
              TransportService: {
                ...prev.TransportService,
                Vehicles: copyVehicles,
              },
            };
          });
        }
        if (id === "Remolque") {
          setData((prev) => {
            const copy = [...prev.TransportService.Vehicles];
            const copyId = [...copy[index[0]]["Remolques"]];
            copyId.splice(index[1], 1);
            copy[index[0]] = {
              ...copy[index[0]],
              ["Remolques"]: copyId,
            };
            return {
              ...prev,
              TransportService: {
                ...prev.TransportService,
                Vehicles: copy,
              },
            };
          });
        }
        break;
    }
  };

  const handleAddNewRemolque = (vehicle, index) => {
    setData((prev) => {
      const copy = [...prev.TransportService.Vehicles];
      copy[index].Remolques.push({});
      return {
        ...prev,
        TransportService: {
          ...prev.TransportService,
          Vehicles: copy,
        },
      };
    });
  };

  const addNewVehicle = () => {
    setData((prev) => {
      return {
        ...prev,
        TransportService: {
          ...prev.TransportService,
          Vehicles: [
            ...prev.TransportService.Vehicles,
            {
              Tractor: {},
              Remolques: [{}],
            },
          ],
        },
      };
    });
  };

  const handleCloseDialog = () => {
    setDialog({ ...dialog, open: false, title: "", content: "", actions: [] });
  };

  const handleMerchForm = () => {
    setDialog({
      ...dialog,
      open: true,
      title: (
        <Typography color={"primary"} variant="h6">
          Mercancía(s) a transportar
        </Typography>
      ),
      content: (
        <MerchForm
          addProduct={handleAddProduct}
          closeDialog={handleCloseDialog}
          handleChangeWeightUnit={handleChangeWeightUnit}
        />
      ),
      actions: [
        {
          label: "Cerrar",
          execute: () => setDialog({ ...dialog, open: false }),
        },
      ],
      keep: false,
      updateOpenState: (v) => setDialog({ ...dialog, open: v }),
    });
  };

  const handleDeleteProduct = (product) => {
    setData((prevData) => {
      const copy = { ...prevData };
      const tarnsportService = { ...copy.TransportService };
      const merch = { ...tarnsportService.Mercancias };
      const mercancia = [...merch.Mercancia];
      const index = mercancia.indexOf(product);
      if (index !== -1) {
        mercancia.splice(index, 1);
      }
      merch.Mercancia = mercancia;
      merch.NumTotalMercancias = mercancia.length;
      tarnsportService.Mercancias = merch;
      copy.TransportService = tarnsportService;
      return copy;
    });
  };

  const resetDialog = () => {
    setDialog({
      ...dialog,
      open: false,
      title: "",
      content: "",
      actions: [],
    });
  };

  const handleChangeFormData = (e, v, r, type) => {
    const key = type;
    const id = e.target.id;

    function isOutSideAddress(id) {
      const outOfAddressProps = ["Rfc", "TaxName", "Type"];
      return outOfAddressProps.includes(id);
    }

    if (id !== "Address") {
      if (isOutSideAddress(id)) {
        setData((prevForm) => {
          // Create a deep copy of the prevForm
          const copy = { ...prevForm };

          // Ensure the nested structure exists
          if (!copy.TransportService) {
            copy.TransportService = {};
          }
          if (!copy.TransportService[type]) {
            copy.TransportService[type] = {};
          }

          // Directly update the nested property
          copy.TransportService[type][id] = v;

          // Return the updated form data
          return copy;
        });

        return;
      }

      setData({
        ...data,
        TransportService: {
          ...data.TransportService,
          [key]: {
            ...data.TransportService[key],
            Address: {
              ...data.TransportService[key]["Address"],
              [id]: v,
            },
          },
        },
      });
      return;
    }

    setData({
      ...data,
      TransportService: {
        ...data.TransportService,
        [key]: {
          ...data.TransportService[key],
          [id]: v,
        },
      },
    });
  };

  const handleNewLoadingCompany = (type) => {
    setDialog({
      ...dialog,
      open: true,
      title: "Nueva Compañía de Carga/Descarga",
      content: (
        <LoadingCompanyForm
          formData={data}
          setFormData={handleChangeFormData}
          type={type}
          userid={userid}
        />
      ),
      actions: [
        {
          label: "Guardar destino",
          execute: () => handleSetLoadingCompany(type),
        },
        {
          label: "Cancelar",
          execute: () => {
            handleChangeSelection(
              {
                target: {
                  id: type,
                  value: TransportItem[type],
                },
              },
              TransportItem[type],
              "clear"
            );
            resetDialog();
          },
        },
      ],
      keep: true,
      updateOpenState: (v) => setDialog({ ...dialog, open: v }),
    });
  };

  const handleShowField = (id) => {
    setShowField({
      ...showField,
      [id]: !showField[id],
    });
  };

  useEffect(() => {
    if (!showField.Unidad) {
      setData({
        ...data,
        TransportService: {
          ...data?.TransportService,
          Vehicles: TransportItem.Vehicles,
        },
      });
    }
  }, [showField.Unidad]);

  useEffect(() => {
    if (!showField.Operadores) {
      setData({
        ...data,
        TransportService: {
          ...data?.TransportService,
          Operadores: TransportItem.Operadores,
        },
      });
    }
  }, [showField.Operadores]);

  useEffect(() => {
    let active = true;
    if (loadingInput !== undefined && loadingInput.length > 3) {
      let filters = [
        {
          label: "q",
          param: "q",
          value: loadingInput,
        },
      ];
      getFilteredDestinations(userid, filters)
        .then((res) => {
          if (res.data.length > 0) {
            setLoadingCompaniesOptions(res.data);
          } else {
            setAlert({
              ...alert,
              open: true,
              title: "Sin resultados",
              message:
                "No se encontraron coincidencias con los valores ingresados.",
              severity: "warning",
            });
          }
        })
        .catch((err) => {
          setAlert({
            ...alert,
            open: true,
            title: "Error",
            message: "Error al obtener resultados, intente nuevamente",
            severity: "error",
          });
          console.log(err);
        });
    }

    return () => {
      active = false;
    };
  }, [loadingInput]);

  useEffect(() => {
    let active = true;
    if (unloadingInput !== undefined && unloadingInput.length > 3) {
      let filters = [
        {
          label: "q",
          param: "q",
          value: unloadingInput,
        },
      ];
      getFilteredDestinations(userid, filters)
        .then((res) => {
          if (res.data.length > 0) {
            setUnloadingCompaniesOptions(res.data);
          } else {
            setAlert({
              ...alert,
              open: true,
              title: "Sin resultados",
              message:
                "No se encontraron coincidencias con los valores ingresados.",
              severity: "warning",
            });
          }
        })
        .catch((err) => {
          setAlert({
            ...alert,
            open: true,
            title: "Error",
            message: "Error al obtener resultados, intente nuevamente",
            severity: "error",
          });
          console.log(err);
        });
    }

    return () => {
      active = false;
    };
  }, [unloadingInput]);

  return (
    <>
      <Grid container spacing={2}>
        {/* TODO: update the props to just one function to update the inputs inside the base transport items */}
        <BaseTransportItem
          data={data}
          options={options}
          changeSelection={handleChangeSelection}
          changeServiceCode={handleChangeServiceCode}
        />

        <DestinationCompanies
          //*DATA IS FORM DATA AND OPTIONS THE OPTIONS OF THE AUTOCOMPLETES (LOCATIONS)
          CompanyLoad={data?.TransportService?.CompanyLoad}
          CompanyUnload={data?.TransportService?.CompanyUnload}
          dateTimeLoad={data?.TransportService?.Load?.ScheduleTime}
          dateTimeUnload={data?.TransportService?.Unload?.ScheduleTime}
          options={[loadingCompaniesOptions, unloadingCompaniesOptions]}
          handleChangeSelection={handleChangeSelection}
          setLoadingCompanyInput={setLoadingInput}
          setUnloadingCompanyInput={setUnloadingInput}
          handleShowForm={handleNewLoadingCompany}
          snack={snack}
          setSnack={setSnack}
        />

        {/* Operator truck and merch extra fields buttons */}
        <ExtraFieldsButtons
          showField={showField}
          handleShowField={handleShowField}
          handleMerchForm={handleMerchForm}
        />
        {/* End Aditional Buttons */}

        {/* Additional fields in creation*/}
        {(showField.Operadores ||
          data?.TransportService?.Operadores?.length > 0) && (
          <Grid item xs={12}>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                Operadores
              </AccordionSummary>
              <Box textAlign="center">
                <Typography variant="h4">Operadores</Typography>
              </Box>
              <Alert severity="info" sx={{ marginY: "1vh" }}>
                <strong>Nota:</strong>Los operadores se asignan a los vehículos
                en el mismo orden en que los agregas. Por ejemplo, si agregas al
                operador 1 y luego al operador 2, el operador 1 se asignará
                automáticamente al vehículo 1, y así sucesivamente.
              </Alert>
              <OperatorServiceComponent
                operatorsData={options?.operators}
                selected={data?.TransportService?.Operadores}
                setSelected={handleChangeSelection}
              />
            </Accordion>
          </Grid>
        )}
        {showField.Unidad && (
          <Grid item xs={12} md={12}>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                Vehículos
              </AccordionSummary>
              <Box textAlign="center">
                <Typography variant="h4">Vehículos</Typography>
              </Box>
              <TransportServiceComponent
                options={{
                  vehicles: options?.vehicles,
                  remolques: options?.remolques,
                }}
                vehicles={data?.TransportService?.Vehicles}
                setVehicles={handleChangeUnidad}
                handleAddNewRemolque={handleAddNewRemolque}
                addNewVehicle={addNewVehicle}
              />
            </Accordion>
          </Grid>
        )}
        {data?.TransportService?.Mercancias?.Mercancia?.length > 0 && (
          <Grid item xs={12}>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                Mercancías
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.5ev", fontWeight: "bold", marginX: "2vh" }}
                >
                  Número total de mercancias:{" "}
                  {data?.TransportService?.Mercancias?.Mercancia.length}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.5ev", fontWeight: "bold", marginX: "2vh" }}
                >
                  Peso bruto total:{" "}
                  {data?.TransportService?.Mercancias?.PesoBrutoTotal}{" "}
                  {data?.TransportService?.Mercancias?.UnidadPeso?.Value}
                </Typography>
              </AccordionSummary>
              <Box textAlign="center">
                <Typography variant="h4">Mercancías</Typography>
              </Box>
              <DynamicMerchTable
                data={data?.TransportService?.Mercancias?.Mercancia}
                handleDelete={handleDeleteProduct}
              />
            </Accordion>
          </Grid>
        )}
      </Grid>
    </>
  );
};

TransportItemForm.defaultProps = {
  formData: {},
  setFormData: () => {
    return;
  },
  options: {
    operators: [],
    vehicles: [],
    remolques: [],
    productCodes: [],
    loadingCompanies: [],
  },
};

export { TransportItemForm };
