import apiObj from "../../../axios";

const { api, version } = apiObj;

export const createUser = (usr, cred, userFormData) => {
  return api.post(`${version}/users`, userFormData, {
    headers: {
      user: usr,
      authorization: usr.token,
    },
  });
};

export const getUserById = (usr, cred, userId) => {
  return api.get(`${version}/users/${userId}`, {
    headers: {
      user: usr,
      authorization: usr.token,
    },
  });
};

export const getUserOrganization = (usr, cred, userid) => {
  return api.get(`${version}/users/${userid}/organization`, {
    headers: {
      user: usr,
      authorization: cred.token,
    },
  });
};

export const updateUserOrganizationConfig = (usr, cred, userid, config) => {
  return api.put(`${version}/users/${userid}/organization/config`, config, {
    headers: {
      user: usr,
      authorization: cred.token,
    },
  });
};
