import axios from "axios";
const hosts = [
  "http://localhost:9999",
  "https://stagingtest-eff3a839c61c.herokuapp.com/",
  "https://fervytest.herokuapp.com/",
  "https://fervystaging-b09417c2ba75.herokuapp.com/",
  "https://beta.transportech.cloud",
  "https://staging.transportech.cloud",
  "https://app.transportech.cloud",
];
const version = "api/v1";
const version2 = "api/v2";

const api = new axios.create({
  baseURL: hosts[5],
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: true,
});

const apiObj = { api, version, version2 };
export default apiObj;
