import React, { useState } from "react";
import useAuth from "../../../hooks/useAuth";
import { uploadProductsCsv } from "../../../services/modules/services/products";
import { TransportServiceProductsForm } from "../Products/TransportServiceProductsForm";
import { Collapse } from "@material-ui/core";
import { Alert, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const MerchForm = ({ addProduct, closeDialog, handleChangeWeightUnit }) => {
  const { userid } = useAuth();
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    state: "",
    message: "",
  });

  const handleAddProduct = (inputProduct) => {
    addProduct(inputProduct);
  };

  const handleSendFile = (csv) => {
    setLoadingCSV(true);
    uploadProductsCsv(csv, userid, userid)
      .then((response) => {
        const convertedArray = response.data.map((obj) => ({
          productCode: { Value: obj.BienesTransp, Name: obj.Descripcion },
          claveUnidad: { Value: obj.ClaveUnidad, Name: obj.Unidad },
          embalaje: { Value: obj.Embalaje, Name: obj.Embalaje },
          cantidad: Number(obj.Cantidad),
          pesoEnKg: Number(obj.PesoEnKg),
          moneda: "MXN",
        }));

        addProduct(null, convertedArray);
        setLoadingCSV(false);
        closeDialog();
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 400) {
          setAlert({
            ...alert,
            show: true,
            state: "error",
            message: "Verifica el formato del archivo. Ver plantilla",
          });
          return;
        }

        if (err?.response?.status === 500) {
          setAlert({
            ...alert,
            show: true,
            state: "error",
            message: "Error del servidor. Contacta a soporte.",
          });
          return;
        }

        if (err?.response?.status === 404) {
          setAlert({
            ...alert,
            show: true,
            state: "error",
            message: "No se encontró el recurso. Contacta a soporte.",
          });
          return;
        }

        if (err?.response?.status === 401) {
          setAlert({
            ...alert,
            show: true,
            state: "error",
            message: "Error de autorización. Recarga la página",
          });
          return;
        }

        setAlert({
          ...alert,
          state: "error",
          show: true,
          message:
            "Hubo un problema al procesar el archivo. Intentalo de nuevo.",
        });
        setLoadingCSV(false);
        return;
      });
  };

  return (
    <>
      <TransportServiceProductsForm
        addProduct={handleAddProduct}
        sendFile={handleSendFile}
        handleChangeWeightUnit={handleChangeWeightUnit}
        loading={loadingCSV}
      />
      <Collapse in={alert.show}>
        <Alert
          severity={alert.state}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlert({ ...alert, show: false });
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          {alert.message}
        </Alert>
      </Collapse>
    </>
  );
};

export { MerchForm };
