//*React
import React, { useState } from "react";
//*MUI
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
//*Network and navigation
import { useNavigate } from "react-router";
//*App components
import { AlertDialog } from "../../../components/userFeedback/AlertDialog";
//*Context hooks
import useAuth from "../../../hooks/useAuth";

const InvoicesIndex = () => {
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    message: "",
    actions: [{ label: "", execute: () => {} }],
    keep: false,
  });

  //*Contexts
  const { userid } = useAuth();
  const navigate = useNavigate();

  const [showDialog, setShowDialog] = useState(false);

  const handleNewClick = () => {
    setShowDialog(true);
  };

  const handleShowDialog = (bool) => {
    setDialog({
      ...dialog,
      open: bool,
    });
  };

  const categories = [
    {
      label: "Grupal",
      description: "Para grupos pre-configurados en el módulo de empleados",
      goToLink: () => console.log("clickau"),
    },
    {
      label: "Selectiva",
      description:
        "Te permite seleccionar manualmente a los empleados por facturar",
      goToLink: () => console.log("clickau"),
    },
    {
      label: "Individual",
      description: "Para facturar a un empleado registrado en específico",
      goToLink: () =>
        navigate(`/${userid.claims.rol}/empleados/nominaIndividual`),
    },
    {
      label: "Libre",
      description: "Formato de Nómina individual completamente configurable",
      goToLink: () => linkDeduction(`/invoices/nomina`),
    },
  ];

  const [nominaOrdinaria, setNominaOrdinaria] = useState(true);

  const linkDeduction = (lunk) => {
    let type;
    if (nominaOrdinaria) {
      type = "O";
    } else {
      type = "E";
    }

    navigate(`/${userid.claims.rol}${lunk}?type=${type}`);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "10vh",
      }}
    >
      <AlertDialog
        updateOpenState={handleShowDialog}
        open={dialog.open}
        title={dialog.title}
        content={dialog.message}
        actions={dialog.actions}
        keep={dialog.keep}
      />
      <Dialog fullWidth maxWidth="md" open={showDialog}>
        <DialogContent>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid item xs={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="secondary"
                    gutterBottom
                  >
                    Factura de tipo
                  </Typography>
                  <Typography variant="h5" component="div">
                    Transporte
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="secondary">
                    Activa
                  </Typography>
                  <Typography variant="body2">
                    Para facturas de servicios de traslado de mercancías
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{ marginTop: "auto", justifyContent: "space-between" }}
                >
                  <Button size="small">Configurar</Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="secondary"
                    gutterBottom
                  >
                    Factura de tipo
                  </Typography>
                  <Typography variant="h5" component="div">
                    Nómina Ordinaria
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="secondary">
                    Activa
                  </Typography>
                  <Typography variant="body2">
                    Para pagos periódicos como salarios
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{ marginTop: "auto", justifyContent: "space-between" }}
                >
                  <Button onClick={() => console.log("click")} size="small">
                    Configurar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="secondary"
                    gutterBottom
                  >
                    Factura de tipo
                  </Typography>
                  <Typography variant="h5" component="div">
                    Nómina Extraordinaria
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="secondary">
                    Activa
                  </Typography>
                  <Typography variant="body2">
                    Para pagos especiales como aguinaldos o bonos
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{ marginTop: "auto", justifyContent: "space-between" }}
                >
                  <Button size="small">Configurar</Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Grid container spacing={{ xs: 3, sm: 1 }} maxWidth={"lg"}>
        {/* <Grid item xs={12}>
                <Box sx={{display:"flex", justifyContent:"flex-end"}}>
                    <Button variant="contained" 
                    onClick={handleNewClick}
                    color="primary"
                    sx={{color:"white"}}
                    >
                        Nueva factura
                    </Button>
                </Box>
            </Grid> */}
        <Grid item xs={12}>
          <Typography variant="h4" color="primary">
            Nóminas
          </Typography>
        </Grid>

        {categories.map((category, idx) => {
          return (
            <Grid
              key={category.label + idx}
              item
              xs={12}
              sm={4}
              sx={{ paddingX: 1 }}
            >
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="secondary"
                    gutterBottom
                  >
                    Nómina
                  </Typography>
                  <Typography variant="h5" component="div">
                    {category.label}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="secondary">
                    Activa
                  </Typography>
                  <Typography variant="body2">
                    {category.description}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{ marginTop: "auto", justifyContent: "space-between" }}
                >
                  <Button onClick={category.goToLink} size="small">
                    Configurar
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export { InvoicesIndex as EmployeesNominaIndex };
