import React, {useState} from "react";
import {FormExpenseComponent} from "../../../components/Forms/Expenses/FormExpenseComponent";
import FormItemExpenseComponent from "../../../components/Forms/Expenses/FormItemExpenseComponent";

import useAuth from "../../../hooks/useAuth";
import {useNavigate} from "react-router";
import {
  ExpenseDto,
  ExpenseFormDateInputFieldsConfigDto,
  ExpenseFormDescriptionInputFieldsConfigDto,
} from "./dto/expenses.dto";
import {
  saveExpense,
  uploadExpenseFile,
} from "../../../services/modules/expenses/expenses";
import {DynamicFieldConfig} from "../../../components/layout/interfaces/DynamicFieldConfig.interface";
import {Grid} from "../../../components/layout/Grid";
import {Button} from "@mui/material";
import useAppContext from "../../../hooks/useAppContext";
import {httpErrorResponses} from "../../../components/userFeedback/httpErrorResponses";

//*We should define the logic of how the data is going to be used on the parents that uses the formExpenseComponent
/**
 * Renders a component for creating an expense.
 * @returns JSX.Element
 */
export function CreateExpense() {
  const {userid} = useAuth();
  const {dialog, setDialog, resetDialog} = useAppContext();

  const navigate = useNavigate();

  /**
   * Handles error UI by displaying an error dialog with the appropriate message.
   * @param err - The error object.
   */
  const handleErrorUi = (err: any) => {
    if (err.response) {
      const message = httpErrorResponses(err);
      setDialog({
        open: true,
        title: "Error",
        message: message,
        actions: [
          {
            label: "Cerrar",
            execute: () => {
              resetDialog();
            },
          },
        ],
      });
      return;
    }

    if (err.message) {
      setDialog({
        open: true,
        title: "Error",
        message: err.message,
        actions: [
          {
            label: "Cerrar",
            execute: () => {
              resetDialog();
            },
          },
        ],
      });
      return;
    }

    setDialog({
      open: true,
      title: "Error",
      message: "Ha ocurrido un error, por favor intente de nuevo",
      actions: [
        {
          label: "Cerrar",
          execute: () => {
            resetDialog();
          },
        },
      ],
    });
  };

  //*Used to handle validation inside the form
  const formRef = React.useRef(null);
  //*Used to handle the expense data
  const [expense, setExpense] = useState({...ExpenseDto});

  //* USE FOR SHOW MESSAGE FOR WAITING
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = () => {
    if (formRef.current) {
      const inputsValid = formRef.current.VALIDATE();
      //* fORM IS HANDLING THE VALIDATION AND THE UI FOR THE USER
      if (!inputsValid) return;

      //*We take the data from inside the form using ref
      console.log(formRef.current.dataState, "formRef.current.dataState");
      let payload = {...formRef.current.dataState};

      //*We take the current files from the form if there are any
      const currentFiles = formRef.current?.dataState?.Files || [];

      if (currentFiles.length > 0) {
        const formData = new FormData();

        for (const key in payload) {
          //*Todo: Map that the key is not hardcoded, instead comes from the expensesDto configuration
          if (key === "Files") {
            const files = payload[key];

            if (files.length === 0) {
              continue;
            }
            
            files.forEach((file: File) => {
              formData.append(key, file);
            });
          } else {
            formData.append(key, payload[key]);
          }
        }
        payload = formData;
      }

      setIsLoading(true);
      saveExpense(userid, payload)
        .then(async (response) => {
          if (currentFiles.length === 0) {
            navigate(`/${userid.claims.rol}/expenses`);
          } else {
            // //WE NEED TO UPLOAD SELECTED FILES
            // for (const file of currentFiles) {
            //   try {
            //     if (!file._id) {
            //       const formData = new FormData();
            //       formData.append("file", file.File);
            //       const responseFile = await uploadExpenseFile(
            //         userid,
            //         response.data._id,
            //         formData
            //       );
            //     }
            //   } catch (err) {
            //     console.log(err);
            //   }
            // }

            navigate(`/${userid.claims.rol}/expenses`);
          }
        })
        .catch((err) => {
          handleErrorUi(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Grid
      container
      spacing={2}
      alignContent="center"
      justifyContent="center"
      alignItems="center"
      justifyItems="center"
      padding={{xs: 2, lg: 7, md: 7, xl: 10}}
    >
      <FormExpenseComponent
        expense={expense}
        setExpense={setExpense}
        isLoading={isLoading}
        onSubmit={onSubmit}
        ref={formRef}
      />

      <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
        <Button variant="contained" onClick={onSubmit} disabled={isLoading}>
          Crear registro
        </Button>
      </Grid>
    </Grid>
  );
}
