import React from "react";
import { Grid, Typography, Autocomplete, TextField } from "@mui/material";

const EmployeePaymentsForm = ({
  employeeData,
  options,
  onAutoCompleteChange,
  onInputChange,
}) => {
  const handleInputChange = (e) => {
    if (e.target.type === "number") {
      e.target.value = Number(e.target.value);
      // Handle number input
      onInputChange(e);
    } else {
      // Handle other input types
      onInputChange(e);
    }
  };

  const handleAutoCompleteChange = (e, v, r) => {
    onAutoCompleteChange(e, v, r);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography color="primary">Datos del Pago</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          id="Periodicidad"
          autoComplete
          sx={{ width: "100%" }}
          options={options.frequency}
          value={employeeData.Periodicidad || ""}
          getOptionLabel={(option) => {
            return `${option.Name}`;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                sx={{ width: "100%" }}
                variant="standard"
                label="Periodicidad del pago"
                required
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }}
          onChange={handleAutoCompleteChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          id="Banco"
          autoComplete
          sx={{ width: "100%" }}
          options={options.bank}
          value={
            { Value: employeeData.BancoClave, Name: employeeData.Banco } || ""
          }
          getOptionLabel={(option) => {
            return `${option.Name}`;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                sx={{ width: "100%" }}
                variant="standard"
                label="Banco"
                required
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            );
          }}
          onChange={handleAutoCompleteChange}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          id="cta-banco"
          name="CtaBanco"
          label="Cuenta bancaria"
          fullWidth
          value={employeeData.CtaBanco || ""}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          id="sueldo"
          name="Sueldo"
          label="Sueldo"
          InputProps={{
            startAdornment: "$",
            inputProps: {
              step: "0.01",
              type: "number",
              pattern: "[0-9]*",
            },
          }}
          placeholder={"0.00"}
          fullWidth
          value={employeeData.Sueldo || ""}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          id="sueldo-base"
          name="SueldoBase"
          label="Sueldo base"
          InputProps={{
            startAdornment: "$",
            inputProps: {
              step: "0.01",
              type: "number",
              pattern: "[0-9]*",
            },
          }}
          placeholder={"0.00"}
          fullWidth
          value={employeeData.SueldoBase || ""}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          id="sueldo-diario"
          name="SueldoDiario"
          label="Sueldo diario"
          InputProps={{
            startAdornment: "$",
            inputProps: {
              step: "0.01",
              type: "number",
              pattern: "[0-9]*",
            },
          }}
          placeholder={"0.00"}
          fullWidth
          type="number"
          value={employeeData.SueldoDiario || ""}
          onChange={handleInputChange}
        />
      </Grid>
    </>
  );
};

export default EmployeePaymentsForm;