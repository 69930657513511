import React, { useEffect, useState } from "react";
import { Alert, Button, Grid, Snackbar, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { EditComponent } from "./components/EditComponent";
import useAuth from "../../../../../hooks/useAuth";
import {
  AlertDialog,
  InitialDialogState,
} from "../../../../../components/userFeedback/AlertDialog";
import { useNavigate } from "react-router";
import ferbyTheme from "../../../../../theme/ferbyTheme";
import {
  getIssuers,
  searchIssuer,
} from "../../../../../services/modules/invoices/issuers";
import { ConsultSearchComponent } from "../../../../../components/actions/ConsultSearchComponent";
import { getGenericErrorDialogContent } from "../../../../../components/userFeedback/AlertDialog";
import { getErrorMessageFromStatus } from "../../../../../utils/httpResponses";

const defaultVisibility = {
  Nombre: true,
  Rfc: false,
  EmployerRegistration: true,
  _id: true,
};

const issuerColumns = [
  {
    field: "Nombre",
    headerName: "Nombre",
    width: 300,
    flex: 1,
    headerClassName: "header-table",
  },
  {
    field: "Rfc",
    headerName: "RFC",
    editable: true,
    width: 150,
    flex: 1,
    headerClassName: "header-table",
  },
  {
    field: "EmployerRegistration",
    headerName: "Registro empleador",
    width: 150,
    editable: true,
    flex: 1,
    headerClassName: "header-table",
  },
  {
    field: "_id",
    headerName: "Acciones",
    renderCell: (params) => {
      return <EditComponent params={params} />;
    },
    flex: 1,
    headerClassName: "header-table",
  },
];

export const ConsultIssuers = ({
  visibility = defaultVisibility,
  checkBoxSelection = false,
  onChangeSelection = (issuers) => {},
  hideRegister = false,
  clearSelection = false,
}) => {
  const { userid, credential } = useAuth();
  const navigate = useNavigate();

  const [alert, setAlert] = useState({
    open: false,
    severity: "success",
    duration: 0,
    message: "",
  });

  const [dialog, setDialog] = useState({
    ...InitialDialogState,
    updateOpenState: () => setDialog({ ...dialog, open: false }),
  });

  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [issuerRows, setIssuerRows] = useState([]);

  const handleOuterSearch = (term) => {
    if (term !== "") {
      console.log("Buscar Emisores");
      setSearchTerm((prev) => term);
      const issuersFilter = {
        label: "query",
        param: "q",
        value: term,
      };
      setLoading(true);

      searchIssuer(userid, credential, issuersFilter.value, issuersFilter.param)
        .then((res) => {
          let issuersData = res.data.data;
          if (issuersData.length > 0) {
            let rowsToSet = getIssuerRowsFromResponse(issuersData);

            setIssuerRows((ir) => rowsToSet);
            setLoading(false);
            setSearched(true);
          } else {
            setLoading(false);
            setAlert({
              open: true,
              severity: "warning",
              duration: 5000,
              message: "No se encontraron resultados",
            });
          }
        })
        .catch((err) => {
          setSearched(false);
          getResponseFromError(err);
        });
    }
  };
  const removeFilters = () => {
    if (searched) {
      getData();
    }
    setSearched(false);
    setSearchTerm("");
  };
  const getIssuerRowsFromResponse = (issuersData) => {
    let rowsToSet = [];
    issuersData.forEach((element) => {
      rowsToSet.push({
        id: element._id,
        _id: element._id,
        Nombre: element.Name,
        Rfc: element.Rfc,
        EmployerRegistration: element.EmployerRegistration,
      });
    });

    return rowsToSet;
  };
  const getResponseFromError = (err) => {
    console.log(err);
    setLoading((l) => false);

    if (err.response) {
      setAlert({
        open: true,
        severity: "error",
        duration: 5000,
        message: getErrorMessageFromStatus(err.response.status),
      });
      return;
    }

    setDialog((dialog) => {
      return {
        ...dialog,
        open: true,
        title: "ERROR Obteniendo emisores",
        content: getGenericErrorDialogContent("default"),
        actions: [
          {
            label: "Recargar sesión",
            execute: () => window.location.reload(),
          },
          {
            label: "Intentar más tarde",
            execute: () => navigate(`/${userid.claims.rol}/welcome`),
          },
        ],
      };
    });
  };
  const getData = () => {
    getIssuers(userid, userid.token)
      .then((res) => {
        let issuersData = res.data.data;
        let rowsToSet = getIssuerRowsFromResponse(issuersData);
        setIssuerRows((ir) => rowsToSet);
        setLoading((l) => false);
      })
      .catch((err) => {
        getResponseFromError(err);
      });
  };

  const handleSelectionModelChange = (selection) => {
    const rows = [];
    selection.forEach((element) => {
      const rowtoAdd = issuerRows.find((row) => element === row._id);
      if (rows.includes(rowtoAdd)) return;
      if (rowtoAdd) {
        rows.push(rowtoAdd);
      }
    });
    onChangeSelection(rows);
  };

  const handleCloseAlert = () => {
    setAlert((alert) => {
      return { ...alert, open: !alert.open };
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <AlertDialog {...dialog} />
      <Snackbar
        open={alert.open}
        autoHideDuration={alert.duration}
        onClose={handleCloseAlert}
      >
        <Alert severity={alert.severity}>{alert.message}</Alert>
      </Snackbar>
      <Grid
        container
        spacing={2}
        sx={{
          padding: "2rem",
          justifyContent: "center",
          alignItems: "center",
          justifyItems: "center",
          alignContent: "center",
        }}
      >
        <Grid item xs={3} display="flex" justifyContent={"left"}>
          <Typography variant="h4" align="center" color="primary.dark">
            {"Emisores"}
          </Typography>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent={"left"}>
          <ConsultSearchComponent
            label={"Buscar (Nombre, RFC)"}
            onSearch={handleOuterSearch}
            handleClearSerch={removeFilters}
          />
        </Grid>
        {!hideRegister && (
          <Grid item xs={3} display="flex" justifyContent={"right"}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ color: "white" }}
              onClick={() =>
                navigate(`/${userid.claims.rol}/invoices/NuevoEmisor`)
              }
            >
              {"Registrar Emisor"}
            </Button>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{
            "& .header-table": {
              backgroundColor: ferbyTheme.palette.primary.main,
              color: "white",
            },
          }}
        >
          <DataGrid
            sx={{ width: "100%" }}
            rows={issuerRows}
            autoHeight
            columns={issuerColumns}
            columnVisibilityModel={visibility}
            disableSelectionOnClick
            checkboxSelection={checkBoxSelection}
            onSelectionModelChange={handleSelectionModelChange}
          />
        </Grid>
      </Grid>
    </>
  );
};
