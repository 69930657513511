const FISCAL_REGIMES = {
  "601": "General de Ley Personas Morales",
  "605": "Sueldos y salarios e ingresos asimilados a salarios",
  "606": "Arrendamiento",
  "607": "Régimen de Enajenación o Adquisición de Bienes",
  "608": "Demás ingresos",
  "609": "Consolidación",
  "610": "Residentes en el Extranjero sin Establecimiento Permanente en México",
  "611": "Ingresos por Dividendos (socios y accionistas)",
  "612": "Personas Morales con Fines no Lucrativos",
  "614": "Ingresos por intereses",
  "616": "Sin obligaciones fiscales",
  "620": "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
  "621": "Incorporación Fiscal",
  "622": "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
  "623": "Opcional para Grupos de Sociedades",
  "624": "Coordinados",
  "626": "Personas físicas con actividades empresariales y profesionales",
  "628": "Hidrocarburos",
};

export default FISCAL_REGIMES;