import React from "react";
import { Grid, Typography, TextField } from "@mui/material";

const EmployeeAddress = ({ employeeData, onLocationChange }) => {
  const handleLocations = (e) => {
    onLocationChange(e);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography color="primary">Domicilio</Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <TextField
          sx={{ minWidth: "100%" }}
          variant="standard"
          name="CP"
          label="Código postal"
          id="CodigoPostal"
          value={employeeData.DomFiscal.CP || ""}
          onChange={handleLocations}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          sx={{ minWidth: "100%" }}
          variant="standard"
          disabled
          label="Localidad"
          name="Localidad"
          id="Localidad"
          value={employeeData.DomFiscal.Localidad || ""}
          onChange={handleLocations}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled
          sx={{ minWidth: "100%" }}
          variant="standard"
          label="Estado"
          name="Estado"
          value={employeeData.DomFiscal.Estado || ""}
          id="Estado"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          disabled
          sx={{ minWidth: "100%" }}
          variant="standard"
          label="Municipio"
          name="Municipio"
          value={employeeData.DomFiscal.Municipio || ""}
          id="Municipio"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          variant="standard"
          id="DomFiscal.Calle"
          name="Calle"
          label="Calle"
          fullWidth
          autoComplete="street-address"
          value={employeeData.DomFiscal.Calle || ""}
          onChange={handleLocations}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          variant="standard"
          id="DomFiscal.NumExt"
          name="NumExt"
          label="Número Exterior"
          fullWidth
          autoComplete="off"
          value={employeeData.DomFiscal.NumExt || ""}
          onChange={handleLocations}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          id="DomFiscal.NumInt"
          name="NumInt"
          label="Número Interior"
          fullWidth
          autoComplete="off"
          value={employeeData.DomFiscal.NumInt || employeeData.BancoClave}
          onChange={handleLocations}
        />
      </Grid>
    </>
  );
};

export default EmployeeAddress;