import React, { useState, useEffect } from "react";
//*MUI COMPONENTS
import {
  Typography,
  CircularProgress,
  Button,
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Box,
  Backdrop,
  Alert,
  AlertTitle,
  Snackbar,
} from "@mui/material";
import { getCustomers } from "../../services/modules/invoices/organization";
import { getOperators } from "../../services/modules/organization/operators";
import {
  getAllRemolques,
  getAllVehicles,
} from "../../services/modules/transports/vehicles";
import useAuth from "../../hooks/useAuth";
import {
  AlertDialog,
  InitialDialogState,
} from "../../components/userFeedback/AlertDialog";
import {
  createService,
  getLatestServiceFolio,
  updateService,
} from "../../services/modules/services/servicesGeneral";
import {
  ServiceItemGeneric,
  TransportItem,
  ManeuverItem,
  StayItem,
  BaseServiceStruct,
} from "./utils/ServiceItemsENUM";
import { getAllTransportProductServicesCodes } from "../../services/modules/invoices/catalogs";
import { useLocation, useNavigate, useParams } from "react-router";
import { StayItemForm } from "../../components/Forms/Services/StayItemForm";
import { ManeuverItemForm } from "../../components/Forms/Services/ManeuverItemForm";
import { GeneralServiceDataForm } from "../../components/Forms/Services/GeneralServiceDataForm";
import {
  isEmptyObject,
  notUndefined,
  validateManeuverItem,
  validateStayItem,
  validateTransportItem,
} from "./utils/ServiceValidationFunctions";
import { TransportItemForm } from "../../components/Forms/Services/TransportItemForm";

export function NewService() {
  const { userid } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(InitialDialogState);
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "",
    withAlert: false,
    severity: "success",
    action: null,
  });
  const [alertState, setAlertState] = useState({
    severity: "info",
    title: "",
    message: "",
    open: false,
  });
  const [withTransport, setWithTransport] = useState(
    window.localStorage.getItem("withTransport") !== null
      ? JSON.parse(window.localStorage.getItem("withTransport"))
      : false
  );
  const [withManeuver, setWithManeuver] = useState(
    window.localStorage.getItem("withManeuver") !== null
      ? JSON.parse(window.localStorage.getItem("withManeuver"))
      : false
  );
  const [withStay, setWithStay] = useState(
    window.localStorage.getItem("withStay") !== null
      ? JSON.parse(window.localStorage.getItem("withStay"))
      : false
  );
  const [options, setOptions] = useState({
    loadingCompanies: [],
  });
  const [formData, setFormData] = useState(
    window.localStorage.getItem("serviceFormData")
      ? JSON.parse(window.localStorage.getItem("serviceFormData"))
      : {
          TransportService: { ...ServiceItemGeneric, ...TransportItem },
          ManeuverServiceItem: { ...ServiceItemGeneric, ...ManeuverItem },
          StayServiceItem: { ...ServiceItemGeneric, ...StayItem },
        }
  );
  const [objectData, setObjectData] = useState(
    window.localStorage.getItem("serviceObject")
      ? JSON.parse(window.localStorage.getItem("serviceObject"))
      : { SubTotal: 0, Unidad: {}, Remolque: {}, Empleados: [], Code: {} }
  );
  const [edit, setEdit] = useState(!!id && location.pathname.includes("edit"));
  // Stepper management
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: "Nuevo Servicio",
      content: (
        <GeneralServiceDataForm
          data={formData}
          setData={setFormData}
          options={options}
          itemStates={{
            transport: {
              state: withTransport,
              set: setWithTransport,
            },
            maniobra: {
              state: withManeuver,
              set: setWithManeuver,
            },
            estadia: {
              state: withStay,
              set: setWithStay,
            },
          }}
        />
      ),
    },
    /*Conditionally add steps */
    withTransport
      ? {
          label: "Transporte",
          content: (
            <TransportItemForm
              userid={userid}
              options={options}
              setDialog={setDialog}
              dialog={dialog}
              data={formData}
              setData={setFormData}
              alert={alertState}
              setAlert={setAlertState}
              snack={snackBar}
              setSnack={setSnackBar}
            />
          ),
        }
      : null,
    withManeuver
      ? {
          label: "Maniobra",
          content: (
            <ManeuverItemForm
              data={formData}
              setData={setFormData}
              objectData={objectData}
              setObjectData={setObjectData}
              alert={setAlertState}
              setAlert={setAlertState}
            />
          ),
        }
      : null,
    withStay
      ? {
          label: "Estadía",
          content: <StayItemForm data={formData} setData={setFormData} />,
        }
      : null,
  ].filter((step) => step !== null);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  function removeEmptyKeys(obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object") {
        removeEmptyKeys(obj[key]);
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      } else if (
        obj[key] === "" ||
        obj[key] === null ||
        (typeof obj === "object" && Object.keys(obj).length === 0)
      ) {
        delete obj[key];
      }
    });
    return obj;
  }

  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  const clearData = () => {
    window.localStorage.removeItem("serviceFormData");
    window.localStorage.removeItem("serviceObject");
    window.localStorage.removeItem("withTransport");
    window.localStorage.removeItem("withManeuver");
    window.localStorage.removeItem("withStay");
  };

  const handleErrorUi = (err) => {
    if (err?.response && err.response.status === 400) {
      setLoading(false);
      setDialog({
        ...dialog,
        open: true,
        title: `Ocurrió un error al crear el servicio. ${err.response.data.message}`,
        content: (
          <>
            Por favor verifique los datos e inténtelo de nuevo.
            {err?.response?.data?.error?.Descriptions && (
              <ul>
                {err.response.data.error.Descriptions.map((error, index) => (
                  <li>
                    {error.Key}: {error.Reason}
                  </li>
                ))}
              </ul>
            )}
          </>
        ),
        actions: [
          {
            label: "Ok",
            execute: () => setDialog({ ...dialog, open: false }),
          },
        ],
      });
      return;
    }

    if (err?.response && err.response.status === 500) {
      setDialog({
        ...dialog,
        open: true,
        title: "Error del servidor",
        content: (
          <>
            Ocurrió un error en el servidor. Por favor intente de nuevo más
            tarde.
          </>
        ),
        actions: [
          {
            label: "Ok",
            execute: () => setDialog({ ...dialog, open: false }),
          },
        ],
      });
      return;
    }

    if (err?.response && err.response.status === 401) {
      setDialog({
        ...dialog,
        open: true,
        title: "Error de autenticación",
        content: <>Su sesión ha expirado. Por favor recarga para continuar.</>,
        actions: [
          {
            label: "Ok",
            execute: () => {
              setDialog({ ...dialog, open: false });
              navigate("/login");
            },
          },
        ],
      });
      return;
    }

    if (!err.response) {
      setDialog({
        ...dialog,
        open: true,
        title: "Error de conexión",
        content: (
          <>
            No se ha podido conectar con el servidor. Por favor intente de nuevo
            más tarde.
          </>
        ),
        actions: [
          {
            label: "Ok",
            execute: () => {
              setDialog({ ...dialog, open: false });
            },
          },
        ],
      });
      return;
    }

    setDialog({
      ...dialog,
      open: true,
      title: "Error desconocido",
      content: <>Ocurrió un error desconocido. Contacta a soporte .</>,
      actions: [
        {
          label: "Ok",
          execute: () => setDialog({ ...dialog, open: false }),
        },
      ],
    });
  };

  const handleCreateService = () => {
    let errorItems = [];

    let ServiceStruct = {
      ...BaseServiceStruct,
      Folio: `${formData.Folio}`,
    };

    if (notUndefined(formData.Customer) && !isEmptyObject(formData.Customer)) {
      ServiceStruct.Customer = {
        ...formData.Customer,
      };
    } else {
      errorItems.push("Cliente");
    }

    if (withTransport) {
      ServiceStruct = {
        ...ServiceStruct,
        TransportService: {
          ...formData.TransportService,
        },
      };

      let { valid, validStates } = validateTransportItem(
        formData.TransportService
      );
      if (!valid) {
        let transportError = Object.keys(validStates).reduce((acc, key) => {
          if (!validStates[key]) {
            acc[key] = validStates[key];
          }
          return acc;
        }, {});
        errorItems = errorItems.concat(Object.keys(transportError));
      }
    }

    if (withManeuver) {
      let { valid, validStates } = validateManeuverItem(
        formData.ManeuverServiceItem
      );
      if (valid) {
        ServiceStruct.Maniobra = formData.ManeuverServiceItem;
      } else {
        let maneuverError = Object.keys(validStates).reduce((acc, key) => {
          if (!validStates[key]) {
            acc[key] = validStates[key];
          }
          return acc;
        }, {});
        errorItems = errorItems.concat(Object.keys(maneuverError));
      }
    }

    if (withStay) {
      let { valid, validStates } = validateStayItem(formData.StayServiceItem);
      if (valid) {
        ServiceStruct.Estadia = formData.StayServiceItem;
      } else {
        let stayError = Object.keys(validStates).reduce((acc, key) => {
          if (!validStates[key]) {
            acc[key] = validStates[key];
          }
          return acc;
        }, {});
        errorItems = errorItems.concat(Object.keys(stayError));
      }
    }

    console.log(ServiceStruct);
    let serviceClean = removeEmptyKeys(ServiceStruct);
    console.log(serviceClean);

    if (errorItems.length === 0) {
      setLoading(true);
      createService(userid, userid, serviceClean)
        .then((res) => {
          if (res.status === 201) {
            setLoading(false);
            setDialog({
              ...dialog,
              open: true,
              title: "Servicio creado con éxito",
              content:
                "El servicio se ha creado y puede ser manejado en la sección correspondiente.",
              actions: [
                {
                  label: "Continuar",
                  execute: () => {
                    navigate(`/${userid.claims.rol}/serviceIndex`);
                    clearData();
                  },
                },
              ],
            });
          }
        })
        .catch((err) => {
          handleErrorUi(err);
        });
    } else {
      setDialog({
        ...dialog,
        open: true,
        title: "Información incompleta",
        content: (
          <>
            Falta información en los siguientes campos:{" "}
            <ul>
              {errorItems.map((error, index) => (
                <>
                  <li key={index}>{error}</li>
                </>
              ))}
            </ul>
          </>
        ),
        actions: [
          { label: "Ok", execute: () => setDialog({ ...dialog, open: false }) },
        ],
      });
    }
  };

  const handleUpdateService = () => {
    let errorItems = [];

    let ServiceStruct = {
      ...BaseServiceStruct,
      _id: formData._id,
      Folio: `${formData.Folio}`,
    };

    if (notUndefined(formData.Customer) && !isEmptyObject(formData.Customer)) {
      ServiceStruct.Customer = {
        ...formData.Customer,
      };
    } else {
      errorItems.push("Cliente");
    }

    if (withTransport) {
      ServiceStruct = {
        ...ServiceStruct,
        TransportService: {
          ...formData.TransportService,
        },
      };

      let { valid, validStates } = validateTransportItem(
        formData.TransportService
      );
      if (!valid) {
        let transportError = Object.keys(validStates).reduce((acc, key) => {
          if (!validStates[key]) {
            acc[key] = validStates[key];
          }
          return acc;
        }, {});
        errorItems = errorItems.concat(Object.keys(transportError));
      }
    }

    if (withManeuver) {
      let { valid, validStates } = validateManeuverItem(
        formData.ManeuverServiceItem
      );
      if (valid) {
        ServiceStruct.Maniobra = formData.ManeuverServiceItem;
      } else {
        let maneuverError = Object.keys(validStates).reduce((acc, key) => {
          if (!validStates[key]) {
            acc[key] = validStates[key];
          }
          return acc;
        }, {});
        errorItems = errorItems.concat(Object.keys(maneuverError));
      }
    }

    if (withStay) {
      let { valid, validStates } = validateStayItem(formData.StayServiceItem);
      if (valid) {
        ServiceStruct.Estadia = formData.StayServiceItem;
      } else {
        let stayError = Object.keys(validStates).reduce((acc, key) => {
          if (!validStates[key]) {
            acc[key] = validStates[key];
          }
          return acc;
        }, {});
        errorItems = errorItems.concat(Object.keys(stayError));
      }
    }

    let serviceClean = removeEmptyKeys(ServiceStruct);

    serviceClean = {
      ...formData,
      ...serviceClean,
    };

    if (errorItems.length === 0) {
      updateService(userid, userid, ServiceStruct._id, serviceClean)
        .then((result) => {
          if (res.status === 200) {
            setLoading(false);
            setDialog({
              ...dialog,
              open: true,
              title: "Servicio actualizado con éxito",
              content:
                "El servicio se ha actualizado, verifica los cambios en la administración de servicios.",
              actions: [
                {
                  label: "Continuar",
                  execute: () => {
                    navigate(`/${userid.claims.rol}/serviceIndex`);
                    clearData();
                  },
                },
              ],
            });
          }
        })
        .catch((err) => {
          handleErrorUi(err);
        });
    }
  };

  const handleCloseSnackBar = () => {
    setSnackBar({
      ...snackBar,
      open: false,
      message: "",
      action: null,
    });
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getCustomers(userid),
      getOperators(userid),
      getAllVehicles(userid),
      getAllRemolques(userid),
      getAllTransportProductServicesCodes(userid, userid),
      getLatestServiceFolio(userid, userid),
    ])
      .then((results) => {
        results.forEach((result, index) => {
          const { data } = result;
          switch (index) {
            case 0:
              setOptions((obj) => ({ ...obj, clients: data.data }));
              break;
            case 1:
              setOptions((obj) => ({ ...obj, operators: data.data }));
              break;
            case 2:
              setOptions((obj) => ({ ...obj, vehicles: data }));
              break;
            case 3:
              setOptions((obj) => ({ ...obj, remolques: data }));
              break;
            case 4:
              setOptions((obj) => ({ ...obj, productCodes: data }));
              break;
            case 5:
              setFormData((obj) => ({ ...obj, Folio: data }));
            default:
              break;
          }
          setLoading(false);
          if (data.length === 0) {
            setDialog({
              title: "Error",
              content:
                "No se ha obtenido la información necesaria para los formularios",
              open: true,
              actions: [
                { label: "Recargar", execute: () => window.location.reload() },
                {
                  label: "Salir",
                  execute: () => navigate(`/${userid.claims.rol}/serviceIndex`),
                },
              ],
            });
          }
        });
      })
      .catch((errors) => {
        const errorMessages = [];
        console.log(errors);
        setDialog({
          title: "Error",
          content: `No se ha obtenido la información necesaria para los formularios ${errorMessages}`,
          open: true,
          actions: [
            { label: "Recargar", execute: () => window.location.reload() },
            {
              label: "Salir",
              execute: () => navigate(`/${userid.claims.rol}/welcome`),
            },
          ],
        });
      });
  }, []);

  useEffect(() => {
    window.localStorage.setItem("serviceFormData", JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    window.localStorage.setItem("serviceObject", JSON.stringify(objectData));
  }, [objectData]);

  useEffect(() => {
    window.localStorage.setItem("withTransport", withTransport);
    window.localStorage.setItem("withManeuver", withManeuver);
    window.localStorage.setItem("withStay", withStay);
  }, [withTransport, withManeuver, withStay]);

  //autoclose feedback alert
  useEffect(() => {
    if (alertState.open) {
      const timer = setTimeout(() => {
        setAlertState({
          severity: "info",
          title: "",
          message: "",
          open: false,
        });
      }, 5000); // Set the duration in milliseconds (e.g., 5000ms = 5 seconds)

      return () => clearTimeout(timer);
    }
  }, [alertState.open]);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <AlertDialog {...dialog} />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        {snackBar.withAlert ? (
          <Alert onClose={handleCloseSnackBar} severity={snackBar.severity}>
            {snackBar.message}
          </Alert>
        ) : (
          snackBar.message
        )}
      </Snackbar>
      <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography component="h1" variant="h4" align="center">
            Generar Servicio
          </Typography>
          <Stepper activeStep={activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {/* Content for the current step */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "2vh",
                marginTop: "3vh",
              }}
            >
              {steps[activeStep].content}
            </Box>

            {alertState.open && (
              <Alert
                severity={alertState.severity}
                onClose={() => setAlertState({ ...alertState, open: false })}
              >
                <AlertTitle>{alertState.title}</AlertTitle>
                {alertState.message}
              </Alert>
            )}

            {/* Stepper Buttons */}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={handleBack} disabled={activeStep === 0}>
                Anterior
              </Button>

              <Button
                onClick={
                  activeStep === steps.length - 1
                    ? edit
                      ? handleUpdateService
                      : handleCreateService
                    : handleNext
                }
              >
                {activeStep === steps.length - 1
                  ? `${edit ? "Actualizar" : "Crear"} servicio`
                  : "Siguiente"}
              </Button>
            </Box>
          </div>
        </Paper>
      </Container>
    </div>
  );
}
