import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Snackbar,
  Typography,
  Button,
  Grid,
  Paper,
  Box,
  FormControlLabel,
  Checkbox,
  Switch,
  CircularProgress,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useAuth from "../../../hooks/useAuth";
import { NominaDateWithType } from "./NominaDateTypeComponent";
import {
  DeductionsTable,
  OtherPaymentsTable,
  PerceptionsTable,
} from "./NominaTables";
import { PreviewCFDI } from "../../Previews/PreviewTables";
import {
  getInvoiceFiles,
  issueInvoice,
} from "../../../services/modules/invoices/invoices";
import { daysToMonday } from "../../../utils/dates";
import NominaIssuerForm from "./NominaIssuerForm";
import NominaGeneralCfdiForm from "./NominaGeneralCfdiForm";
import NominaEmployeeReceiverForm from "./NominaEmployeeReceiverForm";
import CFDI_USES from "../../../utils/enum.js/CfdiUses";
import invoiceReceiverDto from "../../../utils/dto/invoice-receiver.dto.json";
import NominaPaymentMethod from "../../../utils/enum.js/NominaPaymentMethod.enum";
import CFDI_TYPE from "../../../utils/enum.js/CfdiType";
import issuerDto from "../../../utils/dto/issuer.dto.json";
import PayrollInvoiceDto from "../../../utils/dto/payrollInvoice.dto";
import { toAntiguedadFormat } from "../../../modules/invoices/utils/toAntiguedadFormat";
import PostIssuingActions from "../../actions/PostIssuing";
import { getTypeOfInvoice } from "../../Previews/Getters";
import { FolioSerieForm } from "../Invoices/FolioSerieForm";

export const NominaSingleEmployee = () => {
  const invoiceStorage = JSON.parse(localStorage.getItem("invoiceModel"));

  const receiver = invoiceReceiverDto;

  console.log(invoiceStorage);
  if (invoiceStorage) {
    receiver.Rfc = invoiceStorage.Complementos?.Payroll?.Employee?.Rfc || "";
    receiver.TaxName =
      `${invoiceStorage.Complementos?.Payroll?.Employee?.Nombre} ${invoiceStorage.Complementos?.Payroll?.Employee?.ApellidoP} ${invoiceStorage.Complementos?.Payroll?.Employee?.ApellidoM}` ||
      "";
    receiver.CfdiUse = CFDI_USES.NOMINA;
    receiver.FiscalRegime =
      invoiceStorage.Complementos?.Payroll?.Employee?.FiscalRegime || "";
    receiver.Address =
      invoiceStorage.Complementos?.Payroll?.Employee?.Address || "";
  }

  const defaultDaysPaid = 15;

  const defaultDeductions = [
    {
      DeduccionType: "001",
      Code: "IMSS",
      Description: "Seguridad Social",
      Amount: 0,
    },
    {
      DeduccionType: "002",
      Code: "ISR",
      Description: "Impuesto Sobre la Renta",
      Amount: 0,
    },
    {
      DeduccionType: "003",
      Code: "RETIRO",
      Description: "Aportacion a retiro",
      Amount: 0,
    },
  ];

  const defaultPercpetions = [
    {
      PerceptionType: "001",
      Code: "001",
      Description: "Sueldos, Salarios  Rayas y Jornales",
      TaxedAmount: 0,
      ExemptAmount: 0,
    },
  ];

  const defaultOtherPayments = [
    {
      OtherPaymentType: "002",
      Code: "002",
      Description: "Subsidio para el empleo",
      Amount: 0,
      EmploymentSubsidy: { Amount: 0 },
    },
  ];

  //*States
  const [CommonDeductions, setCommonDeductions] = useState(
    dedPercpMap(
      "Deduction",
      invoiceStorage?.Complementos?.Payroll?.Deductions
    ) || defaultDeductions
  );

  const [CommonPerceptions, setCommonPerceptions] = useState(
    dedPercpMap(
      "Perception",
      invoiceStorage?.Complementos?.Payroll?.Perceptions
    ) || defaultPercpetions
  );

  const [commonPayments, setCommonPayments] = useState(defaultOtherPayments);

  const [fechas, setFechas] = useState({
    PaymentDate: new Date(),
    InitialPaymentDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - daysToMonday()
    ),
    FinalPaymentDate: new Date(
      initialDate().getFullYear(),
      initialDate().getMonth(),
      initialDate().getDate() + (defaultDaysPaid - 1)
    ),
    DaysPaid: defaultDaysPaid,
  });

  const [hasOtherPayments, setHasOtherPayments] = useState(false);

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    status: "",
  });

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  const [cfdiOptions, setCfdiOptions] = useState({
    names: [],
    uses: [],
    type: "",
    place: [],
    paymentMethod: [],
  });

  const [datosCfdi, setDatosCfdi] = useState({
    NameId: "16",
    ExpeditionPlace: invoiceStorage?.GeneralModel?.ExpeditionPlace || "",
    CfdiType: CFDI_TYPE.NOMINA,
    PaymentMethod: NominaPaymentMethod.DEFAULT_PAYMENT_METHOD,
    Folio: invoiceStorage?.GeneralModel?.Folio || "",
    issuer: invoiceStorage?.GeneralModel?.Issuer || issuerDto,
    Serie: invoiceStorage?.GeneralModel?.Serie || "",
    SerieFolio: invoiceStorage?.GeneralModel?.SerieFolio || "",
    receiver: receiver || invoiceReceiverDto,
    EmployerRegistration:
      invoiceStorage?.GeneralModel?.Issuer?.EmployerRegistration || "",
  });

  const [payrollGeneral, setPayrollGeneral] = useState({
    Type: invoiceStorage?.Complementos?.Payroll?.Type || "O",
    PaymentDate:
      invoiceStorage?.Complementos?.Payroll?.PaymentDate || fechas.PaymentDate,
    InitialPaymentDate:
      invoiceStorage?.Complementos?.Payroll?.InitialPaymentDate ||
      fechas.InitialPaymentDate,
    FinalPaymentDate:
      invoiceStorage?.Complementos?.Payroll?.FinalPaymentDate ||
      fechas.FinalPaymentDate,
    DaysPaid:
      invoiceStorage?.Complementos?.Payroll?.DaysPaid || defaultDaysPaid,
    DailySalary:
      invoiceStorage?.Complementos?.Payroll?.Employee?.SueldoDiario || 0,
    BaseSalary:
      invoiceStorage?.Complementos?.Payroll?.Employee?.SueldoBase || 0,
    Employee: invoiceStorage?.Complementos?.Payroll?.Employee || "",
    Perceptions: invoiceStorage?.Complementos?.Payroll?.Perceptions || [],
    Deductions: invoiceStorage?.Complementos?.Payroll?.Deductions || [],
    OtherPayments: invoiceStorage?.Complementos?.Payroll?.OtherPayments || [],
  });

  const [selected, setSelected] = useState({
    perception: "",
    deduction: {
      DeduccionType: "",
      Code: "",
      Description: "",
      Amount: "",
    },
  });

  const [errorMap, setErrorMap] = useState({
    GeneralModel: {
      CfdiType: false,
      PaymentMethod: false,
      ExpeditionPlace: false,
      Date: false,
      Folio: false,
      Issuer: false,
      Receiver: false,
    },
    Complementos: {
      Payroll: {
        Employee: false,
        Perceptions: false,
        Deductions: false,
        OtherPayments: false,
      },
    },
  });

  const [loading, setLoading] = useState(false);

  //*Contexts
  const { userid, organization } = useAuth();
  const navigate = useNavigate();
  const [nominaType, setNominaType] = useState(
    payrollGeneral.Type === "O" ? "Ordinaria" : "Extraordinaria"
  );

  function dedPercpMap(type, array) {
    let mappedPerceptions = [];
    let mappedDeductions = [];
    let mappedOtherPayments = [];

    if (!array || !Array.isArray(array) || array.length === 0) {
      return null;
    }

    if (type === "Perception") {
      array.map((DBperception) => {
        mappedPerceptions.push({
          PerceptionType: DBperception.Value,
          Code: DBperception.Value,
          Description: DBperception.Name,
          TaxedAmount: DBperception.TaxedTotal,
          ExemptAmount: DBperception.ExemptTotal,
        });
      });

      return mappedPerceptions;
    }

    if (type === "Deduction") {
      array.map((DBdeduction) => {
        mappedDeductions.push({
          DeduccionType: DBdeduction.Value,
          Code: DBdeduction.Value,
          Description: DBdeduction.Name,
          Amount: DBdeduction.Amount,
        });
      });

      return mappedDeductions;
    }
  }

  function initialDate() {
    return new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - daysToMonday()
    );
  }

  const handleWithOtherPayments = () => {
    setHasOtherPayments(!hasOtherPayments);
    setPayrollGeneral((prevPayrollGeneral) => {
      return {
        ...prevPayrollGeneral,
        OtherPayments: hasOtherPayments ? [] : commonPayments,
      };
    });
  };

  let total = getTotal();

  function getTotal() {
    let total = 0;
    for (const array in CommonPerceptions) {
      total =
        total +
        Number(CommonPerceptions[array].TaxedAmount) +
        Number(CommonPerceptions[array].ExemptAmount);
    }
    for (const array in CommonDeductions) {
      total = total - Number(CommonDeductions[array].Amount);
    }
    if (hasOtherPayments) {
      for (const array in commonPayments) {
        total = total + Number(commonPayments[array].Amount);
      }
    }

    return total;
  }

  const resetState = (key) => {
    setSelected((selected) => {
      return {
        ...selected,
        [key]: {
          Rfc: "",
          Name: "",
          FiscalRegime: "",
          EmployerRegistration: "",
          Curp: "",
        },
      };
    });
  };

  const resetDialog = () => {
    setDialog({
      ...dialog,
      open: false,
      title: "",
      content: "",
      actions: [],
    });
  };

  //*Set issuer inputs
  const handleSetIssuer = (e, v, r) => {
    if (r === "clear") {
      resetState("issuer");
    }

    if (r === "selectOption") {
      setDatosCfdi((previousData) => {
        return {
          ...previousData,
          issuer: {
            ...v,
          },
        };
      });
    }

    if (r === "input") {
      setDatosCfdi((previousData) => {
        return {
          ...previousData,
          [e.target.id]: v,
        };
      });
    }
  };

  //*Set CFDI inputs
  const handleChangeDatosCfdi = (e, v, r) => {
    const id = e.target.id.split("-")[0];
    setDatosCfdi({
      ...datosCfdi,
      [id]: v,
    });
  };

  //*Set receiver inputs (EMPLOYEE)
  const handleSelectEmployee = (e, v, r) => {
    const id = e.target.id.split("-")[0];

    if (r === "selectOption" && id === "Employee") {
      if (nominaType === "Ordinaria") {
        const deductions = dedPercpMap("Deduction", v.Deductions);
        const perceptions = dedPercpMap("Perception", v.Perceptions);
        const otherPayments = v?.OtherPayments;
        if (v.Deductions) {
          setCommonDeductions(deductions);
        }
        if (v.Perceptions) {
          setCommonPerceptions(perceptions);
        }
        setPayrollGeneral((prevPayrollGeneral) => {
          return {
            ...prevPayrollGeneral,
            Employee: {
              ...v,
              Antiguedad: toAntiguedadFormat(new Date(v.FechaInicio)),
            },
            Perceptions: perceptions || [],
            Deductions: deductions || [],
          };
        });
      }

      if (nominaType === "Extraordinaria") {
        const deductions = dedPercpMap("Deduction", v.Deductions);
        const perceptions = dedPercpMap("Perception", [
          {
            Value: "002",
            Name: "Gratificación Anual (Aguinaldo)",
            TaxedTotal: 0,
            ExemptTotal: 0,
          },
        ]);
        if (v.Deductions && Array.isArray(v.Deductions)) {
          setCommonDeductions(deductions);
        }

        if (v.Perceptions && Array.isArray(v.Perceptions)) {
          setCommonPerceptions(perceptions);
        }

        setPayrollGeneral((prevPayrollGeneral) => {
          return {
            ...prevPayrollGeneral,
            Employee: {
              ...v,
              Antiguedad: toAntiguedadFormat(new Date(v.FechaInicio)),
            },
            Perceptions: perceptions,
            Deductions: deductions,
          };
        });
      }

      return;
    }

    if (r === "selectOption") {
      setPayrollGeneral((prevPayrollGeneral) => {
        return {
          ...prevPayrollGeneral,
          Employee: {
            ...prevPayrollGeneral.Employee,
            [id]: v,
          },
        };
      });
    }
  };

  //*Nomina type
  const handleNominaTypes = (e) => {
    const value = e.target.value;

    setNominaType(value);

    if (value === "Ordinaria") {
      setPayrollGeneral((prevPayrollGeneral) => {
        return {
          ...prevPayrollGeneral,
          Type: "O",
        };
      });

      if (
        payrollGeneral.Employee !== "" &&
        payrollGeneral.Employee !== undefined
      ) {
        setCommonDeductions(
          dedPercpMap("Deduction", payrollGeneral.Employee.Deductions)
        );
        setCommonPerceptions(
          dedPercpMap("Perception", payrollGeneral.Employee.Perceptions)
        );
      }

      if (
        payrollGeneral.Employee === "" ||
        payrollGeneral.Employee === undefined
      ) {
        setCommonDeductions(defaultDeductions);
        setCommonPerceptions(defaultPercpetions);
      }
    }

    if (value === "Extraordinaria") {
      setPayrollGeneral((prevPayrollGeneral) => {
        return {
          ...prevPayrollGeneral,
          Type: "E",
        };
      });
      setCommonPerceptions([
        {
          PerceptionType: "002",
          Code: "002",
          Description: "Gratificación Anual (Aguinaldo)",
          TaxedAmount: 0,
          ExemptAmount: 0,
        },
      ]);
    }
  };

  const handleCloseAlert = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({
      ...alert,
      open: false,
      message: "",
      status: "",
    });
  };

  const handleCloseDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  const handleFechas = (newDates) => {
    setFechas(newDates);
    setPayrollGeneral((prevPayrollGeneral) => {
      const copyPayrollGeneral = { ...prevPayrollGeneral };

      copyPayrollGeneral.InitialPaymentDate = newDates.InitialPaymentDate;
      copyPayrollGeneral.FinalPaymentDate = newDates.FinalPaymentDate;
      copyPayrollGeneral.DaysPaid = newDates.DaysPaid;
      copyPayrollGeneral.PaymentDate = newDates.PaymentDate;
      copyPayrollGeneral.Employee = {
        ...copyPayrollGeneral.Employee,
        Antiguedad: toAntiguedadFormat(
          new Date(copyPayrollGeneral.Employee.FechaInicio)
        ),
      };
      return copyPayrollGeneral;
    });
  };

  const handleUserInpuErrorsUi = (err) => {
    if (
      err.response.status &&
      err?.response?.data?.error &&
      err?.response?.data?.error?.Descriptions
    ) {
      const mappedErrors = err.response.data.error.Descriptions.map((error) => {
        return `Valor: ${error.Parent} ${error.Reason}`;
      });

      return mappedErrors;
    }
  };

  const handleErrorResponse = (err) => {
    if (err.response) {
      if (err.response.status === 400) {
        const { data } = err.response;
        if (data && data.error && data.error.Descriptions) {
          const mapErrors = data.error.Descriptions.map((validationError) => {
            return validationError.Reason;
          });
          return `Verifica los datos: ${mapErrors.join(", ")}`;
        }

        return data.message;
      }

      if (err.response.status === 401) {
        return "No tienes permisos para realizar esta acción";
      }

      if (err.response.status === 404) {
        return "No se encontró el recurso";
      }

      if (err.response.status === 500) {
        return "Ocurrió un error en el servidor";
      }

      if (err.response.status === 403) {
        return "No tienes permisos para realizar esta acción";
      }

      return "Ocurrió un error al emitir la factura";
    }

    return "Ocurrió un error de conexión";
  };

  const handleTimbrado = (cfdi) => {
    let msgObj;
    setLoading(true);
    issueInvoice(userid, userid, cfdi, true, "nomina")
      .then((res) => {
        setLoading(false);
        if (res.status === 201) {
          msgObj = {
            message: (
              <>
                <Typography>La factura se ha emitido correctamente</Typography>
                <PostIssuingActions
                  cfdi={{ ...cfdi, ...res.data }}
                  type={getTypeOfInvoice(cfdi)}
                />
              </>
            ),
            actions: [
              {
                label: "Continuar",
                execute: () => {
                  window.localStorage.removeItem("invoiceModel");
                  navigate(`/${userid.claims.rol}/consultInvoices`);
                },
              },
            ],
          };
        }

        setDialog({
          ...dialog,
          title: "",
          content: msgObj.message,
          open: true,
          actions: msgObj.actions,
        });
      })
      .catch((err) => {
        setLoading(false);
        setDialog({
          ...dialog,
          open: true,
          title: "Error",
          content: `
        ${handleErrorResponse(err)}
        `,
          actions: [
            {
              label: "Reintentar",
              execute: () => handleTimbrado(cfdi),
            },
            {
              label: "Cerrar",
              execute: resetDialog,
            },
          ],
        });
      });
  };

  const handlePreview = () => {
    let incomplete = {
      perceptions: false,
      deductions: false,
    };
    if (CommonPerceptions.length === 0) {
      incomplete.perceptions = true;
    }
    if (CommonDeductions.length === 0) {
      incomplete.deductions = true;
    }

    if (CommonPerceptions.length === 0 || CommonDeductions.length === 0) {
      setDialog({
        open: incomplete.perceptions || incomplete.deductions,
        title: "Información requerida",
        content: (
          <>
            {incomplete.perceptions ? (
              <Typography>-Se requiere al menos una percepción</Typography>
            ) : null}

            {incomplete.deductions ? (
              <Typography>-Se requiere al menos una deducción</Typography>
            ) : null}
          </>
        ),
        actions: [
          {
            label: "Cerrar y Corregir",
            execute: resetDialog,
          },
        ],
      });
      return;
    }

    const NominaInvoice = PayrollInvoiceDto;
    NominaInvoice.GeneralModel = invoiceStorage?.GeneralModel;
    NominaInvoice.Complementos.Payroll = invoiceStorage?.Complementos?.Payroll;

    setDialog({
      open: !(incomplete.perceptions || incomplete.deductions),
      title: "Previsualizacion de Nomina",
      content: (
        <>
          <PreviewCFDI CFDI={invoiceStorage} />
        </>
      ),
      actions: [
        {
          label: "Cerrar y Corregir",
          execute: resetDialog,
        },
        {
          label: "Aceptar y Timbrar",
          execute: () => {
            resetDialog();
            handleTimbrado(invoiceStorage);
          },
        },
      ],
    });
  };

  if (!invoiceStorage) {
    const NominaInvoiceStructured = {
      GeneralModel: {
        CfdiType: datosCfdi.CfdiType,
        PaymentMethod: datosCfdi.PaymentMethod,
        ExpeditionPlace: datosCfdi.ExpeditionPlace,
        Folio: datosCfdi.Folio,
        Serie: datosCfdi.Serie,
        SerieFolio: datosCfdi.SerieFolio,
        Issuer: datosCfdi.issuer,
        Receiver: datosCfdi.receiver,
      },
      Complementos: {
        Payroll: payrollGeneral,
      },
    };
    window.localStorage.setItem(
      "invoiceModel",
      JSON.stringify(NominaInvoiceStructured)
    );
  } else {
    const NominaInvoiceStructured = invoiceStorage;
    NominaInvoiceStructured.GeneralModel.CfdiType = datosCfdi.CfdiType;
    NominaInvoiceStructured.GeneralModel.PaymentMethod =
      datosCfdi.PaymentMethod;
    NominaInvoiceStructured.GeneralModel.ExpeditionPlace =
      datosCfdi.ExpeditionPlace;
    NominaInvoiceStructured.GeneralModel.Folio = datosCfdi.Folio;
    NominaInvoiceStructured.GeneralModel.Serie = datosCfdi.Serie;
    NominaInvoiceStructured.GeneralModel.SerieFolio = datosCfdi.SerieFolio;
    NominaInvoiceStructured.GeneralModel.Issuer = datosCfdi.issuer;
    NominaInvoiceStructured.GeneralModel.Issuer = {
      ...NominaInvoiceStructured.GeneralModel.Issuer,
      EmployerRegistration: datosCfdi.EmployerRegistration,
    };
    NominaInvoiceStructured.GeneralModel.Receiver = datosCfdi.receiver;
    NominaInvoiceStructured.Complementos.Payroll = {
      ...payrollGeneral,
      Type: payrollGeneral.Type,
      Perceptions: CommonPerceptions,
      Deductions: CommonDeductions,
      OtherPayments: commonPayments,
    };

    window.localStorage.setItem(
      "invoiceModel",
      JSON.stringify(NominaInvoiceStructured)
    );
  }

  useEffect(() => {
    return () => {
      window.localStorage.removeItem("invoiceModel");
    };
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Dialog
        open={dialog.open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.actions.map((action, index) => {
            return (
              <Button key={index} variant="contained" onClick={action.execute}>
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "6vh",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid
            container
            maxWidth={"xl"}
            spacing={{ xs: 3, sm: 5, md: 3 }}
            sx={{ padding: 2 }}
          >
            <Grid
              item
              xs={12}
              md={9}
              textAlign="left"
              sx={{ alignItems: "baseline" }}
            >
              <Typography variant="h4" color="primary">
                Factura Recibo de Nómina
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} sx={{ alignItems: "center" }}>
              <FormControl>
                <FormLabel id="nomina-type-form-control">
                  Tipo de nómina
                </FormLabel>
                <RadioGroup
                  aria-labelledby="nomina-type-form-control"
                  name="radio-buttons-group"
                  row
                  value={nominaType}
                  onChange={handleNominaTypes}
                >
                  <FormControlLabel
                    value="Ordinaria"
                    control={<Radio />}
                    label="Ordinaria"
                  />
                  <FormControlLabel
                    value="Extraordinaria"
                    control={<Radio />}
                    label="Extraordinaria"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} sx={{ height: "100%" }}>
              <Grid
                container
                spacing={1}
                alignItems={"stretch"}
                component={Paper}
                sx={{ padding: 2, height: "100%" }}
              >
                <Grid item xs={12}>
                  <Divider
                    variant="fullWidth"
                    flexItem
                    alignItems="left"
                    textAlign="left"
                  >
                    <Typography
                      variant="h6"
                      sx={{ marginBottom: 1 }}
                      color="secondary.dark"
                    >
                      Emisor
                    </Typography>
                  </Divider>
                </Grid>
                <Grid item xs={12} md={12}>
                  {/* //*TODO: Abstract this to receive function to change value
                  instead of the state directly */}
                  <NominaIssuerForm
                    selected={datosCfdi}
                    setSelected={setDatosCfdi}
                    onIssuerChange={handleSetIssuer}
                    setAlert={setAlert}
                    organization={organization}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* INICIA DATOS DEL COMPROBANTE */}
            <Grid item xs={12} md={6}>
              <Grid container spacing={1} component={Paper} sx={{ padding: 2 }}>
                <Grid item xs={12}>
                  <Divider
                    variant="fullWidth"
                    flexItem
                    alignItems="left"
                    textAlign="left"
                  >
                    <Typography
                      variant="h6"
                      color="secondary.dark"
                      sx={{ marginBottom: 1 }}
                    >
                      Datos del comprobante
                    </Typography>
                  </Divider>
                </Grid>
                <NominaGeneralCfdiForm
                  selected={datosCfdi}
                  onGeneralCfdiChange={handleChangeDatosCfdi}
                />
              </Grid>
            </Grid>

            {/* TERMINA DATOS DEL COMPROBANTE */}

            {/* INICIA EMPLEADO RECEPTOR */}
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={1}
                component={Paper}
                sx={{ padding: 2, height: "100%" }}
              >
                <Grid item xs={12}>
                  <Divider
                    variant="fullWidth"
                    flexItem
                    alignItems="left"
                    textAlign="left"
                  >
                    <Typography
                      variant="h6"
                      sx={{ marginBottom: 1 }}
                      color="secondary.dark"
                    >
                      Empleado Receptor
                    </Typography>
                  </Divider>
                </Grid>

                <NominaEmployeeReceiverForm
                  employee={payrollGeneral.Employee}
                  onEmployeeChange={handleSelectEmployee}
                  alert={alert}
                  setAlert={setAlert}
                />
              </Grid>
            </Grid>
            {/* TERMINA EMPLEADO RECEPTOR */}

            {/* INICIA DATES */}
            <Grid item xs={12} md={6}>
              <NominaDateWithType
                defaultDays={defaultDaysPaid}
                fechasState={fechas}
                setFechas={handleFechas}
              />
            </Grid>

            {/* TERMINA DATES */}

            {/* INICIA PERCEPTIONS */}
            <Grid item xs={12} md={6}>
              <PerceptionsTable
                key={CommonPerceptions[CommonPerceptions.length - 1]}
                perceptionsArray={CommonPerceptions}
                setPerceptionArray={setCommonPerceptions}
              />
            </Grid>

            {/* TERMINA PERCEPTIONS */}

            {/* INICIA DEDUCTIONS */}
            <Grid item xs={12} md={6}>
              <DeductionsTable
                deductionsArray={CommonDeductions}
                setDeductionsArray={setCommonDeductions}
              />
            </Grid>

            {/* TERMINA DEDUCTIONS */}

            {/* INICIA OTROS PAGOS */}

            <Grid item xs={12} md={6} order={{ xs: 7, md: 6 }}>
              {!!hasOtherPayments && (
                <OtherPaymentsTable
                  otherPaymentsArray={commonPayments}
                  setOtherPaymentssArray={setCommonPayments}
                />
              )}
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              order={{ xs: 6, md: 7 }}
              sx={{ marginBottom: "4vh" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name={"otrosPagos"}
                    checked={hasOtherPayments}
                    onChange={handleWithOtherPayments}
                  />
                }
                label="Añadir Otros Pagos"
                labelPlacement="end"
              />
            </Grid>

            {/* TERMINA OTROS PAGOS */}

            {/* FINALIZAR */}

            <Grid item xs={6} md={8} order={8}></Grid>
            <Grid item xs={6} md={4} order={{ xs: 9 }}>
              <Typography color="primary.main">
                Total Percibido: ${total}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePreview}
              >
                Aceptar y previsualizar
              </Button>
            </Grid>
          </Grid>
        )}
        <Snackbar
          open={alert.open}
          onClose={handleCloseAlert}
          autoHideDuration={3000}
        >
          <Alert severity={alert.open ? alert.status : "info"} variant="filled">
            {alert.message}
          </Alert>
        </Snackbar>

        {/* INICIA GRID DE INPUTS */}
      </Box>
    </div>
  );
};
