const daysToMonday = () => {
  const monday = 1;

  const today = new Date();

  const todayInNumber = today.getDay();

  return todayInNumber - monday;
};

const initialDate = () => {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() - daysToMonday()
  );
};

export { initialDate, daysToMonday };
