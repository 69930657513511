const cfdiPaymentsDto = {
  fechaPago: new Date(),
  formaPago: "",
  tipoCambio: "",
  currency: "MXN",
  total: "",
  idOperación: "",
  rfcEmisorOrdenPago: "",
  nombrInstitucionOrdenPago: "",
  ctaOrdenPago: "",
  rfcCtaBeneficiario: "",
  taxes: [],
  relatedDocuments: [],
};

export { cfdiPaymentsDto };
