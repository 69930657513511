import React, {useEffect, useState} from "react";
import {DataGrid} from "@mui/x-data-grid";
import {Box} from "@mui/system";
import {useNavigate} from "react-router";
import useAuth from "../../hooks/useAuth";
import {Alert, Button, Grid, Snackbar, Typography} from "@mui/material";
import ferbyTheme from "../../theme/ferbyTheme";
import {
  filterCustomers,
  getCustomers,
} from "../../services/modules/invoices/organization";
import EditComponent from "../../components/actions/EditComponent";
import {deleteCustomer} from "../../services/modules/organization/customers";
import {
  AlertDialog,
  InitialDialogState,
} from "../../components/userFeedback/AlertDialog";
import {ConsultSearchComponent} from "../../components/actions/ConsultSearchComponent";
import {getErrorMessageFromStatus} from "../../utils/httpResponses";

const CustomersIndex = () => {
  //* React router hook to navigate between routes
  const navigate = useNavigate();

  //* Custom hook to get the logged user data from the context
  const {userid} = useAuth();

  //* State used to define in a granular way the disabled state of the child components
  const [disabled, setDisabled] = useState({searchBar: false});

  //* State to handle the loading state of the component
  const [loading, setLoading] = useState(false);

  //* State to store the data of the customers table
  const [rows, setRows] = useState([]);

  //* State to handle the dialog component
  const [dialog, setDialog] = useState({
    ...InitialDialogState,
    updateOpenState: () => setDialog({...dialog, open: false}),
  });

  //* State to handle the alert component
  const [alert, setAlert] = useState({
    open: false,
    duration: 0,
    title: "",
    message: "",
    severity: "success",
  });

  //* Constant that defines the columns of the customers table
  const columns = [
    {
      field: "TaxName",
      headerName: "Nombre",
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "Rfc",
      headerName: "RFC",
      flex: 1,
      headerClassName: "header-table",
    },
    {
      field: "Address",
      headerName: "Código postal",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return params.row.Address?.ZipCode || "-";
      },
    },
    {
      field: "Actions",
      headerName: "Opciones",
      flex: 1,
      headerClassName: "header-table",
      renderCell: (params) => {
        return (
          <EditComponent
            loading={loading}
            editionRoute={`/${userid.claims.rol}/customers/update`}
            objName={"Cliente"}
            onDelete={() => handleRemoveCustomer(params.row._id)}
            rowParams={params.row}
          />
        );
      },
    },
  ];

  //* Function to navigate to the create customer route
  const goToCreate = () => {
    navigate(`/${userid.claims.rol}/customers/new`);
  };

  //* Function to handle the delete customer action
  const handleRemoveCustomer = (id) => {
    setLoading(true);

    //*TODO: REASON BY DELETE IS NO LONGER NEEDED, REMOVE IT FROM THE API
    deleteCustomer(userid, userid, id, {reasonByDelete: "default"})
      .then((response) => {
        setLoading(false);
        setAlert({
          open: true,
          duration: 6000,
          title: "Cliente eliminado correctamente",
          message: "Cliente eliminado",
          severity: "success",
        });
        //*get allCustomers after delete customer
        getData();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setAlert({
          open: true,
          duration: 6000,
          title: "Error al eliminar el cliente",
          message: "Error al eliminar el cliente",
          severity: "error",
        });
      });
  };

  //* Function that is called when the search bar is cleared to get all the customers
  const clearSearch = () => {
    setDisabled({searchBar: true});
    getCustomers(userid, userid.token)
      .then((res) => {
        let customersData = res.data.data;
        let rowsToSet = getRowsFromResponse(customersData);
        setRows((ir) => rowsToSet);
        setDisabled({searchBar: false});
      })
      .catch((err) => {
        console.log(err);
        setAlert({
          open: true,
          duration: 6000,
          title: "Error al buscar los clientes",
          message: "Fallo al buscar los clientes",
          severity: "error",
        });
      });
  };

  //* Function to handle the search customer this is passed to the CounsultSearchComponent as prop
  //*? Should we change this behavior to be more generic? The consultActionsComponent is not very useful. We may create a consult abstraction that holds the search, grid, actions, etc. and be able to reuse it in other modules
  const handleSearchCustomer = (term) => {
    if (term !== "") {
      const customerBarFilter = {
        label: "query",
        param: "q",
        value: term,
      };
      setLoading(true);
      filterCustomers(userid, [customerBarFilter])
        .then((res) => {
          setLoading(false);
          let customersData = res.data.data;
          if (customersData.length > 0) {
            let rowsToSet = getRowsFromResponse(customersData);
            setRows((ir) => rowsToSet);
          } else {
            setAlert({
              open: true,
              duration: 6000,
              title: "Sin resultados",
              message:
                "No se encontraron clientes que coincidan con la búsqueda",
              severity: "warning",
            });
            setRows([]);
          }
        })
        .catch((err) => {
          setLoading(false);
          setRows([]);
          getResponseFromError(err);
        });
    }
  };

  //* Function to get the rows from the response of the API
  const getRowsFromResponse = (customersData) => {
    let rowsToSet = [];
    customersData.forEach((element) => {
      //console.log("elementor", element);
      rowsToSet.push({
        id: element._id,
        _id: element._id,
        TaxName: element.TaxName,
        Rfc: element.Rfc,
        Ubicaciones: element.Addresses,
        subEmpresa: element.Subempresa,
      });
    });

    return rowsToSet;
  };

  //* Function to handle the error response from the API it shows an alert or a dialog depending on the error
  const getResponseFromError = (err) => {
    if (err.response) {
      const status = err.response.status;
      const message = getErrorMessageFromStatus(status);
      setAlert({
        open: true,
        duration: 6000,
        title: `Error ${status}`,
        message: "Tu organización no ha registrado clientes " + message,
        severity: "error",
      });
    } else {
      console.log(err);
      setDialog((dialog) => {
        return {
          ...dialog,
          open: true,
          title: "ERROR Obteniendo clientes",
          content: "Ocurrió un error al obtener los clientes",
          actions: [
            {
              label: "Intentar más tarde",
              execute: () => setDialog({...dialog, open: false}),
            },
          ],
        };
      });
    }
  };

  //* Function to get the customers from the API
  const getData = () => {
    setLoading(true);
    getCustomers(userid, userid.token)
      .then((res) => {
        let customersData = res.data.data;
        if (customersData.length > 0) {
          setRows((ir) => res.data.data);
          setLoading(false);
        } else {
          setDialog({
            ...dialog,
            open: true,
            title: "Sin datos",
            content: "No has registrado clientes",
            actions: [
              {
                label: "Registrar un cliente",
                execute: () => navigate(`/${userid.claims.rol}/customers/new`),
              },
            ],
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        getResponseFromError(err);
      });
  };

  //* Function to handle the close of the alert component
  const handleCloseAlert = () => {
    setAlert((alert) => {
      return {...alert, open: !alert.open};
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <AlertDialog {...dialog} />
      <Snackbar
        open={alert.open}
        autoHideDuration={alert.duration}
        onClose={handleCloseAlert}
      >
        <Alert severity={alert.severity} title={alert.title}>
          {alert.message}
        </Alert>
      </Snackbar>
      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: "center",
          justifyItems: "ceter",
          alignContent: "center",
          alignItems: "center",
          paddingX: "2rem",
          paddingY: "2rem",
        }}
      >
        <Grid item xs={3}>
          <Typography variant="h4" color={"primary"}>
            Clientes
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <ConsultSearchComponent
            label={"Buscar Cliente (Nombre, RFC)"}
            onSearch={handleSearchCustomer}
            handleClearSerch={clearSearch}
            disabled={disabled.searchBar}
          />
        </Grid>
        <Grid item xs={3}>
          <Box sx={{display: "flex", justifyContent: "flex-end"}}>
            <Button
              variant="contained"
              onClick={goToCreate}
              color="primary"
              disabled={loading}
            >
              Crear nuevo
            </Button>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            "& .header-table": {
              backgroundColor: ferbyTheme.palette.primary.main,
              color: "white",
            },
          }}
        >
          <DataGrid
            sx={{minHeight: "50vh"}}
            columns={columns}
            rows={rows}
            getRowId={(row) => row._id}
            loading={loading}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CustomersIndex;
