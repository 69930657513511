export const objectToQueryString = (obj) => {
  return Object.entries(obj)
    .filter(([key, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
}

export const filtersToQueryString = (filters) => {
  return filters.map(filter => {
    const key = `${filter.param}=`;
    const value = Array.isArray(filter.value) ? filter.value.join(',') : filter.value;
    return `${key}${value}`;
  }).join('&');
}
