import React, { useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
//*Environment
//* Dialog components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
//*MUI iCONS
import useAuth from "../../../../../hooks/useAuth";
//*Network
import {
  getIssuers,
  saveIssuer,
} from "../../../../../services/modules/invoices/issuers";
import { getFiscalRegimes } from "../../../../../services/satCatalogs";
import CsdForm from "./CsdForm";

const IssuerData = () => {
  const fromExisting =
    new URLSearchParams(window.location.search).get("fromExisting") === "true";

  let files = {};
  const navigate = useNavigate();
  const [cert, setCert] = useState();
  const [csdKey, setCsdKey] = useState();
  const [keypass, setKeyPass] = useState();
  // const [rfc, setRfc] = useState("")
  const [issuer, setIssuer] = useState({});
  const [showCsdFiles, setShowCsdFiles] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorSaving, setErrorSaving] = useState({
    state: false,
    origin: "",
  });
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    status: "",
  });
  const [regimenes, setRegimenes] = useState([]);

  const { state } = useLocation();

  const [defaultValues, setDefaultValues] = useState(
    fromExisting
      ? { name: state.Name, Rfc: state.Rfc, FiscalRegime: state.FiscalRegime }
      : { name: undefined, Rfc: undefined, FiscalRegime: undefined }
  );

  const { userid } = useAuth();

  const handleChange = (e, id, value = null) => {
    // return new Promise((resolve, reject) => {})
    let key = e ? e.target.id.split("_")[1] : null;
    if (e) {
      switch (e.target.id) {
        case "issuer_rfc":
          setIssuer({ ...issuer, [key]: String(e.target.value).toUpperCase() });
          break;
        case "issuer_curp":
          setIssuer({ ...issuer, [key]: String(e.target.value).toUpperCase() });
          break;
        case "issuer_registration":
          setIssuer({ ...issuer, [key]: e.target.value });
          break;
        case "issuer_name":
          setIssuer({ ...issuer, [key]: String(e.target.value).toUpperCase() });
          break;
        default:
          break;
      }
    } else if (id && value) {
      if (id === "issuer_reg") {
        key = id.split("_")[1];
        setIssuer({ ...issuer, [key]: value.Value });
      } else {
        setAlert({
          open: true,
          message: "Id de evento desconocido",
          status: "error",
        });
      }
    } else {
      setAlert({
        open: true,
        message: "No se ha proporcionado un evento, ni un Id correcto",
        status: "error",
      });
    }
  };

  const handleClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const validateData = (issuerObject) => {
    const rfcRegexp = new RegExp(
      /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/
    );
    const nameRegexp = new RegExp(/^(.+ .+)$/);
    const invalidData = [];
    if (
      Object.keys(issuerObject).length > 0 &&
      Object.keys(issuerObject).includes("name" && "reg" && "rfc")
    ) {
      for (const [key, value] of Object.entries(issuerObject)) {
        switch (key) {
          case "name":
            if (!value.length < 5 && !nameRegexp.test(value)) {
              //*El nombre tiene menos de 5 caracteres por lo que no es posible que este completo
              //* o el nombre tiene espacios al inicio o al final
              invalidData.push("Nombre");
            }
            break;
          case "reg":
            //*Si el valor introducido no es un string vacío y la respuesta de API con los regimenes incluye el valor introducido, entonces es un valor correcto
            if (
              Object.keys(value).includes(
                "Moral" && "Name" && "Natural" && "Value"
              ) ||
              value !== (undefined || "")
            ) {
              let empty = [];
              for (const [key, v] of Object.entries(value)) {
                if (v === "" || undefined) {
                  empty.push(key);
                }
              }

              if (empty.length > 0) {
                invalidData.push("Régimen");
              }
            } else {
              invalidData.push("Régimen");
            }
            break;
          case "rfc":
            if (!rfcRegexp.test(value)) {
              invalidData.push("RFC");
            }
            break;
          default:
            break;
        }
      }
    } else {
      const needed = ["reg", "name", "rfc"];
      const completed = Object.keys(issuerObject);
      needed.forEach((value) => {
        if (completed.includes(value)) {
        } else {
          switch (value) {
            case "reg":
              invalidData.push("régimen");
              break;
            case "name":
              invalidData.push("nombre");
              break;
            case "rfc":
              invalidData.push("RFC");
              break;
            default:
              break;
          }
        }
      });
    }
    return invalidData;
  };

  const mapIssuer = (iss) => {
    let obj = {
      FiscalRegime: "",
      Rfc: "",
      Name: "",
      Curp: null,
      EmployeeRegistration: null,
    };

    obj.FiscalRegime = issuer.reg;
    obj.Name = issuer.name;
    obj.Rfc = issuer.rfc;
    if (issuer.curp) {
      obj.Curp = issuer.curp;
    }
    if (issuer.registration) {
      obj.EmployeeRegistration = issuer.registration;
    }

    return obj;
  };

  const handleSave = (e) => {
    e.preventDefault();
    //console.log(issuer)
    const invalidData = validateData(issuer);
    if (invalidData.length > 0) {
      setAlert({
        open: true,
        message: `${
          invalidData.length > 1 ? "Los campos" : "El campo"
        }  ${invalidData.map((value) => value)} no  ${
          invalidData.length > 1
            ? "son válidos, verificalos"
            : "es válido, verificalo"
        }`,
        status: "error",
      });
    } else {
      getIssuers(userid)
        .then((res) => {
          //*La petición devuelve los emisores que ya están almacenados en base de datos
          let exist = res.data.data.map((iss) => {
            //*Si el nombre y el RFC de alguno de los emisores devueltos es igual al que se va a intentar guardar, entonces debería rechazarse el guardado
            //*Y proponerse el cargado de los datos de CSD, en el caso de que no estén ya cargados
            if (iss.Nombre === issuer.name || iss.Rfc === issuer.rfc) {
              return true;
            }
            return false;
          });

          if (!exist[0] || !userid.claims.issuerAdded) {
            let mappedIssuer = mapIssuer(issuer);
            saveIssuer(userid, userid, mappedIssuer)
              .then((response) => {
                setDialog({
                  open: true,
                  title: "Emisor Guardado",
                  content:
                    "El emisor ha sido guardado correctamente, recuerda regresar a añadir su CSD para poder acceder a él.",
                  actions: [
                    {
                      label: "Añadir Otro",
                      execute: () => window.location.reload(),
                    },
                    {
                      label: "Regresar a inicio",
                      execute: () => navigate(`/${userid.claims.rol}/welcome`),
                    },
                  ],
                });
              })
              .catch((err) => {
                if (err.response) {
                  if (err.response) {
                    setDialog({
                      open: true,
                      title: `Error ${err.response.status}`,
                      content: `${err.response.data.message}`,
                      actions: [
                        {
                          label: "Verificar información",
                          execute: () =>
                            setDialog({
                              open: false,
                              title: "",
                              content: "",
                              actions: [],
                            }),
                        },
                      ],
                    });
                    setAlert({
                      ...alert,
                      open: true,
                      message:
                        "Tuvimos problemas para guardar el emisor, revisa tu conexión y recarga la página",
                      status: "error",
                    });
                  }
                }
              });
          }

          if (exist[0] && userid.claims.issuerAdded) {
            //*Si los datos del emisor, existen en base de datos y en el API de facturación entonces, no es necesario que el usuario siga introduciendo la información
            setDialog({
              ...dialog,
              open: true,
              title: "Datos existentes",
              content:
                "Los datos que estás intentando registrar, ya existen en base de datos, no es necesario que vuelvas a cargar esta información, ¿qué deseas hacer?",
              actions: [
                {
                  label: "Volver al inicio",
                  execute: () => {
                    navigate(`/${userid.claims.rol}/welcome`);
                  },
                },
                {
                  label: "Registrar otro emisor",
                  execute: () => {
                    setIssuer({});
                    window.location.reload();
                  },
                },
              ],
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setErrorSaving({
            ...errorSaving,
            state: true,
            origin: "exist",
          });
          setAlert({
            ...alert,
            open: true,
            message:
              "Tuvimos problemas para conectarnos a la base de datos, revisa tu conexión y recarga la página",
            status: "error",
          });
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const invalidData = validateData(issuer);
    if (invalidData.length > 0) {
      setAlert({
        open: true,
        message: `${
          invalidData.length > 1 ? "Los campos" : "El campo"
        }  ${invalidData.map((value) => value)} no  ${
          invalidData.length > 1
            ? "son válidos, verificalos"
            : "es válido, verificalo"
        }`,
        status: "error",
      });
    } else {
      getIssuers(userid)
        .then((res) => {
          //*La petición devuelve los emisores que ya están almacenados en base de datos
          let exist = res.data.data.map((iss) => {
            //*Si el nombre y el RFC de alguno de los emisores devueltos es igual al que se va a intentar guardar, entonces debería rechazarse el guardado
            //*Y proponerse el cargado de los datos de CSD, en el caso de que no estén ya cargados
            if (iss.Nombre === issuer.name || iss.Rfc === issuer.rfc) {
              return true;
            }
            return false;
          });

          if (fromExisting && (!exist[0] || !userid.claims.issuerAdded)) {
            //console.log("siwe")
            setDialog({ ...dialog, open: false });
            setShowCsdFiles(true);
          }
          if (!exist[0] || !userid.claims.issuerAdded) {
            //console.log("siwe")
            let mappedIssuer = mapIssuer(issuer);

            saveIssuer(userid, userid, mappedIssuer)
              .then((response) => {
                setDialog({ ...dialog, open: false });
                setShowCsdFiles(true);
              })
              .catch((err) => {
                if (err.response) {
                  setAlert({
                    ...alert,
                    open: true,
                    message:
                      "Tuvimos problemas para guardar el emisor, revisa tu conexión y recarga la página",
                    status: "error",
                  });

                  console.log("error", err.response);
                }
              });
            // setDialog({...dialog,open:false})
            // setShowCsdFiles(true)
          }

          if (exist[0] && userid.claims.issuerAdded) {
            //*Si los datos del emisor, existen en base de datos y en el API de facturación entonces, no es necesario que el usuario siga introduciendo la información
            setDialog({
              ...dialog,
              open: true,
              title: "Datos existentes",
              content:
                "Los datos que estás intentando registrar, ya existen en base de datos, no es necesario que vuelvas a cargar esta información, ¿qué deseas hacer?",
              actions: [
                {
                  label: "Volver al inicio",
                  execute: () => {
                    navigate(`/${userid.claims.rol}/welcome`);
                  },
                },
                {
                  label: "Registrar otro emisor",
                  execute: () => {
                    setIssuer({});
                    window.location.reload();
                  },
                },
              ],
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setErrorSaving({
            ...errorSaving,
            state: true,
            origin: "exist",
          });
          setAlert({
            ...alert,
            open: true,
            message:
              "Tuvimos problemas para conectarnos a la base de datos, revisa tu conexión y recarga la página",
            status: "error",
          });
        });
    }
  };

  //*Función para cerrar el dialogo
  const handleCloseDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  useEffect(() => {
    if (fromExisting) {
      setIssuer((iss) => {
        return {
          ...iss,
          name: defaultValues.name,
          reg: defaultValues.FiscalRegime,
          rfc: defaultValues.Rfc,
        };
      });
    }

    getFiscalRegimes(userid)
      .then((response) => {
        setRegimenes(response);
        setLoading(false);
        return;
      })
      .catch((err) => {
        if (err.response) {
          setLoading(false);
          console.log("error obteniendo el catálogo de régimenes fiscales");
        }
      });
  }, []);

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      {/* TODO : update dialog */}
      <Dialog open={dialog.open} onClose={handleCloseDialog}>
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialog.actions.map((action, index) => {
            return (
              <Button
                key={index}
                onClick={action.execute}
                variant={"contained"}
              >
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>
      <Typography
        textAlign={"center"}
        sx={{ marginTop: "8vh", marginLeft: "2%", marginRight: "2%" }}
        variant="h4"
      >
        Registra {fromExisting ? "de nuevo los datos del" : " un nuevo"} emisor
      </Typography>

      {/* 
                Im assuming there was some sort of issuer edition already implemented, 
                fromExisting was giving issues when displaying the inputs for loading the files 
                */}
      {fromExisting && (
        <>
          <Typography textAlign={"center"} variant="body2">
            Verifica los datos cargados por defecto, si son correctos presiona
            cargar los archivos.
          </Typography>
        </>
      )}
      {showCsdFiles && (
        <>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "5vh",
              }}
            >
              <CsdForm issuerRFC={issuer.rfc} />
            </Box>
          )}
        </>
      )}
      {!showCsdFiles && (
        <Box
          onSubmit={handleSubmit}
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "60%",
            marginBottom: "2vh",
          }}
        >
          <Grid container spacing={2} maxWidth={"sm"}>
            <Grid item xs={12}>
              <TextField
                id="issuer_name"
                label="Nombre Completo"
                required
                sx={{
                  width: "100%",
                }}
                onChange={handleChange}
                defaultValue={defaultValues.name}
                inputProps={{
                  style: {
                    "&:invalid": {
                      border: "red solid 2px !important",
                    },
                    textTransform: "uppercase",
                  },
                }}
              />
            </Grid>

            {/* RFC */}
            <Grid item xs={6}>
              <TextField
                label="RFC"
                required
                sx={{
                  width: "100%",
                }}
                onChange={handleChange}
                inputProps={{
                  style: {
                    "&:invalid": {
                      border: "red solid 2px !important",
                    },
                    textTransform: "uppercase",
                  },
                  maxLength: 13,
                }}
                defaultValue={defaultValues.Rfc}
                id="issuer_rfc"
              />
            </Grid>
            {/* End RFC */}

            {/* Fiscal Regimes */}
            <Grid item xs={6}>
              <Autocomplete
                options={regimenes}
                id="issuer_reg"
                isOptionEqualToValue={(option, value) => {
                  if (fromExisting) {
                    return option.Value === value;
                  }
                  return option.Name === value;
                }}
                getOptionLabel={(option) => {
                  return option.Name || option;
                }}
                defaultValue={defaultValues.FiscalRegime}
                loading={loading}
                onChange={(e, v, r) => {
                  switch (r) {
                    case "selectOption":
                      //*No se envía directamente el objeto event, porque el valor del ID incluye el identificador de la opción seleccionada
                      //*Esto, complica la estructura de las condicionales en la actualización del estado de los datos de Issuer, pues el ID es diferente cada vez que seleccionada una opción
                      //*Por lo que  a la función se le pasa el parametro "ID" Y "VALAUE"
                      handleChange(null, e.target.id.split("-")[0], v);
                      break;
                    default:
                      break;
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Régimen fiscal"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            {/* End fiscal regimes */}

            {/* CURP */}
            <Grid item xs={12}>
              <TextField
                label="CURP (Opcional)"
                sx={{
                  width: "100%",
                }}
                onChange={handleChange}
                inputProps={{
                  style: {
                    "&:invalid": {
                      border: "red solid 2px !important",
                    },
                    textTransform: "uppercase",
                  },
                  maxLength: 18,
                }}
                defaultValue={defaultValues.Curp}
                id="issuer_curp"
              />
            </Grid>
            {/* End CURP */}

            {/* Registro Patronal */}
            <Grid item xs={12}>
              <TextField
                label="Registro Patronal (Si se cuenta con él)"
                sx={{
                  //margin: "2vh",
                  width: "100%",
                }}
                onChange={handleChange}
                defaultValue={defaultValues.EmployeeRegistration}
                id="issuer_registration"
              />
            </Grid>
            {/* End Registro Patronal */}

            {/* Buttons */}
            <Grid
              item
              xs={12}
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button
                onClick={() => {
                  console.log(
                    "funcion de guardado sin archivos, should be executed in both buttons"
                  );
                  setDialog({
                    open: true,
                    title: "Guardar Emisor",
                    content:
                      "Puede continuar con la configuración del emisor y su CSD, o guardarlo y completar la configuración en otro momento. Recuerda que este emisor no estará disponible hasta que cuente con los archivos de su CSD.",
                    actions: [
                      {
                        label: "Guardar",
                        execute: (e) => handleSave(e),
                      },
                      {
                        label: "Subir CSD",
                        execute: (e) => handleSubmit(e),
                      },
                    ],
                  });
                }}
                variant="contained"
                color="primary"
                sx={{ boxShadow: 8 }}
              >
                Guardar Emisor
              </Button>
            </Grid>

            {/* End Buttons */}
          </Grid>

          <Snackbar
            open={alert.open}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              elevation={6}
              severity={alert.status}
              sx={{ width: "100%" }}
            >
              {alert.message}
            </Alert>
          </Snackbar>
        </Box>
      )}
    </div>
  );
};

export default IssuerData;
