import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Collapse,
  Grid,
  Input,
  Typography,
} from "@mui/material";

import AssignmentIcon from "@mui/icons-material/Assignment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {useState} from "react";

export const DisplayFilePreview = ({file}) => {
  if (file) {
    const filename = file.name;

    return (
      <Box
        alignItems={"center"}
        display={"flex"}
        flexDirection={"column"}
        sx={{maxWidth: "100%"}}
      >
        <Avatar color="success" variant="rounded">
          <AssignmentIcon />
        </Avatar>
        <Box display={"block"} maxWidth={"100%"}>
          <Typography variant="body1" sx={{wordWrap: "break-word"}}>
            {filename}
          </Typography>
        </Box>
      </Box>
    );
  }
};

export const DragAndDrop = ({
  fileExtension = "*",
  componentId = "dragAndDrop",
  submit = (file) => console.log(file),
  validFile = (file) => {
    return true;
  },
  disabled = false,
  limit = 1,
}) => {
  const [internalAlert, setInternalAlert] = useState({
    open: false,
    severity: "warning",
    title: "",
    message: "",
  });
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleFileSelect = (event) => {
    const files = event.target.files;
    setUploadedFiles(files);
    if (files.length > 1 && files.length <= limit) {
      const selectedFiles = Array.from(files);
      return submit(selectedFiles);
    } else if (files.length > limit) {
      setInternalAlert({
        open: true,
        severity: "error",
        title: "Límite excedido",
        message: "El limite de archivos es " + limit || 1,
      });
      return;
    }
    const latestFile = files.length ? files[files.length - 1] : false;
    if (
      latestFile &&
      (fileExtension === "*" ||
        fileExtension.split(",").includes(`.${latestFile.name.split(".")[1]}`))
    ) {
      return submit(latestFile);
    }

    setInternalAlert({
      open: true,
      severity: "error",
      title: "Archivo inválido",
      message: "El archivo debe ser de tipo " + fileExtension,
    });
    return;
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFileSelect({target: {files}});
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Grid item xs={12}>
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          style={{
            border: "2px dashed #aaa",
            borderRadius: "5px",
            padding: "16px",
            textAlign: "center",
            marginBottom: "16px",
          }}
        >
          <Input
            inputProps={{
              type: "file",
              accept: fileExtension,
              multiple: limit > 1,
            }}
            sx={{
              display: "none",
            }}
            onChange={handleFileSelect}
            id={componentId}
            disabled={disabled}
          />
          <label htmlFor={componentId}>
            <Button
              variant="contained"
              startIcon={<CloudUploadIcon />}
              component="span"
              disabled={disabled}
            >
              Arrastre un archivo o haga click para buscar
            </Button>
          </label>
        </div>
        <Collapse in={internalAlert.open}>
          <Alert severity={internalAlert.severity}>
            <AlertTitle>{internalAlert.title}</AlertTitle>
            {internalAlert.message}
          </Alert>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        {uploadedFiles.length > 0 &&
          (function () {
            const components = [];
            for (const file of uploadedFiles) {
              components.push(
                <DisplayFilePreview key={file.name} file={file} />
              );
            }
            return <>{components.map((component) => component)}</>;
          })()}
      </Grid>
    </>
  );
};
