import React, { useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export default function ListItemExpensesComponent({ expense, setExpense }) {
  const getIVA = () => {
    const iva = expense.Items.reduce(
      (accumulator, item) => accumulator + item.IVA,
      0
    );
    return parseFloat(iva.toFixed(2));
  };
  const getSubTotal = () => {
    const subtotal = expense.Items.reduce(
      (accumulator, item) => accumulator + item.Subtotal,
      0
    );
    return parseFloat(subtotal.toFixed(2));
  };
  const getTotal = () => {
    const total = expense.Items.reduce(
      (accumulator, item) => accumulator + item.Total,
      0
    );
    return parseFloat(total.toFixed(2));
  };

  const handleDelete = (position) => {
    const items = expense.Items.filter((item, index)=>index !== position);
    const newExpense = {...expense};
    newExpense.Items = items;
    setExpense(newExpense);
  };

  useEffect(() => {
    expense.IVA = getIVA();
    expense.Subtotal = getSubTotal();
    expense.Total = getTotal();
    setExpense({ ...expense });
  }, [expense.Items]);

  return (
    <>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Tipo</TableCell>
                <TableCell align="left">Descripción</TableCell>
                <TableCell align="center">Cantidad</TableCell>
                <TableCell align="right">Precio</TableCell>
                <TableCell align="right">Sub-Total</TableCell>
                <TableCell align="right">IVA</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expense.Items.map((item, index) => (
                <TableRow
                  key={index + 1}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{item.Type === 'product' ? 'Producto' : 'Servicio'}</TableCell>
                  <TableCell align="left">{item.Name}</TableCell>
                  <TableCell align="center">{item.Quantity}</TableCell>
                  <TableCell align="right">{item.Price}</TableCell>
                  <TableCell align="right">{item.Subtotal}</TableCell>
                  <TableCell align="right">{item.IVA}</TableCell>
                  <TableCell align="right">{item.Total}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={() => {
                        handleDelete(index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }}>
        <Typography variant="h6" component="h6">
          Sub-Total: {expense.Subtotal}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }}>
        <Typography variant="h6" component="h6">
          IVA: {expense.IVA}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }}>
        <Typography variant="h5" component="h5">
          Total: {expense.Total}
        </Typography>
      </Grid>
    </>
  );
}
