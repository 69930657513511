const CFDI_USES = {
  NOMINA: {
    Natural: true,
    Moral: false,
    Name: "Nómina",
    Value: "CN01",
  },
  PAGOS: {
    Value: "CP01",
    Name: "Pagos",
    Natural: true,
    Moral: true,
  },
  GASTOS_GENERAL: {
    Value: "G03",
    Name: "Gastos en general",
    Natural: true,
    Moral: true,
  },
};

export default CFDI_USES;
