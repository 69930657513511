import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  IncomeTaxesStrategy,
  InvoiceItemsMappingContext,
} from "../../../modules/invoices/utils/mappingStrategies.js";
import {isSATCodesProductsInput} from "../../../modules/invoices/utils/regexp.js";
import {calculateItemsTaxes} from "../../../services/modules/invoices/taxCalculation.js";
import useAuth from "../../../hooks/useAuth.js";
import {
  getProductsServices,
  getUnitsCatalog,
} from "../../../services/modules/invoices/catalogs.js";
import {truncateNumber} from "../../../modules/invoices/utils/numerManipulation.js";

let taxesStructure = {
  taxes: [
    {
      Name: "IVA RET",
      IsRetention: "true",
      Rate: "0.04",
      Base: "",
      Total: "",
    },
    {
      Name: "IVA",
      Rate: "0.16",
      Base: "",
      Total: "",
    },
  ],
  Total: 0,
};

let ItemsDataModel = [];

interface IvoiceItemsComponentProps {
  setComplete: (value: boolean) => void;
  dialog: {
    open: boolean;
    title: string;
    message: string;
  };
  setDialog: (value: any) => void;
  snack: {
    open: boolean;
    message: string;
  };
  setSnack: (value: any) => void;
}

const InvoiceItemsData = forwardRef((props: IvoiceItemsComponentProps, ref) => {
  const invoiceJson = JSON.parse(window.localStorage.getItem("invoiceModel"));

  const isIncome = invoiceJson.GeneralModel.CfdiType.Type === "I";
  const hasCartaPorte =
    invoiceJson.Complementos && invoiceJson.Complementos.CartaPorte;

  //*Contexts
  const {userid} = useAuth();

  //*DATA STATES
  const taxObjects = [
    {
      id: "01",
      Value: "No objeto de impuesto",
    },
    {
      id: "02",
      Value: "Sí objeto de impuesto",
    },
    {
      id: "03",
      Value: "Sí objeto de impuesto, no obligado al desglose",
    },
  ];
  const [options, setOptions] = useState({
    productCodes: [],
    unitCodes: [],
    taxes: taxObjects,
  });
  const [counter, setCounter] = useState(
    invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items.length
  );

  //*RENDER STATES
  const [activeItem, setActiveItem] = useState({
    quantity:
      invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items
        .length !== 0
        ? invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items[0]
            .quantity
        : 1,
    product:
      invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items
        .length !== 0
        ? invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items[0]
            .product
        : "",
    unit:
      invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items
        .length !== 0
        ? invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items[0]
            .unit
        : "",
    description:
      invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items
        .length !== 0
        ? invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items[0]
            .description
        : "",
    price:
      invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items
        .length !== 0
        ? invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items[0]
            .price
        : "",
    taxObject:
      invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items
        .length !== 0
        ? invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items[0]
            .taxObject
        : taxObjects[1],
    uuid: undefined,
    taxes: undefined,
    total: 0,
  });
  const [items, setItems] = useState(
    invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items.length !==
      0
      ? invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type]?.Items
      : []
  );
  const [showTaxes, setShowTaxes] = useState(false);

  const [backdrop, setBackdrop] = useState(false);

  const [emptyError, setEmptyError] = useState([]);

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    actions: [],
  });

  //*INPUT LISTENERS
  const [productsInput, setProductsInput] = useState("");
  const [unitsInput, setUnitsInput] = useState("");
  const [addTry, setAddTry] = useState(false);

  //*USER FEEDBACK
  const [loading, setLoading] = useState({
    products: false,
    units: false,
    general: false,
  });
  const [alert, setAlert] = useState({
    state: "",
    message: "",
  });

  const handleItemsData = (e, v, r) => {
    let id;

    if (e.target.id.includes("-")) {
      id = e.target.id.split("-")[0];
    } else {
      id = e.target.id;
    }
    switch (r) {
      case "input":
        if (id === "quantity") {
          setActiveItem({
            ...activeItem,
            [id]: Number(e.target.value),
          });
          break;
        }
        setActiveItem({
          ...activeItem,
          [id]: e.target.value,
        });

        break;
      case "selectOption":
        //*TODO: Handle validation to select an item when the invoice has the complement CartaPorte (matriz de errores)
        //*? Should we start using a context to share specific states, like invoice type, or other data that conditionates the flow of the forms in the workflow?
        /**
         * 1. Validar que el item seleccionado esté incluido en la lista de productos y servicios válidos cuando el complemento CartaPorte esté activo, sólo si y sólo si no ha seleccionado un item válido previamente
         * 2. Si se registra alguna de las siguientes claves de productos relacionadas con el transporte de carga intermodal: 

        78101900, 78101901, 78101902, 78101903 o 78101904.

        En el complemento Carta Porte debe existir más de uno de los siguientes nodos:
        • "Mercancias:Autotransporte",
        • “Mercancias:TransporteMaritimo",
        • "Mercancias:TransporteAereo",
        • "Mercancias:TransporteFerroviario".
        * Es decir, deberán añadirse nuevos pasos en el flujo de trabajo para completar la información de los nodos de transporte de carga intermodal.
         */
        setActiveItem({
          ...activeItem,
          [id]: v,
        });
        break;
      case "clear":
        console.log(id);
        if (id === "product") {
          setActiveItem({
            ...activeItem,
            product: {Name: "", Value: ""},
          });
          setProductsInput("");
        }
        break;
      default:
        break;
    }
  };

  const handleProductsInput = (e) => {
    const inputValue = e.target.value;
    setProductsInput((prevInput) => inputValue);
  };

  const handleUnitsInput = (e) => {
    setUnitsInput(e.target.value);
    if (e.target.value !== undefined && e.target.value.length > 3) {
      getUnitsCatalog(userid, userid, e.target.value)
        .then((data) => {
          if (data.data.length > 0) {
            setOptions((opts) => {
              return {
                ...opts,
                unitCodes: data.data,
              };
            });
          }
        })
        .catch((err) => {
          setAlert((alert) => {
            return {
              ...alert,
              state: "error",
              message: "Error obteniendo el catalogo de unidades",
            };
          });
        });
    }
  };

  const setTaxes = () => {
    const itemsMappingContext = new InvoiceItemsMappingContext(
      new IncomeTaxesStrategy(taxesStructure, activeItem)
    );
    itemsMappingContext.calculateTaxes();

    setActiveItem({
      ...activeItem,
      taxes: taxesStructure.taxes,
      total: taxesStructure.Total,
    });
  };

  const handleDeleteItem = (item) => {
    let tempArr = [...items];
    let index = tempArr.indexOf(item);
    if (index !== -1) {
      tempArr.splice(index, 1);
    }

    invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type].Items = tempArr;

    window.localStorage.setItem("invoiceModel", JSON.stringify(invoiceJson));
    setCounter(
      invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type].Items.length
    );

    setItems(tempArr);
  };

  const mapTaxedItems = (taxedItems) => {
    let incompleteItems = items;

    //for (let txditm in tax)
    taxedItems.forEach((txditm, idx) => {
      let elarr = txditm.Taxes.map((tax) => ({
        ...tax,
        Total: truncateNumber(tax.Total, 2) * 1,
      }));

      const item = incompleteItems[idx];
      const actItem = activeItem;

      incompleteItems[idx] = {
        ...incompleteItems[idx],
        taxObject: item?.taxObject || actItem.taxObject,
        unit: item?.unit || actItem.unit,
        description: item?.description || actItem.description,
        product: item?.product || actItem.product,
        quantity: txditm.Quantity,
        taxes: elarr,
        price: truncateNumber(txditm.UnitPrice, 2) * 1,
        total: truncateNumber(txditm.Total, 2) * 1,
        subtotal: truncateNumber(txditm.Subtotal, 2) * 1,
      };
    });
    return incompleteItems;
  };

  const handleTheft = (invoiceData) => {
    setLoading({...loading, general: true});
    return calculateItemsTaxes(userid, userid, invoiceData);
  };

  const handleAdd = () => {
    setShowTaxes(false);
    const validActiveItem = (item) => {
      if (
        item.quantity !== "" &&
        item.product !== "" &&
        item.unit !== "" &&
        item.price !== "" &&
        item.taxObject !== "" &&
        item.description !== ""
      ) {
        return true;
      }

      return false;
    };

    if (validActiveItem(activeItem)) {
      const invoiceCopy = JSON.parse(
        window.localStorage.getItem("invoiceModel")
      );
      invoiceCopy.Models[invoiceCopy.GeneralModel.CfdiType.Type].Items.push(
        activeItem
      );
      handleTheft(invoiceCopy)
        .then((response) => {
          let modded = mapTaxedItems(response.data);
          invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type].Items =
            modded;
          window.localStorage.setItem(
            "invoiceModel",
            JSON.stringify(invoiceJson)
          );
          setItems(() => modded);
          setCounter(
            invoiceJson.Models[invoiceJson.GeneralModel.CfdiType.Type].Items
              .length
          );
          setLoading({...loading, general: false});
          setShowTaxes(true);
          (props as any).setComplete(true);
          setActiveItem({
            ...activeItem,
            quantity: "",
            product: {Name: "", Value: ""},
            unit: "",
            description: "",
            price: "",
            taxObject: "",
            uuid: undefined,
            taxes: undefined,
            total: undefined,
          });
        })
        .catch((err) => {
          setLoading({...loading, general: false});
          console.log(err);
          props.setSnack({
            open: true,
            message: "Error calculando impuestos",
            severity: "error",
            duration: 1000,
          });
        });
    }

    if (!validActiveItem(activeItem)) {
      setDialog({
        ...dialog,
        open: true,
        title: "Solicitud Incompleta",
        content:
          "Por favor verifique que completó la información en los campos requeridos",
        actions: [
          {
            label: "Ok",
            execute: () => {
              setDialog({...dialog, open: false});
            },
          },
        ],
      });
      setAddTry(true);
      if (items.length > 0) {
        setShowTaxes(true);
      }
    }
  };

  const useDidUpdate = (fn, inputs) => {
    const didMountRef = useRef(false);

    useEffect(() => {
      if (didMountRef.current) {
        return fn();
      }

      didMountRef.current = true;
      fn(true);
    }, inputs);
  };

  const handleInputErrorsUi = () => {
    const errors = [];

    if (items.length === 0) {
      return false;
    }

    items.forEach((item) => {
      if (
        item.quantity === "" ||
        item.product === "" ||
        item.unit === "" ||
        item.price === "" ||
        item.taxObject === "" ||
        item.description === ""
      ) {
        errors.push({value: "Item", description: "Item incompleto"});
      }

      if (typeof item.price === "string") {
        errors.push({
          value: "price",
          description: "El precio debe ser un número",
        });
      }

      if (typeof item.quantity === "string") {
        errors.push({
          value: "cantidad",
          description: "La cantidad debe ser un número",
        });
      }

      if (typeof item.subtotal === "string") {
        errors.push({
          value: "subtotal",
          description: "El subtotal debe ser un número",
        });
      }

      if (typeof item.total === "string") {
        errors.push({
          value: "total",
          description: "El total debe ser un número",
        });
      }

      item.taxes.forEach((tax) => {
        if (typeof tax.Total === "string") {
          errors.push({
            value: `${item.product.Name} - ${tax.Name}`,
            description: `El total de ${tax.Name} debe ser un número`,
          });
        }
      });
    });

    if (errors.length > 0) {
      props.setDialog({
        ...props.dialog,
        open: true,
        title: "Error",
        message: (
          <>
            {errors.map((error, idx) => (
              <div key={idx}>
                <Typography>{`${error.value}: ${error.description}`}</Typography>
              </div>
            ))}
          </>
        ),
      });
    }
  };

  const validateItems = () => {
    const errors = [];

    if (items.length === 0) {
      return false;
    }

    items.forEach((item) => {
      if (
        item.quantity === "" ||
        item.product === "" ||
        item.unit === "" ||
        item.price === "" ||
        item.taxObject === "" ||
        item.description === ""
      ) {
        errors.push("item incorrecto");
      }

      if (typeof item.price === "string") {
        errors.push("item incorrecto");
      }

      if (typeof item.quantity === "string") {
        errors.push("item incorrecto");
      }

      if (typeof item.subtotal === "string") {
        errors.push("item incorrecto");
      }

      if (typeof item.total === "string") {
        errors.push("item incorrecto");
      }

      item.taxes.forEach((tax) => {
        if (typeof tax.Total === "string") {
          errors.push({
            value: `${item.product.Name} - ${tax.Name}`,
            description: `El total de ${tax.Name} debe ser un número`,
          });
        }
      });
    });

    if (errors.length > 0) {
      return false;
    }
    return true;
  };

  useImperativeHandle(ref, () => ({
    validate: validateItems,
    showErrors: handleInputErrorsUi,
  }));

  useDidUpdate(async (dflt) => {
    if (dflt) {
      setLoading((load) => {
        return {
          ...load,
          products: true,
          units: true,
        };
      });

      try {
        const defaultsByType = {
          IngresoComplemento: {
            Servicio: "78101801",
            Unidad: "E48",
          },
        };

        if (isIncome && hasCartaPorte) {
          const res = await getProductsServices(
            userid,
            userid,
            defaultsByType["IngresoComplemento"].Servicio
          );
          const res1 = await getUnitsCatalog(
            userid,
            userid,
            `${defaultsByType["IngresoComplemento"].Unidad}`
          );
          if (res.data.length > 0 && res1.data.length > 0) {
            -setActiveItem((i) => {
              return {
                ...i,
                product: res.data[0],
                unit: res1.data[0],
              };
            });
          }
        }
      } catch (err) {
        setAlert((alert) => {
          return {
            ...alert,
            state: "error",
            message: "Hubo un problema al obtener el catalogo.",
          };
        });
      }

      setLoading((load) => {
        return {
          ...load,
          products: false,
          units: false,
        };
      });
    }
  }, []);

  useEffect(() => {
    let errorArr = [];
    if (addTry) {
      Object.keys(activeItem).forEach((key) => {
        if (activeItem[key] === "" || activeItem[key] === undefined) {
          errorArr.push(key);
        }
      });
      setEmptyError(errorArr);
      setAddTry(false);
    } else {
      // Remove keys from errorArr if they are no longer empty or undefined
      errorArr = emptyError.filter(
        (key) => activeItem[key] === "" || activeItem[key] === undefined
      );
      setEmptyError(errorArr);
    }
  }, [activeItem, addTry]);

  useEffect(() => {
    if (productsInput !== undefined && productsInput.length > 3) {
      getProductsServices(userid, userid, productsInput)
        .then((res) => {
          console.log(res);
          if (res.data.length > 0) {
            setOptions((opts) => {
              return {
                ...opts,
                productCodes: res.data,
              };
            });
          }
        })
        .catch((err) => {
          setAlert((alert) => {
            return {
              ...alert,
              state: "error",
              message: "Error obteniendo el catalogo de productos y servicios",
            };
          });
        });
    }
  }, [productsInput]);

  return (
    <>
      <Typography variant="h6" sx={{marginTop: "2vh"}}>
        Productos / Servicios a facturar
      </Typography>

      {
        <Grid
          container
          spacing={3}
          marginTop=".2vh"
          justifyContent={"center"}
          alignSelf={"center"}
          maxWidth={"lg"}
        >
          <Grid item xs={8}>
            {/* Código del producto */}
            <Autocomplete
              id="product"
              value={activeItem.product || ""}
              onChange={(e, v, r) => {
                switch (r) {
                  case "clear":
                    setActiveItem({
                      ...activeItem,
                      product: {Name: "", Value: ""},
                    });
                    setProductsInput("");
                    break;

                  default:
                    handleItemsData(e, v, r);
                    break;
                }
              }}
              options={options.productCodes}
              sx={{width: "100%"}}
              clearText="Limpiar"
              clearOnBlur={false}
              fullWidth={true}
              autoComplete
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    onChange={handleProductsInput}
                    label="Servicio"
                    error={emptyError.includes("product")}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading.products ? (
                            <CircularProgress />
                          ) : (
                            params.InputProps.endAdornment
                          )}
                        </React.Fragment>
                      ),
                    }}
                  />
                );
              }}
              getOptionLabel={(option) => {
                if (isSATCodesProductsInput(productsInput)) {
                  return option.Value;
                }

                return option.Name;
              }}
              renderOption={(props, option, state) => {
                return (
                  <li {...props}>
                    {option.Name} - {option.Value}
                  </li>
                );
              }}
              isOptionEqualToValue={(option, value) => {
                if (value.Value === "" && value.Name === "") {
                  console.log("Input has been cleared, equality will fail");
                  return true;
                }
                return (
                  option.Value === value.Value || option.Name === value.Name
                );
              }}
            />
          </Grid>
          <Grid item xs={4}>
            {/* Cantidad */}
            <TextField
              id="quantity"
              size="small"
              variant="standard"
              value={activeItem.quantity}
              error={emptyError.includes("quantity")}
              sx={{width: "100%"}}
              label="Cantidad"
              type="number"
              onChange={(e) => handleItemsData(e, e.target.value, "input")}
            />
          </Grid>

          <Grid item xs={8}>
            {/* Unidad */}
            <Autocomplete
              id="unit"
              autoComplete
              options={options.unitCodes}
              onChange={handleItemsData}
              value={activeItem.unit}
              clearText="Limpiar"
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Unidad de medida"
                    name="UnitCode"
                    onChange={handleUnitsInput}
                    error={emptyError.includes("unit")}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading.units ? (
                            <CircularProgress />
                          ) : (
                            params.InputProps.endAdornment
                          )}
                        </>
                      ),
                    }}
                  />
                );
              }}
              getOptionLabel={(option) => option.Name || ""}
              isOptionEqualToValue={(option, value) => {
                if (value === "") {
                  return true;
                }
                return option.Name === value.Name;
              }}
            />
          </Grid>
          <Grid item xs={4}>
            {/* Precio */}
            <TextField
              id="price"
              label="Precio del producto/servicio"
              error={emptyError.includes("price")}
              type="number"
              value={activeItem.price}
              sx={{width: "100%"}}
              onChange={(e) => handleItemsData(e, e.target.value, "input")}
            />
          </Grid>

          <Grid item xs={12} sx={{minHeight: "3vh"}}>
            {/* Descripción */}
            <TextField
              id="description"
              label="Descripción"
              error={emptyError.includes("description")}
              multiline
              rows={3}
              value={activeItem.description}
              sx={{width: "100%"}}
              onChange={(e) => handleItemsData(e, e.target.value, "input")}
            />
          </Grid>
          <Grid item xs={12}>
            {/* Objeto de impuesto */}
            <Autocomplete
              id="taxObject"
              autoComplete
              options={options.taxes}
              value={activeItem.taxObject}
              sx={{width: "100%"}}
              clearText="Limpiar"
              onChange={handleItemsData}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Objeto de impuesto?"
                    error={emptyError.includes("taxObject")}
                    name="TaxObject"
                  />
                );
              }}
              getOptionLabel={(option) => {
                console.log(option);
                return option.Value || "";
              }}
              isOptionEqualToValue={(o, v) => {
                if (v === "") {
                  return true;
                }

                return v.id === o.id;
              }}
            />
          </Grid>

          <Box sx={{display: "flex"}}></Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleAdd}
              variant="contained"
              color="secondary"
              sx={{
                margin: "2vh",
                color: "white",
              }}
            >
              {"Agregar"}
            </Button>
            <Typography
              variant="body2"
              sx={{fontWeight: "bold", fontSize: "2vh"}}
            >
              Items:{`${counter}`}
            </Typography>
          </Box>
        </Grid>
      }

      {showTaxes && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ItemsTable data={items} handleDelete={handleDeleteItem} />
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      )}
      {alert.state !== "" ? (
        <Alert
          severity={`${alert.state}` as AlertColor}
        >{`${alert.message}`}</Alert>
      ) : null}
    </>
  );
});

function ItemsTable({data, handleDelete}) {
  const displayTaxes = (data) => {
    let taxesArr = data.taxes;
    return taxesArr.map((tax) => {
      return (
        <Grid container>
          <Grid item xs={7}>
            <Typography variant={"caption"}>{tax.Name}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant={"caption"}>
              $<b>{tax.Total}</b>
            </Typography>
          </Grid>
        </Grid>
      );
    });
  };

  let total = getTotal();

  function getTotal() {
    let total = 0;
    for (const array in data) {
      total = total + Number(data[array].total);
    }
    return total;
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="dynamic table">
        <TableHead>
          <TableRow sx={{backgroundColor: "primary.main"}}>
            <TableCell align="center">
              <Typography fontWeight={600} color="white">
                {" "}
                Servicio
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="white">
                {" "}
                Descripción interna
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="white">
                {" "}
                Cantidad
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="white">
                {" "}
                SubTotal
              </Typography>
            </TableCell>
            <TableCell align="center" colSpan={2}>
              <Typography fontWeight={600} color="white">
                {" "}
                Impuestos
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="white">
                {" "}
                Total
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontWeight={600} color="white">
                {" "}
                Opciones
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <Typography>{item.product.Name}</Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                <Typography>{item.description}</Typography>
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                <Typography>{item.quantity}</Typography>
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                <Typography>
                  <b>${item.subtotal}</b>
                </Typography>
              </TableCell>
              <TableCell colSpan={2} component="th" scope="row" align="right">
                {displayTaxes(data[index])}
              </TableCell>
              <TableCell component="th" scope="row" align="right">
                <Typography>
                  <b>${item.total}</b>
                </Typography>
              </TableCell>

              <TableCell align="right">
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => handleDelete(item)}
                >
                  Eliminar
                </Button>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row" align="right"></TableCell>
            <TableCell component="th" scope="row" align="right"></TableCell>
            <TableCell component="th" scope="row" align="right">
              <Typography sx={{color: "white"}}>
                <b>${total}</b>
              </Typography>
            </TableCell>
            <TableCell component="th" scope="row" align="right">
              <b>Total:</b>
            </TableCell>
            <TableCell component="th" scope="row" align="right">
              <Typography>
                <b>${total}</b>
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export {InvoiceItemsData, ItemsDataModel};
