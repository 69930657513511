import React from "react";
import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { hasObjectValue } from "../../../../services/helpers/objects/isEmpty";

const BaseTransportItem = ({
  data,
  options,
  changeSelection,
  changeServiceCode,
}) => {
  const handleChangeSelection = (e, v, r) => {
    changeSelection(e, v, r);
  };

  const handleChangeServiceCode = (e, v, r) => {
    changeServiceCode(e, v, r);
  };

  return (
    <>
      <Grid item xs={12} md={4}>
        <TextField
          id={"SubTotal"}
          label="Subtotal"
          onChange={handleChangeSelection}
          variant="standard"
          sx={{ width: "100%" }}
          InputProps={{
            startAdornment: "$",
            sx: {
              "& input": {
                textAlign: "right",
              },
            },
          }}
          placeholder={"0.00"}
          type="number"
          step="any"
          value={
            data?.TransportService?.SubTotal
              ? data.TransportService.SubTotal
              : ""
          }
        />
      </Grid>

      <Grid item xs={12} md={8}>
        {/* Código del servicio */}
        <Autocomplete
          autoComplete
          id="Code"
          options={options.productCodes}
          sx={{ width: "100%" }}
          clearText="Limpiar"
          onChange={handleChangeServiceCode}
          clearOnBlur={false}
          fullWidth={true} 
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label="Código del Servicio de Transporte"
              />
            );
          }}
          getOptionLabel={(option) => {
            if (option == "") {
              return "";
            }
            return `${option.Name} - ${option.Value}`;
          }}
          isOptionEqualToValue={(option, value) => {
            if (value === "") {
              return true;
            }
            return option.Value === value.Value;
          }}
          value={
            hasObjectValue(data?.TransportService?.Code)
              ? data.TransportService.Code
              : ""
          }
        />
      </Grid>
    </>
  );
};

export default BaseTransportItem;
