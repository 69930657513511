import {DynamicFieldConfig} from "../../../../components/layout/interfaces/DynamicFieldConfig.interface";

export const ExpenseFormDescriptionInputFieldsConfigDto: DynamicFieldConfig<string> =
  {
    type: "text",
    label: "Descripción",
    name: "description",
    id: "Description",
    multiline: true,
    validator: (value: string) => {
      console.log("value", value);
      if (value.length < 10) {
        return {
          failed: true,
          message: "La descripción debe tener al menos 10 caracteres",
        };
      }
      return {failed: false, message: ""};
    },
  };

export const ExpenseFormDateInputFieldsConfigDto: DynamicFieldConfig<string> = {
  type: "date",
  label: "Fecha",
  name: "date",
  id: "Date",
  validator: (value: string) => {
    // Add your validation logic for the date field here
    return {failed: false, message: ""};
  },
};

export const ExpensesFormTotalInputFieldConfigDto: DynamicFieldConfig<number> =
  {
    type: "number",
    label: "Total",
    name: "total",
    id: "Total",
    validator: (value: number) => {
      // Add your validation logic for the total field here
      return {failed: false, message: ""};
    },
  };

export const ExpensesFormCategoryInputFieldConfigDto: DynamicFieldConfig<object> =
  {
    type: "select",
    label: "Categoría",
    name: "category",
    id: "Category",
    options: [],
    validator: (value: object) => {
      // Add your validation logic for the category field here
      return {failed: false, message: ""};
    },
  };

export const ExpensesFormLinkedVehicleCheckboxConfigDto: DynamicFieldConfig<boolean> =
  {
    type: "checkbox",
    label: "Asociar vehículo",
    name: "asociarVehiculo",
    id: "linkedVehicle",
  };

export const ExpensesFormLinkedTrailerCheckboxConfigDto: DynamicFieldConfig<boolean> =
  {
    type: "checkbox",
    label: "Asociar remolque",
    name: "asociarRemolque",
    id: "linkedTrailer",
  };

export const ExpensesFormLinkedDriverCheckboxConfigDto: DynamicFieldConfig<boolean> =
  {
    type: "checkbox",
    label: "Asociar conductor",
    name: "asociarConductor",
    id: "linkedDriver",
  };

/**
 * Configuration object for ExpensesFormConfigsById.
 * Maps the form field IDs to their respective configuration objects.
 */
export const ExpensesFormConfigsById = {
  [ExpenseFormDescriptionInputFieldsConfigDto.id]:
    ExpenseFormDescriptionInputFieldsConfigDto,
  [ExpenseFormDateInputFieldsConfigDto.id]: ExpenseFormDateInputFieldsConfigDto,
  [ExpensesFormTotalInputFieldConfigDto.id]:
    ExpensesFormTotalInputFieldConfigDto,
  [ExpensesFormCategoryInputFieldConfigDto.id]:
    ExpensesFormCategoryInputFieldConfigDto,
  [ExpensesFormLinkedVehicleCheckboxConfigDto.id]:
    ExpensesFormLinkedVehicleCheckboxConfigDto,
  [ExpensesFormLinkedTrailerCheckboxConfigDto.id]:
    ExpensesFormLinkedTrailerCheckboxConfigDto,
  [ExpensesFormLinkedDriverCheckboxConfigDto.id]:
    ExpensesFormLinkedDriverCheckboxConfigDto,
};

export interface IExpense {
  Items?: any[];
  Description: string;
  Category: string;
  LinkedVehicles?: any[];
  LinkedTrailers?: any[];
  Subtotal?: number;
  Total: number;
  Date: Date | string;
  Files?: any[];
}

export const ExpenseDto: IExpense = {
  Description: "",
  Category: "",
  Total: 0,
  Date: new Date(),
};
